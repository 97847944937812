import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  convertDataEpochToDate,
  getTpmsTirePressure,
  getThresholdsByAssignedProfile,
  getProfilePsiLevel,
  getClearLabel,
} from '../../utils'
import { isNumber } from 'lodash'
import { Tooltip } from 'reactstrap'
import { NoDataLabels } from '../../enums'
import { tpmsTableSortColumns } from './Thead'

const SensorTooltip = ({ id, timestamp, status, sensorName }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const measurementTime = timestamp
    ? convertDataEpochToDate(timestamp, null, null, true)
    : '---'
  return (
    <Tooltip
      innerClassName={`tire-tooltip tire-tooltip-margin ${status}`}
      placement='top'
      target={id}
      isOpen={tooltipOpen}
      offset='0, 7px'
      hideArrow={true}
      toggle={toggle}
    >
      <div>Position: {getClearLabel(sensorName)}</div>
      <div>Latest Measurement: {measurementTime}</div>
    </Tooltip>
  )
}

const Tr = ({
  asset,
  defaults,
  columns,
  preferences: { unitsOfMeasurementConfig },
  columnsToShow,
}) => {
  const [tpmsThresholds, setTpmsThresholds] = useState({})

  const sortColumns = useMemo(() => {
    const cols = []
    columnsToShow.forEach((colToShow) => {
      const tpmsCol = tpmsTableSortColumns.find((col) => colToShow === col.id)
      if (tpmsCol) {
        cols.push(tpmsCol)
      }
    })
    return cols
  }, [columnsToShow])

  useEffect(() => {
    if (!defaults || !defaults.tpms) return
    const {
      critical_low_pressure_in_percent,
      critical_over_pressure_in_percent,
      low_pressure_in_percent,
      over_pressure_in_percent,
    } = defaults.tpms
    let obj = {
      critical_low_pressure_in_percent,
      critical_over_pressure_in_percent,
      low_pressure_in_percent,
      over_pressure_in_percent,
    }
    let isValid = true

    Object.values(asset.tpms_profile_thresholds_percents).map((item) => {
      if (!item) {
        isValid = false
      }
      return isValid
    })

    if (asset.tpms_profile_thresholds_percents && isValid) {
      obj = { ...asset.tpms_profile_thresholds_percents }
    }

    setTpmsThresholds(obj)
  }, [defaults, asset.tpms_profile_thresholds_percents])

  const sensorsColumns = useMemo(() => {
    const data = columns.map((col) => {
      const td = asset.sensors.find((el) => (el.key === col ? true : false))

      let statusClassName = ''
      let label = NoDataLabels.DASH

      if (td) {
        const { key, pressure, cold_inflation_pressure_in_psi, no_status } = td

        if (no_status) {
          statusClassName = 'noStatus'
        } else if (pressure !== null) {
          label = getTpmsTirePressure(
            pressure,
            unitsOfMeasurementConfig.pressure,
          )
          const profilePsiLevel =
            cold_inflation_pressure_in_psi || getProfilePsiLevel(key)

          const thresholdsByAssignedProfile = getThresholdsByAssignedProfile(
            unitsOfMeasurementConfig.pressure,
            profilePsiLevel,
            tpmsThresholds,
            defaults.tpms?.cold_inflation_pressure_in_psi,
          )

          if (isNumber(label)) {
            if (
              label < thresholdsByAssignedProfile.critical_low_pressure ||
              label > thresholdsByAssignedProfile.critical_over_pressure
            )
              statusClassName = 'criticalWarning'
            else if (
              label < thresholdsByAssignedProfile.low_pressure ||
              label > thresholdsByAssignedProfile.over_pressure
            )
              statusClassName = 'warning'
          }
        }
      }

      const id = col + '_' + asset.asset_id

      return (
        <td key={id} id={id} className={'sensor-column ' + statusClassName}>
          {label}
          {td && (
            <SensorTooltip
              id={id}
              timestamp={td.timestamp_pressure}
              status={statusClassName}
              sensorName={col}
            />
          )}
        </td>
      )
    })

    if (columns.length <= 12) return { upperRow: data }

    const upperRow = []
    const lowerRow = []

    for (let i = 0; i < columns.length; i++) {
      if (i % 2 === 0) upperRow.push(data[i])
      else lowerRow.push(data[i])
    }

    return { upperRow, lowerRow }
  }, [
    asset,
    columns,
    defaults.tpms.cold_inflation_pressure_in_psi,
    unitsOfMeasurementConfig,
    tpmsThresholds,
  ])

  if (!defaults.tpms && !Object.keys(tpmsThresholds).length) return null

  const link = `/details/${asset.vin}?reducePoints=true`
  const rowSpan = sensorsColumns.lowerRow ? 2 : 1

  return (
    <tbody key={asset.id}>
      <tr>
        {sortColumns.map((item, index) => {
          let value

          switch (item.id) {
            case 'latest_tpms_update_at_epoch':
              value =
                asset.latest_tpms_update_at_epoch &&
                asset.latest_tpms_update_at_epoch > 0
                  ? convertDataEpochToDate(
                      asset.latest_tpms_update_at_epoch,
                      null,
                      null,
                      true,
                    )
                  : NoDataLabels.DASH
              break
            case 'tpms_profile':
              value = asset.tpms_profile ? asset.tpms_profile : 'Default'
              break
            case 'asset_groups':
              value =
                asset.asset_groups?.map((ag) => ag.name).join(', ') ??
                NoDataLabels.DASH
              break
            case 'geofences':
              value = asset.geofences?.replace(';', '; ') ?? NoDataLabels.DASH
              break
            case 'geofence_groups':
              value =
                asset.geofence_groups?.replace(';', ', ') ?? NoDataLabels.DASH
              break
            default:
              value = asset[item.id]
              break
          }

          return (
            <td className='primary-column' rowSpan={rowSpan} key={index}>
              <Link to={link}>{value}</Link>
            </td>
          )
        })}

        {sensorsColumns.upperRow}
      </tr>
      {sensorsColumns.lowerRow && <tr>{sensorsColumns.lowerRow}</tr>}
    </tbody>
  )
}

export default Tr
