import { Button } from 'reactstrap'
import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { Row, Col, Alert, UncontrolledTooltip } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import { getColor } from '../../utils'
import defaultConfig from '../../redux/whitelabel/defaultConfig'
import { getAxlesColors, getCurrentAxlesCount } from './utils'
import './style.scss'

const copyColor = (data, color) => ({ ...data, color })

const ColorPicker = ({
  title,
  colorsData = [],
  onUpdate,
  locale,
  showControlButtons,
  onAddAxles,
  onRemoveAxles,
}) => {
  const [colors, setColors] = useState(colorsData)
  
  const [current, setCurrent] = useState(colorsData.length ? 0 : -1)

  const onColorChange = (color) => {
    const updatedColors = colors.map((c, i) =>
      i === current ? copyColor(colors[i], color.hex) : c,
    )
    setColors(updatedColors)
    onUpdate(updatedColors)
  }

  const addAxlesHandler = () => {
    // color from default config or random color
    const getConfigColor = (id) =>
      defaultConfig.wheelColors.find((el) => (el.id === id ? true : false))
        ?.color || getColor('', [])
    const { tractor, trailer } = getAxlesColors(colors)

    let count = getCurrentAxlesCount(tractor)
    let newIdx = count + 1
    const newTractorAxles = tractor
    const newTrailerAxles = trailer

    newTractorAxles.push({
      id: `DriveLeft${newIdx}`,
      label: `Drive Left ${newIdx} Outer OR Drive Left ${newIdx}`,
      color: getConfigColor(`DriveLeft${newIdx}`),
    })
    newTractorAxles.push({
      id: `DriveLeft${newIdx}Inner`,
      label: `Drive Left ${newIdx} Inner`,
      color: getConfigColor(`DriveLeft${newIdx}Inner`),
    })
    newTractorAxles.push({
      id: `DriveRight${newIdx}`,
      label: `Drive Right ${newIdx} Outer OR Drive Right ${newIdx}`,
      color: getConfigColor(`DriveRight${newIdx}`),
    })
    newTractorAxles.push({
      id: `DriveRight${newIdx}Inner`,
      label: `Drive Right ${newIdx} Inner`,
      color: getConfigColor(`DriveRight${newIdx}Inner`),
    })

    newTrailerAxles.push({
      id: `TrailerLeft${newIdx}`,
      label: `Trailer Left ${newIdx} Outer OR Trailer Left ${newIdx}`,
      color: getConfigColor(`TrailerLeft${newIdx}`),
    })
    newTrailerAxles.push({
      id: `TrailerLeft${newIdx}Inner`,
      label: `Trailer Left ${newIdx} Inner`,
      color: getConfigColor(`TrailerLeft${newIdx}Inner`),
    })
    newTrailerAxles.push({
      id: `TrailerRight${newIdx}`,
      label: `Trailer Right ${newIdx} Outer OR Trailer Right ${newIdx}`,
      color: getConfigColor(`TrailerRight${newIdx}`),
    })
    newTrailerAxles.push({
      id: `TrailerRight${newIdx}Inner`,
      label: `Trailer Right ${newIdx} Inner`,
      color: getConfigColor(`TrailerRight${newIdx}Inner`),
    })

    const newWheelColors = [...newTractorAxles, ...newTrailerAxles]

    setColors(newWheelColors)
    onAddAxles(newWheelColors)
  }

  const removeAxlesHandler = () => {
    if (colors.length <= 6) {
      return
    }

    const { tractor, trailer } = getAxlesColors(colors)

    if (tractor.length > 6) {
      tractor.splice(tractor.length - 4)
    }

    if (trailer.length > 4) {
      trailer.splice(trailer.length - 4)
    }

    const newColors = [...tractor, ...trailer]

    // setting last available color if axle was removed and selected is deleted.
    if (newColors.length < current) {
      setCurrent(newColors.length - 1)
    }

    setColors(newColors)
    onRemoveAxles(newColors)
  }

  return (
    <RivataModule title={title} width={6} locale={locale}>
      {current > -1 ? (
        <Row
          xs='1'
          sm='2'
          className='palette__wrapper'
          style={{ minHeight: showControlButtons ? '450px' : 'auto' }}
        >
          <>
            <Col className='d-flex justify-content-around'>
              <div className='color-picker__wrapper'>
                <SketchPicker
                  className='mt-1 mb-1'
                  color={colors[current].color}
                  onChangeComplete={onColorChange}
                />
                {showControlButtons ? (
                  <div className='mt-4'>
                    <h6>Add or Remove axles</h6>
                    <Button
                      className='whitelabel-add-axle__btn'
                      onClick={removeAxlesHandler}
                      disabled={colors.length < 3 ? true : false}
                    >
                      -
                    </Button>
                    <Button
                      className='whitelabel-add-axle__btn'
                      onClick={addAxlesHandler}
                    >
                      +
                    </Button>
                  </div>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className='d-flex flex-column h-100 mt-3 mt-sm-0'>
                {colors.map((c, i) => (
                  <div key={c.id} className='d-inline-flex my-1'>
                    <div
                      style={{ backgroundColor: c.color }}
                      className='p-3 w-25 mr-3 border shadow'
                    />
                    <button
                      type='button'
                      id={`color-button-${c.id}`}
                      className='btn btn-secondary p-3 flex w-75'
                      onClick={() => setCurrent(i)}
                    >
                      {c.label}
                    </button>
                    {locale[`ColorTooltip${c.id}`] && (
                      <UncontrolledTooltip
                        hideArrow={true}
                        placement='left'
                        target={`color-button-${c.id}`}
                        trigger='hover'
                      >
                        {locale[`ColorTooltip${c.id}`]}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </>
        </Row>
      ) : (
        <Alert color='warning'>No Colors</Alert>
      )}
    </RivataModule>
  )
}

export default ColorPicker
