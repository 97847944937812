import React, { useState } from 'react'
import Button from '../Button'
import ClickAwayListener from '../ClickAwayListener'
import { MenuItemType } from '../Menu/MenuItem'
import MenuItemsHelper from '../Menu/MenuItemsHelper'
import StatelessMenu from '../Menu/StatelessMenu'

type Props = {
  items: MenuItemType[]
  buttonLabel: string
  containerClassName?: string
  onItemsChange?: (items: MenuItemType[]) => void
}

const ControlledHealthStatusFilter: React.FC<Props> = (props) => {
  const {
    items,
    buttonLabel,
    containerClassName,
    onItemsChange = () => {},
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const isButtonSelected = items.some((i) => i.checked)

  return (
    <div className={containerClassName}>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Button
          btnType={isButtonSelected ? 'btn__primary' : 'btn__default'}
          onClick={(e) => {
            e.preventDefault()
            setIsOpen(true)
          }}
        >
          {buttonLabel}
        </Button>

        {isOpen && (
          <StatelessMenu
            items={items}
            menuClassName={`item-selector__menu`}
            onFinishSelection={() => setIsOpen(false)}
            onItemChange={(item) => {
              const newItems = MenuItemsHelper.copyDeep(items)
              MenuItemsHelper.handleItemChange(newItems, item)
              onItemsChange(newItems)
            }}
            onSubMenuToggle={(item) => {
              const newItems = MenuItemsHelper.copyDeep(items)
              MenuItemsHelper.handleSubMenuToggle(newItems, item)
              onItemsChange(newItems)
            }}
            onReset={() => {
              const newItems = MenuItemsHelper.copyDeep(items)
              MenuItemsHelper.resetSelection(newItems)
              onItemsChange(newItems)
            }}
          />
        )}
      </ClickAwayListener>
    </div>
  )
}

export default ControlledHealthStatusFilter
