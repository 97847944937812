import React from 'react'
import { useField, Field } from 'formik'
import { Col, Input, FormFeedback, FormGroup, Label } from 'reactstrap'

interface Props {
    className?: string
    md?: number | string
    type?: string
    label?: string
    name: string
    disabled?: boolean
    minHeight?: number
    step?: string
    children?: React.ReactNode
}

const RivataFormField: React.FC<Props> = ({
    className,
    md = "6",
    type = "text",
    children,
    label = null,
    minHeight = 75,
    ...props
}) => {
    const [field, meta] = useField(props.name)

    return (
        <Col md={md} className={className} style={{ minHeight: `${minHeight}px` }}>
            {label &&  <Label>{label}</Label>}
            <FormGroup className="mb-0">
                <Input
                    type={type}
                    {...field}
                    {...props}
                    tag={Field}
                    invalid={!!(meta.touched && meta.error)}
                >
                    {children}
                </Input>
                <FormFeedback>{meta.error}</FormFeedback>
            </FormGroup>
        </Col>
    )
}

export default RivataFormField