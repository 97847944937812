import baseApi from "./BaseApi"

class ConfigurationApi {
    getDataProcessingConfig = () => baseApi.get(`rivata/data_processing_configuration`, false)

    putDataProcessingConfig = (body: IDataProcessingConfig) => {
        return baseApi.put("rivata/data_processing_configuration", body)
    }
}

const configurationApi = new ConfigurationApi()

export default configurationApi