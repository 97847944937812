import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/localStorageUtils'

type column = {
  label: string
  id: string
  [key: string]: unknown
}

type row = {
  columns: Array<{
    columnId: string
    [key: string]: unknown
  }>
  [key: string]: unknown
}

type columnsSelectorProps = {
  showDropdown: boolean
  defaultVisible?: Array<string>
  alwaysVisible?: Array<string>
  localeStorageKey?: string
}

export type columnsSelectorCheckboxProps = {
  columnId: string
  label: string
  onClick: () => void
  disabled: boolean
  checked: boolean
}

const useColumnsSelector = (
  columns: Array<column>,
  rows: Array<row>,
  userName: string,
  columnsSelectorProps?: columnsSelectorProps,
) => {
  const [columnIdsToShow, setColumnsIdsToShow] = useState(
    columnsSelectorProps?.defaultVisible
      ? columnsSelectorProps.defaultVisible
      : columns.map((column) => column.id),
  )
  const [columnsSort, setColumnsSort] = useState(
    columnsSelectorProps?.defaultVisible
      ? columns
          .filter(
            (column) =>
              columnsSelectorProps.defaultVisible?.includes(column.id),
          )
          .map((column) => column.id)
      : columns.map((column) => column.id),
  )

  useEffect(() => {
    setColumnsIdsToShow(() => {
      if (columnsSelectorProps?.localeStorageKey) {
        const userKey = `${columnsSelectorProps.localeStorageKey}.${userName}`
        const localColumns = getLocalStorageItem(userKey)?.split(',')

        if (localColumns) return localColumns
        else {
          //fall back to initialy saved columns
          const localColumns = localStorage.getItem(
            columnsSelectorProps.localeStorageKey,
          )

          if (localColumns) {
            setLocalStorageItem(userKey, localColumns)
            removeLocalStorageItem(columnsSelectorProps.localeStorageKey)
            return localColumns?.split(',')
          }
        }
      }

      if (columnsSelectorProps?.defaultVisible)
        return columnsSelectorProps.defaultVisible
      return columns.map((column) => column.id)
    })
    setColumnsSort(() => {
      if (columnsSelectorProps?.localeStorageKey) {
        const orderKey = `${columnsSelectorProps.localeStorageKey}.order`
        const userKey = `${orderKey}.${userName}`
        const localColumns = getLocalStorageItem(userKey)?.split(',')

        if (localColumns) return localColumns
        else {
          //fall back to initialy saved columns order
          const localColumns = localStorage.getItem(orderKey)

          if (localColumns) {
            setLocalStorageItem(userKey, localColumns)
            removeLocalStorageItem(orderKey)
            return localColumns?.split(',')
          }
        }
      }

      return []
    })
  }, [columnsSelectorProps, columns, userName])

  const onCheckboxClick = useCallback(
    (columnId: any) => {
      setColumnsIdsToShow((columnIdsToShow) => {
        const res = [...columnIdsToShow]
        const idx = res.findIndex((id) => id === columnId)

        if (idx >= 0) res.splice(idx, 1)
        else res.push(columnId)

        if (columnsSelectorProps?.localeStorageKey) {
          setLocalStorageItem(
            `${columnsSelectorProps.localeStorageKey}.${userName}`,
            res.join(','),
          )
          removeLocalStorageItem(
            `${columnsSelectorProps.localeStorageKey}.order.${userName}`,
          )
        }

        return res
      })
      setColumnsSort([])
    },
    [setColumnsIdsToShow, columnsSelectorProps, userName],
  )

  const onSetDefault = useCallback(() => {
    const defaultColumnIds = columnsSelectorProps?.defaultVisible
      ? columnsSelectorProps.defaultVisible
      : columns.map((column) => column.id)

    setColumnsIdsToShow(defaultColumnIds)

    setColumnsSort([])
    if (columnsSelectorProps?.localeStorageKey) {
      removeLocalStorageItem(
        `${columnsSelectorProps.localeStorageKey}.${userName}`,
      )
      removeLocalStorageItem(
        `${columnsSelectorProps.localeStorageKey}.order.${userName}`,
      )
    }
  }, [columns, columnsSelectorProps, userName])

  const columnsModified = useMemo(() => {
    if (!columnIdsToShow) return columns
    const colsToShow = columns.filter(
      (column) =>
        columnIdsToShow.includes(column.id) ||
        columnsSelectorProps?.alwaysVisible?.includes(column.id),
    )
    const colsFinal: any[] = []
    if (columnsSort && columnsSort.length > 0) {
      columnsSort.forEach((id) => {
        const col = columns.find((c) => c.id === id)
        if (col) {
          colsFinal.push(col)
        }
      })
      //in case if we add new columns, but already have columns sort order saved, let's add those columns
      colsToShow.forEach((col) => {
        if (!columnsSort.includes(col.id)) {
          colsFinal.push(col)
        }
      })
    } else {
      colsToShow.forEach((col) => colsFinal.push(col))
    }
    return colsFinal
  }, [columnIdsToShow, columns, columnsSelectorProps, columnsSort])

  const rowsModified = useMemo(() => {
    if (!columnIdsToShow) return rows
    const rowsToReturn = rows.map((row) => ({
      ...row,
      columns: row.columns.filter(
        (column) =>
          columnIdsToShow.includes(column.columnId) ||
          columnsSelectorProps?.alwaysVisible?.includes(column.columnId),
      ),
    })) as Array<row>
    if (columnsSort && columnsSort.length > 0) {
      rowsToReturn.forEach((row) => {
        const cols: any[] = []
        columnsSort.forEach((id) => {
          const col = row.columns.find((c) => c.columnId === id)
          if (col) {
            cols.push(col)
          }
        })
        //in case if we add new columns, but already have columns sort order saved, let's add those columns
        row.columns.forEach((col) => {
          if (!columnsSort.includes(col.columnId)) {
            cols.push(col)
          }
        })
        row.columns = cols
      })
    }
    return rowsToReturn
  }, [columnIdsToShow, rows, columnsSelectorProps, columnsSort])

  const checkboxesProps = useMemo(() => {
    return columns.map((column) => {
      const onClick = () => onCheckboxClick(column.id)
      const disabled = !!columnsSelectorProps?.alwaysVisible?.includes(
        column.id,
      )
      const checked = disabled || columnIdsToShow.includes(column.id)

      return {
        columnId: column.id,
        label: column.label,
        onClick,
        disabled,
        checked,
      } as columnsSelectorCheckboxProps
    })
  }, [columns, onCheckboxClick, columnsSelectorProps, columnIdsToShow])

  const setColumnsOrder = useCallback(
    (data: any[]) => {
      if (data && data.length > 0) {
        const columnsOrder = data.map((d: any) => {
          return d.id
        })
        setColumnsSort(columnsOrder)
        if (columnsSelectorProps?.localeStorageKey) {
          setLocalStorageItem(
            `${columnsSelectorProps.localeStorageKey}.order.${userName}`,
            columnsOrder.join(','),
          )
        }
      }
    },
    [columnsSelectorProps, userName],
  )
  return [
    columnsModified,
    rowsModified,
    checkboxesProps,
    onSetDefault,
    setColumnsOrder,
  ]
}

export default useColumnsSelector
