import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import DropdownItemSelector from '../DropdownItemSelector'
import { MenuItemType } from '../Menu/MenuItem'

import { sensorStatusItems } from './items'

interface Props {
  onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
  className?: string
  disabled?: boolean
  readonly?: boolean
  clearSelectionOnReset?: boolean
  id?: string
  menuClassName?: string
}

const SensorPositionFilter = forwardRef(
  (
    {
      onDropdownItemSelect,
      className,
      disabled,
      readonly,
      clearSelectionOnReset,
      id,
      menuClassName,
    }: Props,
    ref,
  ) => {
    const [allItems, setAllItems] = useState(sensorStatusItems)
    const [selectedItems, setSelectedItems] = useState(
      [] as Array<MenuItemType>,
    )

    const onItemSelect = useCallback(
      (id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(
          (el) => el.checked && !el.id.startsWith('#'),
        )
        if (onDropdownItemSelect) onDropdownItemSelect(id, checked)
        setSelectedItems(checked)
      },
      [onDropdownItemSelect],
    )

    useImperativeHandle(ref, () => ({
      clearSelection() {
        setSelectedItems([])
        setAllItems(
          allItems.map((c) => {
            return {
              ...c,
              checked: false,
            }
          }),
        )
      },
    }))

    const label = useMemo(() => {
      return (
        'Position' +
        (selectedItems?.length > 0 ? `: ${selectedItems?.length} selected` : '')
      )
    }, [selectedItems])

    return (
      <DropdownItemSelector
        id={id || 'position_type'}
        filterClassName={`mr-2 ${className ?? ''}`}
        filterBtnLabel={label}
        items={allItems}
        onItemsChange={onItemSelect}
        disabled={disabled ? disabled : false}
        clearSelectionOnReset={clearSelectionOnReset}
        readOnly={readonly}
        menuClassName={menuClassName}
      />
    )
  },
)

export default React.memo(SensorPositionFilter)
