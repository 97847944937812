import React from 'react'
import DataGrid, { Column } from 'react-data-grid'
import './style.scss'

export interface IColumn extends Column<IRow> {
  name: string // name and key must not match!!! else some properties can not work
}

export interface IRow {
  [key: string]: any
}

interface IRivataGridProps {
  columns: Array<IColumn>
  rows: Array<IRow>
  height?: string
  rowHeight?: number
  allowHorizontalScroll?: boolean
}

const RivataGrid: React.FC<IRivataGridProps> = ({
  columns,
  rows,
  height,
  rowHeight = 35,
  allowHorizontalScroll = false,
}) => {
  // height calculations based on rows and columns count or just pass required height
  const actualHeight = height
    ? height
    : rows.length > 22
    ? '800px'
    : `${rows.length * rowHeight + 37}px` // 35 + 2 = headerRowHeight --- to remove vertical scroll

  return (
    <DataGrid
      className='rdg-light rivata-grid' // light color if dark theme set
      columns={columns}
      rows={rows}
      defaultColumnOptions={{
        sortable: false,
        resizable: true,
      }}
      style={{
        height: actualHeight,
        overflowX: allowHorizontalScroll ? undefined : 'hidden',
      }}
      headerRowHeight={35}
      rowHeight={rowHeight}
    />
  )
}

export default RivataGrid
