import React from "react"

import ConfirmModal from "../../../../components/ConfirmModal"

import { IUnverifiedUsers } from "../../index"

import "./style.scss"


const ListItem: React.FC<{ label: string }> = ({ label }) => <li>{label}</li>


interface Props {
    unverifiedUsers: IUnverifiedUsers
    setUnverifiedUsers: (value: React.SetStateAction<IUnverifiedUsers | null>) => void
    proceedRequest: () => Promise<void>,
    header: string,
    message: string
}

const UnverifiedUsersModal: React.FC<Props> = ({
    unverifiedUsers,
    setUnverifiedUsers,
    proceedRequest,
    header,
    message
}) => {
    const formConfig = [
        { id: 1, label: "Email", list: unverifiedUsers.unverifiedUsersList.email },
        { id: 2, label: "Phone Number", list: unverifiedUsers.unverifiedUsersList.sms },
    ]

    return (
        <ConfirmModal
            header={header}
            open={true}
            onClose={() => setUnverifiedUsers(null)}
            modalButtons={[{
                id: 1, 
                label: "OK", 
                color: "primary", 
                onClick:() => {
                    setUnverifiedUsers(prev => prev ? ({ ...prev, aknowledged: true }) : prev)
                    proceedRequest()
                }
            }]}
        >
            <h5>{message}</h5>
            <div>
                {formConfig.map(item => item.list.length > 0 && (
                    <div key={item.id}>
                        <div className="text-center p-3">
                            <h6>{item.label}</h6>
                        </div>
                        <ul className="unverified-modal-list">
                            {item.list.map(el => <ListItem key={el.id} label={el.username + (item.id === 1 ? ` (${el.email ? el.email : ""})` : ` (${el.phone_number ? el.phone_number : ""})`)} />)}
                        </ul>
                    </div>
                ))}
            </div>
        </ConfirmModal>
    )
}

export default UnverifiedUsersModal