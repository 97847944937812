import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Input } from 'reactstrap'
import { debounce } from 'lodash'
import RivataMenu from '../RivataMenu'
import { IBackendSearchEndPointConfig } from './constants'
import { mapCustomerIdsToNames } from '../../utils/utils'
import { useTypedSelector } from '../../hooks/useTypedSelector'

type Props = {
  endPointConfig: IBackendSearchEndPointConfig
  onSelect: (item: any) => void
  placeholder?: string
  width?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  displayNotFound?: boolean
  disabled?: boolean
  customerIds?: string
}

const BackendSearchInput: React.FC<Props> = ({
  endPointConfig,
  onSelect,
  placeholder = '',
  width = '400px',
  value,
  onChange,
  displayNotFound = true,
  disabled = false,
  customerIds,
}) => {
  const { customers } = useTypedSelector((state) => ({
    customers: state.common.customers.data,
  }))

  const inputRef = useRef<any>()

  const [searchValue, setSearchlValue] = useState('')
  const [foundItems, setFoundItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const debouncedSearch = useMemo(() => {
    return debounce(async (value: string) => {
      if (value)
        await endPointConfig
          .search(value.replaceAll('_', '\\_'), customerIds)
          .then((res) => {
            if (
              (endPointConfig.lablelKey === 'customer' ||
                endPointConfig.subLabelKey === 'customer') &&
              res[0]?.customer_id
            )
              res = mapCustomerIdsToNames(res, customers)

            setFoundItems(res)
          })
      setIsLoading(false)
    }, 300)
  }, [endPointConfig, customers, customerIds])

  const onInputFocus = useCallback(() => setIsOpen(true), [])

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.includes('%')) return

      if (onChange) return onChange(e)

      setIsLoading(true)
      setFoundItems([])
      setSearchlValue(e.target.value)
      debouncedSearch(e.target.value)
    },
    [onChange, debouncedSearch],
  )

  const onMenuItemClick = useCallback(
    (el: any) => {
      setIsOpen(false)

      if (!onChange) {
        setSearchlValue('')
        setFoundItems([])
      }

      onSelect(el)
    },
    [onSelect, onChange],
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target))
        setIsOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if (value === undefined) return

    setIsLoading(true)
    setFoundItems([])
    setSearchlValue(value)
    debouncedSearch(value)
  }, [value, debouncedSearch])

  return (
    <div ref={inputRef} style={{ width }}>
      <Input
        type='text'
        placeholder={placeholder}
        value={searchValue}
        onChange={onInputChange}
        onFocus={onInputFocus}
        disabled={disabled}
      />

      {isOpen && (
        <RivataMenu
          data={foundItems}
          displayLoading={isLoading}
          displayNotFound={
            !foundItems.length && !!searchValue && displayNotFound
          }
          width='inherit'
          labelKey={endPointConfig.lablelKey}
          subLabelKey={endPointConfig.subLabelKey}
          onClick={onMenuItemClick}
          isUserItem={endPointConfig.isUserItem}
        />
      )}
    </div>
  )
}

export default BackendSearchInput
