import React, { useMemo, useContext } from 'react'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import { composeLineChartData, composeAnnotations } from './utils'
import LineChartGroup from '../../components/LineChartGroup'
import StatusAlert from '../../components/StatusAlert'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { AssetDetailsContext } from '../../pages/AssetDetails'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

const LocationChart = ({ width }) => {
  const {
    gps: { isLoading, data, status },
  } = useTypedSelector((state) => ({
    gps: state.assetDetails.gps,
  }))

  const {
    locale,
    unitsOfMeasurementConfig,
    chartsXAxisLabel,
    healthColors,
    gpsColors,
    timestamp,
  } = useContext(AssetDetailsContext)

  const chartData = useMemo(() => {
    if (data && data.length && gpsColors) {
      return composeLineChartData(data, unitsOfMeasurementConfig, gpsColors)
    }
    return null
  }, [data, unitsOfMeasurementConfig, gpsColors])

  const chartsConfig = useMemo(() => {
    if (!chartData) return []

    const speedlabel = `Speed (${
      unitsOfMeasurementConfig?.speed === UnitsOfMeasurementConfig.speed.mph
        ? 'mph'
        : 'kmh'
    })`
    const elevationLabel = `Elevation (${
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.miles
        ? 'ft'
        : 'm'
    })`

    return [
      {
        id: 'speed',
        label: speedlabel,
        yAxisLabel: speedlabel,
        data: chartData.speed,
        height: 300,
        displayX: false,
        annotations: composeAnnotations('speed', +timestamp, healthColors),
      },
      {
        id: 'elevation',
        label: elevationLabel,
        yAxisLabel: elevationLabel,
        data: chartData.elevation,
        height: 300,
        displayX: true,
        annotations: composeAnnotations('elevation', +timestamp, healthColors),
      },
    ]
  }, [chartData, unitsOfMeasurementConfig, timestamp, healthColors])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title='Speed, Elevation'
      width={width}
      locale={locale}
      error={status}
      collapsible
    >
      {isLoading ? (
        <RivataLoader />
      ) : data && data.length ? (
        <LineChartGroup
          chartsXAxisLabel={chartsXAxisLabel}
          chartsData={chartsConfig}
        />
      ) : (
        <StatusAlert customText='No Data' color='success' />
      )}
    </RivataModule>
  )
}

export default LocationChart
