
import { convertDataEpochToDate, getClearLabel } from "../../../utils";
import { IRow } from "../../../components/RivataGrid";

export const failedNotificationHistoryReportRows = (rows: Array<IRow>) =>{
    return rows.map((row) => {
        row.parent_customer = row.parent_customer ? row.parent_customer : "---";
        row.customer = row.customer ? row.customer : "---";
        row.notification_type = row.notification_type === "PUSH" ? "Driver App" : row.notification_type === "SMS" ? "SMS" : getClearLabel(row.notification_type);
        row.warning_type = row.warning_type ? getClearLabel(row.warning_type) : "---";
        row.warning_subtype = row.warning_subtype ? getClearLabel(row.warning_subtype) : "---";
        row.warning_timestamp = row.warning_timestamp ? convertDataEpochToDate(row.warning_timestamp, null, null, true) : "---";
        row.notification_timestamp = row.notification_timestamp ? convertDataEpochToDate(row.notification_timestamp, null, null, true) : "---";
        row.asset_name = row.asset_name ? getClearLabel(row.asset_name) : "---";
        row.position = row.position ? getClearLabel(row.position) : "---";
        row.recipient = row.recipient ? row.recipient : "---";
        return row;
    });
}
