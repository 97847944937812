import Dashboard from '../pages/Dashboard'
import AssetDetails from '../pages/AssetDetails'
import Settings from '../pages/Settings'
import Support from '../pages/Support'
import Reports from '../pages/Reports'
import DocumentPage from '../pages/DocumentPage'
import Geofences from '../pages/Geofences'
import GeofenceDetails from '../pages/GeofenceDetails'
import CargoTags from '../pages/CargoTags'
import TagLocations from '../pages/TagLocations'
import TagLocationDetails from '../pages/TagLocationDetails'

// admin
import Provision from '../pages/Provision'
import TpmsProfiles from '../pages/TpmsProfiles'
import AssetGroups from '../pages/AssetGroups'
import CalibrateAssets from '../pages/CalibrateAssets'
import ManageGeofences from '../pages/ManageGeofences'
import TagAssetDetails from '../pages/TagAssetDetails'
import ManageNotifications from '../pages/ManageNotifications'
import GeofenceGroups from '../pages/GeofenceGroups'
import GeofenceGroupsDetails from '../pages/GeofenceGroupsDetails'

// super admin
import WhiteLabel from '../pages/WhiteLabel'
import Thresholds from '../pages/Thresholds'
import AssetAdminInfo from '../pages/AssetAdminInfo'
import ManageSupport from '../pages/ManageSupport'
import Admin from '../pages/Admin'

import { Pages, Path } from '../enums'
import UIComponents from '../pages/UIComponents'
import AssetGroupsDetails from '../pages/AssetGroupsDetails'
import ActionableItems from '../pages/ActionableItems'

interface IStandardRoute {
  path: Path
  name: Pages
  component: (() => JSX.Element) | React.FC<any>
  exact?: boolean
}

interface IAdminRoute {
  path: Path
  name: Pages
  component: (() => JSX.Element) | React.FC<any>
  checkForSuperAdmin?: boolean
  exact?: boolean
}

export const standardRoutes: Array<IStandardRoute> = [
  {
    path: Path.AssetDetails,
    name: Pages.AssetDetails,
    component: AssetDetails,
  },
  { path: Path.Settings, name: Pages.Settings, component: Settings },
  { path: Path.Support, name: Pages.Support, component: Support },
  { path: Path.Document, name: Pages.Document, component: DocumentPage },
  { path: Path.Reports, name: Pages.Reports, component: Reports },
  { path: Path.CargoTags, name: Pages.CargoTags, component: CargoTags },
  { path: Path.Geofences, name: Pages.Geofences, component: Geofences },
  {
    path: Path.GeofencesDetails,
    name: Pages.GeofencesDetails,
    component: GeofenceDetails,
  },
  {
    path: Path.TagLocations,
    name: Pages.TagLocations,
    component: TagLocations,
  },
  {
    path: Path.TagLocationDetails,
    name: Pages.TagLocationDetails,
    component: TagLocationDetails,
  },
  { path: Path.Dashboard, name: Pages.Dashboard, component: Dashboard },
  { path: Path.Root, name: Pages.Dashboard, component: Dashboard },
]

const adminRoutes: Array<IAdminRoute> = [
  { path: Path.Provision, name: Pages.Provision, component: Provision },
  {
    path: Path.TpmsProfiles,
    name: Pages.TpmsProfiles,
    component: TpmsProfiles,
  },
  {
    path: Path.AssetGroups,
    name: Pages.AssetGroups,
    component: AssetGroups,
    exact: true,
  },
  {
    path: Path.AssetGroupsDetails,
    name: Pages.AssetGroupsDetails,
    component: AssetGroupsDetails,
  },
  {
    path: Path.ManageNotifications,
    name: Pages.ManageNotifications,
    component: ManageNotifications,
  },
  {
    path: Path.CalibrateAssets,
    name: Pages.CalibrateAssets,
    component: CalibrateAssets,
  },
  {
    path: Path.TagAssetDetails,
    name: Pages.TagAssetDetails,
    component: TagAssetDetails,
  },
  {
    path: Path.ManageGeofences,
    name: Pages.ManageGeofences,
    component: ManageGeofences,
  },
  {
    path: Path.GeofenceGroups,
    name: Pages.GeofenceGroups,
    component: GeofenceGroups,
    exact: true,
  },
  {
    path: Path.GeofenceGroupsDetails,
    name: Pages.GeofenceGroupsDetails,
    component: GeofenceGroupsDetails,
    exact: true,
  },
  {
    path: Path.WhiteLabel,
    name: Pages.WhiteLabel,
    component: WhiteLabel,
    checkForSuperAdmin: false,
  },
  {
    path: Path.ActionableItems,
    name: Pages.ActionableItems,
    component: ActionableItems,
    exact: true,
  },
]

const superAdminRoutes: Array<IAdminRoute> = [
  {
    path: Path.Thresholds,
    name: Pages.Thresholds,
    component: Thresholds,
    checkForSuperAdmin: true,
  },
  {
    path: Path.AssetAdminInfo,
    name: Pages.AssetAdminInfo,
    component: AssetAdminInfo,
    checkForSuperAdmin: true,
  },
  {
    path: Path.ManageSupport,
    name: Pages.ManageSupport,
    component: ManageSupport,
    checkForSuperAdmin: true,
  },
  {
    path: Path.Admin,
    name: Pages.Admin,
    component: Admin,
    checkForSuperAdmin: true,
  },
  {
    path: Path.UIComponents,
    name: Pages.UIComponents,
    component: UIComponents,
    checkForSuperAdmin: true,
  },
]

export const publicRoutes: Array<IStandardRoute> = [
  {
    path: Path.PublicDocument,
    name: Pages.PublicDocument,
    component: DocumentPage,
  },
]

export const allAdminRoutes = [...adminRoutes, ...superAdminRoutes]
