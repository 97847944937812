import React, { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { SearchIcon } from "../Icon"
import SearchInput from "../SearchInput"

interface Props {
    wrapperStyle?: CSSProperties
    onChange: (id: string, val: string) => void
    wrapperClassName?: string
    initialValue?: string
}

const UsernameEmailFilter = forwardRef(({ wrapperStyle, onChange, wrapperClassName,initialValue }: Props, ref) => {
    const [value, setValue] = useState<string>("")
    const onSarchByUsernameEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange("user_username_email", e.target.value)
        }
        setValue(e.target.value)
    }, [onChange])

    useImperativeHandle(ref, () => ({
        clearValue() {
            setValue("")
        }
    }))

    useEffect(()=>{
        if (initialValue)
            setValue(initialValue)
    }, [initialValue])

    return (
        <SearchInput
            placeholder='Search by Username or Email'
            icon={<SearchIcon width={20} height={20} color='black' />}
            wrapperStyle={wrapperStyle}
            value={value}
            onChange={onSarchByUsernameEmail}
            wrapperClassName={wrapperClassName}
        />)
})

export default React.memo(UsernameEmailFilter)