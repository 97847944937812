import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import PdfPreview from './PdfPreview'
import PDFColumnsOptions from './PDFColumnsOptions'
import { debounce } from 'lodash'

export type pdfColumnOption = {
  name: string
  checked: boolean
  width: number | ''
}

type Props = {
  data: Array<Array<string>>
  filename: string
  disabled?: boolean
}

const PdfButton: React.FC<Props> = ({ data, filename, disabled }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [breakRows, setBreakRows] = useState(false)
  const [orientation, setOrientation] = useState<'landscape' | 'portrait'>(
    'landscape',
  )

  const [columns, setColumns] = useState<Array<pdfColumnOption>>([])
  const [debouncedColumns, setDebouncedColumns] = useState<
    Array<pdfColumnOption>
  >([])

  const modalToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), [])

  useEffect(() => {
    setColumns(
      data[0].map((name) => ({
        name,
        checked: true,
        width: '',
      })),
    )
  }, [data])

  const debouncedColumnsSetter = useMemo(
    () => debounce(setDebouncedColumns, 300),
    [],
  )

  useEffect(() => {
    debouncedColumnsSetter(columns)
  }, [debouncedColumnsSetter, columns])

  const onColumnCheck = useCallback((name: string) => {
    setColumns((columns) => {
      const res = [...columns]

      const col = res.find((col) => col.name === name)

      if (col) col.checked = !col.checked

      return res
    })
  }, [])

  const onColumnWidthChange = useCallback((value: string, name: string) => {
    setColumns((columns) => {
      const res = [...columns]

      const col = res.find((col) => col.name === name)

      if (col) col.width = +value > 0 ? +value : ''

      return res
    })
  }, [])

  const onColumnsReset = useCallback(() => {
    setColumns((columns) => {
      const res = [...columns]

      res.forEach((col) => {
        col.checked = true
        col.width = ''
      })

      return res
    })
  }, [])

  const pdf = useMemo(() => {
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    const checkedColumns = data[0].flatMap((v, id) => {
      if (debouncedColumns.find((col) => col.name === v)?.checked) return [id]

      return []
    })

    const columnStyles = debouncedColumns
      .filter((col) => col.checked)
      .reduce((styles, col, id) => {
        return {
          ...styles,
          [id]: { cellWidth: col.width === 0 ? 'auto' : col.width },
        }
      }, {})

    autoTable(doc, {
      head: [data[0].filter((v, id) => checkedColumns.includes(id))],
      body: data
        .slice(1)
        .map((row) => row.filter((v, id) => checkedColumns.includes(id))),
      theme: 'grid',
      styles: {
        cellPadding: 0.5,
        fontSize: 8,
      },
      columnStyles,
      horizontalPageBreak: breakRows,
      horizontalPageBreakBehaviour: 'immediately',
    })

    return doc
  }, [data, debouncedColumns, breakRows, orientation])

  const pdfPreviewString = useMemo(() => {
    return pdf.output('dataurlstring', {
      filename: `${filename}.pdf`,
    })
  }, [pdf, filename])

  return (
    <>
      <DropdownItem disabled={disabled} onClick={modalToggle}>
        PDF
      </DropdownItem>

      {!disabled && isOpen && (
        <Modal isOpen={true} toggle={modalToggle} size='xl'>
          <ModalHeader toggle={modalToggle}>PDF Download</ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-between mb-2'>
              <div className='d-flex'>
                <PDFColumnsOptions
                  columns={columns}
                  onColumnCheck={onColumnCheck}
                  onColumnWidthChange={onColumnWidthChange}
                  onColumnsReset={onColumnsReset}
                />

                <UncontrolledDropdown>
                  <DropdownToggle caret color='white' className='ml-3'>
                    Page Orientation
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setOrientation('portrait')
                      }}
                    >
                      Portrait
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setOrientation('landscape')
                      }}
                    >
                      Landscape
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Input
                  type='checkbox'
                  id='break-rows-checkbox'
                  className='position-relative mt-0 ml-4'
                  checked={breakRows}
                  onChange={() => {
                    setBreakRows((breakRows) => !breakRows)
                  }}
                />
                <label
                  htmlFor='break-rows-checkbox'
                  className='ml-2 mb-0 align-self-center'
                >
                  Allow breaking rows in separate pages
                </label>
              </div>

              <Button
                color='primary'
                onClick={() => {
                  pdf.save(`${filename}.pdf`)
                  modalToggle()
                }}
              >
                Download
              </Button>
            </div>
            <PdfPreview file={pdfPreviewString} />
          </ModalBody>
          <ModalFooter>
            <Button
              color='secondary'
              onClick={() => {
                modalToggle()
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default PdfButton
