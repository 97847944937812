import { useMemo } from 'react'

import {
  composeDynamicActionsFormatter,
  IActionColumnConfig,
} from '../../components/RivataGrid/cellFormatter'

import { IColumn, IRow } from '../../components/RivataGrid'

import { getClearLabel } from '../../utils'
import { makeScrollToY } from '../../utils/utils'
// import { ManageProfileUsers } from "./types"
import { NotificationSystemProfileNames } from '../../constants/constants'
import { NoDataLabels } from '../../enums'
import { renderSortableHeader } from '../../components/RivataGrid/headerFormatter'

// const subscribersGridColumns = [
//     { name: "Username", key: "username", minWidth: 100 },
//     { name: "Email", key: "email", minWidth: 100 },
//     { name: "Email Verified", key: "email_verified", minWidth: 100 },
//     { name: "Phone Number Verified", key: "phone_number_verified" }
// ]

const findByKey = (
  data: Array<any>,
  key = 'id',
  searchValue: string | number,
) => {
  return data.find((el) => el[key] === searchValue) || null
}

interface IuseColumns {
  (
    data: Array<INotificationProfile>,
    // users: Array<IUserShortFormat>,
    onDelete: (id: number) => void,
    onEdit: (p: INotificationProfile | null) => void,
    customProfilesSelected: boolean,
  ): Array<IColumn>
}

export const useColumns: IuseColumns = (
  data,
  // users,
  onDelete,
  onEdit,
  customProfilesSelected,
) => {
  const columns = useMemo(() => {
    const formatterConfig: IActionColumnConfig = {
      onEdit: (id) => {
        onEdit(findByKey(data, 'id', id) || null)
        makeScrollToY(0)
      },
      tooltipTexts: {
        deleteTooltipText: 'Delete Profile',
        editTooltipText: 'Edit Profile',
      },
    }

    if (customProfilesSelected) {
      formatterConfig.onDelete = (id) => {
        onDelete(id)
        makeScrollToY(0)
      }
    }

    const colsList: Array<IColumn> = [
      // {
      //     key: 'expanded',
      //     name: '',
      //     minWidth: 30,
      //     width: 30,
      //     colSpan(args) {
      //         return args.type === 'ROW' && args.row.type === 'DETAIL' ? 7 : undefined;
      //     },
      //     cellClass(row) {
      //         return row.type === 'DETAIL'
      //             ? "p-2"
      //             : undefined;
      //     },
      //     formatter({ row, isCellSelected, onRowChange }) {
      //         if (row.type === 'DETAIL') {
      //             const subscribersList: Array<IRow> = []
      //             const profile = data.find(el => el.id === row.parentId)

      //             if (profile?.users) {
      //                 profile.users.forEach((id: number) => {
      //                     const user = users.find(el => el.id === id)

      //                     if (user) subscribersList.push({
      //                         ...user,
      //                         email_verified: user.email_verified.toString(),
      //                         phone_number_verified: user.phone_number_verified.toString(),
      //                     })
      //                 })
      //             }

      //             return (
      //                 <SubscribersGrid
      //                     columns={subscribersGridColumns}
      //                     rows={subscribersList}
      //                     isCellSelected={isCellSelected}
      //                     maxHeight={250}
      //                 />
      //             );
      //         }

      //         return (
      //             <CellDetailsExpander
      //                 id={row.id}
      //                 isCellSelected={isCellSelected}
      //                 expanded={row.expanded}
      //                 tooltipText="Show Subscribers"
      //                 onCellExpand={() => onRowChange({ ...row, expanded: !row.expanded })}
      //             />
      //         );
      //     }
      // },
      // {
      //     key: "selected",
      //     name: "",
      //     minWidth: 30,
      //     width: 30,
      //     resizable: false,
      //     formatter: ({ row, isCellSelected, onRowChange }) => (
      //         <SelectCellFormatter
      //             value={row.selected}
      //             onChange={() => {
      //                 row.selected = !row.selected

      //                 onSelectProfile(prev => {
      //                     const arr = [...prev]
      //                     const idx = arr.findIndex(el => el === row.id)

      //                     if (idx > -1) arr.splice(idx, 1)
      //                     else arr.push(row.id)

      //                     return arr
      //                 })

      //                 onRowChange(row)
      //             }}
      //             isCellSelected={isCellSelected}
      //         />
      //     )
      // },
      {
        name: 'Actions',
        key: 'actions',
        minWidth: 100,
        width: 100,
        renderCell: ({ row }: any) =>
          composeDynamicActionsFormatter({ ...formatterConfig, cellData: row }),
      },
      {
        name: 'Name',
        key: 'name',
        minWidth: 100,
        width: '20%',
        sortable: true,
        renderHeaderCell: renderSortableHeader,
      },
      {
        name: 'Email/SMS',
        key: 'notificationType',
        minWidth: 100,
        width: '7%',
        sortable: true,
        renderHeaderCell: renderSortableHeader,
      },
      {
        name: 'Warning Events',
        key: 'warningEvents',
        minWidth: 100,
        width: customProfilesSelected ? '25%' : '40%',
        sortable: true,
        renderHeaderCell: renderSortableHeader,
      },
      {
        name: 'Subscribers',
        key: 'subscribers',
        minWidth: 100,
        sortable: true,
        renderHeaderCell: renderSortableHeader,
      },
    ]

    if (customProfilesSelected)
      colsList.push({
        name: 'Exclusions',
        key: 'exclusions',
        minWidth: 100,
        width: '25%',
      })

    return colsList
  }, [data, onDelete, onEdit, customProfilesSelected])

  return columns
}

interface IuseRows {
  (
    data: Array<INotificationProfile>,
    // users: Array<IUserShortFormat>,
  ): Array<IRow>
}

export const useRows: IuseRows = (data) => {
  const rows = useMemo(() => {
    const rowsList = data.map((row) => {
      const { sms, email } = row.alert_notification_types
      let notificationType = ''
      let warningEvents: Array<string> = []

      if (email && sms) notificationType = 'Email & SMS'
      else if (email) notificationType = 'Email'
      else if (sms) notificationType = 'SMS'

      const warningKeys = Object.keys(row.profile)

      warningKeys.forEach((key) => {
        const warningTypes = row.profile[key]

        if (warningTypes.length)
          warningEvents.push(
            `${getClearLabel(key)} - ${warningTypes
              .map((w) => getClearLabel(w))
              .join(', ')}`,
          )
      })

      let exclusions = ''
      if (
        !row.exclude ||
        (!row.exclude.assets.length && !row.exclude.geofences.length)
      ) {
        exclusions = NoDataLabels.DASH
      }
      if (row.exclude?.assets.length) {
        exclusions += 'Assets: '
        exclusions += row.exclude.assets.map((asset) => asset.name).join(', ')
      }
      if (row.exclude?.geofences.length) {
        if (exclusions?.length) exclusions += '; '
        exclusions += 'Geofences: '
        exclusions += row.exclude.geofences
          .map((geofence) => geofence.name)
          .join(', ')
      }

      return {
        id: row.id,
        type: 'MASTER', // DETAIL   MASTER
        selected: false,
        name: row.name.includes('system_profile_')
          ? NotificationSystemProfileNames[row.name]
          : row.name,
        notificationType,
        warningEvents: warningEvents.join('; '),
        expanded: false,
        subscribers:
          row.users.length === 1
            ? '1 Subscriber'
            : row.users.length > 1
            ? `${row.users.length} Subscribers`
            : NoDataLabels.DASH,
        exclusions,
      }
    })

    return rowsList
  }, [data])

  return rows
}
