
export enum DocumentsTypes {
  LOADING_DOCUMENTS           = "LOADING_DOCUMENTS",
  LOADING_DOCUMENT_TYPES      = "LOADING_DOCUMENT_TYPES",
  SET_DOCUMENTS               = "SET_DOCUMENTS",
  SET_DOCUMENTS_ERROR         = "SET_DOCUMENTS_ERROR",
  SET_DOCUMENTS_TYPES         = "SET_DOCUMENTS_TYPES",
  SET_DOCUMENTS_TYPES_STATUS  = "SET_DOCUMENTS_TYPES_STATUS",
}

export interface IDocType {
  id: number, 
  key: string,
  name: string,
}

export interface DocumentsReducer {
  isLoading: boolean
  documents: {[dir: string]: IDocument[]}
  error: boolean
  types: {
    isLoading: boolean
    docTypes: IDocType[]
    status: IStatus
  }
}

interface LoadingDocuments {
  type: DocumentsTypes.LOADING_DOCUMENTS
  payload: boolean
}

interface LoadingDocumentTypes {
  type: DocumentsTypes.LOADING_DOCUMENT_TYPES
  payload: boolean
}

interface SetDocuments {
  type: DocumentsTypes.SET_DOCUMENTS
  payload: {[dir: string]: IDocument[]}
}

interface SetError {
  type: DocumentsTypes.SET_DOCUMENTS_ERROR
  payload: boolean
}

interface SetTypes {
  type: DocumentsTypes.SET_DOCUMENTS_TYPES
  payload: IDocType[]
}

interface setTypesStatus {
  type: DocumentsTypes.SET_DOCUMENTS_TYPES_STATUS
  payload: IStatus
}

export type DocumentsAction = LoadingDocuments | LoadingDocumentTypes | SetDocuments | SetError | SetTypes | setTypesStatus