import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import LostTagDaysRow from '../ReportParametersRows/LostTagDaysRow'

const LostTagsReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <LostTagDaysRow value={props.parameters.lost_tag_days} />
    </>
  )
}
export default LostTagsReportParameters
