import React, { useCallback, useEffect, useState } from 'react'
import RivataModule from '../../components/RivataModule'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { useColumns, useRows } from './hooks'
import RivataTable from '../../components/RivataTable'
import { makeScrollToY } from '../../utils/utils'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
} from 'reactstrap'
import './styles.scss'
import { NoDataLabels } from '../../enums'
import api from '../../services/api'
import { getClearLabel } from '../../utils'
import InfoModal from '../../components/InfoModal'
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'provision.assetsBulkUploadHistory.columns',
}

const AssetsBulkUploadHistoryTable: React.FC = () => {
  const [showUploadedAssets, setShowUploadedAssets] = useState<boolean>(false)
  const [showRollback, setShowRollback] = useState<boolean>(false)
  const [showRollbackInfo, setShowRollbackInfo] = useState<boolean>(false)
  const [uploadedAssets, setUploadedAssets] = useState<Array<any>>([])
  const [assetsWithHardware, setAssetsWithHardware] = useState<Array<any>>([])
  const [rollBackItem, setRollBackItem] = useState<any>(undefined)
  const {
    assetBulkUpload: { isLoading, data, offset, limit, totalCount },
    locale,
    selectedCustomersList,
  } = useTypedSelector((state) => ({
    assetBulkUpload: state.assetBulkUpload.history,
    locale: state.whitelabel.locale,
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))
  const {
    getAssetsBulkUploadHistory,
    setAssetBulkUploadHistoryLimit,
    setAssetBulkUploadHistoryOffset,
    setAssetBulkUploadHistorySortOptions,
  } = useActions()
  const loadAssetsBulkUploadHistory = useCallback(() => {
    getAssetsBulkUploadHistory()
    setAssetBulkUploadHistoryOffset(0)
    setAssetBulkUploadHistoryLimit(30)
    setAssetBulkUploadHistorySortOptions({
      column: 'end_dt',
      direction: 'desc',
    })
  }, [
    getAssetsBulkUploadHistory,
    setAssetBulkUploadHistorySortOptions,
    setAssetBulkUploadHistoryLimit,
    setAssetBulkUploadHistoryOffset,
  ])
  useEffect(() => {
    loadAssetsBulkUploadHistory()
  }, [selectedCustomersList, loadAssetsBulkUploadHistory])
  const handleShowUploadeAssets = useCallback((data: any) => {
    setUploadedAssets(data.uploaded_assets)
    setShowUploadedAssets(true)
  }, [])
  const handleShowRollback = useCallback((data: any) => {
    setRollBackItem(data)
    const getAssetsWithHardware = async () => {
      const res = await api.getAssetsHardwareByVins(
        data.uploaded_assets.map((asset: any) => {
          return asset.vin
        }),
      )
      setAssetsWithHardware(res)
      setShowRollback(true)
    }

    getAssetsWithHardware()
  }, [])
  const columns = useColumns()
  const rows = useRows(data, handleShowUploadeAssets, handleShowRollback)

  const handlePageChange = useCallback(
    (offset: number) => {
      setAssetBulkUploadHistoryOffset(offset)
      getAssetsBulkUploadHistory()
      makeScrollToY(0)
    },
    [setAssetBulkUploadHistoryOffset, getAssetsBulkUploadHistory],
  )

  const handleLimitChange = useCallback(
    (limit: number) => {
      setAssetBulkUploadHistoryLimit(limit)
      getAssetsBulkUploadHistory()
      makeScrollToY(0)
    },
    [setAssetBulkUploadHistoryLimit, getAssetsBulkUploadHistory],
  )
  const handleSortOptionsChange = useCallback(
    (options: any) => {
      setAssetBulkUploadHistorySortOptions(options)
      getAssetsBulkUploadHistory()
      makeScrollToY(0)
    },
    [setAssetBulkUploadHistorySortOptions, getAssetsBulkUploadHistory],
  )

  const handleHideUploadeAssets = useCallback((data: any) => {
    setShowUploadedAssets(false)
  }, [])
  const handleHideRollback = useCallback((data: any) => {
    setShowRollback(false)
  }, [])
  const handleConfirmBulkUploadRollback = useCallback(() => {
    const setBulkUploadStatus = async () => {
      try {
        await api.updateAssetBulkUploadStatus(
          rollBackItem.bulk_upload_id,
          'ROLLED_BACK',
        )
      } catch (err) {
        console.log(err)
      }
    }
    const deleteAssets = async () => {
      try {
        await api.deleteAssets(
          rollBackItem.uploaded_assets.map((asset: any) => {
            return asset.vin
          }),
        )
        setBulkUploadStatus()
        setShowRollback(false)
        setShowRollbackInfo(true)
        loadAssetsBulkUploadHistory()
      } catch (err) {
        console.log(err)
      }
    }

    deleteAssets()
  }, [rollBackItem, loadAssetsBulkUploadHistory])
  return (
    <>
      <RivataModule
        title='Assets Bulk Upload History'
        width={12}
        locale={locale}
        marginTop={0}
        filters={<></>}
      >
        <RivataTable
          columns={columns}
          rows={rows}
          totalCount={totalCount}
          page={offset / limit}
          showPagination={true}
          pageLimit={limit}
          isShowingLimit={true}
          onEdit={undefined}
          onDelete={undefined}
          editDisabled={false}
          deleteDisabled={undefined}
          isLoading={isLoading}
          setSortOptions={handleSortOptionsChange}
          onCustomAction={undefined}
          // @ts-ignore component expect undefined cause of default value
          columnsSelectorProps={columnsSelectorProps}
          // @ts-ignore component expect undefined cause of default value
          onPageChange={handlePageChange}
          // @ts-ignore component expect undefined cause of default value
          onSelectLimit={handleLimitChange}
        />
      </RivataModule>
      <Modal size='md' centered isOpen={showUploadedAssets}>
        <ModalHeader toggle={handleHideUploadeAssets}>
          Uploaded Assets
        </ModalHeader>
        <ModalBody>
          <div className='bulk-upload-uploaded-assets mt-3'>
            <div className='uploaded-assets-row'>
              <div className='name'>
                <b>Name</b>
              </div>
              <div className='vin'>
                <b>VIN</b>
              </div>
            </div>
            <div className='uploaded-assets-rows-wrapper'>
              {uploadedAssets.map((item: any) => {
                return (
                  <div className='uploaded-assets-row' key={item.vin}>
                    <div className='name'>{item.name || NoDataLabels.DASH}</div>
                    <div className='vin'>{item.vin}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal size='lg' isOpen={showRollback} style={{ maxWidth: '650px' }}>
        <ModalHeader toggle={handleHideRollback}>
          Roll back bulk upload
        </ModalHeader>
        <ModalBody>
          <Label>
            All assets that were uploaded - will be deleted. If you want to
            continue - click OK button.
          </Label>
          {assetsWithHardware.length > 0 && (
            <>
              <br />
              <Label>
                There are assets with assigned sensors. If you will continue -
                all sensors will be removed from assets.
              </Label>
            </>
          )}
          {assetsWithHardware.length > 0 && (
            <div className='bulk-assets-with-hardware mt-3'>
              <div className='assets-with-hardware-row'>
                <div className='name'>
                  <b>Name</b>
                </div>
                <div className='vin'>
                  <b>VIN</b>
                </div>{' '}
                <div className='mac'>
                  <b>MAC</b>
                </div>{' '}
                <div className='type'>
                  <b>Type</b>
                </div>
              </div>
              <div className='assets-with-hardware-rows-wrapper'>
                {assetsWithHardware.map((item: any) => {
                  return (
                    <div
                      className='assets-with-hardware-row'
                      key={`${item.vin}-${item.mac}`}
                    >
                      <div className='name'>
                        {item.asset_name || NoDataLabels.DASH}
                      </div>
                      <div className='vin'>{item.vin}</div>
                      <div className='mac'>{item.mac}</div>
                      <div className='type'>
                        {getClearLabel(item.hardware_type)}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleConfirmBulkUploadRollback}>
            {locale['ok'] || 'Ok'}
          </Button>{' '}
          <Button color='danger' onClick={handleHideRollback}>
            {locale['cancel'] || 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <InfoModal
        open={showRollbackInfo}
        header='Roll back bulk upload'
        message={'Assets were successfully removed'}
        onConfirm={() => setShowRollbackInfo(false)}
      />
    </>
  )
}
export default AssetsBulkUploadHistoryTable
