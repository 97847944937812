import { store } from "../store";
import api from "../../services/api";
import { getStatusObj } from "../../utils/utils";
import { IgetState, ThunkResult } from "../types";
import { AssetGroupsTypes, IDetailsSort, IAssetGroupDetails, IAssetGroupsResponse } from "./types";
import { Dispatch } from "redux";

export const setAssetGroups = (payload: IAssetGroupsResponse) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS,
    payload
})

export const setAssetGroupsStatus = (payload: IStatus) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS_STATUS,
    payload
})

export const setAssetGroupsLoading = (payload: boolean) => ({
    type: AssetGroupsTypes.LOADING_ASSET_GROUPS,
    payload
})

export const setAssetGroupsOffset = (payload: number) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS_OFFSET,
    payload
})

export const setAssetGroupsLimit = (payload: number) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS_LIMIT,
    payload
})

export const setAssetGroupsDetailsLoading = (payload: boolean) => ({
    type: AssetGroupsTypes.LOADING_ASSET_GROUPS_DETAILS,
    payload
})


export const setAssetGroupsDetails = (payload: IAssetGroupDetails) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS_DETAILS,
    payload
})

export const setAssetGroupsDetailsStatus = (payload: IStatus) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS_DETAILS_STATUS,
    payload
})

export const setAssetGroupsLimitedData = (payload: IAssetGroupsResponse) => ({
    type: AssetGroupsTypes.SET_ASSET_GROUPS_LIMITED_DATA,
    payload
})

export const setAssetsGroupsDetailsOffset = (offset: number) => ({
    type: AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_OFFSET,
    payload: offset
})

export const setAssetsGroupsDetailsSortOptions = (options: IDetailsSort) => {
    return {
        type: AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_SORT_OPTIONS,
        payload: options
    };
};

export const setAssetGroupsDetailsLimit = (payload: number) => ({
    type: AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_LIMIT,
    payload
})

export const fetchAssetGroupsInfo = (): ThunkResult<void> => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch(setAssetGroupsLoading(true));

            const { offset, limit } = getState().assetGroups.assetGroups

            const assetGroups: IAssetGroupsResponse = await api.getAssetGroups(limit, offset);
            dispatch(setAssetGroups(assetGroups));
            dispatch(setAssetGroupsStatus(getStatusObj()));
        } catch (err) {
            // @ts-ignore
            const message = err.message
            dispatch(setAssetGroupsStatus(getStatusObj({ statusCode: 400, message })));
        } finally {
            dispatch(setAssetGroupsLoading(false));
        }
    }
}

export const createAssetGroup = (name: string, customer_id: number): ThunkResult<number | null> => {
    return async () => {
        try {
            store.dispatch(setAssetGroupsLoading(true));
            const res = await api.createAssetGroup(name, customer_id);
            const status = getStatusObj({ statusCode: 201 }, `Asset group: "${name}" successfully created`)
            store.dispatch(setAssetGroupsStatus(status));
            store.dispatch(fetchAssetGroupsInfo());
            return res.id as number
        } catch (err) {
            // @ts-ignore
            const message = err.message
            const status = getStatusObj(err, message)
            store.dispatch(setAssetGroupsStatus(status));
            return null
        } finally {
            store.dispatch(setAssetGroupsLoading(false));
        }
    }
}

export const updateAssetGroup = (name: string, id: number): ThunkResult<IStatus> => {
    return async () => {
        try {
            store.dispatch(setAssetGroupsLoading(true));
            await api.updateAssetGroup(name, id);
            const status = getStatusObj({ statusCode: 201 }, `Asset group: "${name}" successfully updated`)
            store.dispatch(setAssetGroupsStatus(status));
            store.dispatch(fetchAssetGroupsInfo());
            return status
        } catch (err) {
            // @ts-ignore
            const message = err.message
            const status = getStatusObj(err, message)
            store.dispatch(setAssetGroupsStatus(status));
            return status
        } finally {
            store.dispatch(setAssetGroupsLoading(false));
        }
    }
}

export const deleteAssetGroup = (id: number): ThunkResult<IStatus> => {
    return async () => {
        try {
            store.dispatch(setAssetGroupsLoading(true));
            await api.deleteAssetGroup(id);
            const status = getStatusObj({ statusCode: 201 }, `Asset group successfully deleted`)
            store.dispatch(setAssetGroupsStatus(status));
            store.dispatch(fetchAssetGroupsInfo());
            return status
        } catch (err) {
            // @ts-ignore
            const message = err.message
            const status = getStatusObj(err, message)
            store.dispatch(setAssetGroupsStatus(status));
            return status
        } finally {
            store.dispatch(setAssetGroupsLoading(false));
        }
    }
}

export const getAssetGroupById = (
    id: number, 
    limit: number = 30, 
    offset: number = 0, 
    showUngroupedOnly: boolean = false,
    order: {column: string, direction: string} = {column: "name", direction: "asc"}, 
    filters: string = "", 
): ThunkResult<void> => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            store.dispatch(setAssetGroupsDetailsLoading(true));
            const { column, direction } = order;
            const assetGroup: IAssetGroupDetails = await api.getAssetGroupById(
                id, 
                limit, 
                offset,
                showUngroupedOnly,
                column, 
                direction, 
                filters, 
            );
            store.dispatch(setAssetGroupsDetails(assetGroup));
            store.dispatch(setAssetGroupsDetailsStatus(getStatusObj()));
        } catch (err) {
            // @ts-ignore
            const message = err.message
            store.dispatch(setAssetGroupsDetailsStatus(getStatusObj({ statusCode: 400, message })));
        } finally {
            store.dispatch(setAssetGroupsDetailsLoading(false));
        }
    }
}

export function fetchAssetGroupsLimitedData(): ThunkResult<void> {
    return async () => {
        try {
            store.dispatch(setAssetGroupsLoading(true));
            const res = await api.getAssetGroupsLimitedData()
            store.dispatch(setAssetGroupsLimitedData(res))
            store.dispatch(setAssetGroupsLoading(false));
        } catch (err) {
            console.log(err)
        }
    }
}

export function fetchAssetGroupsLimitedDataForCustomers(customerIds: string): ThunkResult<void> {
    return async () => {
        try {
            store.dispatch(setAssetGroupsLoading(true));
            const res = await api.getAssetGroupsLimitedDataForCustomers(customerIds)
            store.dispatch(setAssetGroupsLimitedData(res))
            store.dispatch(setAssetGroupsLoading(false));
        } catch (err) {
            console.log(err)
        }
    }
}

export function changeOffset(offset: number): ThunkResult<void> {
    return async () => {
        try {
            store.dispatch(setAssetsGroupsDetailsOffset(offset));
        } catch (err) {
            console.log(err)
        }
    }
}

store.subscribe(() => {
    const lastAction = store.getState().lastAction;

    if (lastAction.type === AssetGroupsTypes.SET_ASSET_GROUPS_LIMIT) {
        store.dispatch(setAssetGroupsOffset(0))
    }

    if (lastAction.type === AssetGroupsTypes.SET_ASSET_GROUPS_OFFSET) {
        store.dispatch(fetchAssetGroupsInfo())
    }
})