import { ManageNotificationsTypes, ManageNotificationsReducer, ManageNotificationActions } from "./types"


const initialState: ManageNotificationsReducer = {
    isLoading: false,
    data: [],
    users: [],
    limit: 30,
    offset: 0,
    totalCount: 0,
    status: {
        ok: false,
        statusCode: 0,
        message: ""
    },
    filter: {
        filterType: "",
        filterData: ""
    }
}

const manageNotifications = (state = initialState, action: ManageNotificationActions): ManageNotificationsReducer => {
    switch (action.type) {
        case ManageNotificationsTypes.LOADING:
            return { ...state, isLoading: action.payload }
        
        case ManageNotificationsTypes.SET_DATA:
            return { ...state, data: action.payload }
        case ManageNotificationsTypes.SET_USERS:
            return { ...state, users: action.payload }
        
        case ManageNotificationsTypes.SET_LIMIT:
            return { ...state, limit: action.payload }
        case ManageNotificationsTypes.SET_OFFSET:
            return { ...state, offset: action.payload }
        case ManageNotificationsTypes.SET_TOTAL_COUNT:
            return { ...state, totalCount: action.payload }
        case ManageNotificationsTypes.SET_FILTER:
            return { ...state, filter: action.payload }

        case ManageNotificationsTypes.SET_STATUS:
            return { ...state, status: action.payload }
        default:
            return state
    }
}
  
export default manageNotifications