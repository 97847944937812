import {
  LOADING_WHITELABEL,
  SET_WHITELABEL,
  SET_WHITELABEL_ERROR,
  SET_WHITELABEL_LOADED,
} from '../actionTypes'
import { localeText } from '../../locale'
import defaultConfig from './defaultConfig'
import { Languages } from '../../enums'
import { getActualConfig } from '../../utils'

const languagechoice = (data) => {
  switch (data.preferenceDefaults.locale.language) {
    case Languages.FR:
      return localeText.fr
    case Languages.EN:
      return localeText.en
    case Languages.GR:
      return localeText.gr
    default:
      return null
  }
}

const initialState = {
  ...defaultConfig,
  locale: languagechoice(defaultConfig),
  error: false,
  isLoading: false,
  hasCheckedForConfig: false,
  whiteLabelLoaded: false,
}

const setWhiteLabel = (state, data) => {
  if (!data) return state
  const config = getActualConfig(data)

  config.locale =
    data.preferenceDefaults &&
    data.preferenceDefaults.locale &&
    languagechoice(data)

  return {
    ...state,
    ...config,
  }
}

const whitelabel = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_WHITELABEL:
      return { ...state, isLoading: action.payload }
    case SET_WHITELABEL:
      return setWhiteLabel(state, action.payload)
    case SET_WHITELABEL_ERROR:
      return { ...state, error: action.payload, hasCheckedForConfig: true }
    case SET_WHITELABEL_LOADED:
      return { ...state, whiteLabelLoaded: action.payload }
    default:
      return state
  }
}

export default whitelabel
