export enum TagsActionsTypes {
  SET_TAGS_OFFSET = "SET_TAGS_OFFSET",
  LOADING_CARGOTAGS = "LOADING_CARGOTAGS",
  SET_PAGED_CARGOTAGS = "SET_PAGED_CARGOTAGS",
  SET_TAGS_TOTAL_COUNT = "SET_TAGS_TOTAL_COUNT",
  SET_TAGS_ERROR = "SET_TAGS_ERROR",
  SET_TAGS_LIMIT = "SET_TAGS_LIMIT",
  SET_TAGS_SORT_OPTIONS = "SET_TAGS_SORT_OPTIONS",
  SET_CARGO_TAG_UPLOAD_MESSAGE = "SET_CARGO_TAG_UPLOAD_MESSAGE",
  UNASSIGNED_CARGOTAGS = "UNASSIGNED_CARGOTAGS",
  SET_CARGO_TAGS_FILTER = "SET_CARGO_TAGS_FILTER"
}

export interface TagsSortPayload {
  column: string;
  direction: "asc" | "desc";
}