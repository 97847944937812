import React, { useState } from "react";
import classes from "./style.module.scss";
import IconWrapper from "../../common/iconWrapper";
import { Tooltip } from "reactstrap";
import moment from 'moment'
import { convertDataEpochToDate } from "../../../../utils";
import { HealthStatus, NoDataLabels } from "../../../../enums";

const LinePressureLayer = ({ line, timezone }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { is_latest_line_data, no_status, latest_line_measurement, line_pressure } = line[0];
  let msg = ""
  let variant = HealthStatus.good
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipCls = [classes.lineTooltip]

  if (line_pressure) {
    tooltipCls.push(classes.warning)
    msg = "Check Line Pressure"
    variant = HealthStatus.criticalWarning
  }
  else if(!line_pressure && no_status) {
    msg = "In No Status Condition"
    variant = HealthStatus.noStatus
  }
  else if(!is_latest_line_data) {
    msg = "No Data"
    variant = HealthStatus.noStatus
  } else {
    tooltipCls.push(classes.normal)
    msg = "No warnings"
  }

  const date = latest_line_measurement && convertDataEpochToDate(latest_line_measurement, null, null, true)
  const tz = moment().tz(timezone).format("z")
  const dateTz = `${date} ${tz}`

  return (
    <div className={classes.wrapper}>
      <IconWrapper variant={variant} name='linePressure'>
        <div id='linePressure'></div>
      </IconWrapper>
      {line[0] && <Tooltip
        placement="top"
        toggle={toggle}
        innerClassName={tooltipCls.join(" ")}
        isOpen={tooltipOpen}
        target={"linePressure"}
        autohide={false}
        hideArrow={true}
      >
        Sensor Type: Line Pressure<br />
        {msg} <br />
        Position: Red Supply Line <br />
        {`Latest Measurement: ${latest_line_measurement ? dateTz : NoDataLabels.DASH}`}  <br />
      </Tooltip>}
    </div>
  );
};

export default LinePressureLayer;
