const stage = process.env.REACT_APP_STAGE || 'dev'
let hereMapsOauthConfig, clientId, userPoolId, mapApiKey, gaMeasurementId

if (stage === 'prod') {
  hereMapsOauthConfig = require('./hereMapsConfigs/here_maps_oauth_credentials_prod.json')
} else {
  hereMapsOauthConfig = require('./hereMapsConfigs/here_maps_oauth_credentials_dev.json')
}

// TODO: We need to get these values from terraform and added to app
//       via REACT_APP_<VAR> in build spec.
switch (stage) {
  default:
  case 'dev':
    clientId = '64vmlv8go301m4n1vb55f2qtfk'
    userPoolId = 'us-east-1_5BoppvUd8'
    mapApiKey = 'kgWz9GHxVSUNiTpW4fUwtFPKYt4d2qNbpBy6zOLoeeo'
    gaMeasurementId = 'G-XKYNQR2SG8'
    break
  case 'staging':
    clientId = '6l0ffiks2k7fmboon2burmdu92'
    userPoolId = 'us-east-1_zZfS2zaGS'
    mapApiKey = 'kgWz9GHxVSUNiTpW4fUwtFPKYt4d2qNbpBy6zOLoeeo'
    gaMeasurementId = 'G-XKYNQR2SG8'
    break
  case 'prod':
    clientId = '51apm6fv1ilokgaqql558ohu15'
    userPoolId = 'us-east-1_O07i0MovB'
    mapApiKey = 'VGRZTDbGrx2B5zZ4GHsFBojL4uyn_BGZ1MBanqCd5hc'
    gaMeasurementId = 'G-6WEHDFMMJP'
    break
}

export default {
  region: 'us-east-1',
  userPool: userPoolId,
  userPoolBaseUri: `https://auth-rivata-${stage}.auth.us-east-1.amazoncognito.com`,
  clientId: clientId,
  callbackUri: `${window.location.origin}/callback`,
  signoutUri: `${window.location.origin}/logout`,
  tokenScopes: [
    'openid',
    'email',
    'profile',
    `${window.location.origin}/test_scope`,
  ],
  apiUri: `${window.location.origin}`,
  mapApiKey: mapApiKey,
  build_branch: process.env.REACT_APP_BRANCH || 'local',
  build_fullhash:
    process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION || 'local',
  ga_measurement_id: gaMeasurementId,
  hereMapsOauthKey: hereMapsOauthConfig.access_key_id,
  hereMapsOauthSecret: hereMapsOauthConfig.access_key_secret,
  hereMapsOauthUrl: hereMapsOauthConfig.endpoint_url,
}
