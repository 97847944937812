import React from 'react'
import DateTimePicker from '../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../enums'
import moment from 'moment'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import ReportStatusFilter from '../../componentsV2/ReportStatus'
import ReportType from '../../componentsV2/ReportType'
import { Button } from 'reactstrap'
interface ReportsHistoryGridFilterProps {
  dateFrom: Date
  dateTo: Date
  onDateRangeSelect: (from: Date, to: Date) => void
  onReportStatusSelected: (id: string, selectedValue: MenuItemType[]) => void
  onReportTypeSelected: (id: string, selectedValue: MenuItemType[]) => void
  onSubmit: () => void
}
const ReportsHistoryGridFilter: React.FC<ReportsHistoryGridFilterProps> = ({
  dateFrom,
  dateTo,
  onDateRangeSelect,
  onReportStatusSelected,
  onReportTypeSelected,
  onSubmit,
}) => {
  return (
    <>
      <div className='d-flex flex-wrap justify-content-between mb-2'>
        <div className='d-flex flex-wrap flex-column w-100'>
          <div className='d-flex flex-wrap align-items-start flex-column'>
            <div>Select Date Range</div>
            <DateTimePicker
              defaultMode={CalendarTypes.DateRange}
              dateFrom={dateFrom.toISOString()}
              dateTo={dateTo.toISOString()}
              onDateRangeSelect={onDateRangeSelect}
              maxDate={moment().toDate()}
              disabled={false}
              className='mt-2'
            />
          </div>
          <div className='d-flex flex-wrap mt-3 align-items-start'>
            <ReportStatusFilter onDropdownItemSelect={onReportStatusSelected} />
            <ReportType
              className='ml-2'
              onDropdownItemSelect={onReportTypeSelected}
            />
          </div>
          <div className='d-flex justify-content-end mt-2'>
            <Button
              onClick={() => {
                onSubmit()
              }}
              color='primary'
              className='mr-2 mb-2'
            >
              View
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportsHistoryGridFilter
