export const TEMP_THRESHOLD = 167;
export const VIBRATION_THRESHOLD = 1.5;
export const LOW_PRESSURE_TRESHOLD = 80;
export const CRITICAL_LOW_PRESSURE_TRESHOLD = 70;
export const OVER_PRESSURE_TRESHOLD = 100;
export const CRITICAL_OVER_PRESSURE_TRESHOLD = 110;
export const DEFAULT_WINDOW = 7;
export const DEFAULT_INTERVAL = "day";
export const CRITICAL_OVER_TEMPERATURE_THRESHOLD = 101.66;

export const WARNING_TYPE = {
    HUB_VIBRATION_SENSOR: 1,
    HUB_TEMPERATURE_SENSOR: 2,
    TIRE_PRESSURE_SENSOR: 3,
    TIRE_TEMPERATURE_SENSOR: 4,
    LINE_PRESSURE_SENSOR: 5,
    GATEWAY: 6,
    UNKNOWN: 7,
    OVER_SPEED: 9
}

export const INSTALLMENT_POSITION_TYPE = {
    TRACTOR_STEER: 1,
    TRACTOR_FRONT: 2,
    TRACTOR_REAR_1: 3,
    TRACTOR_REAR_2: 4,
    TRACTOR_REAR_3: 11,
    TRAILER_1: 5,
    TRAILER_2: 6,
    TRAILER_3: 7,
    TRAILER_4: 8,
    TRAILER_5: 9,
    TRAILER_6: 10,
}

export const API_OK_RESULT = {
    ok: true, 
    statusCode: 200,
    message: ""
}