import assetGroupsApi from './ApiGroups/AssetGroups'
import assetsApi from './ApiGroups/AssetsApi'
import cargotagsApi from './ApiGroups/CargotagsApi'
import commonApi from './ApiGroups/CommonApi'
import customerApi from './ApiGroups/CustomerApi'
import documentApi from './ApiGroups/DocumentApi'
import geolocationApi from './ApiGroups/GeolocationApi'
import gpsApi from './ApiGroups/GpsApi'
import hardwareApi from './ApiGroups/HardwareApi'
import hardwareDataApi from './ApiGroups/HardwareDataApi'
import notificationApi from './ApiGroups/NotificationsApi'
import reportApi from './ApiGroups/ReportApi'
import userApi from './ApiGroups/UserApi'
import warningApi from './ApiGroups/WarningApi'
import warningSettingApi from './ApiGroups/WarningSettingApi'
import whitelabelApi from './ApiGroups/WhitelabelApi'
import calibrationApi from './ApiGroups/CalibrationApi'
import logApi from './ApiGroups/LogApi'
import testingUtilsApi from './ApiGroups/TestingUtilsApi'
import reportsHistoryApi from './ApiGroups/ReportsHistoryApi'
import geofenceGroupsApi from './ApiGroups/GeofenceGroups'

const api = {
  ...assetGroupsApi,
  ...assetsApi,
  ...cargotagsApi,
  ...commonApi,
  ...customerApi,
  ...documentApi,
  ...geolocationApi,
  ...gpsApi,
  ...hardwareApi,
  ...hardwareDataApi,
  ...reportApi,
  ...userApi,
  ...warningApi,
  ...warningSettingApi,
  ...whitelabelApi,
  ...notificationApi,
  ...calibrationApi,
  ...logApi,
  ...testingUtilsApi,
  ...reportsHistoryApi,
  ...geofenceGroupsApi,
}

export default api
