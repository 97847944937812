import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'

const OdometerOffsetSummaryReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
    </>
  )
}
export default OdometerOffsetSummaryReportParameters
