export const getFileTitle = (title?: string) => title?.slice(0, title.length-4) || "";

export const isValidFileName = (name: string) => {
  let msg = null
    // eslint-disable-next-line
  const rg1 = /[&\/\\#,+()$~%'":*?<>{}]/g; // forbidden characters \ / : * ? " < > |
  const rg2 = name[0] === "." || name[name.length-1] === "."
  const rg3 = /^(nul |prn|con|lpt[0-9]|com[0-9])(.|$)/i; // forbidden file names
    // eslint-disable-next-line
  if(rg1.test(name)) {msg = `A file name can not contain any of the following characters: &\/#,+()$~%'":*?<>{}`}
  if(rg2) {msg = `File name can not start or end with a dot`}
  if(rg3.test(name)) {msg= `Forbiden file name`}
  return msg
};
