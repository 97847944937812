import { store } from "../store";
import api from "../../services/api";
import { getStatusObj } from "../../utils/utils";
import { IgetState, ThunkResult } from "../types";
import { GeofenceGroupsTypes, IDetailsSort, IGeofenceGroupDetails, IGeofenceGroupsResponse } from "./types";
import { Dispatch } from "redux";

export const setGeofenceGroups = (payload: IGeofenceGroupsResponse) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS,
    payload
})

export const setGeofenceGroupsStatus = (payload: IStatus) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_STATUS,
    payload
})

export const setGeofenceGroupsLoading = (payload: boolean) => ({
    type: GeofenceGroupsTypes.LOADING_GEOFENCE_GROUPS,
    payload
})

export const setGeofenceGroupsOffset = (payload: number) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_OFFSET,
    payload
})

export const setGeofenceGroupsLimit = (payload: number) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMIT,
    payload
})

export const setGeofenceGroupsDetailsLoading = (payload: boolean) => ({
    type: GeofenceGroupsTypes.LOADING_GEOFENCE_GROUPS_DETAILS,
    payload
})


export const setGeofenceGroupsDetails = (payload: IGeofenceGroupDetails) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_DETAILS,
    payload
})

export const setGeofenceGroupsDetailsStatus = (payload: IStatus) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_DETAILS_STATUS,
    payload
})

export const setGeofenceGroupsLimitedData = (payload: IGeofenceGroupsResponse) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMITED_DATA,
    payload
})

export const setGeofencesGroupsDetailsOffset = (offset: number) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_OFFSET,
    payload: offset
})

export const setGeofencesGroupsDetailsSortOptions = (options: IDetailsSort) => {
    return {
        type: GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_SORT_OPTIONS,
        payload: options
    };
};

export const setGeofenceGroupsDetailsLimit = (payload: number) => ({
    type: GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_LIMIT,
    payload
})

export const fetchGeofenceGroupsInfo = (): ThunkResult<void> => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch(setGeofenceGroupsLoading(true));

            const { offset, limit } = getState().assetGroups.assetGroups

            const assetGroups: IGeofenceGroupsResponse = await api.getGeofenceGroups(limit, offset);
            dispatch(setGeofenceGroups(assetGroups));
            dispatch(setGeofenceGroupsStatus(getStatusObj()));
        } catch (err) {
            // @ts-ignore
            const message = err.message
            dispatch(setGeofenceGroupsStatus(getStatusObj({ statusCode: 400, message })));
        } finally {
            dispatch(setGeofenceGroupsLoading(false));
        }
    }
}

export const createGeofenceGroup = (name: string, customer_id: number): ThunkResult<number | null> => {
    return async () => {
        try {
            store.dispatch(setGeofenceGroupsLoading(true));
            const res = await api.createGeofenceGroup(name, customer_id);
            const status = getStatusObj({ statusCode: 201 }, `Geofence group: "${name}" successfully created`)
            store.dispatch(setGeofenceGroupsStatus(status));
            store.dispatch(fetchGeofenceGroupsInfo());
            return res.id as number
        } catch (err) {
            // @ts-ignore
            const message = err.message
            const status = getStatusObj(err, message)
            store.dispatch(setGeofenceGroupsStatus(status));
            return null
        } finally {
            store.dispatch(setGeofenceGroupsLoading(false));
        }
    }
}

export const updateGeofenceGroup = (name: string, id: number): ThunkResult<IStatus> => {
    return async () => {
        try {
            store.dispatch(setGeofenceGroupsLoading(true));
            await api.updateGeofenceGroup(name, id);
            const status = getStatusObj({ statusCode: 201 }, `Geofence group: "${name}" successfully updated`)
            store.dispatch(setGeofenceGroupsStatus(status));
            store.dispatch(fetchGeofenceGroupsInfo());
            return status
        } catch (err) {
            // @ts-ignore
            const message = err.message
            const status = getStatusObj(err, message)
            store.dispatch(setGeofenceGroupsStatus(status));
            return status
        } finally {
            store.dispatch(setGeofenceGroupsLoading(false));
        }
    }
}

export const deleteGeofenceGroup = (id: number): ThunkResult<IStatus> => {
    return async () => {
        try {
            store.dispatch(setGeofenceGroupsLoading(true));
            await api.deleteGeofenceGroup(id);
            const status = getStatusObj({ statusCode: 201 }, `Geofence group successfully deleted`)
            store.dispatch(setGeofenceGroupsStatus(status));
            store.dispatch(fetchGeofenceGroupsInfo());
            return status
        } catch (err) {
            // @ts-ignore
            const message = err.message
            const status = getStatusObj(err, message)
            store.dispatch(setGeofenceGroupsStatus(status));
            return status
        } finally {
            store.dispatch(setGeofenceGroupsLoading(false));
        }
    }
}

export const getGeofenceGroupById = (
    id: number,
    limit: number = 30,
    offset: number = 0,
    order: { column: string, direction: string } = { column: "name", direction: "asc" },
    filters: string = "",
): ThunkResult<void> => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            store.dispatch(setGeofenceGroupsDetailsLoading(true));
            const { column, direction } = order;
            const assetGroup: IGeofenceGroupDetails = await api.getGeofenceGroupById(
                id,
                limit,
                offset,
                column,
                direction,
                filters,
            );
            store.dispatch(setGeofenceGroupsDetails(assetGroup));
            store.dispatch(setGeofenceGroupsDetailsStatus(getStatusObj()));
        } catch (err) {
            // @ts-ignore
            const message = err.message
            store.dispatch(setGeofenceGroupsDetailsStatus(getStatusObj({ statusCode: 400, message })));
        } finally {
            store.dispatch(setGeofenceGroupsDetailsLoading(false));
        }
    }
}

export function fetchGeofenceGroupsLimitedData(): ThunkResult<void> {
    return async () => {
        try {
            store.dispatch(setGeofenceGroupsLoading(true));
            const res = await api.getGeofenceGroupsLimitedData()
            store.dispatch(setGeofenceGroupsLimitedData(res))
            store.dispatch(setGeofenceGroupsLoading(false));
        } catch (err) {
            console.log(err)
        }
    }
}

export function fetchGeofenceGroupsLimitedDataForCustomers(customerIds: string): ThunkResult<void> {
    return async () => {
        try {
            store.dispatch(setGeofenceGroupsLoading(true));
            const res = await api.getGeofenceGroupsLimitedDataForCustomers(customerIds)
            store.dispatch(setGeofenceGroupsLimitedData(res))
            store.dispatch(setGeofenceGroupsLoading(false));
        } catch (err) {
            console.log(err)
        }
    }
}

export function changeOffset(offset: number): ThunkResult<void> {
    return async () => {
        try {
            store.dispatch(setGeofencesGroupsDetailsOffset(offset));
        } catch (err) {
            console.log(err)
        }
    }
}

store.subscribe(() => {
    const lastAction = store.getState().lastAction;

    if (lastAction.type === GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMIT) {
        store.dispatch(setGeofenceGroupsOffset(0))
    }

    if (lastAction.type === GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_OFFSET) {
        store.dispatch(fetchGeofenceGroupsInfo())
    }
})