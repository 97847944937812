import moment from "moment"
import baseApi from "./BaseApi"

class CommonApi {
  getDataAboutApi = () => {
    return baseApi.get(`about`, false)
  }


  postResendTomporaryPassword = (data: any) => {
    return baseApi.post("reset_temporary_password", data)
  }


  postRequestAccessData = (data: any) => {
    return baseApi.post("requestaccess", data)
  }


  getLogsByKey = (
    hardware_id: number,
    type: string,
    epoch: number,
    logs_from: number,
    logs_to: number,
    epoch_to: number
  ) => {
    let stamp = type === "sensors" ? moment(epoch * 1000).format("YYYYMMDD") : epoch
    let logsCount = type === 'sensors' ? logs_to-1 : logs_to
    
    return baseApi.get(
      `rivata/logs?hardware_id=${hardware_id}&type=${type}&epoch=${stamp}&epoch_to=${epoch_to}&logs_from=${logs_from}&logs_to=${logsCount}`
    )
  }
}

const commonApi = new CommonApi()

export default commonApi