import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetsRow from '../ReportParametersRows/AssetsRow'

const AssetProvisioningSummaryReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <AssetsRow value={props.parameters.vins} />
    </>
  )
}
export default AssetProvisioningSummaryReportParameters
