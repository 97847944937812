import { useEffect, useMemo, useState } from 'react'
import { ColumnsIds, NoDataLabels } from '../../enums'
import { convertDataEpochToDate, getClearLabel } from '../../utils'

export const useColumns = () => {
  return useMemo(() => {
    const columns = [
      {
        label: '',
        id: 'actions',
        isDraggable: false,
      },
      {
        label: '',
        id: 'actions2',
        isDraggable: false,
      },
      {
        label: 'Customer',
        id: ColumnsIds.CUSTOMER_NAME,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'File Name',
        id: 'file_name',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Status',
        id: 'status',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Uploaded by',
        id: 'uploaded_by',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Uploaded Date',
        id: 'end_dt',
        control: 'sort',
        isDraggable: true,
      },
    ]

    return columns
  }, [])
}

export const useRows = (
  data: Array<any>,
  handleShowUploadeAssets: (data: any) => void,
  handleShowRollback: (data: any) => void,
) => {
  const [rows, setRows] = useState<Array<any>>([])
  useEffect(() => {
    const rowList: Array<any> = data.map((row) => {
      return {
        columns: [
          {
            id: row.bulk_upload_id,
            type: 'iconbutton',
            showIconButton: ['COMPLETED', "ROLLED_BACK"].includes(row.status),
            columnId: 'actions',
            iconButtonIcon: 'fa fa-eye',
            iconButtonToolTipText: 'View Uploaded Assets',
            onClick: handleShowUploadeAssets,
            width: '35px',
          },
          {
            id: row.bulk_upload_id,
            type: 'iconbutton',
            showIconButton: row.status === 'COMPLETED',
            columnId: 'actions2',
            iconButtonIcon: 'fa fa-undo',
            iconButtonToolTipText: 'Roll Back Changes',
            onClick: handleShowRollback,
            width: '35px',
          },
          {
            id: row.bulk_upload_id,
            type: 'text',
            label: row.customer_name,
            columnId: 'customer_name',
          },
          {
            id: row.bulk_upload_id,
            type: 'text',
            label: row.file_name || NoDataLabels.DASH,
            columnId: 'file_name',
          },
          {
            id: row.bulk_upload_id,
            type: 'text',
            label: getClearLabel(row.status) || NoDataLabels.DASH,
            columnId: 'status',
          },
          {
            id: row.bulk_upload_id,
            type: 'text',
            label: row.uploaded_by || NoDataLabels.DASH,
            columnId: 'uploaded_by',
          },
          {
            id: row.bulk_upload_id,
            type: 'text',
            label: row.end_dt
              ? convertDataEpochToDate(
                row.end_dt,
                null,
                null,
                true,
                false,
                true,
              )
              : NoDataLabels.DASH,
            columnId: 'end_dt',
          },
        ],
        id: row.bulk_upload_id,
        data: row,
      }
    })
    setRows(rowList)
  }, [data, handleShowUploadeAssets, handleShowRollback])
  return rows
}
