import baseApi from "./BaseApi"

class GeolocationApi {
  getGeoLocations = (
    limit: number,
    offset: number,
    geofence_type_id?: number | null,
    filters?: string,
    sort_column?: string,
    sort_direction?: string,
    addCustomerIds = true,
    show_deleted?: boolean,
  ) => {
    const queryParams = new URLSearchParams(filters)

    queryParams.append("limit", limit.toString())
    queryParams.append("offset", offset.toString())

    if (geofence_type_id) {
      queryParams.append("geofence_type_id", geofence_type_id.toString())
    }

    if (sort_column && sort_direction) {
      queryParams.append("sort_column", sort_column.toString())
      queryParams.append("sort_direction", sort_direction.toString())
    }

    if (show_deleted) {
      queryParams.append("show_deleted", "true")
    }

    return baseApi.get(`rivata/geolocation?${queryParams.toString()}`, addCustomerIds)
  }


  getGeolocationById = (id: string) => {
    return baseApi.get(`rivata/geolocation?geofence_id=${id}`)
  }


  postGeoLocation = (data: any) => {
    return baseApi.post(`rivata/geolocation`, data)
  }


  deleteGeoLocation = (id: number) => {
    return baseApi.delete(`rivata/geolocation?id=${id}`)
  }


  putGeoLocation = (id: number, data: any) => {
    return baseApi.put(`rivata/geolocation?id=${id}`, data)
  }


  deleteUnnasignGatewayFromLocation = (gatewayEsn: string) => {
    return baseApi.delete(`rivata/geolocation/gateway?gateway_esn=${gatewayEsn}`)
  }


  postAssignGatewayToLocation = (data: any) => {
    return baseApi.post(`rivata/geolocation/gateway`, data)
  }


  getAssetGeofencesEvents = (assetId: number, epoch_from: number, epoch_to: number) => {
    const params = new URLSearchParams();
    if (assetId) {
      params.append("asset_id", assetId.toString());
    }
    if (epoch_from) {
      params.append("epoch_from", epoch_from.toString());
    }
    if (epoch_to) {
      params.append("epoch_to", epoch_to.toString());
    }

    return baseApi.get(`rivata/geolocation/event?${params.toString()}`, false)
  }


  getTagsLocations = (
    limit: number,
    offset: number,
    filter: { filterType?: string, filterData?: string },
    sortOptions?: { column: string, direction: "asc" | "desc" }
  ) => {
    let queryStr = ""
    if (sortOptions && sortOptions.column && sortOptions.direction) queryStr += `&sort_column=${sortOptions.column}&sort_direction=${sortOptions.direction}`
    if (filter) queryStr += `&${filter}`
    return baseApi.get(`rivata/geolocation/tags/locations?limit=${limit}&offset=${offset}${queryStr}`)
  }


  postCopygeolocationToEnv = (data: any) => {
    return baseApi.post(`rivata/geolocation/copy_to_env`, data)
  }


  getAllGeofencesShortFormat = (addCustomerIds = true, geofenceIds: string | undefined = undefined) => {
    const filter = new URLSearchParams();
    filter.append("get_limited_data", "true")
    if (geofenceIds)
      filter.append("geofence_ids", geofenceIds)
    const queryString = filter.toString()
    return baseApi.get(`rivata/geolocation?${queryString}`, addCustomerIds)
  }


  getGeolocationAssetsById = (id: string, filters?: string, sortOptions?: { column: string, direction: "asc" | "desc" }) => {
    const queryParams = new URLSearchParams(filters)

    queryParams.append("id", id)

    if (sortOptions && sortOptions.column && sortOptions.direction) {
      queryParams.append('sort_column', sortOptions.column);
      queryParams.append('sort_direction', sortOptions.direction)
    } 

    return baseApi.get(`rivata/geolocation/assets?${queryParams.toString()}`)
  }


  searchGeolocations = (
    searchValue: string,
    geofence_type_id?: 2 | 3,
    limit = 20,
    customerIds?: string
  ) => {
    const params = new URLSearchParams()

    params.append("search_value", searchValue)

    params.append("limit", limit.toString())

    if (customerIds) params.append("customer_ids", customerIds)

    if (geofence_type_id) params.append("geofence_type_id", geofence_type_id.toString())

    return baseApi.get(`rivata/geolocation/search?${params.toString()}`)
  }

  shareGeolocations = (
    customer_ids: Array<number>,
    location_id: number | Array<number>,
  ) => {
    return baseApi.post("rivata/geolocation/share", { customer_ids, location_id })
  }

  postGeofencesCsv = (base64csv: string, customerId: number) => {
    let queryString = `?customer_id=${customerId}`

    return baseApi.post(`rivata/geolocation/upload${queryString}`, base64csv)
  }
}

const geolocationApi = new GeolocationApi()

export default geolocationApi