import React from 'react'
import { Col } from 'reactstrap'
import SubNav from '../../components/SubNav'
import CustomerSelector from '../../modules/CustomerSelector'
import ActionableItemsModule from '../../modules/ActionableItemsModule'

interface Props {}

const breadcrumbs = [
  { label: 'Home', path: '/dashboard' },
  { label: 'Action Required Items' },
]

const ActionableItems: React.FC<Props> = () => {
  return (
    <>
      <SubNav title='Action Required Items' breadcrumbs={breadcrumbs}>
        <CustomerSelector />
      </SubNav>

      <Col md='12'>
        <ActionableItemsModule />
      </Col>
    </>
  )
}

export default ActionableItems
