import { SetStatus, SetLoading, SetNumber, SetBoolean } from "../types";

export enum ManageGeofencesTypes {
  LOADING_MANAGE_GEOFENCES = "LOADING_MANAGE_GEOFENCES",

  SET_MANAGE_GEOFENCES_DATA = "SET_MANAGE_GEOFENCES_DATA",
  SET_MANAGE_GEOFENCES_COUNT = "SET_MANAGE_GEOFENCES_COUNT",
  SET_MANAGE_GEOFENCES_LIMIT = "SET_MANAGE_GEOFENCES_LIMIT",
  SET_MANAGE_GEOFENCES_OFFSET = "SET_MANAGE_GEOFENCES_OFFSET",
  SET_MANAGE_GEOFENCES_SHOW_DELETED = "SET_MANAGE_GEOFENCES_SHOW_DELETED",

  SET_MANAGE_GEOFENCES_DATA_STATUS = "SET_MANAGE_GEOFENCES_DATA_STATUS",
  SET_MANAGE_GEOFENCES_ACTION_STATUS = "SET_MANAGE_GEOFENCES_ACTION_STATUS",

  LOADING_MANAGE_GEOFENCES_ASSETS = "LOADING_MANAGE_GEOFENCES_ASSETS",
  SET_MANAGE_GEOFENCES_ASSETS = "SET_MANAGE_GEOFENCES_ASSETS",
  SET_MANAGE_GEOFENCES_ASSETS_FILTER = "SET_MANAGE_GEOFENCES_ASSETS_FILTER",
  SET_MANAGE_GEOFENCES_ASSETS_STATUS = "SET_MANAGE_GEOFENCES_ASSETS_STATUS",

  LOADING_MANAGE_GEOFENCES_ALL_SHORT_DATA = "LOADING_MANAGE_GEOFENCES_ALL_SHORT_DATA",
  SET_MANAGE_GEOFENCES_ALL_SHORT_DATA = "SET_MANAGE_GEOFENCES_ALL_SHORT_DATA",
  SET_MANAGE_GEOFENCES_ALL_SHORT_DATA_STATUS = "SET_MANAGE_GEOFENCES_ALL_SHORT_DATA_STATUS",

  RESET_MANAGE_GEOFENCES_REDUCER = "RESET_MANAGE_GEOFENCES_REDUCER",

  SET_GEOFENCES_LIST_FILTERS = "SET_GEOFENCES_LIST_FILTERS",
}

export type GeofenceBodyType = Pick<
  IGeofence,
  "name" | "geofence" | "shape" | "location_type_id"
>;

export type IMapAsset = {
  id: number
  longitude: number
  latitude: number
  hasCriticalWarning: boolean
  hasWarning: boolean
}

export interface ManageGeofencesReducer {
  isLoading: boolean;
  data: Array<IGeofence>;
  totalCount: number;
  limit: number;
  offset: number;
  showDeleted: boolean;
  dataStatus: IStatus;
  actionStatus: IStatus;
  assets: {
    isLoading: boolean;
    data: Array<IMapAsset>;
    filter: string;
    status: IStatus;
  };
  allShortData: {
    isLoading: boolean;
    data: Array<IShortGeofence>;
    status: IStatus;
  };
  filters: string
}

type LoadingManageGeofences = SetLoading<ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES>;

type LoadingManageGeofencesAssets = SetLoading<ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ASSETS>;

type LoadingManageGeofencesAllShortData = SetLoading<ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ALL_SHORT_DATA>;

type SetManageGeofencesDataStatus = SetStatus<ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA_STATUS>;

type SetManageGeofencesActionStatus = SetStatus<ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS>;

type SetManageGeofencesAssetsStatus = SetStatus<ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_STATUS>;

type SetManageGeofencesAllShortDataStatus = SetStatus<ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA_STATUS>;

interface SetData {
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA;
  payload: Array<IGeofence>;
}

type SetManageGeofencesNumber = SetNumber<
  | ManageGeofencesTypes.SET_MANAGE_GEOFENCES_COUNT
  | ManageGeofencesTypes.SET_MANAGE_GEOFENCES_LIMIT
  | ManageGeofencesTypes.SET_MANAGE_GEOFENCES_OFFSET
>;

type SetManageGeofencesShowDeleted = SetBoolean<ManageGeofencesTypes.SET_MANAGE_GEOFENCES_SHOW_DELETED>

interface SetAssets {
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS;
  payload: Array<IMapAsset>;
}

interface SetAssetsFilter {
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_FILTER;
  payload: string;
}

interface SetAllShortData {
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA;
  payload: Array<IShortGeofence>;
}

interface ResetManageGeofencesReducer {
  type: ManageGeofencesTypes.RESET_MANAGE_GEOFENCES_REDUCER;
}

interface SetGeofencesListFiltersReducer {
  type: ManageGeofencesTypes.SET_GEOFENCES_LIST_FILTERS;
  payload: string
}

export type ManageGeofencesAction =
  | LoadingManageGeofences
  | SetManageGeofencesDataStatus
  | SetManageGeofencesActionStatus
  | SetData
  | SetManageGeofencesNumber
  | SetManageGeofencesShowDeleted
  | ResetManageGeofencesReducer
  | LoadingManageGeofencesAssets
  | SetManageGeofencesAssetsStatus
  | SetAssets
  | SetAssetsFilter
  | LoadingManageGeofencesAllShortData
  | SetAllShortData
  | SetManageGeofencesAllShortDataStatus
  | SetGeofencesListFiltersReducer
