import { kmhToMph, metersToFt } from '../../utils'
import {
  chartDatasetSort,
  composeVertLineAnnotation,
} from '../../utils/chartUtils'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

export const composeLineChartData = (
  data,
  unitsOfMeasurementConfig,
  gpsColors,
) => {
  const charts = {
    elevation: {
      datasets: [
        {
          label: 'Elevation',
          data: [],
          fill: false,
          backgroundColor: gpsColors.find((el) =>
            el.id === 'GpsElevation' ? true : false,
          ).color,
          borderColor: gpsColors.find((el) =>
            el.id === 'GpsElevation' ? true : false,
          ).color,
          borderWidth: 2,
        },
      ],
    },
    speed: {
      datasets: [
        {
          label: 'Speed',
          data: [],
          fill: false,
          backgroundColor: gpsColors.find((el) =>
            el.id === 'GpsSpeed' ? true : false,
          ).color,
          borderColor: gpsColors.find((el) =>
            el.id === 'GpsSpeed' ? true : false,
          ).color,
          borderWidth: 2,
        },
      ],
    },
  }

  data.map((item) => {
    const elevation =
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.miles
        ? metersToFt(item.elevation).toFixed(1)
        : item.elevation
    const speed =
      unitsOfMeasurementConfig?.speed === UnitsOfMeasurementConfig.speed.mph
        ? kmhToMph(item.speed).toFixed(2)
        : item.speed

    const elevationData = {
      x: item.datetime,
      y: elevation,
      displayValue: `Elevation: ${elevation} ${
        unitsOfMeasurementConfig?.distance ===
        UnitsOfMeasurementConfig.distance.miles
          ? 'ft'
          : 'm'
      }`,
      displayDate: item.formatted_datetime,
    }
    const speedData = {
      x: item.datetime,
      y: speed,
      displayValue: `Speed: ${speed} ${
        unitsOfMeasurementConfig?.distance ===
        UnitsOfMeasurementConfig.distance.miles
          ? 'mph'
          : 'kmh'
      }`,
      displayDate: item.formatted_datetime,
    }

    charts.elevation.datasets[0].data.push(elevationData)
    return charts.speed.datasets[0].data.push(speedData)
  })

  // sorting for sequential connection between line dots
  chartDatasetSort(charts.elevation.datasets[0].data)
  chartDatasetSort(charts.speed.datasets[0].data)

  return charts
}

export const composeAnnotations = (id, timestamp, healthColors) => {
  const annotations = {}

  if (timestamp) {
    annotations.verticalLine = composeVertLineAnnotation(
      id,
      timestamp,
      healthColors[1].color,
    )
  }

  return annotations
}
