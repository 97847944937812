export interface IGeofenceGroup {
    id: number
    name: string
    customer_id: number
    customer_name: string
    geofences: Array<{ id: number, name: string }> | null
}

export interface IDetailsSort {
    column: string;
    direction: "asc" | "desc";
}

export interface IGeofenceGroupDetails {
    id?: number
    name?: string
    assigned_geofences: {
        paged_data: Array<{ geofence_type: string, id: number, make: string, model: string, name: string, vin: string, year: number, subscriptions: object }>,
        total_count: number
    }
    unassigned_geofences: {
        paged_data: Array<{ geofence_type: string, id: number, make: string, model: string, name: string, vin: string, year: number }>,
        total_count: number
    }
}

export interface IGeofenceGroupsResponse {
    paged_data: Array<IGeofenceGroup>
    total_count: number,
    geofences_count_without_groups: number
}

export enum GeofenceGroupsTypes {
    LOADING_GEOFENCE_GROUPS = "LOADING_GEOFENCE_GROUPS",
    SET_GEOFENCE_GROUPS = "SET_GEOFENCE_GROUPS_DATA",
    SET_GEOFENCE_GROUPS_STATUS = "SET_GEOFENCE_GROUPS_STATUS",
    SET_GEOFENCE_GROUPS_OFFSET = "SET_GEOFENCE_GROUPS_OFFSET",
    SET_GEOFENCE_GROUPS_LIMIT = "SET_GEOFENCE_GROUPS_LIMIT",

    LOADING_GEOFENCE_GROUPS_DETAILS = "LOADING_GEOFENCE_GROUPS_DETAILS",
    SET_GEOFENCE_GROUPS_DETAILS = "SET_GEOFENCE_GROUPS_DETAILS",
    SET_GEOFENCE_GROUPS_DETAILS_STATUS = "SET_GEOFENCE_GROUPS_DETAILS_STATUS",

    SET_GEOFENCE_GROUPS_LIMITED_DATA = "SET_GEOFENCE_GROUPS_LIMITED_DATA",
    SET_GEOFENCES_GROUPS_DETAILS_OFFSET = "SET_GEOFENCES_GROUPS_DETAILS_OFFSET",
    SET_GEOFENCES_GROUPS_DETAILS_SORT_OPTIONS = "SET_GEOFENCES_GROUPS_DETAILS_SORT_OPTIONS",
    SET_GEOFENCES_GROUPS_DETAILS_LIMIT = "SET_GEOFENCE_GROUPS_DETAILS_LIMIT"
}

export interface GeofenceGroupsReducer {
    geofenceGroups: {
        isLoading: boolean,
        data: Array<IGeofenceGroup> | null,
        status: IStatus,
        limit: number,
        offset: number,
        totalCount: number,
        limitedData: Array<IGeofenceGroup> | [],
        unassignedGeofencesCount: number
    },
    geofenceGroupsDetails: {
        isLoading: boolean
        data: IGeofenceGroupDetails,
        limit: number,
        offset: number,
        status: IStatus,
        sortOptions: IDetailsSort
    }
}

interface ISetGeofenceGroups { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS, payload: IGeofenceGroupsResponse }
interface ISetGeofenceGroupsStatus { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_STATUS, payload: IStatus }
interface ISetGeofenceGroupsLoading { type: GeofenceGroupsTypes.LOADING_GEOFENCE_GROUPS, payload: boolean }
interface ISetGeofenceGroupsOffset { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_OFFSET, payload: number }
interface ISetGeofenceGroupsLimit { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMIT, payload: number }

interface ISetGeofenceGroupsDetailsLoading { type: GeofenceGroupsTypes.LOADING_GEOFENCE_GROUPS_DETAILS, payload: boolean }
interface ISetGeofenceGroupsDetails { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_DETAILS, payload: IGeofenceGroupDetails }
interface ISetGeofenceGroupsDetailsStatus { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_DETAILS_STATUS, payload: IStatus }
interface ISetGeofenceGroupsDetailsOffset { type: GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_OFFSET, payload: number }
interface ISetGeofenceGroupsDetailsLimit { type: GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_LIMIT, payload: number }

interface ISetGeofenceGroupsLimitedData { type: GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMITED_DATA, payload: Array<IGeofenceGroup> }

export type GeofenceGroupsActions = ISetGeofenceGroups | ISetGeofenceGroupsStatus | ISetGeofenceGroupsLoading | ISetGeofenceGroupsOffset | ISetGeofenceGroupsLimit | ISetGeofenceGroupsDetailsLoading | ISetGeofenceGroupsDetails | ISetGeofenceGroupsDetailsStatus | ISetGeofenceGroupsLimitedData | ISetGeofenceGroupsDetailsOffset | ISetGeofenceGroupsDetailsLimit
