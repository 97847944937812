import React, { useState } from "react";

import { Spinner, Fade } from "reactstrap";

import Trailer from "./Trailer";

import tractor from "../../../assets/images/tpms_status_diagram/TractorTrailer.svg";

import TimeSinceGpsUpdate from "../common/TimeSinceGpsUpdate";

import "./style.scss";

const TrailerDiagram = ({ statuses, pressureUnit, getTireData, timezone, locale, thresholds, lastUpdatedAtDateTime }) => {
  const width = 1200;
  const height = 300;
  const [imageLoaded, setImageLoaded] = useState(false);

  const { assetTpmsStatus:{sensors},assetSmartStatus, assetAxlesLoads, assetLinePressureStatus } = statuses
  const isAnyStatus = !!(sensors?.length || assetSmartStatus?.length || assetAxlesLoads?.length || assetLinePressureStatus?.length)

  return (
    <div className="d-flex justify-content-center align-items-center diagram-container">
      {statuses.isLoading && !imageLoaded ? (
        <Spinner />
      ) : isAnyStatus  && (
        <Fade in={!statuses.isLoading && imageLoaded} timeout={200}>
          <div className="d-flex align-items-center justify-content-center" style={{ width: `${width}px`, height: `${height}px`, marginBottom: "90px" }}>
            <Trailer
              height={height}
              sensors={statuses.assetTpmsStatus?.sensors}
              smart={statuses.assetSmartStatus}
              line={statuses.assetLinePressureStatus}
              axlesLoads={statuses.assetAxlesLoads}
              thresholds={thresholds}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              timezone={timezone}
              locale={locale}
            />

            <img className="tractor" src={tractor} alt="tractor" height={height} onLoad={() => setImageLoaded(true)} />
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center">
            <span style={{ fontSize: "18px", marginBottom: "15px", marginTop: "30px"}}>
              Asset Health Status
            </span>
            <TimeSinceGpsUpdate lastUpdatedAtDateTime={lastUpdatedAtDateTime} />
          </div>
        </Fade>
      )}
    </div>
  );
};

export default TrailerDiagram;
