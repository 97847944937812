import React from "react";
import { Row, Col } from "reactstrap";
import './styles.scss'
import { IAssetGroupFormValues } from "./types";
import { Link } from "react-router-dom";
import CustomTooltip from "../../components/Header/CustomTooltip";

interface Props {
    group: any, // todo: define type
    onEditClick?: (c: IAssetGroupFormValues) => void,
    onDeleteClick?: (c: IAssetGroupFormValues) => void
}

const GroupItem: React.FC<Props> = ({
    group,
    onEditClick = () => null,
    onDeleteClick = () => null
}) => {
    const deleteButtonId = `delete_asset_group_${group.id}`;
    const updateButtonId = `update_asset_group_${group.id}`;
    const selectButtonId = `select_asset_group_${group.id}`;
    return (
        <Row className={`group-item rounded align-items-center pl-3 pr-3 pt-1 pb-1 ml-2 mr-2 mb-3`}>
            <Col md="1">

                <Row className="asset-group__controls">
                    <div className="pl-2 pr-2">
                        <span id={deleteButtonId} className="fa fa-xmark" onClick={() => { onDeleteClick({ id: group.id, name: group.name }) }}></span>
                        <CustomTooltip target={deleteButtonId} text="Delete Group" />
                    </div>
                    <div className="pl-2 pr-2">
                        <span id={updateButtonId} className="fa fa-edit" onClick={() => { onEditClick({ id: group.id, name: group.name }) }}></span>
                        <CustomTooltip target={updateButtonId} text="Edit Group Name" />
                    </div>
                </Row>

            </Col>
            <Col md="11">
                <Link to={`/asset-groups/${group.id}`}>
                    <Row id={selectButtonId}>
                        <div className="pr-3">
                            <b>{group.name}</b>
                        </div>
                        <div>
                            {group.assets ? group.assets.length : 0} assets total
                        </div>
                        <CustomTooltip 
                            target={selectButtonId}
                            text="Select to add or remove assets from group"
                        />
                    </Row>
                </Link>
            </Col>
        </Row>
    );
};

export default GroupItem;