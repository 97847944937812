import React, { useState } from "react"
import { Tooltip } from "reactstrap"

interface Props {
    target: string
    text: string
}

const CustomTooltip: React.FC<Props> = ({ target, text }) => {
    const [tooltipOpened, setTooltipOpened] = useState(false)

    return (
        <Tooltip
            hideArrow={true}
            placement="auto"
            isOpen={tooltipOpened}
            target={target}
            toggle={() => setTooltipOpened(!tooltipOpened)}
        >
            {text}
        </Tooltip>
    )
}

export default CustomTooltip
