import React, { useEffect, useRef } from 'react'
import { JSONEditor, JSONEditorPropsOptional, Mode } from 'vanilla-jsoneditor'
import 'vanilla-jsoneditor/themes/jse-theme-dark.css'

const ReactJSONEditor: React.FC<JSONEditorPropsOptional> = (props) => {
  const refContainer = useRef<HTMLDivElement>(null)
  const refEditor = useRef<JSONEditor | null>(null)

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current as HTMLDivElement,
      props: {},
    })

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy()
        refEditor.current = null
      }
    }
  }, [])

  // update props
  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps({
        mode: Mode.text,
        mainMenuBar: false,
        statusBar: false,
        ...props,
      })
    }
  }, [props])

  return <div className='json-editor jse-theme-dark' ref={refContainer} />
}

export default ReactJSONEditor
