

import React, { useCallback, useEffect, useState } from 'react'
import { Input, Button } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

type Props = {
  locale?: ILocale
}

const CargoTagsPreferences: React.FC<Props> = ({
  locale = {}
}) => {
  const {
    customers: { selectedCustomersList, loading: isLoading }
  } = useTypedSelector((state) => ({
    customers: state.common.customers
  }));

  const [lostTagDays, setLostTagDays] = useState(selectedCustomersList[0]?.preferences.cargo_tag_lost_tag_days ?? 30)

  useEffect(() => {
    setLostTagDays(selectedCustomersList[0]?.preferences.cargo_tag_lost_tag_days ?? 30)
  }, [selectedCustomersList])

  const {
    updateCustomer
  } = useActions();

  const onSave = useCallback(() => {
    updateCustomer({
      id: selectedCustomersList[0].id,
      preferences: {
        cargo_tag_lost_tag_days: lostTagDays
      }
    })
  }, [updateCustomer, selectedCustomersList, lostTagDays])


  return (
    <RivataModule
      title="Cargo Tags Preferences"
      filters={null}
      locale={locale}
      collapsible
      defaultCollapsed
    >
      {selectedCustomersList.length > 1 ? (
        <StatusAlert
          statusCode={null}
          statusText={null}
          customText={"Cargo Tags Preferences can not be edited when Multiple customers are selected. Please select a single customer in dropdown."}
          color="success"
        />
      ) : (
        <>
          <label> Lost tag number of days threshold </label>

          <Input
            type="number"
            className="col-1"
            value={lostTagDays}
            onChange={(e: any) => setLostTagDays(e.target.value)}
            disabled={isLoading}
          />

          <div className="d-flex justify-content-end">
            <Button
              className="border-0"
              color="primary"
              onClick={onSave}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </> 
      )}
    </RivataModule>
  )
}

export default CargoTagsPreferences