import React from 'react'
import { Redirect } from 'react-router-dom'
import { Pages } from '../enums'
import { useTypedSelector } from '../hooks/useTypedSelector'

const withWhiteLabelEditPermissionsCheck = (Page) => {
  const WithSubCheck = (props) => {
    const {
      isDefaultsLoading,
      isSuperAdmin,
      isAdmin,
      canFleetAdminEditWlSettings,
      whiteLabelLoaded,
    } = useTypedSelector((state) => ({
      isDefaultsLoading: state.common.isDefaultsLoading,
      isSuperAdmin: state.auth.isSuperAdmin,
      isAdmin: state.auth.isAdmin,
      canFleetAdminEditWlSettings:
        state.whitelabel.preferenceDefaults?.permissions
          ?.canFleetAdminEditWlSettings,
      whiteLabelLoaded: state.whitelabel.whiteLabelLoaded,
    }))
    // return all while subscription isn't loaded or user is super admin
    if (
      Pages.WhiteLabel !== props.componentName ||
      isSuperAdmin ||
      isDefaultsLoading ||
      (Pages.WhiteLabel === props.componentName &&
        (!whiteLabelLoaded || (isAdmin && canFleetAdminEditWlSettings)))
    )
      return <Page {...props} />

    return <Redirect to='/' />
  }

  return WithSubCheck
}

export default withWhiteLabelEditPermissionsCheck
