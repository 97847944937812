import React, { useCallback, useMemo, useState } from 'react'
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { UnitsOfMeasurementConfig } from '../../constants/constants'
import { barToPsi, fahrToCelsius } from '../../utils'
import { IAssetSensors, IVibrationWarning } from './types'

enum warningTypes {
  smarthubTemp = 'smarthubTemp',
  smarthubVib = 'smarthubVib',
  tpms = 'tpms',
  linePressure = 'linePressure',
  axleLoad = 'axleLoad',
}

type Props = {
  assetSensors: IAssetSensors
  onAddWarning: (
    warningType: string,
    mac: string,
    value: number | IVibrationWarning,
  ) => void
  unitsOfMeasurementConfig: UnitsOfMeasurement
  disabled?: boolean
}

const AddSensorDataModal: React.FC<Props> = ({
  assetSensors,
  onAddWarning,
  disabled,
  unitsOfMeasurementConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [warningType, setWarningType] = useState(warningTypes.smarthubTemp)
  const [sensorMac, setSensor] = useState('')
  const [value, setValue] = useState(0)
  const [vibrationWarning, setVibrationWarning] = useState<IVibrationWarning>({
    value: 0.5,
    bearing: false,
    tire: false,
  })

  const warningTypeOptions = useMemo(() => {
    const options: Array<{ value: warningTypes; label: string }> = []

    if (assetSensors.smarthub_sensors)
      options.push(
        { value: warningTypes.smarthubTemp, label: 'SmartHub temperature' },
        { value: warningTypes.smarthubVib, label: 'SmartHub vibration' },
      )

    if (assetSensors.tpms)
      options.push({ value: warningTypes.tpms, label: 'TPMS' })

    if (assetSensors.line_pressure)
      options.push({ value: warningTypes.linePressure, label: 'Line pressure' })

    if (assetSensors.axle_load)
      options.push({ value: warningTypes.axleLoad, label: 'Axle load' })

    return options
  }, [assetSensors])

  const sensors = useMemo(() => {
    if (warningType === 'smarthubTemp' || warningType === 'smarthubVib')
      return assetSensors.smarthub_sensors

    if (warningType === 'tpms') return assetSensors.tpms
  }, [warningType, assetSensors])

  const reset = useCallback(() => {
    setWarningType(warningTypeOptions[0].value ?? warningTypes.smarthubTemp)
    setSensor('')
    setValue(0)
    setVibrationWarning({
      value: 0.5,
      bearing: false,
      tire: false,
    })
  }, [warningTypeOptions])

  const toggle = useCallback(() => {
    if (!isOpen) reset()
    setIsOpen(!isOpen)
  }, [isOpen, reset])

  const onAdd = useCallback(() => {
    const warningValue =
      warningType === 'smarthubVib'
        ? vibrationWarning
        : warningType === 'smarthubTemp'
        ? unitsOfMeasurementConfig.temperature ===
          UnitsOfMeasurementConfig.temperature.fahrenheit
          ? +fahrToCelsius(value).toFixed(1)
          : value
        : unitsOfMeasurementConfig.pressure ===
          UnitsOfMeasurementConfig.pressure.psi
        ? value
        : +barToPsi(value).toFixed(1)

    if (
      !sensorMac &&
      warningType !== warningTypes.linePressure &&
      warningType !== warningTypes.axleLoad
    )
      return

    onAddWarning(warningType, sensorMac, warningValue)
    setIsOpen(false)
  }, [
    onAddWarning,
    sensorMac,
    value,
    vibrationWarning,
    warningType,
    unitsOfMeasurementConfig,
  ])

  const disableAddButton = useMemo(() => {
    const sensorNotSelected =
      !sensorMac &&
      warningType !== warningTypes.linePressure &&
      warningType !== warningTypes.axleLoad

    const badVibrationValue =
      warningType === warningTypes.smarthubVib &&
      vibrationWarning.value >= 1.5 &&
      !(vibrationWarning.bearing || vibrationWarning.tire)

    return sensorNotSelected || badVibrationValue
  }, [sensorMac, warningType, vibrationWarning])

  return (
    <>
      <Button onClick={toggle} disabled={disabled}>
        Add Sensor Data
      </Button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Sensor Data</ModalHeader>

        <ModalBody>
          <label>Sensor Type:</label>

          <Input
            type='select'
            value={warningType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              reset()
              setWarningType(e.target.value as warningTypes)
            }}
          >
            {warningTypeOptions.map((wt) => (
              <option key={wt.value} value={wt.value}>
                {wt.label}
              </option>
            ))}
          </Input>

          {sensors && (
            <>
              <label className='mt-3'>Sensor:</label>

              <Input
                type='select'
                value={sensorMac}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSensor(e.target.value)
                }
              >
                <option value='' />

                {sensors.map((sensor) => (
                  <option key={sensor.mac} value={sensor.mac}>
                    {sensor.mac} | {sensor.position}
                  </option>
                ))}
              </Input>
            </>
          )}

          {warningType !== 'smarthubVib' ? (
            <>
              <label className='mt-3'>
                {warningType === 'smarthubTemp'
                  ? unitsOfMeasurementConfig.temperature ===
                    UnitsOfMeasurementConfig.temperature.fahrenheit
                    ? 'Temperature (\u00B0F):'
                    : 'Temperature (\u00B0C):'
                  : unitsOfMeasurementConfig.pressure ===
                    UnitsOfMeasurementConfig.pressure.psi
                  ? 'Pressure (psi):'
                  : 'Pressure (bar):'}
              </label>

              <Input
                type='number'
                value={value}
                onChange={(e: any) => {
                  let value = +e.target.value
                  if (warningType === 'smarthubTemp') {
                    if (
                      unitsOfMeasurementConfig.temperature ===
                      UnitsOfMeasurementConfig.temperature.fahrenheit
                    ) {
                      value =
                        value < -41.8 ? -41.8 : value > 298.4 ? 298.4 : value
                    } else {
                      value = value < -41 ? -41 : value > 148 ? 148 : value
                    }
                  }
                  if (
                    ['tpms', 'linePressure', 'axleLoad'].includes(warningType)
                  ) {
                    if (
                      unitsOfMeasurementConfig.pressure ===
                      UnitsOfMeasurementConfig.pressure.psi
                    ) {
                      value = value < 0 ? 0 : value > 190 ? 190 : value
                    } else {
                      value = value < 0 ? 0 : value > 13.1 ? 13.1 : value
                    }
                  }

                  setValue(value)
                }}
              />
            </>
          ) : (
            <>
              <label className='mt-3'>Vibration Level:</label>
              <Input
                type='select'
                value={vibrationWarning.value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setVibrationWarning({
                    ...vibrationWarning,
                    value: +e.target.value,
                  })
                }}
              >
                <option value={0.5}>Normal</option>
                <option value={1.75}>Abnormal</option>
                <option value={2.5}>Abnormal High</option>
              </Input>

              <label className='mt-2'>Type:</label>
              <div>
                <label className='ml-3' style={{ width: '60px' }}>
                  Bearing:
                </label>
                <input
                  type='checkbox'
                  checked={vibrationWarning.bearing}
                  onChange={(e: any) => {
                    setVibrationWarning({
                      ...vibrationWarning,
                      bearing: e.target.checked,
                    })
                  }}
                />
              </div>

              <div>
                <label className='ml-3' style={{ width: '60px' }}>
                  Tire:
                </label>
                <input
                  type='checkbox'
                  checked={vibrationWarning.tire}
                  onChange={(e: any) => {
                    setVibrationWarning({
                      ...vibrationWarning,
                      tire: e.target.checked,
                    })
                  }}
                />
              </div>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color='primary'
            className='mr-2'
            onClick={onAdd}
            disabled={disableAddButton}
          >
            Add Sensor Data
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddSensorDataModal
