import React, { RefObject, useEffect, useRef, useState } from "react"
import { Label, Button } from "reactstrap"

import { useFilteredData } from "./hooks"
import { RoutesWithBlockedAllOption } from "../../enums"
import { ICustomerListItem } from "."


interface Props {
  data: Array<ICustomerListItem>
  currentSelected: Array<ICustomer>
  isAllSelected: boolean
  isMenuOpen: boolean
  handleModalClickOutside: (e: Event, menu: RefObject<any>) => void
  handleCheck: (item: ICustomerListItem | null, state?: boolean, isAll?: boolean) => void
  onClear: () => void | null
  onCancel: () => void
  onSubmit: () => void
  disableAllOption?: boolean
}

const blockList: Array<string> = Object.values(RoutesWithBlockedAllOption)

const CustomMenu: React.FC<Props> = ({
  data, 
  currentSelected, 
  isAllSelected,
  isMenuOpen,
  handleModalClickOutside,
  handleCheck,
  onClear,
  onCancel,
  onSubmit,
  disableAllOption,
}) => {
  const menu = useRef<any>()
  const pathPart = window.location.pathname.split("/")[1]
  const allDisabled = blockList.includes(pathPart)
  const ids = currentSelected.map(el => el.id)

  const [searchValue, setSearchValue] = useState("")

  const filteredData = useFilteredData(data, searchValue)

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      handleModalClickOutside(e, menu)
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [isMenuOpen, handleModalClickOutside])

  if (!isMenuOpen) return null

  return (
    <div ref={menu} className="custom__menu">
      <div className="menu__control mr-2">
        <input 
          className="menu__input" 
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          autoFocus
        />
      </div>

      <div className="menu__body">
        <ul className="menu__list">
          
          {!searchValue.length && (
            <li className="parent__elem" >
              <div className="d-flex align-items-center" >
                <input
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={(e) => handleCheck(null, e.target.checked, true)}
                  disabled={allDisabled || disableAllOption}
                />
                <Label className="m-0 ml-2">All</Label>
              </div>
            </li>
          )}
          
          {!filteredData.length ? (
            <li className="status__message">
              Not found...
            </li>
          ) : null}
          {filteredData.map((item, i) => {
            return (
              <li key={item.name + i} className="parent__elem" >
                
                {!item.isLabel ? (
                  <div 
                    className="d-flex align-items-center" 
                    onClick={() => handleCheck(item)}
                  >
                    <input
                      type="checkbox"
                      checked={ids.includes(item.id)}
                      readOnly
                      disabled={!currentSelected.map(c => c.id).includes(item.id) && allDisabled && currentSelected.length === 1}
                    />
                    <Label className="m-0 ml-2">{item.name}</Label>
                  </div>
                ) : (
                  <Label className="m-0 ml-2">{item.name}</Label>
                )}

                {item.children.length > 0 ? (
                  <ul className="child_elem">
                    {item.children.map((child, k) => {
                      return (
                        <li key={child.name + k}>
                          <div 
                            className="d-flex align-items-center ml-2"
                            onClick={(e) => handleCheck(child)}  
                          >
                            <input
                              type="checkbox"
                              checked={ids.includes(child.id)}
                              readOnly
                              disabled={!currentSelected.map(c => c.id).includes(child.id) && allDisabled && currentSelected.length === 1}
                            />
                            <Label className="m-0 ml-2">{child.name}</Label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}

              </li>
            );
          })}
        </ul>
      </div>

      <div className="d-flex justify-content-end mr-2">
        <Button 
          color="primary" 
          className="mr-2"
          onClick={onSubmit}
          disabled={!currentSelected.length || (allDisabled && currentSelected.length !== 1)}
        >
          Ok
        </Button>
        <Button 
          size="sm"
          className="mr-2"
          onClick={onClear}
        >
          Clear
        </Button>
        <Button 
          color="danger"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default CustomMenu