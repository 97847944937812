import { RootState } from '../redux/reducers'
import { TypedUseSelectorHook, useSelector, shallowEqual } from 'react-redux'

// for better performance select only thoose states which you use. Otherwwise there will be useless re-renders

// Example 1
// selector = state => ({ common: state.common })
// const { common: { customers: { data } } } = useTypedSelector(selector)
// in this case you subscribe your component to all changes in common reducer
// ================ BAD PRACTICE =====================

// Example 2
// selector = state => ({ data: state.common.customers.data })
// const { data } = useTypedSelector(selector)
// ================ ALL GOOD =========================

export const useTypedSelector: TypedUseSelectorHook<RootState> = (
  selector: any,
) => {
  return useSelector(selector, shallowEqual)
}
