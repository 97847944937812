import React from 'react'
import VehicleInfo from '../../modules/VehicleInfo'
import SensorNodeInfo from '../../modules/SensorNodeInfo'
import TpmsStatusDiagram from '../../modules/TpmsStatusDiagram'
import RecentWarnings from '../../modules/RecentWarnings'
import DetailsMap from '../../modules/DetailsMap'
import ChartsControl from '../../modules/ChartsControl'
import TemperatureChart from '../../modules/TemperatureChart'
// import HubVibration from '../../modules/HubVibration'
import TirePressureChart from '../../modules/TirePressureChart'
import AxleLoadChart from '../../modules/AxleLoadChart'
import LinePressureChart from '../../modules/LinePressureChart'
import PowerInputsChart from '../../modules/PowerInputsChart'
import LocationChart from '../../modules/LocationChart'
import VibrationChart from '../../modules/VibrationChart'
import { DetailsComponents } from '../../enums'

interface IPageData {
  vin: string
  timestamp: number | null
  warningKey?: string
  days?: string
}

export const getComponent = (
  id: string,
  width: number,
  pageData: IPageData,
) => {
  switch (id) {
    case DetailsComponents.VehicleInfo:
      return <VehicleInfo key={id} width={width} />
    case DetailsComponents.SensorNode:
      return <SensorNodeInfo key={id} width={width} />
    case DetailsComponents.TpmsStatusDiagram:
      return <TpmsStatusDiagram key={id} />
    case DetailsComponents.RecentWarnings:
      return <RecentWarnings key={id} width={width} vin={pageData.vin} />
    case DetailsComponents.DetailsMap:
      return <DetailsMap key={id} width={width} />
    case DetailsComponents.ChartsControl:
      return <ChartsControl key={id} width={width} />
    case DetailsComponents.TemperatureChart:
    case DetailsComponents.TemperatureHistory:
      return <TemperatureChart key={id} width={width} />
    case DetailsComponents.HubVibration:
      return <VibrationChart key={id} width={width} />
    case DetailsComponents.TirePressure:
      return <TirePressureChart key={id} width={width} />
    case DetailsComponents.AxleLoad:
      return <AxleLoadChart key={id} width={width} />
    case DetailsComponents.LinePressure:
      return <LinePressureChart key={id} width={width} />

    case DetailsComponents.LocationChart:
      return <LocationChart key={id} width={width} />
    case DetailsComponents.PowerInputsChart:
      return <PowerInputsChart key={id} width={width} />
    default:
      return null
  }
}
