import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { Row, Col, FormGroup, Label } from 'reactstrap'

import RivataFormField from '../../../../components/RivataFormField'

import { CustomInput, Input, Button } from 'reactstrap'
import { SelectFormField } from '../../../../components/CreateModal/children'
import {
  convertDataEpochToDate,
  formatType,
  getReportEndRuleDate,
} from '../../../../utils'
import ScheduledReportUsers from '../ScheduledReportUsers'
import { useActions } from '../../../../hooks/useActions'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { ScheduledReportFormValues } from '../../hooks'
import ScheduledReportCustomerSelector from '../ScheduledReportCustomerSelector'
import {
  AssetGroupSelectorReports,
  AssetHealthSelectorReports,
  AssetTypeSelectorReports,
  AssetTypes,
  AssetsSelectorReports,
  CustomerSelectorReports,
  DaysSelectorReports,
  GeofencesSelectorReports,
  ScheduledReportsWithDateRange,
  SingleCustomerItemReports,
  UserSelectorReports,
  WarningTypeReports,
  WarningTypes,
  GeofenceGroupSelectorReports,
} from '../../../../constants/constants'
import TimezonePicker from '../../../../components/TimezonePicker'
import ItemsSelectWithSearch from '../../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'
import api from '../../../../services/api'
import { SearchItemType } from '../../../../components/ItemsSearch'
import { EndPointConfigs } from '../../../../components/BackendSearchInput/constants'
import {
  CronDisplacementModes,
  CronModes,
  InternalScheduledReportTypes,
  ReportDateRangeTypes,
  ScheduledReportTypes,
} from '../../../../enums'
import { cloneDeep } from 'lodash'
import cronstrue from 'cronstrue'
import moment from 'moment'
import { selectedWeekDays } from '../../../../utils/utils'
import _ from 'lodash'
import InfoModal from '../../../../components/InfoModal'
import AssetGroupsFilter from '../../../../componentsV2/AssetGroupsFilter'
import DatesDropdown from '../../../../components/DatesDropdown'
import { sensorStatusItems } from '../../../../componentsV2/HealthStatusFilter/items'
import HealthStatusFilter from '../../../../componentsV2/HealthStatusFilter'
import DwellDaysInput from '../../../ReportsFormSwitch/Forms/DwellDaysInput'
import GeofenceGroupsFilter from '../../../../componentsV2/GeofenceGroupsFilter'

const sensorStatusItemsFiltered = sensorStatusItems.filter(
  (s) => s.id !== 'ok' && s.id !== 'no_status' && s.parentId !== 'no_status',
)

const endRuleDropdownItems = [
  { value: null, label: null },
  { value: 'times', label: 'Times' },
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
]

const rangeOptions = [
  { value: ReportDateRangeTypes.Today, label: 'Today' },
  { value: ReportDateRangeTypes.Yesterday, label: 'Yesterday' },
  { value: ReportDateRangeTypes.ThisWeek, label: 'This Week' },
  { value: ReportDateRangeTypes.LastWeek, label: 'Last Week' },
  { value: ReportDateRangeTypes.ThisMonth, label: 'This Month' },
  { value: ReportDateRangeTypes.LastMonth, label: 'Last Month' },
  { value: ReportDateRangeTypes.Past24H, label: 'Past 24H' },
  { value: ReportDateRangeTypes.Past7Days, label: 'Past 7 Days' },
  { value: ReportDateRangeTypes.Past30Days, label: 'Past 30 Days' },
]

interface Props {
  initialValues: ScheduledReportFormValues
  setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
  parameters: any
  setParameters: React.Dispatch<React.SetStateAction<any>>
  isDisabled: boolean
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
  creationMode: boolean
  setShowCronModal: React.Dispatch<React.SetStateAction<boolean>>
  frequency: string
  internalReports: boolean
  timezone: string
  setTimezone: React.Dispatch<React.SetStateAction<string>>
  setCustomerId: React.Dispatch<React.SetStateAction<number | null>>
  selectedCustomerId: number | null
  setFrequency: React.Dispatch<React.SetStateAction<string>>
  endRuleEnabled: boolean
  setEndRuleEnabled: React.Dispatch<React.SetStateAction<boolean>>
  formRef: React.RefObject<any>
}

const EditFormInputs: React.FC<Props> = ({
  initialValues,
  setIsFormTouched,
  parameters,
  setParameters,
  creationMode,
  isDisabled,
  setIsDisabled,
  setShowCronModal,
  frequency,
  internalReports,
  timezone,
  setTimezone,
  setCustomerId,
  selectedCustomerId,
  setFrequency,
  endRuleEnabled,
  setEndRuleEnabled,
  formRef,
}) => {
  const {
    locale,
    customersList,
    selectedCustomersList,
    scheduledReports: { users },
  } = useTypedSelector((state) => ({
    locale: state.whitelabel.locale,
    customersList: state.common.customers.data,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    scheduledReports: state.scheduledReports,
  }))

  const usersColumnsToShow = [{ key: 'username', name: 'Username' }]
  const context = useFormikContext<any>()
  const { fetchScheduledReportsUsers } = useActions()

  const columnsToShow = [
    { key: 'name', name: 'Name' },
    { key: 'vin', name: 'VIN' },
  ]
  const geofenceColumnsToShow = [{ key: 'name', name: 'Name' }]
  const [reportId, setReportId] = useState(initialValues.id)
  const [bccUsersToEmail, setBccUsersToEmail] = useState<
    Array<IUserShortFormat>
  >([])
  const [noRecipients, setNoRecipients] = useState(true)
  const [params, setParams] = useState<ScheduledReportParams>({
    bccEmails: parameters.bcc_users,
    customerKeys: parameters.customer_keys,
    reportType: '',
    assetType: parameters.asset_type,
    range: parameters.range,
    warningType: parameters.warning_type
      ? parameters.warning_type
      : WarningTypes[0].value,
    vinList: initialValues.preselectedAssets
      ? initialValues.preselectedAssets
      : [],
    selectedGeofences: initialValues.preselectedGeofences
      ? initialValues.preselectedGeofences
      : [],
    selectedUsers: initialValues.preselectedUsers
      ? initialValues.preselectedUsers
      : [],
    sensorStatusList:
      parameters && parameters.warning_filter
        ? sensorStatusItemsFiltered.map((s) => {
            return {
              ...s,
              checked: parameters.warning_filter.split(',').includes(s.id),
            }
          })
        : sensorStatusItemsFiltered,
    assetGroups:
      parameters && parameters.asset_group
        ? parameters?.asset_group?.split(',').map((i: string) => i)
        : [],
    geofenceGroups:
      parameters && parameters.geofence_group
        ? parameters?.geofence_group?.split(',').map((i: string) => i)
        : [],
    days: parameters.days ? parameters.days : 7,
    dwellDays: parameters.dwellDays,
  })

  const [dateRange, setDateRange] = useState(
    parameters.range
      ? parameters.range
      : ScheduledReportsWithDateRange.includes(params.reportType)
      ? rangeOptions[2].value
      : null,
  )

  const [infoModalMessage, setInfoModalMessage] = useState('')

  const [selectedCustomerIds, setSelectedCustomerIds] = useState('')

  useEffect(() => {
    setSelectedCustomerIds(
      parameters.customer_keys
        ? customersList
            .filter((c: { key: string }) =>
              parameters.customer_keys.includes(c.key),
            )
            .map((c: { id: any }) => c.id)
            .join(',')
        : parameters.customer_id
        ? parameters.customer_id
        : undefined,
    )
  }, [parameters.customer_keys, parameters.customer_id, customersList])

  useEffect(() => {
    let customerIds = ''

    if (internalReports) {
      customerIds = customersList
        .filter((c: { key: string }) => c.key.toLowerCase() === 'rivata')
        .map((c: { id: any }) => c.id)
    } else if (parameters.customer_keys) {
      customerIds = customersList
        .filter((c: { key: string }) =>
          parameters.customer_keys.includes(c.key),
        )
        .map((c: { id: any }) => c.id)
    }

    fetchScheduledReportsUsers(customerIds)
  }, [
    fetchScheduledReportsUsers,
    parameters.customer_keys,
    customersList,
    internalReports,
  ])

  useEffect(() => {
    if (Object.keys(context.touched).length > 0) setIsFormTouched(true)
  }, [context.touched, setIsFormTouched])

  const assetsEndpointConfig = useMemo(() => {
    const type: any = params.assetType !== 'all' ? params.assetType : undefined
    return {
      search: async (value: string, customerIds?: string) =>
        await api.searchAssets(
          value,
          undefined,
          type,
          undefined,
          undefined,
          undefined,
          undefined,
          customerIds,
        ),
      lablelKey: 'name',
      subLabelKey: 'vin',
    }
  }, [params.assetType])

  const geoEndpointConfig = useMemo(() => {
    return {
      ...EndPointConfigs.geofence,
      subLabelKey: selectedCustomersList.length > 1 ? 'customer' : undefined,
    }
  }, [selectedCustomersList.length])

  const onAssetsSelect = (Assets: SearchItemType[]) => {
    setParams((prevState) => {
      return {
        ...prevState,
        vinList: Assets,
      }
    })

    setParameters((prevState: { vins: any[] }) => {
      return {
        ...prevState,
        vins: Assets.map((g) => g.vin),
      }
    })
  }

  const onGeofencesSelect = (selectedGeofences: SearchItemType[]) => {
    setParams((prevState) => {
      return {
        ...prevState,
        selectedGeofences: selectedGeofences,
      }
    })

    setParameters((prevState: { geofences: any[] }) => {
      return {
        ...prevState,
        geofences: selectedGeofences.map((g) => g.id),
      }
    })
  }

  useEffect(() => {
    if (initialValues.id !== reportId) {
      setReportId(initialValues.id)
      // update initial params
      const checkedWarningStatusItems =
        parameters && parameters.warning_filter
          ? sensorStatusItemsFiltered.map((s) => {
              return {
                ...s,
                checked: parameters.warning_filter.split(',').includes(s.id),
              }
            })
          : sensorStatusItemsFiltered.map((s) => {
              return { ...s, checked: false }
            })

      if (ScheduledReportsWithDateRange.includes(params.reportType)) {
        setDateRange(
          parameters.range ? parameters.range : ReportDateRangeTypes.ThisWeek,
        )
      }

      setParams((prevState) => {
        prevState.bccEmails = parameters.bcc_users
        prevState.selectedGeofences = initialValues.preselectedGeofences
        prevState.vinList = initialValues.preselectedAssets
        prevState.customerKeys = parameters.customer_keys
        if (ScheduledReportsWithDateRange.includes(params.reportType)) {
          prevState.range = parameters.range
            ? parameters.range
            : ReportDateRangeTypes.ThisWeek
        }
        prevState.reportType = parameters.report_type
        prevState.assetType = parameters.asset_type
        prevState.warningType = parameters.warning_type
          ? parameters.warning_type
          : WarningTypes[0].value
        prevState.vinList = initialValues.preselectedAssets
          ? initialValues.preselectedAssets
          : []
        prevState.selectedGeofences = initialValues.preselectedGeofences
          ? initialValues.preselectedGeofences
          : []
        prevState.selectedUsers = initialValues.preselectedUsers
          ? initialValues.preselectedUsers
          : []
        prevState.sensorStatusList = checkedWarningStatusItems
        prevState.assetGroups =
          parameters && parameters.asset_group
            ? parameters?.asset_group?.split(',').map((i: string) => i)
            : []
        prevState.geofenceGroups =
          parameters && parameters.geofence_group
            ? parameters?.geofence_group?.split(',').map((i: string) => i)
            : []

        return {
          ...prevState,
        }
      })
      setFrequency(parameters.frequency)
      setTimezone(parameters.timezone)

      setCustomerId(initialValues.customerId)
    }
  }, [
    initialValues,
    reportId,
    parameters,
    setCustomerId,
    setFrequency,
    setTimezone,
    params.reportType,
  ])

  useEffect(() => {
    if (
      parameters &&
      parameters.timezone !== undefined &&
      parameters.timezone !== timezone
    ) {
      let newParameters = cloneDeep(parameters)
      newParameters.timezone = timezone
      setParameters(newParameters)
      setIsFormTouched(true)
    }
  }, [timezone, parameters, setIsFormTouched, setParameters])

  const reportTypeOptions = useMemo(() => {
    const enums: IDefaultList = internalReports
      ? InternalScheduledReportTypes
      : ScheduledReportTypes
    return _.sortBy(
      Object.keys(enums).map((k) => ({
        value: enums[k],
        label: formatType(enums[k]),
      })),
      (s) => s.label,
    )
  }, [internalReports])

  useEffect(() => {
    if (context.values) {
      setParams((prevState) => {
        if (context.values.type !== prevState.reportType) {
          prevState.reportType = context.values.type
          if (creationMode) {
            prevState.customerKeys = ''
            prevState.selectedGeofences = []
            prevState.sensorStatusList = sensorStatusItemsFiltered
            prevState.assetGroups = []
            prevState.geofenceGroups = []

            if (ScheduledReportsWithDateRange.includes(params.reportType)) {
              prevState.range = ReportDateRangeTypes.ThisWeek
              setDateRange(ReportDateRangeTypes.ThisWeek)
            }
          }
        }

        if (
          context.values.assetType !== 'all' &&
          prevState.assetType !== context.values.assetType
        ) {
          prevState.vinList = []
        }
        prevState.assetType = context.values.assetType
        prevState.warningType = context.values.warningType

        return {
          ...prevState,
        }
      })

      setParameters(
        (prevState: { asset_type: any; vins: never[]; warning_type: any }) => {
          if (
            context.values.assetType !== 'all' &&
            prevState.asset_type !== context.values.assetType
          ) {
            prevState.vins = []
          }

          prevState.asset_type = context.values.assetType
          prevState.warning_type = context.values.warningType

          return {
            ...prevState,
          }
        },
      )

      if (
        context.values.reportType &&
        !context.values.reportType.includes('INTERNAL')
      ) {
        setCustomerId(null)
      }
    }
  }, [
    context.values,
    setParameters,
    setCustomerId,
    creationMode,
    params.reportType,
  ])

  useEffect(() => {
    if (users.length > 0) {
      if (params.bccEmails) {
        const bccUsers = users.filter((u: IUserShortFormat) =>
          params.bccEmails
            .map((b: { user_id: any }) => b.user_id)
            .includes(u.id),
        )
        setBccUsersToEmail(bccUsers)
      } else {
        setBccUsersToEmail([])
      }
    } else {
      setBccUsersToEmail([])
    }
  }, [users, params.bccEmails])

  useEffect(() => {
    const noUsers = !bccUsersToEmail.length
    setNoRecipients(noUsers)
  }, [bccUsersToEmail])

  const showModalInfo = () => {
    setInfoModalMessage(
      'This report will automatically be enabled, please disable if you do not wish to start sending this report.',
    )
  }

  const convertCronToTimezone = (cron: string, timezone: string) => {
    const cronParts = cron.split(' ')
    const minutes = cronParts[0]
    const hours = cronParts[1]
    const day = cronParts[2]
    const month = cronParts[3]
    const daysOfWeek = cronParts[4]

    let tzCron = cron
    let mode = CronModes.atHoursAndMinutes
    let displacementMode: number = CronDisplacementModes.none
    let daysOfWeekIds: any[] = []

    // mode detection
    if (day === '*' && month === '*' && daysOfWeek === '*') {
      // hourly tab
      if (hours.includes('*/') && minutes === '0') {
        // every n hour(s)
        mode = CronModes.everyNHours
      } else if (hours === '*' && minutes.includes('*/')) {
        // every n hour(s)
        mode = CronModes.everyNMinutes
      } else {
        mode = CronModes.atHoursAndMinutes
      }
    }

    if (day !== '*') {
      if (day.includes('1/')) {
        // daily tab
        // every n day(s)
        mode = CronModes.everyNDays
      } else {
        // monthly tab
        // n day(s) of every month
        mode = CronModes.nDaysOfEveryMonth
      }
    }

    if (daysOfWeek !== '*') {
      if (daysOfWeek === '1-5') {
        // daily tab
        // every week day
        mode = CronModes.everyWeekdays
      } else {
        // weekly tab
        mode = CronModes.weekly

        // is sequential
        if (daysOfWeek.includes('-')) {
          let days = daysOfWeek.split('-')
          daysOfWeekIds = days.map(Number)
          let firstId = Number(daysOfWeekIds[0])
          let lastId = Number(daysOfWeekIds[1])

          for (let i = firstId; i < lastId; i++) {
            if (!daysOfWeekIds.includes(i)) {
              daysOfWeekIds.push(i)
            }
          }
        } else if (daysOfWeek.includes(',')) {
          let days = daysOfWeek.split(',')

          for (let i = 0; i < days.length; i++) {
            daysOfWeekIds.push(Number(days[i]))
          }
        } else {
          daysOfWeekIds.push(Number(daysOfWeek))
        }
      }
    }

    let m = minutes.includes('*/') ? Number(minutes.slice(2)) : Number(minutes)
    let h = hours.includes('*/')
      ? Number(hours.slice(2))
      : Number(hours)
      ? Number(hours)
      : 0
    let d = day.includes('1/') ? Number(day.slice(2)) : Number(day)

    let time = moment.tz(`${h}:${m}`, 'H:m', timezone)

    let utcH = moment.utc(`${h}:${m}`, 'H:m').hours()
    let utcM = moment.utc(`${h}:${m}`, 'H:m').minutes()

    // day of month
    let offset = 0
    offset = moment.tz(timezone).utcOffset() / 60

    let minutesOffset = time.utcOffset() % 60

    if (timezone) {
      m = time.minutes() + minutesOffset
      h = Math.abs(h) === 0.5 ? 0 : Math.ceil(time.hours() + offset)
      displacementMode =
        h < 0
          ? CronDisplacementModes.backward
          : h > 23
          ? CronDisplacementModes.forward
          : CronDisplacementModes.none

      h = h < 0 ? 24 + h : h > 23 ? 0 + h - 24 : h
      m = m < 0 ? 60 + m : m > 50 ? 0 + m - 60 : m

      if (displacementMode === CronDisplacementModes.forward) {
        d = d + 1
      } else if (displacementMode === CronDisplacementModes.backward) {
        d = d - 1
      }

      if (d < 1) {
        d = 31
      } else if (d > 31) {
        d = 1
      }
    }

    let utcHours = h
    let utcMinutes = m

    if (mode !== CronModes.everyNHours && mode !== CronModes.everyNMinutes) {
      utcHours = time.hours()
      utcMinutes = time.minutes()
    }

    let tzMinutes = utcMinutes
    let tzHours = utcHours

    if (
      mode !== CronModes.everyNHours &&
      mode !== CronModes.everyNMinutes &&
      timezone
    ) {
      let convertedTime = moment
        .utc(`${tzMinutes} ${tzHours}`, 'm H')
        .tz(timezone)
      tzMinutes = convertedTime.minutes()
      tzHours = convertedTime.hours()
    }

    switch (mode) {
      case CronModes.everyNMinutes:
        tzCron = `*/${utcM} * * * *`
        break
      case CronModes.everyNHours:
        tzCron = `0 */${utcH} * * *`
        break
      case CronModes.atHoursAndMinutes:
        tzCron = `${tzMinutes} ${tzHours} * * *`
        break
      case CronModes.everyNDays:
        tzCron = `${tzMinutes} ${tzHours} 1/${d} * *`
        break
      case CronModes.everyWeekdays:
        tzCron = `${tzMinutes} ${tzHours} * * 1-5`
        break
      case CronModes.nDaysOfEveryMonth:
        tzCron = `${tzMinutes} ${tzHours} ${d} * *`
        break
      case CronModes.weekly:
        tzCron = `${tzMinutes} ${tzHours} * * ${selectedWeekDays(
          displacementMode,
          daysOfWeekIds,
        )}`
        break
    }

    return tzCron
  }

  const onUsersSelect = (selectedUsers: SearchItemType[]) => {
    setParams((prevState: any) => {
      return {
        ...prevState,
        selectedUsers: selectedUsers,
      }
    })

    setParameters((prevState: { geofences: any[] }) => {
      return {
        ...prevState,
        usernames: selectedUsers.map((g) => g.username),
      }
    })

    setIsFormTouched(true)
  }

  const onWarningSeverityItemSelect = useCallback(
    (id: any, items: any) => {
      const checkedItems = items
        .filter((el: { checked: any }) => el.checked)
        .map((el: { id: any }) => el.id)
        .toString()

      setParameters((prevState: { geofences: any[] }) => {
        return {
          ...prevState,
          warning_filter: checkedItems,
        }
      })
      setIsFormTouched(true)
    },
    [setIsFormTouched, setParameters],
  )

  const onAssetGroupFilterItemSelected = useCallback(
    (label: any, value: any) => {
      setParameters((prevState: { asset_group: any[] }) => {
        return {
          ...prevState,
          asset_group: value.map((el: { id: any }) => el.id).toString(),
        }
      })
      setIsFormTouched(true)
    },
    [setIsFormTouched, setParameters],
  )

  const onSelectDays = useCallback(
    (d: any) => {
      setParameters((prevState: { days: number }) => {
        return {
          ...prevState,
          days: d,
        }
      })
      setIsFormTouched(true)
    },
    [setIsFormTouched, setParameters],
  )

  const onDwellDaysChange = useCallback(
    (d: any) => {
      setParameters((prevState: any) => {
        return {
          ...prevState,
          dwellDays: d,
        }
      })
      setIsFormTouched(true)
    },
    [setIsFormTouched, setParameters],
  )
  const onGeofenceGroupFilterItemSelected = useCallback(
    (label: any, value: any) => {
      setParameters((prevState: { geofence_group: any[] }) => {
        return {
          ...prevState,
          geofence_group: value.map((el: { id: any }) => el.id).toString(),
        }
      })
      setIsFormTouched(true)
    },
    [setIsFormTouched, setParameters],
  )

  const sendForLabel = useMemo(() => {
    if (formRef.current) {
      const values = formRef.current.values
      if (values.sendFor && values.sendForMetric) {
        if (values.sendForMetric === 'times') {
          let remainsLabel = ''
          if (
            initialValues.sendForMetric === 'times' &&
            Number(initialValues.sendFor) === Number(values.sendFor)
          ) {
            remainsLabel = ` remains ${initialValues.sendForValue}`
          }
          return `(Will be sent ${values.sendFor} times${remainsLabel})`
        } else {
          let value = 0
          if (
            Number(initialValues.sendFor) === Number(values.sendFor) &&
            initialValues.sendForMetric === values.sendForMetric
          ) {
            if (initialValues.sendForValue) {
              value = initialValues.sendForValue
            }
          } else {
            value = getReportEndRuleDate(values.sendForMetric, values.sendFor)
          }

          return `(Will be sent until: ${convertDataEpochToDate(
            value,
            null,
            null,
            true,
          )})`
        }
      }
      return ''
    }
    return ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef, formRef.current, initialValues])

  return (
    <Row className='edit-form-inputs'>
      <Col md='3' className='m-0'>
        <SelectFormField
          md={12}
          name='type'
          label='Type'
          options={reportTypeOptions}
          disabled={!creationMode}
        />
        {CustomerSelectorReports.includes(params.reportType) && (
          <Col md='12' className='mb-3'>
            <ScheduledReportCustomerSelector
              singleItem={SingleCustomerItemReports.includes(params.reportType)}
              disabled={
                params.reportType !==
                  InternalScheduledReportTypes.INTERNAL_CONMET_DAILY_REPORT &&
                !creationMode
              }
              preselectedCustomerKeys={params.customerKeys}
              parameters={parameters}
              setParameters={setParameters}
              setIsFormTouched={setIsFormTouched}
              setCustomerId={setCustomerId}
            />
          </Col>
        )}
        <Col md='12' className='mb-3'>
          <FormGroup className='w-100'>
            <Label>Timezone</Label>
            <TimezonePicker
              onSetTimezone={(tz) => {
                setTimezone(tz)
                context.setFieldValue('parameters.timezone', tz ?? '', true)
              }}
              initialValue={timezone}
              locale={locale}
              id='timezone'
              isInvalid={!timezone}
            />
            {!timezone && <span className='error error-message'>Required</span>}
          </FormGroup>
        </Col>
        {DaysSelectorReports.includes(params.reportType) && (
          <Col md='6' className='mb-3'>
            <label>Select Date Range</label>
            <div className='d-flex flex-row'>
              <DatesDropdown
                onSelect={onSelectDays}
                locale={locale}
                includeOneDay={true}
                initial={params.days}
              />
            </div>
          </Col>
        )}
        {AssetTypeSelectorReports.includes(params.reportType) && (
          <SelectFormField
            md={12}
            name='assetType'
            label='Asset Type'
            options={AssetTypes}
          />
        )}
        {AssetHealthSelectorReports.includes(params.reportType) && (
          <SelectFormField
            md={12}
            className='mb-0'
            name='warningType'
            label='Asset Health State'
            options={WarningTypes}
          />
        )}
        {WarningTypeReports.includes(params.reportType) && (
          <Col md='12'>
            <HealthStatusFilter
              id='warning_type_new'
              className='mr-2 warning-type-select'
              overrideDefaultItems={params.sensorStatusList}
              onDropdownItemSelect={onWarningSeverityItemSelect}
            />
          </Col>
        )}
        {AssetsSelectorReports.includes(params.reportType) && (
          <>
            <Col md='12' className='mb-0'>
              <ItemsSelectWithSearch
                label='Assets'
                inputPlaceholder='Search Assets'
                endPointConfig={assetsEndpointConfig}
                onItemsSelect={onAssetsSelect}
                columnsToShow={columnsToShow}
                disabled={!params.assetType || !selectedCustomerIds}
                selectedItems={params.vinList}
                customerIds={selectedCustomerIds}
              />
            </Col>
          </>
        )}
        {AssetGroupSelectorReports.includes(params.reportType) && (
          <Col md='12'>
            <AssetGroupsFilter
              onDropdownItemSelect={onAssetGroupFilterItemSelected}
              selectedValueIds={params.assetGroups}
              disabled={!selectedCustomerIds}
              customerIds={selectedCustomerIds}
            />
          </Col>
        )}
        {UserSelectorReports.includes(params.reportType) && (
          <>
            <Col md='12' className='mb-3 mt-n4'>
              <ItemsSelectWithSearch
                label='Users'
                inputPlaceholder='Search Users'
                endPointConfig={EndPointConfigs.user}
                onItemsSelect={onUsersSelect}
                columnsToShow={usersColumnsToShow}
                tableWidth='800px'
                selectedItems={params.selectedUsers}
              />
            </Col>
          </>
        )}
        {GeofencesSelectorReports.includes(params.reportType) && (
          <Col md='12' className='mb-3'>
            <ItemsSelectWithSearch
              label='Geofences'
              inputPlaceholder='Search Geofences'
              endPointConfig={geoEndpointConfig}
              onItemsSelect={onGeofencesSelect}
              columnsToShow={geofenceColumnsToShow}
              disabled={!selectedCustomerIds}
              selectedItems={params.selectedGeofences}
              customerIds={selectedCustomerIds}
            />
          </Col>
        )}
        {GeofenceGroupSelectorReports.includes(params.reportType) && (
          <Col md='12'>
            <GeofenceGroupsFilter
              onDropdownItemSelect={onGeofenceGroupFilterItemSelected}
              selectedValueIds={params.geofenceGroups}
              disabled={!selectedCustomerIds}
              customerIds={selectedCustomerIds}
            />
          </Col>
        )}
        {params.reportType === ScheduledReportTypes.DWELL_REPORT && (
          <Col md='12' className='mb-3'>
            <DwellDaysInput
              disabled={!selectedCustomerIds}
              dwellDays={parameters.dwellDays}
              onChange={(ev) => {
                onDwellDaysChange(ev)
              }}
            />
          </Col>
        )}

        <Col md='12' className='mt-3 mb-3'>
          <label htmlFor='frequency'>Frequency</label>
          <Row className='flex-nowrap m-0'>
            <Input
              type={'text'}
              value={
                frequency
                  ? cronstrue.toString(
                      convertCronToTimezone(frequency, timezone),
                      { verbose: true },
                    )
                  : ''
              }
              disabled={true}
              invalid={!frequency.length}
              name='frequency'
            />

            <Button
              type='button'
              className='btn btn-primary pl-3 pr-3'
              onClick={() => setShowCronModal(true)}
            >
              <i className={`fa fa-edit`}></i>
            </Button>
          </Row>
          {!frequency.length && (
            <span className='error error-message'>Required</span>
          )}
        </Col>
        {ScheduledReportsWithDateRange.includes(params.reportType) && (
          <SelectFormField
            customOnChange={(ev: any) => {
              setDateRange(ev.target.value)
              setParameters((prevState: any) => {
                return {
                  ...prevState,
                  range: ev.target.value,
                }
              })
            }}
            name='range'
            label='Date Range'
            value={dateRange}
            options={rangeOptions}
            disabled={false}
          />
        )}
        <RivataFormField
          md='12'
          label={'Name'}
          name={'name'}
          minHeight={62}
          className='mb-3'
        />

        <CustomInput
          type='switch'
          className='ml-3 mb-3 mr-1 '
          id='endRuleSwitch'
          label={'End rule enabled'}
          checked={endRuleEnabled}
          onChange={(e: { target: { checked: boolean } }) => {
            setEndRuleEnabled(e.target.checked)
            setIsFormTouched(true)
          }}
        />
        {endRuleEnabled && (
          <Col md='12' className='mt-3 mb-3'>
            <div className='mb-2'>{sendForLabel}</div>
            <Row className='flex-nowrap'>
              <RivataFormField
                md={6}
                label={'Send for'}
                name={'sendFor'}
                minHeight={62}
              />
              <SelectFormField
                md={6}
                name='sendForMetric'
                label='Metric Type'
                options={endRuleDropdownItems}
              />
            </Row>
          </Col>
        )}
        <CustomInput
          type='switch'
          className='ml-3 mb-3'
          id='isDisabledSwitch'
          label={'Schedule enabled'}
          checked={!isDisabled}
          onChange={(e: { target: { checked: boolean } }) => {
            setIsDisabled(!e.target.checked)
            setIsFormTouched(true)
          }}
          disabled={noRecipients}
        />
        <InfoModal
          open={!!infoModalMessage}
          header={`Scheduled report info`}
          message={infoModalMessage}
          onConfirm={() => setInfoModalMessage('')}
        />
      </Col>
      <Col md='9' className='m-0'>
        <Row className='flex-nowrap m-0 mb-3'>
          <ScheduledReportUsers
            users={users}
            bccUsersToEmail={bccUsersToEmail}
            setBccUsersToEmail={setBccUsersToEmail}
            setIsFormTouched={setIsFormTouched}
            parameters={parameters}
            setParameters={setParameters}
            selectedCustomerId={selectedCustomerId}
            preselectedCustomerKeys={params.customerKeys}
            internalReports={internalReports}
            showModalInfo={showModalInfo}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        </Row>
      </Col>
    </Row>
  )
}

export default EditFormInputs
