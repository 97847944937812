import React, { useMemo } from "react"
import RivataModule from "../../../components/RivataModule"
import ScrollBlockWrapper from "../../../components/ScrollBlockWrapper";
import LocationMap from "../../../modules/LocationMap"
import { getTooltipCoordinates } from "../../../modules/LocationMap/utils";
import { convertDataEpochToDate } from "../../../utils";


const getBubbleContent = (data: any, e:any) => {
  const labelColor = "green";
  const map = document.getElementById("rivata-map-cluster");
  const { top, left } = getTooltipCoordinates(map, data, e, -25, -173);
  return `
    <div id="location-bubble" style="position: absolute; top: ${top}px; left: ${left}px; width: 290px">
      <div class="alert-primary-location show"
       role="alert" aria-live="assertive"
        aria-atomic="true"" 
        style="background-color: ${labelColor};
         border-color: ${labelColor};">
        <div class="label-line"></div>
        <table class="table-responsive table-light table-sm m-0 p-0">
          <tbody>
            <tr>
              <th scope="row">Mac: </th>
              <td colspan=3>${data.gateway}</td>
            </tr>
            <tr>
              <th scope="row">Latitude: </th>
              <td colspan=3>${data.latitude}</td>
            </tr>
            <tr>
              <th scope="row">Longitude: </th>
              <td colspan=3>${data.longitude}</td>
            </tr>
            <tr>
              <th scope="row">Last Reported Time:</th>
              <td colspan=3>${convertDataEpochToDate(data.epoch, null, null, true)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `
}

interface Props {
  locale: ILocale
  location: any
  preferences: any
  status: IStatus
}

const CargotagLocationDetailsMap: React.FC<Props> = ({
  locale,
  location,
  preferences,
  status
}) => {
  const mapData = useMemo(() => {
    let geofences: Array<any> = []
    let gateways = []

    if (location) {
      geofences = [location]
      if (location.gateways_details) gateways = location.gateways_details
    }

    return { geofences, gateways }
  }, [location])


  return (
    <RivataModule
      title={"Location Map"}
      locale={locale}
      width={8}
      filters={null}
      error={status}
    >
      <ScrollBlockWrapper>
        <LocationMap
          locations={mapData.gateways}
          getCustombubbleContent={getBubbleContent}
          locale={locale}
          preferences={preferences}
          geofences={mapData.geofences}
          displayBubble={false}
        />
      </ScrollBlockWrapper>
    </RivataModule>
  )
}

export default React.memo(CargotagLocationDetailsMap)