import React, { useEffect, useState } from "react"
import RivataLoader from "../../../components/RivataLoader";

import RivataModule from "../../../components/RivataModule"
import ScrollBlockWrapper from "../../../components/ScrollBlockWrapper";
import LocationMap from "../../../modules/LocationMap/LocationMap"

const getBubbleContent = (data: any) => {
  const labelColor = "green";

  return `
    <div>
      <div class="alert-primary-location show" role="alert" aria-live="assertive" aria-atomic="true"" style="background-color: ${labelColor}; border-color: ${labelColor};">
        <div class="label-line"></div>
        <table class="table-responsive table-light table-sm m-0 p-0">
          <tbody>
            <tr>
              <th scope="row">Name: </th>
              <td colspan=2>${data.name}</td>
            </tr>
            <tr>
              <th scope="row">VIN: </th>
              <td colspan=2>${data.vin}</td>
            </tr>
            <tr>
              <th scope="row">Latitude: </th>
              <td colspan=2>${data.latitude}</td>
            </tr>
            <tr>
              <th scope="row">Longitude: </th>
              <td colspan=2>${data.longitude}</td>
            </tr>
            <tr>
              <th scope="row">Tags:</th>
              <td colspan=2>${data.tags_data.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `
}


interface Props {
  locale: ILocale,
  asset: any
  isLoading: boolean
  status: IStatus
}

const CargotagAssetLocation: React.FC<Props> = ({
  locale,
  asset,
  isLoading,
  status,
}) => {
  const [locations, setLocations] = useState<any>([])

  useEffect(() => {
    setLocations(asset ? [asset] : [])
  }, [asset])

  return (
    <RivataModule
      title={"Asset Location"}
      locale={locale}
      width={8}
      filters={null}
      error={status}
    >
      {isLoading && <RivataLoader />}
      <ScrollBlockWrapper>
        <LocationMap
          locations={locations}
          getCustombubbleContent={getBubbleContent}
        />
      </ScrollBlockWrapper>
    </RivataModule>
  )
}

export default React.memo(CargotagAssetLocation)