import React from 'react'
import './style.scss'

const Toggle = ({ onChange, items, selected }) => {
  return (
    <div className='d-flex toggle__wrapper'>
      {items.map((item) => {
        return (
          <button
            type='button'
            id={`item_btn_${item}`}
            className={`toggle__selector ${
              selected === item ? 'active__unit' : ''
            }`}
            onClick={() => onChange(item)}
          >{item}</button>
        )
      })}
    </div>
  )
}

export default Toggle
