import React, { useMemo } from 'react'
import { MenuItemType } from './MenuItem'
import MenuList from './MenuList'
import './style.scss'

export interface IMenuProps {
  items: Array<MenuItemType>
  readOnly?: boolean
  disabled?: boolean
  resetBtnLabel?: string
  finishBtnLabel?: string
  menuClassName?: string
  onItemChange: (item: MenuItemType) => void
  onSubMenuToggle: (item: MenuItemType) => void
  onReset: () => void
  onFinishSelection: () => void
}

const StatelessMenu: React.FC<IMenuProps> = ({
  items,
  readOnly,
  disabled,
  resetBtnLabel = 'Reset',
  finishBtnLabel = 'Done',
  menuClassName = '',
  onItemChange,
  onSubMenuToggle,
  onReset,
  onFinishSelection,
}) => {
  // zero level items to draw
  const idsToRender = useMemo(() => {
    return items.reduce<Array<string>>((prev, curr) => {
      if (!curr.parentId) prev.push(curr.id)

      return prev
    }, [])
  }, [items])

  return (
    <div className={`custom-selector ${menuClassName}`}>
      <div className='custom-selector-body'>
        <MenuList
          items={items}
          idsToRender={idsToRender}
          depth={0}
          onChange={onItemChange}
          onSubMenuToggle={onSubMenuToggle}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>

      <div className='custom-selector-footer'>
        {!readOnly && (
          <span
            className='footer__reset'
            onClick={() => !disabled && onReset()}
          >
            {resetBtnLabel}
          </span>
        )}

        <span
          className='footer__done'
          onClick={() => !disabled && onFinishSelection()}
        >
          {finishBtnLabel}
        </span>
      </div>
    </div>
  )
}

export default StatelessMenu
