import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import axleLoadIcon from '../../assets/images/icons/svg/fleet_summary_axle_load.svg'
import insideGeofenceIcon from '../../assets/images/icons/svg/fleet_summary_geofence_inside.svg'
import outsideGeofenceIcon from '../../assets/images/icons/svg/fleet_summary_geofence_outside.svg'
import linePressureIcon from '../../assets/images/icons/svg/fleet_summary_line_pressure.svg'
import smarthubIcon from '../../assets/images/icons/svg/fleet_summary_smarthub.svg'
import tpmsIcon from '../../assets/images/icons/svg/fleet_summary_tpms.svg'
import AssetCard, { AssetCardItem } from '../../components/AssetCard'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import { WarningCase, WhiteLabelFilenames } from '../../enums'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import AssetHealthSummary from './AssetHealthSummary'
import './styles.scss'

export interface FleetSummaryModuleProps {
  locale: Record<string, string>
  width: number
  data: {
    trailers_count: number
    tractors_count: number
    inside_geofence_count: number
    outside_geofence_count: number
    gateways_sensor_assets_count: number
    smarthub_sensor_assets_count: number
    tpms_sensor_assets_count: number
    line_pressure_sensor_assets_count: number
    axle_load_sensor_assets_count: number
    normal_state_assets_count: number
    warning_state_assets_count: number
    critical_warning_state_assets_count: number
    active_warning_subtypes: ActiveWarningSubtype[]
    active_critical_warning_subtypes: ActiveWarningSubtype[]
  }
  isLoading: boolean
  availableCustomersSubscriptions: any
}

export type ActiveWarningSubtype = {
  warning_subtype: WarningCase
  warning_type_id: number
  warning_type_key: string
}

const iconHash = `?r=${+new Date()}`

const FleetSummaryModule: React.FC<FleetSummaryModuleProps> = (props) => {
  const { locale, width, data, isLoading, availableCustomersSubscriptions } =
    props

  const { whiteLabelUrl } = useTypedSelector((state) => ({
    whiteLabelUrl: state.auth.whiteLabelUrl,
  }))

  const [sensorsData, setSensorsData] = useState<any>([])

  useEffect(() => {
    if (!availableCustomersSubscriptions) {
      return
    }
    const sD = []
    if (availableCustomersSubscriptions.valid_smarthub_subscription) {
      sD.push({
        icon: `${whiteLabelUrl}${WhiteLabelFilenames.fleetSummarySmarthubSvg}${iconHash}`,
        fallBackIcon: smarthubIcon,
        label: 'Smarthub',
        value: data.smarthub_sensor_assets_count,
      })
    }
    if (availableCustomersSubscriptions.valid_tpms_subscription) {
      sD.push({
        icon: `${whiteLabelUrl}${WhiteLabelFilenames.fleetSummaryTpmsSvg}${iconHash}`,
        fallBackIcon: tpmsIcon,
        label: 'TPMS',
        value: data.tpms_sensor_assets_count,
      })
    }
    if (availableCustomersSubscriptions.valid_line_pressure_subscription) {
      sD.push({
        icon: `${whiteLabelUrl}${WhiteLabelFilenames.fleetSummaryLinePressureSvg}${iconHash}`,
        fallBackIcon: linePressureIcon,
        label: 'Line Pressure',
        value: data.line_pressure_sensor_assets_count,
      })
    }
    if (availableCustomersSubscriptions.valid_axle_load_subscription) {
      sD.push({
        icon: `${whiteLabelUrl}${WhiteLabelFilenames.fleetSummaryAxleLoadSvg}${iconHash}`,
        fallBackIcon: axleLoadIcon,
        label: 'Axle Load',
        value: data.axle_load_sensor_assets_count,
      })
    }
    setSensorsData(sD)
  }, [availableCustomersSubscriptions, data, setSensorsData, whiteLabelUrl])

  return (
    <RivataModule
      title='Fleet Summary'
      width={width}
      marginTop={4}
      locale={locale}
      filters={null}
      customClass='custom-module-style'
    >
      {isLoading || isEmpty(data) ? (
        <RivataLoader />
      ) : (
        <>
          <div className='fleet-summary-container'>
            <AssetCard>
              <AssetCard.Header label='Total Assets by Type' />

              {[
                { label: 'Tractors', value: data.tractors_count },
                { label: 'Trailers', value: data.trailers_count },
                {
                  label: 'All',
                  value: data.tractors_count + data.trailers_count,
                },
              ].map((item) => (
                <AssetCardItem key={item.label} {...item} />
              ))}
            </AssetCard>

            <AssetCard>
              <AssetCard.Header label='Assets by Sensor Type' />

              {((sensorsData as any[]) || []).map((item) => (
                <AssetCardItem key={item.label} {...item} />
              ))}
            </AssetCard>
          </div>

          <div className='fleet-summary-container'>
            <AssetHealthSummary {...props} />

            <AssetCard>
              <AssetCard.Header label='Assets in Geofence' />

              {[
                {
                  icon: `${whiteLabelUrl}${WhiteLabelFilenames.fleetSummaryGeofenceInsideSvg}${iconHash}`,
                  fallBackIcon: insideGeofenceIcon,
                  label: 'Inside',
                  value: data.inside_geofence_count,
                },
                {
                  icon: `${whiteLabelUrl}${WhiteLabelFilenames.fleetSummaryGeofenceOutsideSvg}${iconHash}`,
                  fallBackIcon: outsideGeofenceIcon,
                  label: 'Outside',
                  value: data.outside_geofence_count,
                },
              ].map((item) => (
                <AssetCardItem key={item.label} {...item} />
              ))}
            </AssetCard>
          </div>
        </>
      )}
    </RivataModule>
  )
}

export default FleetSummaryModule
