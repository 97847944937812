import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

import TableRow, { tableRowType } from './TableRow'
import Th from './Th'
import LimitDropdown from '../LimitDropdown'
import RivataLoader from '../RivataLoader'
import StatusAlert from '../StatusAlert'
import RivataPagination from '../RivataPagination'
import useColumnsSelector from './useColumnsSelector'
import ColumnsSelectorDropdown from './ColumnsSelectorDropdown'
import {
  saveGoogleAnalyticsEvent,
  reorderTableColumns,
} from '../../utils/utils'

import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import './styles.scss'
import DraggableTh from './DraggableTh'
import { useTypedSelector } from '../../hooks/useTypedSelector'

const RivataTable = ({
  columns,
  rows,
  onSelectedRow = undefined,
  onCheckRowItem,
  onCheckRowColumn,
  onEdit,
  onDelete,
  editDisabled,
  deleteDisabled,
  totalCount = 0,
  pageLimit = 0,
  daysCount = 0,
  page = 0,
  onSelectLimit = undefined,
  isLoading,
  onPageChange = undefined,
  isShowingLimit = false,
  limitDropdownItems = undefined,
  tpmsProfiles = undefined,
  showPagination = true,
  setSortOptions,
  onCustomAction,
  customDeleteStyle = {},
  customEditStyle = {},
  onRowClick = undefined,
  customActionIcon = 'fa fa-plus-square font-16',
  customActionTooltipText = '',
  editTooltipText = '',
  deleteTooltipText = '',
  columnsSelectorProps = undefined,
  customNoDataText = '',
  children = null,
}) => {
  const { userName } = useTypedSelector((state) => ({
    userName: state.auth.user.userName,
  }))
  const [sortColumn, setSortColumn] = useState(null)

  const [
    columnsModified,
    rowsModified,
    checkboxesProps,
    onSetDefault,
    setColumnsOrder,
  ] = useColumnsSelector(columns, rows, userName, columnsSelectorProps)

  const onPageClick = useCallback(
    (newPage) => {
      onPageChange(newPage * pageLimit)
    },
    [pageLimit, onPageChange],
  )

  const sortHandler = useCallback(
    (columnId, direction) => {
      saveGoogleAnalyticsEvent('dashboard_sort_columns_click', { columnId })
      setSortOptions({ column: columnId, direction })
    },
    [setSortOptions],
  )
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorderTableColumns(
      columnsModified,
      result.source.index,
      result.destination.index,
    )
    setColumnsOrder(newItems)
  }
  return (
    <>
      {isLoading && <RivataLoader />}

      {rowsModified.length > 0 && (
        <div className='d-flex'>
          {showPagination && (
            <>
              <RivataPagination
                totalCount={totalCount}
                pageLimit={pageLimit}
                currentPage={page}
                setCurrentPage={onPageClick}
              />

              {isShowingLimit && (
                <LimitDropdown
                  setPageLimit={onSelectLimit}
                  pageLimit={pageLimit}
                  limitDropdownItems={limitDropdownItems}
                />
              )}
            </>
          )}

          {columnsSelectorProps?.showDropdown && (
            <ColumnsSelectorDropdown
              checkboxesProps={checkboxesProps}
              onSetDefault={onSetDefault}
              className='ml-auto'
            />
          )}
        </div>
      )}

      {rowsModified.length ? (
        <>
          <Table hover size='sm' responsive>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable' direction='horizontal'>
                {(droppableProvided, droppableSnapshot) => (
                  <thead>
                    <tr ref={droppableProvided.innerRef}>
                      {onDelete && (
                        <Th
                          customInlineStyle={customDeleteStyle}
                          key={`table_header_delete`}
                          label=''
                        />
                      )}
                      {onEdit && (
                        <Th
                          customInlineStyle={customEditStyle}
                          key={`table_header_edit`}
                          label=''
                        />
                      )}
                      {onCustomAction && (
                        <Th key={`table_header_custom_action`} label='' />
                      )}

                      {columnsModified.map((column, index) =>
                        column.isDraggable ? (
                          <DraggableTh
                            key={`table_header_${column.id}`}
                            onCheck={onCheckRowColumn}
                            sortColumn={sortColumn}
                            setSortColumn={(id) => setSortColumn(id)}
                            sortHandler={sortHandler}
                            customStyle={column.customStyle}
                            {...column}
                            index={index}
                          />
                        ) : (
                          <Th
                            key={`table_header_${column.id}`}
                            onCheck={onCheckRowColumn}
                            sortColumn={sortColumn}
                            setSortColumn={(id) => setSortColumn(id)}
                            sortHandler={sortHandler}
                            customStyle={column.customStyle}
                            {...column}
                            index={index}
                          />
                        ),
                      )}
                      {droppableProvided.placeholder}
                    </tr>
                  </thead>
                )}
              </Droppable>
            </DragDropContext>

            <tbody>
              {rowsModified.map((row, i) => {
                return (
                  <TableRow
                    key={`table_rows_${i}`}
                    id={row.id}
                    selectedRow={row.selectedRow}
                    columns={row.columns}
                    data={row.data}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onSelectedRow={onSelectedRow}
                    onCheckRowItem={onCheckRowItem}
                    editDisabled={editDisabled}
                    deleteDisabled={deleteDisabled}
                    tpmsProfiles={tpmsProfiles}
                    daysCount={daysCount}
                    onCustomAction={onCustomAction}
                    onRowClick={onRowClick}
                    customActionIcon={customActionIcon}
                    customActionTooltipText={customActionTooltipText}
                    editTooltipText={editTooltipText}
                    deleteTooltipText={deleteTooltipText}
                    isCustomActionHidden={row.isCustomActionHidden}
                  />
                )
              })}
            </tbody>
          </Table>

          {children}

          {showPagination && rowsModified.length > 0 && (
            <div className='d-flex justify-content-end'>
              <RivataPagination
                totalCount={totalCount}
                pageLimit={pageLimit}
                currentPage={page}
                setCurrentPage={onPageClick}
              />
              {isShowingLimit ? (
                <LimitDropdown
                  setPageLimit={onSelectLimit}
                  pageLimit={pageLimit}
                  limitDropdownItems={limitDropdownItems}
                />
              ) : null}
            </div>
          )}
        </>
      ) : (
        !isLoading && (
          <StatusAlert
            customText={customNoDataText ? customNoDataText : 'No Data'}
            color='success'
          />
        )
      )}
    </>
  )
}

export const tableType = {
  id: PropTypes.string,
  onSelected: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired }),
  ),
  rows: PropTypes.arrayOf(PropTypes.shape(tableRowType)).isRequired,
  onCheckRowItem: PropTypes.func,
  onCheckRowColumn: PropTypes.func,
}

RivataTable.propTypes = tableType

export default RivataTable
