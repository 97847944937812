import React, { useMemo, useContext } from 'react'

import RivataModule from '../../components/RivataModule'
import RivataLineChart from '../../components/RivataLineChart'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'

import { psiToBar } from '../../utils'
import { composeChartData, composeAnnotations } from './utils'
import { composeCustomTooltip } from '../../utils/chartUtils'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { AssetDetailsContext } from '../../pages/AssetDetails'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

const tooltip = composeCustomTooltip({
  displayLabelColors: false,
  bgColor: true,
  beforeLabel: true,
  afterLabel: true,
})

const id = 'Red Supply Line'

const LinePressureChart = ({ width }) => {
  const {
    linePressure: { isLoading, data, status },
    thresholds,
  } = useTypedSelector((state) => ({
    linePressure: state.assetDetails.linePressure,
    thresholds: state.common.customerDefaults.line_pressure,
  }))

  const {
    locale,
    unitsOfMeasurementConfig,
    chartsXAxisLabel,
    healthColors,
    timestamp,
  } = useContext(AssetDetailsContext)

  const threshold =
    unitsOfMeasurementConfig?.pressure === UnitsOfMeasurementConfig.pressure.psi
      ? thresholds.line_pressure_treshold
      : psiToBar(thresholds.line_pressure_treshold)

  const chartData = useMemo(() => {
    if (!data.length) return null
    return composeChartData(data, unitsOfMeasurementConfig)
  }, [data, unitsOfMeasurementConfig])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title='Line Pressure'
      width={width}
      locale={locale}
      error={status}
      collapsible
    >
      {isLoading ? (
        <RivataLoader />
      ) : chartData ? (
        <RivataLineChart
          id={id}
          data={chartData}
          height={400}
          yAxisLabel={`Pressure (${
            unitsOfMeasurementConfig?.pressure ===
            UnitsOfMeasurementConfig.pressure.psi
              ? 'psi'
              : 'bar'
          })`}
          tooltip={tooltip}
          annotations={composeAnnotations(
            id,
            threshold,
            timestamp,
            healthColors,
          )}
          threshold={threshold}
          xAxisLabel={chartsXAxisLabel}
        />
      ) : (
        <StatusAlert customText='No Data' color='success' />
      )}
    </RivataModule>
  )
}

export default LinePressureChart
