import React, { useMemo, useContext } from "react";
import { useDiagramData } from "./hooks";
import TrailerDiagram from "./trailer/TrailerDiagram";
import { useTypedSelector } from "../../hooks/useTypedSelector"
import TractorDiagram from "./tractor/TractorDiagram";

import { AssetDetailsContext } from '../../pages/AssetDetails'


const TpmsStatusDiagram = () => {
  const {
    thresholds,
    preferences,
    statuses,
  } = useTypedSelector((state) => ({
    thresholds: state.common.customerDefaults,
    preferences: state.auth.preferences,
    statuses: state.details.statuses
  }));

  const {
    locale,
    assetInfoData
  } = useContext(AssetDetailsContext)


  const params = useMemo(() => {
    return { 
      assetTpmsStatus: statuses.assetTpmsStatus, 
      preferences, 
      customerDefaults: thresholds
    }
  }, [statuses, preferences, thresholds])

  const { pressureUnit, timezone, getTireData } = useDiagramData(params);
  
  const lastUpdatedAtDateTime = useMemo(() => assetInfoData?.gateways.find(gw => gw.is_main)?.last_updated_at_datetime, [assetInfoData])

  return (
    <>
      {assetInfoData?.asset_type === "trailer" ? (
        <TrailerDiagram 
          statuses={statuses} 
          thresholds={thresholds} 
          pressureUnit={pressureUnit} 
          timezone={timezone} 
          locale={locale} 
          getTireData={getTireData} 
          lastUpdatedAtDateTime={lastUpdatedAtDateTime} 
        />
      ) : (
        <TractorDiagram 
          statuses={statuses} 
          thresholds={thresholds} 
          pressureUnit={pressureUnit} 
          timezone={timezone} 
          locale={locale} 
          getTireData={getTireData} 
          lastUpdatedAtDateTime={lastUpdatedAtDateTime} 
        />
      )}
    </>
  );
};

export default React.memo(TpmsStatusDiagram);
