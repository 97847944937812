import React from 'react'

import AssetCreateChain, { ICreateAssetValues } from './AssetCreateChain'
import AssetModal from './AssetModal'
import DeleteModal from '../../../components/DeleteModal'
import InfoModal from '../../../components/InfoModal'
import ElementMigrateModal from './ElementMigrateModal'

import { getStatusObj } from '../../../utils/utils'
import { lastReportWasToday } from '../../../pages/Provision/utils'
import CopyToEnvModal from '../../../components/CopyToEnvModal'


const getDeleteAssetWarningMessage = (assetDelete: IAsset) => {
    if (assetDelete.last_gps_update_epoch) {
        if (lastReportWasToday(assetDelete.last_gps_update_epoch)) {
            return "This asset is reporting data. Are you sure you want to delete this asset?"
        }
    }
}


interface Props {
    isLoading: boolean
    isCreateStarted: boolean
    isCopyCustomerAssets: boolean
    locale: ILocale
    status: IStatus
    assetDelete: IAsset
    assetUpdate: IAsset
    assetMigrate: IAsset
    assets: Array<IAsset>
    selectedCustomersList: Array<ICustomer>
    setIsCreateStarted: React.Dispatch<React.SetStateAction<boolean>>
    onCreateAsset: (values: ICreateAssetValues) => Promise<IStatus>
    setStatus: (st: IStatus) => void
    onAssetDelete: () => void
    onAssetDeleteCancel: React.Dispatch<React.SetStateAction<null>>
    onAssetUpdate: (values: any) => void
    onAssetUpdateCancel: React.Dispatch<React.SetStateAction<null>>
    onAssetMigrate: (values: any) => void
    onAssetMigrateCancel: React.Dispatch<React.SetStateAction<null>>
    onAssetCopy: (values: any) => void
    onAssetCopyCancel: React.Dispatch<React.SetStateAction<boolean>>
}

const AssetModals: React.FC<Props> = ({
    isLoading,
    isCreateStarted, 
    isCopyCustomerAssets,
    locale,
    status,
    assetDelete,
    assetUpdate,
    assetMigrate,
    assets,
    selectedCustomersList,
    setIsCreateStarted,
    onCreateAsset,
    setStatus,
    onAssetDelete,
    onAssetDeleteCancel,
    onAssetUpdate,
    onAssetUpdateCancel,
    onAssetMigrate,
    onAssetMigrateCancel,
    onAssetCopy,
    onAssetCopyCancel
}) => {
    return (
        <>
            {isCreateStarted && (
                <AssetCreateChain
                    isCreateStarted={true}
                    locale={locale}
                    setIsCreateStarted={setIsCreateStarted}
                    onCreateAsset={onCreateAsset}
                />
            )}

            {status.statusCode <= 201 && status.statusCode !== 0 && (
                <InfoModal
                    open={true}
                    header="Assets Info"
                    message={status.message}
                    onConfirm={() => setStatus( getStatusObj() )}
                />
            )}

            {!!assetDelete && (
                <DeleteModal
                    open={true}
                    disabled={false}
                    header={`Delete asset "${assetDelete.name}"?`}
                    message={"This cannot be undone!"}
                    locale={locale}
                    warningMessage={getDeleteAssetWarningMessage(assetDelete)}
                    onDelete={onAssetDelete}
                    onCancel={() => onAssetDeleteCancel(null)}
                />
            )}

            {!!assetUpdate && (
                <AssetModal
                    open={true}
                    disabled={false}
                    title="Update"
                    locale={locale}
                    initialValues={assetUpdate}
                    onCreate={onAssetUpdate}
                    onClose={() => onAssetUpdateCancel(null)}
                />
            )}

            {!!assetMigrate && (
                <ElementMigrateModal
                    open={true}
                    disabled={false}
                    isLoading={isLoading}
                    elementType={"asset"}
                    elementName={assetMigrate.name}
                    currentCustomerName={assetMigrate.customer_name}
                    locale={locale}
                    initialValues={{ "new_customer": assetMigrate.customer_name }}
                    onCreate={onAssetMigrate}
                    onClose={() => onAssetMigrateCancel(null)}
                />
            )}

            {isCopyCustomerAssets && (
                <CopyToEnvModal
                    header={`Copy ${selectedCustomersList[0].name} Assets`}
                    title="Select Assets"
                    data={assets.map(el => ({ id: el.vin, label: el.name }))}
                    onSubmit={onAssetCopy}
                    onClose={() => onAssetCopyCancel(false)}
                />
            )}
        </>
    )
}

export default AssetModals
