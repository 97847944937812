import {
  LOADING_REPORT,
  SET_REPORT_COLUMNS,
  SET_REPORT_ROWS,
  SET_REPORT_ERROR,
  SET_SELECTED_REPORT_TYPE,
} from '../actionTypes'

const initialState = {
  report: {
    isLoading: false,
    selectedReportType: null,
    columns: [],
    rows: [],
    error: {
      statusCode: 200,
      message: 'Use Report Controls To Get Data',
    },
  },
}

const setField = (state, key, field, payload) => ({
  ...state,
  [key]: { ...state[key], [field]: payload },
})

const reports = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_REPORT:
      return setField(state, 'report', 'isLoading', action.payload)
    case SET_REPORT_COLUMNS:
      return setField(state, 'report', 'columns', action.payload)
    case SET_REPORT_ROWS:
      return setField(state, 'report', 'rows', action.payload)
    case SET_SELECTED_REPORT_TYPE:
      return setField(state, 'report', 'selectedReportType', action.payload)
    case SET_REPORT_ERROR:
      return setField(state, 'report', 'error', action.payload)
    default:
      return state
  }
}

export default reports
