import React, { useEffect, useMemo } from 'react'
import { History, Location } from 'history'
import moment from 'moment'

import SubNav from '../../components/SubNav'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'

import { getComponent } from './getComponent'
import { getClearWheelPosition } from '../../utils'
import { addPowerInputsChart, subscriptionFilter } from './utils'
import { NoDataLabels } from '../../enums'

import { IComponentConfigItem } from './types'

interface ICommonPropsContext {
  isSuperAdmin: boolean
  isAdmin: boolean
  locale: ILocale
  healthColors: Array<IHealthColor>
  wheelColors: any
  gpsColors: any
  chartsXAxisLabel: string
  unitsOfMeasurementConfig: any
  assetInfoData: IAssetInfo | null
  timestamp: number | null
}
interface IAssetDetailsContext extends ICommonPropsContext {
  userName: string
}
export const AssetDetailsContext = React.createContext<IAssetDetailsContext>(
  {} as IAssetDetailsContext,
)

interface Props {
  history: History
  location: Location
  match: {
    params: {
      vin: string
      timestamp?: string
      warningKey?: string
      days?: string
    }
  }
  name: string
}

const AssetDetails: React.FC<Props> = ({ match }) => {
  const {
    isSuperAdmin,
    isAdmin,
    preferences,
    assetInfoData,
    whitelabel: {
      locale,
      wheelColors,
      warnings,
      details,
      healthColors,
      gpsColors,
    },
    userName,
  } = useTypedSelector((state) => ({
    isSuperAdmin: state.auth.isSuperAdmin,
    isAdmin: state.auth.isAdmin,
    preferences: state.auth.preferences,
    assetInfoData: state.assetDetails.assetInfo.data,
    whitelabel: state.whitelabel,
    userName: state.auth.user.userName,
  }))
  const {
    fetchAssetDetailsAssetInfo,
    fetchAssetDetailsComponentsData,
    resetAssetDetailsReducer,
  } = useActions()

  const pageData = useMemo(() => {
    return {
      vin: match.params.vin,
      timestamp: match.params.timestamp
        ? parseInt(match.params.timestamp)
        : null,
      warningKey: match.params.warningKey,
      days: match.params.days,
    }
  }, [match.params])

  useEffect(() => {
    // fetch if there is no asset in store or asset vin is different
    if (!assetInfoData || assetInfoData.vin !== pageData.vin) {
      fetchAssetDetailsAssetInfo(pageData.vin, pageData.timestamp)
    }
  }, [
    assetInfoData,
    pageData.vin,
    pageData.timestamp,
    fetchAssetDetailsAssetInfo,
  ])

  useEffect(() => {
    fetchAssetDetailsComponentsData(pageData.timestamp)
  }, [pageData.timestamp, pageData.warningKey, fetchAssetDetailsComponentsData])

  useEffect(() => {
    // clear store on unmount
    return () => {
      resetAssetDetailsReducer(true)
    }
  }, [resetAssetDetailsReducer])

  const title = useMemo(() => {
    const pageName = pageData.warningKey ? 'Warning Details' : 'Asset Details'

    if (!assetInfoData) return pageName

    const assetName = assetInfoData.name
    const assetVin = assetInfoData.vin || NoDataLabels.DASH

    return `${pageName} For Asset: ${assetInfoData.customer_name || ''}, ${
      assetName ? assetName : assetVin
    }`
  }, [assetInfoData, pageData])

  const breadcrumbs = useMemo(() => {
    const data = [
      { label: 'Home', path: '/dashboard' },
      { label: 'Asset Details' },
    ]

    if (pageData.warningKey) {
      data[1].path = `/details/${pageData.vin}?reducePoints=true`
      data.push({ label: 'Warning Details' })
    }

    return data
  }, [pageData])

  const components = useMemo(() => {
    let componentsConfig: Array<IComponentConfigItem> = pageData.warningKey
      ? warnings
      : details

    if (assetInfoData) {
      componentsConfig = subscriptionFilter(
        isSuperAdmin,
        componentsConfig,
        assetInfoData,
      )
    }
    if (!pageData.warningKey && isSuperAdmin) {
      componentsConfig = addPowerInputsChart(componentsConfig)
    }

    const result: Array<any> = []

    componentsConfig.forEach((item) => {
      if (item.data.enabled)
        result.push({
          id: item.id,
          width: item.data.width,
        })
    })

    return result
  }, [isSuperAdmin, pageData, details, warnings, assetInfoData])

  const commonProps: IAssetDetailsContext = useMemo(() => {
    return {
      isSuperAdmin,
      isAdmin,
      locale,
      healthColors,
      wheelColors: getClearWheelPosition(wheelColors),
      chartsXAxisLabel: `Date (${moment()
        .tz(preferences.timezone)
        .format('z')})`,
      unitsOfMeasurementConfig: preferences.unitsOfMeasurementConfig,
      assetInfoData,
      gpsColors,
      timestamp: pageData.timestamp,
      warningKey: pageData.warningKey,
      userName: userName,
    }
  }, [
    isSuperAdmin,
    isAdmin,
    pageData,
    preferences,
    wheelColors,
    locale,
    healthColors,
    assetInfoData,
    gpsColors,
    userName,
  ])

  return (
    <>
      <SubNav title={title} breadcrumbs={breadcrumbs} />

      <AssetDetailsContext.Provider value={commonProps}>
        {components.map((c) => getComponent(c.id, c.width, pageData))}
      </AssetDetailsContext.Provider>
    </>
  )
}

export default React.memo(AssetDetails)
