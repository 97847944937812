import React from "react";
import { DATE_FORMAT_ZONE } from "../../../utils";
import { NoDataLabels } from "../../../enums";

const TimeSinceGpsUpdate = ({ lastUpdatedAtDateTime }) => {
    return (
        <span style={{ fontSize: "14px", marginBottom: "15px" }}>
            Last GPS update: {lastUpdatedAtDateTime ? lastUpdatedAtDateTime.format(DATE_FORMAT_ZONE) : NoDataLabels.DASH}
        </span>
    );
};

export default TimeSinceGpsUpdate;