import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import HealthStatusRow from '../ReportParametersRows/HealthStatusRow'

const MilesInWarningSteateReportParameters: React.FC<ReportParameterGroupProps> = (
    props: ReportParameterGroupProps,
) => {
    return (
        <>
            <HealthStatusRow value={props.parameters.warning_filter} />
            <DateRangeRow
                value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
            />
            <AssetTypeRow value={props.parameters.asset_type} />
            <AssetsRow value={props.parameters.vins} />
            <AssetGroupsRow value={props.parameters.asset_group} />
        </>
    )
}
export default MilesInWarningSteateReportParameters
