import React from "react"


interface IDefaultItem {
    item: any
    labelKey: string
    subLabelKey?: string
}

export const DefaultItem: React.FC<IDefaultItem> = ({ item, labelKey, subLabelKey }) => {
    return (
        <>
            <b>{item[labelKey]}</b>
            <br/>
            {subLabelKey && <label>{item[subLabelKey]}</label>}
        </>
    )
}


interface IUserItem { item: IUserShortFormat }

export const UserItem: React.FC<IUserItem> = ({ item }) => {
    let str = ""

    if (item.first_name) str += item.first_name
    if (item.last_name) str += ` ${item.last_name}`

    return (
        <div>
            <b>{`${str}`}</b> ({item.username})

            <br/>

            <label>{item.email}</label>
        </div>
    )
}

