import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DateRangePicker, Range } from 'react-date-range'
import { Row, Col, Input } from 'reactstrap'
import { CalendarTypes } from '../../enums'
import moment, { Moment } from 'moment'
import RivataDropdown from '../../components/RivataDropdown'

import './style.scss'
import {
  dateFormat,
  dateWithTimeFormat,
  timeFormat,
} from '../../constants/constants'
import { cloneDeep } from 'lodash'

export enum PositioningValues {
  topLeft = '--top-left-aligned',
  topRight = '--top-right-aligned',
  topRightNoIndentation = '--top-right-aligned-no-indent',
}

interface Props {
  defaultMode?: string
  dateFrom: string
  dateTo: string
  onDateRangeSelect: (from: Date, to: Date) => void
  timeEnabled?: boolean
  hourLimit?: number
  minDate?: Date
  maxDate?: Date
  disabled: boolean
  limitToHoursSelection?: boolean
  showSecondInput?: boolean
  defaultContainer?: boolean
  className?: string
  positioning?: number
  availableModes?: string[]
  defaultDateFrom?: string
  defaultDateTo?: string
}

const dropdownItems = [
  {
    id: CalendarTypes.Continuous,
    label: 'Continuous',
  },
  {
    id: CalendarTypes.DateRange,
    label: 'Date range',
  },
  {
    id: CalendarTypes.ExactDate,
    label: 'Exact date',
  },
]

const DateTimePicker: React.FC<Props> = ({
  defaultMode,
  dateFrom,
  onDateRangeSelect,
  timeEnabled = false,
  hourLimit,
  dateTo,
  minDate = new Date(2000, 0, 1),
  maxDate = new Date(),
  disabled,
  limitToHoursSelection,
  showSecondInput = true,
  defaultContainer = true,
  className,
  positioning = PositioningValues.topLeft,
  availableModes = [
    CalendarTypes.Continuous,
    CalendarTypes.DateRange,
    CalendarTypes.ExactDate,
  ],
  defaultDateFrom,
  defaultDateTo,
}) => {
  const ref = useRef<any>()

  const [showCalendar, setShowCalendar] = useState(false)
  const [selectedMode, setSelectedMode] = useState(defaultMode)

  const [defaults] = useState({
    startDate: moment(dateFrom).hours(0).minutes(0).toDate(),
    endDate: moment(dateTo)
      .hours(23)
      .minutes(limitToHoursSelection ? 0 : 59)
      .toDate(),
    startTime: '00:00',
    endTime: limitToHoursSelection ? '23:00' : '23:59',
  })

  const range = useMemo(() => {
    return {
      startDate: minDate,
      endDate: maxDate,
    }
  }, [minDate, maxDate])

  const [selectedDateTime, setSelectedDateTime] = useState({
    startDate: moment(dateFrom).format(dateFormat),
    endDate: moment(dateTo).format(dateFormat),
    startTime: moment(dateFrom).format(timeFormat),
    endTime: moment(dateTo).format(timeFormat),
  })

  useEffect(() => {
    const startD = getFormattedDate(dateFrom.toString())
    const endD = getFormattedDate(dateTo.toString())
    const startT = getFormattedTime(moment(dateFrom).format('HH:mm').toString())
    const endT = getFormattedTime(moment(dateTo).format('HH:mm').toString())

    updateInputsDateTime(startD, endD, startT, endT)
  }, [dateFrom, dateTo])

  // Inputs
  const [startDate, setStartDate] = useState(
    moment(dateFrom).format(dateFormat),
  )
  const [endDate, setEndDate] = useState(moment(dateTo).format(dateFormat))
  const [startTime, setStartTime] = useState(
    moment(dateFrom).format(timeFormat),
  )
  const [endTime, setEndTime] = useState(moment(dateTo).format(timeFormat))

  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: moment(dateFrom).year(moment().year()).toDate(),
    endDate: moment(dateTo).year(moment().year()).toDate(),
    key: 'selection',
  })

  const updateDateTime = useCallback(
    (
      startDate: string = selectedDateTime.startDate,
      endDate: string = selectedDateTime.endDate,
      startTime: string = selectedDateTime.startTime,
      endTime: string = selectedDateTime.endTime,
    ) => {
      let newDateTime = selectedDateTime

      newDateTime.startDate = startDate ? startDate : selectedDateTime.startDate
      newDateTime.endDate = endDate ? endDate : selectedDateTime.endDate
      newDateTime.startTime = startTime ? startTime : selectedDateTime.startTime
      newDateTime.endTime = endTime ? endTime : selectedDateTime.endTime

      setSelectedDateTime(newDateTime)

      updateInputsDateTime(
        newDateTime.startDate,
        newDateTime.endDate,
        newDateTime.startTime,
        newDateTime.endTime,
      )
    },
    [selectedDateTime],
  )

  const updateInputsDateTime = (
    startD: string,
    endD: string,
    startT: string,
    endT: string,
  ) => {
    setStartDate(startD)
    setEndDate(endD)
    setStartTime(startT)
    setEndTime(endT)
  }

  const getFormattedDate = (date: string) => {
    return moment(date).format(dateFormat)
  }

  const getFormattedTime = (time: string) => {
    return moment(time, 'hh:mm A').format(timeFormat)
  }

  const handleSelectMode = (id: string): void => {
    const selected = dropdownItems.find((di) => di.id === id)

    if (selected) {
      setSelectedMode(selected.id)

      if (selected.id === CalendarTypes.Continuous) {
        let end = moment(range.endDate)

        if (hourLimit) {
          end = moment(selectedDateTime.startDate).add(hourLimit, 'hours')
        }

        if (end.valueOf() > moment(range.endDate).valueOf()) {
          end = moment(range.endDate)
        }

        updateDateTime(
          selectedDateTime.startDate,
          getFormattedDate(end.hours(23).minutes(59).toString()),
          getFormattedTime(defaults.startTime),
          getFormattedTime(defaults.endTime),
        )

        setSelectionRange((prev) => {
          return {
            ...prev,
            startDate: moment(selectedDateTime.startDate).toDate(),
            endDate: end.toDate(),
          }
        })
      }

      if (selected.id === CalendarTypes.ExactDate) {
        updateDateTime(
          selectedDateTime.startDate,
          selectedDateTime.startDate,
          getFormattedTime(defaults.startTime),
          getFormattedTime(defaults.endTime),
        )

        setSelectionRange((prev) => {
          return {
            ...prev,
            startDate: moment(selectedDateTime.startDate).toDate(),
            endDate: moment(selectedDateTime.startDate).toDate(),
          }
        })
      }

      if (selected.id === CalendarTypes.DateRange) {
        updateDateTime(
          selectedDateTime.startDate,
          selectedDateTime.endDate,
          getFormattedTime(defaults.startTime),
          getFormattedTime(defaults.endTime),
        )
      }
    }
  }

  const handleChangeDate = (e: any): void => {
    const { startDate, endDate } = e.selection

    let start = startDate
    let end = endDate

    if (
      selectedMode === CalendarTypes.ExactDate ||
      selectedMode === CalendarTypes.Continuous
    ) {
      // Hack to have ability of exact date selection without ranges
      if (
        moment(dateFrom).valueOf() < moment(start).valueOf() ||
        moment(dateFrom).valueOf() < moment(end).valueOf()
      ) {
        start = end
      } else {
        end = start
      }
    }

    if (selectedMode === CalendarTypes.Continuous) {
      if (
        hourLimit &&
        moment(start).add(hourLimit, 'hours').valueOf() <=
          moment(maxDate).valueOf()
      ) {
        end = moment(start).add(hourLimit, 'hours').toDate()
      } else {
        end = maxDate
      }
    }

    const startT = moment(startTime, timeFormat)
    const endT = moment(endTime, timeFormat)

    const startHours = startT.hours()
    const startMinutes = startT.minutes()

    const endHours = endT.hours()
    const endMinutes = endT.minutes()

    if (selectedMode !== CalendarTypes.Continuous) {
      start = moment(start).hours(startHours).minutes(startMinutes).toDate()

      end = moment(end).hours(endHours).minutes(endMinutes).toDate()
    } else {
      start = moment(start).hours(startHours).minutes(startMinutes).toDate()

      end = moment(end).hours(23).minutes(59).toDate()
    }

    onDateRangeSelect(start, end)
    updateDateTime(getFormattedDate(start), getFormattedDate(end))

    setSelectionRange((prev) => {
      return {
        ...prev,
        startDate: moment(start).toDate(),
        endDate: moment(end).toDate(),
      }
    })
  }

  const handleOnBlur = () => {
    validateValues()
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleOnBlur()
    }
  }

  const validateValues = useCallback(() => {
    // Date
    let newStartDate = moment(dateFrom)
    let newEndDate = moment(dateTo)

    const tmpDateStart = moment(startDate)
    const tmpDateEnd = moment(endDate)

    if (moment(tmpDateStart).isValid()) {
      if (!(tmpDateStart.valueOf() < moment(range.startDate).valueOf())) {
        if (selectedMode === CalendarTypes.ExactDate) {
          newStartDate = moment(startDate)
          newEndDate = cloneDeep(newStartDate)
        } else {
          if (!(tmpDateStart.valueOf() > tmpDateEnd.valueOf())) {
            newStartDate = moment(startDate)
          }
        }
      }
    }

    if (selectedMode !== CalendarTypes.ExactDate) {
      if (moment(tmpDateEnd).isValid()) {
        if (!(tmpDateEnd.valueOf() > moment(range.endDate).valueOf())) {
          if (!(tmpDateEnd.valueOf() < tmpDateStart.valueOf())) {
            newEndDate = moment(endDate)
          }
        }
      }
    }

    if (newStartDate.year() === 2001) {
      newStartDate = moment(newStartDate).year(moment().year())
    }

    if (newEndDate.year() === 2001) {
      newEndDate = moment(newEndDate).year(moment().year())
    }

    // Time
    let newStartTime = moment(defaults.startTime, 'HH:mm')
    let newEndTime = moment(defaults.endTime, 'HH:mm')

    let tmpStartTime: Moment = newStartTime
    let tmpEndTime: Moment = newEndTime

    if (moment(startTime, 'hh:mm A').isValid()) {
      tmpStartTime = moment(startTime, 'hh:mm A')
    } else if (moment(startTime, 'hh:mm').isValid()) {
      tmpStartTime = moment(startTime, 'hh:mm')
    }

    if (moment(endTime, 'hh:mm A').isValid()) {
      tmpEndTime = moment(endTime, 'hh:mm A')
    } else if (moment(endTime, 'hh:mm').isValid()) {
      tmpEndTime = moment(endTime, 'hh:mm')
    }

    if (tmpStartTime.isValid()) {
      if (!(tmpStartTime.valueOf() > tmpEndTime.valueOf())) {
        newStartTime = tmpStartTime
      }
    }

    if (tmpEndTime.isValid()) {
      if (!(tmpEndTime.valueOf() < tmpStartTime.valueOf())) {
        newEndTime = tmpEndTime
      }
    }

    newStartTime = limitToHoursSelection
      ? newStartTime.minutes(0)
      : newStartTime
    newEndTime = limitToHoursSelection ? newEndTime.minutes(0) : newEndTime

    newStartDate = newStartDate
      .hours(newStartTime.hours())
      .minutes(newStartTime.minutes())
      .seconds(0)
    newEndDate = newEndDate
      .hours(newEndTime.hours())
      .minutes(newEndTime.minutes())
      .seconds(0)

    const startTimeValue = getFormattedTime(newStartTime.format('HH:mm'))
    const endTimeValue = getFormattedTime(newEndTime.format('HH:mm'))
    const startDateValue = getFormattedDate(newStartDate.toString())
    const endDateValue = getFormattedDate(newEndDate.toString())

    updateDateTime(startDateValue, endDateValue, startTimeValue, endTimeValue)
    onDateRangeSelect(newStartDate.toDate(), newEndDate.toDate())
  }, [
    dateFrom,
    dateTo,
    onDateRangeSelect,
    range,
    selectedMode,
    defaults,
    limitToHoursSelection,
    endDate,
    startDate,
    endTime,
    startTime,
    updateDateTime,
  ])

  const resetValues = () => {
    if (defaultDateFrom && defaultDateTo) {
      updateDateTime(
        defaultDateFrom,
        defaultDateTo,
        moment(defaultDateFrom).format(timeFormat),
        moment(defaultDateTo).format(timeFormat),
      )

      updateInputsDateTime(
        moment(defaultDateFrom).format(dateFormat),
        moment(defaultDateTo).format(dateFormat),
        moment(defaultDateFrom).format(timeFormat),
        moment(defaultDateTo).format(timeFormat),
      )

      const dt = {
        selection: {
          startDate: moment(defaultDateFrom),
          endDate: moment(defaultDateTo),
        },
      }

      handleChangeDate(dt)
    } else {
      updateDateTime(
        '',
        '',
        getFormattedTime(defaults.startTime),
        getFormattedTime(defaults.endTime),
      )

      updateInputsDateTime(
        '',
        '',
        moment(defaults.startTime, 'HH:mm').format(timeFormat),
        moment(defaults.endTime, 'HH:mm').format(timeFormat),
      )
    }
  }

  useEffect(() => {
    setSelectionRange((prev) => {
      return {
        ...prev,
        startDate: moment(dateFrom).toDate(),
        endDate: moment(dateTo).toDate(),
      }
    })
  }, [dateFrom, dateTo])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        validateValues()
        setShowCalendar(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [validateValues])

  return (
    <>
      <div>
        <Row
          className={`${
            defaultContainer
              ? timeEnabled
                ? 'inputs-container-wide'
                : 'inputs-container'
              : ''
          } m-0 ${className}`}
        >
          <Col md={6} className='pl-0 pr-0'>
            <Input
              type='text'
              className={
                timeEnabled ? 'calendar-wide-input' : 'calendar-date-input'
              }
              onClick={() => {
                setShowCalendar(true)
              }}
              value={moment(dateFrom).format(
                timeEnabled ? dateWithTimeFormat : dateFormat,
              )}
              disabled={disabled}
            ></Input>
          </Col>
          {showSecondInput && (
            <Col md={6}>
              <Input
                type='text'
                className={
                  timeEnabled ? 'calendar-wide-input' : 'calendar-date-input'
                }
                onClick={() => {
                  setShowCalendar(true)
                }}
                value={moment(dateTo).format(
                  timeEnabled ? dateWithTimeFormat : dateFormat,
                )}
                disabled={disabled}
              ></Input>
            </Col>
          )}
        </Row>
      </div>
      {showCalendar && (
        <div
          className={`form-container form-container${positioning}`}
          ref={ref}
        >
          {selectedMode !== CalendarTypes.ExactDate &&
            hourLimit &&
            moment
              .duration(
                moment(selectedDateTime.endDate).diff(
                  moment(selectedDateTime.startDate),
                ),
              )
              .asHours() > hourLimit && (
              <div className='info-tooltip'>
                <b>Note</b>
                <br />
                Only up to
                {hourLimit + 24 > 72
                  ? ` ${Math.floor((hourLimit + 24) / 24)} days `
                  : ` ${hourLimit + 24} hours `}
                of data can be shown for this selection.
              </div>
            )}
          <Row className='header-container m-0'>
            <Col md={3} className='p-0'>
              {availableModes.length > 1 && (
                <RivataDropdown
                  caret={true}
                  items={dropdownItems.filter((di) =>
                    availableModes.includes(di.id),
                  )}
                  selected={selectedMode}
                  onSelect={handleSelectMode}
                  buttonLabel={null}
                  filters={null}
                  onRemove={null}
                  disabled={disabled}
                  color={'secondary'}
                  v2Style={true}
                />
              )}
            </Col>
            <Col md={9} className='header-right-container'>
              <Row className='header-right-container__content m-0'>
                <Col md={selectedMode === CalendarTypes.DateRange ? 5 : 10}>
                  <Input
                    autoFocus
                    type='text'
                    className='calendar-date-input'
                    onChange={(e: any) => setStartDate(e.target.value)}
                    value={startDate}
                    onBlur={handleOnBlur}
                    onKeyDown={handleKeyDown}
                    placeholder='Select a start date'
                    id='startDate'
                    name='startDate'
                  />
                </Col>
                {selectedMode === CalendarTypes.DateRange && (
                  <Col md={5}>
                    <Input
                      type='text'
                      className='calendar-date-input'
                      onChange={(e: any) => setEndDate(e.target.value)}
                      value={endDate}
                      onBlur={handleOnBlur}
                      onKeyDown={handleKeyDown}
                      placeholder='Select an end date'
                      id='endDate'
                      name='endDate'
                    ></Input>
                  </Col>
                )}
                <Col md={2} className='d-flex justify-content-end pr-0'>
                  <span className='reset-btn' onClick={resetValues}>
                    Reset
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleChangeDate}
            minDate={range.startDate}
            maxDate={range.endDate}
            months={2}
            direction='horizontal'
            staticRanges={[]}
            inputRanges={[]}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            showMonthArrow={true}
            moveRangeOnFirstSelection={selectedMode === CalendarTypes.DateRange}
            showPreview={selectedMode === CalendarTypes.DateRange}
            dragSelectionEnabled={selectedMode === CalendarTypes.DateRange}
          />
          {timeEnabled && (
            <Row className='align-items-center justify-content-between'>
              <Col md={4}>
                {selectedMode === CalendarTypes.DateRange && (
                  <span className='label'>Time range</span>
                )}
              </Col>
              <Col md={7}>
                <Row>
                  <Col
                    md={selectedMode === CalendarTypes.DateRange ? 6 : 12}
                    className='d-flex flex-direction-row align-items-center gap-1'
                  >
                    <span className='label pr-2 ml-auto'>
                      {selectedMode === CalendarTypes.DateRange
                        ? 'From'
                        : 'Time'}
                    </span>
                    <Input
                      type='text'
                      className='calendar-input'
                      value={startTime}
                      placeholder='Select a start time'
                      onChange={(e: any) => setStartTime(e.target.value)}
                      name='startTime'
                      id='startTime'
                      onBlur={handleOnBlur}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                  {selectedMode === CalendarTypes.DateRange && (
                    <Col
                      md={6}
                      className='d-flex flex-direction-row align-items-center gap-1'
                    >
                      <span className='label pr-2'>To</span>
                      <Input
                        type='text'
                        className='calendar-input'
                        value={endTime}
                        placeholder='Select an end time'
                        onChange={(e: any) => setEndTime(e.target.value)}
                        name='endTime'
                        id='endTime'
                        onBlur={handleOnBlur}
                        onKeyDown={handleKeyDown}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
          {selectedMode !== CalendarTypes.ExactDate &&
            hourLimit &&
            moment
              .duration(
                moment(selectedDateTime.endDate).diff(
                  moment(selectedDateTime.startDate),
                ),
              )
              .asHours() > hourLimit && (
              <p className='mt-3 mb-0 info-notice'>
                *Not all data may be shown for this date with the current
                selection. Adjust the time range or select “Exact date” for this
                date’s full data set.
              </p>
            )}
        </div>
      )}
    </>
  )
}

export default DateTimePicker
