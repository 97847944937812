import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { cloneDeep, groupBy, orderBy } from 'lodash'

import DropdownItemSelector from '../DropdownItemSelector'
import { MenuItemType } from '../Menu/MenuItem'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface Props {
  onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
  selectedValueIds?: any[]
  className?: string
  disabled?: boolean
  customerIds?: string
  hideNoGroupOption?: boolean
  clearSelectionOnReset?: boolean
}

const GeofenceGroupsFilter = forwardRef(
  (
    {
      onDropdownItemSelect,
      selectedValueIds,
      className,
      disabled,
      customerIds,
      hideNoGroupOption,
      clearSelectionOnReset,
    }: Props,
    ref,
  ) => {
    const [geofenceGroupItems, setGeofenceGroupItems] = useState(
      [] as Array<MenuItemType>,
    )
    const [selectedItems, setSelectedItems] = useState(
      [] as Array<MenuItemType>,
    )
    const [geofenceGroupLabel, setGeofenceGroupLabel] =
      useState('Geofence Group')

    const { geofenceGroupsLimitedData } = useTypedSelector((state) => ({
      geofenceGroupsLimitedData:
        state.geofenceGroups.geofenceGroups.limitedData,
    }))
    const { fetchGeofenceGroupsLimitedDataForCustomers } = useActions()
    const isNewSelectedValueIds = useRef(true)

    useEffect(() => {
      if (selectedValueIds) isNewSelectedValueIds.current = true
    }, [selectedValueIds])

    useEffect(() => {
      setSelectedItems([])
      fetchGeofenceGroupsLimitedDataForCustomers(customerIds ? customerIds : '')
    }, [fetchGeofenceGroupsLimitedDataForCustomers, customerIds])

    const onGroupItemSelect = useCallback(
      (id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(
          (el) => el.checked && el.id.indexOf('||') === -1,
        )
        if (onDropdownItemSelect) onDropdownItemSelect(id, checked)
        setSelectedItems(checked)
      },
      [onDropdownItemSelect],
    )

    const buildGeofenceGroupsListItem = useCallback(() => {
      let groups: Array<MenuItemType> = []
      const checkedItems =
        !clearSelectionOnReset || isNewSelectedValueIds.current
          ? cloneDeep(selectedValueIds)
          : undefined
      if (customerIds && customerIds.split(',').length === 1) {
        groups = (geofenceGroupsLimitedData as any[]).map((ag: any) => {
          return {
            id: ag.id.toString(),
            label: ag.name,
            type: 'checkbox',
            markType: 'check',
            checked:
              checkedItems && checkedItems.includes(ag.id.toString())
                ? true
                : false,
            parentId: null,
          } as MenuItemType
        })
      } else {
        const groupedGroups = groupBy(
          orderBy(geofenceGroupsLimitedData, 'customer_name') as any[],
          (item) => `${item.customer_id}||${item.customer_name}`,
        )
        Object.keys(groupedGroups).forEach((k) => {
          const customer = k.split('||')
          const allChecked =
            checkedItems &&
            groupedGroups[k].every(
              (ag: any) => checkedItems?.includes(ag.id.toString()),
            )
              ? true
              : false
          const anyChecked =
            checkedItems &&
            groupedGroups[k].some(
              (ag: any) => checkedItems?.includes(ag.id.toString()),
            )
              ? true
              : false
          groups.push({
            id: k,
            label: customer[1],
            type: 'checkbox',
            markType:
              (allChecked ? 'check' : anyChecked ? 'line' : 'check') || 'check',
            checked: anyChecked || allChecked ? true : false,
            parentId: null,
            subMenuExpanded: true,
          } as MenuItemType)
          groupedGroups[k].forEach((ag: any) => {
            groups.push({
              id: ag.id.toString(),
              label: ag.name,
              type: 'checkbox',
              markType: 'check',
              checked:
                checkedItems && checkedItems.includes(ag.id.toString())
                  ? true
                  : false,
              parentId: k,
            } as MenuItemType)
          })
        })
      }

      if (!hideNoGroupOption) {
        groups.unshift({
          id: '-1',
          label: 'Geofences without group',
          type: 'checkbox',
          markType: 'check',
          checked: checkedItems && checkedItems?.includes('-1') ? true : false,
          parentId: null,
        })
      }
      if (checkedItems) {
        onGroupItemSelect(
          'geofence_group',
          groups.filter((ag) => checkedItems?.includes(ag.id)),
        )
      }
      setGeofenceGroupItems(groups)
    }, [
      geofenceGroupsLimitedData,
      customerIds,
      onGroupItemSelect,
      selectedValueIds,
      hideNoGroupOption,
      clearSelectionOnReset,
    ])

    useEffect(() => {
      buildGeofenceGroupsListItem()
    }, [buildGeofenceGroupsListItem, geofenceGroupsLimitedData])
    useImperativeHandle(ref, () => ({
      clearSelection() {
        isNewSelectedValueIds.current = false
        buildGeofenceGroupsListItem()
        setSelectedItems([])
      },
    }))
    useEffect(() => {
      setGeofenceGroupLabel(
        'Geofence Group' +
          (selectedItems && selectedItems?.length > 0
            ? `: ${selectedItems?.length} selected`
            : ''),
      )
    }, [selectedItems])
    return (
      <DropdownItemSelector
        id='geofence_group'
        filterClassName={`mr-2 ${className ?? ''}`}
        filterBtnLabel={geofenceGroupLabel}
        items={geofenceGroupItems || []}
        onItemsChange={onGroupItemSelect}
        disabled={disabled ? disabled : false}
        clearSelectionOnReset={clearSelectionOnReset}
      />
    )
  },
)

export default React.memo(GeofenceGroupsFilter)
