import React, { useEffect, useState, useRef } from 'react'
import config from '../../../config/appConfig'
import { behaviorUi } from '../utils'
import { LayerTypes } from '../../../components/RivataMapCluster/utils'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'
interface Props {
  map: H.Map | null
  selectedLayerOption: string
  setMap: (map: H.Map) => void
  setUi: (ui: H.ui.UI) => void
  setBehavior: (behavior: H.mapevents.Behavior) => void
}

const GeofenceMap: React.FC<Props> = ({
  map,
  selectedLayerOption,
  setMap,
  setUi,
  setBehavior,
}) => {
  const { preferences } = useTypedSelector((state) => ({
    preferences: state.auth.preferences,
  }))

  const mapRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const [defaultLayers, setDefaultLayers] = useState<H.service.DefaultLayers>()
  useEffect(() => {
    const platform = new H.service.Platform({
      apikey: config.mapApiKey,
    })
    
    //@ts-expect-error //bug of @types/heremaps
    const engineType = H.Map.EngineType['HARP'];
    //@ts-expect-error //bug of @types/heremaps
    const defaultLayers = platform.createDefaultLayers({engineType, lg: 'en'})

    setDefaultLayers(defaultLayers)

    setMap(
      new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        engineType,
        center: { lat: 41.850033, lng: -87.6500523 },
        zoom: 4,
        pixelRatio: window.devicePixelRatio || 1,
      }),
    )
  }, [mapRef, setMap, setDefaultLayers])

  useEffect(() => {
    const resize = () => {
      if (map) map.getViewPort().resize()
    }

    if (map) {
      window.addEventListener('resize', resize)

      if (defaultLayers) {
        const { ui, behavior } = behaviorUi(map, defaultLayers)
        ui.getControl('zoom').setVisibility(true)
        ui.getControl('mapsettings').setVisibility(false)
        ui.setUnitSystem(
          preferences.unitsOfMeasurementConfig ===
            UnitsOfMeasurementConfig.distance.km
            ? H.ui.UnitSystem.METRIC
            : H.ui.UnitSystem.IMPERIAL,
        )
        setUi(ui)
        setBehavior(behavior)
      }

      resize()
    }

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [
    defaultLayers,
    setUi,
    preferences.unitsOfMeasurementConfig,
    map,
    setBehavior,
  ])

  useEffect(() => {
    return () => {
      if (map) map.dispose()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (map && defaultLayers && selectedLayerOption) {
      const layerChoices = {
        [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
        [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
      }

      const baseLayer = layerChoices[selectedLayerOption]

      if (baseLayer) map.setBaseLayer(baseLayer)
    }
  }, [map, defaultLayers, selectedLayerOption])

  return <div className='geofence-map mb-3' ref={mapRef} />
}

export default GeofenceMap
