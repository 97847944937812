import { BackendSearchOptions } from "../../enums"
import api from "../../services/api"

export type IBackendSearchEndPointConfig = {
  search: (value: string, customerIds?: string) => Promise<any>
} & (
  | {
      lablelKey: string
      subLabelKey?: string
      isUserItem?: false
    }
  | {
      lablelKey?: string
      subLabelKey?: string
      isUserItem: true
    }
)

export const EndPointConfigs: Record<
  BackendSearchOptions,
  IBackendSearchEndPointConfig
> = {
  assetName: {
    search: async (value: string) => await api.searchAssets(value),
    lablelKey: "name",
    subLabelKey: "vin",
  },
  assetVin: {
    search: async (value: string) => await api.searchAssets(value),
    lablelKey: "vin",
    subLabelKey: "name",
  },
  warningSetting: {
    search: async (value: string) =>
      await api
        .searchWarningsSettings(value)
        .then((res: any) =>
          res.filter(
            (i: any, id: number) =>
              id === res.findIndex((j: any) => j.name === i.name)
          )
        ),
    lablelKey: "name",
  },
  user: {
    search: async (value: string) => await api.searchUsers(value),
    isUserItem: true,
  },
  geofence: {
    search: async (value: string, customerIds?: string) => await api.searchGeolocations(value, 2, undefined, customerIds),
    lablelKey: "name",
  },
  tagGeofence: {
    search: async (value: string) => await api.searchGeolocations(value, 3),
    lablelKey: "name",
  },
  cargotag: {
    search: async (value: string) => await api.searchCargoTags(value),
    lablelKey: "name",
    subLabelKey: "mac",
  },
}
