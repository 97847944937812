import React, { useCallback, useMemo } from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'

import { EndPointConfigs } from '../../../components/BackendSearchInput/constants'
import RivataDropdown from '../../../components/RivataDropdown'
import { ReportTypes } from '../../../enums'
import { getClearLabel, sortByAlphabet } from '../../../utils'

type IScheduledReportsSubscriptionsReportForm = {
  disabled: boolean
  setUsernames: (list: Object[]) => void
  selectedUsersItems: Array<SearchItemType>
  reportTypeFilter: string
  setReportTypeFilter: (id: string) => void
}

const usersColumnsToShow = [
  { key: 'username', name: 'Username' },
  { key: 'first_name', name: 'First Name' },
  { key: 'last_name', name: 'Last Name' },
  { key: 'email', name: 'Email' },
]

const ScheduledReportsSubscriptionsReportForm: React.FC<
  IScheduledReportsSubscriptionsReportForm
> = ({
  disabled,
  setUsernames,
  selectedUsersItems,
  reportTypeFilter,
  setReportTypeFilter,
}) => {
  const onUsersSelect = useCallback(
    (selectedUsers: SearchItemType[]) => {
      setUsernames(selectedUsers)
    },
    [setUsernames],
  )

  const reportItems = useMemo(() => {
    const items = Object.values(ReportTypes).map((el) => ({
      id: el,
      label: getClearLabel(el),
    }))

    sortByAlphabet(items, 'label')

    return items
  }, [])

  return (
    <>
      <ItemsSelectWithSearch
        label='Users'
        inputPlaceholder='Search Users'
        endPointConfig={EndPointConfigs.user}
        onItemsSelect={onUsersSelect}
        columnsToShow={usersColumnsToShow}
        disabled={disabled}
        tableWidth='800px'
        selectedItems={selectedUsersItems}
      />

      <div style={{ marginBottom: 8, marginTop: 20 }}>Report Type Filter</div>
      <RivataDropdown
        caret={true}
        selected={reportTypeFilter}
        items={[{ id: null, label: null }, ...reportItems]}
        onSelect={(id: string) => setReportTypeFilter(id)}
      />
    </>
  )
}

export default ScheduledReportsSubscriptionsReportForm
