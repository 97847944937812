import { IRow } from "../../../components/RivataGrid";
import { convertDataEpochToDate } from "../../../utils";



export const cargotagGatewayReportRows = (rows: Array<IRow>) => {
    rows.forEach(row => {
        row.parent_customer = row.parent_customer ?? "---"
        row.last_communication_timestamp = row.last_communication_timestamp 
            ? convertDataEpochToDate(row.last_communication_timestamp, null, null, true) 
            : "---"
        row.internal_battery_voltage = row.internal_battery_voltage ?? "---"
        row.latest_latitude = row.latest_latitude ?? "---"
        row.latest_longitude = row.latest_longitude ?? "---"
        row.actual_geofence = row.actual_geofence ?? "---"
    })

    return rows
}