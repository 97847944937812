import { IFilterOptions, SetFilter, SetLoading, SetNumber, SetStatus } from "../types"


export enum ManageNotificationsTypes {
    LOADING           = "LOADING_MANAGE_NOTIFICATIONS",
    SET_DATA          = "SET_MANAGE_NOTIFICATIONS_DATA",
    SET_USERS         = "SET_MANAGE_NOTIFICATIONS_USERS",
    SET_LIMIT         = "SET_MANAGE_NOTIFICATIONS_LIMIT",
    SET_OFFSET        = "SET_MANAGE_NOTIFICATIONS_OFFSET",
    SET_TOTAL_COUNT   = "SET_MANAGE_NOTIFICATIONS_TOTAL_COUNT",
    SET_STATUS        = "SET_MANAGE_NOTIFICATIONS_STATUS",
    SET_FILTER        = "SET_MANAGE_NOTIFICATIONS_FILTER",
}

export interface ManageNotificationsReducer {
    isLoading: boolean
    data: Array<INotificationProfile>
    users: Array<IUserShortFormat>
    limit: number
    offset: number
    totalCount: number
    status: IStatus
    filter: IFilterOptions
}


export interface ICreateNotificationprofile extends Omit<INotificationProfile, "id" | "profile"> {
    profile: {
        tire_pressure?: Array<string>
        line_pressure?: Array<string>
        hub_temperature?: Array<string>
        asset_state?: Array<string>
        geofence?: Array<string>
        tire_vibration?: Array<string>
        bearing_vibration?: Array<string>
        asset_speed?: Array<string>
        pressure_fast_leak?: Array<string>
    }
}

export type IUpdateNotificationprofile = Omit<ICreateNotificationprofile, "customer_id">

type LoadingManageNotifications = SetLoading<ManageNotificationsTypes.LOADING>
type SetManageNotificationsStatus = SetStatus<ManageNotificationsTypes.SET_STATUS>
type SetManageNotificationsNumber = SetNumber<ManageNotificationsTypes.SET_LIMIT | ManageNotificationsTypes.SET_OFFSET | ManageNotificationsTypes.SET_TOTAL_COUNT>
type SetManageNotificationsFilter = SetFilter<ManageNotificationsTypes.SET_FILTER>
interface SetManageNotificationsData {
    type: ManageNotificationsTypes.SET_DATA
    payload: Array<INotificationProfile>
}

interface SetManageNotificationsUsers {
    type: ManageNotificationsTypes.SET_USERS
    payload: Array<IUserShortFormat>
}


export type ManageNotificationActions = LoadingManageNotifications | SetManageNotificationsStatus | SetManageNotificationsNumber | 
    SetManageNotificationsData | SetManageNotificationsUsers | SetManageNotificationsFilter