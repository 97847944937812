import React, { useState, useEffect } from 'react'

import RivataModule from '../../components/RivataModule'
import ReportsFormSwitch from '../ReportsFormSwitch'
import RivataDropdown from '../../components/RivataDropdown'
import StatusAlert from '../../components/StatusAlert'

import { ReportTypes, TagReportTypes } from '../../enums'
import { Button, CustomInput } from 'reactstrap'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { saveGoogleAnalyticsEvent } from '../../utils/utils'

const ReportControls = ({
  isSuperAdmin = false,
  locale = {},
  selectedReport,
  setDateRangeString,
  isAll,
  setIsAll,
  dropdown,
  reportsDropdownItems,
}) => {
  const { selectedCustomersList, hideAutogeneratedAssets, isLoading } =
    useTypedSelector((state) => ({
      hideAutogeneratedAssets: state.common.hideAutogeneratedAssets,
      selectedCustomersList: state.common.customers.selectedCustomersList,
      isLoading: state.reports.report.isLoading,
    }))

  const {
    setSelectedReportType,
    fetchReport,
    cancelReportAction,
    setHideAutogeneratedAssets,
  } = useActions()

  const [reportLink, setReportLink] = useState(null)

  useEffect(() => {
    return () => cancelReportAction()
  }, [cancelReportAction])

  useEffect(() => {
    if (selectedReport !== ReportTypes.LATEST_TAG_LOCATION_REPORT) {
      setIsAll(false)
    }
  }, [selectedReport, setIsAll])

  useEffect(() => {
    setSelectedReportType(null)
  }, [reportsDropdownItems, setSelectedReportType])

  const handleView = () => {
    if (reportLink) {
      saveGoogleAnalyticsEvent('view_report_button_click', { selectedReport })
      fetchReport(reportLink)
    }
  }

  const cancelReport = () => {
    cancelReportAction()
  }

  const showGeneratedHideInput = ![
    ReportTypes.USER_LOGINS_REPORT,
    TagReportTypes.LATEST_TAG_LOCATION_REPORT,
    TagReportTypes.LOST_TAGS_REPORT,
    TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT,
    TagReportTypes.TAG_HISTORY_DETAILS_REPORT,
    TagReportTypes.CARGO_TAG_GATEWAY_REPORT,
    ReportTypes.DRIVER_APP_TRIP_REPORT,
    ReportTypes.SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT,
    ReportTypes.NOTIFICATIONS_SUBSCRIPTIONS_REPORT,
  ].includes(selectedReport)

  return (
    <RivataModule
      title='Report Controls'
      locale={locale}
      filters={
        <RivataDropdown
          caret={true}
          selected={selectedReport}
          buttonLabel={!selectedReport && 'Select Report'}
          items={reportsDropdownItems}
          onSelect={(id) => setSelectedReportType(id)}
        />
      }
      marginTop={0}
    >
      {!selectedReport ? (
        <StatusAlert
          statusText='Please select report in dropdown above.'
          color='success'
        />
      ) : [
          ReportTypes.DWELL_REPORT,
          TagReportTypes.LOST_TAGS_REPORT,
          ReportTypes.UTILIZATION_REPORT,
          ReportTypes.NON_REPORTING_SENSORS_REPORT,
          ReportTypes.VEHICLE_LOCATION_REPORT,
        ].includes(selectedReport) && selectedCustomersList.length > 1 ? (
        <StatusAlert
          customText={
            'This report can not be shown when Multiple customers are selected. Please select a single customer in dropdown.'
          }
          color='success'
        />
      ) : (
        <div className='report'>
          <fieldset>
            {isSuperAdmin && showGeneratedHideInput && (
              <CustomInput
                id='hideAutogenerated'
                className='d-flex align-items-center mb-3'
                type='checkbox'
                label='Hide Autogenerated Assets'
                checked={hideAutogeneratedAssets}
                onChange={() =>
                  setHideAutogeneratedAssets(!hideAutogeneratedAssets)
                }
              />
            )}

            {selectedReport === TagReportTypes.LATEST_TAG_LOCATION_REPORT ? (
              <div className='d-flex'>
                <CustomInput
                  id='useDatePicker'
                  className='d-flex align-items-center mb-3'
                  type='radio'
                  label='Select date range'
                  checked={!isAll}
                  onChange={() => setIsAll(false)}
                />
                <CustomInput
                  id='setAll'
                  className='d-flex align-items-center mb-3 ml-3'
                  type='radio'
                  label='Run report for all time'
                  checked={isAll}
                  onChange={() => setIsAll(true)}
                />
              </div>
            ) : null}

            <ReportsFormSwitch
              selectedReport={selectedReport}
              setReportLink={setReportLink}
              disabled={isLoading}
              setDateRangeString={setDateRangeString}
              isAll={isAll}
              dropdown={dropdown}
            />

            <div className='d-flex justify-content-end'>
              <Button
                className='border-0'
                color='primary'
                onClick={handleView}
                disabled={isLoading}
              >
                View
              </Button>
              <Button
                className='border-0 ml-4 mr-2'
                color='danger'
                onClick={cancelReport}
                disabled={!isLoading}
              >
                Cancel
              </Button>
            </div>
          </fieldset>
        </div>
      )}
    </RivataModule>
  )
}

export default ReportControls
