import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Fade, Spinner } from "reactstrap";
import { getFileTitle, isValidFileName } from "./utils";
import "./styles.scss";
import api from "../../services/api";
import RivataDropdown from "../../components/RivataDropdown";
import { IDocType } from "../../redux/documents/types";

interface Props {
  documents: Array<IDocument>;
  docTypes: IDocType[];
  dir: string;
  addDocument: (dir: string, title: string, doc_type: number) => void;
}

const DocumentsUploader: React.FC<Props> = ({ documents = [], docTypes, dir, addDocument }) => {
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileTitle, setFileTitle] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState<string | null>("");
  const [key, setKey] = useState(0);
  const [selectedDocType, setSelectedDocType] = useState<number | null>(null);

  const typesDropdownItems = useMemo(() => {
    return docTypes.map(item => ({ id: item.id, label: item.name }))
  }, [docTypes])

  const setFileData = (file: File | null, title: string) => {
    setSelectedFile(file);
    setFileTitle(title.trim());
  };

  const clearFileData = () => {
    setFileData(null, "");
    setKey((prev) => (prev += 1));
  };

  const onFileChange = (e: { target: HTMLInputElement }) => {
    setMessage("");

    const files = e.target.files;

    if (!files?.length) {
      clearFileData();
      return;
    }

    const file = files[0];
    setFileData(file, getFileTitle(file.name));
  };

  const onFileUpload = async () => {
    if (!selectedFile) return;

    if (selectedFile.type !== "application/pdf") {
      setError("Only PDF documents can be uploaded");
      return;
    }

    if (isValidFileName(fileTitle)) {
      setError(isValidFileName(fileTitle));
      return;
    }

    if (documents.some((document) => getFileTitle(document.file_name) === fileTitle && document.doc_type !== selectedDocType)) {
      setError("Document with the same title already exists");
      return;
    }

    if (selectedFile.size > 4194304) {
      setError("The file size limit is 4 MB");
      return;
    }

    setUploading(true);

    const title = `${fileTitle}.pdf`;
    const blob: Blob = selectedFile.slice(0, selectedFile.size, "application/pdf");
    const newFile = new File([blob], title, { type: "application/pdf" });

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      async function () {
        if (reader.result && selectedDocType) {
          const base64 = reader.result.toString().split("base64,")[1];

          if (base64) {
            await api
              .postDocument(base64, dir, title, selectedDocType)
              .then(() => {
                clearFileData();
                addDocument(dir, title, selectedDocType);
                setMessage("The document upload is completed");
              })
              .catch((e) => {
                const error = e.message.replace('Exception', "Error")
                setError(error);
              })
              .finally(() => {
                setUploading(false);
              });
          } else {
            setUploading(false);
            setError("Failed to upload document");
          }
        }
      },
      false
    );

    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };

  useEffect(() => {
    if (selectedFile && !fileTitle) {
      setError("Please enter a title!");
    } else {
      setError("");
    }
  }, [selectedFile, fileTitle]);

  useEffect(() => {
    setSelectedDocType(null)
  }, [selectedFile])

  return (
    <div className="d-flex flex-column document-uploader">
      <div className="d-flex align-items-center">
        <Input key={key} type="file" accept="application/pdf" className="file-input" onChange={onFileChange} />
      </div>

      {selectedFile && (
        <Fade className="mt-3">
          <span className="edit-title">Edit a title</span>

          <div className="d-flex mb-2">
            <Input
              className="title-input col-4"
              value={fileTitle}
              onChange={(e: { target: HTMLInputElement }) => {
                setFileTitle(e.target.value);
              }}
              disabled={uploading}
            />
          </div>

          <div className="d-flex justify-content-between col-4 px-0">
            <RivataDropdown
              buttonLabel={selectedDocType === null && 'Select Document Type'}
              caret={true}
              items={typesDropdownItems}
              selected={selectedDocType}
              onSelect={setSelectedDocType}
            />

            <Button className="ml-3 btn-primary" onClick={onFileUpload} disabled={!!error || uploading || !selectedDocType}>
              {uploading ? <Spinner size="sm" color="light" /> : "Upload"}
            </Button>
          </div>

          {error && <span className="error-message">{error}</span>}
        </Fade>
      )}

      {message && <span className="mt-3">{message}</span>}
    </div>
  );
};

export default DocumentsUploader;
