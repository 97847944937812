export interface IAssetGroup {
    id: number
    name: string
    customer_id: number
    customer_name: string
    assets: Array<{ id: number, name: string }> | null
}

export interface IDetailsSort {
    column: string;
    direction: "asc" | "desc";
}

export interface IAssetGroupDetails {
    id?: number
    name?: string
    assigned_assets: {
        paged_data: Array<{ asset_type: string, id: number, make: string, model: string, name: string, vin: string, year: number, subscriptions: object }>,
        total_count: number
    }
    unassigned_assets: {
        paged_data: Array<{ asset_type: string, id: number, make: string, model: string, name: string, vin: string, year: number }>,
        total_count: number
    }
}

export interface IAssetGroupsResponse {
    paged_data: Array<IAssetGroup>
    total_count: number,
    assets_count_without_groups: number
}

export enum AssetGroupsTypes {
    LOADING_ASSET_GROUPS = "LOADING_ASSET_GROUPS",
    SET_ASSET_GROUPS = "SET_ASSET_GROUPS_DATA",
    SET_ASSET_GROUPS_STATUS = "SET_ASSET_GROUPS_STATUS",
    SET_ASSET_GROUPS_OFFSET = "SET_ASSET_GROUPS_OFFSET",
    SET_ASSET_GROUPS_LIMIT = "SET_ASSET_GROUPS_LIMIT",

    LOADING_ASSET_GROUPS_DETAILS = "LOADING_ASSET_GROUPS_DETAILS",
    SET_ASSET_GROUPS_DETAILS = "SET_ASSET_GROUPS_DETAILS",
    SET_ASSET_GROUPS_DETAILS_STATUS = "SET_ASSET_GROUPS_DETAILS_STATUS",

    SET_ASSET_GROUPS_LIMITED_DATA = "SET_ASSET_GROUPS_LIMITED_DATA",
    SET_ASSETS_GROUPS_DETAILS_OFFSET = "SET_ASSETS_GROUPS_DETAILS_OFFSET",
    SET_ASSETS_GROUPS_DETAILS_SORT_OPTIONS = "SET_ASSETS_GROUPS_DETAILS_SORT_OPTIONS",
    SET_ASSETS_GROUPS_DETAILS_LIMIT = "SET_ASSET_GROUPS_DETAILS_LIMIT"
}

export interface AssetGroupsReducer {
    assetGroups: {
        isLoading: boolean,
        data: Array<IAssetGroup> | null,
        status: IStatus,
        limit: number,
        offset: number,
        totalCount: number,
        limitedData: Array<IAssetGroup>|[],
        unassignedAssetsCount: number
    },
    assetGroupsDetails: {
        isLoading: boolean
        data: IAssetGroupDetails,
        limit: number,
        offset: number,
        status: IStatus,
        sortOptions: IDetailsSort
    }
}

interface ISetAssetGroups { type: AssetGroupsTypes.SET_ASSET_GROUPS, payload: IAssetGroupsResponse }
interface ISetAssetGroupsStatus { type: AssetGroupsTypes.SET_ASSET_GROUPS_STATUS, payload: IStatus }
interface ISetAssetGroupsLoading { type: AssetGroupsTypes.LOADING_ASSET_GROUPS, payload: boolean }
interface ISetAssetGroupsOffset { type: AssetGroupsTypes.SET_ASSET_GROUPS_OFFSET, payload: number }
interface ISetAssetGroupsLimit { type: AssetGroupsTypes.SET_ASSET_GROUPS_LIMIT, payload: number }

interface ISetAssetGroupsDetailsLoading { type: AssetGroupsTypes.LOADING_ASSET_GROUPS_DETAILS, payload: boolean}
interface ISetAssetGroupsDetails{ type: AssetGroupsTypes.SET_ASSET_GROUPS_DETAILS, payload: IAssetGroupDetails}
interface ISetAssetGroupsDetailsStatus{ type: AssetGroupsTypes.SET_ASSET_GROUPS_DETAILS_STATUS, payload: IStatus}
interface ISetAssetGroupsDetailsOffset{ type: AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_OFFSET, payload: number}
interface ISetAssetGroupsDetailsLimit { type: AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_LIMIT, payload: number }

interface ISetAssetGroupsLimitedData{ type: AssetGroupsTypes.SET_ASSET_GROUPS_LIMITED_DATA, payload: Array<IAssetGroup>}

export type AssetGroupsActions = ISetAssetGroups | ISetAssetGroupsStatus | ISetAssetGroupsLoading | ISetAssetGroupsOffset | ISetAssetGroupsLimit | ISetAssetGroupsDetailsLoading | ISetAssetGroupsDetails | ISetAssetGroupsDetailsStatus | ISetAssetGroupsLimitedData | ISetAssetGroupsDetailsOffset | ISetAssetGroupsDetailsLimit
