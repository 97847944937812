import React from 'react'
import { CSVLink } from 'react-csv'

type Props = {
  data: Array<any>
  filename: string
  toggle: () => void
  disabled?: boolean
}

const CsvButton: React.FC<Props> = ({ data, filename, toggle, disabled }) => {
  return (
    <CSVLink
      data={data}
      filename={`${filename}.csv`}
      onClick={toggle}
      target='_blank'
      className={`dropdown-item ${disabled ? 'disabled' : ''}`}
      style={{ pointerEvents: disabled ? 'none' : undefined }} //disabling anchor element action
    >
      CSV
    </CSVLink>
  )
}

export default CsvButton
