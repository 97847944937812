import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from 'reactstrap'

import Th from '../../components/RivataTable/Th'
import WarningRow from './WarningRow'

import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import DraggableTh from '../../components/RivataTable/DraggableTh'
import { reorderTableColumns } from '../../utils/utils'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/localStorageUtils'
const ColumnIds = {
  CHECK: 'CHECK',
  TIME: 'TIME',
  LOCATION: 'LOCATION',
  TYPE: 'TYPE',
  DESCRIPTION: 'DESCRIPTION',
  SENSORTYPE: 'SENSORTYPE',
  ACTIONS_REQUIRED: 'ACTIONS_REQUIRED',
}

interface Props {
  locale: ILocale
  isSuperAdmin: boolean
  isAdmin: boolean
  vin: string
  days: number
  timezone: string
  unitsOfMeasurementConfig: any
  healthColors: any
  data: Array<IAssetWarning>
  checked: Array<string>
  setChecked: React.Dispatch<React.SetStateAction<string[]>>
  userName: string
}

const columnsOrderKey = 'recentWarnings.columns.order'

const WarningsGrid: React.FC<Props> = ({
  locale,
  isSuperAdmin,
  isAdmin,
  vin,
  days,
  timezone,
  unitsOfMeasurementConfig,
  healthColors,
  data,
  checked,
  setChecked,
  userName,
}) => {
  const handleSelectAll = useCallback(() => {
    setChecked((prev) => {
      if (prev.length === data.length) return []

      return data.map((el) => el.key)
    })
  }, [data, setChecked])

  const columns = useMemo(() => {
    return [
      {
        id: ColumnIds.ACTIONS_REQUIRED,
        label: '',
        isDraggable: false,
        customInlineStyle: { maxWidth: '17px', width: '17px' },
      },
      {
        id: ColumnIds.SENSORTYPE,
        label: locale['SensorType'],
        isDraggable: true,
      },
      { id: ColumnIds.TIME, label: locale['Time'], isDraggable: true },
      { id: ColumnIds.LOCATION, label: locale['Position'], isDraggable: true },
      { id: ColumnIds.TYPE, label: locale['WarningType'], isDraggable: true },
      {
        id: ColumnIds.DESCRIPTION,
        label: locale['Description'],
        isDraggable: true,
      },
    ]
  }, [locale])

  const [columnsToShow, setColumnsToShow] = useState(columns)

  useEffect(() => {
    let localSortedColumns = getLocalStorageItem(
      `${columnsOrderKey}.${userName}`,
    )?.split(',')
    if (!localSortedColumns) {
      localSortedColumns = localStorage.getItem(columnsOrderKey)?.split(',')
      if (localSortedColumns) {
        setLocalStorageItem(
          `${columnsOrderKey}.${userName}`,
          localSortedColumns.join(','),
        )
        removeLocalStorageItem(columnsOrderKey)
      }
    }
    if (localSortedColumns) {
      const colsToShow: any[] = []
      if (columns.length === localStorage.length)
        localSortedColumns?.forEach((col: any) => {
          const warningColumn = columns.find((c) => c.id === col)
          if (warningColumn) colsToShow.push(warningColumn)
        })
      else colsToShow.push(...columns)
      setColumnsToShow(colsToShow)
    }
  }, [columns, userName])
  const setColumnsOrder = useCallback(
    (data: any[]) => {
      if (data && data.length > 0) {
        const columnsOrder = data.map((d: any) => {
          return d.id
        })
        setLocalStorageItem(
          `${columnsOrderKey}.${userName}`,
          columnsOrder.join(','),
        )
        setColumnsToShow(data)
      }
    },
    [userName],
  )
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorderTableColumns(
      columnsToShow,
      result.source.index,
      result.destination.index,
    )
    setColumnsOrder(newItems)
  }
  return (
    <Table hover size='sm' responsive>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' direction='horizontal'>
          {(droppableProvided, droppableSnapshot) => (
            <thead>
              <tr ref={droppableProvided.innerRef}>
                {isAdmin && (
                  // @ts-ignore component expect undefined cause of default value
                  <Th
                    id={ColumnIds.CHECK}
                    onCheck={handleSelectAll}
                    label={locale['All'] || 'All'}
                    control='checkbox'
                    checked={checked.length === data.length}
                    customInlineStyle={{ maxWidth: '50px', width: '50px' }}
                  />
                )}

                {columnsToShow.map((col: any, index: number) =>
                  col.isDraggable ? ( // @ts-ignore component expect undefined cause of default value
                    <DraggableTh
                      index={index}
                      key={col.id}
                      id={col.id}
                      label={col.label}
                      customInlineStyle={col.customInlineStyle}
                    />
                  ) : (
                    // @ts-ignore component expect undefined cause of default value
                    <Th
                      key={col.id}
                      id={col.id}
                      label={col.label}
                      customInlineStyle={col.customInlineStyle}
                    />
                  ),
                )}
                {droppableProvided.placeholder}
              </tr>
            </thead>
          )}
        </Droppable>
      </DragDropContext>
      <tbody>
        {data.map((datum, i) => {
          return (
            <WarningRow
              vin={vin}
              isAdmin={isAdmin}
              isSuperAdmin={isSuperAdmin}
              days={days}
              timezone={timezone}
              unitsOfMeasurementConfig={unitsOfMeasurementConfig}
              onCheckRowItem={() => {
                const { key } = datum
                let array = [...checked]

                const idx = array.findIndex((el) => el === key)

                if (idx > -1) {
                  array.splice(idx, 1)
                } else {
                  array.push(key)
                }

                setChecked(array)
              }}
              {...datum}
              rowId={datum.key}
              isChecked={checked.includes(datum.key)}
              locale={locale}
              healthColors={healthColors}
              columns={columnsToShow}
            />
          )
        })}
      </tbody>
    </Table>
  )
}

export default WarningsGrid
