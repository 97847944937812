import { fetchPresignedUrl } from "../base"
import appConfig from "../../../config/appConfig"
import OAuth from 'oauth-1.0a';
import crypto from 'crypto';
import { getCurrentEpoch } from "../../../utils";

class HereMapsApi {
  access_token = ""
  constructor() {
    this.getAccessToken()
  }

  getAccessToken = async () => {
    const hereMapsCachedOauthToken = localStorage.getItem('here_maps_oauth_data');
    if (hereMapsCachedOauthToken) {
      const jsonItem = JSON.parse(hereMapsCachedOauthToken)
      if (jsonItem.saved_at_epoch >= getCurrentEpoch() - jsonItem.expires_in) {
        this.access_token = jsonItem.access_token;
        return
      }
    }

    const oauth = new OAuth({
      consumer: {
        key: appConfig.hereMapsOauthKey,
        secret: appConfig.hereMapsOauthSecret
      },
      signature_method: 'HMAC-SHA256',
      hash_function(baseString, key) {
        return crypto.createHmac("sha256", key).update(baseString).digest("base64")
      }
    });

    const requestData = {
      url: appConfig.hereMapsOauthUrl,
      method: "POST",
      data: {grant_type: "client_credentials"}
    }

    const response = await fetch(requestData.url, {
      method: requestData.method,
      body: new URLSearchParams("grant_type=client_credentials"),
      headers: {"content-type": "application/x-www-form-urlencoded", ...oauth.toHeader(oauth.authorize(requestData))}
    })

    const result = await response.json();

    this.access_token = `Bearer ${result.access_token}`;

    localStorage.setItem('here_maps_oauth_data', JSON.stringify({
      "access_token": this.access_token,
      "expires_in": result.expires_in,
      "saved_at_epoch": getCurrentEpoch()
    }));
  }

  getSuggestions =async (address: string) => {
    return fetchPresignedUrl(`https://autocomplete.search.hereapi.com/v1/autocomplete?q=${address}`,'GET', this.access_token);
  }
  getAddressDetails = (id: string) => {
    return fetchPresignedUrl(`https://lookup.search.hereapi.com/v1/lookup?id=${id}`,'GET', this.access_token);
  }
}

const hereMapsApi = new HereMapsApi()

export default hereMapsApi
