import baseApi from "./BaseApi"

class GeofenceGroupsApi {
  getGeofenceGroups = (
    limit = 30,
    offset = 0,
    sortColumn?: string | null,
    sortDirection?: string | null
  ) => {
    const params = new URLSearchParams()

    if (typeof limit === "number") {
      params.append("limit", limit.toString())
    }

    if (typeof offset === "number") {
      params.append("offset", offset.toString())
    }

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }

    const queryString = params.toString()

    return baseApi.get(`rivata/geofence_group?${queryString}`)
  }

  createGeofenceGroup = (
    name: string,
    customer_id: number
  ) => {
    return baseApi.post(`rivata/geofence_group`, { name, customer_id })
  }

  updateGeofenceGroup = (
    name: string,
    id: number
  ) => {
    return baseApi.put(`rivata/geofence_group?id=${id}`, { name })
  }

  deleteGeofenceGroup = (
    id: number
  ) => {
    return baseApi.delete(`rivata/geofence_group?id=${id}`)
  }

  getGeofenceGroupById = (
    id: number,
    limit: number,
    offset: number,
    sortColumn?: string,
    sortDirection?: string,
    filters?: string,
  ) => {
    const params = new URLSearchParams()

    params.append("id", id.toString())

    if (typeof limit === "number") {
      params.append("limit", limit.toString())
    }

    if (typeof offset === "number") {
      params.append("offset", offset.toString())
    }

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }

    const queryString = params.toString()

    return baseApi.get(`rivata/geofence_group?${queryString}${filters ? "&" + filters : ""}`)
  }

  getGeofenceGroupsLimitedData = (
    sortColumn?: string | null,
    sortDirection?: string | null,
    groupIds?: string | null
  ) => {
    const params = new URLSearchParams()

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }

    params.append("get_limited_data", "true")
    if (groupIds)
      params.append("group_ids", groupIds)
    if (baseApi.customerIdsList) {
      params.append("customer_ids", baseApi.customerIdsList)
    }
    const queryString = params.toString()

    return baseApi.get(`rivata/geofence_group?${queryString}`)
  }

  getGeofenceGroupsLimitedDataForCustomers = (
    customerIds: string,
    sortColumn?: string | null,
    sortDirection?: string | null
  ) => {
    const params = new URLSearchParams()

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }
    params.append("get_limited_data", "true")
    if (customerIds && customerIds.split(",").length > 0) {
      params.append("customer_ids", customerIds)
    }
    const queryString = params.toString()

    return baseApi.get(`rivata/geofence_group?${queryString}`, false)
  }

  manageGeofenceGroupGeofences = (
    groupId: number,
    geofenceIds: Array<number>,
    action: string
  ) => {
    return baseApi.post("rivata/geofence_group/manage", { id: groupId, geofences_ids: geofenceIds, action })
  }
}

const geofenceGroupsApi = new GeofenceGroupsApi()

export default geofenceGroupsApi
