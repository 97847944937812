import { useEffect, useState } from 'react'
import { ReportEndpoints, ReportTypes, TagReportTypes } from '../../enums'
import { dateToEpoch } from '../../utils'
import { getStrFromArray } from './utils'

export const useReportLink = (
  selectedReport: string,
  dateFrom: Date,
  dateTo: Date,
  timeFrom: string | null,
  timeTo: string | null,
  vinList: Array<string>,
  macList: Array<string>,
  usernames: Array<string>,
  selectedGeofences: Array<string>,
  assetType: string,
  notificationTypes: {
    email: boolean
    sms: boolean
    push: boolean
  },
  isAll: boolean,
  location: string,
  lostTagDays: number,
  warningType: string,
  application: string,
  assetGroups: Array<string>,
  days: number,
  warningStatus: string,
  dwellDays: number,
  reportTypeFilter: string,
  geofenceGroups: Array<string>,
  showUnassignedTagsOnly: boolean,
) => {
  const [reportLink, setReportLink] = useState('')

  useEffect(() => {
    const { startDate, endDate } = dateToEpoch(
      dateFrom,
      dateTo,
      timeFrom ?? '00:00',
      timeTo ?? '23:59:59',
    )
    //@ts-ignore
    const reportType = ReportEndpoints[selectedReport]
    let link = `?type=${reportType}`
    let vinsStr = getStrFromArray(vinList, ':', 'all')
    let macsStr = getStrFromArray(macList, ':', 'all')
    let usernamesStr = getStrFromArray(usernames, ':', 'all')
    let selectedGeofencesStr = getStrFromArray(selectedGeofences, ':', 'all')
    let selectedAssetGroupsStr = getStrFromArray(assetGroups, ',', 'all')
    let selectedGeofenceGroupsStr = getStrFromArray(geofenceGroups, ',', 'all')

    let notificationTypesStr =
      notificationTypes.email && notificationTypes.sms && notificationTypes.push
        ? 'all'
        : (
            (notificationTypes.email ? 'EMAIL ' : '') +
            (notificationTypes.sms ? 'SMS ' : '') +
            (notificationTypes.push ? 'PUSH' : '')
          )
            .trim()
            .replaceAll(' ', ':')
    if (notificationTypesStr.length === 0) notificationTypesStr = 'all'

    switch (selectedReport) {
      case ReportTypes.USER_LOGINS_REPORT:
        link += `&application=${application}&epoch_from=${startDate}&epoch_to=${endDate}`
        break
      case ReportTypes.HARDWARE_COMMANDS_HISTORY_REPORT:
        link += `&epoch_from=${startDate}&epoch_to=${endDate}`
        break
      case ReportTypes.TPMS_REPORT:
        link += `&vins=${vinsStr}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (warningType && warningType !== 'all') {
          link += `&warning_filter=${warningType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case ReportTypes.DEVICE_HEALTH_REPORT:
        link += `&vins=${vinsStr}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        break
      case ReportTypes.WARNING_HISTORY_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (warningType) {
          link += `&warning_filter=${warningType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case ReportTypes.ODOMETER_OFFSET_SUMMARY_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        break
      case ReportTypes.DAILY_MILEAGE_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        break
      case ReportTypes.DISTANCE_TRAVELED_REPORT:
      case ReportTypes.VEHICLE_LOCATION_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        break
      case ReportTypes.GEOFENCE_HISTORY_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}` /////
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case TagReportTypes.LATEST_TAG_LOCATION_REPORT:
        link += `&macs=${macsStr}`
        if (!isAll) {
          link += `&epoch_from=${startDate}&epoch_to=${endDate}`
        }
        break
      case TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT:
        link += `&macs=${macsStr}`
        break
      case TagReportTypes.TAG_HISTORY_DETAILS_REPORT:
        link += `&macs=${macsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (location && location !== 'all') {
          link += `&location=${location}`
        }
        if (showUnassignedTagsOnly) {
          link+=`&show_unassigned_tags_only=true`
        }
        break
      case ReportTypes.ACTIVE_WARNINGS_REPORT:
        link += `&vins=${vinsStr}`
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (warningType) {
          link += `&warning_filter=${warningType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case ReportTypes.DURATION_IN_WARNING_STATE_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (warningType) {
          link += `&warning_filter=${warningType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        break
      case ReportTypes.ASSET_PROVISIONING_SUMMARY_REPORT:
        link += `&vins=${vinsStr}`
        break
      case ReportTypes.NOTIFICATION_HISTORY_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}&usernames=${usernamesStr}&notification_types=${notificationTypesStr}`
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        break
      case ReportTypes.FAILED_NOTIFICATION_HISTORY_REPORT:
        link += `&usernames=${usernamesStr}&days=${days}`
        break
      case ReportTypes.DWELL_REPORT:
        link += `&vins=${vinsStr}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (dwellDays) {
          link += `&dwell_days=${dwellDays}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case ReportTypes.ASSETS_IN_GEOFENCE_REPORT:
        link += `&vins=${vinsStr}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case TagReportTypes.LOST_TAGS_REPORT:
        link += `&lost_tag_days=${lostTagDays}`
        break
      case TagReportTypes.CARGO_TAG_GATEWAY_REPORT:
        if (macsStr && macsStr !== 'all') {
          link += `&macs=${macsStr}`
        }
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        break
      case ReportTypes.DRIVER_APP_TRIP_REPORT:
        link += `&epoch_from=${startDate}&epoch_to=${endDate}`
        link += `&vins=${vinsStr}`
        link += `&usernames=${usernamesStr}`
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        break
      case ReportTypes.UTILIZATION_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        break
      case ReportTypes.NON_REPORTING_SENSORS_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (selectedGeofencesStr && selectedGeofencesStr !== 'all') {
          link += `&selected_geofences=${selectedGeofencesStr}`
        }
        if (warningType) {
          link += `&warning_filter=${warningType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (warningStatus && warningStatus !== 'all') {
          link += `&warning_status_filter=${warningStatus}`
        }
        if (selectedGeofenceGroupsStr && selectedGeofenceGroupsStr !== 'all') {
          link += `&geofence_group=${selectedGeofenceGroupsStr}`
        }
        break
      case ReportTypes.MILES_IN_WARNING_STATE_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`
        if (assetType && assetType !== 'all') {
          link += `&asset_type=${assetType}`
        }
        if (selectedAssetGroupsStr && selectedAssetGroupsStr !== 'all') {
          link += `&asset_group=${selectedAssetGroupsStr}`
        }
        if (warningType) {
          link += `&warning_filter=${warningType}`
        }
        break
      case ReportTypes.SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT:
        if (usernames.length) {
          link += `&usernames=${usernamesStr}`
        }
        if (reportTypeFilter) {
          link += `&report_type_filter=${reportTypeFilter}`
        }
        break
      case ReportTypes.NOTIFICATIONS_SUBSCRIPTIONS_REPORT:
        link += `&usernames=${usernamesStr}&notification_types=${notificationTypesStr.toLowerCase()}`
        if (warningType && warningType !== 'all') {
          link += `&warning_filter=${warningType}`
        }
        break
      default:
        break
    }

    setReportLink(link)
  }, [
    selectedReport,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    vinList,
    macList,
    usernames,
    selectedGeofences,
    assetType,
    isAll,
    location,
    notificationTypes,
    lostTagDays,
    warningType,
    application,
    assetGroups,
    days,
    warningStatus,
    dwellDays,
    reportTypeFilter,
    geofenceGroups,
    showUnassignedTagsOnly,
  ])

  return reportLink
}
