import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'

import configurationApi from '../../services/api/ApiGroups/Configuration'

import RivataModule from '../../components/RivataModule'
import SubNav from '../../components/SubNav'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import RivataLoader from '../../components/RivataLoader'
import ConfirmModal from '../../components/ConfirmModal'
import { getStatusObj } from '../../utils/utils'
import StatusAlert from '../../components/StatusAlert'
import ReactJSONEditor from '../../components/ReactJSONEditor'
import { Content, OnChangeStatus, toJSONContent } from 'vanilla-jsoneditor'

const Admin: React.FC = () => {
  const locale = useTypedSelector((state) => state.whitelabel.locale)

  const [jsonEditorContent, setJsonEditorContent] = useState<Content>({
    text: '',
  })
  const [jsonError, setJsonError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<IStatus | null>(null)
  const [config, setConfig] = useState<IDataProcessingConfig | null>(null)
  const [updateBody, setUpdateBody] = useState<IDataProcessingConfig | null>(
    null,
  )

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'prod') return

    const fetchConfig = async () => {
      try {
        setIsLoading(true)
        const res = await configurationApi.getDataProcessingConfig()

        setConfig(res)
        setJsonEditorContent({ json: res })
      } catch (err) {
        setStatus(getStatusObj(err))

        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchConfig()
  }, [])

  const updateConfig = useCallback(async () => {
    if (!updateBody) return

    try {
      setIsLoading(true)

      const res = await configurationApi.putDataProcessingConfig(updateBody)

      const status = getStatusObj()
      status.message = 'Update success'

      setStatus(status)
      setConfig(res)
      setJsonEditorContent({ json: res })
    } catch (err) {
      //@ts-ignore error always has a message
      setStatus(getStatusObj(err, err.message))

      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }, [updateBody])

  const breadcrumbs = useMemo(() => {
    return [{ label: 'Home', path: '/dashboard' }, { label: 'Admin Page' }]
  }, [])

  const onJsonEditorChange = useCallback(
    (content: Content, pc: Content, status: OnChangeStatus) => {
      setJsonEditorContent(content)
      setJsonError(!!status.contentErrors)
      if (!status.contentErrors) {
        setUpdateBody(toJSONContent(content).json as IDataProcessingConfig)
      }
    },
    [],
  )

  if (process.env.REACT_APP_STAGE === 'prod') {
    return <Redirect to='/' />
  }

  return (
    <>
      <SubNav title='Admin Page' breadcrumbs={breadcrumbs} />

      <RivataModule
        title='Data Processing Configuration'
        locale={locale}
        filters={null}
      >
        <>
          {isLoading && <RivataLoader />}

          {status && (
            <ConfirmModal
              header='Status'
              open={true}
              onClose={() => setStatus(null)}
            >
              <StatusAlert
                color={status.ok ? 'success' : 'danger'}
                customText={status.message}
                statusCode={undefined}
                statusText={undefined}
              />
            </ConfirmModal>
          )}

          <div>
            <h3 className='mt-3 mb-3'>Update Config</h3>

            <ReactJSONEditor
              content={jsonEditorContent}
              onChange={onJsonEditorChange}
            />

            <div className='do-provision__button'>
              <Button
                onClick={updateConfig}
                color='success'
                className='mt-3'
                disabled={jsonError || !updateBody}
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      </RivataModule>
    </>
  )
}

export default Admin
