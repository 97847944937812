import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import RivataDropdown from '../../components/RivataDropdown'
import LineChart from '../../components/RivataLineChart'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'
import { AssetDetailsContext } from '../../pages/AssetDetails'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { composeCustomTooltip } from '../../utils/chartUtils'
import { composeChartData } from './utils'

const tooltip = composeCustomTooltip({
  displayLabelColors: true,
  footer: true,
})

interface Props {
  width?: number
}

const PowerInputsChart: React.FC<Props> = ({ width }) => {
  const {
    timezone,
    powerInputs: { isLoading, data, status },
  } = useTypedSelector((state) => ({
    powerInputs: state.assetDetails.powerInputs,
    timezone: state.auth.preferences.timezone,
  }))
  const { fetchAssetDetailsPowerInputs } = useActions()

  const { locale, assetInfoData } = useContext(AssetDetailsContext)

  const [selectedDataset, setSelectedDataset] = useState('all')
  const [chartData, setChartData] = useState<any>(null)
  const [selectedGateway, setSelectedGateway] = useState<string | null>(null)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  useEffect(() => {
    if (!data) return

    setChartData(composeChartData(data))
  }, [data])

  useEffect(() => {
    if (!assetInfoData?.gateways) return

    const mainGW = assetInfoData.gateways.find((el) => el.is_main)

    if (mainGW) setSelectedGateway(mainGW.mac)
  }, [assetInfoData])

  const datasetsWithFIlter: any = useMemo(() => {
    if (!chartData) return {}

    const datasets = chartData.datasets

    if (selectedDataset === 'all' && datasets.length) return { datasets }

    const elem = datasets.find((el: any) => el.id === selectedDataset)
    if (elem) {
      return {
        datasets: [elem],
      }
    }

    return null
  }, [chartData, selectedDataset])

  useEffect(() => {
    if (!isCollapsed && selectedGateway)
      fetchAssetDetailsPowerInputs(selectedGateway)
  }, [isCollapsed, selectedGateway, fetchAssetDetailsPowerInputs])

  const gatewaysItems: any = useMemo(() => {
    if (!assetInfoData?.gateways) return []

    return assetInfoData.gateways.map((g) => ({ id: g.mac, label: g.mac }))
  }, [assetInfoData])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title={
        selectedGateway
          ? `Power / Inputs (${selectedGateway})`
          : 'Power / Inputs'
      }
      width={width}
      locale={locale}
      error={status}
      collapsible={true}
      defaultCollapsed={true}
      onCollapse={(isCollapsed: boolean) => setIsCollapsed(isCollapsed)}
      filters={
        <>
          {assetInfoData?.gateways &&
            assetInfoData.gateways.length > 1 &&
            !isCollapsed && (
              <RivataDropdown
                caret={true}
                items={gatewaysItems}
                selected={selectedGateway}
                onSelect={setSelectedGateway}
                disabled={false}
                buttonLabel={null}
                onRemove={null}
                locale={locale}
                color={'secondary'}
                filters={null}
              />
            )}
          {chartData && chartData.dropdownItems.length > 1 && (
            <RivataDropdown
              caret={true}
              items={chartData.dropdownItems}
              selected={selectedDataset}
              onSelect={setSelectedDataset}
              disabled={false}
              buttonLabel={null}
              onRemove={null}
              locale={locale}
              color={'secondary'}
              filters={null}
            />
          )}
        </>
      }
    >
      {isLoading ? (
        <RivataLoader />
      ) : chartData && datasetsWithFIlter ? (
        <LineChart
          id={'power-input'}
          data={datasetsWithFIlter}
          yAxisLabel={'Voltage'}
          height={400}
          xAxisLabel={`Date (${moment().tz(timezone).format('z')})`}
          displayX={true}
          tooltip={tooltip}
          isSync={false}
        />
      ) : (
        <StatusAlert
          customText='No Data'
          color='success'
          statusCode={null}
          statusText={null}
        />
      )}
    </RivataModule>
  )
}

export default PowerInputsChart
