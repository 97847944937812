import { SetLoading } from "../types"



export enum SettingsActionTypes {
    LOADING                     = "LOADING_SETTINGS",
    SET_NOTIFICATIONS_PROFILES  = "SET_SETTINGS_NOTIFICATIONS_PROFILES"
}


export interface SettingsReducer {
    isLoading: boolean
    notificationsProfiles: Array<INotificationProfile>
}


type LoadingManageNotifications = SetLoading<SettingsActionTypes.LOADING>

interface SetSettingsNotificationsProfiles {
    type: SettingsActionTypes.SET_NOTIFICATIONS_PROFILES
    payload: Array<INotificationProfile>
}


export type SettingsActions = LoadingManageNotifications | SetSettingsNotificationsProfiles