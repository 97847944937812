import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const SendActionableItemNotificationEmailButton = (props: any) => {
  const { disabled, btnClassName, onResendEmail, locale = {} } = props

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <div>
      <Button
        disabled={disabled}
        size='md'
        className={btnClassName}
        onClick={toggle}
      >
        Send Email Notification
      </Button>
      <Modal isOpen={modal && !disabled}>
        <ModalHeader toggle={toggle}>Send Email Notification</ModalHeader>
        <ModalBody>Resend email notifications for selected warnig(s)?</ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              onResendEmail()
              toggle()
            }}
          >
            {locale['ok'] || 'Ok'}
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
            {locale['cancel'] || 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default SendActionableItemNotificationEmailButton
