import React, { ChangeEvent, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap'
import InfoModal from '../../../components/InfoModal'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
  AssetSubtypeTractor,
  AssetSubtypeTrailer,
  AssetTypes,
} from '../../../enums'
import { isInteger } from 'lodash'
import './styles.scss'
import api from '../../../services/api'
import { useActions } from '../../../hooks/useActions'
const defaultUploadStatus = {
  success: false,
  message: '',
}
interface ValidationResultRow {
  rowNumber: number
  vin: string | undefined
  message: string
}
interface ValidationResult {
  valid: boolean
  details: Array<ValidationResultRow>
}
const defaultValidationResult: ValidationResult = {
  valid: true,
  details: [],
}
const assetTypes = Object.values(AssetTypes).map((v: string) => {
  return v
})
const trailerSubTypes = Object.values(AssetSubtypeTrailer).map((v: string) => {
  return v
})
const tractorSubTypes = Object.values(AssetSubtypeTractor).map((v: string) => {
  return v
})
const AssetsBulkUpload = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const [validationResult, setValidationResult] = useState<ValidationResult>(
    defaultValidationResult,
  )
  const [uploadStatus, setUploadStatus] = useState(defaultUploadStatus)
  const toggleModalVisibility = () => {
    setModalVisible(!modalVisible)
    setUploadStatus(defaultUploadStatus)
    setIsLoading(false)
    setValidationResult(defaultValidationResult)
  }

  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))

  const { postAssetsCsv } = useActions()
  const onFileChange = (event: ChangeEvent) => {
    if (event.target) {
      const target = event.target as HTMLInputElement
      if (target) {
        const csvFile = target.files?.[0]
        if (csvFile) {
          setFile(csvFile)
          const reader = new FileReader()

          reader.readAsText(csvFile)
          reader.onload = async function () {
            const result = reader.result
            if (result) {
              setValidationResult({ valid: true, details: [] })
              validateCsv(result.toString())
            }
          }
          reader.onerror = function (error) {
            console.log('Error: ', error)
          }
        }
      }
    }
  }
  const validateCsv = async (csvText: string) => {
    const rows: Array<string> = csvText.split('\r\n')
    const validationDetails: Array<ValidationResultRow> = []
    const vins: Array<ValidationResultRow> = []
    const maxYear = new Date().getFullYear() + 2
    rows.forEach((row: string, index: number) => {
      if (row && row.length > 0) {
        const columns = row.split(',')
        const columnsCount: number = columns.length
        const vin: string | undefined =
          columnsCount > 2 ? columns[1].trim() : undefined
        const rowNumber: number = index + 1
        if (columnsCount < 9) {
          validationDetails.push({
            rowNumber: index + 1,
            vin: vin,
            message: 'Not all properties provided',
          })
          return
        }
        if (columns[0].toLowerCase() === 'name (required)') {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'Please remove header row',
          })
          return
        }
        const name = columns[0]
        const assetType = columns[2]
        const assetSubType = columns[3]
        const year = columns[4]
        const make = columns[5]
        const model = columns[6]
        const numberOfAxles = columns[7]
        const atiInstalled = columns[8]
        if (!vin) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'VIN number is required',
          })
        } else {
          validateLength(vin, vin, rowNumber, 'VIN number', validationDetails)
          const existingVin = vins.find((vinItem) => {
            return vinItem.vin === vin
          })
          if (existingVin) {
            validationDetails.push({
              rowNumber: rowNumber,
              vin: vin,
              message: `Asset with vin ${vin} is already added in the file`,
            })
          } else {
            vins.push({ vin: vin, rowNumber: rowNumber, message: '' })
          }
        }
        if (!name) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'Name is required',
          })
        } else {
          validateLength(name, vin, rowNumber, 'Name', validationDetails)
        }
        if (!assetType) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'Asset type is required',
          })
        } else if (!assetTypes.includes(assetType.toLowerCase())) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: `Asset type is invalid. Allowed values: ${assetTypes.join(
              ', ',
            )}`,
          })
        }
        if (!assetSubType) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'Asset subtype is required',
          })
        } else if (
          assetType.toLowerCase() === AssetTypes.TRACTOR &&
          !tractorSubTypes.includes(assetSubType.toLowerCase())
        ) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: `Asset subtype is invalid. Allowed values: ${tractorSubTypes.join(
              ', ',
            )}`,
          })
        } else if (
          assetType.toLowerCase() === AssetTypes.TRAILER &&
          !trailerSubTypes.includes(assetSubType.toLowerCase())
        ) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: `Asset subtype is invalid. Allowed values: ${trailerSubTypes.join(
              ', ',
            )}`,
          })
        }
        if (year && !isInteger(parseInt(year))) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'Year is not valid integer',
          })
        } else {
          if (
            year &&
            !(
              parseInt(year) >= 1900 &&
              parseInt(year) <= new Date().getFullYear() + 2
            )
          ) {
            validationDetails.push({
              rowNumber: rowNumber,
              vin: vin,
              message: `Year should be between 1900 and ${maxYear}`,
            })
          }
        }
        if (
          !numberOfAxles ||
          !isInteger(parseInt(numberOfAxles)) ||
          parseInt(numberOfAxles) < 1 ||
          parseInt(numberOfAxles) > 5
        ) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'Number of Axles should be between 1 and 5',
          })
        }
        if (!atiInstalled || ![0, 1, 2].includes(parseInt(atiInstalled))) {
          validationDetails.push({
            rowNumber: rowNumber,
            vin: vin,
            message: 'ATI Installed value should be 0, 1 or 2',
          })
        }
        validateLength(make, vin, rowNumber, 'Make', validationDetails)
        validateLength(model, vin, rowNumber, 'Model', validationDetails)
      }
    })
    const vinsInSystem: Array<string> = await api.checkAssetsExistsByVins({
      vins: vins.map((v) => {
        return v.vin
      }),
    })
    vins.forEach((vinItem: ValidationResultRow) => {
      if (vinItem.vin && vinsInSystem.includes(vinItem.vin.toUpperCase())) {
        validationDetails.push({
          rowNumber: vinItem.rowNumber,
          vin: vinItem.vin,
          message: 'Asset with this vin already exist in our system',
        })
      }
    })
    setValidationResult({
      valid: validationDetails.length === 0,
      details: validationDetails.sort((a, b) => a.rowNumber - b.rowNumber),
    })
  }
  const validateLength = (
    value: string,
    vin: string | undefined,
    index: number,
    label: string,
    results: Array<ValidationResultRow>,
  ) => {
    if (value && value.length > 100) {
      results.push({
        rowNumber: index,
        vin: vin,
        message: `${label} can't be longer than 100 characters`,
      })
    }
  }
  const onUpload = async () => {
    if (!file) return

    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = async function () {
      const result = reader.result?.toString().split('base64,')[1]
      if (result) {
        setIsLoading(true)
        const response: any = await postAssetsCsv(result, file.name)

        setIsLoading(false)

        const successful = response.statusCode === 200

        setUploadStatus({
          success: successful,
          message: response.message,
        })

        if (successful) {
          setModalVisible(false)
        }
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const downloadExample = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    let csvData = [
      [
        'Name (required)',
        'VIN (required)',
        'Asset Type (required)',
        'Asset Subtype (required)',
        'Year',
        'Make',
        'Model',
        'Number of Axles (required)',
        'ATI Installed  (required)',
        '<- Note: This row is for reference and should be removed!',
      ],
      [
        'Truck1',
        '3GTP2VE76CG301299',
        'tractor',
        'other_tractor',
        '2020',
        'Ford',
        '',
        '2',
        '0',
      ],
      [
        'Trailer1',
        '2GTP1VE76CG321299',
        'trailer',
        'other_trailer',
        '2023',
        'HYUNDAI TRANSLEAD TRAILERS',
        'Hyundai Translead Trailers',
        '2',
        '1',
      ],
    ]

    csvData.forEach(function (rowArray) {
      let row = rowArray.join(',')
      csvContent += row + '\r\n'
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.querySelector('#assets-example-link') as HTMLElement

    if (!link) {
      link = document.createElement('a')
      link.style.display = 'none'
      link.id = 'assets-example-link'
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'AsssetsExample.csv')
      document.body.appendChild(link)
    }

    link.click()
  }

  return (
    <>
      <Button
        className='mr-2 btn btn-primary'
        onClick={toggleModalVisibility}
        disabled={selectedCustomersList.length !== 1}
      >
        Bulk Upload Assets
      </Button>
      <Modal size='lg' centered isOpen={modalVisible}>
        <ModalHeader toggle={toggleModalVisibility}>
          Bulk Upload Assets
        </ModalHeader>
        <ModalBody>
          {!uploadStatus.success && uploadStatus.message && (
            <>
              <span className='upload_csv_error'>{uploadStatus.message}</span>
            </>
          )}

          <div className='upload_csv'>
            <div>
              <span>Bulk Upload Assets file</span>
              <input type='file' onChange={onFileChange} accept='text/csv' />
            </div>
            <div>
              <div>
                <Button
                  size='md'
                  onClick={downloadExample}
                  className={'ml-1 mt-3'}
                >
                  Download File Example
                </Button>
              </div>
            </div>
            {!validationResult.valid && (
              <div className='bulk-upload-validation-result mt-3'>
                <b>Please fix errors below to continue</b>
                <div className='validation-result-row'>
                  <div className='row-number'>
                    <b>Row#</b>
                  </div>
                  <div className='vin'>
                    <b>VIN</b>
                  </div>
                  <div className='message'>
                    <b>Error</b>
                  </div>
                </div>
                <div className='validation-result-rows-wrapper'>
                  {validationResult.details.map((item: ValidationResultRow) => {
                    return (
                      <div
                        className='validation-result-row'
                        key={`${item.vin}_${item.message.replace(' ', '_')}_${
                          item.rowNumber
                        }`}
                      >
                        <div className='row-number'>{item.rowNumber}</div>
                        <div className='vin'>{item.vin}</div>
                        <div className='message'>{item.message}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              onUpload()
            }}
            disabled={!file || isLoading || !validationResult.valid}
          >
            {isLoading ? <Spinner size='sm' color='light' /> : 'Ok'}
          </Button>

          <Button
            color='danger'
            onClick={toggleModalVisibility}
            disabled={isLoading}
          >
            {'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <InfoModal
        open={uploadStatus.success}
        message={uploadStatus.message}
        header='CSV Upload'
        onConfirm={() => {
          setUploadStatus(defaultUploadStatus)
        }}
      />
    </>
  )
}

export default AssetsBulkUpload
