import baseApi from "./BaseApi"

class HardwareDataApi {
  getVibrations = (assetId: number, window: number, epochFrom: number | null, epochTo?: number | null) => {
    let query = ""

    if (epochFrom && epochTo) {
      query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`
    } else {
      query = `&days=${window}`
    }

    return baseApi.get(`rivata/vibration?asset_id=${assetId}${query}`, false)
  }


  getTirePressureAssets = (
    limit = 30,
    offset = 0,
    type: string,
    filtersQuery?: string,
    sortColumn?: string,
    sortDirection?: string,
    hideAutogeneratedAssets = true,
  ) => {
    const params = new URLSearchParams(filtersQuery)
  
    if (typeof limit === "number") {
      params.append("limit", limit.toString())
    }
  
    if (typeof offset === "number") {
      params.append("offset", offset.toString())
    }
  
    if (typeof type === "string") {
      params.append("filters", `asset_type=${type}`)
    }
  
    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }
  
    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }
    
    if (hideAutogeneratedAssets) {
      params.append("hide_autogenerated", "true")
    }
  
    const queryString = params.toString()
  
    return baseApi.get(`rivata/pressure/latest?${queryString}`)
  }


  getTirePressure = (assetId: number, window: number, epochFrom: number | null, epochTo: number | null, reducePoints: boolean) => {
    let query = ""
    if (epochFrom && epochTo) {
      query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`
    } else {
      query = `&days=${window}`
    }

    if (reducePoints) query += "&reduced_data=true"

    return baseApi.get(`rivata/pressure?asset_id=${assetId}${query}`, false)
  }


  getpowerInput = (assetId: number, esn: string) => {
    return baseApi.get(`rivata/powerinputs?asset_id=${assetId}&esn=${esn}`, false)
  }


  getAxleLoad = (assetId: number, days: number) => {
    return baseApi.get(`rivata/axleload?asset_id=${assetId}&days=${days}`, false)
  }


  getLinePressure = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null) => {
    let query = ""

    if (epochFrom && epochTo) {
      query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`
    }  else {
      query = `&days=${days}`
    }

    return baseApi.get(`rivata/linepressure?asset_id=${assetId}${query}`, false)
  }


  getTemperatures = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null, reducePoints: boolean) => {
    let query = ""

    if (epochFrom && epochTo) {
      query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`
    } else {
      query = `&days=${days}`
    }

    if (reducePoints) query += "&reduced_data=true"

    return baseApi.get(`rivata/temperature?asset_id=${assetId}${query}`, false)
  }


  getSpeedData = (
    assetId: number,
    days: number | null,
    epochFrom: number | null,
    epochTo: number | null,
    calculateMotionTimespans: boolean
  ) => {
    let query = ""
  
    if (epochFrom && epochTo) {
      query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`
    } else {
      query = `&days=${days}`
    }
  
    if (calculateMotionTimespans) {
      query += `&calculate_motion_timespans=true`
    }
  
    return baseApi.get(`rivata/speed?asset_id=${assetId}${query}`, false)
  }
}

const hardwareDataApi = new HardwareDataApi()

export default hardwareDataApi