import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Col } from 'reactstrap'
import SubNav from '../../components/SubNav'
import RivataLoader from '../../components/RivataLoader'
import ModuleOrder from '../../modules/ModuleOrder'
import ColorPicker from '../../modules/ColorPicker'
import FileUploader from '../../modules/FileUploader'
import ResetWhitelabelStyles from '../../modules/ResetWhitelabelStyles'
import {
  fetchWhitelabelAdmin,
  postWhitelabel,
  setWhitelabelAdmin,
  setWhitelabelAdminDirty,
  setSmarthubHealthSvg,
  setTpmsHealthSvg,
  setLinePressureHealthSvg,
  setAxleLoadHealthSvg,
  setGatewayHealthSvg,
  setLogoIconPng,
  setLogoFullPng,
  setFleetSummarySmarthubSvg,
  setFleetSummaryTpmsSvg,
  setFleetSummaryLinePressureSvg,
  setFleetSummaryAxleLoadSvg,
  setFleetSummaryGeofenceInsideSvg,
  setFleetSummaryGeofenceOutsideSvg,
  resetWhitelabelToDefault,
  setWhitelabelAdminError,
  setAuthImagePng,
  setAppSmartHubIconPng,
  setAppAxleLoadIconPng,
  setAppLinePressureIconPng,
  setAppTpmsIconPng,
  setAppLogoBrandDarkPng,
  setAppLogoBrandLightPng,
} from '../../redux/whitelabelAdmin/action'
import { WhiteLabelFilenames, WhiteLabelAppFilenames } from '../../enums'
import CustomerDefaults from '../../modules/CustomerDefaults'
import ErrorJumbo from '../../components/ErrorJumbo'
import CustomerSelector from '../../modules/CustomerSelector'

import InfoModal from '../../components/InfoModal'
import { getStatusObj } from '../../utils/utils'
import FooterSettings from '../../modules/FooterSettings'

import fleetSummarySmarthubIcon from '../../assets/images/icons/svg/fleet_summary_smarthub.svg'
import fleetSummaryTpmsIcon from '../../assets/images/icons/svg/fleet_summary_tpms.svg'
import fleetSummaryLinePressureIcon from '../../assets/images/icons/svg/fleet_summary_line_pressure.svg'
import fleetSummaryAxleLoadIcon from '../../assets/images/icons/svg/fleet_summary_axle_load.svg'
import fleetSummaryGeofenceInsideIcon from '../../assets/images/icons/svg/fleet_summary_geofence_inside.svg'
import fleetSummaryGeofenceOutsideIcon from '../../assets/images/icons/svg/fleet_summary_geofence_outside.svg'

import defaultAppSmartHubIcon from '../../assets/images/app_icons/app_smarthub.png'
import defaultAppLinePressureIcon from '../../assets/images/app_icons/app_line_pressure.png'
import defaultAppAxleLoadIcon from '../../assets/images/app_icons/app_axle_load.png'
import defaultAppTpmsIcon from '../../assets/images/app_icons/app_tpms.png'

import defaultAppBrandLogoLight from '../../assets/images/conmet_brand/app_logo_brand_light.png'
import defaultAppBrandLogoDark from '../../assets/images/conmet_brand/app_logo_brand_dark.png'
const parseWhitelabelAccountName = (name) => {
  const regex = /[^0-9a-zA-Z]+/g
  name = name.trim().toLowerCase()
  name = name.replaceAll(regex, '_')
  if (name.startsWith('_')) {
    name = name.substring(1)
  }
  if (name.endsWith('_')) {
    name = name.substring(0, name.length - 1)
  }

  return name
}

const WhiteLabel = ({
  fetchConfig,
  setConfig,
  postConfig,
  setDirty,
  setSmarthubHealthSvg,
  setTpmsHealthSvg,
  setLinePressureHealthSvg,
  setAxleLoadHealthSvg,
  setGatewayHealthSvg,
  setLogoIconPng,
  setLogoFullPng,
  setFleetSummarySmarthubSvg,
  setFleetSummaryTpmsSvg,
  setFleetSummaryLinePressureSvg,
  setFleetSummaryAxleLoadSvg,
  setFleetSummaryGeofenceInsideSvg,
  setFleetSummaryGeofenceOutsideSvg,
  resetWhitelabelToDefault,
  setWhitelabelAdminError,
  whitelabel: {
    isLoading,
    isPosting,
    dirty,
    postedConfig,
    locale = {},
    config,
    error,
  },
  customers: {
    data,
    selectedCustomersList,
    // error
  },
  setAuthImagePng,
  setAppSmartHubIconPng,
  setAppAxleLoadIconPng,
  setAppLinePressureIconPng,
  setAppTpmsIconPng,
  setAppLogoBrandDarkPng,
  setAppLogoBrandLightPng,
  auth: { isAdmin, isSuperAdmin },
}) => {
  const [isValid, setIsValid] = useState(true)
  const [hideSuperAdminOnlyElements, setHideSuperAdminOnlyElements] = useState(
    isAdmin && !isSuperAdmin,
  )
  const handleUpdate = (newItems) => {
    setConfig(newItems, true)
    setDirty(true)
  }

  const show = !isLoading && config

  const whiteLabel = (
    (selectedCustomersList[0]?.key &&
      parseWhitelabelAccountName(selectedCustomersList[0].key)) ||
    ''
  ).toLowerCase()

  const btnValue = useMemo(() => {
    if (isPosting) return locale.savingEllipsis || 'Saving...'
    else if (postedConfig) return locale.Saved || 'Saved'
    else return locale.Save || 'Save'
  }, [locale, isPosting, postedConfig])

  // hide 'Warnings' module
  const dashInitialItems = useMemo(() => {
    return config.dash.filter((item) => item.id !== 'Warnings')
  }, [config.dash])

  // non reference variable so useEfect could trigger only on customer change
  const selectedCustomerId = useMemo(() => {
    if (selectedCustomersList.length === 1) return selectedCustomersList[0].id
  }, [selectedCustomersList])

  useEffect(() => {
    if (selectedCustomerId) fetchConfig()
  }, [fetchConfig, selectedCustomerId])

  const setValidity = useCallback(
    (emailValid, phoneValid) => {
      setIsValid(emailValid && phoneValid)
    },
    [setIsValid],
  )

  const filteredThemeColors = useMemo(
    () =>
      config.themeColors.filter((col) =>
        ['Primary1', 'Secondary1'].includes(col.id),
      ),
    [config.themeColors],
  )

  return (
    <>
      <InfoModal
        open={error.statusCode === 200}
        locale={locale}
        header='Whitelabel'
        message={error.message}
        onConfirm={() => setWhitelabelAdminError(getStatusObj())}
      />

      {error.statusCode >= 400 ? (
        <ErrorJumbo
          statusCode={error.statusCode >= 400 ? error.statusCode : null}
          statusText={error.message ? error.message : ''}
        />
      ) : (
        <>
          <SubNav
            title='Whitelabel'
            breadcrumbs={[
              { label: 'Home', path: '/dashboard' },
              { label: 'Whitelabel' },
            ]}
          >
            {data.length && (
              <>
                <CustomerSelector />
                <Button
                  color='primary'
                  disabled={
                    !dirty ||
                    isPosting ||
                    selectedCustomersList.length !== 1 ||
                    !isValid
                  }
                  className='ml-3'
                  onClick={async () => {
                    postConfig(selectedCustomersList[0].id)
                    setDirty(false)
                  }}
                >
                  {btnValue}
                </Button>
              </>
            )}
          </SubNav>
          {show ? (
            <>
              <CustomerDefaults
                title={'Defaults Settings'}
                locale={locale}
                width={12}
                preferences={config.preferenceDefaults}
                onUpdate={(preferenceDefaults) =>
                  handleUpdate({ ...config, preferenceDefaults })
                }
                setValidity={setValidity}
                hideSuperAdminOnlyElements={hideSuperAdminOnlyElements}
              />
              <ResetWhitelabelStyles
                onResetClick={resetWhitelabelToDefault}
                hideSuperAdminOnlyElements={hideSuperAdminOnlyElements}
              />
              <Col className='d-flex justify-content-between flex-wrap col-12'>
                <ModuleOrder
                  initialItems={dashInitialItems}
                  width={4}
                  customClass='col-xl-4 col-lg-6 whitelabel-config pl-0'
                  title='Dashboard Page'
                  locale={locale}
                  onUpdate={(dash) => handleUpdate({ ...config, dash })}
                  showDisableCheckbox={!hideSuperAdminOnlyElements}
                />
                <ModuleOrder
                  initialItems={config.details}
                  width={4}
                  customClass='col-xl-4 col-lg-6 whitelabel-config'
                  title='Details Page'
                  locale={locale}
                  onUpdate={(details) => handleUpdate({ ...config, details })}
                  showDisableCheckbox={!hideSuperAdminOnlyElements}
                />
                <ModuleOrder
                  initialItems={config.warnings}
                  width={4}
                  customClass='col-xl-4 col-lg-6 whitelabel-config pr-0'
                  title='Warning Details Page'
                  locale={locale}
                  onUpdate={(warnings) => handleUpdate({ ...config, warnings })}
                  showDisableCheckbox={!hideSuperAdminOnlyElements}
                />
              </Col>
              <FileUploader
                title='Health Icons'
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.gatewayHealthSvg,
                    label: 'Gateway Health Symbol',
                    text: 'GatewayHealthSymbolText',
                    id: 'GatewayHealthSymbol',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setGatewayHealthSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                  },
                  {
                    filename: WhiteLabelFilenames.smarthubHealthSvg,
                    label: 'SmartHub Health Symbol',
                    text: 'SmartHubHealthSymbolText',
                    id: 'SmartHubHealthSymbol',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setSmarthubHealthSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                  },
                  {
                    filename: WhiteLabelFilenames.tpmsHealthSvg,
                    label: 'TPMS Health Symbol',
                    text: 'TpmsHealthSymbolText',
                    id: 'TpmsHealthSymbol',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setTpmsHealthSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                  },
                  {
                    filename: WhiteLabelFilenames.linePressureHealthSvg,
                    label: 'Line Pressure Health Symbol',
                    text: 'LinePressureHealthSymbolText',
                    id: 'LinePressureHealthSymbol',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setLinePressureHealthSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                  },
                  {
                    filename: WhiteLabelFilenames.axleLoadHealthSvg,
                    label: 'Axle Load Health Symbol',
                    text: 'AxleLoadHealthSymbolText',
                    id: 'AxleLoadHealthSymbol',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAxleLoadHealthSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                  },
                ]}
              />
              <FileUploader
                title='Brand Logos'
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.logoFullPng,
                    text: 'LogoFullText',
                    id: 'LogoFull',
                    accept: 'image/png, image/jpeg',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setLogoFullPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                  },
                  {
                    filename: WhiteLabelFilenames.logoIconPng,
                    label: 'Logo Icon',
                    text: 'LogoIconText',
                    id: 'LogoIcon',
                    accept: 'image/png, image/jpeg',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setLogoIconPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                  },
                  {
                    filename: WhiteLabelFilenames.authPageImagePng,
                    label: 'Login Page Image',
                    text: 'AuthPageImageText',
                    id: 'AuthPageImage',
                    accept: 'image/png, image/jpeg',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAuthImagePng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                  },
                ]}
              />
              <FileUploader
                title='Fleet Summary Icons'
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.fleetSummarySmarthubSvg,
                    text: 'FleetSummarySmartHubIconText',
                    id: 'FleetSummarySmartHubIcon',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setFleetSummarySmarthubSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: fleetSummarySmarthubIcon,
                  },
                  {
                    filename: WhiteLabelFilenames.fleetSummaryTpmsSvg,
                    text: 'FleetSummaryTpmsIconText',
                    id: 'FleetSummaryTpmsIcon',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setFleetSummaryTpmsSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: fleetSummaryTpmsIcon,
                  },
                  {
                    filename: WhiteLabelFilenames.fleetSummaryLinePressureSvg,
                    text: 'FleetSummaryLinePressureIconText',
                    id: 'FleetSummaryLinePressureIcon',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setFleetSummaryLinePressureSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: fleetSummaryLinePressureIcon,
                  },
                  {
                    filename: WhiteLabelFilenames.fleetSummaryAxleLoadSvg,
                    text: 'FleetSummaryAxleLoadIconText',
                    id: 'FleetSummaryAxleLoadIcon',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setFleetSummaryAxleLoadSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: fleetSummaryAxleLoadIcon,
                  },
                  {
                    filename: WhiteLabelFilenames.fleetSummaryGeofenceInsideSvg,
                    text: 'FleetSummaryGeofenceInsideIconText',
                    id: 'FleetSummaryGeofenceInsideIcon',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setFleetSummaryGeofenceInsideSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: fleetSummaryGeofenceInsideIcon,
                  },
                  {
                    filename:
                      WhiteLabelFilenames.fleetSummaryGeofenceOutsideSvg,
                    text: 'FleetSummaryGeofenceOutsideIconText',
                    id: 'FleetSummaryGeofenceOutsideIcon',
                    accept: 'image/svg+xml',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setFleetSummaryGeofenceOutsideSvg(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: fleetSummaryGeofenceOutsideIcon,
                  },
                ]}
              />
              <FileUploader
                title='Sensor Icons for App'
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelAppFilenames.smartHubPng,
                    label: 'SmartHub Icon',
                    text: 'SmartHubIconText',
                    id: 'SmartHubIcon',
                    accept: 'image/png',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAppSmartHubIconPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: defaultAppSmartHubIcon,
                  },
                  {
                    filename: WhiteLabelAppFilenames.tpmsPng,
                    label: 'TPMS Icon',
                    text: 'TpmsIconText',
                    id: 'TpmsIcon',
                    accept: 'image/png',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAppTpmsIconPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: defaultAppTpmsIcon,
                  },
                  {
                    filename: WhiteLabelAppFilenames.linePressurePng,
                    label: 'Line Pressure Icon',
                    text: 'LinePressureIconText',
                    id: 'LinePressureIcon',
                    accept: 'image/png',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAppLinePressureIconPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: defaultAppLinePressureIcon,
                  },
                  {
                    filename: WhiteLabelAppFilenames.axleLoadPng,
                    label: 'Axle Load Icon',
                    text: 'AxleLoadIconText',
                    id: 'AxleLoadIcon',
                    accept: 'image/png',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAppAxleLoadIconPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: defaultAppAxleLoadIcon,
                  },
                ]}
              />
              <FileUploader
                title='Brand Logos for App'
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelAppFilenames.logoBrandLightPng,
                    label: 'Logo Brand Light',
                    text: 'AppLogoBrandLightText',
                    id: 'AppLogoBrandLight',
                    accept: 'image/png',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAppLogoBrandLightPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: defaultAppBrandLogoLight,
                  },
                  {
                    filename: WhiteLabelAppFilenames.logoBrandDarkPng,
                    label: 'Logo Brand Dark',
                    text: 'AppLogoBrandDarkText',
                    id: 'AppLogoBrandDark',
                    accept: 'image/png',
                    onUpdate: (file, _filename) => {
                      setDirty(true)
                      setAppLogoBrandDarkPng(file)
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id),
                    maxSizeInBytes: 100000,
                    fallBackImage: defaultAppBrandLogoDark,
                  },
                ]}
              />
              <Col className='d-flex justify-content-between flex-wrap col-12'>
                <ColorPicker
                  title='Theme Colors'
                  locale={locale}
                  colorsData={filteredThemeColors}
                  onUpdate={(themeColors) =>
                    handleUpdate({ ...config, themeColors })
                  }
                />
                <ColorPicker
                  title='Health Colors'
                  locale={locale}
                  colorsData={config.healthColors}
                  onUpdate={(healthColors) =>
                    handleUpdate({ ...config, healthColors })
                  }
                />
                <ColorPicker
                  title='Graph Palette'
                  locale={locale}
                  colorsData={config.wheelColors}
                  onUpdate={(wheelColors) =>
                    handleUpdate({ ...config, wheelColors })
                  }
                  showControlButtons={true}
                  onAddAxles={(wheelColors) =>
                    handleUpdate({ ...config, wheelColors })
                  }
                  onRemoveAxles={(wheelColors) =>
                    handleUpdate({ ...config, wheelColors })
                  }
                />
                <ColorPicker
                  title='Gps Palette'
                  locale={locale}
                  colorsData={config.gpsColors}
                  onUpdate={(gpsColors) =>
                    handleUpdate({ ...config, gpsColors })
                  }
                />
              </Col>
              {!hideSuperAdminOnlyElements && (
                <FooterSettings
                  title={'Footer'}
                  locale={locale}
                  width={12}
                  footer={config.footer}
                  onUpdate={(footer) => handleUpdate({ ...config, footer })}
                />
              )}
            </>
          ) : isLoading ? (
            <RivataLoader />
          ) : null}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  whitelabel: state.whitelabelAdmin,
  customers: state.common.customers,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(fetchWhitelabelAdmin()),
  postConfig: (customerId) => dispatch(postWhitelabel(customerId)),
  setConfig: (config, isUpdate) =>
    dispatch(setWhitelabelAdmin(config, isUpdate)),
  setDirty: (dirty) => dispatch(setWhitelabelAdminDirty(dirty)),
  setSmarthubHealthSvg: (file) => dispatch(setSmarthubHealthSvg(file)),
  setTpmsHealthSvg: (file) => dispatch(setTpmsHealthSvg(file)),
  setLinePressureHealthSvg: (file) => dispatch(setLinePressureHealthSvg(file)),
  setAxleLoadHealthSvg: (file) => dispatch(setAxleLoadHealthSvg(file)),
  setGatewayHealthSvg: (file) => dispatch(setGatewayHealthSvg(file)),
  setLogoIconPng: (file) => dispatch(setLogoIconPng(file)),
  setLogoFullPng: (file) => dispatch(setLogoFullPng(file)),
  setFleetSummarySmarthubSvg: (file) =>
    dispatch(setFleetSummarySmarthubSvg(file)),
  setFleetSummaryTpmsSvg: (file) => dispatch(setFleetSummaryTpmsSvg(file)),
  setFleetSummaryLinePressureSvg: (file) =>
    dispatch(setFleetSummaryLinePressureSvg(file)),
  setFleetSummaryAxleLoadSvg: (file) =>
    dispatch(setFleetSummaryAxleLoadSvg(file)),
  setFleetSummaryGeofenceInsideSvg: (file) =>
    dispatch(setFleetSummaryGeofenceInsideSvg(file)),
  setFleetSummaryGeofenceOutsideSvg: (file) =>
    dispatch(setFleetSummaryGeofenceOutsideSvg(file)),
  setAuthImagePng: (file) => dispatch(setAuthImagePng(file)),
  setAppSmartHubIconPng: (file) => dispatch(setAppSmartHubIconPng(file)),
  setAppAxleLoadIconPng: (file) => dispatch(setAppAxleLoadIconPng(file)),
  setAppLinePressureIconPng: (file) =>
    dispatch(setAppLinePressureIconPng(file)),
  setAppTpmsIconPng: (file) => dispatch(setAppTpmsIconPng(file)),
  setAppLogoBrandDarkPng: (file) => dispatch(setAppLogoBrandDarkPng(file)),
  setAppLogoBrandLightPng: (file) => dispatch(setAppLogoBrandLightPng(file)),
  resetWhitelabelToDefault: (resetType) =>
    dispatch(resetWhitelabelToDefault(resetType)),
  setWhitelabelAdminError: (p) => dispatch(setWhitelabelAdminError(p)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabel)
