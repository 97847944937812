import React, { useCallback, useEffect, useMemo, useState } from 'react'

import LayersDropdown from '../../../components/LayersDropdown'
import RivataModule from '../../../components/RivataModule'

import { LayerTypes } from '../../../components/RivataMapCluster/utils'
import RivataMapCluster from '../../../components/RivataMapCluster'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import RivataLoader from '../../../components/RivataLoader'
import ScrollBlockWrapper from '../../../components/ScrollBlockWrapper'
import { CustomInput } from 'reactstrap'

interface Props {
  locale: ILocale
}

const GeofenceDetailsMap: React.FC<Props> = ({ locale }) => {
  const { preferences, selectedGeofence, assets } = useTypedSelector(
    (state) => ({
      preferences: state.auth.preferences,
      selectedGeofence: state.geofences.selectedGeofence,
      assets: state.geofences.assets,
    }),
  )

  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL,
  )
  const [mapZoomBounds, setMapZoomBounds] = useState({
    zoom: null,
    bounds: null,
  })
  const [locations, setLocations] = useState<Array<any>>([])
  const [clusteringDisabled, setClusteringDisabled] = useState(false)

  const geofence = useMemo(() => {
    return selectedGeofence.data ? [selectedGeofence.data] : []
  }, [selectedGeofence.data])

  useEffect(() => {
    setLocations(assets.data)
  }, [assets.data])

  const onMapViewChange = useCallback((bounds: any, zoom: any) => {
    setMapZoomBounds({ bounds, zoom })
  }, [])

  return (
    <RivataModule
      title={'Geofence Map'}
      width={12}
      locale={locale}
      fullScreenModalModeEnabled
      customFullScreenAction={() => {
        // TODO hack to rerender map in full screen
        setTimeout(() => {
          setLocations([...locations, {}])
        }, 100)
      }}
      error={
        selectedGeofence.status.statusCode >= 400
          ? selectedGeofence.status
          : assets.status
      }
      filters={
        <div className='d-flex align-items-center'>
          <CustomInput
            type='switch'
            id='clusteringSwitch'
            label={'Clustering'}
            checked={!clusteringDisabled}
            onChange={(e: any) => setClusteringDisabled(!e.target.checked)}
            className={'mr-3'}
          />
          <LayersDropdown
            selected={selectedLayerOption}
            onSelect={setSelectedLayerOption}
            locale={locale}
          />
        </div>
      }
    >
      {assets.isLoading && <RivataLoader />}

      <ScrollBlockWrapper>
        <RivataMapCluster
          onMapViewChange={onMapViewChange}
          mapZoomBounds={mapZoomBounds}
          locations={locations}
          layerType={selectedLayerOption}
          locale={locale}
          preferences={preferences}
          geofences={geofence}
          geofencesVisible={true}
          setBounds={true}
          geofenceLocationBtn={true}
          displayGeofenceBubble={false}
          clusteringDisabled={clusteringDisabled}
        />
      </ScrollBlockWrapper>
    </RivataModule>
  )
}

export default GeofenceDetailsMap
