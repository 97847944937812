import React, { useCallback, useEffect, useState } from 'react'

import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'

import { useTableColumns, useTableRows } from './hooks'
import { LogModal } from './logModal'
import { parseLog } from './utils'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import './style.scss'

const HardwareCommandsTable = ({
    locale = {},
}) => {
    const { putHardwareCommand, fetchHardwareCommandsHistory } = useActions()
    const {
        assetInfo: {whitelistingAutomation},
        hardwareCommands: {isLoading, data, error}
    } = useTypedSelector(state => ({
        assetInfo: state.assetAdminInfo.assetInfo,
        hardwareCommands: state.assetAdminInfo.hardwareCommands
    }))
    const onLogButtonPress = useCallback((log) => {
        setLogModalVisible(true);
        setLogModalText(parseLog(log))
    }, []);

    const [logModalVisible, setLogModalVisible] = useState(false)
    const [logModalText, setLogModalText] = useState("")
    const columns = useTableColumns()
    const rows = useTableRows(data, putHardwareCommand, onLogButtonPress, whitelistingAutomation)

    useEffect(() => {
        fetchHardwareCommandsHistory()
    }, [fetchHardwareCommandsHistory])

    return (
        <RivataModule
            title="Hardware Commands"
            locale={locale}
            marginTop={0}
            error={error}
        >
            <RivataTable
                isLoading={isLoading}
                columns={columns}
                rows={rows}
            />
            <LogModal isOpen={logModalVisible} text={logModalText} onLogModalClose={() => { setLogModalVisible(false) }} />
        </RivataModule>
    )
}

export default HardwareCommandsTable