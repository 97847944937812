
import { convertDataEpochToDate, getClearLabel, getCurrentEpoch } from "../../../utils";
import { IRow } from "../../../components/RivataGrid";

export const notificationHistoryReportRows = (rows: Array<IRow>) =>{
    const currentDateTime = convertDataEpochToDate(getCurrentEpoch(), null, null, true);
    return rows.map((row) => {
        row.sensor_type = row.sensor_type ? getClearLabel(row.sensor_type) : "---";
        row.warning_type = row.warning_type ? getClearLabel(row.warning_type) : "---";
        row.position = row.position ? getClearLabel(row.position) : "---";
        row.warning_timestamp = row.warning_timestamp ? convertDataEpochToDate(row.warning_timestamp, null, null, true) : "---";
        row.notification_timestamp = row.notification_timestamp ? convertDataEpochToDate(row.notification_timestamp, null, null, true) : "---";
        row.report_time = currentDateTime;
        row.notification_type = row.notification_type === "PUSH" ? "Driver App" : row.notification_type === "SMS" ? "SMS" : getClearLabel(row.notification_type) ;
        row.asset_groups = row.asset_groups || "---";
        return row;
    });
}