import { MenuItemType } from "./MenuItem"


const getOptionInfo = (options: Array<MenuItemType>, id: string) => {
    const idx = options.findIndex(el => el.id === id)

    return idx > -1 ? { idx, item: options[idx] } : null
}

export const getChildrenByParentId = (options: Array<MenuItemType>, parentId: string | null) => {
    return options.reduce<Array<MenuItemType>>((prev, curr) => {
        parentId === curr.parentId && prev.push({ ...curr })

        return prev
    }, [])
}

export const uppdateParentItem = (options: Array<MenuItemType>, children: Array<MenuItemType>, currentParent: MenuItemType) => {
    const idx = options.findIndex(el => el.id === currentParent.id)
    if (idx < 0) return 

    const currentParentCopy = { ...currentParent }
    let atLeastOneSelected = false
    let allSelected = true
    let hasSubItemsPartialSelection = false
    let hasRadioChildren = false

    children.forEach(el => {
        el.checked ? atLeastOneSelected = true : allSelected = false

        if (el.checked && el.markType === "line") hasSubItemsPartialSelection = true
        if (el.type === "radio") hasRadioChildren = true
    })

    if ((allSelected && !hasSubItemsPartialSelection) || hasRadioChildren) {
        currentParentCopy.checked = true 
        currentParentCopy.markType = "check"
    } else if (atLeastOneSelected) {
        currentParentCopy.checked = true 
        currentParentCopy.markType = "line"
    } else {
        currentParentCopy.checked = false
        currentParentCopy.markType = "check"
    }

    options[idx] = currentParentCopy

    if (currentParentCopy.parentId) {
        const nextParent = options.find(el => el.id === currentParentCopy.parentId)
        if (nextParent) uppdateParentItem(options, getChildrenByParentId(options, currentParentCopy.parentId), nextParent)
    }
}

export const updateChildren = (options: Array<MenuItemType>, children: Array<MenuItemType>, parent: MenuItemType) => {
    children.forEach((item, i) => {
        if (item.markType !== "circle") {
            item.checked = parent.checked
            item.markType = "check"
        } else {
            item.checked = false 
            if (i === 0) {
                item.checked = parent.checked
            } 
        }

        const idx = options.findIndex(el => el.id === item.id)
        if (idx < 0) return
        options[idx] = item

        const itemChildren = getChildrenByParentId(options, item.id)
        if (itemChildren.length > 0) {
            updateChildren(options, itemChildren, item)
        }
    })
}

export const handleRadioSelection = (options: Array<MenuItemType>, currentLevelItems: Array<MenuItemType>, selectedItem: MenuItemType) => {
    currentLevelItems.forEach(el => {
        el.checked = el.id === selectedItem.id
        const optionInfo = getOptionInfo(options, el.id)
        if (optionInfo) options[optionInfo.idx] = el
    })
}

export const handleCheckboxSelection = (options: Array<MenuItemType>, itemChildren: Array<MenuItemType>, item: MenuItemType) => {
    if (item.checked) {
        if (item.markType === "line") {
            // has partial selection 
            item.markType = "check"
        }
        else if (itemChildren.length > 0) {
            // have children
            item.markType = "line"
            item.checked = false
        } else { // has full selection
            item.checked = false
        }
    } else {
        // don't have selection
        item.checked = true

        if (itemChildren.length > 0) {
            // have children
            item.markType = "check"
        }
    }

    const idx = options.findIndex(el => el.id === item.id)
    if (idx > -1) options[idx] = item
}