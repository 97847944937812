import React, { useCallback, useState } from "react"

import { IFilterOptions } from "../../../redux/types"
import BackendSearchInput from "../../../components/BackendSearchInput"
import { EndPointConfigs } from "../../../components/BackendSearchInput/constants"


interface Props {
    setManageNotificationsProfilesFilter: (f: IFilterOptions) => void
}

const NotificationsGridFilters: React.FC<Props> = ({
    setManageNotificationsProfilesFilter,
}) => {
    const [selectedUsername, setSelectedUsername] = useState<string>("")

    const handleSelectUser = useCallback((el: IUserShortFormat) => {
        setSelectedUsername(el.username)
        setManageNotificationsProfilesFilter({ filterType: "user_id", filterData: `${el.id}` })
    }, [setManageNotificationsProfilesFilter])


    return (
        <div className="d-flex justify-content-between" style={{ height: "85px" }}>
            <div className="d-flex">
                <div>
                    <BackendSearchInput
                        endPointConfig={EndPointConfigs.user}
                        placeholder="Search User"
                        onSelect={handleSelectUser}
                        width="355px"
                    />

                    {selectedUsername && (
                        <div className="notification-grid__username">
                            <div className="mr-2">
                                Selected User: {selectedUsername}
                            </div>

                            <div 
                                className="btn__remove"
                                onClick={() => {
                                    setSelectedUsername("")
                                    setManageNotificationsProfilesFilter({})
                                }}
                            >
                                &#10006;
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(NotificationsGridFilters)