import Login from '../pages/Login'
import RequestAccess from '../pages/RequestAccess'

var authRoutes = [
  {
    path: '/auth/:customer',
    name: 'Login',
    icon: 'mdi mdi-account-key',
    component: Login,
  },
  {
    path: '/auth',
    name: 'Login',
    icon: 'mdi mdi-account-key',
    component: Login,
  },

  {
    path: '/authentication/login',
    name: 'Login',
    icon: 'mdi mdi-account-key',
    component: Login,
  },
  {
    path: '/authentication/request-access',
    name: 'RequestAccess',
    component: RequestAccess,
  },
]
export default authRoutes
