import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import CustomerRow from '../ReportParametersRows/CustomerRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'

const InternalTpmsReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <CustomerRow value={props.parameters.customer_keys} />
      <TimeZoneRow value={props.parameters.timezone} />
      <AssetTypeRow value={props.parameters.asset_type} />
      <AssetGroupsRow value={props.parameters.asset_group} />
    </>
  )
}
export default InternalTpmsReportParameters
