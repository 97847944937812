import { ActionableItemsFilter } from '../../../redux/actionableItems/types'
import { ISortOptions } from '../../../redux/types'
import baseApi from './BaseApi'

class WarningApi {
  getRecentWarnings = (
    assetId: number,
    daysRange: number | null,
    offset: number,
    limit: number,
    allWarnings: boolean,
    filter: any,
  ) => {
    let queryString = `rivata/warning?asset_id=${assetId}&limit=${limit}&offset=${offset}`

    let warningStatusQuery = filter.warning_status
      .map((w: { id: any }) => w.id)
      .join(':')
    let sensorTypeQuery = filter.sensor_type
      .map((w: { id: any }) => w.id)
      .join(':')
    let warningTypeQuery = filter.warning_type
      .map((w: { id: any }) => w.id)
      .join(':')
    let positionTypeQuery = filter.position_type
      .map((w: { id: any }) => w.id)
      .join(':')

    if (daysRange) {
      queryString += `&days=${daysRange}`
    }

    if (allWarnings) {
      queryString += '&all_warnings=true'
    }

    if (filter) {
      queryString += `&warning_status=${warningStatusQuery}&sensor_type=${sensorTypeQuery}&warning_type=${warningTypeQuery}&position_type=${positionTypeQuery}`
    }

    return baseApi.get(queryString, false)
  }

  postAcknowledgements = (acknowledgements: any) => {
    return baseApi.post(`rivata/warning/acknowledge`, acknowledgements)
  }

  postWarningsCancel = (body: any) => {
    return baseApi.post(`rivata/warning/cancel`, body)
  }
  postWarningsRequireActions = (body: any) => {
    return baseApi.post(`rivata/warning/actions_required`, body)
  }
  getActionableItems = (
    daysRange: number | null,
    offset: number,
    limit: number,
    filter: ActionableItemsFilter,
    sortOptions?: ISortOptions,
  ) => {
    const params = new URLSearchParams()

    if (typeof limit === 'number') {
      params.append('limit', limit.toString())
    }

    if (typeof offset === 'number') {
      params.append('offset', offset.toString())
    }

    if (filter.asset_name_vin) {
      params.append('asset_name_vin', filter.asset_name_vin[0])
    }

    if (filter.warning_status) {
      params.append('warning_status', filter.warning_status.join(':'))
    }

    if (filter.sensor_type) {
      params.append('sensor_type', filter.sensor_type.join(':'))
    }

    if (filter.warning_type) {
      params.append('warning_type', filter.warning_type.join(':'))
    }

    if (filter.position_type) {
      params.append('position_type', filter.position_type.join(':'))
    }

    if (sortOptions && sortOptions.column && sortOptions.direction) {
      params.append('sort_column', sortOptions.column)
      params.append('sort_direction', sortOptions.direction)
    }

    return baseApi.get(
      `rivata/warning/actions_required?${params.toString()}`,
      true,
    )
  }

  forceResendWarningEmail = (body: any) => {
    return baseApi.post(`rivata/warning/resend_email`, body)
  }

  getUnseenActionableItemsCount = (lastSeenEpoch: number) => {
    const params = new URLSearchParams()

    if (typeof lastSeenEpoch === 'number') {
      params.append('last_seen_epoch', lastSeenEpoch.toString())
    }

    return baseApi.get(
      `rivata/warning/unseen_actions_required?${params.toString()}`,
      false,
    )
  }
}

const warningApi = new WarningApi()

export default warningApi
