import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import { ResetWhitelabelType } from '../../enums'
import './style.scss'

const ResetWhitelabelStyles = ({
  onResetClick,
  hideSuperAdminOnlyElements,
}) => {
  const [tooltipState, setTooltipState] = useState({
    style: false,
    layout: false,
    images: false,
    footer: false,
  })
  const toggleTooltipState = (key) => {
    setTooltipState({ ...tooltipState, [key]: !tooltipState[key] })
  }
  return (
    <div data-component='fleet-map' className='mt-4 reset-config'>
      <h4>Reset Whitelabel To Default</h4>
      <div className='d-flex'>
        <div>
          <Tooltip
            className='reset-styles__warning'
            placement='top'
            isOpen={tooltipState.layout}
            target={'resetLayout'}
            toggle={() => toggleTooltipState('layout')}
          >
            This will restore all layout configuration back to ConMet Digital
            defaults
          </Tooltip>
          <button
            id='resetLayout'
            className='btn btn-danger'
            onClick={() => onResetClick(ResetWhitelabelType.LAYOUT)}
          >
            Reset Layout
          </button>
        </div>
        <div>
          <Tooltip
            className='reset-styles__warning'
            placement='top'
            isOpen={tooltipState.images}
            target={'resetImages'}
            toggle={() => toggleTooltipState('images')}
          >
            This will restore all images back to ConMet Digital defaults
          </Tooltip>
          <button
            id='resetImages'
            className='btn btn-danger'
            onClick={() => onResetClick(ResetWhitelabelType.IMAGES)}
          >
            Reset Images
          </button>
        </div>
        <div>
          <Tooltip
            className='reset-styles__warning'
            placement='top'
            isOpen={tooltipState.style}
            target={'resetStyles'}
            toggle={() => toggleTooltipState('style')}
          >
            This will restore all colors back to ConMet Digital defaults
          </Tooltip>
          <button
            id='resetStyles'
            className='btn btn-danger'
            onClick={() => onResetClick(ResetWhitelabelType.STYLES)}
          >
            Reset Colors
          </button>
        </div>
        {!hideSuperAdminOnlyElements && (
          <div>
            <Tooltip
              className='reset-styles__warning'
              placement='top'
              isOpen={tooltipState.footer}
              target={'resetFooter'}
              toggle={() => toggleTooltipState('footer')}
            >
              This will restore all footer settings back to ConMet Digital
              defaults
            </Tooltip>
            <button
              id='resetFooter'
              className='btn btn-danger'
              onClick={() => onResetClick(ResetWhitelabelType.FOOTER)}
            >
              Reset Footer
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetWhitelabelStyles
