import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { shapes } from "../constants";

interface Props {
  selectedShape: string;
  disabled: boolean;
  setSelectedShape: (shape: string) => void;
}

const ShapeSelector: React.FC<Props> = ({ selectedShape, disabled, setSelectedShape }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const title = shapes.find((shape) => shape.id === selectedShape)?.name;

  return (
    <Dropdown
      disabled={disabled}
      className="mr-2"
      style={disabled ? { opacity: "0.65", boxShadow: "none" } : {}}
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen((prevState) => !prevState)}
    >
      <DropdownToggle caret>{title || "Select Shape"}</DropdownToggle>
      <DropdownMenu>
        {shapes.map((shape, index) => (
          <DropdownItem
            onClick={() => {
              setSelectedShape(shape.id);
            }}
            key={index}
          >
            {shape.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ShapeSelector;
