import {
  SET_ASSET_TPMS_STATUS,
  SET_SMARTHUB_STATUS,
  SET_LINE_PRESSURE_STATUS,
  SET_LATEST_AXLE_LOADS,
  LOADING_ASSET_STATUS,
} from "../actionTypes";

// TODO: May want to do reducers by data, not pages.
// Many of the data below is copy pasta in warnings,
// and gets set by actions on that page (and vice versa).
const initialState = {
  statuses: { isLoading: false, assetTpmsStatus: {}, assetSmartStatus:[], assetLineStatus: [], assetAxlesLoads: [], error: { statusCode: 0, message: "" } },
};

const setLoading = (key, state, isLoading) => ({
  ...state,
  [key]: { ...state[key], isLoading },
});
const setField = (state, property, field, payload) => ({ ...state, [property]: { ...state[property], [field]: payload } })

const details = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ASSET_STATUS:
      return setLoading("statuses", state, action.payload);
    
    case SET_ASSET_TPMS_STATUS:
      return setField( state, "statuses","assetTpmsStatus", action.payload);
    case SET_SMARTHUB_STATUS:
      return setField(state, "statuses","assetSmartStatus", action.payload);
    case SET_LINE_PRESSURE_STATUS:
      return setField( state, "statuses","assetLinePressureStatus", action.payload);
    case SET_LATEST_AXLE_LOADS:
      return setField( state, "statuses","assetAxlesLoads", action.payload);
    default:
      return state;
  }
};

export default details;
