import { IFullPropertyGroup, SetLoading, SetNumber, SetSortOptions, SetStatus } from "../types"

export interface IGeofencesReducer {
	selectedGeofence: {
		data: IGeofence | null
		status: IStatus
	}
	geofences: IFullPropertyGroup<Array<IGeofence>>
	assets: Omit<IFullPropertyGroup<Array<IAsset>>, "filter">
	assets_locations: Omit<IFullPropertyGroup<Array<IAsset>>, "filter">
}


export enum GeofencesActionTypes {
	SET_SELECTED_GEOFENCE = "SET_GEOFENCES_PAGE_SELECTED_GEOFENCE",
	SET_SELECTED_GEOFENCE_STATUS = "SET_SELECTED_GEOFENCE_STATUS",

	LOADING_GEOFENCES = "LOADING_GEOFENCES_PAGE_GEOFENCES",
	SET_GEOFENCES = "SET_GEOFENCES_PAGE_GEOFENCES_DATA",
	SET_GEOFENCES_LIMIT = "SET_GEOFENCES_PAGE_GEOFENCES_DATA_LIMIT",
	SET_GEOFENCES_OFFSET = "SET_GEOFENCES_PAGE_GEOFENCES_DATA_OFFSET",
	SET_GEOFENCES_COUNT = "SET_GEOFENCES_PAGE_GEOFENCES_DATA_COUNT",
	SET_GEOFENCES_SORT = "SET_GEOFENCES_PAGE_GEOFENCES_DATA_SORT",
	SET_GEOFENCES_STATUS = "SET_GEOFENCES_PAGE_GEOFENCES_STATUS",

	LOADING_ASSETS = "LOADING_GEOFENCES_PAGE_ASSETS",
	SET_ASSETS = "SET_GEOFENCES_PAGE_ASSETS_DATA",
	SET_ASSETS_LIMIT = "SET_GEOFENCES_PAGE_ASSETS_LIMIT",
	SET_ASSETS_OFFSET = "SET_GEOFENCES_PAGE_ASSETS_OFFSET",
	SET_ASSETS_TOTAL_COUNT = "SET_GEOFENCES_PAGE_ASSETS_TOTAL_COUNT",
	SET_ASSETS_SORT = "SET_GEOFENCES_PAGE_ASSETS_SORT",
	SET_ASSETS_STATUS = "SET_GEOFENCES_PAGE_ASSETS_STATUS",
	SET_GEOFENCES_ASSETS_LOCATIONS = "SET_GEOFENCES_ASSETS_LOCATIONS",
	SET_GEOFENCES_ASSETS_LOCATIONS_STATUS = "SET_GEOFENCES_ASSETS_LOCATIONS_STATUS",
	LOADING_GEOFENCES_ASSETS_LOCATIONS = "LOADING_GEOFENCES_ASSETS_LOCATIONS",

}

type LoadingGeofencesType = SetLoading<GeofencesActionTypes.LOADING_GEOFENCES | GeofencesActionTypes.LOADING_ASSETS |GeofencesActionTypes.LOADING_GEOFENCES_ASSETS_LOCATIONS >
type SetGeofencesNumberType = SetNumber<
	GeofencesActionTypes.SET_GEOFENCES_LIMIT |
	GeofencesActionTypes.SET_GEOFENCES_OFFSET |
	GeofencesActionTypes.SET_GEOFENCES_COUNT |
	GeofencesActionTypes.SET_ASSETS_LIMIT |
	GeofencesActionTypes.SET_ASSETS_OFFSET |
	GeofencesActionTypes.SET_ASSETS_TOTAL_COUNT
>
type SetGeofencesStatusType = SetStatus<GeofencesActionTypes.SET_GEOFENCES_STATUS | GeofencesActionTypes.SET_ASSETS_STATUS | GeofencesActionTypes.SET_SELECTED_GEOFENCE_STATUS | GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS_STATUS>
type SetGeofencesSortOptionsType = SetSortOptions<GeofencesActionTypes.SET_GEOFENCES_SORT | GeofencesActionTypes.SET_ASSETS_SORT>

interface ISetSelectedGeofence { type: GeofencesActionTypes.SET_SELECTED_GEOFENCE, payload: IGeofence | null }
interface ISetGeofences { type: GeofencesActionTypes.SET_GEOFENCES, payload: Array<IGeofence> }
interface ISetAssets { type: GeofencesActionTypes.SET_ASSETS, payload: Array<IAsset> }
interface ISetAssetsLocations { type: GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS, payload: Array<IAsset> }


export type GeofencesActionsType = LoadingGeofencesType | SetGeofencesNumberType | SetGeofencesStatusType | ISetSelectedGeofence |
	ISetGeofences | SetGeofencesSortOptionsType | ISetAssets | ISetAssetsLocations