import React, { useMemo } from "react";
import RivataDropdown from "../../../components/RivataDropdown";
import DateTimePicker from "../../../componentsV2/DateTimePicker";
import { CalendarTypes } from "../../../enums";
import moment from "moment";

interface Props {
  disabled: boolean;
  onDateRangeSelect: (from: Date, to: Date) => void;
  setApplication: React.Dispatch<React.SetStateAction<string>>;
  application: "installer_app_web" | "rivata" | "driver_app" | "installer_app";
  dateFrom: string;
  dateTo: string;
}

const UserLoginsReportForm: React.FC<Props> = ({
  disabled,
  onDateRangeSelect,
  dateFrom,
  dateTo,
  application,
  setApplication,
}) => {
  const applications = useMemo(() => {
    return [
      { id: "all", label: "All", disabled: false },
      { id: "rivata", label: "Digital Dashboard", disabled: false },
      { id: "installer_app", label: "Install Manager", disabled: false },
      {
        id: "installer_app_web",
        label: "Install Manager (web)",
        disabled: false,
      },
      { id: "driver_app", label: "Digital Driver App", disabled: false },
    ];
  }, []);
  return (
    <>
      <div className="mb-2">Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateRangeSelect={onDateRangeSelect}
        minDate={new Date(2000, 0, 1, 0, 0)}
        maxDate={moment().hours(23).minutes(59).seconds(0).toDate()}
        disabled={disabled}
      />

      <div
        className="d-flex mt-4 align-items-start"
        style={{ minWidth: "300px" }}
      >
        <label className="mr-2 mb-0 mt-2" style={{ minWidth: "120px" }}>
          Application:
        </label>
        <RivataDropdown
          caret={true}
          items={applications}
          selected={application}
          onSelect={setApplication}
          buttonLabel={null}
          filters={null}
          onRemove={null}
          disabled={disabled}
          color={"secondary"}
        />
      </div>
    </>
  );
};

export default UserLoginsReportForm;
