import React from "react"

import Subnav from '../../components/SubNav'
import CustomerSelector from "../../modules/CustomerSelector";
import NotificationsGrid from "../../modules/NotificationsGrid";


const breadcrumbs = [{ label: "Home", path: "/dashboard" }, { label: "Manage Notifications" }]

const ManageNotifications: React.FC = () => {
    return (
        <>
            <Subnav 
                title="Manage Notifications"
                breadcrumbs={breadcrumbs}
            >
                <CustomerSelector/>
            </Subnav>

            <NotificationsGrid/>
        </>
    )
}

export default React.memo(ManageNotifications)
