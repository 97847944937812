import React, { useCallback, useMemo } from 'react'
import { Spinner } from 'reactstrap'
import { DefaultItem, UserItem } from './ListItems'
import { FixedSizeList } from 'react-window'

import './style.scss'

interface Props {
  isUserItem?: boolean
  data: Array<any>
  displayLoading?: boolean
  displayNotFound?: boolean
  labelKey?: string
  subLabelKey?: string
  width?: string
  onClick: (el: any) => void
  listRef?: React.LegacyRef<FixedSizeList<any>>
}

// make sure parent node has position relative
const RivataMenu: React.FC<Props> = ({
  isUserItem = false,
  data,
  displayLoading,
  displayNotFound,
  labelKey,
  subLabelKey,
  width = '100%',
  onClick,
  listRef,
}) => {
  const rowHeight = useMemo(() => {
    if (isUserItem || subLabelKey) return 58

    return 39
  }, [isUserItem, subLabelKey])

  const listHeight = useMemo(() => {
    if (data.length > 4 && rowHeight > 50) return rowHeight * 4 + 1

    if (data.length > 4) return rowHeight * 5 + 1

    return rowHeight * data.length + 1
  }, [data.length, rowHeight])

  const Row = useCallback(
    ({ index, style }: any) => {
      return (
        <li style={style} onClick={() => onClick(data[index])}>
          {isUserItem ? (
            <UserItem item={data[index]} />
          ) : (
            labelKey && (
              <DefaultItem
                item={data[index]}
                labelKey={labelKey}
                subLabelKey={subLabelKey}
              />
            )
          )}
        </li>
      )
    },
    [data, onClick, isUserItem, labelKey, subLabelKey],
  )

  if (displayLoading)
    return (
      <div className='rivata-menu__list' style={{ width, padding: '10px' }}>
        <Spinner size='sm' className='mr-2' />
        Loading
      </div>
    )

  if (displayNotFound)
    return (
      <div className='rivata-menu__list' style={{ width }}>
        <li>Not Found...</li>
      </div>
    )

  if (!data.length) return null

  return (
    <FixedSizeList
      className='rivata-menu__list'
      height={listHeight}
      itemCount={data.length}
      itemSize={rowHeight}
      width={width}
      ref={listRef}
    >
      {Row}
    </FixedSizeList>
  )
}

export default RivataMenu
