import React, { useEffect, useMemo } from 'react'

import Subnav from '../../components/SubNav'
import CustomerSelector from '../../modules/CustomerSelector'
import GeofenceManager from '../../modules/Geofences/manager/GeofenceManager'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { sortByAlphabet } from '../../utils'

const breadcrumbs = [{ label: 'Manage Geofences' }]

const ManageGeofences = () => {
  const {
    locale = {},
    selectedCustomersList,
    customers,
    isSuperAdmin,
    manageGeofences: {
      isLoading,
      data,
      limit,
      offset,
      showDeleted,
      totalCount,
      dataStatus,
      assets: {
        data: assets,
        isLoading: isLoadingAssets,
        status: assetsStatus,
      },
      allShortData: { data: allShortData },
    },
    unitsOfMeasurementConfig,
  } = useTypedSelector((state) => ({
    locale: state.whitelabel.locale,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    customers: state.common.customers.data,
    isSuperAdmin: state.auth.isSuperAdmin,
    manageGeofences: state.manageGeofences,
    unitsOfMeasurementConfig: state.auth.preferences.unitsOfMeasurementConfig,
  }))

  const {
    clearManageGeofencesReducer,
    setManageGeofencesOffset,
    fetchManageGeofencesAssets,
    setManageGeofencesAssetsFilter,
    fetchManageGeofencesAllShortData,
    setManageGeofencesShowDeleted,
  } = useActions()

  useEffect(() => {
    if (selectedCustomersList.length > 0) {
      setManageGeofencesOffset(0)
      fetchManageGeofencesAssets()
      fetchManageGeofencesAllShortData()
    }
  }, [
    selectedCustomersList,
    setManageGeofencesOffset,
    fetchManageGeofencesAssets,
    fetchManageGeofencesAllShortData,
  ])

  useEffect(() => {
    // clear reducer on unmount
    return () => {
      clearManageGeofencesReducer()
    }
  }, [clearManageGeofencesReducer])

  const allGeofencesInShortFormat = useMemo(() => {
    const geo = allShortData.filter((item) =>
      selectedCustomersList.find((customer: ICustomer) =>
        item.shared_with.includes(customer.id),
      ),
    )

    sortByAlphabet(geo, 'name')

    return geo
  }, [allShortData, selectedCustomersList])

  return (
    <>
      <Subnav title='Manage Geofences' breadcrumbs={breadcrumbs}>
        <CustomerSelector />
      </Subnav>

      <GeofenceManager
        width={12}
        locale={locale}
        isLoading={isLoading}
        geofences={data}
        limit={limit}
        offset={offset}
        totalCount={totalCount}
        status={dataStatus}
        selectedCustomersList={selectedCustomersList}
        isSuperAdmin={isSuperAdmin}
        allGeofencesInShortFormat={allGeofencesInShortFormat}
        assets={assets}
        isLoadingAssets={isLoadingAssets}
        assetsStatus={assetsStatus}
        setAssetsFilter={setManageGeofencesAssetsFilter}
        customers={customers}
        showDeleted={showDeleted}
        setShowDeleted={setManageGeofencesShowDeleted}
        unitsOfMeasurementConfig={unitsOfMeasurementConfig}
      />
    </>
  )
}

export default ManageGeofences
