import baseApi from "./BaseApi"
import { ICreateNotificationprofile, IUpdateNotificationprofile } from "../../../redux/manageNotifications/types"


class NotificationApi {
    postNotificationProfile = (data: ICreateNotificationprofile) => {
        return baseApi.post("rivata/alertprofile", data)
    }
    

    getNotificationProfile = (customerId: number, limit?: number, offset?: number, filterType?: string, filterData?: string, userId?: string) => {
        let query = `customer_id=${customerId}`

        if (limit) query += `&limit=${limit}`
        if (offset) query += `&offset=${offset}`
        if (filterType && filterData) query += `&filter_type=${filterType}&filter_data=${filterData}`
        if (userId) query += `&user_id=${userId}`

        return baseApi.get(`rivata/alertprofile?${query}`, false)
    }


    putNotificationProfile = (profileId: number, data: IUpdateNotificationprofile) => {
        return baseApi.put(`rivata/alertprofile?id=${profileId}`, data)
    }


    deleteNotificationProfile = (id: number) => {
        return baseApi.delete(`rivata/alertprofile?id=${id}`)
    }

    manageUserNotificationProfile = (data:any) => {
        return baseApi.put(`rivata/alertprofile/manage`, data)
    }
}

const notificationApi = new NotificationApi()

export default notificationApi
