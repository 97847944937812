import React from 'react'
import { SearchIcon } from "../../componentsV2/Icon";
import { MenuItemType } from "../../componentsV2/Menu/MenuItem";
import { ListItemType } from '../../componentsV2/LabelSelector/List';

export const geofenceSearchItems: Array<ListItemType> = [
    { id: "inside", label: "All assets inside geofences", isSingleSelection: true, showFull: true, icon: <SearchIcon width={15} height={15} color="black" /> },
    { id: "outside", label: "All assets outside geofences", isSingleSelection: true, showFull: true, icon: <SearchIcon width={15} height={15} color="black" /> },
]

export const sensorTypeItems: Array<MenuItemType> = [
    { id: "smarthub_sensors", label: "SmartHub", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "tpms", label: "TPMS", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "line_pressure", label: "Line Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "axle_load", label: "Axle Load", type: "checkbox", markType: "check", checked: false, parentId: null },
]

