import React, { ChangeEvent, useCallback, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap'
import InfoModal from '../../components/InfoModal'
import UploadCargoTagException from './UploadCargoTagException'

import './styles.scss'

const defaultUploadStatus = {
  success: false,
  message: '',
}

interface AssignedCargoTag {
  customer_name: string
  cargo_tags_count: number
}

const UploadCargoTagModal = ({ postCustomersCargoTagsCsv }: any) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const [uploadStatus, setUploadStatus] = useState(defaultUploadStatus)
  const toggleModalVisability = () => {
    setModalVisible(!modalVisible)
    setUploadStatus(defaultUploadStatus)
    setAssignedCargoTags([])
    setIncorrectNames([])
    setNameExistsCargoTags([])
    setIsLoading(false)
  }
  const [assignedCargoTags, setAssignedCargoTags] = useState<
    Array<AssignedCargoTag>
  >([])
  const [incorrectNames, setIncorrectNames] = useState<Array<string>>([])
  const [nameExistsCargoTags, setNameExistsCargoTags] = useState<
    Array<[string, string]>
  >([])

  const onFileChange = (event: ChangeEvent) => {
    if (event.target) {
      const target = event.target as HTMLInputElement
      if (target) {
        const file = target.files?.[0]
        setFile(file)
      }
    }
  }

  const onUpload = async (reassignAll?: boolean) => {
    if (!file) return

    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = async function () {
      const result = reader.result?.toString().split('base64,')[1]
      if (result) {
        setIsLoading(true)

        const response = await postCustomersCargoTagsCsv(result, reassignAll)

        setIsLoading(false)

        if (!response.statusCode) {
          setAssignedCargoTags(response.assigned_cargo_tags)
          setIncorrectNames(response.incorrect_names)
          setNameExistsCargoTags(response.name_exists_cargo_tags)
        } else {
          const successful = response.statusCode === 200

          setUploadStatus({
            success: successful,
            message: response.message,
          })

          if (successful) {
            setModalVisible(false)
          }
        }
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const AssignedToStr = useCallback((item: any) => {
    return `${item.cargo_tags_count} ${
      item.cargo_tags_count === 1 ? 'tag' : 'tags'
    } already assigned to ${item.customer_name}`
  }, [])

  const InvalidToStr = useCallback((item: any) => {
    return item
  }, [])

  const NotUniqueToStr = useCallback((item: any) => {
    return `${item[0]} : ${item[1]}`
  }, [])

  const downloadExample = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    let csvData = [
      [
        'MAC Address',
        'Name',
        '<- Note: This row is for reference and should be removed!',
      ],
      ['00:1A:2B:3C:4D:5E', 'CargoTag1'],
      ['11:22:33:44:55:66', 'CargoTag2'],
      ['AA:BB:CC:DD:EE:FF', 'CargoTag3'],
    ]

    csvData.forEach(function (rowArray) {
      let row = rowArray.join(',')
      csvContent += row + '\r\n'
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.querySelector('#cargotag-example-link') as HTMLElement

    if (!link) {
      link = document.createElement('a')
      link.style.display = 'none'
      link.id = 'cargotag-example-link'
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'CargoTagExample.csv')
      document.body.appendChild(link)
    }

    link.click()
  }

  return (
    <>
      <Button
        variant='primary'
        className='mr-2'
        onClick={toggleModalVisability}
      >
        Upload Cargo Tags Csv
      </Button>
      <Modal size='lg' centered isOpen={modalVisible}>
        <ModalHeader toggle={toggleModalVisability}>
          Upload Cargo Tags Csv
        </ModalHeader>
        <ModalBody>
          {!uploadStatus.success && uploadStatus.message && (
            <>
              <span className='upload_csv_error'>{uploadStatus.message}</span>
            </>
          )}

          <UploadCargoTagException
            label='Tags Assigned To Another Customer'
            items={assignedCargoTags}
            itemToString={AssignedToStr}
          />

          <UploadCargoTagException
            label='Invalid Names'
            items={incorrectNames}
            itemToString={InvalidToStr}
          />

          <UploadCargoTagException
            label='Names Not Unique'
            items={nameExistsCargoTags}
            itemToString={NotUniqueToStr}
          />

          {!assignedCargoTags.length &&
            !incorrectNames.length &&
            !nameExistsCargoTags.length && (
              <div className='upload_csv'>
                <div>
                  <span>Upload cargo tags csv file</span>
                  <input
                    type='file'
                    onChange={onFileChange}
                    accept='text/csv'
                  />
                </div>
                <div>
                  <Button
                    size='md'
                    onClick={downloadExample}
                    className={'download-sample'}
                  >
                    Download File Example
                  </Button>
                </div>
              </div>
            )}
        </ModalBody>
        <ModalFooter>
          {!!assignedCargoTags.length && (
            <Button
              color='secondary'
              onClick={() => {
                onUpload(true)
              }}
              disabled={!file || isLoading}
            >
              {isLoading ? <Spinner size='sm' color='light' /> : 'Reasign All'}
            </Button>
          )}

          {!assignedCargoTags.length &&
            !incorrectNames.length &&
            !nameExistsCargoTags.length && (
              <Button
                color='primary'
                onClick={() => {
                  onUpload()
                }}
                disabled={!file || isLoading}
              >
                {isLoading ? <Spinner size='sm' color='light' /> : 'Ok'}
              </Button>
            )}

          <Button
            color='danger'
            onClick={toggleModalVisability}
            disabled={isLoading}
          >
            {'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <InfoModal
        open={uploadStatus.success}
        message={uploadStatus.message}
        header='CSV Upload'
        onConfirm={() => {
          setUploadStatus(defaultUploadStatus)
        }}
      />
    </>
  )
}

export default UploadCargoTagModal
