import React from 'react'
import RivataDropdown from '../../../components/RivataDropdown'
import CargoTagsSelect from './CargoTagsSelect'
import DateTimePicker from '../../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../../enums'
import moment from 'moment'
import { Label } from 'reactstrap'
import { CustomInput } from 'reactstrap'

type Props = {
  disabled: boolean
  dateFrom: Date
  dateTo: Date
  assetsOptions: Array<{
    id: number
    name: string
    vin: string
    asset_type: string
  }>
  vinList: string[]
  locations: string[]
  location: string
  selectedCargoTags: Array<{ id: number; mac: number; name: string }>
  showUnassignedTagsOnly: boolean
  onDateRangeSelect: (from: Date, to: Date) => void
  setSelectedCargoTags: React.Dispatch<React.SetStateAction<Array<Object>>>
  setLocation: React.Dispatch<React.SetStateAction<Array<string>>>
  setShowUnassignedTagsOnly: React.Dispatch<React.SetStateAction<boolean>>
}

const TagHistoryDetailsReportForm: React.FC<Props> = ({
  disabled,
  dateFrom,
  dateTo,
  locations,
  location,
  selectedCargoTags,
  showUnassignedTagsOnly,
  onDateRangeSelect,
  setSelectedCargoTags,
  setLocation,
  setShowUnassignedTagsOnly,
}) => {
  return (
    <>
      <div
        className='d-flex flex-row align-items-start mb-3'
        style={{ minWidth: '300px' }}
      >
        <CustomInput
          type='switch'
          id='unassignedTagsOnly'
          label='Unassigned Tags'
          checked={showUnassignedTagsOnly}
          onChange={() => setShowUnassignedTagsOnly((prev) => !prev)}
        />
      </div>
      <div className='mb-2'>Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom.toISOString()}
        dateTo={dateTo.toISOString()}
        onDateRangeSelect={onDateRangeSelect}
        maxDate={moment().toDate()}
        disabled={disabled}
      />

      {locations.length > 1 && (
        <div
          className='d-flex flex-row align-items-start mt-3'
          style={{ minWidth: '300px', marginBottom: '-1rem' }}
        >
          <label className='mb-0 mt-2' style={{ minWidth: '120px' }}>
            Select Location:
          </label>

          <RivataDropdown
            caret={true}
            items={locations}
            selected={location}
            onSelect={setLocation}
            buttonLabel={null}
            filters={null}
            onRemove={null}
            disabled={disabled}
            color={'secondary'}
          />
        </div>
      )}

      <CargoTagsSelect
        selectedCargoTags={selectedCargoTags}
        setSelectedCargoTags={setSelectedCargoTags}
        disabled={disabled}
      />
    </>
  )
}

export default TagHistoryDetailsReportForm
