import { isPlainObject, isInteger, isNumber, startCase } from 'lodash'
import moment from 'moment-timezone'
import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'
import { store } from '../redux/store'
import {
  PhoneCountryCodes,
  ScheduledReportTypes,
  NoDataLabels,
  WhiteLabelFilenames,
  HealthStatus,
} from '../enums'
import { INSTALLMENT_POSITION_TYPE } from '../constants'
import { UnitsOfMeasurementConfig } from '../constants/constants'
import {
  AxleLoadWarningTypes,
  AxlesGroups,
  LinePressureWarningTypes,
  SmartHubWarningTypes,
  TpmsWarningTypes,
} from '../constants/constants'
import defaultWhiteLabelConfig from '../redux/whitelabel/defaultConfig'
import { getStatusFromState } from '../utils/utils'
import React from 'react'

export const TIME_FORMAT = 'YYYY MM DD'
export const LABEL_TIME_FORMAT = 'MMM D'
export const DATE_FORMAT = 'MM/DD/YYYY hh:mm A'
export const ONLY_DATE = 'MM/DD/YYYY'
export const ONLY_TIME = 'hh:mm A'
export const DATE_FORMAT_ZONE = 'MM/DD/YYYY h:mm a z'
export const DATE_FORMAT_WITH_SECONDS = 'MM/DD/YYYY hh:mm:ss'

export const fahrToCelsius = (fahrein) => ((fahrein - 32) * 5.0) / 9
export const celsiusToFahr = (celsius) => (celsius * 9) / 5.0 + 32
export const psiToBar = (psi) => {
  if (psi === 0) return psi
  const bar = psi * 0.0689475729
  if (Number.isNaN(bar) || bar === 0) return ''
  return bar
}
export const barToPsi = (bar) => bar / 0.0689475729
export const kmhToMph = (kmh) => kmh * 0.621371
export const mphToKmh = (mph) => mph * 1.60934
export const metersToFt = (m) => m * 3.2808
export const ftToMeters = (ft) => ft * 0.3048
export const kgToLbs = (kg) => kg * 2.2046226218
export const lbsToKg = (lbs) => lbs * 0.45359237
export const metersToKmh = (m) => m / 1000
export const metersToMph = (m) => m * 0.000621371192
export const kilometersToMeters = (km) => km * 1000
export const milesToMeters = (ml) => ml * 1609.344

export const getMileage = (mileage, unitsOfMeasurementConfig) => {
  let result = 0

  if (
    unitsOfMeasurementConfig?.distance === UnitsOfMeasurementConfig.distance.km
  ) {
    result = metersToKmh(mileage)
  } else {
    result = metersToMph(mileage)
  }

  return result
}

const convertDataArrEpochToDate = (
  rootData,
  epochKey,
  outputDateKey = 'datetime',
) => {
  return rootData.map((data) => {
    return convertDataObjEpochToDate(data, epochKey, outputDateKey)
  })
}

const convertDataObjEpochToDate = (
  data,
  epochKey,
  outputDateKey = 'datetime',
) => {
  const datetime = data[epochKey]
    ? moment
        .unix(data[epochKey])
        .tz(store.getState().auth.preferences.timezone || 'America/Los_Angeles')
    : null

  data[outputDateKey] = datetime
  data[`formatted_${outputDateKey}`] = datetime
    ? datetime.format(DATE_FORMAT)
    : NoDataLabels.DASH

  return data
}

const convertEpochToDate = (
  epoch,
  shouldFormat,
  addBreak,
  convertToLocalDate = false,
  showOnlyDate,
) => {
  let datetime
  if (convertToLocalDate) {
    datetime = moment.unix(epoch).local()
  } else {
    datetime = moment
      .unix(epoch)
      .tz(store.getState().auth.preferences.timezone || 'America/Los_Angeles')
  }

  if (addBreak) {
    return `${datetime.format(ONLY_DATE)} <br/> ${datetime.format(ONLY_TIME)}`
  }

  if (shouldFormat) {
    return datetime.format(DATE_FORMAT)
  }
  if (showOnlyDate) {
    return datetime.format(ONLY_DATE)
  }

  return datetime
}

export const convertDataEpochToDate = (
  data,
  epochKey,
  outputDateKey,
  shouldFormat,
  addBreak,
  toLocalDate = false,
  showOnlyDate,
) => {
  if (Array.isArray(data)) {
    return convertDataArrEpochToDate(data, epochKey, outputDateKey)
  } else if (typeof data === 'object') {
    return convertDataObjEpochToDate(data, epochKey, outputDateKey)
  } else if (typeof data === 'number') {
    return convertEpochToDate(
      data,
      shouldFormat,
      addBreak,
      toLocalDate,
      showOnlyDate,
    )
  }
}

export const dateToEpoch = (
  dateFrom = new Date(),
  dateTo = new Date(),
  startTime = '00:00',
  endTime = '23:59:59',
) => {
  const timezone =
    store.getState().auth.preferences.timezone || 'America/Los_Angeles'
  const startDate = moment
    .tz(`${moment(dateFrom).format('YYYY-MM-DD')} ${startTime}`, timezone)
    .unix()
  const endDate = moment
    .tz(`${moment(dateTo).format('YYYY-MM-DD')} ${endTime}`, timezone)
    .unix()

  return {
    startDate,
    endDate,
  }
}

export const getClearLabel = (str) => {
  if (!str) {
    return null
  }

  let label = str.toLowerCase().split('_').join(' ')
  label = label.split('')

  label[0] = label[0].toUpperCase()

  label.map((char, i) => {
    if (char === ' ') {
      label[i + 1] = label[i + 1].toUpperCase()
    }
    return char
  })
  let res = label.join('')

  if (res.includes('Tpms')) {
    res = res.replace('Tpms', 'TPMS')
  }
  return res
}

export const getColor = (label, graphColors) => {
  const el = graphColors.find((item) => {
    if (item.label === label) {
      return true
    }
    return false
  })

  if (el) {
    return el.color
  } else {
    // random color
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }
}

export const getWhitelabelColors = (healthColors = []) =>
  healthColors.length
    ? {
        healthGood: healthColors[0].color,
        healthWarning: healthColors[1].color,
        healthCriticalWarning: healthColors[2].color,
      }
    : null

export const getColdInflationFromPercent = (coldInflation, percent) => {
  return coldInflation + (coldInflation * percent) / 100
}

export const getThresholdsByAssignedProfile = (
  pressureUnit,
  cold_inflation_pressure_in_psi,
  tpmsThresholds,
  profileDefaultPressureInPsi,
) => {
  let cold_presure_in_psi =
    cold_inflation_pressure_in_psi || profileDefaultPressureInPsi
  let cold_presure_in_bar =
    psiToBar(cold_inflation_pressure_in_psi) ||
    psiToBar(profileDefaultPressureInPsi)

  return {
    critical_low_pressure:
      pressureUnit === UnitsOfMeasurementConfig.pressure.psi
        ? getColdInflationFromPercent(
            cold_presure_in_psi,
            tpmsThresholds.critical_low_pressure_in_percent,
          )
        : getColdInflationFromPercent(
            cold_presure_in_bar,
            tpmsThresholds.critical_low_pressure_in_percent,
          ),
    critical_over_pressure:
      pressureUnit === UnitsOfMeasurementConfig.pressure.psi
        ? getColdInflationFromPercent(
            cold_presure_in_psi,
            tpmsThresholds.critical_over_pressure_in_percent,
          )
        : getColdInflationFromPercent(
            cold_presure_in_bar,
            tpmsThresholds.critical_over_pressure_in_percent,
          ),
    low_pressure:
      pressureUnit === UnitsOfMeasurementConfig.pressure.psi
        ? getColdInflationFromPercent(
            cold_presure_in_psi,
            tpmsThresholds.low_pressure_in_percent,
          )
        : getColdInflationFromPercent(
            cold_presure_in_bar,
            tpmsThresholds.low_pressure_in_percent,
          ),
    over_pressure:
      pressureUnit === UnitsOfMeasurementConfig.pressure.psi
        ? getColdInflationFromPercent(
            cold_presure_in_psi,
            tpmsThresholds.over_pressure_in_percent,
          )
        : getColdInflationFromPercent(
            cold_presure_in_bar,
            tpmsThresholds.over_pressure_in_percent,
          ),
  }
}

export const getTpmsTirePressure = (value, pressureUnit) => {
  let label = ''
  if (value === '' || value < 0) {
    label = NoDataLabels.DASH
  } else if (pressureUnit === UnitsOfMeasurementConfig.pressure.psi) {
    if (isInteger(value)) {
      label = value
    } else if (isNumber(value)) {
      label = parseFloat(value.toFixed(2))
    } else {
      label = value
    }
  } else if (pressureUnit === UnitsOfMeasurementConfig.pressure.bar) {
    if (value === 0) {
      label = value
    } else if (isNumber(value)) {
      label = parseFloat(psiToBar(value).toFixed(2))
    } else {
      label = value
    }
  } else {
    label = NoDataLabels.DASH
  }

  return label
}

export const getTpmsTemperature = (value, temperatureUnit) => {
  if (
    temperatureUnit === UnitsOfMeasurementConfig.temperature.fahrenheit &&
    isNumber(value)
  )
    return `${celsiusToFahr(value).toFixed(1)} °F`

  if (
    temperatureUnit === UnitsOfMeasurementConfig.temperature.celsius &&
    isNumber(value)
  )
    return `${value.toFixed(1)} °C`

  return NoDataLabels.DASH
}

export const getSensorFromSensorType = (sensorType) => {
  if (!sensorType) {
    return NoDataLabels.DASH
  }

  if (sensorType === 'gateway') {
    return 'Gateway'
  } else if (sensorType === 'tire_sensor') {
    return 'TPMS'
  } else if (sensorType === 'hub_sensor') {
    return 'SmartHub'
  } else if (sensorType === 'axle_load') {
    return 'Axle load'
  } else if (sensorType === 'line_pressure') {
    return 'Line Pressure'
  }
  return 'Others'
}

export const getClearWheelPosition = (wheelData) => {
  const clearData = []
  wheelData.map((item) => {
    const positions = item.label.split(' OR ')
    const clearObj1 = { ...item, label: positions[0] }
    const clearObj2 = { ...item, label: positions[1] }
    if (positions[1]) {
      clearData.push(clearObj2)
    }
    return clearData.push(clearObj1)
  })

  return clearData
}

export const upperCaseFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const getErrorObj = (
  err = { statusCode: 0, message: '' },
  customMessage,
) => ({
  statusCode: err.statusCode,
  message: customMessage ? customMessage : err.message.toUpperCase(),
  ok: false,
})

export const getActualConfig = (data) => {
  if (!data) return defaultWhiteLabelConfig
  const config = {}
  const configKeys = Object.keys(defaultWhiteLabelConfig)

  configKeys.map((key) => {
    if (isPlainObject(defaultWhiteLabelConfig[key])) {
      const newObj = {}
      if (data[key] !== defaultWhiteLabelConfig[key]) {
        const preferenceKeys = Object.keys(defaultWhiteLabelConfig[key])
        preferenceKeys.map((prefKey) => {
          if (data[key] && data[key][prefKey]) {
            newObj[prefKey] = data[key][prefKey]
          } else {
            newObj[prefKey] = defaultWhiteLabelConfig[key][prefKey]
          }
          return prefKey
        })
      }
      return (config[key] = newObj)
    }
    if (Array.isArray(defaultWhiteLabelConfig[key])) {
      const newComponents = []
      config[key] = []
      if (key.includes('Colors')) {
        config[key] = data[key]
        return key
      }
      defaultWhiteLabelConfig[key].map((item, i) => {
        const elementIdx = data[key].findIndex((el) =>
          el.id === item.id ? true : false,
        )
        if (elementIdx > -1) {
          config[key][elementIdx] = data[key][elementIdx]
        } else {
          newComponents.push({ idx: i, item })
        }
        return item
      })

      if (newComponents.length) {
        for (let i = 0; i < newComponents.length; i++) {
          config[key].splice(newComponents[i].idx, 0, newComponents[i].item)
        }
      }
    }
    return key
  })

  return config
}

export const composeTpmsProfiles = (warningSettings) => {
  const tractorValidAxles = [
    INSTALLMENT_POSITION_TYPE.TRACTOR_STEER,
    INSTALLMENT_POSITION_TYPE.TRACTOR_FRONT,
    INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_1,
    INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_2,
    INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_3,
  ]

  const trailerValidAxles = [
    INSTALLMENT_POSITION_TYPE.TRAILER_1,
    INSTALLMENT_POSITION_TYPE.TRAILER_2,
    INSTALLMENT_POSITION_TYPE.TRAILER_3,
    INSTALLMENT_POSITION_TYPE.TRAILER_4,
    INSTALLMENT_POSITION_TYPE.TRAILER_5,
    INSTALLMENT_POSITION_TYPE.TRAILER_6,
  ]

  const profiles = []
  Object.keys(warningSettings).map((key) => {
    const s = warningSettings[key]
    const tpmsProfile = s.settings.find(
      (c) => c.installment_position_type_id == null,
    )?.profile
    const axlesSettings = s.settings
      .filter((c) => c.installment_position_type_id !== null)
      .sort((c) => c.installment_position_type_id)
      .reverse()

    const tractorAxles = axlesSettings.filter((c) =>
      tractorValidAxles.includes(c.installment_position_type_id),
    )
    const trailerAxles = axlesSettings.filter((c) =>
      trailerValidAxles.includes(c.installment_position_type_id),
    )

    let profile = {
      id: key,
      settings_id: key,
      label: s.name,
      name: s.name,
      low_pressure_in_percent: tpmsProfile.low_pressure_in_percent,
      critical_low_pressure_in_percent:
        tpmsProfile.critical_low_pressure_in_percent,
      over_pressure_in_percent: tpmsProfile.over_pressure_in_percent,
      critical_over_pressure_in_percent:
        tpmsProfile.critical_over_pressure_in_percent,
      over_temperature_degrees_in_f: tpmsProfile.over_temperature_degrees_in_f,
      axels: {
        tractor: {
          tractor_axle_1: '',
          tractor_axle_2: '',
          tractor_axle_3: '',
          tractor_axle_4: '',
          tractor_axle_5: '',
        },
        trailer: {
          trailer_axle_1: '',
          trailer_axle_2: '',
          trailer_axle_3: '',
          trailer_axle_4: '',
          trailer_axle_5: '',
          trailer_axle_6: '',
        },
        tractorViewAxlesCount: tractorAxles.length,
        trailerViewAxlesCount: trailerAxles.length,
        hasTractor: tractorAxles.length > 0 ? true : false,
        hasTrailer: trailerAxles.length > 0 ? true : false,
      },
    }

    tractorAxles.forEach((element) => {
      const coldInflationPressure =
        element?.profile.cold_inflation_pressure_in_psi || 0
      switch (element.installment_position_type_id) {
        case INSTALLMENT_POSITION_TYPE.TRACTOR_STEER:
          profile.axels.tractor.tractor_axle_1 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRACTOR_FRONT:
          profile.axels.tractor.tractor_axle_2 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_1:
          profile.axels.tractor.tractor_axle_3 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_2:
          profile.axels.tractor.tractor_axle_4 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_3:
          profile.axels.tractor.tractor_axle_5 = coldInflationPressure
          break
        default:
          break
      }
    })

    trailerAxles.forEach((element) => {
      const coldInflationPressure =
        element?.profile.cold_inflation_pressure_in_psi || 0
      switch (element.installment_position_type_id) {
        case INSTALLMENT_POSITION_TYPE.TRAILER_1:
          profile.axels.trailer.trailer_axle_1 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRAILER_2:
          profile.axels.trailer.trailer_axle_2 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRAILER_3:
          profile.axels.trailer.trailer_axle_3 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRAILER_4:
          profile.axels.trailer.trailer_axle_4 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRAILER_5:
          profile.axels.trailer.trailer_axle_5 = coldInflationPressure
          break
        case INSTALLMENT_POSITION_TYPE.TRAILER_6:
          profile.axels.trailer.trailer_axle_6 = coldInflationPressure
          break
        default:
          break
      }
    })
    return profiles.push(profile)
  })

  return profiles
}

export const getCurrentEpoch = () => {
  return moment()
    .tz(store.getState().auth.preferences.timezone || 'America/Los_Angeles')
    .unix()
}

export const sortByAlphabet = (data, sortKey) =>
  data.sort((a, b) =>
    a[sortKey].localeCompare(b[sortKey], 'en', { sensitivity: 'base' }),
  )

export const containsOnlyNumbers = (string) => /^\d+$/.test(string)

export const removeSpecialCharacters = (string = '') =>
  string.replace(/[^a-zA-Z0-9]/g, '')

export const isHexFormat = (string = '') => !/[^a-fA-F0-9]/g.test(string)

export const composeLocationFilterOptions = (sensor_info) => {
  const { smarthub_sensors, line_pressure, tpms } = sensor_info
  let sensorInfo = []

  if (smarthub_sensors) {
    if (isPlainObject(smarthub_sensors)) {
      Object.keys(smarthub_sensors).forEach((key) => {
        sensorInfo.push({ mac: smarthub_sensors[key], position: key })
      })
    } else {
      sensorInfo = [...smarthub_sensors]
    }
  }
  if (tpms) {
    if (isPlainObject(tpms)) {
      Object.keys(tpms).forEach((key) => {
        sensorInfo.push({ mac: tpms[key], position: key })
      })
    } else {
      sensorInfo = [...sensorInfo, ...tpms]
    }
  }
  if (line_pressure) {
    if (isPlainObject(line_pressure)) {
      Object.keys(line_pressure).forEach((key) => {
        sensorInfo.push({ mac: line_pressure[key], position: 'CHASSIS' })
      })
    } else {
      sensorInfo = [...sensorInfo, ...tpms, ...line_pressure]
    }
  }
  return sensorInfo
}

export const getTimeLabelFromSeconds = (seconds) => {
  let days = Math.floor(seconds / (3600 * 24))
  seconds -= days * 3600 * 24

  let hours = Math.floor(seconds / 3600)
  seconds -= hours * 3600

  let minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60

  let timeLabel = ''

  if (seconds) {
    let secondsText = seconds === 1 ? ' second' : ' seconds'
    timeLabel = seconds + secondsText
  }

  if (minutes) {
    let minutesText = minutes === 1 ? ' minute, ' : ' minutes, '
    timeLabel = minutes + minutesText + timeLabel
  }

  if (hours) {
    let hoursText = hours === 1 ? ' hour, ' : ' hours, '
    timeLabel = hours + hoursText + timeLabel
  }

  if (days) {
    let daysText = days === 1 ? ' day, ' : ' days, '
    timeLabel = days + daysText + timeLabel
  }

  return timeLabel.replace(/,\s*$/, '').trim()
}

export const getProfilePsiLevel = (position) => {
  const tpms = store.getState().common.customerDefaults.tpms

  if (tpms) {
    const { cold_inflation_pressure_in_psi, thresholdsPerAxle } = tpms

    if (thresholdsPerAxle) {
      for (const key in AxlesGroups) {
        if (AxlesGroups[key].includes(getClearLabel(position))) {
          const thresholds = thresholdsPerAxle.find((el) => el.axle === key)
          if (thresholds) {
            return thresholds.pressure_value
          }
        }
      }
    }

    return cold_inflation_pressure_in_psi
  }
}

export const parseSecondsToDateParts = (seconds) => {
  const dayInSeconds = 86400
  const hourInSeconds = 3600
  const minuteInSeconds = 60

  const days = Math.floor(seconds / dayInSeconds)
  seconds -= days * dayInSeconds
  const hours = Math.floor(seconds / hourInSeconds)
  seconds -= hours * hourInSeconds
  const minutes = Math.floor(seconds / minuteInSeconds)
  seconds -= minutes * minuteInSeconds

  const fDays = `${days} ${days > 1 ? 'days' : 'day'}`
  const fHours = String(hours).padStart(2, '0')
  const fMinutes = String(minutes).padStart(2, '0')
  const fSeconds = String(seconds).padStart(2, '0')

  return {
    formatted: { fDays, fHours, fMinutes, fSeconds },
    parsed: { days, hours, minutes, seconds },
  }
}

export const isValidCoord = function (value) {
  if (typeof value !== 'number' || Math.floor(value) === value) return false
  const decimals = value.toString().split('.')[1].length || 0
  return decimals > 2
}

export const validatePhone = (phone) => {
  if (!phone || phone.length === 0) return { isValid: true, errorMessage: '' }

  let message = 'Invalid phone number'
  if (isPossiblePhoneNumber(phone)) {
    message = 'Not allowed phone code'
    const countryCallingCode = `+${parsePhoneNumber(phone).countryCallingCode}`
    if (
      PhoneCountryCodes.findIndex((e) => e.code === countryCallingCode) >= 0
    ) {
      return { isValid: true, errorMessage: '' }
    }
  }

  return { isValid: false, errorMessage: message }
}

export const fixInValidPhoneNumber = (value) => {
  if (!value || !validatePhone(value).isValid) return ''
  return value
}

export const formatRole = (role) => {
  return role.replace('_', ' ')
}

export const serializeRole = (role) => {
  return role.replace(' ', '_')
}

export const formatType = (type) => {
  if (type === ScheduledReportTypes.TPMS_REPORT) {
    return 'TPMS Report'
  }

  let t = type.replaceAll('_', ' ').toLowerCase()
  return startCase(t)
}

export const nameToCapitalizedLabel = (name) => {
  if (!name) {
    return ''
  }

  return name.charAt(0).toUpperCase() + name.slice(1)
}

const getSmartHubTooltip = (status, warningState) => {
  if (status === HealthStatus.noStatus)
    return 'No status on one or more sensors'

  const getTooltipBody = (className, label) => (
    <>
      <span className={className} />
      {label}
    </>
  )

  let vibClassName = 'status-circle mr-1'
  let vibLabel = ''

  if (warningState.smartHub.vibration.critWarn) {
    vibClassName += ' status-circle--critical-warning'
    vibLabel = 'Vibration: Active High Severity Warning'
  } else if (warningState.smartHub.vibration.warn) {
    vibClassName += ' status-circle--warning'
    vibLabel = 'Vibration: Active Warning'
  } else {
    vibClassName += ' status-circle--good'
    vibLabel = 'Vibration: No Active Warning'
  }

  let tempClassName = 'status-circle mr-1'
  let tempLabel = ''

  if (warningState.smartHub.temperature.critWarn) {
    tempClassName += ' status-circle--critical-warning'
    tempLabel = 'Temperature: Active Critical Warning'
  } else if (warningState.smartHub.temperature.warn) {
    tempClassName += ' status-circle--warning'
    tempLabel = 'Temperature: Active Warning'
  } else {
    tempClassName += ' status-circle--good'
    tempLabel = 'Temperature: No Active Warning'
  }

  const vibrationTooltip = getTooltipBody(vibClassName, vibLabel)
  const temperatureTooltip = getTooltipBody(tempClassName, tempLabel)

  return (
    <div style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
      {vibrationTooltip} <br />
      {temperatureTooltip}
    </div>
  )
}

export const getIconsStatusData = (whiteLabelUrl, isSuperAdmin, asset) => {
  const currentDate = getCurrentEpoch()
  const smartHubSrc = `${whiteLabelUrl}${
    WhiteLabelFilenames.smarthubHealthSvg
  }?r=${+new Date()}`
  const tpmsSrc = `${whiteLabelUrl}${
    WhiteLabelFilenames.tpmsHealthSvg
  }?r=${+new Date()}`
  const linePressureSrc = `${whiteLabelUrl}${
    WhiteLabelFilenames.linePressureHealthSvg
  }?r=${+new Date()}`
  const axleLoadSrc = `${whiteLabelUrl}${
    WhiteLabelFilenames.axleLoadHealthSvg
  }?r=${+new Date()}`

  const warningState = getStatusFromState(asset.warning_state)
  let assetWarnings = []

  if (asset.warnings) {
    assetWarnings = asset.warnings.split(';')
  }

  const sensorInfo = asset.sensor_info
  const subscription = asset.subscription
  const src = []
  const alt = []
  const styles = []
  const tooltips = []
  const counts = []

  const pushIcon = (iconType, source, style, tooltip, count) => {
    src.push(source)
    alt.push(iconType)
    styles.push(style)
    tooltips.push(tooltip)
    counts.push(count)
  }

  const getIconStyle = (state) => {
    if (state.critWarn) return HealthStatus.criticalWarning
    if (state.warn) return HealthStatus.warning
    if (state.noStatus) return HealthStatus.noStatus

    return HealthStatus.good
  }

  if (
    sensorInfo?.smarthub_sensors &&
    ((subscription?.HUB_SENSOR &&
      currentDate <= subscription.HUB_SENSOR.valid_to) ||
      isSuperAdmin)
  ) {
    const status = getIconStyle(warningState.smartHub)
    const tooltipContent = getSmartHubTooltip(status, warningState)

    let smartHubWarnings = []
    smartHubWarnings = assetWarnings.filter((w) =>
      SmartHubWarningTypes.includes(w),
    )

    pushIcon(
      'smarthub',
      smartHubSrc,
      status,
      tooltipContent,
      smartHubWarnings.length,
    )
  }

  if (
    sensorInfo?.tpms &&
    ((subscription?.TIRE_SENSOR &&
      currentDate <= subscription.TIRE_SENSOR.valid_to) ||
      isSuperAdmin)
  ) {
    const status = getIconStyle(warningState.tpms)

    let tooltipContent
    switch (status) {
      case HealthStatus.good:
        tooltipContent = 'No active TPMS warnings'
        break
      case HealthStatus.warning:
        tooltipContent = 'Active TPMS warning'
        break
      case HealthStatus.criticalWarning:
        tooltipContent = 'Active TPMS critical warning'
        break
      case HealthStatus.noStatus:
      default:
        tooltipContent = 'No status on one or more sensors'
        break
    }

    let tpmsWarnings = []
    tpmsWarnings = assetWarnings.filter((w) => TpmsWarningTypes.includes(w))

    pushIcon('tpms', tpmsSrc, status, tooltipContent, tpmsWarnings.length)
  }

  if (
    sensorInfo?.line_pressure &&
    ((subscription?.LINE_PRESSURE &&
      currentDate <= subscription.LINE_PRESSURE.valid_to) ||
      isSuperAdmin)
  ) {
    const status = getIconStyle(warningState.linePressure)

    let tooltipContent
    switch (status) {
      case HealthStatus.good:
        tooltipContent = 'No active Line Pressure warnings'
        break
      case HealthStatus.criticalWarning:
        tooltipContent = 'Active Line Pressure warning'
        break
      case HealthStatus.noStatus:
      default:
        tooltipContent = 'No status on Line Pressure sensor'
        break
    }

    let linePressureWarnings = []
    linePressureWarnings = assetWarnings.filter((w) =>
      LinePressureWarningTypes.includes(w),
    )

    pushIcon(
      'line_pressure',
      linePressureSrc,
      status,
      tooltipContent,
      linePressureWarnings.length,
    )
  }

  if (
    sensorInfo?.axle_load &&
    ((subscription?.AXLE_LOAD &&
      currentDate <= subscription.AXLE_LOAD.valid_to) ||
      isSuperAdmin)
  ) {
    const status = getIconStyle(warningState.axleLoad)

    let tooltipContent
    switch (status) {
      case HealthStatus.good:
        tooltipContent = 'No active Axle Load warnings'
        break
      case HealthStatus.noStatus:
      default:
        tooltipContent = 'No status on Axle Load sensor'
        break
    }

    let axleLoadWarnings = []
    axleLoadWarnings = assetWarnings.filter((w) =>
      AxleLoadWarningTypes.includes(w),
    )

    pushIcon(
      'axle_load',
      axleLoadSrc,
      status,
      tooltipContent,
      axleLoadWarnings.length,
    )
  }

  return {
    src,
    alt,
    styles,
    tooltips,
    counts,
  }
}

export const dropdownItemHasChilds = (item, dropdownItems) => {
  return dropdownItems.findIndex((di) => di.parentId === item.id) !== -1
}

export const getDropdownSelectedCount = (selectedItems, dropdownItems) => {
  let selectedSensorItemsCount = 0

  selectedItems.forEach((s) => {
    if (
      s.parentId ||
      (!s.parentId && !dropdownItemHasChilds(s, dropdownItems))
    ) {
      selectedSensorItemsCount++
    }
  })

  return selectedSensorItemsCount
}

export const getDurationFromSeconds = (seconds) => {
  const duration = moment.duration(seconds, 'seconds')

  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const secs = duration.seconds()

  return `${days ? days + ' day(s), ' : ''}${
    hours || days ? hours + ' hour(s), ' : ''
  }${hours || minutes ? minutes + ' minute(s), ' : ''}${
    minutes || secs ? secs + ' second(s)' : '---'
  }`
}

export const getReportEndRuleDate = (sendForMetric, sendFor) => {
  let daysMultiplier = 0
  if (sendForMetric === 'days') {
    daysMultiplier = 1 * sendFor
  } else if (sendForMetric === 'weeks') {
    daysMultiplier = 7 * sendFor
  } else if (sendForMetric === 'months') {
    daysMultiplier = 30 * sendFor
  }
  const value = getCurrentEpoch() + 24 * 60 * 60 * daysMultiplier
  return value
}

export const validateEmail = (email) => {
  if (!email || email.length === 0) return { isValid: true, errorMessage: '' }

  let message = 'Invalid email address'
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (emailRegex.test(email)) {
    return { isValid: true, errorMessage: '' }
  }

  return { isValid: false, errorMessage: message }
}
