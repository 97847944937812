import React from 'react'
import momentTz from 'moment-timezone'
import { Row } from 'reactstrap'

import CreateModal, { SelectFormField } from '../../../components/CreateModal/children'
import CompanySelector from '../components/CompanySelector'

import { Timezones, RolePermission } from '../../../enums'
import { IUserFormValues, IValidate } from '../types'
import UserSchema from './validationSchema'

import { useCustomerOptionsCreate } from '../hooks'
import { FormField, PhoneNumberField } from '../../../components/CreateModal'
import { formatRole } from '../../../utils'

interface IDefaultList {
    [key: string]: string
}

const tz: IDefaultList = Timezones
const tzOptions = Object.keys(tz).map(k => ({
    value: tz[k],
    label: `${momentTz.tz(tz[k]).format("Z")} ${tz[k]}`
}))

const roles: IDefaultList = RolePermission
const roleOptions = Object.keys(roles).map(k => ({
    value: roles[k],
    label: formatRole(roles[k])
}))

interface ICreateUserModal {
    locale: ILocale,
    title: string,
    initialValues: IUserFormValues,
    selectedCustomersList: Array<ICustomer>,
    isSuperAdmin: boolean,
    isAdmin: boolean,
    userCustomerIds: Array<number>,
    open: boolean,
    customersList: Array<ICustomer>,
    onClose: () => void,
    onSubmit: (data: IUserFormValues) => any,
    validate: IValidate | null,
    resetFormValues: () => void,
    setFormValues: (data: IUserFormValues) => void
}

const CreateUserModal: React.FC<ICreateUserModal> = ({
    locale,
    title,
    initialValues,
    selectedCustomersList,
    isSuperAdmin,
    isAdmin,
    userCustomerIds,
    open,
    customersList,
    onClose,
    onSubmit,
    validate,
    resetFormValues,
    setFormValues
}) => {
    const customerOption = useCustomerOptionsCreate(selectedCustomersList, isSuperAdmin, isAdmin, userCustomerIds, open, customersList)

    const handleSetPermissions = (ids: Array<number> | null) => {
        const formData = { ...initialValues }
        
        if (ids) {
            ids.forEach((id) => {
                const idx = formData.customerIds.findIndex(el => el === id ? true : false)
                if (idx > -1) {
                    formData.customerIds.splice(idx, 1)
                } else {
                    formData.customerIds.push(id)
                }
            })
        }
        
        setFormValues(formData)
    }

    return (
        <CreateModal
            disabled={false}
            btnClassName="ml-3"
            onCreate={onSubmit}
            createBtnLabel={title}
            openBtnLabel={`${title} User`}
            header={`${title} User`}
            locale={locale}
            schema={UserSchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
            validate={validate}
            resetFormValues={resetFormValues}
            footerBtnDisabled={!initialValues.customerIds.length}
        >
            <Row>
                <FormField name="username" label="Username" />
                <FormField name="email_address" type="email" label="Email" />
                <FormField name="first_name" label="First Name" />
                <FormField name="last_name" label="Last Name" />
                <PhoneNumberField name="phone_number" label="Mobile Number" />
                <SelectFormField name="timezone" label="Time Zone" options={tzOptions} />
                <SelectFormField name="role" label="Role" options={roleOptions}/>
                {(userCustomerIds && userCustomerIds.length > 1) || isSuperAdmin ? (
                    <CompanySelector
                        options={customerOption}
                        setPermissions={handleSetPermissions}
                        customerIds={initialValues.customerIds}
                    />
                ) : null}
            </Row>
        </CreateModal>
    )
}

export default CreateUserModal