import React, { useCallback, useMemo } from 'react'
import { Pagination, PaginationLink, PaginationItem } from 'reactstrap'

import './style.scss'

interface Props {
    totalCount: number
    pageLimit: number
    currentPage: number
    setCurrentPage: (page: number) => void
}

const RivataPagination: React.FC<Props> = ({ totalCount, pageLimit, currentPage, setCurrentPage }) =>  {
    const pagesCount = Math.ceil(totalCount / pageLimit)

    const onPageClick = useCallback((incomingPage: number) => {
        if (incomingPage === currentPage || incomingPage < 0 || incomingPage >= pagesCount) return

        setCurrentPage(incomingPage)
    }, [currentPage, pagesCount, setCurrentPage])


    const paginationElements = useMemo(() => {
        const getPageIdx = (el: number | string) => paginationRange.indexOf(el)
        const getRangeArray = (from: number, to: number) => {
            const range = []
            for (let i = from; i <= to; i++) range.push(i)

            return range
        }

        const paginationRange: Array<string | number> = getRangeArray(1, pagesCount)
        let paginationVisibleRange: Array<string | number> = []

        if (pagesCount < 10) {
            paginationVisibleRange = paginationRange
        } else if (currentPage < 4) {
            paginationVisibleRange = [...getRangeArray(1, 6), "...", pagesCount]
        } else if (currentPage >= 4 && currentPage < pagesCount - 4) {
            paginationVisibleRange = [1, "...", ...getRangeArray(currentPage - 1, currentPage + 3), "...", pagesCount]
        } else if (currentPage >= pagesCount - 4) {
            paginationVisibleRange = [1, "...", ...getRangeArray(currentPage - 2, pagesCount)]
        }

        return paginationVisibleRange.map((item, index) => {
            const key = index + 1

            if (typeof item === "number") {
                return (
                    <PaginationItem key={key} active={currentPage === getPageIdx(item)}>
                        <PaginationLink
                            onClick={() => onPageClick(getPageIdx(item))}
                        >
                            {item}
                        </PaginationLink>
                    </PaginationItem>
                )
            } else if (item === "...") {
                const idx = index + (index === 1 ? 1 : -1)

                return (
                    <PaginationItem key={key}>
                        <PaginationLink
                            onClick={() => onPageClick( getPageIdx(paginationVisibleRange[ idx ]) )}
                        >
                            {item}
                        </PaginationLink>
                    </PaginationItem>
                )
            } else {
                return null
            }
        })
    }, [currentPage, pagesCount, onPageClick])

    return (
        <>
            {pagesCount > 0 && (
                <Pagination>
                    <PaginationItem>
                        <PaginationLink previous onClick={() => onPageClick(currentPage - 1)} />
                    </PaginationItem>

                    {paginationElements}

                    <PaginationItem>
                        <PaginationLink next onClick={() => onPageClick(currentPage + 1)} />
                    </PaginationItem>
                </Pagination>
            )}
        </>
    )
}

export default React.memo(RivataPagination)
