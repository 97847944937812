import React, { useCallback, useMemo } from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'
import { EndPointConfigs } from '../../../components/BackendSearchInput/constants'
import HealthStatusFilter from '../../../componentsV2/HealthStatusFilter'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'

type INotificationsSubscriptionsReportForm = {
  disabled: boolean
  selectedUsersItems: Array<SearchItemType>
  setUsernames: (list: Object[]) => void
  notificationTypes: { email: boolean; sms: boolean; push: boolean }
  setNotificationTypes: React.Dispatch<
    React.SetStateAction<{
      email: boolean
      sms: boolean
      push: boolean
    }>
  >
  sersorStatusItemsFiltered: Array<any>
  onWarningItemSelectNew: (id: string, conf: Array<MenuItemType>) => void
}

const geofenceWarningItems = [
  {
    id: 'geofence',
    label: 'Geofence',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: 'geofence_entry',
    label: 'Geofence Entry',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'geofence',
  },
  {
    id: 'geofence_exit',
    label: 'Geofence Exit',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'geofence',
  },
]

const usersColumnsToShow = [
  { key: 'username', name: 'Username' },
  { key: 'first_name', name: 'First Name' },
  { key: 'last_name', name: 'Last Name' },
  { key: 'email', name: 'Email' },
]

const NotificationsSubscriptionsReportForm: React.FC<
  INotificationsSubscriptionsReportForm
> = ({
  disabled,
  selectedUsersItems,
  setUsernames,
  notificationTypes,
  setNotificationTypes,
  sersorStatusItemsFiltered,
  onWarningItemSelectNew,
}) => {
  const onUsersSelect = useCallback(
    (selectedUsers: SearchItemType[]) => {
      setUsernames(selectedUsers)
    },
    [setUsernames],
  )

  const warningItemsWithGeoEvents = useMemo(
    () => [...sersorStatusItemsFiltered, ...geofenceWarningItems],
    [sersorStatusItemsFiltered],
  )

  return (
    <>
      <ItemsSelectWithSearch
        label='Users'
        inputPlaceholder='Search Users'
        endPointConfig={EndPointConfigs.user}
        onItemsSelect={onUsersSelect}
        columnsToShow={usersColumnsToShow}
        disabled={disabled}
        tableWidth='800px'
        selectedItems={selectedUsersItems}
      />

      <div className='mb-2 mt-3'>Notification types</div>
      <div className='d-flex flex-row'>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            id='email'
            checked={notificationTypes.email}
            onChange={() =>
              setNotificationTypes((old) => ({ ...old, email: !old.email }))
            }
          />
          <label className='form-check-label' htmlFor='email'>
            Email
          </label>
        </div>

        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            id='SMS'
            checked={notificationTypes.sms}
            onChange={() =>
              setNotificationTypes((old) => ({ ...old, sms: !old.sms }))
            }
          />
          <label className='form-check-label' htmlFor='SMS'>
            SMS
          </label>
        </div>
      </div>

      <HealthStatusFilter
        id='warning_type_new'
        className='mr-2 mt-3'
        overrideDefaultItems={warningItemsWithGeoEvents}
        onDropdownItemSelect={onWarningItemSelectNew}
        disabled={disabled}
      />
    </>
  )
}

export default NotificationsSubscriptionsReportForm
