import React, { useEffect, useRef, useState } from 'react'
import Chart, { BubbleDataPoint, ChartTypeRegistry, ScatterDataPoint } from 'chart.js/auto';
import { each } from 'chart.js/helpers';
import annotationPlugin from 'chartjs-plugin-annotation'
import { Line } from 'react-chartjs-2'
import zoom from 'chartjs-plugin-zoom'
import 'chartjs-adapter-moment'

import { ILineChartProps } from './interfaces'
import { debounce } from 'lodash';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { getDateRange } from '../../utils/chartUtils';
import { useActions } from '../../hooks/useActions';
import { IChartXRange } from "../../redux/assetDetails/types"
import { composeChartOptions } from './chartOptions';

Chart.register(annotationPlugin, zoom)

function updateChart(triggeredChartId: number | string, min: number, max: number) {
    each(Chart.instances, function (instance) {
        if (
            triggeredChartId !== instance.id && // criggered chart already have required range 
            //@ts-ignore
            instance.config._config.options.isSync
        ) {
            instance.zoomScale("x", { min, max }, "zoom")
        }
    })
}

const debouncer = debounce(f => f(), 200, { leading: true })

export const handleZoomSync = (context: { chart: Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> }) => {
    const { min, max } = context.chart.scales.x

    debouncer(() => updateChart(context.chart.id, min, max))
}

const onDoubleClick = (triggeredId: string, isTriggeredChartSync: boolean) => {
    each(Chart.instances, function (instance) {
        //@ts-ignore
        const id = instance.config._config.options.id
        //@ts-ignore
        const isCurrentChartSynced = instance.config._config.options.isSync

        if (isTriggeredChartSync && isCurrentChartSynced) instance.resetZoom("normal")
        else if (!isTriggeredChartSync && triggeredId === id) instance.resetZoom("normal")
    })
}


const LineChart: React.FC<ILineChartProps> = ({
    id,
    data,
    height = 200,
    isSync = true,
    ignoreMotionAnnotations = false,
    ...props
}) => {
    const chartRef = useRef<any>({})
    const [xRange, setXRange] = useState<IChartXRange>({ min: null, max: null })

    const {
        motionAnnotations,
        chartsXRange
    } = useTypedSelector(state => ({
        motionAnnotations: state.assetDetails.motionAnnotations,
        chartsXRange: state.assetDetails.chartsXRange
    }))
    const { setAssetDetailsChartsXRange } = useActions()

    useEffect(() => {
        //@ts-ignore
        const { min, max } = getDateRange(data.datasets)

        let currentMin = min * 1000
        let currentMax = max * 1000

        if (!isSync) {
            setXRange({ min: currentMin, max: currentMax })
            
            return
        }

        let shouldUpdate = false
        const range = { ...chartsXRange }
        
        if (!range.min || range.min > currentMin) {
            shouldUpdate = true
            range.min = currentMin
        }
        if (!range.max || range.max < currentMax) {
            shouldUpdate = true
            range.max = currentMax
        }

        // shouldUpdate check is required otherwise infinite loop will appear
        if (shouldUpdate) {
            setAssetDetailsChartsXRange(range)
        }

        setXRange(range)
    }, [data, isSync, chartsXRange, setAssetDetailsChartsXRange])

    useEffect(() => {
        return () => {
            // React want to create variable for ref instance in effect but it's always undefined...
            // eslint-disable-next-line react-hooks/exhaustive-deps
            chartRef.current?.destroy()
        }
    }, [chartRef])

    return (
        <div id="line_chart" onDoubleClick={() => onDoubleClick(id, isSync)} style={{ maxHeight: `${height}px` }}>
            <Line
                id={id}
                ref={chartRef}
                data={data}
                height={height}
                options={
                    composeChartOptions({
                        id,
                        xMin: xRange.min, 
                        xMax: xRange.max, 
                        assetMotionAnnotations: ignoreMotionAnnotations ? {} : motionAnnotations,
                        isSync,
                        ...props
                    })
                }
            />
        </div>
    )
}

export default LineChart