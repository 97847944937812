import moment from "moment";
import React from "react";
import AssetsSelect from "./AssetsSelect";
import DateTimePicker from "../../../componentsV2/DateTimePicker";
import { CalendarTypes } from "../../../enums";

type Props = {
  dateFrom: Date;
  dateTo: Date;
  minDate: Date;
  onDateRangeSelect: (from: Date, to: Date) => void;
  timeFrom: string;
  timeTo: string;
  setTimeFrom: React.Dispatch<React.SetStateAction<string>>;
  setTimeTo: React.Dispatch<React.SetStateAction<string>>;
  assetTypes: Array<{ id: string; label: string; disabled: boolean }>;
  selectedAssetType: "tractor" | "trailer" | "all";
  setAssetType: React.Dispatch<React.SetStateAction<string>>;
  selectedVinList: Array<Object>;
  setVinList: React.Dispatch<React.SetStateAction<Array<any>>>;
  disabled: boolean;
};

const DistanceTraveledReportForm: React.FC<Props> = ({
  dateFrom,
  dateTo,
  minDate,
  onDateRangeSelect,
  timeFrom,
  timeTo,
  setTimeFrom,
  setTimeTo,
  assetTypes,
  selectedAssetType,
  setAssetType,
  selectedVinList,
  setVinList,
  disabled,
}) => {
  return (
    <>
      <>
        <div className="mb-2">Select Date Range</div>
        <DateTimePicker
          defaultMode={CalendarTypes.DateRange}
          dateFrom={dateFrom.toString()}
          dateTo={moment(dateTo).minutes(0).toString()}
          onDateRangeSelect={onDateRangeSelect}
          disabled={disabled}
          timeEnabled={true}
          limitToHoursSelection={true}
          minDate={minDate}
        />
      </>

      <AssetsSelect
        assetTypes={assetTypes}
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setAssetType}
        selectedItems={selectedVinList}
        setVinList={setVinList}
        disabled={disabled}
      />
    </>
  );
};

export default DistanceTraveledReportForm;
