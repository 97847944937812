import React from "react";
import { Draggable } from "@hello-pangea/dnd";
import Th from "./Th";
export default ({ control, onCheck, id, checked, label, sortColumn, setSortColumn, sortHandler, customStyle, customInlineStyle, index }) => {

  return (
    <Draggable draggableId={id} index={index}>
      {(draggableProvided,) => (
        <Th control={control} onCheck={onCheck} id={id} checked={checked} label={label}
          sortColumn={sortColumn} setSortColumn={setSortColumn}
          sortHandler={sortHandler} customStyle={customStyle}
          customInlineStyle={customInlineStyle} draggableProps={{
            draggableRef: draggableProvided.innerRef, 
            draggableProps: draggableProvided.draggableProps,
            dragHandleProps: draggableProvided.dragHandleProps
          }}
          />
      )}</Draggable>
  );
};
