import React, { useEffect } from "react"
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import { useTypedSelector } from "../hooks/useTypedSelector";


const withInitialLoadingHandler = (App) => {
    const LoadingHandler = (props) => {
        const cargoTagsSubscription = useTypedSelector(state => state.common.customerDefaults.cargo_tags_subscription)
        const { isDriver, userId } = useTypedSelector(state => ({ 
            isDriver: state.auth.isDriver,
            userId: state.auth.preferences.id
        }))
        const history = useHistory()

        useEffect(() => {
            if (userId === undefined) return

            ReactGA.set({'user_id': userId})

            if (isDriver && history.location.pathname.length > 1) {
                history.push("/")
                return
            }

            // if path === / then it means that it is first loading and not page refresh
            if (cargoTagsSubscription && history.location.pathname.length === 1) {
                history.push("/tag-locations")
            }
        }, [cargoTagsSubscription, history, isDriver, userId])

        return <App {...props} />
    }
  
    return LoadingHandler
}
  
export default withInitialLoadingHandler;