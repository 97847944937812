import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import DropdownItemSelector from '../DropdownItemSelector'
import { MenuItemType } from '../Menu/MenuItem'

import { sensorStatusItems } from './items'

interface Props {
  onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
  className?: string
  disabled?: boolean
  overrideDefaultItems?: MenuItemType[]
  readonly?: boolean
  clearSelectionOnReset?: boolean
  id?: string
  menuClassName?: string
  ignoreParrentFilterCheck?: boolean
}

const parentFilters = [
  'tire',
  'hub',
  'line_pressure',
  'no_status',
  'asset_state',
  'asset_speed',
  'pressure_fast_leak',
  'geofence',
]

const HealthStatusFilter = forwardRef(
  (
    {
      onDropdownItemSelect,
      className,
      disabled,
      overrideDefaultItems,
      readonly,
      clearSelectionOnReset,
      id,
      menuClassName,
      ignoreParrentFilterCheck = false,
    }: Props,
    ref,
  ) => {
    const [selectedItems, setSelectedItems] = useState(
      [] as Array<MenuItemType>,
    )
    const [label, setLabel] = useState('Health Status')
    const [allItems, setAllItems] = useState(sensorStatusItems)

    useEffect(() => {
      if (overrideDefaultItems) {
        setAllItems(overrideDefaultItems)
        const checked = overrideDefaultItems.filter(
          (el) =>
            el.checked &&
            (ignoreParrentFilterCheck ||
              (!ignoreParrentFilterCheck && !parentFilters.includes(el.id))),
        )
        setSelectedItems(checked)
      }
    }, [overrideDefaultItems, ignoreParrentFilterCheck])

    const isNewSelectedValueIds = useRef(true)

    const onItemSelect = useCallback(
      (id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(
          (el) =>
            el.checked &&
            (ignoreParrentFilterCheck ||
              (!ignoreParrentFilterCheck && !parentFilters.includes(el.id))),
        )
        if (onDropdownItemSelect) onDropdownItemSelect(id, checked)
        setSelectedItems(checked)
      },
      [onDropdownItemSelect, ignoreParrentFilterCheck],
    )

    useImperativeHandle(ref, () => ({
      clearSelection() {
        isNewSelectedValueIds.current = false
        setSelectedItems([])
        setAllItems(
          allItems.map((c) => {
            return {
              ...c,
              checked: false,
            }
          }),
        )
      },
    }))

    useEffect(() => {
      setLabel(
        'Health Status' +
          (selectedItems && selectedItems?.length > 0
            ? `: ${selectedItems?.length} selected`
            : ''),
      )
    }, [selectedItems])

    return (
      <DropdownItemSelector
        id={id || 'status_2'}
        filterClassName={`mr-2 ${className ?? ''}`}
        filterBtnLabel={label}
        items={allItems}
        onItemsChange={onItemSelect}
        disabled={disabled ? disabled : false}
        clearSelectionOnReset={clearSelectionOnReset}
        readOnly={readonly}
        menuClassName={menuClassName}
      />
    )
  },
)

export default React.memo(HealthStatusFilter)
