import baseApi from "./BaseApi"

class LogApi {
  postLog = (message: string, log_level: "INFO" | "WARNING" | "ERROR") => {
    return baseApi.post(`rivata/log`, {
      source: "rivata_ui",
      log_level,
      message,
    })
  }
}

const logApi = new LogApi()

export default logApi
