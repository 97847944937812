import React from 'react'
import './style.scss'

export type RivataToggleItemType = {
  id: number | string
  label: string
  isDisabled?: boolean
}

interface Props {
  item1: RivataToggleItemType
  item2: RivataToggleItemType
  selectedId: number | string
  onToggle: (st: RivataToggleItemType) => void
}

const RivataToggle: React.FC<Props> = ({
  item1,
  item2,
  selectedId,
  onToggle,
}) => {
  return (
    <div>
      <div className='rivata-toggle'>
        <span
          className={`${selectedId === item1.id ? 'active' : ''} ${
            item1.isDisabled ? 'disabled' : ''
          }`}
          onClick={() => (!item1.isDisabled ? onToggle(item1) : {})}
        >
          {item1.label}
        </span>
        <span
          className={`${selectedId === item2.id ? 'active' : ''} ${
            item2.isDisabled ? 'disabled' : ''
          }`}
          onClick={() => (!item2.isDisabled ? onToggle(item2) : {})}
        >
          {item2.label}
        </span>
      </div>
    </div>
  )
}

export default RivataToggle
