import { IWhiteLabelColors } from '.'
import { UnitsOfMeasurementConfig } from '../../constants/constants'
import { NoDataLabels } from '../../enums'
import { IMapAsset } from '../../redux/manageGeofences/types'
import { convertDataEpochToDate, kmhToMph, metersToFt } from '../../utils'
import { getGpsDirectinFromDeg } from '../../utils/utils'

export default function getBubbleContent(
  coordinates: { top: number; left: number },
  data: IMapAsset,
  unitsOfMeasurementConfig: any,
  whiteLabelColors: IWhiteLabelColors,
  assetDetails?: {
    name: string
    vin: string
    epoch: number
    gps_details: {
      speed: number
      elevation: string
      heading: number
    }
  },
  isLoading = false,
) {
  const { top, left } = coordinates

  const { latitude, longitude, hasCriticalWarning, hasWarning } = data

  const labelTitle = assetDetails?.name || isLoading ? 'Name:' : 'VIN'

  const label = assetDetails?.name ?? assetDetails?.vin ?? ''

  const datetime = assetDetails?.epoch
    ? convertDataEpochToDate(assetDetails.epoch, null, null, true)
    : ''

  const heading =
    assetDetails?.gps_details.heading !== undefined
      ? getGpsDirectinFromDeg(assetDetails?.gps_details.heading)
      : NoDataLabels.DASH

  const speed =
    !assetDetails?.gps_details.speed || isNaN(assetDetails?.gps_details.speed)
      ? 'NA'
      : unitsOfMeasurementConfig?.speed === UnitsOfMeasurementConfig.speed.mph
      ? kmhToMph(assetDetails?.gps_details.speed).toFixed(1) + 'mph'
      : assetDetails?.gps_details.speed.toFixed(1) + 'kmh'

  const elevation =
    unitsOfMeasurementConfig?.distance ===
    UnitsOfMeasurementConfig.distance.miles
      ? metersToFt(assetDetails?.gps_details.elevation).toFixed(1) + 'ft'
      : assetDetails?.gps_details.elevation + 'm'

  const { healthCriticalWarning, healthWarning, healthGood } = whiteLabelColors
  const labelColor = hasCriticalWarning
    ? healthCriticalWarning
    : hasWarning
    ? healthWarning
    : healthGood

  return `
    <div id="dashboard-buble" style="position: absolute; top: ${top}px; left: ${left}px; padding: 20px">
      <div class="alert-primary show" role="alert" aria-live="assertive" aria-atomic="true" style="background-color: ${labelColor}; border-color: ${labelColor};">
        <div class="label-line"></div>

        <div class="bubble-body">
          <div class="row-wrapper">
            <div class="key">${labelTitle}</div>
            <div class="value">${isLoading ? '' : label}</div>
            ${
              isLoading
                ? `
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  `
                : ''
            }
          </div>

          <div class="row-wrapper">
            <div class="key">Date:</div>
            <div class="value">${isLoading ? '' : datetime}</div>
          </div>

          <div class="row-wrapper">
            <div class="key">Heading:</div>
            <div class="value">${isLoading ? '' : heading}</div>
          </div>

          <div class="row-wrapper">
            <div class="key">Speed:</div>
            <div class="value">${isLoading ? '' : speed}</div>
          </div>

          <div class="row-wrapper">
            <div class="key">Elevation:</div>
            <div class="value">${isLoading ? '' : elevation}</div>
          </div>

          <div class="row-wrapper">
            <div class="key">Location:</div>
            <div class="value">${latitude}, ${longitude}</div>
            <div id="details" class="aligned-cell">Details</div>
          </div>
        </div>
      </div>
    </div>
  `
}
