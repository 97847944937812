import pinOk from '../../assets/images/icons/svg/pin_ok.svg'
import pinWarn from '../../assets/images/icons/svg/pin_warn.svg'
import pinCritWarn from '../../assets/images/icons/svg/pin_crit_warn.svg'
import pinInfo from '../../assets/images/icons/svg/pin_info.svg'
import pinDefault from '../../assets/images/icons/svg/pin_default.svg'
import pinNavyBlue from '../../assets/images/icons/svg/pin_navy_blue.svg'
import circleOk from '../../assets/images/icons/svg/circle_ok.svg'
import circleWarn from '../../assets/images/icons/svg/circle_warn.svg'
import circleInfo from '../../assets/images/icons/svg/circle_warn.svg'
import { store } from '../../redux/store'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

export const healthColors = store.getState().whitelabel.healthColors
const healthOkColor = healthColors.find((el) => el.id === 'HealthGood').color
const healthWarnColor = healthColors.find(
  (el) => el.id === 'HealthWarning',
).color
const healthCritWarnColor = healthColors.find(
  (el) => el.id === 'HealthCriticalWarning',
).color

export const H = window.H || {
  map: {
    Icon: () => {},
  },
}

const getRectangleBbox = (top, left, bottom, right, padding) => {
  const paddingTopBottom = (top - bottom) * padding
  const paddingLeftRight = (left - right) * padding
  const rect = new H.geo.Rect(
    top + paddingTopBottom,
    left + paddingLeftRight,
    bottom - paddingTopBottom,
    right - paddingLeftRight,
  )

  return rect.getBoundingBox()
}

export const bboxAll = (bbox, padding) => {
  const top = bbox.getTop()
  const bottom = bbox.getBottom()
  const right = bbox.getRight()
  const left = bbox.getLeft()

  return getRectangleBbox(top, left, bottom, right, padding)
}

export const bboxLast24 = (markers, padding) => {
  let data = markers
    .map((m) => m.getData())
    .filter((d) => d.timestamp)
    .sort((d1, d2) => +new Date(d2.timestamp) - +new Date(d1.timestamp))

  if (!data.length) {
    return null
  }

  // TODO: need the warning timestamp from the url for this.
  // const locationWarning = data.find(location => location.warningStatus) || data[0];
  const locationWarning = data[0]
  const latestTimestamp = +new Date(locationWarning.timestamp)

  const THRESHOLD_24_HRS_MS = 86400000

  data = data.filter(
    (d) => latestTimestamp - +new Date(d.timestamp) < THRESHOLD_24_HRS_MS,
  )

  const { top, left, bottom, right } = data.reduce((accum, curr) => {
    const { timestamp, latitude, longitude } = curr
    if (
      timestamp &&
      latitude &&
      longitude &&
      latestTimestamp - +new Date(timestamp) <= THRESHOLD_24_HRS_MS
    ) {
      if (!accum.top) {
        // if top is not set, there so are none of the others
        accum.top = latitude
        accum.left = longitude
        accum.bottom = latitude
        accum.right = longitude
      } else if (accum.top < latitude) {
        accum.top = latitude
      } else if (accum.left > longitude) {
        accum.left = longitude
      } else if (accum.bottom > latitude) {
        accum.bottom = latitude
      } else if (accum.right < longitude) {
        accum.right = longitude
      }
    }

    return accum
  }, {})

  return getRectangleBbox(top, left, bottom, right, padding)
}

export const pinSizeRegular = { size: { w: 24, h: 24 } }
export const pinSizeHighlighted = { size: { w: 32, h: 32 } }

export const circleSize = { size: { w: 8, h: 8 } }
export const circleWarnSize = { size: { w: 16, h: 16 } }

export const LayerTypes = {
  NORMAL: 'normal',
  SATELLITE: 'satellite',
}

export const pinMap = {
  ok: new H.map.Icon(pinOk, { ...pinSizeRegular }),
  warn: new H.map.Icon(pinWarn, { ...pinSizeRegular }),
  critWarn: new H.map.Icon(pinCritWarn, { ...pinSizeRegular }),
  info: new H.map.Icon(pinInfo, { ...pinSizeRegular }),
  default: new H.map.Icon(pinDefault, { ...pinSizeRegular }),
  pinNavyBlue: new H.map.Icon(pinNavyBlue, { ...pinSizeRegular }),
  okHiLite: new H.map.Icon(pinOk, { ...pinSizeHighlighted }),
  warnHiLite: new H.map.Icon(pinWarn, { ...pinSizeHighlighted }),
  infoHiLite: new H.map.Icon(pinInfo, { ...pinSizeHighlighted }),
}

export const circleMap = {
  ok: new H.map.Icon(circleOk, { ...circleSize }),
  warn: new H.map.Icon(circleWarn, { ...circleWarnSize }),
  info: new H.map.Icon(circleInfo, { ...circleSize }),
}

export const getMarkerPinMap = (markerType) =>
  markerType === 'circle' ? circleMap : pinMap

export const behaviorUi = (hMap, defaultLayers, unitsOfMeasurementConfig) => {
  // eslint-disable-next-line no-unused-vars
  const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap))
  // eslint-disable-next-line no-unused-vars
  const ui = H.ui.UI.createDefault(hMap, defaultLayers)

  ui.setUnitSystem(
    unitsOfMeasurementConfig.distance === UnitsOfMeasurementConfig.distance.km
      ? H.ui.UnitSystem.METRIC
      : H.ui.UnitSystem.IMPERIAL,
  )

  return { behavior, ui }
}

export const getDistanceFromCoordinates = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distance in km
  return distance
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

export const circleColor = {
  ok: { background: healthOkColor, border: healthOkColor },
  warn: { background: healthWarnColor, border: healthWarnColor },
  critWarn: { background: healthCritWarnColor, border: healthCritWarnColor },
  info: { background: '#fed925', border: '#ffac04' },
  default: { background: '#6fbdf1', border: '#2b8596' }, // bg: #6fbdf1; border:
}

export const getCircleIcon = (
  direction,
  pinKey,
  drawArrow = true,
  number = '',
) => {
  if (direction === 0) direction = 360
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const radius = 32
  const halfRadius = radius / 2
  const diameter = radius * 2
  const containerPadding = 8
  const containerHeight = 42
  canvas.width = radius * 4
  canvas.height = radius * 4

  const color = circleColor[pinKey] || circleColor.default
  const arrowColor = '#ffffff'
  context.globalAlpha = 0.55

  // Rotate circle according to direction
  context.translate(diameter, diameter)
  context.rotate((direction * Math.PI) / 180)
  context.translate(-diameter, -diameter)

  // Draw border
  context.beginPath()
  context.arc(diameter, diameter, radius, 0, 2 * Math.PI)
  context.fillStyle = color.border
  context.fill()

  // Draw circle
  context.beginPath()
  context.arc(diameter, diameter, radius - 2, 0, 2 * Math.PI)
  context.fillStyle = color.background
  context.fill()

  context.globalAlpha = 1
  if (drawArrow && direction) {
    // Draw left side of the arrow
    context.beginPath()
    context.moveTo(diameter, diameter)
    context.lineTo(diameter, halfRadius + radius)
    context.lineTo(halfRadius + radius, halfRadius * 3 + radius)
    context.fillStyle = arrowColor
    context.closePath()
    context.fill()

    // Draw right side of the arrow
    context.beginPath()
    context.moveTo(diameter, diameter)
    context.lineTo(diameter, halfRadius + radius)
    context.lineTo(halfRadius * 3 + radius, halfRadius * 3 + radius)
    context.fillStyle = arrowColor
    context.closePath()
    context.fill()
  }
  // Draw number
  if (number !== '') {
    context.setTransform(1, 0, 0, 1, 0, 0)
    const textWidth = context.measureText(number).width
    const containerWidth = textWidth * 3 + containerPadding * 2
    const containerX = diameter + containerPadding / 2 - containerWidth / 2
    const containerY = -12

    context.fillStyle = '#000000'
    context.fillRect(containerX, containerY, containerWidth, containerHeight)

    context.font = 'bold 32px Arial'
    const textX = containerX + containerPadding / 1.5
    const textY = containerY + containerPadding + 32
    context.fillStyle = '#ffffff'
    context.fillText(number, textX, textY)
  }

  return new H.map.Icon(canvas, {
    size: { w: radius, h: radius },
    anchor: { x: halfRadius, y: halfRadius },
  })
}
