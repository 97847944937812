import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import CustomerRow from '../ReportParametersRows/CustomerRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'
import RangeRow from '../ReportParametersRows/RangeRow'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'

const UtilizationReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      {' '}
      {props.isSchedule && (
        <CustomerRow value={props.parameters.customer_keys} />
      )}
      {props.isSchedule && <TimeZoneRow value={props.parameters.timezone} />}
      {!props.isSchedule && (
        <DateRangeRow
          value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
        />
      )}
      <AssetTypeRow value={props.parameters.asset_type} />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
      {props.isSchedule && <RangeRow value={props.parameters.range} />}
    </>
  )
}
export default UtilizationReportParameters
