import { IRow } from "../../../components/RivataGrid";
import { convertDataEpochToDate } from "../../../utils";

const parseApplicationName = (application: string): string => {
    let parsedName = "Unknown";
    switch (application) {
        case "rivata":
            parsedName = "Digital Dashboard";
            break;
        case "installer_app_web":
            parsedName = "Install Manager (web)";
            break;
        case "installer_app":
            parsedName = "Install Manager";
            break;
        case "driver_app":
            parsedName = "Digital Driver App";
            break;
        default:
            break;
    } 

    return parsedName;
}

export const userLoginsReportRows = (rows: Array<IRow>) => {
    return rows.map(row => {
        return {
            ...row,
            application: parseApplicationName(row.application),
            last_login: convertDataEpochToDate(row.last_login, null, null, true)
        }
    })
}
