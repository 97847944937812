import {
  LOADING_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_ERROR,
  POSTING_WHITELABEL_ADMIN,
  POSTED_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_DATA_DIRTY,
  SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LOGO_ICON_PNG,
  SET_WHITELABEL_ADMIN_LOGO_FULL_PNG,
  SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_GATEWAY_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_AUTH_IMAGE_PNG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_SMARTHUB_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_TPMS_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_LINE_PRESSURE_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_AXLE_LOAD_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_INSIDE_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_OUTSIDE_SVG,
  SET_WHITELABEL_ADMIN_APP_SMARTHUB_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_AXLE_LOAD_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_TPMS_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_LINE_PRESSURE_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_LIGHT_PNG,
  SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_DARK_PNG,
} from '../actionTypes'
import { fetchCustomers } from '../common/action'
import defaultConfig from '../whitelabel/defaultConfig'
import { setWhitelabel } from '../whitelabel/action'
import { WhiteLabelFilenames, WhiteLabelAppFilenames } from '../../enums'
import { getActualConfig } from '../../utils'
import api from '../../services/api'
import { setAuthWhitelabel } from '../auth/action'
import { getStatusObj } from '../../utils/utils'

export const setSmarthubHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG,
  payload: isPosting,
})

export const setTpmsHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG,
  payload: isPosting,
})

export const setLinePressureHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG,
  payload: isPosting,
})

export const setAxleLoadHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG,
  payload: isPosting,
})

export const setGatewayHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_GATEWAY_HEALTH_SVG,
  payload: isPosting,
})

export const setLogoIconPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_LOGO_ICON_PNG,
  payload: isPosting,
})

export const setLogoFullPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_LOGO_FULL_PNG,
  payload: isPosting,
})

export const setFleetSummarySmarthubSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_FLEET_SUMMARY_SMARTHUB_SVG,
  payload: isPosting,
})

export const setFleetSummaryTpmsSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_FLEET_SUMMARY_TPMS_SVG,
  payload: isPosting,
})

export const setFleetSummaryLinePressureSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_FLEET_SUMMARY_LINE_PRESSURE_SVG,
  payload: isPosting,
})

export const setFleetSummaryAxleLoadSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_FLEET_SUMMARY_AXLE_LOAD_SVG,
  payload: isPosting,
})

export const setFleetSummaryGeofenceInsideSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_INSIDE_SVG,
  payload: isPosting,
})

export const setFleetSummaryGeofenceOutsideSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_OUTSIDE_SVG,
  payload: isPosting,
})

export const loadingWhitelabelAdmin = (isLoading) => ({
  type: LOADING_WHITELABEL_ADMIN,
  payload: isLoading,
})

export const postingWhitelabel = (isPosting) => ({
  type: POSTING_WHITELABEL_ADMIN,
  payload: isPosting,
})

export const postedWhitelabel = (hasPosted) => ({
  type: POSTED_WHITELABEL_ADMIN,
  payload: hasPosted,
})

export const setWhitelabelAdmin = (config, isUpdate = false) => ({
  type: SET_WHITELABEL_ADMIN,
  payload: { config, isUpdate },
})

export const setWhitelabelAdminDirty = (isDirty) => ({
  type: SET_WHITELABEL_ADMIN_DATA_DIRTY,
  payload: isDirty,
})

export const setWhitelabelAdminError = (payload) => ({
  type: SET_WHITELABEL_ADMIN_ERROR,
  payload,
})
export const setAuthImagePng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_AUTH_IMAGE_PNG,
  payload: isPosting,
})
export const setAppSmartHubIconPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_APP_SMARTHUB_ICON_PNG,
  payload: isPosting,
})

export const setAppAxleLoadIconPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_APP_AXLE_LOAD_ICON_PNG,
  payload: isPosting,
})

export const setAppLinePressureIconPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_APP_LINE_PRESSURE_ICON_PNG,
  payload: isPosting,
})

export const setAppTpmsIconPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_APP_TPMS_ICON_PNG,
  payload: isPosting,
})

export const setAppLogoBrandLightPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_LIGHT_PNG,
  payload: isPosting,
})
export const setAppLogoBrandDarkPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_DARK_PNG,
  payload: isPosting,
})
const compareConfigParametersCount = (config, newConfig) => {
  const keys = Object.keys(newConfig)

  for (let i = 0; i < keys.length; i++) {
    if (Array.isArray(newConfig[keys[i]])) {
      if (newConfig[keys[i]].length !== config[keys[i]].length) {
        return false
      }
    }
  }
  // compare preferences keys count
  if (
    Object.keys(config.preferenceDefaults).length !==
    Object.keys(newConfig.preferenceDefaults).length
  ) {
    return false
  }

  return true
}

export function fetchWhitelabelAdmin(customerId) {
  return function (dispatch, getState) {
    if (!customerId) {
      customerId = getState().common.customers.selectedCustomersList[0]?.id
    }

    dispatch(loadingWhitelabelAdmin(true))
    return api
      .getConfig(customerId)
      .then((config) => {
        const newConfig = getActualConfig(config)

        // check parameters count if it is not equal then it means that we have been added something new in local config and need to save it on server for old customer
        const isEqual = compareConfigParametersCount(config, newConfig)

        // compare default attributes from s3 with attributes from db if it is not equal then update config in s3
        const { units_of_measurement, timezone } =
          getState().common.customers.selectedCustomersList[0]
        const { unitsOfMeasurement, locale } = config.preferenceDefaults

        const whitelabelKey = customerId
          ? getState().common.customers.data.find((e) => e.id === customerId)
              .key
          : getState().auth.defWhiteLabel
        dispatch(setAuthWhitelabel({ key: whitelabelKey, reload: true }))

        if (
          !unitsOfMeasurement ||
          units_of_measurement !== unitsOfMeasurement ||
          timezone !== locale.timezone ||
          !isEqual
        ) {
          config.preferenceDefaults.unitsOfMeasurement = units_of_measurement
          config.preferenceDefaults.locale.timezone = timezone

          dispatch(postWhitelabel(customerId, true))
        }

        dispatch(setWhitelabelAdmin(newConfig))
        dispatch(setWhitelabel(newConfig))
      })
      .catch((err) => {
        dispatch(setWhitelabelAdmin(defaultConfig))
        dispatch(setWhitelabel(defaultConfig))
        dispatch(setWhitelabelAdminError(getStatusObj(err)))
      })
      .finally(() => dispatch(loadingWhitelabelAdmin(false)))
  }
}

export function postWhitelabelImage(file, filename, customerId) {
  return function (_dispatch, _getState) {
    return api.uploadImage(file, filename, customerId)
  }
}
export function postWhitelabel(customerId, ignoreStatus = false) {
  return function (dispatch, getState) {
    dispatch(postingWhitelabel(true))
    const whitelabelAdmin = getState().whitelabelAdmin
    const {
      config,
      smarthubHealthSvg,
      tpmsHealthSvg,
      linePressureHealthSvg,
      logoIconPng,
      logoFullPng,
      axleLoadHealthSvg,
      gatewayHealthSvg,
      authImagePng,
      appSmartHubIconPng,
      appAxleLoadIconPng,
      appTpmsIconPng,
      appLinePressureIconPng,
      appLogoBrandLightPng,
      appLogoBrandDarkPng,
      fleetSummarySmarthubSvg,
      fleetSummaryTpmsSvg,
      fleetSummaryLinePressureSvg,
      fleetSummaryAxleLoadSvg,
      fleetSummaryGeofenceInsideSvg,
      fleetSummaryGeofenceOutsideSvg,
    } = whitelabelAdmin

    const savedConfig = { ...config, customer_id: customerId }

    if (smarthubHealthSvg)
      dispatch(
        postWhitelabelImage(
          smarthubHealthSvg,
          WhiteLabelFilenames.smarthubHealthSvg,
          customerId,
        ),
      )
    if (tpmsHealthSvg)
      dispatch(
        postWhitelabelImage(
          tpmsHealthSvg,
          WhiteLabelFilenames.tpmsHealthSvg,
          customerId,
        ),
      )
    if (linePressureHealthSvg)
      dispatch(
        postWhitelabelImage(
          linePressureHealthSvg,
          WhiteLabelFilenames.linePressureHealthSvg,
          customerId,
        ),
      )
    if (axleLoadHealthSvg)
      dispatch(
        postWhitelabelImage(
          axleLoadHealthSvg,
          WhiteLabelFilenames.axleLoadHealthSvg,
          customerId,
        ),
      )
    if (gatewayHealthSvg)
      dispatch(
        postWhitelabelImage(
          gatewayHealthSvg,
          WhiteLabelFilenames.gatewayHealthSvg,
          customerId,
        ),
      )
    if (logoIconPng)
      dispatch(
        postWhitelabelImage(
          logoIconPng,
          WhiteLabelFilenames.logoIconPng,
          customerId,
        ),
      )
    if (logoFullPng)
      dispatch(
        postWhitelabelImage(
          logoFullPng,
          WhiteLabelFilenames.logoFullPng,
          customerId,
        ),
      )

    if (authImagePng)
      dispatch(
        postWhitelabelImage(
          authImagePng,
          WhiteLabelFilenames.authPageImagePng,
          customerId,
        ),
      )
    if (fleetSummarySmarthubSvg)
      dispatch(
        postWhitelabelImage(
          fleetSummarySmarthubSvg,
          WhiteLabelFilenames.fleetSummarySmarthubSvg,
          customerId,
        ),
      )
    if (fleetSummaryTpmsSvg)
      dispatch(
        postWhitelabelImage(
          fleetSummaryTpmsSvg,
          WhiteLabelFilenames.fleetSummaryTpmsSvg,
          customerId,
        ),
      )
    if (fleetSummaryLinePressureSvg)
      dispatch(
        postWhitelabelImage(
          fleetSummaryLinePressureSvg,
          WhiteLabelFilenames.fleetSummaryLinePressureSvg,
          customerId,
        ),
      )
    if (fleetSummaryAxleLoadSvg)
      dispatch(
        postWhitelabelImage(
          fleetSummaryAxleLoadSvg,
          WhiteLabelFilenames.fleetSummaryAxleLoadSvg,
          customerId,
        ),
      )
    if (fleetSummaryGeofenceInsideSvg)
      dispatch(
        postWhitelabelImage(
          fleetSummaryGeofenceInsideSvg,
          WhiteLabelFilenames.fleetSummaryGeofenceInsideSvg,
          customerId,
        ),
      )
    if (fleetSummaryGeofenceOutsideSvg)
      dispatch(
        postWhitelabelImage(
          fleetSummaryGeofenceOutsideSvg,
          WhiteLabelFilenames.fleetSummaryGeofenceOutsideSvg,
          customerId,
        ),
      )
    if (appSmartHubIconPng)
      dispatch(
        postWhitelabelImage(
          appSmartHubIconPng,
          WhiteLabelAppFilenames.smartHubPng,
          customerId,
        ),
      )
    if (appAxleLoadIconPng)
      dispatch(
        postWhitelabelImage(
          appAxleLoadIconPng,
          WhiteLabelAppFilenames.axleLoadPng,
          customerId,
        ),
      )
    if (appLinePressureIconPng)
      dispatch(
        postWhitelabelImage(
          appLinePressureIconPng,
          WhiteLabelAppFilenames.linePressurePng,
          customerId,
        ),
      )
    if (appTpmsIconPng)
      dispatch(
        postWhitelabelImage(
          appTpmsIconPng,
          WhiteLabelAppFilenames.tpmsPng,
          customerId,
        ),
      )
    if (appLogoBrandDarkPng)
      dispatch(
        postWhitelabelImage(
          appLogoBrandDarkPng,
          WhiteLabelAppFilenames.logoBrandDarkPng,
          customerId,
        ),
      )
    if (appLogoBrandLightPng)
      dispatch(
        postWhitelabelImage(
          appLogoBrandLightPng,
          WhiteLabelAppFilenames.logoBrandLightPng,
          customerId,
        ),
      )
    return api
      .postConfig(savedConfig)
      .then((r) => {
        dispatch(fetchCustomers())
        if (!ignoreStatus)
          dispatch(
            setWhitelabelAdminError(
              getStatusObj(
                { statusCode: 200, message: '' },
                'Whitelabel saved',
              ),
            ),
          )
      })
      .catch((err) => dispatch(setWhitelabelAdminError(getStatusObj(err))))
      .finally(() => dispatch(postingWhitelabel(false)))
  }
}

export const resetWhitelabelToDefault = (resetType) => {
  return (dispatch, getState) => {
    const { id } = getState().common.customers.selectedCustomersList[0]
    const body = {
      customer_id: id,
      reset_type: resetType,
    }
    return api
      .putWhitelabelReset(body)
      .then(() => {
        dispatch(fetchWhitelabelAdmin(id))
        dispatch(
          setWhitelabelAdminError(
            getStatusObj(
              { statusCode: 200, message: '' },
              'The whitelabel scheme has been reset to default',
            ),
          ),
        )
      })
      .catch((err) => console.log(err))
  }
}
