import {
  LOADING_SUBSCRIPTION_INFO,
  SET_SUBSCRIPTION_INFO,
  SET_SUBSCRIPTION_INFO_ERROR,
  LOADING_SENSOR_INFO_JSON,
  SET_SENSOR_INFO_JSON,
  SET_SENSOR_INFO_JSON_ERROR,
  LOADING_PROVISION_INFO,
  SET_PROVISION_INFO,
  SET_LAST_PROVISION_TIMESTAMP,
  SET_PROVISION_INFO_ERROR,
  SET_ASSET_INFO,
  LOADING_HARDWARE_COMMANDS,
  SET_HARDWARE_COMMANDS,
  SET_HARDWARE_COMMANDS_ERROR,
  SET_IS_LOADING_PROVISION_HISTORY,
  SET_PROVISION_HISTORY,
  SET_PROVISION_HISTORY_ERROR,
  SET_ASSET_ADMIN_GATEWAYS,
  SET_IS_LOADING_GEOFENCES_EVENTS,
  SET_GEOFENCES_EVENTS,
  SET_GEOFENCES_EVENTS_ERROR,
} from '../actionTypes'
import api from '../../services/api'
import { getErrorObj, convertDataEpochToDate } from '../../utils'
import { generateCommandDetails } from '../../modules/HardwareCommandsTable/utils'
import { HardwareType } from '../../enums'
import { getStatusObj } from '../../utils/utils'

export const setLoadingSubscriptionInfo = (loading) => ({
  type: LOADING_SUBSCRIPTION_INFO,
  payload: loading,
})

export const setSubscriptionInfoJson = (json) => ({
  type: SET_SUBSCRIPTION_INFO,
  payload: json,
})

export const setSubscriptionInfoError = (error) => ({
  type: SET_SUBSCRIPTION_INFO_ERROR,
  payload: error,
})

export const setLoadingSensorInfo = (loading) => ({
  type: LOADING_SENSOR_INFO_JSON,
  payload: loading,
})

export const setSensorInfoJson = (json) => ({
  type: SET_SENSOR_INFO_JSON,
  payload: json,
})

export const setSensorInfoError = (error) => ({
  type: SET_SENSOR_INFO_JSON_ERROR,
  payload: error,
})

export const setLoadingProvisionInfo = (loading) => ({
  type: LOADING_PROVISION_INFO,
  payload: loading,
})

export const setProvisionInfoJson = (json) => ({
  type: SET_PROVISION_INFO,
  payload: json,
})

export const setProvisionLatestTimestamp = (timestamp) => ({
  type: SET_LAST_PROVISION_TIMESTAMP,
  payload: timestamp,
})

export const setProvisionInfoError = (error) => ({
  type: SET_PROVISION_INFO_ERROR,
  payload: error,
})

export const setAssetInfo = (info) => ({
  type: SET_ASSET_INFO,
  payload: info,
})

export const setLoadingHardwareCommands = (loading) => ({
  type: LOADING_HARDWARE_COMMANDS,
  payload: loading,
})

export const setHardwareCommands = (data) => ({
  type: SET_HARDWARE_COMMANDS,
  payload: data,
})

export const setHardwareCommandsError = (error) => ({
  type: SET_HARDWARE_COMMANDS_ERROR,
  payload: error,
})

const setIsLoadingProvisionHistory = (isLoading) => ({
  type: SET_IS_LOADING_PROVISION_HISTORY,
  payload: isLoading,
})

const setProvisionHistory = (data) => ({
  type: SET_PROVISION_HISTORY,
  payload: data,
})

const setProvisionHistoryError = (error) => ({
  type: SET_PROVISION_HISTORY_ERROR,
  payload: error,
})

const setIsLoadingGeofencesEvents = (isLoading) => ({
  type: SET_IS_LOADING_GEOFENCES_EVENTS,
  payload: isLoading,
})

const setGeofencesEvents = (data) => ({
  type: SET_GEOFENCES_EVENTS,
  payload: data,
})

const setGeofencesEventsError = (error) => ({
  type: SET_GEOFENCES_EVENTS_ERROR,
  payload: error,
})

export const fetchAdminInfoPage = (vin) => {
  return function (dispatch) {
    dispatch(fetchAssetInfo(vin)).then(() => {
      dispatch(fetchLastProvisionDate(vin))
      dispatch(fetchAssetHardwareByType(HardwareType.GATEWAY))
    })
  }
}

export const fetchAssetGeofencesEvents = (epoch_from, epoch_to) => {
  return function (dispatch, getState) {
    dispatch(setIsLoadingGeofencesEvents(true))
    const assetId = getState().assetAdminInfo.assetInfo.id
    return api
      .getAssetGeofencesEvents(assetId, epoch_from, epoch_to)
      .then((data) => {
        data = convertDataEpochToDate(data, 'created_epoch', 'created_datetime')
        data = convertDataEpochToDate(data, 'epoch', 'event_datetime')
        dispatch(setGeofencesEvents(data))
      })
      .catch((err) => {
        dispatch(setGeofencesEventsError(getErrorObj(err)))
      })
      .finally(() => {
        dispatch(setIsLoadingGeofencesEvents(false))
      })
  }
}

export const fetchProvisionHistory = (from = null, to = null) => {
  return function (dispatch, getState) {
    dispatch(setIsLoadingProvisionHistory(true))
    const assetId = getState().assetAdminInfo.assetInfo.id
    return api
      .getHardwareProvisionHistory(assetId, from, to)
      .then((data) => {
        data = convertDataEpochToDate(data, 'created_epoch', 'created_datetime')
        dispatch(setProvisionHistory(data))
      })
      .catch((err) => {
        dispatch(setProvisionHistoryError(getErrorObj(err)))
      })
      .finally(() => {
        dispatch(setIsLoadingProvisionHistory(false))
      })
  }
}

export const fetchProvisionJson = (vin) => {
  return function (dispatch, getState) {
    dispatch(setLoadingProvisionInfo(true))

    return api
      .getProvisionJson(vin)
      .then((res) => {
        dispatch(setProvisionInfoJson(res))
        dispatch(setProvisionInfoError(getErrorObj()))
      })
      .catch((err) => dispatch(setProvisionInfoError(getErrorObj(err))))
      .finally(() => dispatch(setLoadingProvisionInfo(false)))
  }
}

export const fetchLastProvisionDate = (vin) => {
  return function (dispatch) {
    return api
      .getLatestProvisionTimestamp(vin)
      .then((res) => {
        const convertedTs = convertDataEpochToDate(
          res.last_provisioning_timestamp,
          null,
          null,
          true,
        )
        dispatch(setProvisionLatestTimestamp(convertedTs))
      })
      .catch((err) => console.log(err))
  }
}

export const postMigrateHardwareData = (data) => {
  return async function () {
    try {
      await api.postMigrateData(data)
      return getStatusObj()
    } catch (err) {
      return getStatusObj(err)
    }
  }
}

export const deleteHardwareData = (assetId) => {
  return async function () {
    try {
      await api.deleteHardwareData(assetId)
      return getStatusObj()
    } catch (err) {
      return getStatusObj(err)
    }
  }
}

export const postProvision = (data) => {
  return function (dispatch) {
    dispatch(setLoadingProvisionInfo(true))
    return api
      .postProvisionJson(data)
      .then((res) => {
        dispatch(setProvisionInfoError(getErrorObj()))
        return {
          statusCode: 200,
          message: '',
          concerned_assets_with_macs: res['concerned_assets_with_macs'],
        }
      })
      .catch((err) => {
        return { statusCode: 400, message: err.message }
      })
      .finally(() => dispatch(setLoadingProvisionInfo(false)))
  }
}

export const fetchAssetInfo = (vin) => {
  return function (dispatch, getState) {
    dispatch(setLoadingSubscriptionInfo(true))
    dispatch(setLoadingSensorInfo(true))
    const assetInfo = getState().assetAdminInfo.assetInfo
    return api
      .getExactAsset(vin)
      .then((res) => {
        const validSubs = {} // parse timestamp to normal date
        const keys = res.subscription ? Object.keys(res.subscription) : []
        if (keys.length) {
          keys.map((subKey) => {
            res.subscription[subKey].valid_from = convertDataEpochToDate(
              res.subscription[subKey].valid_from,
              null,
              null,
              true,
            )
            res.subscription[subKey].valid_to = convertDataEpochToDate(
              res.subscription[subKey].valid_to,
              null,
              null,
              true,
            )
            return (validSubs[subKey] = res.subscription[subKey])
          })
        }

        dispatch(setSubscriptionInfoJson(validSubs))
        dispatch(setSensorInfoJson(res.sensor_info))
        dispatch(
          setAssetInfo({
            ...assetInfo,
            id: res.id,
            name: res.name,
            whitelistingAutomation: res.whitelisting_automation,
          }),
        )
        dispatch(setSubscriptionInfoError(getErrorObj()))
        dispatch(setSensorInfoError(getErrorObj()))
      })
      .catch((err) => {
        console.log(err)
        dispatch(setSubscriptionInfoError(getErrorObj(err)))
        dispatch(setSensorInfoError(getErrorObj(err)))
      })
      .finally(() => {
        dispatch(setLoadingSubscriptionInfo(false))
        dispatch(setLoadingSensorInfo(false))
      })
  }
}

export const fetchHardwareCommandsHistory = () => {
  return function (dispatch, getState) {
    dispatch(setLoadingHardwareCommands(true))
    const assetId = getState().assetAdminInfo.assetInfo.id

    return api
      .getAssetHardwareCommandsHistory(assetId)
      .then((res) => {
        generateCommandDetails(res)
        dispatch(setHardwareCommands(res))
        dispatch(setHardwareCommandsError(getErrorObj()))
      })
      .catch((err) => dispatch(setHardwareCommandsError(getErrorObj(err))))
      .finally(() => dispatch(setLoadingHardwareCommands(false)))
  }
}

export const putHardwareCommand = (data) => {
  return function (dispatch, getState) {
    dispatch(setLoadingHardwareCommands(true))
    const assetId = getState().assetAdminInfo.assetInfo.id

    return api
      .putHardwareCommand(data)
      .then((res) => {
        console.log(res)
        dispatch(fetchHardwareCommandsHistory(assetId))
        return res
      })
      .catch((err) => dispatch(setHardwareCommandsError(getErrorObj(err))))
      .finally(() => dispatch(setLoadingHardwareCommands(false)))
  }
}

export const postCopyToEnviroments = (data) => {
  return function () {
    return api
      .postCopyProvisioningToEnviroments(data)
      .then((res) => {
        console.log(res)
        return res
      })
      .catch((err) => {})
      .finally(() => {})
  }
}

export const fetchAssetHardwareByType = (hardwareTypeId) => {
  return function (dispatch, getState) {
    dispatch(setLoadingHardwareCommands(true))
    const assetId = getState().assetAdminInfo.assetInfo.id

    return api
      .getSensorNodeInfo(assetId, hardwareTypeId)
      .then((res) => {
        dispatch({ type: SET_ASSET_ADMIN_GATEWAYS, payload: res })
      })
      .catch((err) => console.log(err))
  }
}
