import { Permission, ReportTypes, TagReportTypes } from '../../enums'
import { getCurrentEpoch } from '../../utils'

export const reportAccessFilter = (
  isSuperAdmin: boolean,
  isAdmin: boolean,
  subscriptions: ISubscriptions,
  permissions: Array<string>,
) => {
  const reports = Object.values(ReportTypes)

  if (isSuperAdmin) return reports

  return reports.filter((rep) => {
    switch (rep) {
      case ReportTypes.USER_LOGINS_REPORT:
        return permissions.includes(Permission.VIEW_USER_LOGINS_REPORT)
      case ReportTypes.WARNING_HISTORY_REPORT:
      case ReportTypes.ACTIVE_WARNINGS_REPORT:
      case ReportTypes.DURATION_IN_WARNING_STATE_REPORT:
      case ReportTypes.NOTIFICATION_HISTORY_REPORT:
        return Object.keys(subscriptions).length > 1 // gw sub + any other
      case ReportTypes.TPMS_REPORT:
        return (
          subscriptions.TIRE_SENSOR &&
          subscriptions.TIRE_SENSOR.valid_to > getCurrentEpoch()
        )
      case ReportTypes.ODOMETER_OFFSET_SUMMARY_REPORT:
      case ReportTypes.DEVICE_HEALTH_REPORT:
        return isAdmin
      // hide for now
      case ReportTypes.HARDWARE_COMMANDS_HISTORY_REPORT:
      case ReportTypes.DAILY_MILEAGE_REPORT:
      case ReportTypes.FAILED_NOTIFICATION_HISTORY_REPORT:
      case ReportTypes.SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT:
      case ReportTypes.NOTIFICATIONS_SUBSCRIPTIONS_REPORT:
        return false
      case ReportTypes.VEHICLE_LOCATION_REPORT:
        return false
      default:
        return true
    }
  })
}
export const tagReportAccessFilter = (
  isSuperAdmin: boolean,
  hasCargoTagSubscription: boolean,
) => {
  const reports = Object.values(TagReportTypes)

  if (isSuperAdmin) return reports

  return reports.filter((rep) => {
    switch (rep) {
      case TagReportTypes.LATEST_TAG_LOCATION_REPORT:
      case TagReportTypes.LOST_TAGS_REPORT:
      case TagReportTypes.TAG_HISTORY_DETAILS_REPORT:
        return hasCargoTagSubscription
      case TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT:
      case TagReportTypes.CARGO_TAG_GATEWAY_REPORT:
        return false
      default:
        return true
    }
  })
}
