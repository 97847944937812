import React, { useCallback, useEffect, useMemo, useState } from 'react'

import DetailsGrid from '../../components/RivataGrid/DetailsGrid'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useColumns, useRows } from './hooks'
import ScheduledReportsModule from '../ScheduledReportsModule'
import PseudoBtn from '../../components/CustomButtons/fakeBnt'
import { useInitialFormValues } from '../ScheduledReportsModule/hooks'
import { makeScrollToY } from '../../utils/utils'
import SheduledReportDeleteModal from './SheduledReportDeleteModal'
import { InternalScheduledReportTypes, ScheduledReportTypes } from '../../enums'
import { getClearLabel, sortByAlphabet } from '../../utils'
import RivataDropdown from '../../components/RivataDropdown'

interface Props {
  internalReports: boolean
}

const ScheduledReportsGrid: React.FC<Props> = ({ internalReports }) => {
  const {
    auth,
    scheduledReports: { isLoading, data },
    whitelabel: { locale },
    selectedCustomersList,
  } = useTypedSelector((state) => ({
    auth: state.auth,
    scheduledReports: state.scheduledReports,
    whitelabel: state.whitelabel,
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))

  const timezone = selectedCustomersList[0]?.timezone

  const { fetchScheduledReports } = useActions()
  const [showEditForm, setShowEditForm] = useState(false)
  const [reportToEdit, setReportToEdit] = useState<IScheduledReport | null>(
    null,
  )
  const [reportToDelete, setReportToDelete] = useState<IScheduledReport | null>(
    null,
  )
  const [creationMode, setCreationMode] = useState(false)
  const initialFormValues = useInitialFormValues(reportToEdit)

  const [parameters, setParameters] = useState(initialFormValues.parameters)
  const [isDisabled, setIsDisabled] = useState(initialFormValues.isDisabled)

  const [reportType, setReportType] = useState<string>('ALL')

  const reportsDropdownItems = useMemo(() => {
    const accessedReports = internalReports
      ? InternalScheduledReportTypes
      : ScheduledReportTypes
    const items = Object.keys(accessedReports)
      .filter((t) => t !== 'EMPTY_TYPE')
      .map((el) => ({ id: el, label: getClearLabel(el) }))

    sortByAlphabet(items, 'label')
    items.unshift({ id: 'ALL', label: 'All Reports' })

    return items
  }, [internalReports])

  useEffect(() => {
    fetchScheduledReports(internalReports, reportType)
  }, [fetchScheduledReports, internalReports, reportType])

  const editCreateEnabled = useMemo(
    () => auth.isSuperAdmin || auth.isAdmin,
    [auth],
  )

  const handleSetCreationMode = useCallback(
    (state: boolean) => {
      setCreationMode(state)

      if (!state) return

      setParameters({ bcc_users: [], timezone: timezone, asset_type: 'all' })
      setReportToEdit({
        id: -1,
        report_type_id: -1,
        report_type_name: '',
        parameters: { bcc_users: [], timezone: timezone },
        last_run_at_epoch: '',
        next_run_at_epoch: '',
        schedule: '',
        report_name: '',
        is_disabled: false,
        preselected_assets: [],
        preselected_geofences: [],
        customer_id: null,
        preselected_users: [],
        end_rule: {
          send_for: null,
          send_for_metric: null,
          type: null,
          value: null,
        },
      })
      setShowEditForm(true)
    },
    [timezone],
  )

  const columns = useColumns(
    data,
    setShowEditForm,
    setReportToEdit,
    handleSetCreationMode,
    setParameters,
    editCreateEnabled,
    setReportToDelete,
  )
  const rows = useRows(data)

  const gridHeight = useMemo(
    () => (rows.length > 10 ? '495px' : `${rows.length * 45 + 47}px`),
    [rows],
  )

  const handleCreationModeClicked = () => {
    handleSetCreationMode(true)
    makeScrollToY(0)
  }

  useEffect(() => {
    setShowEditForm(false)
    handleSetCreationMode(false)
    setReportType('ALL')
  }, [selectedCustomersList, handleSetCreationMode])

  return (
    <>
      <RivataModule
        title='Scheduled Reports'
        subTitle='All times shown on this page are in timezone selected for the report'
        showInfoIcon={true}
        locale={locale}
        filters={
          <div className='d-flex align-items-center'>
            <div className='mr-3'>
              {editCreateEnabled && (
                <PseudoBtn
                  icon='fa fa-plus-circle'
                  label={'Create Scheduled Report'}
                  active={!isLoading && !creationMode}
                  onClickHandler={handleCreationModeClicked}
                />
              )}
            </div>
            <RivataDropdown
              caret={true}
              selected={reportType}
              items={reportsDropdownItems}
              onSelect={(id: React.SetStateAction<string>) => setReportType(id)}
            />
          </div>
        }
        marginTop={0}
      >
        {isLoading && <RivataLoader />}

        {showEditForm && (
          <div className='mb-4'>
            <ScheduledReportsModule
              creationMode={creationMode}
              setCreationMode={handleSetCreationMode}
              reportToEdit={reportToEdit}
              showEditForm={showEditForm}
              setShowEditForm={setShowEditForm}
              setReportToEdit={setReportToEdit}
              parameters={parameters}
              setParameters={setParameters}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              locale={locale}
              customerTimezone={timezone}
              internalReports={internalReports}
              reportType={reportType}
            />
            <hr />
          </div>
        )}

        {rows.length ? (
          <DetailsGrid
            columns={columns}
            rows={rows}
            height={gridHeight}
            allowHorizontalScroll
          />
        ) : !isLoading ? (
          <StatusAlert
            customText={'No Data'}
            color='success'
            statusCode={undefined}
            statusText={undefined}
          />
        ) : (
          ''
        )}
      </RivataModule>

      <SheduledReportDeleteModal
        reportToDelete={reportToDelete}
        setReportToDelete={setReportToDelete}
        internalReports={internalReports}
        reportType={reportType}
      />
    </>
  )
}

export default ScheduledReportsGrid
