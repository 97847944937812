import React, { useCallback, useRef, useState } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import CalibrationEditModal from './calibrationEditModal'
import CalibrationDeleteModal, { IItem } from './calibrationDeleteModal'
import InfoModal from '../../components/InfoModal'
import { useColumns, useTableRows } from './hooks'
import { makeScrollToElementWithOffset } from '../../utils/utils'
import './style.scss'

interface IAxleLoadCalibration {
  data: Array<IItem>
  isLoading: boolean
  totalCount: number
  limit: number
  offset: number
  selectedUnitsOfMeasurement: string
  selectedAsset: IAsset
  locale: ILocale | undefined
  onEdit: () => IStatus
  onDelete: (selectedAssetId: number, deleteItemId?: number) => IStatus
  setCalibrationDataOffset: (data: number) => void
  setCalibrationDataLimit: (data: number) => void
}

const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'calibrate.axleLoadTable.columns',
}
const AxleLoadCalibrationTable: React.FC<IAxleLoadCalibration> = ({
  data,
  isLoading,
  totalCount,
  limit,
  offset,
  selectedUnitsOfMeasurement,
  selectedAsset,
  locale = {},
  onEdit,
  onDelete,
  setCalibrationDataOffset,
  setCalibrationDataLimit,
}) => {
  const scrollTarget = useRef(null)

  const [deleteItem, setDeleteItem] = useState<IItem | null>(null)
  const [editItem, setEditItem] = useState<null | IItem>(null)
  const [statusMessage, setStatusMessage] = useState<string | null>(null)

  const columns = useColumns()
  const rows = useTableRows(data, selectedUnitsOfMeasurement)

  const handlePageChange = useCallback(
    (offset: number) => {
      setCalibrationDataOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setCalibrationDataOffset],
  )

  const handleLimitChange = useCallback(
    (limit: number) => {
      setCalibrationDataLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setCalibrationDataLimit],
  )

  return (
    <RivataModule
      title={`Axle load Calibration Data For Asset: ${
        selectedAsset && selectedAsset.id
          ? selectedAsset.name + '(' + selectedAsset.vin + ')'
          : ''
      }`}
      marginTop={0}
      locale={locale}
      filters={undefined}
    >
      <div ref={scrollTarget} className='axle-load-calibration-table'>
        {editItem && (
          <CalibrationEditModal
            onCancel={() => setEditItem(null)}
            editItem={editItem}
            setEditItem={(data) => setEditItem(data)}
            selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
            setStatusMessage={(message) => setStatusMessage(message)}
            onEdit={onEdit}
            selectedAsset={selectedAsset}
            disabled={false}
            locale={undefined}
          />
        )}
        {deleteItem && (
          <CalibrationDeleteModal
            deleteItem={deleteItem}
            selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
            onCancel={() => setDeleteItem(null)}
            onDelete={async () => {
              const res: IStatus = await onDelete(
                selectedAsset.id,
                deleteItem.id,
              )
              setDeleteItem(null)
              setStatusMessage(res.message)
            }}
          />
        )}
        <InfoModal
          header={'Calibration info'}
          open={!!statusMessage}
          onConfirm={() => setStatusMessage(null)}
          message={statusMessage || ''}
        />
        <RivataTable
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          totalCount={totalCount}
          page={offset / limit}
          // @ts-ignore component expect undefined cause of default value
          onSelectLimit={handleLimitChange}
          // @ts-ignore component expect undefined cause of default value
          onPageChange={handlePageChange}
          pageLimit={limit}
          isShowingLimit={true}
          onDelete={(data: IItem) => setDeleteItem(data)}
          onEdit={(data: IItem) => setEditItem(data)}
          deleteTooltipText={'Delete Calibration'}
          editTooltipText={'Edit Calibration'}
          editDisabled={undefined}
          deleteDisabled={undefined}
          setSortOptions={undefined}
          onCustomAction={undefined}
          // @ts-ignore component expect undefined cause of default value
          columnsSelectorProps={columnsSelectorProps}
        />
      </div>
    </RivataModule>
  )
}

export default AxleLoadCalibrationTable
