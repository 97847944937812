import React from 'react'
import { Col } from "reactstrap";
import SubNav from '../../components/SubNav'
import GeofenceGroupsDetailsModule from '../../modules/GeofenceGroupsDetailsModule';
import { match } from 'react-router-dom';

interface Props {
    match: match<{ id: string }>
}

const breadcrumbs = [
    { label: "Home", path: "/dashboard" },
    { label: "Geofence Groups", path: "/geofence-groups" },
    { label: "Details" }
]

const GeofenceGroupsDetails: React.FC<Props> = ({ match }) => {
    return (
        <>
            <SubNav
                title="Geofence Group Details"
                breadcrumbs={breadcrumbs}
            ></SubNav>

            <Col md="12">
                <GeofenceGroupsDetailsModule group_id={match.params.id} />
            </Col>
        </>
    )
}

export default GeofenceGroupsDetails
