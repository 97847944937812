import React, { useMemo } from 'react';
import { Button, Row } from 'reactstrap';
import * as Yup from "yup";
import { FormField } from "../../components/CreateModal";
import CreateModal from "../../components/CreateModal/children";
import RivataLoader from '../../components/RivataLoader';
import { removeSpecialCharacters, isHexFormat } from '../../utils';
import './styles.scss';


const validateGatewayFormat = (value) => {
    if (value) {
        value = removeSpecialCharacters(value);
        const hexOnly = isHexFormat(value);

        if (!hexOnly || value.length < 10 || value.length > 12) {
            return false;
        }
    }

    return true;
}

const LocationGatewayModal = ({
    locale = {},
    onCreate,
    disabled,
    initialValues,
    open,
    onClose,
    data,
    onDelete,
    isLoading
}) => {
    let gateways = data.gateways;

    const LocationGatewaySchema = useMemo(() => {
        Yup.addMethod(Yup.string, "isAssigned", function(errorMessage) {
            return this.test({
                name: 'isAssigned',
                message: errorMessage,
                test: value => {
                    const mappedGateways = gateways.map(gw => removeSpecialCharacters(gw));
                    value = removeSpecialCharacters(value);
                    const isAlreadyAssigned = value.length === 12 ? mappedGateways.includes(value) : mappedGateways.includes(`00${value}`)

                    return !isAlreadyAssigned
                }
            });
        })

        return Yup.object().shape({
            gateway_esn: Yup.string()
                .notRequired()
                .test("format", "Incorrect gateway format", validateGatewayFormat)
                .isAssigned("Gateway already assigned to this location")
        })
    }, [gateways]);

    return (
        <CreateModal
            variant="primary"
            disabled={disabled}
            className="ml-3"
            btnClassName="ml-3"
            onCreate={onCreate}
            createBtnLabel={"Save"}
            header={`Gateways`}
            locale={locale}
            schema={LocationGatewaySchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
        >
            {isLoading && <RivataLoader />}
            {gateways && <>
                {gateways.map(gw => {
                    return (<div key={gw} className="assigned-gateway">
                        <span>{gw}</span>
                        <Button
                            className="pl-2 pr-1"
                            close
                            disabled={false}
                            onClick={async () => {
                                const result = await onDelete(gw)
                                if (result && result.ok) {
                                    const index = gateways.indexOf(gw);
                                    if (index > -1) {
                                        gateways.splice(index, 1);
                                    }
                                }
                            }}
                        />
                    </div>)
                })}
            </>}
            <Row className="p-t-0">
                <FormField name="gateway_esn" label="Assign Gateway" />
            </Row>
        </CreateModal>
    );
};

export default LocationGatewayModal;