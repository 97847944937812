import { convertDataEpochToDate, psiToBar, kmhToMph } from '../../utils'
import {
  chartDatasetSort,
  composeVertLineAnnotation,
} from '../../utils/chartUtils'
import { NoDataLabels } from '../../enums'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

export const composeChartData = (data, unitsOfMeasurementConfig) => {
  const parsedData = {
    datasets: [
      {
        label: 'Line Pressure',
        data: [],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
      },
    ],
  }

  const pressureUnitsOfMeasure = `(${
    unitsOfMeasurementConfig?.pressure === UnitsOfMeasurementConfig.pressure.psi
      ? 'PSI'
      : 'Bar'
  })`
  const speedUnitsOfMeasure = `${
    unitsOfMeasurementConfig?.speed === UnitsOfMeasurementConfig.speed.mph
      ? 'Mph'
      : 'Kmh'
  }`

  data.map((item) => {
    const value =
      unitsOfMeasurementConfig?.pressure ===
      UnitsOfMeasurementConfig.pressure.psi
        ? item.min_value.toFixed(2)
        : parseFloat(psiToBar(item.min_value)).toFixed(2)

    const speed =
      item.speed >= 0
        ? unitsOfMeasurementConfig?.speed === UnitsOfMeasurementConfig.speed.mph
          ? kmhToMph(item.speed)
          : item.speed
        : NoDataLabels.DASH

    const speedWithUnits = `${Math.round(speed)} ${speedUnitsOfMeasure}`
    const obj = {
      x: convertDataEpochToDate(item.bucket_epoch, null, null, false),
      y: value,
      displayBeforeLabel: `Pressure: ${value} ${pressureUnitsOfMeasure}`,
      displayValue: `Speed: ${speed === '-' ? speed : speedWithUnits}`,
      displayAfterLabel: `Date: ${convertDataEpochToDate(
        item.bucket_epoch,
        null,
        null,
        true,
      )}`,
    }
    return parsedData.datasets[0].data.push(obj)
  })

  // sorting for sequential connection between line dots
  chartDatasetSort(parsedData.datasets[0].data)

  return parsedData
}

export const composeAnnotations = (
  id,
  threshold = 60,
  verticalLine,
  healthColors,
) => {
  const annotations = {}

  annotations.box1 = {
    type: 'box',
    id: `box-plot-top-${id}`,
    xScaleID: 'x',
    yScaleID: 'y',
    yMax: Infinity,
    yMin: threshold,
    backgroundColor: '#fff',
    borderWidth: 0,
    drawTime: 'beforeDatasetsDraw',
  }

  annotations.box2 = {
    type: 'box',
    id: `box-plot-bottom-${id}`,
    xScaleID: 'x',
    yScaleID: 'y',
    yMax: threshold,
    yMin: -Infinity,
    backgroundColor: '#fffcdf',
    borderWidth: 0,
    drawTime: 'beforeDatasetsDraw',
  }

  if (threshold) {
    annotations.box3 = {
      id: `threshold-${id}`,
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y',
      value: threshold,
      endValue: threshold,
      borderColor: '#FED925',
      borderWidth: 2,
      borderDash: [2, 2],
      borderDashOffset: 10,
      drawTime: 'beforeDatasetsDraw',
    }
  }
  if (verticalLine && healthColors && healthColors[1]) {
    annotations.verticalLine = composeVertLineAnnotation(
      id,
      verticalLine,
      healthColors[1].color,
    )
  }

  return annotations
}
