import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'

import { history } from '../../../jwt/_helpers'
import {
  getTooltipCoordinates,
  getTooltipContent,
  wktToGeometry,
} from '../utils'
import {
  addLocationButton,
  bboxAll,
} from '../../../components/RivataMapCluster/utils'
import { pinMap } from '../../../components/RivataMap/utils'

interface Props {
  map: H.Map | null
  ui?: H.ui.UI | null
  edit?: boolean
  zoomTo?: number | null
  geofences: Array<IGeofence>
  geofencesVisible?: boolean
  setBounds?: boolean
  shouldAddCenterMarkers?: boolean
  onMapViewChange?: (bounds: any, zoom: any) => void
  mapZoomBounds?: any
  renderLocationBtn?: boolean
  customTooltipContent?: (d: any) => string
  displayBubble?: boolean
}

const GeofenceRenderer: React.FC<Props> = ({
  map,
  ui,
  zoomTo,
  geofences,
  geofencesVisible,
  setBounds = false,
  edit = false,
  onMapViewChange,
  mapZoomBounds,
  renderLocationBtn = false,
  customTooltipContent,
  displayBubble = true,
  shouldAddCenterMarkers = false,
}) => {
  const [polygons, setPolygons] = useState<Array<H.map.Polygon>>([])
  const [counter, setCounter] = useState<number>(0)
  const [polIndexes, setPolIndexes] = useState<Array<number>>([])
  const [targetId, setTargetId] = useState<number | null>(null)
  const [newPsn, setNewPsn] = useState<any>(null)
  const [bbox, setBbox] = useState<any>(null)

  const markerGroup = useRef(new H.map.Group())
  const polygonsGroup = useRef(new H.map.Group())

  const onMapViewChangeEvent = (ev: any) => {
    if (onMapViewChange && ev['newValue']['lookAt']) {
      const bounds = ev['newValue']['lookAt']['bounds'].getBoundingBox()
      const zoom = ev['newValue']['lookAt']['zoom']

      onMapViewChange(bounds, zoom)
    }
  }

  // Use this for Tag/Geofence markers clustering
  // TODO: markers should hide on initial loading and reveal on zoom
  // const startClustering = () => {
  //   var dataPoints = geofences.map(function (item) {
  //     const pol = new H.map.Polygon(wktToGeometry(item.geofence))
  //     const center = pol.getBoundingBox().getCenter()

  //     return new H.clustering.DataPoint(center.lat, center.lng)
  //   })

  //   // Create a clustering provider with custom options for clusterizing the input
  //   var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
  //     clusteringOptions: {
  //       // Maximum radius of the neighbourhood
  //       eps: 32,
  //       // minimum weight of points required to form a cluster
  //       minWeight: 2,
  //     },
  //   })

  //   let defaultTheme = clusteredDataProvider.getTheme()
  //   clusteredDataProvider.setTheme(clusterTheme(defaultTheme))

  //   // Create a layer tha will consume objects from our clustering provider
  //   var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider)

  //   // To make objects from clustering provder visible,
  //   // we need to add our layer to the map
  //   map?.addLayer(clusteringLayer)
  // }

  useEffect(() => {
    if (map) {
      map.addEventListener(
        'mapviewchange',
        debounce(onMapViewChangeEvent, 200, { leading: false }),
      )
    }
    return () => {
      if (map) {
        map.removeEventListener('mapviewchange', onMapViewChangeEvent)
      }
    }
    // eslint-disable-next-line
  }, [map])

  const removeBubbles = () => {
    if (!ui) return

    const bubbles = ui.getBubbles()

    bubbles.forEach((bubble) => {
      const data = bubble.getData()
      if (data) ui.removeBubble(bubble)
    })
  }

  const onPointerEnter = (e: any) => {
    if (!map || !ui || !displayBubble) return
    removeBubbles()
    const id = e.target.getId()
    setPolIndexes((prev: any) => {
      return [...prev, id]
    })
    setTargetId(id)
    const data = e.target.getData().data
    const viewPort = map.getViewPort()
    const mouseCoordinates = map.screenToGeo(
      e.currentPointer.viewportX,
      e.currentPointer.viewportY,
    )
    const pixelCoordinates = map.geoToScreen({
      lat: mouseCoordinates.lat,
      lng: mouseCoordinates.lng,
    })

    const { x, y } = getTooltipCoordinates(
      pixelCoordinates.x,
      pixelCoordinates.y,
      viewPort.width,
      data,
    )

    const bubble = new H.ui.InfoBubble(map.screenToGeo(x, y), {
      content: customTooltipContent
        ? customTooltipContent(data)
        : getTooltipContent(data),
    })

    bubble.setData(data)
    bubble.addClass('geofence-info-bubble')
    ui.addBubble(bubble)
    bubble.getElement()?.addEventListener('pointerleave', onPointerLeave)
    const el = document.getElementById('locationMapBubble')

    if (el) {
      el.addEventListener('click', () => {
        if (!data) return

        let link = null

        if (data.location_type_id === 2) link = `/geofence-details/${data.id}`
        else if (data.location_type_id === 3)
          link = `/tag-location-details/${data.id}`

        if (link) history.push(link)
      })
    }

    setCounter((prev) => prev + 1)
  }

  //// add zIndexes for internal geofences
  useEffect(() => {
    let ids = new Set(polIndexes)
    const targetPolygon = polygons.find((pol) => pol.getId() === targetId)

    if (!targetPolygon?.getZIndex()) {
      targetPolygon?.setZIndex(0)
    }

    polygons.forEach((p) => {
      const pId = p.getId()
      const isInSet = ids.has(pId)
      if (!isInSet) {
        const parentZIndex = targetPolygon?.getZIndex() as number
        p.setZIndex(parentZIndex + 10)
      }
    })
  }, [counter, polIndexes, polygons, targetId])

  ///clear indexes array if move from geofence to normal map
  useEffect(() => {
    const onIndexesClear = () => {
      setPolIndexes([])
    }
    map?.addEventListener('pointerenter', onIndexesClear)
    return () => map?.removeEventListener('pointerenter', () => {})
  }, [map])

  const onPointerLeave = () => {
    removeBubbles()
  }

  const zoomHandler = useCallback(
    (
      bounds?: H.geo.AbstractGeometry | undefined,
      ignoreMapZoomBounds = false,
    ) => {
      if (!map) return

      if (
        !ignoreMapZoomBounds &&
        mapZoomBounds?.zoom &&
        mapZoomBounds?.bounds
      ) {
        map.getViewModel().setLookAtData({
          zoom: mapZoomBounds?.zoom,
          bounds: mapZoomBounds?.bounds,
        })
      } else {
        map.getViewModel().setLookAtData(
          {
            bounds,
          },
          false,
        )
      }
    },
    [map, mapZoomBounds],
  )

  useEffect(() => {
    setTargetId(null)
    onPointerLeave()
    const dispose = () => {
      polygons.forEach((polygon) => {
        polygon.removeEventListener('pointerenter', onPointerEnter)
        polygon.dispose()
      })

      map?.getElement()?.removeEventListener('pointerleave', onPointerLeave)
    }

    if (map && geofences) {
      dispose()
      const polygons = geofences
        .filter((g) => g.geofence && g.geofence.includes('POLYGON'))
        .map(({ geofence }) => new H.map.Polygon(wktToGeometry(geofence!)))

      if (shouldAddCenterMarkers) {
        const pinsGroup = markerGroup.current
        pinsGroup.removeAll() // clear prev markers

        const markers: H.map.Object[] = []

        polygons.forEach((polygon) => {
          const center = polygon.getBoundingBox().getCenter()
          const marker = new H.map.Marker(
            { lat: center.lat, lng: center.lng },
            { icon: pinMap.pinNavyBlue },
          )
          marker.setData(polygon)
          marker.addEventListener('pointerenter', onPointerEnter)
          markers.push(marker)
        })

        pinsGroup.addObjects(markers)
        map.addObject(pinsGroup)

        map.getElement()?.addEventListener('pointerleave', onPointerLeave)
      }

      polygons.forEach((polygon, index) => {
        const polyData = {
          ...geofences[index],
          geofence: '',
        }

        polygon.setData(polyData)
      })

      const polyGroup = polygonsGroup.current
      polyGroup.addObjects(polygons)

      map.addObject(polyGroup)

      let boundingBox = polyGroup.getBoundingBox()
      boundingBox = boundingBox ? bboxAll(boundingBox, 0.2) : boundingBox

      setBbox(boundingBox)

      if (setBounds) {
        map.addEventListener('dragend', () => {
          let bounds = map.getViewModel().getLookAtData().bounds
          setNewPsn(bounds)
        })
        map.addEventListener('pointermove', () => {
          let bounds = map.getViewModel().getLookAtData().bounds
          setNewPsn(bounds)
        })

        if (boundingBox) {
          let bounds = boundingBox
          if (edit && newPsn) {
            bounds = newPsn
          }

          zoomHandler(bounds, true)
        }
      }

      setPolygons(polygons)

      // User this for Tag/Geofence markers clustering
      // startClustering()
    }

    return () => {
      dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, geofences])

  useEffect(() => {
    if (!ui || !renderLocationBtn) return
    // add zoom button
    addLocationButton(ui, () => zoomHandler(bbox, true))
  }, [ui, bbox, renderLocationBtn, zoomHandler])

  useEffect(() => {
    const setVisible = (value: boolean) => {
      polygons.forEach((polygon) => polygon.setVisibility(value))
      markerGroup.current
        .getObjects()
        .forEach((marker) => marker.setVisibility(value))
    }

    if (geofencesVisible === true) setVisible(true)

    if (geofencesVisible === false) setVisible(false)
  }, [geofencesVisible, polygons])

  useEffect(() => {
    if (!map || !zoomTo) return

    for (const polygon of polygons) {
      if (polygon.getData()?.id === zoomTo) {
        map.getViewModel().setLookAtData(
          {
            bounds: bboxAll(polygon.getBoundingBox(), 0.5),
          },
          false,
        )

        break
      }
    }
    // eslint-disable-next-line
  }, [map, zoomTo, polygons])

  return <></>
}

export default React.memo(GeofenceRenderer)
