import { useEffect, useMemo, useState } from 'react'
import { convertDataEpochToDate } from '../../utils'
import { NoDataLabels } from '../../enums'

const ColumnsIds = {
  Action: 'action',
  Mac: 'mac',
  CargoName: 'cargo_tag_name',
  Customer: 'customer_name',
  LastReported: 'last_reported_date',
  Created: 'created_date',
}

interface Row {
  data: ICargoTag
  columns: Array<{ columnId: string; label: string | number }>
}

interface Column {
  label: string
  id: string
  control: string | boolean
  checked?: boolean
}

export const useColumns = (unassigned: boolean, selected: boolean) => {
  const [columns, setColumns] = useState<Array<any>>([])

  useEffect(() => {
    const columnsList = []

    const labelVariant = !unassigned ? '' : 'All'
    const controlVariant = unassigned && 'checkbox'
    const customerDefinition = !unassigned
      ? 'Customer Name'
      : 'Last Known Customer'

    columnsList.push({
      label: labelVariant,
      id: ColumnsIds.Action,
      control: controlVariant,
      checked: selected,
    })
    columnsList.push({
      label: 'MAC',
      id: ColumnsIds.Mac,
      control: 'sort',
      isDraggable: true,
    })

    if (!unassigned) {
      columnsList.push({
        label: 'Cargo Name',
        id: ColumnsIds.CargoName,
        control: 'sort',
        isDraggable: true,
      })
    }

    columnsList.push({
      label: customerDefinition,
      id: ColumnsIds.Customer,
      control: 'sort',
      isDraggable: true,
    })
    columnsList.push({
      label: 'First Reported',
      id: ColumnsIds.Created,
      control: 'sort',
      isDraggable: true,
    })
    columnsList.push({
      label: 'Last Reported',
      id: ColumnsIds.LastReported,
      control: 'sort',
      isDraggable: true,
    })

    setColumns(columnsList)
  }, [selected, unassigned])
  return columns
}

export const useRows = (
  tags: Array<ICargoTag>,
  unassigned: boolean,
  selectAll: boolean,
  selectedRows: Array<number>,
) => {
  const [rows, setRows] = useState<Array<any>>([])

  useMemo(() => {
    const rws = tags.map((el, i) => {
      const columns = []

      columns.push({
        columnId: ColumnsIds.Action,
        control: unassigned ? 'checkbox' : null,
        hi_id: el.hi_id,
        checked: selectAll
          ? true
          : selectedRows.indexOf(el.hi_id) > -1
          ? true
          : false,
      })
      columns.push({
        columnId: ColumnsIds.Mac,
        label: el.mac,
      })

      if (!unassigned) {
        columns.push({
          columnId: ColumnsIds.CargoName,
          label: el.cargo_tag_name ?? NoDataLabels.DASH,
        })
      }

      columns.push({
        columnId: ColumnsIds.Customer,
        label: el.customer_name ? el.customer_name : NoDataLabels.DASH,
      })
      columns.push({
        columnId: ColumnsIds.Created,
        label: el.first_reported_date
          ? convertDataEpochToDate(el.first_reported_date, null, null, true)
          : NoDataLabels.DASH,
      })
      columns.push({
        columnId: ColumnsIds.LastReported,
        label: el.last_reported_date
          ? convertDataEpochToDate(el.last_reported_date, null, null, true)
          : NoDataLabels.DASH,
      })

      return {
        id: i,
        data: el,
        columns: columns,
      }
    })

    setRows(rws)
  }, [tags, selectAll, selectedRows, unassigned])
  return rows
}

export const useAssign = (data: any[], pagedTags: any, unassigned: boolean) => {
  const [selectedMacs, setSelectedMacs] = useState<
    Array<{ label: string; id: number }>
  >([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [body, setBody] = useState<Array<{
    hi_id: number
    customer_id: number
  }> | null>(null)
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | string>(
    '',
  )
  const [selectedRows, setSelectedRows] = useState<Array<number>>([])

  const rows = useRows(pagedTags, unassigned, selectAll, selectedRows)
  const columns = useColumns(unassigned, selectAll)

  const getMACS = (macs: { label: string; id: number }[]) => {
    let str = ''
    macs.forEach((mac, i, arr) => {
      if (i === arr.length - 1) str += ` ${mac.label}`
      else str += ` ${mac.label},`
    })
    return str
  }

  const customersWithTagsSubscription = useMemo(() => {
    return data
      .filter(
        (el: { cargo_tags_subscription: boolean }) =>
          el.cargo_tags_subscription,
      )
      .map((e: { id: number; name: string }) => ({ id: e.id, label: e.name }))
  }, [data])

  const chosenCustomerName = useMemo(() => {
    if (selectedCustomerId)
      return customersWithTagsSubscription.find(
        (el: { id: string | number }) => el.id === selectedCustomerId,
      )?.label
  }, [customersWithTagsSubscription, selectedCustomerId])

  const onRequestBody = (customer_id: number) => {
    setSelectedCustomerId(customer_id)
    const body = selectedRows.map((el) => ({ hi_id: el, customer_id }))
    const macs = selectedRows.map((el) => {
      const mac = rows.find((tag) => tag.data.hi_id === el).data.mac
      return { label: mac, id: el }
    })
    setBody(body)
    setSelectedMacs(macs)
  }

  const onClearAssignInfo = () => {
    setSelectedRows([])
    setBody(null)
    setSelectedMacs([])
    setSelectedCustomerId('')
  }

  const onCheckRowColumn = (id: string, checked: boolean) => {
    setSelectAll(checked)
    const selected = rows.map((el) => el.data.hi_id)
    setSelectedRows(selected)
    if (!checked) {
      setSelectedRows([])
    }
  }

  const onCheckRowItem = (item: ICargoTag) => {
    const { hi_id } = item
    if (selectedRows.includes(hi_id)) {
      const updatedSelected = selectedRows.filter((el) => el !== hi_id)
      setSelectedRows(updatedSelected)
    } else if (selectAll) {
      const selected = rows.map((el) => el.data.hi_id)
      setSelectedRows(selected)
    } else {
      let array = [...selectedRows, hi_id]
      setSelectedRows(array)
    }
    setSelectAll(false)
  }

  return {
    getMACS,
    selectedMacs,
    selectAll,
    setBody,
    body,
    setSelectAll,
    setSelectedMacs,
    customersWithTagsSubscription,
    chosenCustomerName,
    selectedCustomerId,
    setSelectedCustomerId,
    onRequestBody,
    selectedRows,
    setSelectedRows,
    rows,
    columns,
    onClearAssignInfo,
    onCheckRowColumn,
    onCheckRowItem,
  }
}
