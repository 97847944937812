import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import ApplicationRow from '../ReportParametersRows/ApplicationRow'

const UserLoginsReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <ApplicationRow value={props.parameters.application} />
    </>
  )
}
export default UserLoginsReportParameters
