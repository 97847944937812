import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import HealthStatusRow from '../ReportParametersRows/HealthStatusRow'
import UsersRow from '../ReportParametersRows/UsersRow'
import NotificationTypesRow from '../ReportParametersRows/NotificationTypesRow'

const NotificationsSubscriptionsReportParameters: React.FC<ReportParameterGroupProps> = (
    props: ReportParameterGroupProps,
) => {
    return (
        <>
            <UsersRow value={props.parameters.usernames} />
            <HealthStatusRow value={props.parameters.warning_filter} />
            <NotificationTypesRow value={props.parameters.notification_types} />
        </>
    )
}
export default NotificationsSubscriptionsReportParameters
