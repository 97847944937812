import React from 'react'
import RivataDropdown from '../../components/RivataDropdown'

const dropdownItems = [
  {
    id: 4,
    label: 'One Third',
  },
  {
    id: 6,
    label: 'One Half',
  },
  {
    id: 8,
    label: 'Two Thirds',
  },
  {
    id: 12,
    label: 'Full Width',
  },
]

type Props = {
  onSelect: (width: number) => void
  selected: number
  disabled?: boolean
}

const WidthDropdown: React.FC<Props> = ({ onSelect, selected, disabled }) => {
  return (
    <RivataDropdown
      size='sm'
      items={dropdownItems}
      onSelect={onSelect}
      selected={selected}
      caret={true}
      disabled={disabled}
    />
  )
}

export default WidthDropdown
