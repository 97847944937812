import { ReportStatus } from "../../enums";
import { getClearLabel } from "../../utils";
import { MenuItemType } from "../Menu/MenuItem";

export const reportStatusItems: Array<MenuItemType> = [
    { id: ReportStatus.QUEUED, label: getClearLabel(ReportStatus.QUEUED), type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: ReportStatus.IN_PROGRESS, label: getClearLabel(ReportStatus.IN_PROGRESS), type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: ReportStatus.COMPLETED, label: getClearLabel(ReportStatus.COMPLETED), type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: ReportStatus.FAILED, label: getClearLabel(ReportStatus.FAILED), type: "checkbox", markType: "check", checked: false, parentId: null },
]
