import { IColumn, IRow } from '../../../components/RivataGrid'
import { getClearLabel, convertDataEpochToDate } from '../../../utils'


interface IdvHealthReportColumns {
    (
        columns: Array<string>
    ): Array<IColumn>
}

export const dvHealthReportColumns: IdvHealthReportColumns = (columns) => {
    const parsedColumns: Array<IColumn> = []
    columns.forEach((key, i) => {
        if ([0].includes(i)) {
            parsedColumns.push({ key: "customer_name", name: key, minWidth: 100, width: 250, frozen: true });
            return;
        } else if ([1].includes(i)) {
            parsedColumns.push({ key: "asset_name", name: key, minWidth: 100, width: 250, frozen: true });
            return;
        } else if (key.toLocaleLowerCase() === "vin") {
            parsedColumns.push({ key, name: key.toUpperCase(), minWidth: 100, width: 200 })
            return
        }

        if (i > 17) {
            let str = ""
            let arr = key.split(" ")

            if (arr[0] === "PSM") {
                str = arr.join(" ")
            } else {
                arr[0] = getClearLabel(arr[0])
                str = arr.join(" ")
            }

            parsedColumns.push({ key, name: str, minWidth: 100, width: 200 })
            return
        }

        parsedColumns.push({ key, name: getClearLabel(key), minWidth: 100, width: 200 })
    })

    return parsedColumns
}

interface IdvHealthReportRows {
    (
        columns: Array<string>,
        rows: Array<IRow>,
        timezone: string
    ): Array<IRow>
}

export const dvHealthReportRows: IdvHealthReportRows = (columns, rows, timezone) => {
    const parsedRows = rows.map(item => {
        let row: IRow = {}
        columns.forEach((col) => {
            row[col] = "---"
        })
        row = { ...row, ...item, "customer_name": item["Customer Name"] }
        row = { ...row, ...item, "asset_name": item["Asset Name"] }
        row["Report Date"] = convertDataEpochToDate(+row["Report Date"], null, null, true)
        row["Timezone"] = timezone
        row["Last Location Date Time"] = row["Last Location Date Time"] ? convertDataEpochToDate(+row["Last Location Date Time"], null, null, true) : "---"

        for (let i = 0; i < columns.length; i++) {
            const key = columns[i]
            const value = row[key]
            if (i >= 14) {
                if (!value) {
                    row[key] = "---"
                }
                else if (Number.isInteger(+value) && !["---", ""].includes(value)) {
                    row[key] = convertDataEpochToDate(+value, null, null, true)
                }
                else {
                    row[key] = value
                }

            } else {
                if (!value) {
                    row[key] = "---"
                }
            }
        }

        delete row["Asset Name"]
        delete row["Customer Name"]
        
        return row
    })

    return parsedRows
}