import React, { useCallback, useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Button from '../../componentsV2/Button'
import StatusAlert from '../../components/StatusAlert'
import './styles.scss'
import GroupItem from './GroupItem'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { Spinner } from 'reactstrap'
import GeofenceGroupModal from './GeofenceGroupModal'
import { IGeofenceGroupFormValues } from './types'
import InfoModal from '../../components/InfoModal'
import { getStatusObj } from '../../utils/utils'
import DeleteModal from '../../components/DeleteModal'
import RivataPagination from '../../components/RivataPagination'
import LimitDropdown from '../../components/LimitDropdown'
import { makeScrollToY } from '../../utils/utils'

const initialGeofenceGroupFormValues: IGeofenceGroupFormValues = {
  id: null,
  name: '',
}

const GeofenceGroupsModule = () => {
  const {
    selectedCustomersList,
    geofenceGroups: { isLoading, data, totalCount, status, limit, offset },
    locale,
  } = useTypedSelector((state) => ({
    geofenceGroups: state.geofenceGroups.geofenceGroups,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    locale: state.whitelabel.locale,
  }))
  const {
    setGeofenceGroupsOffset,
    fetchGeofenceGroupsInfo,
    createGeofenceGroup,
    updateGeofenceGroup,
    setGeofenceGroupsStatus,
    deleteGeofenceGroup,
    setGeofenceGroupsLimit,
  } = useActions()

  const history = useHistory()

  const [groupsList, setGroupsList] = useState<Array<any> | null>([])
  const [geofenceGroupModalVisible, setGeofenceGroupModalVisible] =
    useState(false)
  const [deleteGeofenceGroupData, setDeleteGeofenceGroupData] = useState(
    initialGeofenceGroupFormValues,
  )
  const [geofenceGroupInitialValues, setGeofenceGroupInitialValues] = useState(
    initialGeofenceGroupFormValues,
  )

  useEffect(() => {
    if (selectedCustomersList.length === 1) {
      fetchGeofenceGroupsInfo()
    }
  }, [setGeofenceGroupsOffset, fetchGeofenceGroupsInfo, selectedCustomersList])

  useEffect(() => {
    setGroupsList(data)
  }, [data])

  const toggleGeofenceGroupModal = () => {
    setGeofenceGroupModalVisible(!geofenceGroupModalVisible)
  }

  const onCreateGeofenceGroupClick = () => {
    setGeofenceGroupInitialValues(initialGeofenceGroupFormValues)
    toggleGeofenceGroupModal()
  }

  const onEditGeofenceGroupClick = (values: IGeofenceGroupFormValues) => {
    setGeofenceGroupInitialValues(values)
    toggleGeofenceGroupModal()
  }

  const onDeleteGeofenceGroupClick = (values: IGeofenceGroupFormValues) => {
    setDeleteGeofenceGroupData(values)
  }

  const onDeleteGeofenceGroupSubmit = async () => {
    if (deleteGeofenceGroupData.id) {
      const res: any = await deleteGeofenceGroup(deleteGeofenceGroupData.id)

      if (res.ok) {
        setDeleteGeofenceGroupData(initialGeofenceGroupFormValues)
      }
    }
  }

  const onGeofenceGroupSubmit = async (
    formValues: IGeofenceGroupFormValues,
  ) => {
    let res: any
    const selectedCustomerId = selectedCustomersList[0].id
    if (formValues.id) {
      res = await updateGeofenceGroup(formValues.name, formValues.id)
    } else {
      res = await createGeofenceGroup(formValues.name, selectedCustomerId)
      if (res) history.push(`/geofence-groups/${res}?tab=unassigned`)
    }

    if (res.ok) {
      toggleGeofenceGroupModal()
    }
  }

  const onPaginationClick = useCallback(
    (newPage: number) => {
      setGeofenceGroupsOffset(newPage * limit)
      makeScrollToY(0)
    },
    [limit, setGeofenceGroupsOffset],
  )

  return (
    <>
      <div className='row container-fluid justify-content-center align-items-center'>
        <Col md='4 d-flex justify-content-start p-0'>
          <h5>All groups ({totalCount})</h5>
        </Col>
        <Col md='4 d-flex justify-content-end p-0'>
          <Button
            btnType='btn__primary-filled'
            onClick={onCreateGeofenceGroupClick}
          >
            Create new group
          </Button>
        </Col>
      </div>
      <div className='row container-fluid justify-content-center align-items-center'>
        <Col md='8 border' className='mt-3 pt-3 bg-white rounded'>
          {isLoading ? (
            <Col md='12 d-flex justify-content-center pb-3'>
              <Spinner size='md' className='mr-2' />
            </Col>
          ) : (
            <>
              {!!data?.length && (
                <div className='d-flex'>
                  <RivataPagination
                    totalCount={totalCount}
                    pageLimit={limit}
                    setCurrentPage={onPaginationClick}
                    currentPage={offset / limit}
                  />

                  <LimitDropdown
                    pageLimit={limit}
                    setPageLimit={setGeofenceGroupsLimit}
                  />
                </div>
              )}

              {!groupsList || groupsList.length === 0 ? (
                <StatusAlert
                  customText='No Geofence Groups'
                  color='success'
                  statusCode={undefined}
                  statusText={undefined}
                />
              ) : (
                data &&
                data.map((group: any, idx: number) => (
                  <GroupItem
                    key={group.id}
                    group={group}
                    onEditClick={onEditGeofenceGroupClick}
                    onDeleteClick={onDeleteGeofenceGroupClick}
                  />
                ))
              )}

              {!!data?.length && (
                <div className='d-flex justify-content-end'>
                  <RivataPagination
                    totalCount={totalCount}
                    pageLimit={limit}
                    setCurrentPage={onPaginationClick}
                    currentPage={offset / limit}
                  />

                  <LimitDropdown
                    pageLimit={limit}
                    setPageLimit={setGeofenceGroupsLimit}
                  />
                </div>
              )}
            </>
          )}
        </Col>
      </div>
      {geofenceGroupModalVisible && (
        <GeofenceGroupModal
          disabled={isLoading}
          initialValues={geofenceGroupInitialValues}
          locale={locale}
          onClose={toggleGeofenceGroupModal}
          onCreate={onGeofenceGroupSubmit}
          open={true}
          title={geofenceGroupInitialValues.id ? 'Update' : 'Create'}
        />
      )}

      {!status.ok && (
        <InfoModal
          open={true}
          header='Geofence Group Info'
          message={status.message}
          onConfirm={() => setGeofenceGroupsStatus(getStatusObj())}
        />
      )}

      {deleteGeofenceGroupData.id && (
        <DeleteModal
          open={true}
          disabled={false}
          header={`Delete Geofence Group "${deleteGeofenceGroupData.name}"?`}
          message={'This cannot be undone!'}
          locale={locale}
          onDelete={() => onDeleteGeofenceGroupSubmit()}
          onCancel={() =>
            setDeleteGeofenceGroupData(initialGeofenceGroupFormValues)
          }
        />
      )}
    </>
  )
}

export default GeofenceGroupsModule
