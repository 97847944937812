import React, { useEffect, useState } from 'react'
import DataGrid, { Row, SortColumn } from 'react-data-grid'
import { IColumn, IRow } from '.'

import './style.scss'
import { orderBy } from 'lodash'

interface Props {
  columns: Array<IColumn>
  rows: Array<IRow>
  height?: string
  selectedRowId?: number
  allowHorizontalScroll?: boolean
  enableVirtualization?: boolean
}

// grid with ability to expand subgrid https://adazzle.github.io/react-data-grid/#/master-detail
const DetailsGrid: React.FC<Props> = ({
  columns,
  rows,
  height,
  selectedRowId,
  allowHorizontalScroll = false,
  enableVirtualization = false,
}) => {
  const [detailsRows, setDetailsRows] = useState(rows)
  const [sortColumns, setSortColumns] = useState<Array<SortColumn>>([])

  useEffect(() => {
    setDetailsRows(rows)
  }, [rows])

  useEffect(() => {
    if (sortColumns.length) {
      const direction = sortColumns[0].direction === 'ASC' ? 'asc' : 'desc'
      const key = sortColumns[0].columnKey
      setDetailsRows(orderBy(rows, [key], [direction]))
    }
  }, [sortColumns, rows, setDetailsRows])

  function onRowsChange(
    rows: Array<IRow>,
    { indexes }: { indexes: Array<number> },
  ) {
    const row = rows[indexes[0]]

    if (row.type === 'MASTER') {
      if (!row.expanded) {
        rows.splice(indexes[0] + 1, 1)
      } else {
        rows.splice(indexes[0] + 1, 0, {
          type: 'DETAIL',
          id: row.id + 100,
          parentId: row.id,
        })
      }
      setDetailsRows(rows)
    }
  }

  return (
    <DataGrid
      className='rdg-light rivata-grid fill-grid react-grid-HeaderCell-sortable' // light color if dart theme set
      style={{
        height,
        overflowX: allowHorizontalScroll ? undefined : 'hidden',
      }}
      columns={columns}
      rows={detailsRows}
      defaultColumnOptions={{
        sortable: false,
        resizable: true,
      }}
      sortColumns={sortColumns}
      onSortColumnsChange={(d) => setSortColumns(d)}
      onRowsChange={onRowsChange}
      rowKeyGetter={(row) => row.id}
      headerRowHeight={45}
      rowHeight={(args) =>
        args.type === 'ROW' && args.row.type === 'DETAIL' ? 300 : 45
      }
      enableVirtualization={enableVirtualization}
      rowClass={(row: any) => {
        return row.id === selectedRowId ? 'row__highlight' : ''
      }}
      // renderers={(props:any) => <Row {...props} className={props.row.id === selectedRowId ? "row__highlight" : ""} />}
    />
  )
}

export default DetailsGrid
