import React, { useCallback, useMemo, useRef, useState } from "react"
import { debounce, cloneDeep } from "lodash"

import Button from "../../../componentsV2/Button"
import { MenuItemType } from "../../../componentsV2/Menu/MenuItem"
import { useQuery } from "../../../hooks/useQuery"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import AssetGroupsFilter from "../../../componentsV2/AssetGroupsFilter"

import {
  sensorStatusItems,
} from "../../../componentsV2/HealthStatusFilter/items"
import AssetTypeFilter from "../../../componentsV2/AssetTypeFilter"
import HealthStatusFilter from "../../../componentsV2/HealthStatusFilter"
import AssetNameVinFilter from "../../../componentsV2/AssetNameVinFilter"

type DropdownFilterType = "asset_subtype" | "status_2" | "asset_group"

interface Props {
  onFilter: (filters: string) => void
}

const debouncer = debounce((func: () => void) => func(), 400)

const wrapperStyle = { width: "470px" }
const initialSelection = {
  asset_subtype: [],
  status_2: [],
  asset_group: [],
}

const getInitStatesFromFilters = (filters: string[]) => {
  const searchParams: Record<string, Array<string>> = cloneDeep(initialSelection)

  const actualQuery = new URLSearchParams(filters.map(f => ["filters", f])).toString()

  const assetSearchValue = filters.find(f => f.includes("asset_name_vin="))?.split("=")[1] ?? ""

  const dropdownItems = {
    sensorStatusItems: cloneDeep(sensorStatusItems),
  }

  const selectedItems: Record<DropdownFilterType, Array<MenuItemType>> = cloneDeep(initialSelection)

  if (assetSearchValue) searchParams.asset_name_vin = [assetSearchValue]

  filters.filter(f => f.includes("status_2")).map(f => f.split("=")[1]).forEach(id => {
    const item = dropdownItems.sensorStatusItems.find(item => item.id === id)

    if (item) {
      item.checked = true
      searchParams.status_2.push(item.id)
      selectedItems.status_2.push(item)

      const parentItem = dropdownItems.sensorStatusItems.find(p => p.id === item.parentId);
      if (parentItem && !parentItem.checked) {
        parentItem.checked = true;
      }
    }
  })

  filters.filter(f => f.includes("asset_group")).map(f => f.split("=")[1]).forEach(id => {
    searchParams.asset_group.push(id)
  })

  return { searchParams, actualQuery, assetSearchValue, dropdownItems, selectedItems }
}

const GeofenceDetailsFilterMenu: React.FC<Props> = ({ onFilter }) => {
  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList
  }))

  const query = useQuery()

  const initStates = useMemo(() => getInitStatesFromFilters(query.getAll("filters")), [query])

  const searchParams = useRef<Record<string, Array<string>>>(
    cloneDeep(initStates.searchParams)
  )
  const actualQuery = useRef(cloneDeep(initStates.actualQuery))
  const groupsFilterRef = useRef<any>(null);
  const assetTypeFilterRef = useRef<any>(null);
  const healthStatusFilterRef = useRef<any>(null);
  const assetNameVinFilterRef = useRef<any>(null);
  const [dropdownItems, setDropdownItems] = useState(cloneDeep(initStates.dropdownItems))
  const [assetSearchValue] = useState(cloneDeep(initStates.assetSearchValue))
  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value;

      if ((searchParams.current[key]?.length !== tempValue?.length)
        || !searchParams.current[key]?.every(function (element, index) {
          return element === tempValue[index];
        })) {
        if (!tempValue || !tempValue.length) {
          delete searchParams.current[key]
        } else if (Array.isArray(tempValue)) {
          searchParams.current[key] = tempValue
        } else {
          searchParams.current[key] = [tempValue]
        }

        const query = new URLSearchParams()

        Object.entries(searchParams.current).forEach((filter) => {
          const key = filter[0]
          filter[1].forEach((value) => query.append("filters", `${key}=${value}`))
        })

        actualQuery.current = query.toString()

        onFilter(actualQuery.current)
      }
    },
    [onFilter]
  )

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value))
  }

  const handleFiltersReset = () => {
    // make filtering only if filter query params are different from prevoius
    if (actualQuery.current === "") return

    setDropdownItems({
      sensorStatusItems: cloneDeep(sensorStatusItems),
    })

    actualQuery.current = ""
    searchParams.current = cloneDeep(initialSelection)
    groupsFilterRef?.current?.clearSelection()
    assetTypeFilterRef?.current?.clearSelection()
    healthStatusFilterRef?.current?.clearSelection()
    assetNameVinFilterRef?.current.clearValue()
    onFilter("")
  }

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id)
        )
      )
    },
    [updateFilters]
  )

  return (
    <>
      <div className="d-flex flex-wrap">
        <AssetNameVinFilter
          wrapperStyle={wrapperStyle}
          onChange={onSearchByStringValue}
          ref={assetNameVinFilterRef}
          wrapperClassName="mr-3"
          initialValue={assetSearchValue}
        />
      </div>

      <br />

      <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex flex-wrap">
          <AssetTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={assetTypeFilterRef}
          />
          <HealthStatusFilter
            onDropdownItemSelect={onDropdownItemSelect}
            clearSelectionOnReset
            overrideDefaultItems={dropdownItems.sensorStatusItems}
            ref={healthStatusFilterRef} />
          <AssetGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={groupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(",")}
            selectedValueIds={initStates.searchParams.asset_group}
            clearSelectionOnReset
          />
        </div>

        <div>
          <Button onClick={handleFiltersReset}>Clear</Button>
        </div>
      </div>
    </>
  )
}

export default React.memo(GeofenceDetailsFilterMenu)
