import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Label, Col, Button } from 'reactstrap'
import { useFormikContext } from 'formik'

import RivataGrid from '../../../../components/RivataGrid'
import BackendSearchInput from '../../../../components/BackendSearchInput'

import {
  useScheduledReportsUsersColumns,
  useScheduledReportsUsersRows,
} from '../../hooks'

import { cloneDeep } from 'lodash'
import ManageScheduledReportUsersModal from '../ManageScheduledReportUsersModal'

import './style.scss'

interface Props {
  users: Array<IUserShortFormat>
  bccUsersToEmail: Array<IUserShortFormat>
  setBccUsersToEmail: React.Dispatch<React.SetStateAction<IUserShortFormat[]>>
  setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
  parameters: any
  setParameters: React.Dispatch<React.SetStateAction<any>>
  selectedCustomerId: number | null
  preselectedCustomerKeys?: string
  internalReports: boolean
  showModalInfo: () => void
  isDisabled: boolean
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const ScheduledReportUsers: React.FC<Props> = ({
  users,
  bccUsersToEmail,
  setBccUsersToEmail,
  setIsFormTouched,
  parameters,
  setParameters,
  selectedCustomerId,
  preselectedCustomerKeys,
  internalReports,
  showModalInfo,
  isDisabled,
  setIsDisabled,
}) => {
  const context = useFormikContext()
  const [bccUsersToManage, setBccUsersToManage] =
    useState<Array<number> | null>(null)

  useEffect(() => {
    if (Object.keys(context.touched).length > 0) setIsFormTouched(true)
  }, [context.touched, setIsFormTouched])

  const handleBccMenuSelect = useCallback(
    (el: any) => {
      setIsFormTouched(true)
      setBccUsersToEmail((prev) => {
        if (!prev || !prev.length) {
          if (isDisabled) {
            showModalInfo()
            setIsDisabled(false)
          }

          return [el]
        }

        if (prev.find((oldItem) => oldItem.id === el.id)) return prev

        return [...prev, el]
      })
    },
    [
      setBccUsersToEmail,
      setIsFormTouched,
      isDisabled,
      setIsDisabled,
      showModalInfo,
    ],
  )

  // TODO: Modal user selection check

  const handleBccRemoveUser = useCallback(
    (id: number) => {
      setIsFormTouched(true)
      setBccUsersToEmail((prev) => {
        if (!prev) return prev

        const newArr = [...prev]
        const idx = newArr.findIndex((el) => el.id === id)

        if (idx > -1) newArr.splice(idx, 1)

        if (!newArr.length) setIsDisabled(true)

        return newArr
      })
    },
    [setBccUsersToEmail, setIsFormTouched, setIsDisabled],
  )

  useEffect(() => {
    if (parameters && parameters.bcc_users !== undefined) {
      if (bccUsersToEmail.length === 0) {
        let newParameters = cloneDeep(parameters)
        newParameters.bcc_users = []
        setParameters(newParameters)
      } else {
        let newParameters = cloneDeep(parameters)
        newParameters.bcc_users = bccUsersToEmail.map(
          ({ email, id, username, email_verified }) => ({
            email: email,
            user_id: id,
            username: username,
            email_verified: email_verified,
          }),
        )
        setParameters(newParameters)
      }
    }
  }, [bccUsersToEmail, parameters, setParameters])

  const bccColumns = useScheduledReportsUsersColumns(
    context.values,
    handleBccRemoveUser,
  )
  const bccUsersRows = useScheduledReportsUsersRows(bccUsersToEmail)
  const bccUsersGridHeight = useMemo(
    () => (bccUsersRows.length > 6 ? 250 : bccUsersRows.length * 35 + 47),
    [bccUsersRows.length],
  )

  const searchUser = (value: string) => {
    return new Promise(function (resolve, reject) {
      resolve(
        users.filter(
          (u) =>
            (u.username && u.username.toLowerCase().includes(value)) ||
            (u.first_name && u.first_name.toLowerCase().includes(value)) ||
            (u.last_name && u.last_name.toLowerCase().includes(value)) ||
            (u.email && u.email.toLowerCase().includes(value)),
        ),
      )
    })
  }

  const userSelectDisabled = useMemo(
    () =>
      !internalReports &&
      !preselectedCustomerKeys?.length &&
      !selectedCustomerId,
    [internalReports, selectedCustomerId, preselectedCustomerKeys],
  )

  return (
    <div className='report-users'>
      <Col md='12' className='report-users-search mb-3'>
        <div className='d-flex justify-content-between mb-3'>
          <div className='w-50'>
            <Label>Recipients:</Label>

            <BackendSearchInput
              disabled={userSelectDisabled}
              endPointConfig={{ search: searchUser, isUserItem: true }}
              placeholder='Search User'
              onSelect={handleBccMenuSelect}
              width='355px'
            />
          </div>

          <div className='d-flex align-items-end'>
            <Button
              disabled={userSelectDisabled}
              className='manage-notifications__btn mr-2'
              type='button'
              onClick={() =>
                setBccUsersToManage(
                  bccUsersToEmail ? bccUsersToEmail.map((el) => el.id) : [],
                )
              }
            >
              Select Users
            </Button>
            <Button
              className='manage-users__btn'
              type='button'
              color='danger'
              disabled={bccUsersToEmail?.length === 0}
              onClick={() => {
                if (bccUsersToEmail?.length) {
                  setIsFormTouched(true)
                  setBccUsersToEmail([])
                }
              }}
            >
              Remove Users
            </Button>
          </div>
        </div>

        {bccUsersToManage && (
          <ManageScheduledReportUsersModal
            isOpen={true}
            users={users}
            selectedUserIds={bccUsersToManage}
            onCancel={() => setBccUsersToManage(null)}
            singleOption={false}
            onConfirm={(selectedUsers) => {
              setBccUsersToEmail(selectedUsers)
              setIsFormTouched(true)
              setBccUsersToManage(null)

              if (
                !bccUsersToEmail.length &&
                selectedUsers.length &&
                isDisabled
              ) {
                showModalInfo()
                setIsDisabled(false)
              }

              if (bccUsersToEmail.length && !selectedUsers.length) {
                setIsDisabled(true)
              }
            }}
          />
        )}

        {bccUsersToEmail && bccUsersToEmail.length > 0 && (
          <RivataGrid
            columns={bccColumns}
            rows={bccUsersRows}
            height={`${bccUsersGridHeight}px`}
          />
        )}
      </Col>
    </div>
  )
}

export default ScheduledReportUsers
