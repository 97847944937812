import { getClearLabel } from '../../utils'
import { MenuItemType } from '../Menu/MenuItem'

const createNumberedAxelsItems = (drive: number, trailer: number) => {
  const res: Array<MenuItemType> = []

  const createAxle = (type: 'DRIVE' | 'TRAILER', id: number) => {
    const items = [
      `${type}_LEFT_${id}`,
      `${type}_LEFT_${id}_INNER`,
      `${type}_LEFT_${id}_OUTER`,
      `${type}_RIGHT_${id}`,
      `${type}_RIGHT_${id}_INNER`,
      `${type}_RIGHT_${id}_OUTER`,
    ]

    return [
      {
        id: `#${type}_${id}`,
        label: getClearLabel(`${type}_${id}`),
        type: 'checkbox',
        markType: 'check',
        checked: false,
        parentId: null,
        subMenuExpanded: false,
      },
      ...items.map((item) => {
        return {
          id: item,
          label: getClearLabel(item),
          type: 'checkbox',
          markType: 'check',
          checked: false,
          parentId: `#${type}_${id}`,
        }
      }),
    ] as Array<MenuItemType>
  }

  for (let i = 1; i <= drive; i++) {
    res.push(...createAxle('DRIVE', i))
  }

  for (let i = 1; i <= trailer; i++) {
    res.push(...createAxle('TRAILER', i))
  }

  return res
}

export const sensorStatusItems: Array<MenuItemType> = [
  //steer axle
  {
    id: '#STEER',
    label: 'Steer',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: false,
  },
  {
    id: 'STEER_LEFT',
    label: 'Steer Left',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: '#STEER',
  },
  {
    id: 'STEER_RIGHT',
    label: 'Steer Right',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: '#STEER',
  },

  // drive and trailer axles
  ...createNumberedAxelsItems(5, 5),

  // RED_SUPPLY_LINE
  {
    id: 'RED_SUPPLY_LINE',
    label: 'Red Supply Line',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },

  // CHASSIS
  {
    id: 'CHASSIS',
    label: 'Chassis',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },

  // UNKNOWN
  {
    id: 'UNKNOWN',
    label: 'Unknown',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },
]
