import React from 'react'
import { debounce } from 'lodash'
import { connect } from 'react-redux'

import gpsApi from '../../services/api/ApiGroups/GpsApi'
import { history } from '../../jwt/_helpers'
import GeofenceRenderer from '../../modules/Geofences/renderer/GeofenceRenderer'
import {
  H,
  bboxAll,
  getMarkerPinMap,
  behaviorUi,
  LayerTypes,
  getTooltipCoordinates,
  getPinData,
  addLocationButton,
} from './utils'
import { getGpsDirectinFromDeg } from '../../utils/utils'
import {
  convertDataEpochToDate,
  getWhitelabelColors,
  isValidCoord,
  kmhToMph,
  metersToFt,
  getCurrentEpoch,
  getTimeLabelFromSeconds,
} from '../../utils'
import config from '../../config/appConfig'
import { NoDataLabels } from '../../enums'
import './styles.scss'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

class HereMap extends React.PureComponent {
  mapRef = React.createRef()
  state = {
    hMap: null,
    markersGroup: null,
    markersMap: {},
    layerChoices: null,
    bubble: null,
    whiteLabelColors: getWhitelabelColors(),
    clusteringLayerRef: null,
    unitsOfMeasurementConfig: {},
    ui: null,
    initialBoundsZoom: { zoom: null, bounds: null },
  }

  handleMapLeave = (e) => {
    this.state.bubble.close()
  }

  setZoom = (bbox, zoomLevel) => {
    const { zoom, bounds } = this.state.initialBoundsZoom
    if (zoom && bounds) {
      bbox = bounds
      zoomLevel = zoom
    }
    this.state.hMap
      .getViewModel()
      .setLookAtData({ bounds: bbox, zoom: zoomLevel })
  }

  resize = () => {
    const { hMap } = this.state

    if (hMap) {
      hMap.getViewPort().resize()
    }
  }

  onMapViewChange = (a) => {
    if (this.props.onMapViewChange && a['newValue']['lookAt']) {
      const bounds = a['newValue']['lookAt']['bounds'].getBoundingBox()
      const zoom = a['newValue']['lookAt']['zoom']

      this.props.onMapViewChange(bounds, zoom)
    }
  }

  componentWillUnmount() {
    const { hMap } = this.state

    if (hMap) {
      hMap.removeEventListener('mapviewchange', this.onMapViewChange)
      hMap.dispose()
    }
    window.removeEventListener('resize', this.resize)
  }

  componentDidMount() {
    const { zoom, bounds } = this.props.mapZoomBounds
    if (zoom && bounds) {
      this.setState({ initialBoundsZoom: { zoom, bounds } })
    }

    const platform = new H.service.Platform({
      apikey: config.mapApiKey,
    })
    const engineType = H.Map.EngineType['HARP']
    const defaultLayers = platform.createDefaultLayers({ engineType, lg: 'en' })

    const hMap = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        engineType,
        center: { lat: 41.850033, lng: -87.6500523 }, // center of U.S. default
        zoom: 4,
        pixelRatio: window.devicePixelRatio || 1,
      },
    )

    const markersGroup = new H.map.Group()
    //hMap.addObject(markersGroup);

    window.addEventListener('resize', this.resize)
    const { ui } = behaviorUi(
      hMap,
      defaultLayers,
      this.state.unitsOfMeasurementConfig,
    )

    const bubble = new H.ui.InfoBubble(
      { lng: 13.4, lat: 52.51 },
      {
        content: '',
      },
    )
    bubble.addClass('info-bubble')
    ui.addBubble(bubble)
    bubble.getElement().addEventListener('pointerleave', this.removeInfoBubble)

    ui.getControl('zoom').setVisibility(true)
    ui.getControl('mapsettings').setVisibility(false)

    if (!this.props.geofenceLocationBtn) addLocationButton(ui, null)

    const layerChoices = {
      [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
      [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
    }

    const whiteLabelColors = getWhitelabelColors(this.props.healthColors)

    this.setState({
      hMap,
      markersGroup,
      layerChoices,
      bubble,
      whiteLabelColors,
      ui,
    })
    this.resize()
    this.mapRef.current.addEventListener('mouseleave', this.handleMapLeave)
    hMap.addEventListener(
      'mapviewchange',
      debounce(this.onMapViewChange, 200, { leading: false }),
    )
  }

  selectMarkers = () => {
    const { markersGroup } = this.state
    if (!markersGroup) return

    const markerIconMap = getMarkerPinMap(this.props.markerType)

    markersGroup.getObjects().forEach((marker) => {
      const { pinKey, zIndex } = getPinData(marker)
      // Yes, zIndex here is bottom to top, so backwards of normal html!
      marker.setZIndex(zIndex)
      if (pinKey in markerIconMap) {
        marker.setIcon(markerIconMap[pinKey])
      }
    })
  }

  getTooltipContent = (
    top,
    left,
    labelColor,
    labelTitle,
    label,
    timestamp,
    heading,
    speed,
    elevation,
    latitude,
    longitude,
    actualGeofence = null,
    isLoading = false,
  ) => {
    return `
      <div id="dashboard-buble" style="position: absolute; top: ${top}px; left: ${left}px; padding: 20px">
        <div class="alert-primary show" role="alert" aria-live="assertive" aria-atomic="true" style="background-color: ${labelColor}; border-color: ${labelColor};">
          <div class="label-line"></div>

          <div class="bubble-body">
            <div class="row-wrapper">
              <div class="key">${labelTitle}</div>
              <div class="value">${label}</div>
              ${
                isLoading
                  ? `
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              `
                  : ''
              }
            </div>

            <div class="row-wrapper">
              <div class="key">Date:</div>
              <div class="value">${timestamp}</div>
            </div>

            <div class="row-wrapper">
              <div class="key">Heading:</div>
              <div class="value">${heading}</div>
            </div>

            <div class="row-wrapper">
              <div class="key">Speed:</div>
              <div class="value">${speed}</div>
            </div>

            <div class="row-wrapper">
              <div class="key">Elevation:</div>
              <div class="value">${elevation}</div>
            </div>

            ${
              this.props.geofencesVisible
                ? `<div class="row-wrapper">
              <div class="key">Dwell Time:</div>
              <div class="value">
                ${
                  actualGeofence &&
                  actualGeofence?.name &&
                  actualGeofence?.entry_epoch
                    ? `In '${
                        actualGeofence.name
                      }' geofence for ${getTimeLabelFromSeconds(
                        getCurrentEpoch() - actualGeofence.entry_epoch,
                      )}`
                    : '---'
                }
              </div>
            </div>`
                : ''
            }

            <div class="row-wrapper">
              <div class="key">Location:</div>
              <div class="value">${latitude}, ${longitude}</div>
              <div id="details" class="aligned-cell">Details</div>
            </div>
            
          </div>
        </div>
      </div>
    `
  }

  goToAssetDetails = (vin) => {
    history.push(`/details/${vin}?reducePoints=true`)
  }

  getTooltipContentClustered = (
    top,
    left,
    normalData,
    warningData,
    criticalWarningData,
  ) => {
    let colsCount =
      normalData.length + warningData.length + criticalWarningData.length
    const { whiteLabelColors } = this.state
    const { healthCriticalWarning, healthWarning, healthGood } =
      whiteLabelColors

    return `
      <div id="dashboard-buble" style="position: absolute; top: ${top}px; left: ${left}px; padding: 20px; width: ${
        60 * colsCount
      }">
        <div class="alert-primary show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="label-line"></div>
          <div class="bubble-body-clustered">
            <div class="bubble-content">
              ${
                criticalWarningData.length
                  ? `<div class='bubble-content__column'>
                    ${criticalWarningData
                      .map((data) => {
                        return `
                          <div class="column-container" data-vin=${data.vin}>
                            <div class="tooltip-status-circle" style="background-color: ${healthCriticalWarning}"></div>
                            <div class="column-container__text" title="${data.name}">${data.name}</div>
                          </div>`
                      })
                      .join('')}
                  </div>`
                  : ''
              }
              ${
                warningData.length
                  ? `<div class='bubble-content__column'>
                    ${warningData
                      .map((data) => {
                        return `
                          <div class="column-container" data-vin=${data.vin}>
                            <div class="tooltip-status-circle" style="background-color: ${healthWarning}"></div>
                            <div class="column-container__text" title="${data.name}">${data.name}</div>
                          </div>`
                      })
                      .join('')}
                  </div>`
                  : ''
              }
              ${
                normalData.length
                  ? `<div class='bubble-content__column'>
                    ${normalData
                      .map((data) => {
                        return `
                          <div class="column-container" data-vin=${data.vin}>
                            <div class="tooltip-status-circle" style="background-color: ${healthGood}"></div>
                            <div class="column-container__text" title="${data.name}">${data.name}</div>
                          </div>`
                      })
                      .join('')}
                  </div>`
                  : ''
              }
            </div>
          </div>
        </div>
      </div>
    `
  }

  getAssetGpsDetails = async (assetId) => {
    try {
      return await gpsApi.getAssetGpsDetails(assetId)
    } catch (err) {
      console.log(err)
    }
  }

  addInfoBubble = async (e) => {
    const { bubble, whiteLabelColors } = this.state
    const {
      preferences: { unitsOfMeasurementConfig = {} },
    } = this.props

    if (!bubble) return
    const data = e.target.getData()
    const { id, hasCriticalWarning, hasWarning, latitude, longitude } = data
    const { healthCriticalWarning, healthWarning, healthGood } =
      whiteLabelColors

    const labelColor = hasCriticalWarning
      ? healthCriticalWarning
      : hasWarning
      ? healthWarning
      : healthGood

    const calcData = {
      name: '',
      vin: '',
      formatted_datetime: '',
    }
    const map = document.getElementById('rivata-map-cluster')

    const initialCoords = getTooltipCoordinates(map, calcData, e, -25, -173)
    bubble.setContent(
      this.getTooltipContent(
        initialCoords.top,
        initialCoords.left,
        labelColor,
        'Name:',
        '',
        '',
        '',
        '',
        '',
        latitude,
        longitude,
        true,
      ),
    )
    bubble.setPosition(e.target.getGeometry())
    bubble.open()

    const assetDetails = await this.getAssetGpsDetails(id)
    if (!assetDetails) {
      console.error('Asset Gps not found assetDetails = ', assetDetails)
      return
    }

    const {
      name,
      vin,
      epoch,
      gps_details: { speed, elevation, heading },
    } = assetDetails

    const elevationValue = elevation < 0 ? 0 : elevation
    const timestamp = convertDataEpochToDate(epoch, null, null, true)
    const formattedSpeed = isNaN(speed)
      ? 'NA'
      : unitsOfMeasurementConfig?.speed === UnitsOfMeasurementConfig.speed.mph
      ? kmhToMph(speed).toFixed(1) + 'mph'
      : speed.toFixed(1) + 'kmh'
    const formattedElevation =
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.miles
        ? metersToFt(elevationValue).toFixed(1) + 'ft'
        : elevationValue + 'm'

    calcData.name = name
    calcData.vin = vin
    calcData.formatted_datetime = timestamp
    const { top, left } = getTooltipCoordinates(map, calcData, e, -25, -173)

    const labelTitle = name ? 'Name:' : 'VIN'
    const label = name ? name : vin
    const headinfDir =
      heading !== undefined ? getGpsDirectinFromDeg(heading) : NoDataLabels.DASH

    bubble.setContent(
      this.getTooltipContent(
        top,
        left,
        labelColor,
        labelTitle,
        label,
        timestamp,
        headinfDir,
        formattedSpeed,
        formattedElevation,
        latitude,
        longitude,
        assetDetails.actual_geofence,
      ),
    )

    const el = document.getElementById('details')
    el.addEventListener('click', () => {
      history.push(`/details/${vin}?reducePoints=true`)
    })
  }

  makeTooltipItemsClickable = () => {
    let items = []
    items = document.getElementsByClassName('column-container')

    for (let i of items) {
      if (i?.dataset?.vin) {
        i.addEventListener('click', () => {
          this.goToAssetDetails(i.dataset.vin)
        })
      }
    }
  }

  addClusteredInfoBubble = async (e, markers) => {
    const { bubble } = this.state

    if (!bubble) return

    const calcData = {
      name: '',
      vin: '',
      formatted_datetime: '',
    }
    const map = document.getElementById('rivata-map-cluster')

    const initialCoords = getTooltipCoordinates(map, calcData, e, -25, -173)

    let normalData = []
    let warningData = []
    let criticalWarningData = []

    if (markers && Array.isArray(markers)) {
      markers.forEach((m) => {
        const data = m.getData()

        if (data) {
          if (data.hasCriticalWarning) {
            criticalWarningData.push({ name: data.name, vin: data.vin })
          } else if (data.hasWarning) {
            warningData.push({ name: data.name, vin: data.vin })
          } else {
            normalData.push({ name: data.name, vin: data.vin })
          }
        }
      })
    }

    bubble.setContent(
      this.getTooltipContentClustered(
        initialCoords.top,
        initialCoords.left,
        normalData,
        warningData,
        criticalWarningData,
      ),
    )

    bubble.setPosition(e.target.getGeometry())
    bubble.open()
    this.makeTooltipItemsClickable()
  }

  removeInfoBubble = () => {
    const { bubble } = this.state
    if (!bubble) return
    bubble.close()
  }

  updateMarkers = async (reset) => {
    const { hMap, markersMap, markersGroup, ui } = this.state
    const { locations, markerType } = this.props

    if (!hMap || !Array.isArray(locations) || !markersGroup) return

    if (reset) {
      markersGroup.removeAll()
    }

    const newMarkersMap = reset ? {} : { ...markersMap }
    const markersToAdd = []
    const markerIconMap = getMarkerPinMap(markerType)

    locations.forEach((data, i) => {
      if (newMarkersMap[data.id]) return
      newMarkersMap[data.id] = data
      let icon

      if ('ok' in markerIconMap) {
        icon = markerIconMap.ok
      }
      // this check removes 0, 0 coords, which are almost ALWAYS bad data
      if (isValidCoord(data.latitude) && isValidCoord(data.longitude)) {
        const marker = new H.map.Marker(
          { lat: data.latitude, lng: data.longitude },
          { icon, data: { ...data } },
        )
        marker.addEventListener('pointerenter', this.addInfoBubble)
        markersToAdd.push(marker)
      }
    })
    // update the data that was not just added above.
    this.updateExistingData()

    if (markersToAdd.length) {
      this.setState({ markersMap: newMarkersMap })
      // add markers to the group

      markersGroup.addObjects(markersToAdd)
      const bbox = bboxAll(markersGroup.getBoundingBox(), 0.2)

      if (bbox && !this.props.geofenceLocationBtn) {
        // get geo bounding box for the group and set it to the map
        addLocationButton(ui, () =>
          this.setZoom(bbox, markersToAdd.length === 1 ? 16 : undefined),
        )
        this.setZoom(bbox, markersToAdd.length === 1 ? 16 : undefined)
      }
    }
  }

  startClustering = (isListNew) => {
    const { hMap, markersGroup, clusteringLayerRef } = this.state
    const { locations } = this.props
    if (!hMap || !Array.isArray(locations) || !isListNew) return

    if (clusteringLayerRef) {
      //remove layer if it already exists
      hMap.removeLayer(clusteringLayerRef)
      this.setState({ clusteringLayerRef: null })
    }

    var dataPoints = new Array(markersGroup.length)
    markersGroup.getObjects().forEach((marker) => {
      let data = marker.getData()
      if (marker && data) {
        dataPoints.push(
          new H.clustering.DataPoint(data.latitude, data.longitude, 1, data),
        )
      }
    })

    dataPoints.forEach((marker) => {
      if (!marker) {
        dataPoints.shift()
      }
    })

    var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 32, // Max radius of cluster
        minWeight: this.props.clusteringDisabled ? Number.MAX_SAFE_INTEGER : 2, // Min markers in a cluster
        strategy: H.clustering.Provider.Strategy.GRID,
      },
    })

    let defaultTheme = clusteredDataProvider.getTheme()
    clusteredDataProvider.setTheme(this.clusterTheme(defaultTheme))

    var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider)
    hMap.getLayers().add(clusteringLayer)
    this.setState({ clusteringLayerRef: clusteringLayer })
  }

  clusterTheme = (defaultTheme) => {
    const { whiteLabelColors } = this.state
    const { markerType } = this.props
    const markerIconMap = getMarkerPinMap(markerType)
    const parent = this
    return {
      getClusterPresentation: function (cluster) {
        let clusterMarker = defaultTheme.getClusterPresentation.call(
          defaultTheme,
          cluster,
        )
        const dataPoints = []
        cluster.forEachDataPoint(dataPoints.push.bind(dataPoints))

        const clusterWarnings = dataPoints.reduce(
          (points, marker) => {
            const { hasCriticalWarning, hasWarning } = marker.getData()
            if (hasCriticalWarning === true) {
              points[0].count++
            } else if (hasWarning === true) {
              points[1].count++
            } else {
              points[2].count++
            }
            return points
          },
          [
            {
              type: 'criticalWarning',
              color: whiteLabelColors.healthCriticalWarning,
              count: 0,
            },
            {
              type: 'warning',
              color: whiteLabelColors.healthWarning,
              count: 0,
            },
            {
              type: 'normal',
              color: whiteLabelColors.healthGood,
              count: 0,
            },
          ],
        )

        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const radius = 38

        canvas.width = radius * 2
        canvas.height = radius * 2

        const cursor = { end: 0, start: 0 }

        clusterWarnings.forEach((item) => {
          const ratio = item.count / dataPoints.length
          cursor.end = 2 * ratio * Math.PI
          context.beginPath()
          context.moveTo(radius, radius)
          context.arc(
            radius,
            radius,
            radius,
            cursor.start,
            cursor.end + cursor.start,
            false,
          )
          context.fillStyle = item.color
          context.globalAlpha = 0.8
          cursor.start += cursor.end
          context.fill()
        })

        context.fillStyle = '#6d6e71'
        context.globalAlpha = 1
        context.beginPath()
        context.arc(radius, radius, 25, 0, 2 * Math.PI)
        context.fill()
        context.fillStyle = 'white'
        context.font = 'bold 22px Arial'
        context.textAlign = 'center'
        context.fillText(dataPoints.length, radius, 45)

        clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: new H.map.Icon(canvas, {
            size: { w: 38, h: 38 },
            anchor: { x: 19, y: 19 },
          }),
          min: cluster.getMinZoom() < 2 ? undefined : cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        })

        clusterMarker.addEventListener('pointerenter', (e) => {
          parent.addClusteredInfoBubble(e, dataPoints)
        })

        return clusterMarker
      },
      getNoisePresentation: function (noisePoint) {
        let data = noisePoint.getData()
        const { pinKey, zIndex } = getPinData(noisePoint)

        var noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
          icon: markerIconMap[pinKey],
          data: { ...data },
          min: noisePoint.getMinZoom(),
        })

        // Yes, zIndex here is bottom to top, so backwards of normal html!
        noiseMarker.setZIndex(zIndex)
        noiseMarker.setData(data)
        noiseMarker.addEventListener('pointerenter', parent.addInfoBubble)

        return noiseMarker
      },
    }
  }

  updateExistingData = () => {
    const { markersGroup, markersMap } = this.state
    if (!markersGroup) return

    markersGroup.getObjects().forEach((marker) => {
      const data = marker.getData()
      const id = data.id
      const location = markersMap[id]
      if (location) {
        marker.setData(location)
      }
    })
  }

  updateBaseLayer = () => {
    // layerType, hMap
    const { hMap, layerChoices } = this.state
    const { layerType } = this.props
    if (!layerType || !hMap) return

    if (layerType in layerChoices) {
      hMap.setBaseLayer(layerChoices[layerType])
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldBaseLayerUpdate(prevProps, prevState)) {
      this.updateBaseLayer()
    }
    if (
      this.shouldMarkersUpdate(prevProps, prevState) ||
      prevProps.clusteringDisabled !== this.props.clusteringDisabled
    ) {
      this.updateMarkers(
        this.props.locations.length !== prevProps.locations.length,
      )
      this.startClustering(
        this.props.locations.length !== prevProps.locations.length ||
          prevProps.clusteringDisabled !== this.props.clusteringDisabled,
      )
    }
    if (this.shouldSelectMarkersUpdate(prevProps, prevState)) {
      this.selectMarkers()
    }

    if (
      this.props.preferences &&
      this.props.preferences.unitsOfMeasurementConfig !==
        this.state.unitsOfMeasurementConfig
    ) {
      if (this.state.ui) {
        this.state.ui.setUnitSystem(
          this.props.preferences.unitsOfMeasurementConfig?.distance ===
            UnitsOfMeasurementConfig.distance.km
            ? H.ui.UnitSystem.METRIC
            : H.ui.UnitSystem.IMPERIAL,
        )
      }
    }

    if (this.props.locations.length !== prevProps.locations.length) {
      this.setState({ initialBoundsZoom: { zoom: null, bounds: null } })
    }

    if (this.props.size !== prevProps.size) {
      this.state.hMap.getViewPort().resize()
    }
  }

  shouldMarkersReset = (pp) => {
    const { locations } = this.props

    return locations.length !== pp.locations.length
  }

  shouldBaseLayerUpdate = (pp, ps) => {
    const { hMap } = this.state
    const { layerType } = this.props

    return hMap !== ps.hMap || layerType !== pp.layerType
  }

  shouldMarkersUpdate = (pp, ps) => {
    const { markersGroup, markersMap } = this.state
    const { locations, selectable } = this.props

    const shouldUpdate =
      selectable !== false ||
      locations !== pp.locations ||
      markersGroup !== ps.markersGroup ||
      markersMap !== ps.markersMap

    return shouldUpdate
  }

  shouldSelectMarkersUpdate = (pp, ps) => {
    const { hMap, markersGroup, markersMap } = this.state
    const { locations, selected } = this.props

    return (
      hMap !== ps.hMap ||
      locations !== pp.locations ||
      markersGroup !== ps.markersGroup ||
      markersMap !== ps.markersMap ||
      selected !== pp.selected
    )
  }

  render() {
    return (
      <div
        className='map'
        id='rivata-map-cluster'
        ref={this.mapRef}
        style={{ height: '400px', width: '100%' }}
      >
        <GeofenceRenderer
          map={this.state.hMap}
          ui={this.state.ui}
          shouldAddCenterMarkers={true}
          geofences={this.props.geofences || []}
          geofencesVisible={this.props.geofencesVisible}
          setBounds={this.props.setBounds}
          renderLocationBtn={this.props.geofenceLocationBtn}
          displayBubble={this.props.displayGeofenceBubble}
          mapZoomBounds={this.props.mapZoomBounds}
          onMapViewChange={this.props.onMapViewChange}
          customTooltipContent={this.props.customGeofenceTooltipContent}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  healthColors: state.whitelabel.healthColors,
})

export default connect(mapStateToProps)(HereMap)
