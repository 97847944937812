import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import moment from 'moment'
import { CustomInput, Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import HEREMap from '../../components/RivataMap'
import { LayerTypes } from '../../components/RivataMap/utils'
import RivataLoader from '../../components/RivataLoader'
import LayersDropdown from '../../components/LayersDropdown'
import Legend from './Legend'
import { dateToEpoch, convertDataEpochToDate } from '../../utils'
import { useParsedData } from './hooks'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { AssetDetailsContext } from '../../pages/AssetDetails'
import ScrollBlockWrapper from '../../components/ScrollBlockWrapper'
import DateTimePicker from '../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../enums'
import { debounce } from 'lodash'
import { useInterval } from '../../utils/utils'
import RivataDropdown from '../../components/RivataDropdown'
import { Range, getTrackBackground } from 'react-range'

import './styles.scss'

const maxAdditionalHours = 144

const DetailsMap = ({ width }) => {
  const {
    gps: { isLoading, data, status },
    geofences,
    preferences,
    warnings,
  } = useTypedSelector((state) => ({
    gps: state.assetDetails.gps,
    geofences: state.geofences.geofences.data,
    preferences: state.auth.preferences,
    warnings: state.assetDetails.recentWarnings.data,
  }))
  const { fetchAssetDetailsGps } = useActions()

  const { locale, assetInfoData, healthColors, warningKey } =
    useContext(AssetDetailsContext)

  const speedDropdownItems = [
    { id: 1, label: '1x speed' },
    { id: 2, label: '2x speed' },
    { id: 4, label: '4x speed' },
    { id: 8, label: '8x speed' },
  ]

  const [formValues, setFormValues] = useState({
    inputValue:
      moment(new Date()).format('MM/DD/YYYY') +
      ' - ' +
      moment(new Date()).format('MM/DD/YYYY'),
    startTime: '00:00',
    endTime: '23:59',
    dayStart: new Date(),
    dayEnd: new Date(),
  })
  const [defaultDate, setDefaultDate] = useState({
    startTime: '00:00',
    endTime: '23:59',
    dayStart: new Date(),
    dayEnd: new Date(),
  })
  const [dateRangeError, setDateRangeError] = useState(null)
  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL,
  )
  const [forceMapRerender, setForceMapRerender] = useState(false)
  const [geofencesVisible, setGeofencesVisible] = useState(false)
  const [mapZoomBounds, setMapZoomBounds] = useState({
    zoom: null,
    bounds: null,
  })

  const selectedWarning = useMemo(() => {
    return warnings.find((el) => el.key === warningKey)
  }, [warningKey, warnings])

  // custom hook
  const nonAcknowledgedCriticalWarningsOnly = true

  const parsedLocationsData = useParsedData(
    data,
    assetInfoData,
    selectedWarning,
    setForceMapRerender,
    nonAcknowledgedCriticalWarningsOnly,
  )

  const [parsedLocations, setParsedLocations] = useState(parsedLocationsData)
  const [playbackMode, setPlaybackMode] = useState(false)
  const [playbackMarkerIdx, setPlaybackMarkerIdx] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)

  const [latestPointHasWarning, setLatestPointHasWarning] = useState(false)
  const [timelineTooltipText, setTimelineTooltipText] = useState('')
  const [selectedSpeed, setSelectedSpeed] = useState(speedDropdownItems[0].id)
  const [values, setValues] = useState([0])

  const initPlaybackMode = () => {
    setIsPlaying(false)
    setPlaybackMode(true)
    setPlaybackMarkerIdx(0)
    setParsedLocations({
      ...parsedLocationsData,
      data: parsedLocationsData.data.slice(0, 1),
    })
  }

  const exitPlaybackMode = useCallback(() => {
    setPlaybackMode(false)
    setParsedLocations({
      ...parsedLocationsData,
    })
  }, [parsedLocationsData])

  const playNextStep = () => {
    let idx = playbackMarkerIdx
    const dataLength = parsedLocationsData.data.length - 1

    if (idx < dataLength) {
      idx += selectedSpeed
      if (idx > dataLength) {
        idx = dataLength
      }
      setPlaybackMarkerIdx(idx)
    } else {
      setIsPlaying(false)
    }
  }

  useInterval(
    () => {
      playNextStep()
    },
    isPlaying ? (latestPointHasWarning ? 3000 : 1000) : null,
  )

  const updateData = useCallback(() => {
    if (playbackMode) {
      setParsedLocations({
        ...parsedLocationsData,
        data: parsedLocationsData.data.slice(0, playbackMarkerIdx + 1),
      })
      setValues([playbackMarkerIdx])
      if (parsedLocationsData?.data[playbackMarkerIdx]) {
        setTimelineTooltipText(
          parsedLocationsData.data[playbackMarkerIdx].formatted_datetime,
        )
      }

      const pLocations = parsedLocationsData.data.slice(
        0,
        playbackMarkerIdx - 1,
      )

      const lastPoint = pLocations[pLocations.length + 1]

      if (lastPoint) {
        setLatestPointHasWarning(lastPoint.warning_info.has_warning)
      }
    }
  }, [parsedLocationsData, playbackMarkerIdx, playbackMode])

  useEffect(() => {
    const debouncedUpdateData = debounce(updateData, 500)
    debouncedUpdateData()

    return () => {
      debouncedUpdateData.cancel()
    }
  }, [playbackMarkerIdx, updateData])

  useEffect(() => {
    exitPlaybackMode()
    const lastPoint =
      parsedLocationsData?.data[parsedLocationsData?.data.length - 1]

    if (lastPoint) {
      setLatestPointHasWarning(lastPoint.warning_info.has_warning)
    }
    setParsedLocations(parsedLocationsData)
    setTimelineTooltipText(parsedLocationsData?.data[0]?.formatted_datetime)
  }, [parsedLocationsData, exitPlaybackMode])

  useEffect(() => {
    if (selectedWarning?.epoch) {
      const composedFrom = moment
        .unix(selectedWarning.epoch)
        .subtract(4, 'hours')
        .unix()

      const composedTo = moment
        .unix(selectedWarning.epoch)
        .add(4, 'hours')
        .unix()

      const filterDateStart = convertDataEpochToDate(
        composedFrom,
        null,
        null,
        true,
      ).split(' ')
      const filterDateEnd = convertDataEpochToDate(
        composedTo,
        null,
        null,
        true,
      ).split(' ')

      const beforeWarningtime = moment(
        [filterDateStart[1], filterDateStart[2]].join(' '),
        ['HH:mm A'],
      ).format('HH:mm')
      const afterWarningtime = moment(
        [filterDateEnd[1], filterDateEnd[2]].join(' '),
        ['HH:mm A'],
      ).format('HH:mm')

      const beforeWarningDate = filterDateStart[0]
      const afterWarningDate = filterDateEnd[0]

      setFormValues({
        inputValue: `${beforeWarningDate} ${
          afterWarningDate ? '- ' + afterWarningDate : ''
        }`,
        startTime: beforeWarningtime,
        endTime: afterWarningtime,
        dayStart: new Date(beforeWarningDate),
        dayEnd: new Date(afterWarningDate),
      })
      setDefaultDate({
        startTime: beforeWarningtime,
        endTime: afterWarningtime,
        dayStart: new Date(beforeWarningDate),
        dayEnd: new Date(afterWarningDate),
      })
    }
  }, [selectedWarning])

  useEffect(() => {
    if (assetInfoData?.lastLocationEpoch && !selectedWarning) {
      const start = convertDataEpochToDate(
        assetInfoData.lastLocationEpoch - 24 * 3600,
        null,
        null,
        false,
      )
      const end = convertDataEpochToDate(
        assetInfoData.lastLocationEpoch,
        null,
        null,
        false,
      )

      setFormValues({
        inputValue:
          start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'),
        startTime: start.format('HH:mm'),
        endTime: end.format('HH:mm'),
        dayStart: new Date(start),
        dayEnd: new Date(end),
      })

      setDefaultDate({
        startTime: start.format('HH:mm'),
        endTime: end.format('HH:mm'),
        dayStart: new Date(start),
        dayEnd: new Date(end),
      })

      setForceMapRerender(true)
    }
  }, [assetInfoData, selectedWarning])

  const dateRangeHandler = useCallback((startDate, endDate) => {
    setFormValues((prev) => ({
      ...prev,
      inputValue: `${moment(startDate).format('MM/DD/YYYY')} ${
        endDate ? '- ' + moment(endDate).format('MM/DD/YYYY') : ''
      }`,
      dayStart: startDate,
      dayEnd: endDate,
      startTime: moment(startDate).format('HH:mm'),
      endTime: moment(endDate).format('HH:mm'),
    }))

    const selectedDays = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .as('days')
    if (selectedDays > (maxAdditionalHours + 24) / 24) {
      return setDateRangeError("You can't select more than 7 days")
    }
    setDateRangeError(null)
  }, [])

  const handleSubmit = useCallback(() => {
    const { startDate, endDate } = dateToEpoch(
      formValues.dayStart,
      formValues.dayEnd,
      formValues.startTime,
      formValues.endTime,
    )
    fetchAssetDetailsGps(startDate, endDate)
  }, [
    formValues.dayStart,
    formValues.dayEnd,
    formValues.startTime,
    formValues.endTime,
    fetchAssetDetailsGps,
  ])

  const onMapViewChange = useCallback((bounds, zoom) => {
    setMapZoomBounds({ bounds, zoom })
  }, [])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title='Vehicle Location'
      width={width}
      locale={locale}
      paddingMobile={false}
      error={status}
      filters={
        <>
          {geofences?.length ? (
            <div className='d-flex align-items-center mr-3'>
              <CustomInput
                type='switch'
                id='geofencesSwitch'
                label={'Show Geofences'}
                checked={geofencesVisible}
                onChange={(e) => setGeofencesVisible(e.target.checked)}
              />
            </div>
          ) : null}

          <LayersDropdown
            left={0}
            top={0}
            zIndex={1}
            selected={selectedLayerOption}
            onSelect={(type) => setSelectedLayerOption(type)}
            locale={locale}
          />
        </>
      }
      collapsible
    >
      <section>
        <Row className='mb-2'>
          <Col className='col-8 d-flex ml-0 align-items-center'>
            <DateTimePicker
              defaultMode={CalendarTypes.DateRange}
              hourLimit={maxAdditionalHours}
              dateFrom={moment(formValues.dayStart)
                .hours(moment(formValues.startTime, 'HH:mm').hours())
                .minutes(moment(formValues.startTime, 'HH:mm').minutes())}
              dateTo={moment(formValues.dayEnd)
                .hours(moment(formValues.endTime, 'HH:mm').hours())
                .minutes(moment(formValues.endTime, 'HH:mm').minutes())}
              onDateRangeSelect={dateRangeHandler}
              timeEnabled={true}
              maxDate={moment().toDate()}
              defaultDateFrom={moment(defaultDate.dayStart)
                .hours(moment(defaultDate.startTime, 'HH:mm').hours())
                .minutes(moment(defaultDate.startTime, 'HH:mm').minutes())}
              defaultDateTo={moment(defaultDate.dayEnd)
                .hours(moment(defaultDate.endTime, 'HH:mm').hours())
                .minutes(moment(defaultDate.endTime, 'HH:mm').minutes())}
            />
            <button
              className='btn btn-primary ml-3'
              type='submit'
              disabled={!!dateRangeError}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Col>
          <Col className='col-4 mr-0 d-flex align-items-center justify-content-end'>
            <Button
              id='playback-button'
              onClick={playbackMode ? exitPlaybackMode : initPlaybackMode}
              disabled={!(parsedLocationsData?.data?.length > 1)}
            >
              {playbackMode ? 'Exit' : 'Enter'} playback mode
            </Button>
            {!(parsedLocationsData?.data?.length > 1) && (
              <UncontrolledTooltip
                hideArrow={true}
                placement='auto'
                target='playback-button'
                trigger='hover'
              >
                Not enough data for playback
              </UncontrolledTooltip>
            )}
          </Col>
        </Row>
        {playbackMode && (
          <Row className='playback-controls'>
            <Col className='d-flex col-12 mt-2 justify-content-center'>
              <Range
                values={values}
                min={0}
                max={
                  parsedLocationsData?.data?.length - 1 > 0
                    ? parsedLocationsData?.data?.length - 1
                    : 1
                }
                onChange={(values) => {
                  setValues(values)
                  setPlaybackMarkerIdx(Number(values[0]))
                  const selectedDatetime = parsedLocationsData.data[values[0]]
                    .formatted_datetime
                    ? parsedLocationsData.data[values[0]].formatted_datetime
                    : ''
                  setTimelineTooltipText(selectedDatetime)
                  setIsPlaying(false)
                }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: '36px',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: '8px',
                        width: '100%',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values,
                          colors: ['var(--primary)', '#ccc'],
                          min: 0,
                          max:
                            parsedLocationsData?.data?.length - 1 > 0
                              ? parsedLocationsData?.data?.length - 1
                              : 1,
                        }),
                        alignSelf: 'center',
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '42px',
                      width: '42px',
                      borderRadius: '4px',
                      backgroundColor: '#FFF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '0px 2px 6px #AAA',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '-32px',
                        width: '170px',
                        display: 'flex',
                        height: '28px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        padding: '4px',
                        borderRadius: '4px',
                        backgroundColor: 'var(--primary)',
                      }}
                    >
                      {timelineTooltipText}
                    </div>
                    <div
                      style={{
                        height: '16px',
                        width: '5px',
                        backgroundColor: isDragged ? 'var(--primary)' : '#CCC',
                      }}
                    />
                  </div>
                )}
              />
            </Col>
            <Col className='d-flex col-12 mt-4 justify-content-center container'>
              <Button
                className='control'
                onClick={() => {
                  initPlaybackMode()
                }}
              >
                <i className='fa-solid fa-backward-fast'></i>
              </Button>
              <Button
                className='control'
                onClick={() => {
                  if (playbackMarkerIdx > 0) {
                    setPlaybackMarkerIdx((prev) => --prev)
                    setIsPlaying(false)
                  }
                }}
              >
                <i className='fa-solid fa-backward'></i>
              </Button>
              <Button
                className='control'
                onClick={() => {
                  setIsPlaying((prev) => !prev)
                }}
              >
                {isPlaying ? (
                  <i className='fa-solid fa-pause'></i>
                ) : (
                  <i className='fa-solid fa-play'></i>
                )}
              </Button>
              <Button
                className='control'
                onClick={() => {
                  if (
                    playbackMarkerIdx <
                    parsedLocationsData?.data?.length - 1
                  ) {
                    setPlaybackMarkerIdx((prev) => ++prev)
                    setIsPlaying(false)
                  }
                }}
              >
                <i class='fa-solid fa-forward'></i>
              </Button>
              <Button
                className='control'
                onClick={() => {
                  setPlaybackMarkerIdx(parsedLocationsData?.data?.length - 1)
                }}
              >
                <i className='fa-solid fa-forward-fast'></i>
              </Button>
              <RivataDropdown
                items={speedDropdownItems}
                caret={true}
                onSelect={(option) => {
                  setSelectedSpeed(option)
                }}
                selected={selectedSpeed}
                locale={locale}
                size={'md'}
              />
            </Col>
          </Row>
        )}
      </section>
      <ScrollBlockWrapper>
        {window.H && (
          <Legend
            locale={locale}
            isWarningDetailsPage={true}
            isAssetDetailsPage={!selectedWarning}
          />
        )}
        {window.H && (
          <HEREMap
            locations={parsedLocations.data}
            layerType={selectedLayerOption}
            closestTimestamp={parsedLocations.warningTimestamp}
            selectable={false}
            bboxLast24hrs={false}
            locale={locale}
            preferences={preferences}
            unitsOfMeasurementConfig={preferences.unitsOfMeasurementConfig}
            healthColors={healthColors}
            showLastLocationAsPin={true}
            forceMapRerender={forceMapRerender}
            setForceMapRerender={setForceMapRerender}
            geofences={geofences}
            vin={assetInfoData?.vin}
            geofencesVisible={geofencesVisible}
            isWarningDetailsPage={true}
            onMapViewChange={onMapViewChange}
            mapZoomBounds={mapZoomBounds}
            size={width}
            playbackMode={playbackMode}
            latestPointHasWarning={latestPointHasWarning}
          />
        )}
      </ScrollBlockWrapper>
      {isLoading && (
        <div
          className='position-absolute w-100 h-100'
          style={{
            backgroundColor: 'rgba(0,0,0,.1)',
            left: 0,
            top: 0,
            display: 'flex',
            zIndex: 1,
            justifyContent: 'center',
          }}
        >
          <RivataLoader />
        </div>
      )}
    </RivataModule>
  )
}

export default DetailsMap
