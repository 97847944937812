import React, { useMemo, useContext, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'
import AssetRow from './AssetRow'
import ModalLogInfo from '../../components/LogsModal'

import { useTypedSelector } from '../../hooks/useTypedSelector'

import { AssetDetailsContext } from '../../pages/AssetDetails'
import { dateToEpoch, getClearLabel, getMileage } from '../../utils'
import { useActions } from '../../hooks/useActions'
import { NoDataLabels } from '../../enums'
import { UnitsOfMeasurementConfig } from '../../constants/constants'
import FavoriteButton from './FavoriteButton'

const VehicleInfo = ({
  width,
  // onGatewayLogsGet,
}) => {
  const {
    isLoading,
    data,
    status,
    tpmsProfile,
    adminInfo,
    auth: {
      preferences: { unitsOfMeasurementConfig },
    },
    isFavoriteLoading,
  } = useTypedSelector((state) => ({
    ...state.assetDetails.assetInfo,
    tpmsProfile: state.assetDetails.assignedTpmsProfile,
    sensorInfo: state.assetDetails.sensorInfo.data,
    adminInfo: state.getAdminLogs,
    whitelabel: state.whitelabel,
    auth: state.auth,
  }))
  const { fetchAdminLogs, resetTable, toggleDetailsAssetFavorite } =
    useActions()

  const { locale, isSuperAdmin } = useContext(AssetDetailsContext)

  const defaultData = {
    hardware_id: '',
    mac: '',
    date: {
      fromDate: moment().format(),
      toDate: moment().format(),
    },
    logsType: '',
    time: { from: '00:00', to: '23:59' },
    offset: 0,
    logsCount: 10,
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fetchData, setFetchData] = useState(defaultData)

  const { hardware_id, mac, date, logsType, time, offset, logsCount } =
    fetchData

  const setUserEpoch = useCallback(
    (dates) => {
      const { from, to } = time
      const { fromDate, toDate } = dates

      return dateToEpoch(fromDate, toDate, from, to)
    },
    [time],
  )

  const onSelectBase = useCallback(
    (baseType) => {
      const type = baseType.toLowerCase().replace(' ', '_')

      setFetchData((prev) => ({
        ...prev,
        logsType: type,
        time: { from: '00:00', to: '23:59' },
        offset: 0,
        logsCount: 20,
      }))

      const { startDate, endDate } = setUserEpoch(date)
      const isMac = type === 'gateway_rivata' ? hardware_id : mac

      fetchAdminLogs(isMac, type, startDate, 0, 20, endDate)
    },
    [date, fetchAdminLogs, hardware_id, mac, setUserEpoch],
  )

  const fetchLogs = useCallback(
    (epoch, fromPage, toPage) => {
      const { startDate, endDate } = setUserEpoch(epoch)
      const gatewaysType =
        logsType === 'gateway_translator' || logsType === 'gateway_rivata'
      logsType === 'sensors' &&
        fetchAdminLogs(hardware_id, logsType, startDate, fromPage, toPage)
      const isMac = logsType === 'gateway_rivata' ? hardware_id : mac
      gatewaysType &&
        fetchAdminLogs(isMac, logsType, startDate, fromPage, 20, endDate)
    },
    [fetchAdminLogs, hardware_id, logsType, mac, setUserEpoch],
  )

  const onSelectLimit = useCallback(
    (num) => {
      setFetchData((prev) => ({ ...prev, offset: 0, logsCount: num }))
      fetchLogs(date, 0, num)
    },
    [date, fetchLogs],
  )

  const onSubmit = useCallback(
    (data) => {
      setFetchData((prev) => ({ ...prev, offset: 0, logsCount: 10 }))
      fetchLogs(data, offset, logsCount)
    },
    [fetchLogs, logsCount, offset],
  )

  const formReset = useCallback((formData) => {
    const { fromDate, toDate, from, to } = formData
    setFetchData((prev) => ({
      ...prev,
      date: {
        fromDate,
        toDate,
      },
      time: { from, to },
    }))
  }, [])

  const onLogsPageChange = useCallback(
    (step) => {
      setFetchData((prev) => ({ ...prev, offset: step }))
      let start = step - logsCount
      let end = step <= 0 ? logsCount : step
      if (step <= 0 && logsType === 'sensors') {
        start = 0
      }
      if (step !== 0 && logsType === 'sensors') {
        start = step
        end = step + logsCount
      }
      if (logsType !== 'sensors') {
        start = step
      }
      fetchLogs(date, start, end)
    },
    [date, fetchLogs, logsCount, logsType],
  )

  const setTime = useCallback((time, fromTo) => {
    setFetchData((prev) => {
      const newTime = { ...prev.time }

      if (fromTo === 'from') {
        newTime.from = time
      } else {
        newTime.to = time
      }

      return {
        ...prev,
        offset: 0,
        time: newTime,
      }
    })
  }, [])

  const odometerValue = useMemo(() => {
    if (data && unitsOfMeasurementConfig) {
      if (data.asset_type === 'trailer' && data.odometer > 0) {
        return `${getMileage(data.odometer, unitsOfMeasurementConfig).toFixed(
          0,
        )} ${
          unitsOfMeasurementConfig?.distance ===
          UnitsOfMeasurementConfig.distance.miles
            ? 'mi'
            : 'km'
        }`
      }
    }
    return NoDataLabels.DASH
  }, [data, unitsOfMeasurementConfig])

  const assetType = useMemo(() => {
    if (data) {
      let label = `${getClearLabel(data.asset_type)}`
      if (data.asset_subtype) {
        label += `, ${getClearLabel(data.asset_subtype)}`
      }
      return label
    }
    return NoDataLabels.DASH
  }, [data])
  return (
    <RivataModule
      title='Vehicle Info'
      width={width}
      locale={locale}
      collapsible
      defaultCollapsed
    >
      {isLoading ? (
        <RivataLoader />
      ) : !data || !data.id ? (
        <StatusAlert statusText={status.message} />
      ) : (
        <div>
          <div>
            <FavoriteButton
              isFavorite={data.favorite}
              isLoading={isFavoriteLoading}
              favoriteToggle={toggleDetailsAssetFavorite}
              assetId={data.id}
            />

            <AssetRow label={locale['Name']} value={data.name} />
            <AssetRow label={locale['VIN']} value={data.vin} />
            <AssetRow label={locale['Asset Type']} value={assetType} />
            {data.asset_type === 'trailer' && (
              <AssetRow
                label={locale['NumberOfAxles']}
                value={data.number_of_axles || NoDataLabels.DASH}
              />
            )}
            {data.asset_type === 'trailer' && data.odometer && (
              <>
                <AssetRow
                  id='vehicle_info_odometer_value'
                  label={locale['Odometer']}
                  value={odometerValue}
                />
              </>
            )}
            <AssetRow
              label={'Asset Groups'}
              value={data.asset_groups?.map((c) => c.name).join(', ') ?? '-'}
            />
            {data.sensor_info?.tpms ? (
              <AssetRow
                label={'TPMS Profile'}
                value={tpmsProfile?.name || 'Default'}
              />
            ) : null}
            {isSuperAdmin && (
              <Link
                className='btn btn-primary mt-2'
                to={`/asset-admin-info/${data.vin}`}
              >
                Asset Admin Info
              </Link>
            )}
          </div>

          {isModalOpen && (
            <ModalLogInfo
              onClose={() => setIsModalOpen(false)}
              logsData={adminInfo}
              isOpen={isModalOpen}
              type={fetchData.logsType}
              setType={onSelectBase}
              onSelectLimit={onSelectLimit}
              date={date}
              offset={offset}
              onSubmit={onSubmit}
              logsCount={logsCount}
              resetTable={resetTable}
              formReset={formReset}
              onLogsPageChange={onLogsPageChange}
              setDate={(dateFromCalendar) =>
                setFetchData({
                  ...fetchData,
                  date: dateFromCalendar,
                  offset: 0,
                  time: { from: '00:00', to: '23:59' },
                })
              }
              time={time}
              setTime={setTime}
              onSetDefaultData={() => setFetchData(defaultData)}
            />
          )}
        </div>
      )}
    </RivataModule>
  )
}

export default VehicleInfo
