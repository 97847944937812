import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { getClearLabel } from '../../../utils'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
const AssetHealthStateRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    if (!value || (value && value.toLowerCase() === 'all')) {
      setDisplayValue('All')
    } else {
      setDisplayValue(getClearLabel(value))
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Asset Health State:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default AssetHealthStateRow
