import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'
import CustomerRow from '../ReportParametersRows/CustomerRow'

const DeviceHealthReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      {props.isSchedule && (
        <CustomerRow value={props.parameters.customer_keys} />
      )}
      {props.isSchedule && <TimeZoneRow value={props.parameters.timezone} />}
      <AssetTypeRow value={props.parameters.asset_type} />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
    </>
  )
}
export default DeviceHealthReportParameters
