import React, { useRef, useEffect, useState } from 'react'
import moment from 'moment'
import { Col, Input } from 'reactstrap'
import { DateRangePicker, Calendar, DateRange } from 'react-date-range'
import './style.scss'

export const DateInput = (props) => {
  const {
    title = 'Select Date Range',
    singleDateTitle = 'Select Date',
    isSingleDate = false,
    onSelect,
    from,
    to,
    customClassNames = 'report-date_input',
    children = null,
    disabled,
    md = null,
    singleDateMd = null,
    minDate = null,
  } = props

  const [dateFrom, setDateFrom] = useState(from || new Date())
  const [dateTo, setDateTo] = useState(to || new Date())
  const [showCalendar, setShowCalendar] = useState(false)
  const [inputValue, setnputValue] = useState('')
  const toggleCalendar = (value) => setShowCalendar(value)

  const handleSelect = (values) => {
    const { startDate, endDate } = values.selection
    setDateFrom(startDate)
    setDateTo(endDate)
    onSelect(startDate, endDate)
    setnputValue(
      `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format(
        'MM/DD/YYYY',
      )}`,
    )
  }

  useEffect(() => {
    if (!isSingleDate) {
      return setnputValue(
        `${
          dateFrom ? moment(dateFrom).format('MM/DD/YYYY') + ' - ' : ''
        }${moment(dateTo).format('MM/DD/YYYY')}`,
      )
    }

    setnputValue(moment(dateTo).format('MM/DD/YYYY'))
  }, [isSingleDate, dateFrom, dateTo])

  useEffect(() => {
    if (isSingleDate) {
      setnputValue(moment(to).format('MM/DD/YYYY'))
    }
  }, [to, isSingleDate])

  let selectionRange = {
    startDate: dateFrom,
    endDate: dateTo,
    key: 'selection',
  }

  useEffect(() => {
    if (from) {
      setDateFrom(from)
    }
    if (to) {
      setDateTo(to)
    }
  }, [from, to])

  if (isSingleDate) {
    return (
      <Col md={md ? md : '6'} className='p-0 custom__width'>
        <div
          className={`col-${singleDateMd ? singleDateMd : 3} position-relative`}
        >
          <label>{singleDateTitle}</label>
          <Input
            onClick={() => toggleCalendar(true)}
            placeholder={'Select Date'}
            value={inputValue}
            disabled={disabled}
          />
          <DatePicker
            visible={showCalendar}
            date={dateTo}
            handleSelect={(value) => {
              onSelect(value)
              setDateTo(value)
            }}
            toggleCalendar={toggleCalendar}
            customClassNames={customClassNames}
            minDate={minDate}
          />
        </div>
      </Col>
    )
  }

  return (
    <Col md={md ? md : '4'} className='p-0 d-flex'>
      <div className='position-relative pl-0' style={{ width: '190px' }}>
        <label>{title}</label>
        <Input
          onClick={toggleCalendar}
          placeholder={'Select Date Range'}
          value={inputValue}
          readOnly={disabled ? false : true}
          disabled={disabled}
        />
        <DateRangeCalendar
          visible={showCalendar}
          selectionRange={selectionRange}
          handleSelect={handleSelect}
          toggleCalendar={toggleCalendar}
          customClassNames={customClassNames}
          minDate={minDate}
        />
      </div>
      {children}
    </Col>
  )
}

export const DatePicker = ({
  visible,
  toggleCalendar,
  handleSelect,
  date,
  customClassNames = 'date-picker',
  minDate,
}) => {
  const modal = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (modal.current && !modal.current.contains(event.target)) {
        toggleCalendar(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modal, toggleCalendar])

  return (
    <div
      ref={modal}
      className={`position-absolute ${customClassNames}`}
      style={{
        border: visible ? '1px solid silver' : 'none',
        background: '#fff',
      }}
    >
      {visible && (
        <Calendar
          onChange={handleSelect}
          maxDate={new Date()}
          minDate={minDate ? minDate : new Date(2000, 0, 1)} // calendar crash if use arrows to change month - do not pass null or something like that to else statement
          date={date}
        />
      )}
    </div>
  )
}

export const DateRangeSelector = ({
  selectionRange,
  handleSelect,
  toggleCalendar,
  visible,
  customClassNames = '',
}) => {
  // without selection labels (left panel of DateRangePicker)
  const modal = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (modal.current && !modal.current.contains(event.target)) {
        toggleCalendar(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modal, toggleCalendar])

  return (
    <div
      ref={modal}
      className={customClassNames}
      style={{
        top: '40px',
        border: visible ? '1px solid silver' : 'none',
        position: 'absolute',
        left: 0,
        zIndex: 201,
      }}
    >
      {visible && (
        <DateRange
          ranges={[selectionRange]}
          onChange={handleSelect}
          maxDate={new Date()}
        />
      )}
    </div>
  )
}

const DateRangeCalendar = ({
  selectionRange,
  handleSelect,
  toggleCalendar,
  visible,
  customClassNames = 'modal_calendar',
  top = '40px',
  minDate,
}) => {
  const modal = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (modal.current && !modal.current.contains(event.target)) {
        toggleCalendar(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modal, toggleCalendar])
  return (
    <div
      ref={modal}
      className={customClassNames}
      style={{ top: top, border: visible ? '1px solid silver' : 'none' }}
    >
      {visible && (
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          maxDate={new Date()}
          minDate={minDate ? minDate : new Date(2000, 0, 1)}
        />
      )}
    </div>
  )
}

export default DateRangeCalendar
