import React, { useCallback } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import classes from './styles.module.scss'

type Props = {
  isFavorite?: boolean
  isLoading?: boolean
  favoriteToggle: (assetId: number) => void
  assetId: number
}

const FavoriteButton: React.FC<Props> = ({
  isFavorite,
  isLoading,
  favoriteToggle,
  assetId,
}) => {
  const onClick = useCallback(() => {
    if (isLoading) return
    favoriteToggle(assetId)
  }, [isLoading, favoriteToggle, assetId])

  return (
    <div className='d-flex justify-content-end pb-2 pr-1'>
      <i
        id={`favorite_button`}
        className={`${classes.favoriteButton} fa fa-star ${
          isFavorite ? classes.starOn : classes.starOff
        }`}
        onClick={onClick}
        key={`favorite_button`}
      />
      <UncontrolledTooltip
        hideArrow={true}
        placement='auto'
        target={`favorite_button`}
        trigger='hover'
      >
        Favorite
      </UncontrolledTooltip>
    </div>
  )
}

export default FavoriteButton
