import api from "../../services/api";
import {
  SET_TRESHOLDS_LOADING,
} from "../actionTypes";
import { fetchDefaults } from '../common/action'

export const setIsLoading = (payload) => {
  return {
    type: SET_TRESHOLDS_LOADING,
    payload
  }
}

export function postTresholds(tresholds) {
  return function (dispatch, getState) {
    dispatch(setIsLoading(true));
    const selectedCustomersList = getState().common.customers.selectedCustomersList
    if (selectedCustomersList.length !== 1) return

    return api.putWarningSettings(tresholds)
      .then(async () => {
        dispatch(fetchDefaults(selectedCustomersList[0].id))

        return { statusCode: 200, message: `Thresholds for customer "${selectedCustomersList[0].name}" successfully updated` }
      }).catch(err => {
        return { statusCode: 400, message: err.message ? err.message : "Something went wrong, please try again later" }
      }).finally(() => {
        dispatch(setIsLoading(false));
      })
  }
}