import React, { useEffect } from 'react'
import { match, Route, Switch, withRouter } from 'react-router-dom'
import { History, Location } from 'history'

import { standardRoutes, allAdminRoutes, publicRoutes } from './routes'

import PrivateRoute from './routes/PrivateRoutes'
import AdminRoute from './routes/AdminRoute'
import Callback from './routes/authCallback'

import Blanklayout from './layouts/blanklayout'
import RivataPage from './components/RivataPage/index'
import withInitialLoadingHandler from './hoc/withInitialLoadingHandler'
import ScrollToTopWrapper from './components/ScrollToTopWrapper'
import CssRgbThemeColorsWrapper from './components/CssRgbThemeColorsWrapper'

import { useActions } from './hooks/useActions'

import { useTypedSelector } from './hooks/useTypedSelector'

// white label overrides
import './app.scss'
import './theme.css'
import Logout from './pages/Logout'

interface IAppProps {
  location: Location
  history: History
  match: match
}

const App: React.FC<IAppProps> = ({ ...props }) => {
  const { location } = props
  const { handleAppLocationChange } = useActions()
  const { isDriver } = useTypedSelector((state) => ({
    isDriver: state.auth.isDriver,
  }))

  useEffect(() => {
    // add or remove "All" option from customer dropdown based on location
    handleAppLocationChange(location.pathname)
  }, [location.pathname, handleAppLocationChange])

  return (
    <CssRgbThemeColorsWrapper>
      <RivataPage {...props}>
        <ScrollToTopWrapper>
          <Switch>
            {/** main routes for user who is not authorized */}
            <Route
              path='/auth/:customer'
              component={(props: any) => <Blanklayout {...props} />}
            />
            <Route
              path='/auth'
              component={(props: any) => <Blanklayout {...props} />}
            />
            <Route
              path='/authentication'
              component={(props: any) => <Blanklayout {...props} />}
            />
            {/** logout route */}
            <Route path='/logout' component={(props: any) => <Logout />} />
            {/** callback handles the response from the aws login flow */}
            <Route
              path='/callback'
              component={(props: any) => <Callback {...props} />}
            />
            {publicRoutes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                component={route.component}
              />
            ))}
            {!isDriver &&
              allAdminRoutes.map((route) => (
                <AdminRoute
                  key={route.name}
                  path={route.path}
                  componentName={route.name}
                  Component={route.component}
                  checkForSuperAdmin={route.checkForSuperAdmin}
                  exact={route.exact}
                />
              ))}
            {!isDriver &&
              standardRoutes.map((route) => (
                <PrivateRoute
                  path={route.path}
                  key={route.name}
                  componentName={route.name}
                  component={route.component}
                  exact={route.exact}
                />
              ))}
          </Switch>
        </ScrollToTopWrapper>
      </RivataPage>
    </CssRgbThemeColorsWrapper>
  )
}

export default withInitialLoadingHandler(withRouter(App))
