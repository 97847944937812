import { getClearLabel, getMileage } from '../../../utils'
import { IColumn, IRow } from '../../../components/RivataGrid'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'

const getColumnName = (column: string, unitsOfMeasurementConfig: any) => {
  let label = column

  if (
    column === 'mileage' ||
    column === 'start_odometer_value' ||
    column === 'end_odometer_value'
  ) {
    if (
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.km
    ) {
      label += ' (km)'
    } else {
      label += ' (mi)'
    }
  }

  return label !== 'vin' ? getClearLabel(label) : label.toUpperCase()
}

export const dailyReportColumns = (
  columns: Array<string>,
  unitsOfMeasurementConfig: any,
): Array<IColumn> => {
  columns.push('mileage') // not in response cause data will be calculated based on others response values on frontend side

  const parsedColumns = columns.map((column) => ({
    key: column,
    name: getColumnName(column, unitsOfMeasurementConfig),
    width: 260,
    minWidth: 100,
  }))

  return parsedColumns
}

type RowItem = {
  start_value: number
  end_value: number
  date: string
}

// interface IMileageRow {
//   asset_id: number
//   asset_name: string
//   vin: string
//   data: Array<RowItem>
// }

export const dailyReportRows = (
  rows: Array<IRow>,
  unitsOfMeasurementConfig: any,
): Array<any> => {
  return rows.reduce((accum: Array<IRow>, curr) => {
    curr.data.forEach((item: RowItem) => {
      const start_value = item.start_value
      const end_value = item.end_value

      accum.push({
        asset_name: curr.asset_name,
        vin: curr.vin,
        date: item.date,
        start_odometer_value: getMileage(
          start_value,
          unitsOfMeasurementConfig,
        ).toFixed(2),
        end_odometer_value: getMileage(
          end_value,
          unitsOfMeasurementConfig,
        ).toFixed(2),
        mileage: getMileage(
          end_value - start_value,
          unitsOfMeasurementConfig,
        ).toFixed(2),
      })
    })

    return accum
  }, [])
}
