import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debounce, cloneDeep } from 'lodash'
import Button from '../../componentsV2/Button'
import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import { getGpsDropdownItems } from './utils'
import { geofenceSearchItems } from './filterConfigs'
import AssetGroupsFilter from '../../componentsV2/AssetGroupsFilter'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import AssetTypeFilter from '../../componentsV2/AssetTypeFilter'
import SensorTypeFilter from '../../componentsV2/SensorTypeFilter'
import AssetNameVinFilter from '../../componentsV2/AssetNameVinFilter'
import GeofenceFilter from '../../componentsV2/GeofenceFilter'
import SensorIdFilter from '../../componentsV2/SensorIdFilter'
import GeofenceGroupsFilter from '../../componentsV2/GeofenceGroupsFilter'
import { useActions } from '../../hooks/useActions'
import ControlledHealthStatusFilter from '../../componentsV2/HealthStatusFilter/ControlledHealthStatusFilter'
import { selectIsFilterQueryEmpty } from '../../redux/dashboard/selectors'

interface Props {
  timezone: string
  onFilter: () => void
}

const debouncer = debounce((func: () => void) => func(), 400)

const wrapperStyle = { width: '470px' }

const healthStatusParentIdsToSkip = [
  'tire',
  'hub',
  'line_pressure',
  'no_status',
  'asset_state',
  'asset_speed',
  'pressure_fast_leak',
  'geofence',
]

const FilterMenu: React.FC<Props> = (props) => {
  const { timezone, onFilter } = props

  const groupsFilterRef = useRef<any>(null)
  const geofenceGroupsFilterRef = useRef<any>(null)
  const assetTypeFilterRef = useRef<any>(null)
  const sensorTypeFilterRef = useRef<any>(null)
  const assetNameVinFilterRef = useRef<any>(null)
  const sensorIdFilterRef = useRef<any>(null)
  const [clearLabelSelector, setClearLabelSelector] = useState(0)
  const [dropdownItems, setDropdownItems] = useState({
    geofenceSearchItems: cloneDeep(geofenceSearchItems),
    gpsDropdownItems: getGpsDropdownItems(timezone),
  })

  const {
    selectedCustomersList,
    healthStatusItems,
    isFilterQueryEmpty,
    filterParams,
  } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
    healthStatusItems: state.dash.filters.healthStatus as MenuItemType[],
    isFilterQueryEmpty: selectIsFilterQueryEmpty(state),
    filterParams: state.dash.filterParams,
  }))

  const {
    setFiltersField,
    setFilterParamsField,
    deleteFilterParamsField,
    resetAssetsTabFilters,
  } = useActions()

  useEffect(() => {
    setDropdownItems((prev) => ({
      ...prev,
      gpsDropdownItems: getGpsDropdownItems(timezone),
    }))
  }, [timezone])

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value

      if (!tempValue || !tempValue.length) {
        deleteFilterParamsField(key)
      } else if (Array.isArray(tempValue)) {
        setFilterParamsField(key, tempValue)
      } else {
        setFilterParamsField(key, [tempValue])
      }

      onFilter()
    },
    [onFilter, setFilterParamsField, deleteFilterParamsField],
  )

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value))
  }

  const handleFiltersReset = useCallback(() => {
    resetComponentsInternalState()

    // make filtering only if filter query params are different from prevoius
    if (isFilterQueryEmpty) return

    resetAssetsTabFilters()
    onFilter()
  }, [onFilter, timezone, resetAssetsTabFilters, isFilterQueryEmpty])

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  function getHealthStatusFilterLabel(items: MenuItemType[]) {
    const selectedItems = items.filter(
      (item) => item.checked && !healthStatusParentIdsToSkip.includes(item.id),
    )

    return (
      'Health Status' +
      (selectedItems && selectedItems?.length > 0
        ? `: ${selectedItems?.length} selected`
        : '')
    )
  }

  function resetComponentsInternalState() {
    //some elements must be reseted even if filters weren't selected
    setClearLabelSelector((prev) => prev + 1)
    setDropdownItems({
      geofenceSearchItems: cloneDeep(geofenceSearchItems),
      gpsDropdownItems: getGpsDropdownItems(timezone),
    })

    groupsFilterRef?.current?.clearSelection()
    geofenceGroupsFilterRef?.current?.clearSelection()
    assetTypeFilterRef?.current?.clearSelection()
    sensorTypeFilterRef?.current?.clearSelection()
    assetNameVinFilterRef?.current?.clearValue()
    sensorIdFilterRef?.current?.clearValue()
  }

  useEffect(() => {
    if (selectedCustomersList.length > 0) {
      resetComponentsInternalState()
    }
  }, [selectedCustomersList])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap'>
        <AssetNameVinFilter
          wrapperStyle={wrapperStyle}
          onChange={onSearchByStringValue}
          ref={assetNameVinFilterRef}
        />

        <SensorIdFilter
          ref={sensorIdFilterRef}
          wrapperStyle={wrapperStyle}
          updateFilters={updateFilters}
        />

        <GeofenceFilter
          wrapperStyle={wrapperStyle}
          clearSelection={clearLabelSelector}
          updateFilters={updateFilters}
          searchParams={{ current: filterParams }}
        />
      </div>

      <br />

      <div className='d-flex flex-wrap justify-content-between'>
        <div className='d-flex flex-wrap'>
          <AssetTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={assetTypeFilterRef}
          />
          <SensorTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={sensorTypeFilterRef}
          />

          <ControlledHealthStatusFilter
            items={healthStatusItems}
            buttonLabel={getHealthStatusFilterLabel(healthStatusItems)}
            containerClassName='mr-2'
            onItemsChange={(items) => {
              setFiltersField('healthStatus', items)
              onDropdownItemSelect(
                'status_2',
                items.filter(
                  (item) =>
                    item.checked &&
                    !healthStatusParentIdsToSkip.includes(item.id),
                ),
              )
            }}
          />

          <DropdownItemSelector
            id='last_gps_update_2'
            filterClassName='mr-2'
            filterBtnLabel='Last GPS Update'
            items={dropdownItems.gpsDropdownItems}
            onItemsChange={onDropdownItemSelect}
          />
          <AssetGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={groupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(',')}
          />
          <GeofenceGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={geofenceGroupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(',')}
          />
        </div>

        <div>
          <Button
            onClick={() => {
              handleFiltersReset()
            }}
          >
            Clear
          </Button>
        </div>
      </div>
    </>
  )
}

export default React.memo(FilterMenu)
