import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { Typeahead, Menu, MenuItem, ClearButton } from "react-bootstrap-typeahead";

import { useTypedSelector } from "../../hooks/useTypedSelector";

import './styles.scss';

const CustomInput = ({ inputRef, referenceElementRef, inputValue, ...inputProps }) => (
  <input
    {...inputProps}
    value={inputValue}
    className="form-control"
    ref={(input) => {
      inputRef(input);
      referenceElementRef(input);
    }}
  />
)

const CustomClearButton = ({ onClick }) => (
  <div className="rbt-aux">
    <ClearButton
      onClick={onClick}
    />
  </div>
)

const CustomersDropdown = ({
  label,
  locale,
  onChange,
  isLoading = false,
  shouldRemoveAllOption = false,
  customSelectedCustomerName = "",
  ml = "ml-3"
}) => {
  const { selectedCustomersList, data, loading } = useTypedSelector(state => ({
    ...state.common.customers
  }))
  const [singleSelection, setSingleSelection] = useState(selectedCustomersList[0]);
  const [groupedCustomers, setGroupedCustomers] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (selectedCustomersList[0] && selectedCustomersList[0]?.name !== singleSelection?.name) {
      setSingleSelection(selectedCustomersList[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomersList])

  // if user have access only to child customers pass them as parent customers in dropdown
  useEffect(() => {
    if (groupedCustomers.length === 1 && groupedCustomers[0].id === null && data.length > 1) {
      const newGroupedCustomers = [...data];
      setGroupedCustomers(newGroupedCustomers);
    }
  }, [groupedCustomers, data])

  useEffect(() => {
    if (singleSelection) {
      const parent = singleSelection.parent;
      const formattedValue = parent ? `${parent.name} / ${singleSelection.name}` : singleSelection.name;

      setInputValue(formattedValue);
    }
  }, [singleSelection]);

  useEffect(() => {
    const parentCustomers = data.filter(c => !c.parent_id);
    const childCustomers = data.filter(c => c.parent_id);

    parentCustomers.forEach(element => element.children = []);

    childCustomers.forEach(element => {
      const parentCustomerId = element.parent_id;
      const parent = parentCustomers.find(c => c.id === parentCustomerId);
      if (parent) {
        element.parent = parent;
        parent.children.push(element);
      }
    });

    setGroupedCustomers(parentCustomers);
  }, [data]);

  // for asset migration tool on provision page 
  useEffect(() => {
    if (shouldRemoveAllOption) {
      const idx = groupedCustomers.findIndex(el => el.id === null)
      if (idx > -1) {
        const newCustomersList = [ ...groupedCustomers ]
        newCustomersList.splice(idx, 1)

        setGroupedCustomers(newCustomersList)
        const c = data.find(el => el.name === customSelectedCustomerName)
        setSingleSelection(c || newCustomersList[0])
      }
    }
  }, [groupedCustomers, data, shouldRemoveAllOption, customSelectedCustomerName])

  const renderMenu = (results, menuProps) => {
    return <Menu className="menu" {...menuProps}>
      {results.map((result) => (
        <span key={result.id}>
          <MenuItem className="menu_item" option={result} >
            {result.name}
          </MenuItem>
          {result.children &&
            result.children.map(c => {
              return <MenuItem key={c.id} className="menu_sub_item" option={c} >
                {c.name}
              </MenuItem>
            })
          }
        </span>
      ))}
    </Menu>
  }

  const onInputChange = (text, event = null) => {
    setInputValue(text);
  }

  const onSelectChange = (data) => {
    const singleSelection = data.length ? data[0] : null;
    setSingleSelection(singleSelection);
    if (singleSelection) {
      onChange(singleSelection);
    }
  }

  const filterBy = (option, props) => {
    const text = props.text.toLowerCase();
    if (!text) {
      return option;
    }

    const found = (option.children || []).find(e => e.name.toLowerCase().includes(text));
    if (found) {
      return option;
    }

    if (option.name.toLowerCase().includes(text)) {
      return option;
    }
  }

  return (
    data.length > 1 ? (
      <div className={`${ml} d-inline-flex justify-content-center align-items-center`}>
        <Label className="mr-2 mb-0">{label}</Label>
        <Typeahead
          className="customers_dropdown"
          id={`customers-typeahead-single-${label}`}
          isLoading={isLoading || loading}
          labelKey="name"
          onInputChange={onInputChange}
          onChange={onSelectChange}
          options={groupedCustomers}
          placeholder={locale.chooseVin_todo || "Choose a customer"}
          selected={singleSelection ? [singleSelection] : []}
          renderMenu={renderMenu}
          renderInput={(props) => <CustomInput {...props} inputValue={inputValue} />}
          filterBy={filterBy}
        >
          {({ onClear, selected }) =>
            !!selected.length && (
              <CustomClearButton onClick={() => {
                onClear();
                onInputChange("");
              }} />
            )
          }
        </Typeahead>
      </div>
    ) : null
  );
};

export default CustomersDropdown;