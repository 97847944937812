import React from "react";
import RivataDropdown from "../RivataDropdown";

const ProfilesDropdown = ({ onSelect, profiles, selected, locale }) => {
  const def = profiles.filter((el)=>el.name === "Default")
  const toSort = profiles.filter((el)=>el.name !== "Default").sort((a, b) => {
    if(a.name.toLowerCase() > b.name.toLowerCase())
    return 1
    else return -1
  } )
  const res = [...def, ...toSort]
  return (
    <RivataDropdown
      items={res}
      caret={true}
      onSelect={(item) => {
        onSelect(item);
      }}
      selected={selected}
      locale={locale}
    />
  );
};

export default ProfilesDropdown;
