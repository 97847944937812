import { Dispatch } from 'redux'
import { Moment } from 'moment'
import api from '../../services/api'
import { DATE_FORMAT, convertDataEpochToDate } from '../../utils'
import { getStatusFromState, getStatusObj } from '../../utils/utils'
import { store } from '../store'
import { IgetState } from '../types'
import { GeofenceBodyType, ManageGeofencesTypes } from './types'

export const setManageGeofencesLimit = (payload: number) => ({
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_LIMIT,
  payload,
})

export const setManageGeofencesOffset = (payload: number) => ({
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_OFFSET,
  payload,
})

export const setManageGeofencesShowDeleted = (payload: boolean) => ({
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_SHOW_DELETED,
  payload,
})

export const clearManageGeofencesReducer = () => ({
  type: ManageGeofencesTypes.RESET_MANAGE_GEOFENCES_REDUCER,
})

export function fetchManageGeofences() {
  return function (dispatch: Dispatch, getState: IgetState) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
      payload: true,
    })

    const state = getState()

    const {
      limit,
      offset,
      showDeleted,
      filters,
      // sortOptions: { column, direction },
    } = state.manageGeofences

    const customers = state.common.customers.data as Array<ICustomer>

    return api
      .getGeoLocations(
        limit,
        offset,
        undefined,
        filters,
        undefined,
        undefined,
        true,
        showDeleted,
      )
      .then((geofences: any) => {
        const totalCount = geofences.total_count

        geofences = convertDataEpochToDate(
          geofences.paged_data,
          'created_at_epoch',
          'created_at',
        )

        geofences = geofences.map((geofence: IGeofence) => {
          if (geofence.is_deleted) {
            const regex = /^(.+)_deleted_(\d+)$/ //matches name with deleted postfix and puts name and epoch in capture groups
            const match = geofence.name.match(regex)

            if (match) {
              const deleteMoment = convertDataEpochToDate(+match[2]) as Moment

              geofence.deleteDateTime = deleteMoment.format(DATE_FORMAT)

              geofence.nameOriginal = match[1]
              geofence.nameDeleted =
                match[1] + '_deleted_' + deleteMoment.format('MMDDYY')
            }
          }

          geofence.haveAccessToCreator = customers.some(
            (customer) => customer.id === geofence.customer_id,
          )

          if (geofence.shared_with_customer_ids) {
            const sharedWithNames = customers
              .filter(
                (customer) =>
                  geofence.shared_with_customer_ids?.includes(customer.id),
              )
              .map((customer) => customer.name)

            const unknownCustomersCount =
              geofence.shared_with_customer_ids.length - sharedWithNames.length

            const sharedWithString = `${sharedWithNames.join(', ')}${
              unknownCustomersCount ? ` and ${unknownCustomersCount} more` : ''
            }`
            geofence.sharedWithString = sharedWithString
          }

          return geofence
        })

        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA,
          payload: geofences,
        })
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_COUNT,
          payload: totalCount,
        })
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA_STATUS,
          payload: getStatusObj(),
        })
      })
      .catch((err) => {
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA_STATUS,
          payload: getStatusObj(err),
        })
      })
      .finally(() => {
        dispatch({
          type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
          payload: false,
        })
      })
  }
}

export function createGeofence(data: GeofenceBodyType) {
  return async function (dispatch: Dispatch<any>, getState: IgetState) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
      payload: true,
    })

    const { selectedCustomersList } = getState().common.customers

    const id =
      selectedCustomersList.length === 1 ? selectedCustomersList[0].id : null

    if (!id)
      return getStatusObj({
        statusCode: 400,
        message: 'Only one customer must be selected',
      })

    return await api
      .postGeoLocation({ ...data, customer_id: id })
      .then(() => {
        dispatch(fetchManageGeofences())
        dispatch(fetchManageGeofencesAllShortData())

        const status = getStatusObj()
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        return status
      })
      .catch((err: any) => {
        const status = getStatusObj(err)
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        dispatch({
          type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
          payload: false,
        })
        return status
      })
  }
}

export function updateGeofence(id: number, data: GeofenceBodyType) {
  return async function (dispatch: Dispatch<any>) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
      payload: true,
    })

    return await api
      .putGeoLocation(id, data)
      .then(() => {
        dispatch(fetchManageGeofences())
        dispatch(fetchManageGeofencesAllShortData())

        const status = getStatusObj()
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        return status
      })
      .catch((err: any) => {
        const status = getStatusObj(err)
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        dispatch({
          type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
          payload: false,
        })
        return status
      })
  }
}

export function deleteGeofence(id: number) {
  return async function (dispatch: Dispatch<any>) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
      payload: true,
    })

    return await api
      .deleteGeoLocation(id)
      .then(() => {
        dispatch(fetchManageGeofences())
        dispatch(fetchManageGeofencesAllShortData())

        const status = getStatusObj()
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        return status
      })
      .catch((err: any) => {
        const status = getStatusObj(err)
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        dispatch({
          type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
          payload: false,
        })
        return status
      })
  }
}

export function assignGatewayToLocation(data: any) {
  return function (dispatch: Dispatch<any>) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
      payload: true,
    })

    return api
      .postAssignGatewayToLocation(data)
      .then((res) => {
        if (!res.ok) {
          return getStatusObj({ statusCode: 400 }, res.message)
        }

        const status = getStatusObj()

        dispatch(fetchManageGeofences())
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })

        return status
      })
      .catch((err) => {
        const status = getStatusObj(err, err.message.split('Exception: ')[1])
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS,
          payload: status,
        })
        dispatch({
          type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
          payload: false,
        })

        return status
      })
  }
}

export function unnasignGatewayFromlocation(gatewayEsn: string) {
  return function (dispatch: Dispatch<any>) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
      payload: true,
    })
    return api
      .deleteUnnasignGatewayFromLocation(gatewayEsn)
      .then((response) => {
        dispatch(fetchManageGeofences())
        return getStatusObj()
      })
      .catch((err) => {
        dispatch({
          type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES,
          payload: false,
        })
        return getStatusObj(err)
      })
  }
}

export const setManageGeofencesAssetsFilter = (payload: string) => ({
  type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_FILTER,
  payload,
})

export function fetchManageGeofencesAssets() {
  return function (dispatch: Dispatch, getState: IgetState) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ASSETS,
      payload: true,
    })

    const filter = getState().manageGeofences.assets.filter

    const queryParams = new URLSearchParams(filter)

    queryParams.append('hide_autogenerated', 'true')

    api
      .getLatestGpsV2(queryParams.toString())
      .then((locations) => {
        locations.forEach((loc: any) => {
          const warningInfo = getStatusFromState(loc.warning_state)
          loc.hasCriticalWarning =
            warningInfo.tpms.critWarn ||
            warningInfo.smartHub.critWarn ||
            warningInfo.linePressure.critWarn
          loc.hasWarning = warningInfo.tpms.warn || warningInfo.smartHub.warn
          delete loc.warning_state
        })

        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS,
          payload: locations,
        })
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_STATUS,
          payload: getStatusObj(),
        })
      })
      .catch((err) => {
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_STATUS,
          payload: getStatusObj(err),
        })
      })

    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ASSETS,
      payload: false,
    })
  }
}

export function fetchManageGeofencesAllShortData() {
  return function (dispatch: Dispatch) {
    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ALL_SHORT_DATA,
      payload: true,
    })

    api
      .getAllGeofencesShortFormat(false)
      .then((res) => {
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA,
          payload: res,
        })

        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA_STATUS,
          payload: getStatusObj(),
        })
      })
      .catch((err) => {
        dispatch({
          type: ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA_STATUS,
          payload: getStatusObj(err),
        })
      })

    dispatch({
      type: ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ALL_SHORT_DATA,
      payload: false,
    })
  }
}

export function setGeofencesListFilters(filters: string) {
  return function (dispatch: Dispatch<any>) {
    dispatch({
      type: ManageGeofencesTypes.SET_GEOFENCES_LIST_FILTERS,
      payload: filters,
    })
  }
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction

  if (
    lastAction.type === ManageGeofencesTypes.SET_MANAGE_GEOFENCES_LIMIT ||
    lastAction.type === ManageGeofencesTypes.SET_MANAGE_GEOFENCES_SHOW_DELETED
  ) {
    store.dispatch(setManageGeofencesOffset(0))
  } else if (
    lastAction.type === ManageGeofencesTypes.SET_MANAGE_GEOFENCES_OFFSET
  ) {
    store.dispatch(fetchManageGeofences())
  }

  if (
    lastAction.type === ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_FILTER
  ) {
    store.dispatch(fetchManageGeofencesAssets())
  }
})
