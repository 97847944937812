import { useMemo } from 'react'

import {
  AssetSpeedLevel,
  BearingVibrationLevel,
  NotificationTypes,
  PressureWarningLevel,
  TemperatureWarningLevel,
  TireVibrationLevel,
} from '../../enums'

import {
  composeDynamicActionsFormatter,
  IActionColumnConfig,
} from '../../components/RivataGrid/cellFormatter'
import { IColumn, IRow } from '../../components/RivataGrid'

import { secondsToHours } from './utils'
import { NotificationSystemProfileNames } from '../../constants/constants'

interface IuseNotificationUsersColumns {
  (formValues: any, onDelete: (id: number) => void): Array<IColumn>
}

export const useNotificationUsersColumns: IuseNotificationUsersColumns = (
  formValues,
  onDelete,
) => {
  return useMemo(() => {
    const { sms, email } = formValues

    const formatterConfig: IActionColumnConfig = {
      onDelete,
      // tooltipTexts: {
      //     deleteTooltipText: "Remove User",
      // }
    }

    const columns: Array<IColumn> = [
      {
        name: 'Remove',
        key: 'remove',
        minWidth: 75,
        width: 75,
        renderCell: ({ row }: any) =>
          composeDynamicActionsFormatter({ ...formatterConfig, cellData: row }),
      },
      { name: 'Username', key: 'username', minWidth: 100, width: '25%' },
      { name: 'First Name', key: 'first_name', minWidth: 100, width: '20%' },
      { name: 'Last Name', key: 'last_name', minWidth: 100, width: '20%' },
    ]

    if (email) {
      columns.push({ name: 'Email', key: 'email', minWidth: 100, width: '30%' })
      columns.push({ name: 'Email Verified', key: 'email_verified', width: 75 })
    }
    if (sms)
      columns.push({
        name: 'Phone Number Verified',
        key: 'phone_number_verified',
        width: 75,
      })

    const lastColumn = { ...columns[columns.length - 1] }
    lastColumn.width = undefined
    columns[columns.length - 1] = lastColumn
    return columns
  }, [formValues, onDelete])
}

export const useNotificationUsersRows = (
  data: Array<IUserShortFormat> | null,
): Array<IRow> => {
  return useMemo(() => {
    if (!data) return []

    return data.map((row) => {
      return {
        ...row,
        email_verified: row.email_verified.toString(),
        phone_number_verified: row.phone_number_verified.toString(),
      }
    })
  }, [data])
}

const defaultInitialValues = {
  name: '',
  sms: false,
  email: false,
  critical_alert_interval_in_seconds: 24,
  info_alert_interval_in_seconds: 72,
  tire_pressure: NotificationTypes.none,
  line_pressure: NotificationTypes.none,
  hub_temperature: NotificationTypes.none,
  asset_state: NotificationTypes.none,
  geofence: NotificationTypes.none,
  tire_vibration: NotificationTypes.none,
  bearing_vibration: NotificationTypes.none,
  asset_speed: NotificationTypes.none,
  pressure_fast_leak: NotificationTypes.none,
  is_system_profile: false,
  is_combined: false,
}

export type NotificationFormValues = typeof defaultInitialValues

interface IuseInitialFormValues {
  (profileToEdit: INotificationProfile | null): NotificationFormValues
}

export const useInitialFormValues: IuseInitialFormValues = (profileToEdit) => {
  return useMemo(() => {
    if (!profileToEdit) return defaultInitialValues

    const notificationsIntervals = profileToEdit.intervals
    const tirePressureWarningTypes = profileToEdit.profile.tire_pressure
    const linePressureWarningTypes = profileToEdit.profile.line_pressure
    const hubTemperatureWarningTypes = profileToEdit.profile.hub_temperature
    const assetStateWarningTypes = profileToEdit.profile.asset_state
    const geofenceAlertTypes = profileToEdit.profile.geofence
    const tireVibrationWarningTypes = profileToEdit.profile.tire_vibration
    const bearingVibrationWarningTypes = profileToEdit.profile.bearing_vibration
    const assetWarningTypes = profileToEdit.profile.asset_speed
    const pressureFastLeakTypes = profileToEdit.profile.pressure_fast_leak

    let tirePressure = NotificationTypes.none
    let linePressure = NotificationTypes.none
    let hubTemperature = NotificationTypes.none
    let assetState = NotificationTypes.none
    let geofence = NotificationTypes.none
    let tireVibration = NotificationTypes.none
    let bearingVibration = NotificationTypes.none
    let assetSpeed = NotificationTypes.none
    let pressureFastLeak = NotificationTypes.none

    // tire pressure
    if (tirePressureWarningTypes) {
      if (tirePressureWarningTypes.length === 4)
        tirePressure = NotificationTypes.all
      else if (tirePressureWarningTypes.length === 2) {
        if (
          tirePressureWarningTypes.includes(
            PressureWarningLevel.criticalLowPressure,
          ) &&
          tirePressureWarningTypes.includes(
            PressureWarningLevel.criticalOverPressure,
          )
        )
          tirePressure = NotificationTypes.criticalWarning
        else if (
          tirePressureWarningTypes.includes(PressureWarningLevel.lowPressure) &&
          tirePressureWarningTypes.includes(PressureWarningLevel.overPressure)
        )
          tirePressure = NotificationTypes.warning
      } else tirePressure = NotificationTypes.none
    }

    // line pressure
    if (linePressureWarningTypes?.length) linePressure = NotificationTypes.all

    // hub temperature
    if (hubTemperatureWarningTypes) {
      if (hubTemperatureWarningTypes.length === 2)
        hubTemperature = NotificationTypes.all
      else if (hubTemperatureWarningTypes.length === 1) {
        if (
          hubTemperatureWarningTypes.includes(
            TemperatureWarningLevel.criticalHighTemperature,
          )
        ) {
          hubTemperature = NotificationTypes.criticalWarning
        } else if (
          hubTemperatureWarningTypes.includes(
            TemperatureWarningLevel.highTemperature,
          )
        ) {
          hubTemperature = NotificationTypes.warning
        }
      } else hubTemperature = NotificationTypes.none
    }

    // asset state
    if (assetStateWarningTypes?.length) {
      assetState = NotificationTypes.all
    }

    // geofence alerts
    if (geofenceAlertTypes) {
      if (geofenceAlertTypes.length === 2) geofence = NotificationTypes.all
      else if (geofenceAlertTypes.length === 1) {
        if (geofenceAlertTypes.includes('entry')) {
          geofence = NotificationTypes.geofenceEntry
        } else if (geofenceAlertTypes.includes('exit')) {
          geofence = NotificationTypes.geofenceExit
        }
      } else geofence = NotificationTypes.none
    }

    // tire vibration
    if (tireVibrationWarningTypes) {
      if (tireVibrationWarningTypes.length === 2)
        tireVibration = NotificationTypes.all
      else if (tireVibrationWarningTypes.length === 1) {
        if (
          tireVibrationWarningTypes.includes(
            TireVibrationLevel.abnormalHighTireVibration,
          )
        ) {
          tireVibration = NotificationTypes.criticalWarning
        } else if (
          tireVibrationWarningTypes.includes(
            TireVibrationLevel.abnormalTireVibration,
          )
        ) {
          tireVibration = NotificationTypes.warning
        }
      } else tireVibration = NotificationTypes.none
    }

    // bearing vibration
    if (bearingVibrationWarningTypes) {
      if (bearingVibrationWarningTypes.length === 2)
        bearingVibration = NotificationTypes.all
      else if (bearingVibrationWarningTypes.length === 1) {
        if (
          bearingVibrationWarningTypes.includes(
            BearingVibrationLevel.abnormalHighBearingVibration,
          )
        ) {
          bearingVibration = NotificationTypes.criticalWarning
        } else if (
          bearingVibrationWarningTypes.includes(
            BearingVibrationLevel.abnormalBearingVibration,
          )
        ) {
          bearingVibration = NotificationTypes.warning
        }
      } else bearingVibration = NotificationTypes.none
    }

    // asset speed
    if (assetWarningTypes) {
      if (assetWarningTypes.length === 2) assetSpeed = NotificationTypes.all
      else if (assetWarningTypes.length === 1) {
        if (assetWarningTypes.includes(AssetSpeedLevel.criticalOverSpeed)) {
          assetSpeed = NotificationTypes.criticalWarning
        } else if (assetWarningTypes.includes(AssetSpeedLevel.overSpeed)) {
          assetSpeed = NotificationTypes.warning
        }
      } else assetSpeed = NotificationTypes.none
    }

    // pressure fast leak
    if (pressureFastLeakTypes?.length) {
      pressureFastLeak = NotificationTypes.all
    }

    let is_system_profile = profileToEdit.is_system_profile

    return {
      name: is_system_profile
        ? NotificationSystemProfileNames[profileToEdit.name]
        : profileToEdit.name,
      sms: profileToEdit.alert_notification_types.sms,
      email: profileToEdit.alert_notification_types.email,
      critical_alert_interval_in_seconds: secondsToHours(
        notificationsIntervals.critical_alert_interval_in_seconds,
      ),
      info_alert_interval_in_seconds: secondsToHours(
        notificationsIntervals.info_alert_interval_in_seconds,
      ),
      tire_pressure: tirePressure,
      line_pressure: linePressure,
      hub_temperature: hubTemperature,
      asset_state: assetState,
      tire_vibration: tireVibration,
      bearing_vibration: bearingVibration,
      is_system_profile: is_system_profile,
      geofence: geofence,
      asset_speed: assetSpeed,
      pressure_fast_leak: pressureFastLeak,
      is_combined: profileToEdit.is_combined,
    }
  }, [profileToEdit])
}
