import React, { useEffect } from 'react'

import Circle from '../shapes/Circle'
import Rectangle from '../shapes/Rectangle'
import Polygon from '../shapes/Polygon'

import { Geometry } from '../types'

interface Props {
  map: H.Map | null
  behavior: H.mapevents.Behavior | null
  selectedShape: string
  geometry: Geometry
  disabled: boolean
  setGeometry: (geometry: Geometry) => void
  onDrawingStarted: () => void
  onDrawingFinished: (geofenceWKT: string) => void
}

const GeofenceConstructor: React.FC<Props> = ({
  map,
  behavior,
  geometry,
  selectedShape,
  disabled,
  setGeometry,
  onDrawingStarted,
  onDrawingFinished,
}) => {
  useEffect(() => {
    if (!map || !selectedShape || disabled) return

    if (geometry) geometry.dispose(true)
    setGeometry(null)

    if (selectedShape === 'circle')
      setGeometry(
        new Circle(map, behavior, onDrawingStarted, onDrawingFinished),
      )

    if (selectedShape === 'rectangle')
      setGeometry(
        new Rectangle(map, behavior, onDrawingStarted, onDrawingFinished),
      )

    if (selectedShape === 'polygon')
      setGeometry(
        new Polygon(map, behavior, onDrawingStarted, onDrawingFinished),
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, selectedShape, disabled])

  return <></>
}

export default GeofenceConstructor
