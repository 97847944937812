import { IPowerInputs } from "../../redux/assetDetails/types";
import { convertDataEpochToDate, DATE_FORMAT, getClearLabel, sortByAlphabet } from "../../utils";

const colors = ["#297568", "#40beac", "#12284b", "#3ab0c7", "#007497", "#85d4ec", "#749799", "#914921"]

export const composeChartData = (data: IPowerInputs) => {
    const keys = Object.keys(data)

    if (keys.length < 1) return null
    
    const template: { [key: string]: Array<any> } = {
        accelerometer_motion: [],
        brake_circuit: [],
        external_power: [],
        external_voltage: [],
        ignition: [],
        internal_battery_charge_is_low: [],
        internal_battery_voltage: [],
        powered_by_internal_battery: []
    }

    const dropdownItems = keys.map((key) => ({ id: key, label: getClearLabel(key) }))
    sortByAlphabet(dropdownItems, "label")

    const voltages = ["internal_battery_voltage", "external_voltage"]

    keys.forEach((key) => {
        const isVoltage = voltages.includes(key)

        data[key].forEach((item) => {
            const xStart = convertDataEpochToDate(item.epoch_start, null, null, false)
            const value = isVoltage ? (item.value / 1000).toFixed(3) : item.value

            const point = {
                x: xStart,
                y: value,
                displayFooter: `Date: ${xStart.format(DATE_FORMAT)}`,
                displayValue: `Value: ${value}`
            }

            template[key].push(point)

            // if only 1 point was aggregated then don't render second at the same epoch with the same value
            if (item.epoch_start !== item.epoch_end) {
                const xEnd = convertDataEpochToDate(item.epoch_end, null, null, false)
                template[key].push({ ...point, x: xEnd, displayFooter: `Date: ${xEnd.format(DATE_FORMAT)}` })
            }
        })
        
    })

    const datasets: any = []

    keys.forEach((key, idx) => {
        if (!template[key].length) return;

        datasets.push({
            backgroundColor: colors[idx],
            borderColor: colors[idx],
            borderWidth: 3,
            fill: false,
            label: getClearLabel(key),
            id: key,
            data: template[key]
        }) 
    })

    return { datasets, dropdownItems: [ { id: "all", label: "All" }, ...dropdownItems ] }
}
