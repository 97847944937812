import { cloneDeep } from 'lodash'
import { IRow } from '../../../components/RivataGrid'
import { NoDataLabels } from '../../../enums'
import {
  DATE_FORMAT_WITH_SECONDS,
  ONLY_DATE,
  convertDataEpochToDate,
  getClearLabel,
  getMileage,
  kmhToMph,
  parseSecondsToDateParts,
} from '../../../utils'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'

export const utilizationReportRows = (
  rows: Array<IRow>,
  unitsOfMeasurementConfig: any,
) => {
  const rowsToMap = cloneDeep(rows)
  return rowsToMap.map((row) => {
    row.date_start = convertDataEpochToDate(
      parseInt(row.date_start),
      null,
      null,
      false,
    ).format(ONLY_DATE)
    row.date_end = convertDataEpochToDate(
      parseInt(row.date_end),
      null,
      null,
      false,
    ).format(ONLY_DATE)
    row.parent_customer_name = row.parent_customer_name
      ? row.parent_customer_name
      : NoDataLabels.TRIPPLE_DASH
    row.asset_subtype = row.asset_subtype
      ? row.asset_subtype
      : NoDataLabels.TRIPPLE_DASH
    row.model = row.model ? row.model : NoDataLabels.TRIPPLE_DASH

    row.year = row.year ? row.year : NoDataLabels.TRIPPLE_DASH
    row.moving_time = durationSecondsEpochToTimeLabel(row.moving_time)
    row.idle_time = durationSecondsEpochToTimeLabel(row.idle_time)
    row.unused_time = durationSecondsEpochToTimeLabel(row.unused_time)
    row.last_location_date_time = row.last_log_time
    row.latest_latitude = row.last_lat
    row.latest_longitude = row.last_lon
    row.distance_traveled = getMileage(
      row.distance_traveled,
      unitsOfMeasurementConfig,
    ).toFixed(2)
    row.avg_distance_traveled_per_day = getMileage(
      row.avg_distance_traveled_per_day,
      unitsOfMeasurementConfig,
    ).toFixed(2)
    if (
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.miles
    ) {
      row.top_speed = Math.ceil(kmhToMph(row.top_speed))
      row.average_speed = Math.ceil(kmhToMph(row.average_speed))
    } else {
      row.top_speed = Math.ceil(row.top_speed)
      row.average_speed = Math.ceil(row.average_speed)
    }
    row.units =
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.miles
        ? 'mph'
        : 'kph'
    row.last_gateway_communication = row.last_gateway_communication
      ? convertDataEpochToDate(
          row.last_gateway_communication,
          null,
          null,
          false,
        ).format(DATE_FORMAT_WITH_SECONDS)
      : NoDataLabels.TRIPPLE_DASH

    let lastGwStatusLabel = 'No Communication'
    if (row.last_gateway_status) {
      const statusObj = row.last_gateway_status
      if (statusObj.ignition === 1) {
        lastGwStatusLabel = statusObj.speed > 8 ? 'Active' : 'Idle'
      } else {
        lastGwStatusLabel = 'Inactive'
      }
    }
    row.last_gateway_status = lastGwStatusLabel
    row.day_wise_utilization = `${Math.ceil(row.day_wise_utilization)}%`
    row.first_powered = row.first_powered
      ? convertDataEpochToDate(row.first_powered, null, null, false).format(
          DATE_FORMAT_WITH_SECONDS,
        )
      : NoDataLabels.TRIPPLE_DASH
    row.last_powered = row.last_powered
      ? convertDataEpochToDate(row.last_powered, null, null, false).format(
          DATE_FORMAT_WITH_SECONDS,
        )
      : NoDataLabels.TRIPPLE_DASH

    row.total_powered_time = durationSecondsEpochToTimeLabel(
      row.total_powered_time,
    )
    row.avg_powered_time_per_day = durationSecondsEpochToTimeLabel(
      row.avg_powered_time_per_day,
    )
    row.last_active_gateway_status = row.last_active_gateway_status
      ? convertDataEpochToDate(
          row.last_active_gateway_status,
          null,
          null,
          false,
        ).format(DATE_FORMAT_WITH_SECONDS)
      : NoDataLabels.TRIPPLE_DASH
    row.utilized_hours = row.utilized_hours
      ? `${Math.ceil(row.utilized_hours)}%`
      : '0%'
    row.last_location_date_time = row.last_location_date_time
      ? convertDataEpochToDate(
          row.last_location_date_time,
          null,
          null,
          false,
        ).format(DATE_FORMAT_WITH_SECONDS)
      : NoDataLabels.TRIPPLE_DASH
    row.asset_groups = row.asset_groups || '---'

    return row
  })
}
export const utilizationReportColumns = (columns: Array<string>) => {
  return columns.map((column) => {
    if (column === 'last_log_time') column = 'last_location_date_time'
    else if (column === 'last_lat') column = 'latest_latitude'
    else if (column === 'last_lon') column = 'latest_longitude'

    return {
      key: column,
      name: column === 'vin' ? 'VIN' : getClearLabel(column),
      minWidth: 100,
      width: setColumnWidth(column),
    }
  })
}
const durationSecondsEpochToTimeLabel = (data: any) => {
  if (data) {
    const {
      parsed: { days, hours, minutes },
    } = parseSecondsToDateParts(data)

    if (days > 0) {
      const daysLabel = days > 1 ? 'Days' : 'Day'

      data = `${days} ${daysLabel} ${hours} Hrs ${minutes} Min`
    } else {
      data = `${hours} Hrs ${minutes} Min`
    }
  } else data = NoDataLabels.TRIPPLE_DASH
  return data
}

const setColumnWidth = (key: string) => {
  switch (key) {
    case 'date_start':
    case 'date_end':
    case 'timezone':
    case 'year':
    case 'top_speed':
    case 'units':
      return 100

    case 'average_speed':
      return 120
    case 'vin':
    case 'asset_type':
    case 'asset_subtype':
    case 'latest_latitude':
    case 'latest_longitude':
    case 'days_active':
    case 'days_inactive':
      return 160
    default:
      return 200
  }
}
