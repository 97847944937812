import React, { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { SearchIcon } from "../Icon"
import SearchInput from "../SearchInput"

interface Props {
    wrapperStyle?: CSSProperties
    onChange: (id: string, val: string) => void
    wrapperClassName?: string
    initialValue?: string
}

const AssetNameVinFilter = forwardRef(({ wrapperStyle, onChange, wrapperClassName,initialValue }: Props, ref) => {
    const [value, setValue] = useState<string>("")
    const onSearchByAsset = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange("asset_name_vin", e.target.value)
        }
        setValue(e.target.value)
    }, [onChange])
    useImperativeHandle(ref, () => ({
        clearValue() {
            setValue("")
        }
    }))
    useEffect(()=>{
        if (initialValue)
            setValue(initialValue)
    }, [initialValue])
    return (
        <SearchInput
            placeholder='Search by VIN or Asset name'
            icon={<SearchIcon width={20} height={20} color='black' />}
            wrapperStyle={wrapperStyle}
            value={value}
            onChange={onSearchByAsset}
            wrapperClassName={wrapperClassName}
        />)
})

export default React.memo(AssetNameVinFilter)