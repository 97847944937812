import React from 'react'
import { Col } from "reactstrap";
import SubNav from '../../components/SubNav'
import AssetGroupsDetailsModule from '../../modules/AssetGroupsDetailsModule';
import { match } from 'react-router-dom';

interface Props {
    match: match<{ id: string }>
}

const breadcrumbs = [
    { label: "Home", path: "/dashboard" },
    { label: "Asset Groups", path: "/asset-groups" },
    { label: "Details" }
]

const AssetGroupsDetails: React.FC<Props> = ({match}) => {
    return (
        <>
            <SubNav
                title="Asset Group Details"
                breadcrumbs={breadcrumbs}
            ></SubNav>

            <Col md="12">
                <AssetGroupsDetailsModule group_id={match.params.id}/>
            </Col>
        </>
    )
}

export default AssetGroupsDetails
                                                        