import { AnnotationOptions } from "chartjs-plugin-annotation"
import { handleZoomSync } from "."
import { convertDataEpochToDate } from "../../utils"
import { chartDefaultOptions } from "./chartDefaultOptions"
import { IChartOptions } from "./interfaces"
import { Chart } from 'react-chartjs-2'
import { saveGoogleAnalyticsEvent } from "../../utils/utils"

const adjustment = 60*60*1000

export const composeChartOptions: IChartOptions = ({
    id,
    xMin, 
    xMax, 
    yAxisLabel = chartDefaultOptions.yAxisLabel, 
    displayX = chartDefaultOptions.displayX,   
    tooltip = chartDefaultOptions.tooltip,
    xAxisLabel = chartDefaultOptions.xAxisLabel,
    annotations = chartDefaultOptions.annotations,
    assetMotionAnnotations = chartDefaultOptions.assetMotionAnnotations,
    isSync = chartDefaultOptions.isSync,
    point = chartDefaultOptions.elements.point,
    line = chartDefaultOptions.elements.line,
    animation = chartDefaultOptions.animation,
    yScale = JSON.parse(JSON.stringify(chartDefaultOptions.yScale)) //dereference
}) => {
    const allAnnotations: IDictStrKeys<AnnotationOptions> = { ...annotations, ...assetMotionAnnotations }

    xMin = xMin - adjustment
    xMax = xMax + adjustment
    yScale.title.text = yAxisLabel
    
    return {
        id,
        isSync,
        maintainAspectRatio: false,
        animation,
        elements: {
            line,
            point
        },
        scales: {
            y: yScale,
            x: {
                id: "x",
                type: "time",
                bound: "data",
                display: displayX,
                title: {
                    display: true,
                    text: xAxisLabel
                },
                min: xMin,
                max: xMax,
                time: {
                    minUnit: "minute",
                    displayFormats: {
                        millisecond: "MMM DD, h:mm A",
                        second: "MMM DD, h:mm A",
                        minute: "MMM DD, h:mm A",
                        hour: "MMM DD, h A",
                        day: "MMM DD",
                        week: "MMM DD",
                        month: "MMM DD",
                        quarter: "MMM DD",
                        year: "MMM DD",
                    },
                },
                ticks: {
                    maxRotation: 10,
                    callback: (value, idx, ticks) => {
                        return convertDataEpochToDate(ticks[idx].value / 1000, null, null, false).format("MMM DD, h:mm A")
                    },
                },
            }
        },
        plugins: {
            zoom: {
                limits: {
                    x: {min: xMin, max: xMax, minRange: 1000},
                },
                pan: {
                    mode: "x",
                    enabled: true,
                    threshold: 1,
                    onPan: isSync ? handleZoomSync : undefined
                },
                zoom: {
                    mode: "x",
                    drag: {
                        mode: "x",
                        enabled: false
                    },
                    wheel: {
                        enabled: true,
                        speed: 0.2,
                        mode: "x",
                    },
                    onZoom: isSync ? handleZoomSync : undefined
                }
            },
            legend: {
                display: true,
                position: "top",
                onClick(evt, item) {
                    saveGoogleAnalyticsEvent('chart_legend_click', { position: item.text })
                    Chart.defaults.plugins.legend.onClick.call(this, evt, item, this);
                  }
            },
            annotation: {
                annotations: allAnnotations
            },
            tooltip
        },
    }
}
