import React, { useEffect, useState } from "react"
import { ReportParameterRowProps } from "./ReportParameterRowProps"
import { sensorStatusItems } from "../../../componentsV2/HealthStatusFilter/items"
import { Label } from 'reactstrap'
import classes from './styles.module.scss';
const HealthStatusRow: React.FC<ReportParameterRowProps> = (props: ReportParameterRowProps) => {
    const { value } = props
    const [displayValue, setDisplayValue] = useState<string>("All")
    useEffect(() => {
        if (!value || (value && value.toLowerCase() === "all")) {
            setDisplayValue("All")
        } else {
            const valueArray = value.split(",")
            const selectedItems = sensorStatusItems.filter((status: any) => { return valueArray.includes(status.id) }).map((s) => { return s.label })
            setDisplayValue(selectedItems.join(", "))
        }

    }, [value])
    return <div className={classes.reportParameterRow}>
        <Label className={classes.label}>Health Status:</Label>
        <Label className={classes.value}>{displayValue}</Label>
    </div>
}
export default HealthStatusRow