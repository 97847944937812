import React, { useCallback, useMemo } from 'react'
import RivataDropdown from '../../../components/RivataDropdown'
import { SearchItemType } from '../../../components/ItemsSearch'
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'
import api from '../../../services/api'

type Props = {
  disabled: boolean,
  assetTypes?: Array<{ id: string, label: string }>,
  selectedAssetType?: "tractor" | "trailer"| "all",
  setSelectedAssetType?: React.Dispatch<React.SetStateAction<string>>,
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>,
  selectedItems: Array<SearchItemType>
}

const columnsToShow = [{key: "name", name: "Name"}, {key: "vin", name: "VIN"}]

const AssetsSelect: React.FC<Props> = ({
  disabled,
  assetTypes,
  selectedAssetType,
  setSelectedAssetType,
  setVinList,
  selectedItems
}) => {
  const showAssetTypeSelector = useMemo(() => {
    return assetTypes && selectedAssetType && setSelectedAssetType
  }, [assetTypes, selectedAssetType, setSelectedAssetType])

  const onAssetsSelect = useCallback((Assets: SearchItemType[]) => {
    setVinList(Assets)
  }, [setVinList])

  const endPointConfig = useMemo(() => {
    const assetType = selectedAssetType !== "all" ? selectedAssetType : undefined
    return {
      search: async (value: string) => await api.searchAssets(value, undefined, assetType),
      lablelKey: "name",
      subLabelKey: "vin",
    }
  }, [selectedAssetType])

  return (
    <>
      {showAssetTypeSelector && assetTypes && assetTypes.length > 1 && (
        <div className="d-flex mt-4 align-items-start" style={{ minWidth: "300px" }}>
          <label className="mr-2 mb-0 mt-2" style={{ minWidth: "120px" }}>Asset Type:</label>
          <RivataDropdown
            caret={true}
            items={assetTypes}
            selected={selectedAssetType}
            onSelect={setSelectedAssetType}
            buttonLabel={null}
            filters={null}
            onRemove={null}
            disabled={disabled}
            color={"secondary"}
          />
        </div>
      )}

      <ItemsSelectWithSearch 
          label="Assets"
          inputPlaceholder = "Search Assets"
          endPointConfig={endPointConfig}
          onItemsSelect={onAssetsSelect}
          columnsToShow={columnsToShow}
          disabled={disabled}
          selectedItems={selectedItems}
      />
    </>
  )
}

export default AssetsSelect