import { useMemo, useState } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { ICustomerOptions } from './types'
import { formatRole, sortByAlphabet } from '../../utils'
import api from '../../services/api'
import { NoDataLabels, UserColumnsIds } from '../../enums'

type columns =
  | 'username'
  | 'email'
  | 'roles'
  | 'name'
  | 'family_name'
  | 'phone_number'
  | 'timezone'
  | 'customers'
  | 'email_verified'
  | 'phone_number_verified'
  | 'resend_temporary_password'

type IorderedFields = Array<{
  id: columns | string
  control: 'sort' | ''
  label: string
}>

const orderedFields: IorderedFields = [
  { id: 'username', control: 'sort', label: 'Username' },
  { id: 'email', control: 'sort', label: 'Email' },
  { id: 'roles', control: 'sort', label: 'Roles' },
  { id: 'name', control: 'sort', label: 'First Name' },
  { id: 'family_name', control: 'sort', label: 'Last Name' },
  { id: 'phone_number', control: 'sort', label: 'Phone Number' },
  { id: 'timezone', control: 'sort', label: 'Time Zone' },
  { id: 'customers', control: 'sort', label: 'Customers' },
  { id: 'email_verified', control: 'sort', label: 'Email Verified' },
  {
    id: 'phone_number_verified',
    control: 'sort',
    label: 'Mobile Phone Verified',
  },
  {
    id: 'resend_temporary_password',
    control: '',
    label: 'Resend Temporary Password',
  },
]

export const resendTemporaryPassword = async (data: any) => {
  try {
    await api.postResendTomporaryPassword(data)

    return 'ok'
  } catch (err) {
    console.log(err)
  }
}

interface IuseTableRows {
  (
    data: Array<IUser>,
    setInfoModalMessage: React.Dispatch<React.SetStateAction<string>>,
  ): Array<any>
}

export const useTableRows: IuseTableRows = (data, setInfoModalMessage) => {
  const [btnColorSuccess, setBtnColorSuccess] = useState<Array<string>>([])
  const [btnColorError, setBtnColorError] = useState<Array<string>>([])

  return useMemo(() => {
    return data.map((rowData: IUser, rowIndex) => {
      return {
        id: rowIndex,
        data: rowData,
        columns: orderedFields.map(({ id }) => {
          const col: any = {
            columnId: id,
            label: rowData[id]?.toString() || NoDataLabels.DASH,
            type: 'text',
          }
          if (id === 'customers' || id === 'roles') {
            const field = rowData[id]

            if (field) {
              let names: Array<string> = []

              if (id === 'customers') {
                rowData[id].forEach((el) => names.push(el.name))
              } else if (id === 'roles') {
                rowData[id].forEach((el) => names.push(formatRole(el.name)))
              }

              col.label = names.length ? names.join(', ') : NoDataLabels.DASH
            }
          } else if (id === 'resend_temporary_password') {
            const resendHandler = async () => {
              if (!btnColorSuccess.includes(rowData.username)) {
                setBtnColorSuccess([...btnColorSuccess, rowData.username])
                setInfoModalMessage('Password reset email sent')
                const res = await resendTemporaryPassword({
                  username: rowData.username,
                })

                if (res !== 'ok') {
                  setBtnColorSuccess(
                    btnColorSuccess.filter((s) => s !== rowData.username),
                  )
                  setBtnColorError([...btnColorError, rowData.username])
                  setInfoModalMessage(
                    `Password reset for user ${rowData.username} failed`,
                  )
                }
              }
            }

            col.label = 'Send'
            col.type = 'button'
            col.showButton = !rowData.email_verified
            col.onClick = () => resendHandler()
            col.btnColor = btnColorSuccess.includes(rowData.username)
              ? 'secondary'
              : btnColorError.includes(rowData.username)
              ? 'danger'
              : 'success'
          } else if (id === 'phone_number') {
            const phoneFormated = formatPhoneNumberIntl(col.label)
            col.label = phoneFormated.length > 0 ? phoneFormated : col.label
          }

          return col
        }),
      }
    })
  }, [data, btnColorSuccess, btnColorError, setInfoModalMessage])
}

export const useColumns = (locale: ILocale) => {
  return useMemo(() => {
    return orderedFields.map(({ id, control, label }) => {
      return { label: locale[label] || label, id, control, isDraggable: true }
    })
  }, [locale])
}

const sortCustomerOptions = (options: Array<ICustomerOptions>) => {
  const idx = options.findIndex((el) => el.parent_id === null)
  let parentCustomerOption = null

  if (idx !== -1) {
    parentCustomerOption = options[idx]
    options.splice(idx, 1)
    sortByAlphabet(options, 'name')
    options = [parentCustomerOption, ...options]
  } else {
    sortByAlphabet(options, 'name')
  }

  return options
}

interface IuseCustomerOptionsCreate {
  (
    selectedCustomersList: Array<ICustomer>,
    isSuperAdmin: boolean,
    isAdmin: boolean,
    userCustomerIds: Array<number>,
    open: boolean,
    customersList: Array<ICustomer>,
  ): Array<ICustomerOptions>
}

export const useCustomerOptionsCreate: IuseCustomerOptionsCreate = (
  selectedCustomersList,
  isSuperAdmin,
  isAdmin,
  userCustomerIds,
  open,
  customersList,
) => {
  return useMemo(() => {
    if ((!userCustomerIds && !open) || selectedCustomersList.length !== 1)
      return []

    const {
      id,
      name,
      children = [],
      parent = null,
      parent_id,
    } = selectedCustomersList[0]

    const options: Array<ICustomerOptions> =
      parent === null
        ? [
            {
              id,
              name,
              disabled:
                !isSuperAdmin && !userCustomerIds.includes(id) && isAdmin,
              parent_id,
            },
          ]
        : []

    const setOption = (option: ICustomerOptions) => {
      if (options.findIndex((el) => el.id === option.id) === -1) {
        const opt = id
          ? {
              ...option,
              disabled:
                !isSuperAdmin && !userCustomerIds.includes(id) && isAdmin,
            }
          : null
        if (opt) options.push(opt)
      }
    }

    if (!isSuperAdmin && customersList) {
      customersList.forEach((customer) => {
        if (customer.id)
          setOption({
            id: customer.id,
            name: customer.name,
            parent_id: customer.parent_id,
          })
      })
    } else {
      if (children) {
        children.forEach((child) => {
          if (child && child.id) {
            setOption({
              id: child.id,
              name: child.name,
              parent_id: child.parent_id,
            })
          }
        })
      }

      if (parent && parent.id) {
        setOption({ id: parent.id, name: parent.name, parent_id: null })

        if (parent.children) {
          parent.children.forEach((child) => {
            if (child && child.id) {
              setOption({
                id: child.id,
                name: child.name,
                parent_id: child.parent_id,
              })
            }
          })
        }
      }
    }

    return sortCustomerOptions(options)
  }, [
    selectedCustomersList,
    isSuperAdmin,
    isAdmin,
    userCustomerIds,
    open,
    customersList,
  ])
}

interface IuseCustomerOptionsUpdate {
  (
    isSuperAdmin: boolean,
    isAdmin: boolean,
    selectedCustomersList: Array<ICustomer>,
    userCustomerIds: Array<number>,
    customerIds: Array<number>,
    refCustomers?: Array<ICustomerOptions>,
  ): Array<ICustomerOptions>
}

export const useCustomerOptionsUpdate: IuseCustomerOptionsUpdate = (
  isSuperAdmin,
  isAdmin,
  selectedCustomersList,
  userCustomerIds,
  customerIds,
  refCustomers,
) => {
  return useMemo(() => {
    if (!userCustomerIds || selectedCustomersList.length !== 1) return []

    const { id, name, children, parent, parent_id } = selectedCustomersList[0]
    const options: Array<ICustomerOptions> =
      parent === null
        ? [
            {
              id,
              name,
              disabled:
                !isSuperAdmin && !userCustomerIds.includes(id) && isAdmin,
              parent_id,
            },
          ]
        : []

    const setOption = (option: ICustomerOptions) => {
      if (options.findIndex((el) => el.id === option.id) === -1) {
        const opt = {
          ...option,
          disabled:
            !isSuperAdmin && !userCustomerIds.includes(option.id) && isAdmin,
        }

        options.push(opt)
      }
    }

    customerIds.forEach((customerId) => {
      if (refCustomers) {
        const customer = refCustomers.find((el) => el.id === customerId)

        if (customer)
          setOption({
            id: customerId,
            name: customer.name,
            parent_id: customer.parent_id,
          })
      }
    })

    if (children) {
      children.forEach((child) => {
        if (child && child.id) {
          setOption({
            id: child.id,
            name: child.name,
            parent_id: child.parent_id,
          })
        }
      })
    }

    if (parent && parent.id) {
      setOption({ id: parent.id, name: parent.name, parent_id: null })

      if (parent.children) {
        sortByAlphabet(parent.children, 'name')

        parent.children.forEach((child) => {
          if (child && child.id) {
            setOption({
              id: child.id,
              name: child.name,
              parent_id: child.parent_id,
            })
          }
        })
      }
    }

    // const idx = options.findIndex(el => el.parent_id === null)
    // const parentCustomerOption = options[idx]
    // options.splice(idx, 1)

    // sortByAlphabet(options, "name")

    return sortCustomerOptions(options)
  }, [
    isSuperAdmin,
    isAdmin,
    selectedCustomersList,
    userCustomerIds,
    customerIds,
    refCustomers,
  ])
}

export const useSearchUsers = (locale: ILocale) => {
  return useMemo(
    () => [
      {
        label: locale['username'],
        id: UserColumnsIds.USERNAME,
      },
      {
        label: locale['email'],
        id: UserColumnsIds.EMAIL,
      },
      {
        label: locale['First Name'],
        id: UserColumnsIds.FIRST_NAME,
      },
      {
        label: locale['Last Name'],
        id: UserColumnsIds.LAST_NAME,
      },
      {
        label: locale['Role'],
        id: UserColumnsIds.ROLE,
      },
    ],
    [locale],
  )
}
