import pinOk from '../../assets/images/icons/svg/pin_ok.svg'
import pinWarn from '../../assets/images/icons/svg/pin_warn.svg'
import pinCritWarn from '../../assets/images/icons/svg/pin_crit_warn.svg'
import pinInfo from '../../assets/images/icons/svg/pin_info.svg'
import circleOk from '../../assets/images/icons/svg/circle_ok.svg'
import circleWarn from '../../assets/images/icons/svg/circle_warn.svg'
import circleInfo from '../../assets/images/icons/svg/circle_warn.svg'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

export const H = window.H || {
  map: {
    Icon: () => {},
  },
}

const getRectangleBbox = (top, left, bottom, right, padding) => {
  const paddingTopBottom = (top - bottom) * padding
  const paddingLeftRight = (left - right) * padding
  const rect = new H.geo.Rect(
    top + paddingTopBottom,
    left + paddingLeftRight,
    bottom - paddingTopBottom,
    right - paddingLeftRight,
  )

  return rect.getBoundingBox()
}

export const bboxAll = (bbox, padding) => {
  const top = bbox.getTop()
  const bottom = bbox.getBottom()
  const right = bbox.getRight()
  const left = bbox.getLeft()

  return getRectangleBbox(top, left, bottom, right, padding)
}

export const bboxLast24 = (markers, padding) => {
  let data = markers
    .map((m) => m.getData())
    .filter((d) => d.timestamp)
    .sort((d1, d2) => +new Date(d2.timestamp) - +new Date(d1.timestamp))

  if (!data.length) {
    return null
  }

  // TODO: need the warning timestamp from the url for this.
  // const locationWarning = data.find(location => location.warningStatus) || data[0];
  const locationWarning = data[0]
  const latestTimestamp = +new Date(locationWarning.timestamp)

  const THRESHOLD_24_HRS_MS = 86400000

  data = data.filter(
    (d) => latestTimestamp - +new Date(d.timestamp) < THRESHOLD_24_HRS_MS,
  )

  const { top, left, bottom, right } = data.reduce((accum, curr) => {
    const { timestamp, latitude, longitude } = curr
    if (
      timestamp &&
      latitude &&
      longitude &&
      latestTimestamp - +new Date(timestamp) <= THRESHOLD_24_HRS_MS
    ) {
      if (!accum.top) {
        // if top is not set, there so are none of the others
        accum.top = latitude
        accum.left = longitude
        accum.bottom = latitude
        accum.right = longitude
      } else if (accum.top < latitude) {
        accum.top = latitude
      } else if (accum.left > longitude) {
        accum.left = longitude
      } else if (accum.bottom > latitude) {
        accum.bottom = latitude
      } else if (accum.right < longitude) {
        accum.right = longitude
      }
    }

    return accum
  }, {})

  return getRectangleBbox(top, left, bottom, right, padding)
}

export const pinSizeRegular = { size: { w: 24, h: 24 } }
export const pinSizeHighlighted = { size: { w: 32, h: 32 } }

export const circleSize = { size: { w: 8, h: 8 } }
export const circleWarnSize = { size: { w: 16, h: 16 } }

export const LayerTypes = {
  NORMAL: 'normal',
  SATELLITE: 'satellite',
}

export const pinMap = {
  ok: new H.map.Icon(pinOk, { ...pinSizeRegular }),
  warn: new H.map.Icon(pinWarn, { ...pinSizeRegular }),
  critWarn: new H.map.Icon(pinCritWarn, { ...pinSizeRegular }),
  info: new H.map.Icon(pinInfo, { ...pinSizeRegular }),
  okHiLite: new H.map.Icon(pinOk, { ...pinSizeHighlighted }),
  warnHiLite: new H.map.Icon(pinWarn, { ...pinSizeHighlighted }),
  infoHiLite: new H.map.Icon(pinInfo, { ...pinSizeHighlighted }),
}

export const circleMap = {
  ok: new H.map.Icon(circleOk, { ...circleSize }),
  warn: new H.map.Icon(circleWarn, { ...circleWarnSize }),
  info: new H.map.Icon(circleInfo, { ...circleSize }),
}

export const getMarkerPinMap = (markerType) =>
  markerType === 'circle' ? circleMap : pinMap

export const behaviorUi = (hMap, defaultLayers, unitsOfMeasurementConfig) => {
  // eslint-disable-next-line no-unused-vars
  const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap))
  // eslint-disable-next-line no-unused-vars
  const ui = H.ui.UI.createDefault(hMap, defaultLayers)

  ui.setUnitSystem(
    unitsOfMeasurementConfig?.distance === UnitsOfMeasurementConfig.distance.km
      ? H.ui.UnitSystem.METRIC
      : H.ui.UnitSystem.IMPERIAL,
  )

  return { behavior, ui }
}

export const getPinData = (marker, selected) => {
  const { asset_id, hasCriticalWarning, hasWarning } = marker.getData()

  let pinKey = hasCriticalWarning ? 'critWarn' : hasWarning ? 'warn' : 'ok'
  let zIndex = hasCriticalWarning ? 2 : hasWarning ? 3 : 4

  if (asset_id === selected) {
    pinKey = `${pinKey}HiLite`
    // Yes, zIndex here is bottom to top, so backwards of normal html!
    zIndex = 1
  }

  return {
    pinKey,
    zIndex,
  }
}

export const getcustomTooltipContent = (data) => {
  const labelColor = '#4c86c2'

  return `
    <div 
      class="alert-primary cluster show" 
      role="alert" 
      aria-live="assertive" 
      aria-atomic="true"" 
      style="background-color: ${labelColor};
       border-color: ${labelColor};"
    >
      <div class="label-line"></div>
      <table class="table-responsive table-light table-sm m-0 p-0">
      <tbody>
        <tr>
          <th scope="row">Geofence: </th>
          <td colspan=3>${data.name}</td>
        </tr>
        <tr>
          <th scope="row">Tags:</th>
          <td colspan=3>${data.tagsCount}</td>
        </tr>
        <tr>
        <th scope="row">Customer:</th>
        <td colspan=3>${data.customerName}</td>
        <td class="aligned-cell details"><a id="locationMapBubble">Details</a></td>
      </tr>
      </tbody>
    </table>
    </div>
  `
}

const getMousePosition = (rect, evt) => ({
  x: evt.clientX - rect.left,
  y: evt.clientY - rect.top,
})

const getLongestStringLength = (data) => {
  return data.gateway?.length || 20
}

export const getTooltipCoordinates = (
  map,
  data,
  event,
  leftRatio,
  topRatio,
  tooltipHeight = 130,
) => {
  const rect = map.getBoundingClientRect()

  const mousePosition = getMousePosition(rect, event.originalEvent)
  const longestStringLength = getLongestStringLength(data)

  const mapWidth = rect.width
  const mapHeight = rect.height
  let tooltipWidth = 11.5 * longestStringLength // Roughly calculated, but it works

  // Tooltip can't be smaller
  if (tooltipWidth < 270) tooltipWidth = 270
  const widthDiff = mapWidth - tooltipWidth
  const heightDiff = mapHeight - tooltipHeight

  let top = -20
  let left = -20

  if (mousePosition.x >= widthDiff) {
    left = -tooltipWidth + leftRatio
  }

  if (mousePosition.y >= heightDiff) {
    top = topRatio
  }

  return { top, left }
}
