import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomInput, Button } from 'reactstrap'
import ButtonV2 from '../../componentsV2/Button'

import hardwareApi from '../../services/api/ApiGroups/HardwareApi'

import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import PseudoBtn from '../../components/CustomButtons/fakeBnt'
import AssetModals from './AssetModals'

import { useTableRows, useColumns } from './hooks'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'

import { assetRequestBody } from './utils'
import { getStatusObj, makeScrollToElementWithOffset } from '../../utils/utils'
import { ColumnsIds } from '../../enums'
import { debounce, cloneDeep } from 'lodash'
import AssetGroupsFilter from '../../componentsV2/AssetGroupsFilter'
import SearchInput from '../../componentsV2/SearchInput'
import { SearchIcon } from '../../componentsV2/Icon'
import AssetNameVinFilter from '../../componentsV2/AssetNameVinFilter'

import AssetTypeFilter from '../../componentsV2/AssetTypeFilter'
import AssetsBulkUpload from './AssetModals/AssetsBulkUpload'

const defaultVisibleColumnsIds = [
  ColumnsIds.CUSTOMER_NAME,
  ColumnsIds.ASSET_NAME,
  ColumnsIds.ASSET_TYPE,
  ColumnsIds.ASSET_GROUPS,
  ColumnsIds.SUBSCRIPTION,
  ColumnsIds.MAKE,
  ColumnsIds.ATI_INSTALLED,
]

const alwaysVisibleColumnsIds = [
  ColumnsIds.ASSET_NAME,
  ColumnsIds.ASSET_TYPE,
  ColumnsIds.SUBSCRIPTION,
]

const columnsSelectorProps = {
  showDropdown: true,
  defaultVisible: defaultVisibleColumnsIds,
  alwaysVisible: alwaysVisibleColumnsIds,
  localeStorageKey: 'provision.assetsTable.columns',
}

const makeMigrationPayload = (new_customer_key, vin) => {
  return {
    customer_key: new_customer_key,
    asset_vin: vin,
  }
}

const initialSelection = {
  asset_group: [],
  asset_subtype: [],
}

const debouncer = debounce((func) => func(), 400)
const AssetsTable = ({ width, locale = {}, selectedCustomersList }) => {
  const {
    auth: { isSuperAdmin, isAdmin, whiteLabelUrl, preferences },
    assets: { loading, data, totalCount, error, pageLimit, pageOffset },
    hideAutogeneratedAssets,
  } = useTypedSelector((state) => ({
    auth: state.auth,
    assets: state.provision.assets,
    hideAutogeneratedAssets: state.common.hideAutogeneratedAssets,
  }))
  const {
    setProvisionAssetsLimit,
    setProvisionAssetsOffset,
    setProvisionAssetsSortOptions,
    setProvisionAssetsError,
    addAsset,
    updateAsset,
    deleteAsset,
    migrateAssetBetweenCustomers,
    setHideAutogeneratedAssets,
    setProvisionAssetsFilter,
    removeProvisionAssetGroupFilter,
  } = useActions()

  const scrollTarget = useRef(null)

  const columns = useColumns(locale, preferences.customer_ids, isSuperAdmin)
  const rows = useTableRows(
    data,
    columns,
    whiteLabelUrl,
    preferences.customer_ids,
    isSuperAdmin,
  )

  const [modalUpdate, setModalUpdate] = useState(null)
  const [modalDelete, setModalDelete] = useState(null)
  const [assetMigrateModalData, setAssetMigrateModalData] = useState(null)
  const [isCreateStarted, setIsCreateStarted] = useState(false)
  const [copyCustomerAssetsToEnv, setCopyCustomerAssetsToEnv] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(true)
  const [assetMakeModelSearchValue, setAssetMakeModelSearchValue] = useState('')

  const groupsFilterRef = useRef(null)
  const assetTypeFilterRef = useRef(null)
  const assetNameVinFilterRef = useRef(null)

  const searchParams = useRef(cloneDeep(initialSelection))
  const actualQuery = useRef('')

  const editText = buttonsDisabled ? 'Select Customer First' : 'Edit Asset'
  const deleteText = buttonsDisabled ? 'Select Customer First' : 'Delete Asset'
  const migrateText = buttonsDisabled
    ? 'Select Customer First'
    : 'Migrate Asset'

  useEffect(() => {
    if (selectedCustomersList.length) {
      removeProvisionAssetGroupFilter()
      if (isAdmin) {
        setProvisionAssetsFilter('skip_active_sub_check=true')
      } else {
        setProvisionAssetsOffset(0)
      }
    }
  }, [
    setProvisionAssetsOffset,
    selectedCustomersList,
    removeProvisionAssetGroupFilter,
    isAdmin,
    setProvisionAssetsFilter,
  ])

  useEffect(() => {
    setButtonsDisabled(selectedCustomersList.length !== 1)
  }, [loading, selectedCustomersList])

  const handleCreateAsset = useCallback(
    async (values) => {
      const reqBody = assetRequestBody(values, selectedCustomersList[0].id)

      const res = await addAsset(reqBody)

      if (res.ok) setIsCreateStarted(false)

      return res
    },
    [selectedCustomersList, addAsset],
  )

  const handleUpdateAsset = async (values) => {
    const reqBody = assetRequestBody(values, selectedCustomersList[0].id)
    const res = await updateAsset(reqBody)
    return res
  }

  const onEditPress = useCallback((assetData) => {
    setModalUpdate(
      // change null and undefined values to "" for input
      Object.keys(assetData).reduce((prev, curr) => {
        prev[curr] =
          assetData[curr] === null || assetData[curr] === undefined
            ? ''
            : assetData[curr]

        return prev
      }, {}),
    )
  }, [])

  const handleAssetMigration = async (values) => {
    const payload = makeMigrationPayload(
      values.new_customer,
      assetMigrateModalData.vin,
    )

    const res = await migrateAssetBetweenCustomers(payload)
    if (res.statusCode <= 201) {
      setProvisionAssetsFilter(actualQuery.current)
    }
    return res
  }

  const handleCopyCustomerAssets = async (env, vinsList) => {
    if (selectedCustomersList.length !== 1) return

    try {
      const assetJsonList = await Promise.all(
        vinsList.map((vin) => {
          return hardwareApi.getProvisionJson(vin)
        }),
      )

      await Promise.all(
        assetJsonList.map((json) => {
          return hardwareApi.postCopyProvisioningToEnviroments({
            ...env,
            provisioning_data: json,
          })
        }),
      )

      setCopyCustomerAssetsToEnv(false)
      setProvisionAssetsError({ statusCode: 200, message: 'Success!' })
    } catch (err) {
      return getStatusObj(err)
    }
  }

  const handleAssetDelete = useCallback(async () => {
    if (modalDelete && modalDelete.id) {
      await deleteAsset(modalDelete.id)
      setModalDelete(null)
    }
  }, [modalDelete, deleteAsset])

  const handlePageChange = useCallback(
    (offset) => {
      setProvisionAssetsOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setProvisionAssetsOffset],
  )

  const handleLimitChange = useCallback(
    (limit) => {
      setProvisionAssetsLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setProvisionAssetsLimit],
  )

  const updateFilters = useCallback(
    (key, value) => {
      if (!value || !value.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(value)) {
        searchParams.current[key] = value
      } else {
        searchParams.current[key] = [value]
      }

      const query = new URLSearchParams()

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0]
        filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
      })
      if (isAdmin) {
        query.append('skip_active_sub_check', true)
      }
      actualQuery.current = query.toString()
      setProvisionAssetsFilter(actualQuery.current)
    },
    [setProvisionAssetsFilter, isAdmin],
  )

  const onDropdownItemSelect = useCallback(
    (id, items) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  const onSearchByStringValue = (id, value) => {
    debouncer(() => updateFilters(id, value))
  }

  const onSearchByAssetModelMake = (e) => {
    const value = e.target.value

    setAssetMakeModelSearchValue(value)
    debouncer(() => updateFilters('asset_model_make', value))
  }

  const handleFiltersReset = () => {
    if (
      actualQuery.current === '' ||
      (isAdmin && actualQuery.current === 'skip_active_sub_check=true')
    )
      return

    setAssetMakeModelSearchValue('')

    groupsFilterRef.current.clearSelection()
    assetTypeFilterRef.current.clearSelection()
    assetNameVinFilterRef.current.clearValue()

    actualQuery.current = isAdmin ? 'skip_active_sub_check=true' : ''
    searchParams.current = cloneDeep(initialSelection)
    setProvisionAssetsFilter(actualQuery.current)
  }

  return (
    <RivataModule
      title='Assets'
      width={width}
      locale={locale}
      marginTop={0}
      filters={
        <>
          {isAdmin && (
            <>
              <CustomInput
                innerRef={scrollTarget}
                id='hideAutogenerated'
                className='d-flex align-items-center mr-3'
                type='checkbox'
                label='Hide Autogenerated Assets'
                checked={hideAutogeneratedAssets}
                onChange={() => {
                  setHideAutogeneratedAssets(!hideAutogeneratedAssets)
                  setProvisionAssetsFilter(actualQuery.current)
                }}
              />
              {isSuperAdmin &&
                ['staging', 'prod'].includes(process.env.REACT_APP_STAGE) && (
                  <Button
                    onClick={() => setCopyCustomerAssetsToEnv(true)}
                    disabled={buttonsDisabled}
                  >
                    Copy Assets To Environment
                  </Button>
                )}
              {selectedCustomersList.length === 1 && <AssetsBulkUpload />}
              <PseudoBtn
                icon='fa fa-plus-circle'
                label={'Create Asset'}
                active={selectedCustomersList.length === 1}
                onClickHandler={() => setIsCreateStarted(true)}
                message={'Select Customer First'}
              />
            </>
          )}
        </>
      }
    >
      <div className='d-flex justify-content-between flex-wrap'>
        <AssetNameVinFilter
          wrapperClassName='mb-2'
          onChange={onSearchByStringValue}
          ref={assetNameVinFilterRef}
        />
        <SearchInput
          placeholder='Search by Make or Asset Model'
          icon={<SearchIcon width={20} height={20} color='black' />}
          value={assetMakeModelSearchValue}
          onChange={onSearchByAssetModelMake}
          wrapperClassName='mb-2'
        />
      </div>
      <div className='d-flex flex-wrap justify-content-between'>
        <div className='d-flex flex-wrap'>
          <AssetTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={assetTypeFilterRef}
          />
          <AssetGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            ref={groupsFilterRef}
          />
        </div>
        <div>
          <ButtonV2
            type='reset'
            className='btn btn-clear'
            onClick={handleFiltersReset}
          >
            Clear
          </ButtonV2>
        </div>
      </div>
      <br />
      <AssetModals
        isLoading={loading}
        isCreateStarted={isCreateStarted}
        isCopyCustomerAssets={copyCustomerAssetsToEnv}
        locale={locale}
        status={error}
        assetDelete={modalDelete}
        assetUpdate={modalUpdate}
        assetMigrate={assetMigrateModalData}
        assets={data}
        selectedCustomersList={selectedCustomersList}
        setIsCreateStarted={setIsCreateStarted}
        onCreateAsset={handleCreateAsset}
        setStatus={setProvisionAssetsError}
        onAssetDelete={handleAssetDelete}
        onAssetDeleteCancel={setModalDelete}
        onAssetUpdate={handleUpdateAsset}
        onAssetUpdateCancel={setModalUpdate}
        onAssetMigrate={handleAssetMigration}
        onAssetMigrateCancel={setAssetMigrateModalData}
        onAssetCopy={handleCopyCustomerAssets}
        onAssetCopyCancel={setCopyCustomerAssetsToEnv}
      />

      <RivataTable
        columns={columns}
        rows={rows}
        totalCount={totalCount}
        page={pageOffset / pageLimit}
        showPagination={true}
        isShowingLimit={true}
        isLoading={loading}
        setSortOptions={setProvisionAssetsSortOptions}
        onPageChange={handlePageChange}
        onSelectLimit={handleLimitChange}
        pageLimit={pageLimit}
        editDisabled={buttonsDisabled}
        deleteDisabled={buttonsDisabled}
        onEdit={onEditPress}
        onDelete={
          isAdmin || isSuperAdmin ? (data) => setModalDelete(data) : null
        }
        onCustomAction={
          isAdmin || isSuperAdmin
            ? (data) => {
              setAssetMigrateModalData(data)
            }
            : null
        }
        customActionIcon={'fas fa-arrows-alt-h'}
        customActionTooltipText={migrateText}
        editTooltipText={editText}
        deleteTooltipText={deleteText}
        // filter={filter}
        columnsSelectorProps={columnsSelectorProps}
      />
    </RivataModule>
  )
}

export default AssetsTable
