import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form
} from "reactstrap";

const ConfirmModal = (props) => {
  const {
    disabled,
    btnClassName,
    btnLabel,
    header,
    children,
    onClose, // passing this means it is controlled
    open, // if controlled required
    locale = {},
    modalButtons,
  } = props;
  // modalButtons
  // [
  //   { id: 1, label: "Save Changes", color: "success", onClick:() => {}, disabled?: true },
  //   { id: 2, label: "Discard Changes", color: "primary", onClick:() => {}, disabled?: false },
  // ]

  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (open && onClose) onClose();
    else setModal(!modal);
  };

  return (
    <>
      {!onClose && (
        <Button
          disabled={disabled}
          size="md"
          className={btnClassName}
          onClick={toggle}
        >
          {btnLabel}
        </Button>
      )}
      <Modal isOpen={(modal || open) && !disabled} unmountOnClose={false}>
        <Form>
          {header && <ModalHeader toggle={toggle}>{header}</ModalHeader>}
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            {modalButtons ? modalButtons.map((btn) => {
              return (
                <Button 
                  key={btn.id}
                  className="mr-3" 
                  color={btn.color}
                  onClick={btn.onClick}
                  disabled={btn.disabled}
                >
                  {btn.label}
                </Button>
              )
            }) : null}
            <Button color="danger" onClick={toggle}>
              {locale["cancel"] || "Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ConfirmModal;
