import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Label,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  UncontrolledTooltip,
} from 'reactstrap'
import ReactJSONEditor from '../ReactJSONEditor'
import { hexToCSSFilter } from 'hex-to-css-filter'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { HealthStatus, NoDataLabels } from '../../enums'

export const Checkbox = ({ children, disabled, onCheck, data, checked }) => (
  <Label check>
    <Input
      type='checkbox'
      disabled={disabled}
      checked={checked}
      onChange={(evt) => onCheck(data, evt.target.checked)}
    />
    {children}
  </Label>
)

const Icon = ({
  src,
  variant,
  label,
  title,
  assetId,
  showIconTooltip,
  tooltipContent,
  count,
}) => {
  const healthColors = useTypedSelector(
    (state) => state.whitelabel.healthColors,
  )

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const filter = useMemo(() => {
    let filterObj = ''

    switch (variant) {
      case HealthStatus.good:
        filterObj = hexToCSSFilter(
          healthColors.find((el) => el.id === 'HealthGood').color,
        )
        break

      case HealthStatus.warning:
        filterObj = hexToCSSFilter(
          healthColors.find((el) => el.id === 'HealthWarning').color,
        )
        break

      case HealthStatus.criticalWarning:
        filterObj = hexToCSSFilter(
          healthColors.find((el) => el.id === 'HealthCriticalWarning').color,
        )
        break

      case HealthStatus.noStatus:
        filterObj = hexToCSSFilter('#6D6E71')
        break

      default:
        return ''
    }

    return `brightness(0) saturate(100%) ${filterObj.filter.replace(';', '')}`
  }, [variant, healthColors])

  const tooltipClassName = useMemo(() => {
    switch (variant) {
      case HealthStatus.good:
        return 'tooltip-ok'
      case HealthStatus.warning:
        return 'tooltip-warning'
      case HealthStatus.criticalWarning:
        return 'tooltip-critical-warning'
      case HealthStatus.noStatus:
        return 'tooltip-no-status'
      default:
        return ''
    }
  }, [variant])

  return (
    <>
      <div className='icon-container'>
        {count ? <div className='count-badge'>{count}</div> : ''}
        <img
          id={`icon-${label}-${assetId}`}
          style={{ filter: filter }}
          src={src}
          alt={label}
          title={title}
          width={30}
          height={30}
        />
      </div>
      {showIconTooltip && (
        <Tooltip
          innerClassName={`status-tooltip ${tooltipClassName}`}
          hideArrow={true}
          placement='auto'
          isOpen={tooltipOpen}
          target={`icon-${label}-${assetId}`}
          toggle={toggle}
        >
          {tooltipContent}
        </Tooltip>
      )}
    </>
  )
}

const LabelWithBreak = ({ label }) => {
  const splittedLabel = label.split('<br/>')
  return (
    <Label className='text-center'>
      {splittedLabel[0].trim()} <br /> {splittedLabel[1].trim()}
    </Label>
  )
}

const Buttons = ({ buttons }) =>
  buttons.map((button, idx) => (
    <div key={idx}>
      <Button
        disabled={button.disabled}
        style={button.style}
        onClick={button.onClick}
      >
        {button.label}
      </Button>
      <br />
    </div>
  ))

const TooltipLink = ({ link, label, tooltip, assetId }) => {
  const [tooltipShown, setTooltipShown] = useState(false)
  const toggle = () => {
    setTooltipShown(!tooltipShown)
  }

  const link_id = tooltip
    ? `link-${String(label).replace(' ', '')}-${assetId}`
    : null

  return (
    <>
      {tooltip && (
        <Tooltip
          innerClassName={`status-tooltip`}
          hideArrow={true}
          placement='auto'
          isOpen={tooltipShown}
          target={link_id}
          toggle={toggle}
          trigger='hover'
        >
          {tooltip}
        </Tooltip>
      )}

      {label ? (
        <Link id={link_id} to={link}>
          {label}
        </Link>
      ) : (
        <div id={link_id} className='fake_link_div'></div>
      )}
    </>
  )
}

const TableRow = ({
  id,
  selectedRow,
  columns,
  data,
  onEdit,
  onDelete,
  onSelectedRow,
  onCheckRowItem,
  editDisabled,
  deleteDisabled,
  tpmsProfiles,
  daysCount,
  onCustomAction,
  customActionIcon,
  customActionTooltipText,
  editTooltipText,
  deleteTooltipText,
  onRowClick,
  isCustomActionHidden,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)
  const [editTooltipOpen, setEditTooltipOpen] = useState(false)
  const [customTooltipOpen, setCustomTooltipOpen] = useState(false)
  const toggleDeleteTooltip = () => {
    setDeleteTooltipOpen(!deleteTooltipOpen)
  }
  const toggleEditTooltip = () => {
    setEditTooltipOpen(!editTooltipOpen)
  }
  const toggleCustomTooltip = () => {
    setCustomTooltipOpen(!customTooltipOpen)
  }
  const deleteIconButtonId = `delete_button_${id}`
  const editIconButtonId = `edit_button_${id}`
  const customIconButtonId = `custom_button_${id}`

  const disableEditColor = editDisabled ? '#84897e' : '#000'
  const disableDeleteColor =
    deleteDisabled && customActionIcon === 'fas fa-arrows-alt-h'
      ? '#84897e'
      : '#000'
  const disableMoveColor =
    (editDisabled || !data) && customActionIcon === 'fas fa-arrows-alt-h'
      ? '#84897e'
      : '#000'

  return (
    <tr
      id={'asset-' + id}
      className='table-row'
      style={{
        backgroundColor: selectedRow ? 'rgba(0, 0, 0, 0.075)' : 'white',
      }}
      onMouseEnter={() => onSelectedRow(id)}
      onMouseLeave={() => onSelectedRow(null)}
    >
      {onDelete && (
        <td key={`delete_row_${id}`} id={deleteIconButtonId}>
          <Button
            className='pl-2 pr-1'
            close
            disabled={deleteDisabled || !data}
            onClick={() => onDelete(data)}
            style={{ color: disableDeleteColor }}
          />

          {deleteTooltipText && (
            <Tooltip
              hideArrow={true}
              placement='auto'
              isOpen={deleteTooltipOpen}
              target={deleteIconButtonId}
              toggle={toggleDeleteTooltip}
              trigger='hover'
            >
              {deleteTooltipText}
            </Tooltip>
          )}
        </td>
      )}
      {onEdit && (
        <td key={`edit_row_${id}`} id={editIconButtonId}>
          <Button
            className='pl-1 pr-2'
            close
            disabled={editDisabled || !data}
            onClick={() => onEdit(data)}
          >
            <i
              className='fa fa-edit font-16'
              style={{ color: disableEditColor }}
            />
          </Button>

          {editTooltipText && (
            <Tooltip
              hideArrow={true}
              placement='auto'
              isOpen={editTooltipOpen}
              target={editIconButtonId}
              toggle={toggleEditTooltip}
              trigger='hover'
            >
              {editTooltipText}
            </Tooltip>
          )}
        </td>
      )}
      {onCustomAction && (
        <td key={`custom_action_${id}`} id={customIconButtonId}>
          {!isCustomActionHidden ? (
            <>
              <Button
                className='pl-1 pr-2'
                close
                disabled={editDisabled || !data}
                onClick={() => onCustomAction(data)}
              >
                <i
                  className={customActionIcon}
                  style={{ color: disableMoveColor }}
                />
              </Button>

              {customActionTooltipText && (
                <Tooltip
                  hideArrow={true}
                  placement='auto'
                  isOpen={customTooltipOpen}
                  target={customIconButtonId}
                  toggle={toggleCustomTooltip}
                  trigger='hover'
                >
                  {customActionTooltipText}
                </Tooltip>
              )}
            </>
          ) : null}
        </td>
      )}

      {columns.map((itemData) => {
        const {
          label,
          src,
          title,
          link,
          classNames,
          columnId,
          assetId,
          type,
          control,
          controlEnabled,
          checked,
          selected,
          onSelectProfile,
          error,
          onClick,
          onCheck,
          addBreak,
          buttons,
          width,
          max_width,
          min_width,
          showIconTooltip = false,
          showButton = true,
          btnColor = null,
          tooltipContents = [],
          tooltip = null,
          counts = [],
          showIconButton = false,
          iconButtonToolTipText = null,
          iconButtonIcon = '',
        } = itemData
        let Wrapper
        let wrapperProps
        if (control === 'checkbox') {
          Wrapper = Checkbox
          wrapperProps = {
            onCheck:
              typeof onCheckRowItem === 'function' ? onCheckRowItem : onCheck,
            checked,
            data: itemData,
            disabled: controlEnabled === false,
          }
        } else {
          Wrapper = React.Fragment
          wrapperProps = {}
        }

        return (
          <td
            key={`${assetId}_${columnId}`}
            className={type === 'button' ? 'td_button' : ''}
            style={{
              width:
                (['json', 'iconbutton'].includes(type) && width) ||
                (control === 'checkbox' && width),
              maxWidth: max_width,
              minWidth: min_width,
              height: '100%',
            }}
            onClick={() => {
              if (!onRowClick) return

              if (typeof onRowClick === 'function') onRowClick(data)
            }}
          >
            <Wrapper {...wrapperProps}>
              {error ? (
                <i className='fas fa-exclamation-circle text-danger'></i>
              ) : type === 'link' ? (
                <TooltipLink
                  label={label}
                  link={link}
                  tooltip={tooltip}
                  id={id}
                  assetId={assetId}
                />
              ) : type === 'icon' ? (
                src ? (
                  <div className='d-flex'>
                    {src.map((src, i) => (
                      <Icon
                        key={label[i] + assetId}
                        src={src}
                        variant={classNames[i]}
                        label={label[i]}
                        daysCount={daysCount}
                        assetId={assetId}
                        showIconTooltip={showIconTooltip}
                        tooltipContent={tooltipContents[i]}
                        count={counts[i]}
                        title={title && title[i] ? title[i] : ''}
                      />
                    ))}
                  </div>
                ) : (
                  ''
                )
              ) : type === 'dropdown' ? (
                <Dropdown
                  setActiveFromChild={true}
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                >
                  <DropdownToggle
                    color='#525f7f'
                    className='custom-rivata-dropdown'
                    caret
                  >
                    {selected}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {tpmsProfiles.map((el) => {
                      return (
                        <DropdownItem
                          key={el.id}
                          onClick={() => onSelectProfile(assetId, el.label)}
                        >
                          {el.label}
                        </DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              ) : type === 'button' ? (
                <>
                  {showButton ? (
                    <Button
                      color={btnColor ? btnColor : 'secondary'}
                      onClick={onClick}
                    >
                      {label}
                    </Button>
                  ) : (
                    NoDataLabels.DASH
                  )}
                </>
              ) : type === 'buttons' ? (
                <Buttons buttons={buttons} />
              ) : type === 'json' ? (
                <ReactJSONEditor
                  content={label ? { json: label } : { text: '' }}
                  readOnly
                />
              ) : type === 'textarea' ? (
                <textarea value={label} readOnly style={{ width: width }} />
              ) : addBreak ? (
                <LabelWithBreak label={label} />
              ) : type === 'iconbutton' ? (
                <>
                  {showIconButton ? (
                    <div className='rivata-table-icon-button-wrapper'>
                      <Button
                        id={`icon_button_${id}_${columnId}`}
                        key={`icon_button_${id}_${columnId}`}
                        onClick={() => onClick(data)}
                        close
                        className='rivata-table-icon-button'
                      >
                        {label}
                        <i
                          className={iconButtonIcon}
                          style={{ color: disableMoveColor }}
                        />
                      </Button>
                      {iconButtonToolTipText && (
                        <UncontrolledTooltip
                          hideArrow={true}
                          placement='auto'
                          target={`icon_button_${id}_${columnId}`}
                          trigger='hover'
                        >
                          {iconButtonToolTipText}
                        </UncontrolledTooltip>
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                label
              )}
            </Wrapper>
          </td>
        )
      })}
    </tr>
  )
}

export const tableRowType = {
  id: PropTypes.number.isRequired,
  onSelectedRow: PropTypes.func,
  onCheckRowItem: PropTypes.func,
  tpmsProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        'text',
        'icon',
        'link',
        'dropdown',
        'button',
        'json',
        'textarea',
        'buttons',
        'iconbutton',
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      link: PropTypes.string,
      src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      control: PropTypes.oneOf(['checkbox']),
      controlEnabled: PropTypes.bool,
    }),
  ).isRequired,
}

TableRow.propTypes = tableRowType

TableRow.defaultProps = {
  onCheck: () => {},
  onSelectedRow: () => {},
}

export default TableRow
