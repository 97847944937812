import React, { useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import authService from "../../services/auth"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import logger from "../../utils/logger"

type Props = {
  failedTokenRefresh: {
    failed: boolean
    expired: boolean
    error: string
  }
}

const FailedTokenRefreshModal = ({ failedTokenRefresh }: Props) => {
  const userName = useTypedSelector((state) => state.auth.user.userName)

  useEffect(() => {
    if (!failedTokenRefresh.failed) return

    logger.error(`failed_token_refresh: {userName: ${userName}, error: ${failedTokenRefresh.error}}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedTokenRefresh])

  return (
    <Modal isOpen={failedTokenRefresh.failed} centered>
      <ModalHeader>
        {failedTokenRefresh.expired
          ? "You will be logged out"
          : "Failed Token Refresh"}
      </ModalHeader>

      <ModalBody>
        {failedTokenRefresh.expired
          ? "You have been logged in for 30 days. Please login again."
          : failedTokenRefresh.error.includes("Network error")
          ? "Network error. Check internet connection."
          : "Something went wrong. Please reload page or logout."}
      </ModalBody>

      <ModalFooter>
        {!failedTokenRefresh.expired && (
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        )}

        {!failedTokenRefresh.error.includes("Network error") && (
          <Button onClick={() => authService.signOut()}>Logout</Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default React.memo(FailedTokenRefreshModal)
