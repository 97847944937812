import React, { ChangeEvent, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap'
import InfoModal from '../../../components/InfoModal'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import api from '../../../services/api'
import { UnitsOfMeasurement } from '../../../enums'
import { Typeahead } from 'react-bootstrap-typeahead'
import TimezonePicker from '../../../components/TimezonePicker'
import { getStatusObj } from '../../../utils/utils'

const defaultUploadStatus = {
  success: false,
  message: '',
}

const UploadOdometerButton = () => {
  const { selectedCustomersList, preferences } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
    preferences: state.auth.preferences,
  }))

  const [modalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const [uploadStatus, setUploadStatus] = useState(defaultUploadStatus)
  const [selectedUnitsOfMeasurement, setSelectedUnitsOfMeasurement] = useState({
    id: UnitsOfMeasurement.imperial,
    label: 'Imperial',
  })
  const [timezone, setTimezone] = useState(preferences.timezone)
  const toggleModalVisability = () => {
    setModalVisible(!modalVisible)
    setUploadStatus(defaultUploadStatus)
    setInvalidDataMessage(null)
    setIsLoading(false)
  }

  const [invalidDataMessage, setInvalidDataMessage] = useState<string | null>(
    null,
  )

  const onFileChange = (event: ChangeEvent) => {
    if (event.target) {
      const target = event.target as HTMLInputElement
      if (target) {
        const file = target.files?.[0]
        setFile(file)
      }
    }
  }

  const onUpload = async () => {
    if (!file) return

    setInvalidDataMessage(null)

    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = async function () {
      const result = reader.result?.toString().split('base64,')[1]
      if (result) {
        setIsLoading(true)
        try {
          const response: any = await api.uploadOdometerCsv(
            result,
            selectedUnitsOfMeasurement.id,
            timezone,
            selectedCustomersList[0].id,
          )

          setIsLoading(false)

          if (
            response.no_access_assets?.length ||
            response.calibration_data_errors?.length ||
            response.datetime_format_errors?.length ||
            response.odometer_data_errors?.length
          ) {
            let message = ''
            if (response.no_access_assets?.length) {
              message = `No access to entered assets: ${response.no_access_assets.join(
                ', ',
              )}. Please check data.`
            } else if (response.calibration_data_errors?.length)
              message = `Calibration date should not be earlier than initial gateway installation date for assets: ${response.calibration_data_errors.join(
                ', ',
              )}.`
            else if (response.datetime_format_errors?.length) {
              message = `Datetime format error at rows: '${response.datetime_format_errors.join(
                ', ',
              )}'.`
            } else if (response.odometer_data_errors?.length) {
              message = `Wrong odometer data at rows: ${response.odometer_data_errors.join(
                ', ',
              )}. Please check odometer validity.`
            } else {
              message =
                'Exception: Something went wrong, please validate data in csv file.'
            }
            setInvalidDataMessage(message)
          } else {
            setUploadStatus({
              success: true,
              message: 'Odometer uploaded successfully.',
            })

            setModalVisible(false)
          }
        } catch (err) {
          setIsLoading(false)

          setInvalidDataMessage(getStatusObj(err).message)
        }
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const downloadExample = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    let csvData = [
      [
        'VIN',
        'Datetime (In format DD/MM/YYYY HH:MM AM/PM)',
        'Odometer',
        '<- Note: This row is for reference and should be removed!',
      ],
      ['VIN12345', '01/04/2024 02:27 PM', '350000'],
      ['VIN23456', '01/04/2024 02:27 PM', '12000'],
      ['VIN34567', '01/04/2024 02:27 AM', '80195'],
    ]

    csvData.forEach(function (rowArray) {
      let row = rowArray.join(',')
      csvContent += row + '\r\n'
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.querySelector('#odometer-example-link') as HTMLElement

    if (!link) {
      link = document.createElement('a')
      link.style.display = 'none'
      link.id = 'odometer-example-link'
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'OdometerExample.csv')
      document.body.appendChild(link)
    }

    link.click()
  }

  return (
    <>
      <Button
        variant='primary'
        className='mr-2'
        onClick={toggleModalVisability}
        disabled={selectedCustomersList.length !== 1}
      >
        Upload Odometer Csv
      </Button>
      <Modal size='lg' centered isOpen={modalVisible}>
        <ModalHeader toggle={toggleModalVisability}>
          Upload Odometer Csv
        </ModalHeader>
        <ModalBody>
          {!uploadStatus.success && uploadStatus.message && (
            <>
              <span className='upload_csv_error'>{uploadStatus.message}</span>
            </>
          )}

          {invalidDataMessage && (
            <div className='upload_csv_error'>{invalidDataMessage}</div>
          )}

          <div className='upload_csv'>
            <div>
              <span>Upload odometer csv file</span>
              <input type='file' onChange={onFileChange} accept='text/csv' />
            </div>

            <div className='mt-3'>
              <span>Select odometer Units Of Measurement</span>
              <span style={{ display: 'inline-grid' }}>
                <Typeahead
                  id={`rivata-typeahead-odometer`}
                  labelKey='label'
                  onChange={(selections: any) => {
                    if (selections.length) {
                      setSelectedUnitsOfMeasurement(selections[0])
                    }
                  }}
                  options={[
                    {
                      id: 'metric',
                      label: 'Metric',
                    },
                    {
                      id: 'imperial',
                      label: 'Imperial',
                    },
                  ]}
                  placeholder={'Select'}
                  selected={[selectedUnitsOfMeasurement]}
                  multiple={false}
                  filterBy={() => true}
                />
              </span>
              <div className='mt-3'>
                <span>Select timezone</span>
                <span style={{ display: 'inline-grid' }}>
                  <TimezonePicker
                    onSetTimezone={(tz) => {
                      if (tz) {
                        setTimezone(tz)
                      }
                    }}
                    initialValue={timezone}
                    id='timezone_picker'
                  />
                </span>
              </div>
            </div>

            <div>
              <div>
                <Button
                  size='md'
                  onClick={downloadExample}
                  className={'download-sample'}
                >
                  Download File Example
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              onUpload()
            }}
            disabled={!file || isLoading}
          >
            {isLoading ? <Spinner size='sm' color='light' /> : 'Ok'}
          </Button>

          <Button
            color='danger'
            onClick={toggleModalVisability}
            disabled={isLoading}
          >
            {'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <InfoModal
        open={uploadStatus.success}
        message={uploadStatus.message}
        header='CSV Upload'
        onConfirm={() => {
          setUploadStatus(defaultUploadStatus)
        }}
      />
    </>
  )
}

export default UploadOdometerButton
