import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const InfoModal = (props) => {
    const { onConfirm, locale = {}, open, header, message } = props;

    return (
        <div>
            <Modal isOpen={open}>
                <ModalHeader toggle={onConfirm}>
                    {header}
                </ModalHeader>
                <ModalBody>
                    {message.split('\n').map((m, i) => {
                        return <span key={i}>
                            {m}
                            <br />
                        </span>
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={onConfirm}
                    >
                        {locale["ok"] || "Ok"}
                    </Button>{" "}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default InfoModal;
