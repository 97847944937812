import React, { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import { debounce } from "lodash";
import SearchInput from "../SearchInput";
import { SearchIcon } from "../Icon";

interface Props {
  wrapperStyle?: CSSProperties;
  updateFilters: (key: string, value: Array<string> | string) => void;
}

const debouncer = debounce((func: () => void) => func(), 400);

const SensorIdFilter = forwardRef(({ wrapperStyle, updateFilters }: Props, ref) => {
  const [macSearchValue, setMacSearchValue] = useState("");
  const onSearchByMac = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setMacSearchValue(value);
    debouncer(() => updateFilters("mac", value));
  };

  useImperativeHandle(ref, () => ({
    clearValue() {
      setMacSearchValue("");
    }
}))

  return (
    <SearchInput
      placeholder="Search by Sensor ID"
      icon={<SearchIcon width={20} height={20} color="black" />}
      wrapperStyle={wrapperStyle}
      value={macSearchValue}
      onChange={onSearchByMac}
    />
  );
});

export default React.memo(SensorIdFilter);
