import React, { useState } from "react"

import DeleteModal from "../../../components/DeleteModal"


interface Props {
    locale: ILocale
    profile: INotificationProfile | null
    onClose: (isClosedBySuccess?: boolean) => void
    onDelete: (id: number) => any
}

const DeleteNotificationProfileModal: React.FC<Props> = ({
    locale, 
    profile,
    onClose,
    onDelete
}) => {
    const [error, setError] = useState<string | null>(null)

    if (!profile) return null

    return (
        <DeleteModal
            locale={locale}
            header={"Delete Notification Profile"}
            open={true}
            error={error}
            deleteBtnColor={"danger"}
            onCancel={() => onClose(false)}
            onDelete={async () => {
                if (!profile.id) return;
                
                const res: any = await onDelete(profile.id)
                
                if (res.ok) onClose(true)
                else setError(res.message)
            }}
        >
            <div>
                <h6>{`Are you sure you want to delete profile ${profile.name}?`}</h6>
            </div>
        </DeleteModal>
    )
}

export default DeleteNotificationProfileModal
