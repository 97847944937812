import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import CustomerRow from '../ReportParametersRows/CustomerRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'
import DaysRangeRow from '../ReportParametersRows/DaysRangeRow'
import UsersRow from '../ReportParametersRows/UsersRow'

const InternalFailedNotificationHistoryReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <CustomerRow value={props.parameters.customer_keys} />
      <TimeZoneRow value={props.parameters.timezone} />
      <DaysRangeRow value={props.parameters.days} />
      <UsersRow value={props.parameters.usernames} />
    </>
  )
}
export default InternalFailedNotificationHistoryReportParameters
