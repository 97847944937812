import baseApi from './BaseApi'

class ReportsHistoryApi {

  getReportsHistory = (reportStatus: Array<string>, reportType: Array<string>, dateFrom: number, dateTo: number) => {
    const filter = new URLSearchParams();
    if (reportStatus && reportStatus.length > 0)
      filter.append("report_status", reportStatus.join(","))
    if (reportType && reportType.length > 0)
      filter.append("report_type", reportType.join(","))
    if (typeof dateFrom === "number")
      filter.append("epoch_from", dateFrom.toString())
    if (typeof dateTo === "number")
      filter.append("epoch_to", dateTo.toString())
    const queryString = filter.toString()
    return baseApi.get(`rivata/report/history?${queryString}`)
  }
  getReportsHistoryResult = (history_id: number) => {
    return baseApi.get(`rivata/report/history/result?history_id=${history_id}`)
  }
}
const reportsHistoryApi = new ReportsHistoryApi()

export default reportsHistoryApi
