import {
    SET_GEOLOCATIONS,
    SET_GEOLOCATIONS_TAGS_GEOFENCE_DATA,
    SET_GEOLOCATIONS_LOADING,
    SET_GEOLOCATIONS_ORDER,
    SET_GEOLOCATIONS_OFFSET,
    SET_GEOLOCATIONS_LIMIT,
    SET_GEOLOCATIONS_TOTAL_COUNT,
    SET_GEOLOCATIONS_FILTER,
    SET_GEOLOCATIONS_ASSETS_WITH_CARGOTAGS,
    SET_SELECTED_LOCATION_OR_ASSET,
    SET_GEOLOCATIONS_DROPDOWN,
    SET_GEOLOCATIONS_DATA_STATUS,
    SET_GEOLOCATIONS_GEOFENCES_STATUS,
    SET_GEOLOCATIONS_ASSETS_STATUS,
    SET_GEOLOCATIONS_SELECTED_STATUS
} from "../actionTypes";
import { store } from "../store";
import api from "../../services/api";
import { convertDataEpochToDate } from "../../utils";
import { getStatusObj } from "../../utils/utils"

const setGeolocations = (payload) => ({
    type: SET_GEOLOCATIONS,
    payload
});

const setTotalCount = (count) => ({
    type: SET_GEOLOCATIONS_TOTAL_COUNT,
    payload: count
})

const setGeolocationsTagsGeofences = (payload) => ({
    type: SET_GEOLOCATIONS_TAGS_GEOFENCE_DATA,
    payload,
})

const setGeolocationsLoading = (loading) => ({
    type: SET_GEOLOCATIONS_LOADING,
    payload: loading
})

export const setLocationsOffset = (offset) => ({
    type: SET_GEOLOCATIONS_OFFSET,
    payload: offset
})

export const setLocationsLimit = (limit) => ({
    type: SET_GEOLOCATIONS_LIMIT,
    payload: limit
})

export const setLocationsOrder = (order) => ({
    type: SET_GEOLOCATIONS_ORDER,
    payload: order
})

export const setTagsLocationsFilter = (payload) => ({
    type: SET_GEOLOCATIONS_FILTER,
    payload,
})

export const setSelectedPlace = (payload) => ({
    type: SET_SELECTED_LOCATION_OR_ASSET,
    payload
})

const setGeolocationsDrop = (payload) => ({
    type: SET_GEOLOCATIONS_DROPDOWN,
    payload,
})


export function fetchTagsLocations() {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        const { limit, offset, filter, sortOptions } = getState().tagLocations;
        return api
            .getTagsLocations(limit, offset, filter, sortOptions)
            .then((res) => {
                dispatch(setGeolocations(res.paged_data));
                dispatch(setTotalCount(res.total_count))
                dispatch({ type: SET_GEOLOCATIONS_DATA_STATUS, payload: getStatusObj() })
            })
            .catch((err) => {
                dispatch({ type: SET_GEOLOCATIONS_DATA_STATUS, payload: getStatusObj(err) })
            })
            .finally(() => {
                dispatch(setGeolocationsLoading(false));
            });
    };
}

export function fetchTagsLocationForDropdown() {
    return function (dispatch, getState) {
        const { filter, sortOptions } = getState().tagLocations;

        return api
            .getTagsLocations(1000, 0, filter, sortOptions)
            .then((res) => {
                const rowResult = res.paged_data.map((el) => ({ id: el.id, label: el.name }))
                const resultWithAll = [{ id: 'all', label: 'All' }, ...rowResult].sort((a, b) => a.label.localeCompare(b.label))
                dispatch(setGeolocationsDrop(resultWithAll));
            })
            .catch((err) => {
                console.log(err);
            })
    }
}

export function fetchAssetsWithCargoGps(assetId) {
    return async function (dispatch) {
        try {
            dispatch(setGeolocationsLoading(true));

            const res = await api.getAssetsWithCargotags(assetId)

            if (assetId) {
                res.type = "ASSET"

                res.gateways = res.gateways.map(el => {
                    el.date = el.epoch ? convertDataEpochToDate(el.epoch, null, null, true) : null
                    return el
                })

                dispatch({ type: SET_SELECTED_LOCATION_OR_ASSET, payload: res })
                dispatch({ type: SET_GEOLOCATIONS_SELECTED_STATUS, payload: getStatusObj() })
            }
            else {
                // test gps data
                // res[0].latitude = 39.387265
                // res[0].longitude = -82.963947
                dispatch({ type: SET_GEOLOCATIONS_ASSETS_WITH_CARGOTAGS, payload: res })
                dispatch({ type: SET_GEOLOCATIONS_ASSETS_STATUS, payload: getStatusObj() })
            }

        } catch (err) {
            assetId
                ? dispatch({ type: SET_GEOLOCATIONS_SELECTED_STATUS, payload: getStatusObj(err) })
                : dispatch({ type: SET_GEOLOCATIONS_ASSETS_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch(setGeolocationsLoading(false));
        }
    }
}

export function fetchLocationsWithCargoGps(locationId) {
    return async function (dispatch) {
        try {
            dispatch(setGeolocationsLoading(true));

            const res = await api.getLocationsWithCargotags(locationId)

            if (locationId) {
                res.type = "TAG_LOCATION"
                res.gateways = res.gateways_details?.map(el => ({ esn: el.gateway, date: el.epoch ? convertDataEpochToDate(el.epoch, null, null, true) : null })) || []
                dispatch({ type: SET_SELECTED_LOCATION_OR_ASSET, payload: res })
                dispatch({ type: SET_GEOLOCATIONS_SELECTED_STATUS, payload: getStatusObj() })
            }

            else {
                dispatch(setGeolocationsTagsGeofences(res))
                dispatch({ type: SET_GEOLOCATIONS_GEOFENCES_STATUS, payload: getStatusObj() })
            }
        } catch (err) {
            locationId
                ? dispatch({ type: SET_GEOLOCATIONS_SELECTED_STATUS, payload: getStatusObj(err) })
                : dispatch({ type: SET_GEOLOCATIONS_GEOFENCES_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch(setGeolocationsLoading(false));
        }
    }
}


store.subscribe(() => {
    const lastAction = store.getState().lastAction;

    if (
        lastAction.type === SET_GEOLOCATIONS_LIMIT ||
        lastAction.type === SET_GEOLOCATIONS_ORDER ||
        lastAction.type === SET_GEOLOCATIONS_FILTER
    ) {
        store.dispatch(setLocationsOffset(0))
    }

    if (lastAction.type === SET_GEOLOCATIONS_OFFSET) {
        store.dispatch(fetchTagsLocations())
    }
})