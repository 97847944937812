import React, { useState } from 'react'
import amstedIndGray from '../../assets/images/footer/LOGO_AmstedIndustries_RGB_GRAY.svg'
import ConfirmModal from '../ConfirmModal'

import './style.scss'
import { DEFAULT_LEGAL_TEXT } from '../../constants/constants'

interface Props {
  legalText?: string | undefined
}
const Footer: React.FC<Props> = ({ legalText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <footer className='footer text-center'>
      <ConfirmModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {legalText || DEFAULT_LEGAL_TEXT}
      </ConfirmModal>
      <div className='m-2 p-2 legal-text'>
        <p onClick={() => setIsModalOpen(!isModalOpen)}>LEGAL</p>
      </div>
      <div className='m-2 p-2'>
        © Copyright Consolidated Metco, Inc. All rights reserved. ConMet is a
        registered trademark of
        <a href='https://conmet.com/'> Consolidated Metco, Inc</a>.
        <div className='d-flex justify-content-end'>
          <img src={amstedIndGray} className='mt-3' height='40px' alt='logo' />
        </div>
      </div>
    </footer>
  )
}

export default Footer
