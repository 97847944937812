import { createBrowserHistory } from 'history';
import ReactGA from "react-ga4";

export const history = createBrowserHistory();

history.listen(location => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});

    if (!location.state?.scroll) return

    window.scrollTo(0, 0)
})