import {
  LOADING_CUSTOMERS,
  SET_CUSTOMERS,
  SET_SELECTED_CUSTOMERS_LIST,
  SET_CUSTOMERS_ERROR,
  SET_SYSTEM_DEFAULTS,
  SET_CUSTOMER_DEFAULTS,
  SET_VERSION_INFO,
  LOADING_DEFAULTS,
  SET_HIDE_AUTOGENERATED_FILTER,
} from '../actionTypes'

const selectedCustomersCache = localStorage.getItem('selectedCustomersCache')

const initialState = {
  customers: {
    data: [],
    selectedCustomersList: selectedCustomersCache
      ? JSON.parse(selectedCustomersCache)
      : [],
    error: {
      statusCode: 0,
      message: '',
    },
    loading: false,
  },
  isDefaultsLoading: false,
  systemDefaults: {},
  customerDefaults: {},
  versionInfo: {},
  hideAutogeneratedAssets: true,
}

const setItem = (state, key, payload) => ({ ...state, [key]: payload })
const setField = (state, property, field, payload) => ({
  ...state,
  [property]: { ...state[property], [field]: payload },
})

const common = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return setField(state, 'customers', 'loading', action.payload)
    case SET_CUSTOMERS:
      return setField(state, 'customers', 'data', action.payload)
    case SET_SELECTED_CUSTOMERS_LIST:
      return setField(
        state,
        'customers',
        'selectedCustomersList',
        action.payload,
      )
    case SET_CUSTOMERS_ERROR:
      return setField(state, 'customers', 'error', action.payload)

    case SET_SYSTEM_DEFAULTS:
      return setItem(state, 'systemDefaults', action.payload)
    case SET_CUSTOMER_DEFAULTS:
      return setItem(state, 'customerDefaults', action.payload)
    case LOADING_DEFAULTS:
      return setItem(state, 'isDefaultsLoading', action.payload)

    case SET_VERSION_INFO:
      return setItem(state, 'versionInfo', action.payload)

    case SET_HIDE_AUTOGENERATED_FILTER:
      return setItem(state, 'hideAutogeneratedAssets', action.payload)

    default:
      return state
  }
}

export default common
