import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import RivataTable from '../../components/RivataTable'
import RivataModule from '../../components/RivataModule'
import { useTableColumns, useTableRows } from './hooks'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import SearchInput from '../../componentsV2/SearchInput'
import { SearchIcon } from '../../componentsV2/Icon'
import { cloneDeep, debounce } from 'lodash'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import Button from '../../componentsV2/Button'
import RivataToggle from '../../components/RivataToggle'
import { makeScrollToY } from '../../utils/utils'
import ConfirmModal from '../../components/ConfirmModal'
import api from '../../services/api'
import InfoModal from '../../components/InfoModal'
import AssetGroupsFilter from '../../componentsV2/AssetGroupsFilter'
import { CustomInput, Row } from 'reactstrap'
import _ from 'lodash'
import { AssetGroupDetailsColumnIds, Path } from '../../enums'
import { useQuery } from '../../hooks/useQuery'
import AssetTypeFilter from '../../componentsV2/AssetTypeFilter'
import SensorTypeFilter from '../../componentsV2/SensorTypeFilter'
import AssetNameVinFilter from '../../componentsV2/AssetNameVinFilter'
import RouteLeavingGuard from '../../components/BlockLeavingModal'

const initialSelection = {
  asset_subtype: [],
  sensor_type: [],
  asset_groups: [],
}

const debouncer = debounce((func: () => void) => func(), 400)

const tabs = {
  assigned: 'assigned',
  unassigned: 'unassigned',
}

const defaultVisibleColumnsIds = [
  AssetGroupDetailsColumnIds.MAKE,
  AssetGroupDetailsColumnIds.MODEL,
  AssetGroupDetailsColumnIds.SUBSCRIPTIONS,
]

const alwaysVisibleColumnsIds = [
  AssetGroupDetailsColumnIds.ALL,
  AssetGroupDetailsColumnIds.ASSET_NAME,
  AssetGroupDetailsColumnIds.ASSET_TYPE,
  AssetGroupDetailsColumnIds.ASSET_GROUPS,
]

const columnsSelectorProps = {
  showDropdown: true,
  defaultVisible: defaultVisibleColumnsIds,
  alwaysVisible: alwaysVisibleColumnsIds,
  localeStorageKey: 'assetGroups.detailsTable.columns',
}

const AssetGroupsDetailsModule = ({ group_id }: any) => {
  const {
    auth: { whiteLabelUrl },
    assetGroupsDetails: { isLoading, data, limit, offset },
    locale,
    selectedCustomersList,
  } = useTypedSelector((state) => ({
    auth: state.auth,
    assetGroupsDetails: state.assetGroups.assetGroupsDetails,
    locale: state.whitelabel.locale,
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))

  const params = useQuery()

  const [assetMakeModelSearchValue, setAssetMakeModelSearchValue] = useState('')
  const [assetYearSearchValue, setAssetYearSearchValue] = useState('')
  const groupsFilterRef = useRef<any>(null)
  const assetTypeFilterRef = useRef<any>(null)
  const sensorTypeFilterRef = useRef<any>(null)
  const assetNameVinFilterRef = useRef<any>(null)
  const [showUngroupedOnly, setShowUngroupedOnly] = useState(false)

  // Sorting
  const [sortOrder, setSortOrder] = useState({
    column: 'name',
    direction: 'asc',
  })

  // Table
  const [selectedOption, setSelectedOption] = useState(
    params.get('tab') === 'unassigned'
      ? { id: tabs.unassigned }
      : { id: tabs.assigned },
  )
  const [selectedRows, setSelectedRows] = useState<Array<number>>([])
  const [selectAll, setSelectAll] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [statusMessage, setStatusMessage] = useState('')

  const searchParams = useRef<Record<string, Array<string>>>(
    cloneDeep(initialSelection),
  )

  const [filters, setFilters] = useState('')

  const actualQuery = useRef('')

  const {
    setAssetGroupsDetailsLimit,
    setAssetsGroupsDetailsOffset,
    getAssetGroupById,
  } = useActions()

  useEffect(() => {
    setAssetsGroupsDetailsOffset(0)
  }, [setAssetsGroupsDetailsOffset, selectedOption])

  useEffect(() => {
    getAssetGroupById(
      group_id,
      limit,
      offset,
      showUngroupedOnly,
      sortOrder,
      filters,
    )
    makeScrollToY(0)
    setSelectedRows([])
  }, [
    setAssetsGroupsDetailsOffset,
    getAssetGroupById,
    group_id,
    limit,
    offset,
    sortOrder,
    filters,
    showUngroupedOnly,
  ])

  useEffect(() => {
    setShowUngroupedOnly(false)
  }, [selectedOption, setShowUngroupedOnly])

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value

      if (!tempValue || !tempValue.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(tempValue)) {
        searchParams.current[key] = tempValue
      } else {
        searchParams.current[key] = [tempValue]
      }

      const query = new URLSearchParams()

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0]
        filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
      })

      actualQuery.current = query.toString()
      setFilters(query.toString())
      setSelectAll(false)
    },
    [],
  )

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value))
  }

  const onSearchByAssetModelMake = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setAssetMakeModelSearchValue(value)
    debouncer(() => updateFilters('asset_model_make', value))
  }

  const onSearchByAssetYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isInteger(Number(e.target.value))) {
      const value = e.target.value

      setAssetYearSearchValue(value)
      debouncer(() => updateFilters('asset_year', value))
    }
  }

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  const handleFiltersReset = () => {
    setSelectAll(false)
    setSelectedRows([])
    setShowUngroupedOnly(false)
    if (actualQuery.current === '') return

    setAssetMakeModelSearchValue('')
    setAssetYearSearchValue('')

    actualQuery.current = ''
    setFilters('')
    searchParams.current = cloneDeep(initialSelection)
    setShowUngroupedOnly(false)
    groupsFilterRef?.current?.clearSelection()
    sensorTypeFilterRef?.current?.clearSelection()
    assetTypeFilterRef?.current?.clearSelection()
    assetNameVinFilterRef?.current?.clearValue()
  }

  const onPageChange = useCallback(
    (offset: number) => {
      setSelectAll(false)
      setAssetsGroupsDetailsOffset(offset)
      makeScrollToY(0)
    },
    [setAssetsGroupsDetailsOffset],
  )

  const onCheckRowColumn = (id: string, checked: boolean) => {
    setSelectAll(checked)

    const rows =
      selectedOption.id === tabs.assigned
        ? data.assigned_assets.paged_data
        : data.unassigned_assets.paged_data
    const selected = rows.map((el) => el.id)

    setSelectedRows(selected)
    if (!checked) {
      setSelectedRows([])
    }
  }

  const onCheckRowItem = (item: { id: number }) => {
    const { id } = item
    const rows =
      selectedOption.id === tabs.assigned
        ? data.assigned_assets.paged_data
        : data.unassigned_assets.paged_data

    if (selectedRows.includes(id)) {
      const updatedSelected = selectedRows.filter((el) => el !== id)
      setSelectedRows(updatedSelected)
    } else if (selectAll) {
      const selected = rows.map((el) => el.id)
      setSelectedRows(selected)
    } else {
      let array = [...selectedRows, id]
      setSelectedRows(array)
    }
    setSelectAll(false)
  }

  const columns = useTableColumns(selectAll)
  const rows = useTableRows(
    selectedOption.id === tabs.assigned
      ? data?.assigned_assets
      : data?.unassigned_assets,
    selectedRows,
    selectAll,
    whiteLabelUrl,
  )

  const showModalHandler = () => {
    setShowModal(selectedRows.length > 0)
  }

  const saveChangesHandler = () => {
    const action = selectedOption.id === tabs.assigned ? 'unassign' : 'assign'

    api
      .manageAssetGroupAssets(group_id, selectedRows, action)
      .then(() => {
        setSelectAll(false)
        setSelectedRows([])
        setAssetsGroupsDetailsOffset(0)
        setShowModal(false)
        getAssetGroupById(
          group_id,
          limit,
          offset,
          showUngroupedOnly,
          sortOrder,
          actualQuery.current,
        )
      })
      .catch((err) => {
        setStatusMessage(err ? err.message : '')
      })
  }

  const handleSaveCancel = () => {
    setSelectedRows([])
    setSelectAll(false)
  }

  const handleLimitChange = useCallback(
    (limit: number) => {
      setSelectAll(false)
      setAssetsGroupsDetailsOffset(0)
      setAssetGroupsDetailsLimit(limit)
      makeScrollToY(0)
    },
    [setAssetGroupsDetailsLimit, setAssetsGroupsDetailsOffset],
  )

  return (
    <>
      <Row>
        <RivataModule
          title={data?.name}
          locale={locale}
          marginTop={0}
          error={undefined}
          filters={
            <RivataToggle
              item1={{
                id: 'assigned',
                label: `Show assets in group`,
                isDisabled:
                  selectedRows.length > 0 &&
                  selectedOption.id === tabs.unassigned,
              }}
              item2={{
                id: 'unassigned',
                label: `Show available assets`,
                isDisabled:
                  selectedRows.length > 0 &&
                  selectedOption.id === tabs.assigned,
              }}
              selectedId={selectedOption.id}
              onToggle={(e) => {
                if (selectedRows.length === 0) {
                  setSelectedOption({ id: e.id.toString() })
                }
              }}
            />
          }
        >
          <div className='d-flex justify-content-between flex-wrap mb-2'>
            <AssetNameVinFilter
              wrapperClassName='mb-2'
              onChange={onSearchByStringValue}
              ref={assetNameVinFilterRef}
            />
            <SearchInput
              placeholder='Search by Make or Asset Model'
              icon={<SearchIcon width={20} height={20} color='black' />}
              value={assetMakeModelSearchValue}
              onChange={onSearchByAssetModelMake}
              wrapperClassName='mb-2'
            />
            <SearchInput
              placeholder='Search by Asset year'
              icon={<SearchIcon width={20} height={20} color='black' />}
              value={assetYearSearchValue}
              onChange={onSearchByAssetYear}
              wrapperClassName='mb-2'
            />
          </div>

          <div className='d-flex flex-wrap justify-content-between'>
            <div className='d-flex flex-wrap'>
              <AssetTypeFilter
                onDropdownItemSelect={onDropdownItemSelect}
                ref={assetTypeFilterRef}
              />

              <SensorTypeFilter
                onDropdownItemSelect={onDropdownItemSelect}
                ref={sensorTypeFilterRef}
              />

              <AssetGroupsFilter
                onDropdownItemSelect={onDropdownItemSelect}
                ref={groupsFilterRef}
                customerIds={selectedCustomersList
                  .map((c: any) => c.id)
                  .join(',')}
                hideNoGroupOption={true}
                disabled={showUngroupedOnly}
              />

              {selectedOption.id === tabs.unassigned && (
                <CustomInput
                  type='switch'
                  id='showOnlyUngrouped'
                  label='Only show ungrouped assets'
                  checked={showUngroupedOnly}
                  onChange={(e: {
                    target: {
                      checked: boolean | ((prevState: boolean) => boolean)
                    }
                  }) => {
                    setShowUngroupedOnly(e.target.checked)
                  }}
                  className='d-flex ml-2 align-items-center'
                />
              )}
            </div>
            <div>
              <Button
                type='reset'
                className='btn btn-clear'
                onClick={handleFiltersReset}
              >
                Clear
              </Button>
            </div>
          </div>
          <hr />
          <>
            <RivataTable
              columns={columns}
              rows={rows}
              onDelete={undefined}
              isLoading={isLoading}
              onEdit={undefined}
              editDisabled={undefined}
              deleteDisabled={false}
              setSortOptions={setSortOrder}
              onCustomAction={undefined}
              onCheckRowColumn={onCheckRowColumn}
              onCheckRowItem={onCheckRowItem}
              totalCount={
                selectedOption.id === tabs.assigned
                  ? data?.assigned_assets?.total_count
                  : data?.unassigned_assets?.total_count
              }
              showPagination={true}
              // @ts-ignore component expect undefined cause of default value
              onSelectLimit={handleLimitChange}
              pageLimit={limit}
              isShowingLimit={true}
              page={offset / limit}
              // @ts-ignore component expect undefined cause of default value
              onPageChange={onPageChange}
              customNoDataText={
                selectedOption.id === tabs.assigned
                  ? 'There are currently no assets in this group. Select available assets tab to add assets to the group.'
                  : ''
              }
              // @ts-ignore component expect undefined cause of default value
              columnsSelectorProps={columnsSelectorProps}
            >
              {/* @ts-ignore component expect undefined cause of default value */}
              <div className='d-flex flex-wrap justify-content-end align-items-center mt-3 mb-4'>
                <p className='mb-0 mr-2'>{selectedRows.length + ' selected'}</p>
                <Button
                  type='button'
                  className='btn btn-clear'
                  onClick={handleSaveCancel}
                >
                  Cancel
                </Button>

                <Button
                  type='button'
                  className='btn btn-clear'
                  onClick={showModalHandler}
                >
                  {selectedOption.id !== tabs.assigned
                    ? 'Add selected'
                    : 'Remove selected'}
                </Button>
              </div>
            </RivataTable>
          </>

          <hr />

          <Link to={Path.AssetGroups}>
            <Button type='button' className='btn btn-clear m-0'>
              Return To The Groups List
            </Button>
          </Link>
        </RivataModule>
      </Row>
      <ConfirmModal
        open={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        modalButtons={[
          {
            id: 1,
            label: 'Save Changes',
            color: 'success',
            onClick: saveChangesHandler,
          },
        ]}
      >
        <div>
          <h5>Confirm Changes</h5>
          <p>
            {selectedOption.id !== tabs.assigned ? 'Add' : 'Remove'}{' '}
            {selectedRows.length} asset(s){' '}
            {selectedOption.id !== tabs.assigned ? 'to' : 'from'} group?
          </p>
        </div>
      </ConfirmModal>
      <InfoModal
        header={'Saving info'}
        message={statusMessage}
        open={!!statusMessage}
        onConfirm={() => {
          setStatusMessage('')
        }}
      />

      <RouteLeavingGuard when={!!selectedRows.length} />
    </>
  )
}

export default AssetGroupsDetailsModule
