import React, { useCallback, useEffect, useMemo, useState } from 'react'

import api from '../../../services/api'

import RivataModule from '../../../components/RivataModule'
import OdometerTable from './OdometerTable'
import OdometerForm from './OdometerForm'
import RivataLoader from '../../../components/RivataLoader'
import BackendSearchInput from '../../../components/BackendSearchInput'

import { AssetTypes, UnitsOfMeasurement } from '../../../enums'
import {
  convertDataEpochToDate,
  kilometersToMeters,
  milesToMeters,
} from '../../../utils'
import { getStatusObj } from '../../../utils/utils'
import InfoModal from '../../../components/InfoModal'
import UploadOdometerButton from './UploadOdometerButton'

interface Props {
  locale: ILocale
  isSuperAdmin: boolean
  timezone: string
  selectedCustomersList: Array<ICustomer>
  selectedUnitsOfMeasurement: string
  setSelectedUnitsOfMeasurement: React.Dispatch<React.SetStateAction<string>>
}

const CalibrateOdometer: React.FC<Props> = ({
  locale,
  isSuperAdmin,
  timezone,
  selectedCustomersList,
  selectedUnitsOfMeasurement,
  setSelectedUnitsOfMeasurement,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<IStatus | null>(null)
  const [selectedAsset, setSelectedAsset] =
    useState<AssetShortFormatType | null>(null)

  useEffect(() => {
    console.log('Selected units of measurement: ', selectedUnitsOfMeasurement)
  }, [selectedUnitsOfMeasurement])

  useEffect(() => {
    setSelectedAsset(null)
  }, [selectedCustomersList])

  const handleOdometerFormSubmit = useCallback(
    async (data: any, formikHelpers: any, startDate: any) => {
      try {
        if (!selectedAsset || !startDate) return

        setIsLoading(true)

        const createOdometerDto = {
          asset_id: selectedAsset.id,
          odometer_value:
            selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial
              ? milesToMeters(data.odometer)
              : kilometersToMeters(data.odometer),
          epoch: startDate,
          source: 'rivata',
        }

        createOdometerDto.odometer_value = Math.floor(
          createOdometerDto.odometer_value,
        )

        const res = await api.postAssetOdometerCalibration(createOdometerDto)

        if (res?.status === 'ok') {
          formikHelpers.resetForm()
          setStatus(getStatusObj({}, 'Odometer value successfully added'))
          setSelectedAsset((prev) => (prev ? { ...prev } : null)) // will trigger fetch data
        } else if (res.status === 'error') {
          setStatus(
            getStatusObj(
              {},
              `${res.message}, ${convertDataEpochToDate(
                res.epoch,
                null,
                null,
                true,
              )}`,
            ),
          )
        }

        return res
      } catch (err) {
        setStatus(getStatusObj(err))
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    },
    [selectedAsset, selectedUnitsOfMeasurement],
  )

  const handleOdometerRecordDelete = useCallback(async (data: any) => {
    try {
      if (!data.id) return

      setIsLoading(true)

      const res = await api.deleteAssetOdometerCalibration(data.id)

      if (res) {
        setStatus(
          getStatusObj({}, 'Odometer calibration record successfully deleted'),
        )
      }

      setSelectedAsset((prev) => (prev ? { ...prev } : null)) // will trigger fetch data
    } catch (err) {
      setStatus(getStatusObj(err))
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onAssetSelect = useCallback((asset: any) => {
    setSelectedAsset(asset as AssetShortFormatType)
  }, [])

  const endPointConfig = useMemo(() => {
    const assetType = isSuperAdmin
      ? undefined
      : (AssetTypes.TRAILER as AssetTypes)

    return {
      search: async (value: string) =>
        await api.searchAssets(
          value,
          undefined,
          assetType,
          'subscription',
          'gateway',
        ),
      lablelKey: 'name',
      subLabelKey: 'vin',
    }
  }, [isSuperAdmin])

  return (
    <RivataModule
      title='Calibrate Asset Odometer'
      locale={locale}
      marginTop={0}
      filters={
        <>
          <div className='d-flex align-items-center mr-2'>
            {selectedAsset && (
              <>
                <span>
                  <span
                    className='mr-1 font-14'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedAsset(null)}
                  >
                    <b>&#10005;</b>
                  </span>
                  {selectedAsset.name}(<b>{selectedAsset.vin}</b>)
                </span>
              </>
            )}
          </div>

          <UploadOdometerButton />

          <BackendSearchInput
            endPointConfig={endPointConfig}
            placeholder='Select Asset'
            onSelect={onAssetSelect}
            disabled={false}
          />
        </>
      }
    >
      {isLoading && <RivataLoader />}

      {status && (
        <InfoModal
          open={true}
          locale={locale}
          header='Asset Odometer Calibration'
          message={status.message}
          onConfirm={() => setStatus(null)}
        />
      )}

      <OdometerForm
        selectedAsset={selectedAsset}
        timezone={timezone}
        selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
        setSelectedUnitsOfMeasurement={setSelectedUnitsOfMeasurement}
        handleOdometerFormSubmit={handleOdometerFormSubmit}
      />

      <hr />

      <OdometerTable
        selectedAsset={selectedAsset}
        selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
        onDelete={handleOdometerRecordDelete}
      />
    </RivataModule>
  )
}

export default React.memo(CalibrateOdometer)
