export const DatesEnum = {
  ONE_DAY: 1,
  SEVEN_DAYS: 7,
  THIRTY_DAYS: 30,
  NINETY_DAYS: 90,
  SIX_MONTHS: 180,
}

export enum ReportTypes {
  USER_LOGINS_REPORT = 'USER_LOGINS_REPORT',
  HARDWARE_COMMANDS_HISTORY_REPORT = 'HARDWARE_COMMANDS_HISTORY_REPORT',
  TPMS_REPORT = 'TPMS_REPORT',
  DEVICE_HEALTH_REPORT = 'DEVICE_HEALTH_REPORT',
  DAILY_MILEAGE_REPORT = 'DAILY_MILEAGE_REPORT',
  DISTANCE_TRAVELED_REPORT = 'DISTANCE_TRAVELED_REPORT',
  GEOFENCE_HISTORY_REPORT = 'GEOFENCE_HISTORY_REPORT',
  ACTIVE_WARNINGS_REPORT = 'ACTIVE_WARNINGS_REPORT',
  ASSET_PROVISIONING_SUMMARY_REPORT = 'ASSET_PROVISIONING_SUMMARY_REPORT',
  WARNING_HISTORY_REPORT = 'WARNING_HISTORY_REPORT',
  NOTIFICATION_HISTORY_REPORT = 'NOTIFICATION_HISTORY_REPORT',
  ASSETS_IN_GEOFENCE_REPORT = 'ASSETS_IN_GEOFENCE_REPORT',
  DWELL_REPORT = 'DWELL_REPORT',
  ODOMETER_OFFSET_SUMMARY_REPORT = 'ODOMETER_OFFSET_SUMMARY_REPORT',
  FAILED_NOTIFICATION_HISTORY_REPORT = 'FAILED_NOTIFICATION_HISTORY_REPORT',
  DRIVER_APP_TRIP_REPORT = 'DRIVER_APP_TRIP_REPORT',
  UTILIZATION_REPORT = 'UTILIZATION_REPORT',
  NON_REPORTING_SENSORS_REPORT = 'NON_REPORTING_SENSORS_REPORT',
  DURATION_IN_WARNING_STATE_REPORT = 'DURATION_IN_WARNING_STATE_REPORT',
  MILES_IN_WARNING_STATE_REPORT = 'MILES_IN_WARNING_STATE_REPORT',
  SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT = 'SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT',
  NOTIFICATIONS_SUBSCRIPTIONS_REPORT = 'NOTIFICATIONS_SUBSCRIPTIONS_REPORT',
  VEHICLE_LOCATION_REPORT = 'VEHICLE_LOCATION_REPORT',
}
export enum TagReportTypes {
  LATEST_TAG_LOCATION_REPORT = 'LATEST_TAG_LOCATION_REPORT',
  LOST_TAGS_REPORT = 'LOST_TAGS_REPORT',
  UNASSIGNED_CARGO_TAGS_REPORT = 'UNASSIGNED_CARGO_TAGS_REPORT',
  TAG_HISTORY_DETAILS_REPORT = 'TAG_HISTORY_DETAILS_REPORT',
  CARGO_TAG_GATEWAY_REPORT = 'CARGO_TAG_GATEWAY_REPORT',
}
export const ReportEndpoints = {
  USER_LOGINS_REPORT: 'user_logins_report',
  HARDWARE_COMMANDS_HISTORY_REPORT: 'hardware_commands_history_report',
  TPMS_REPORT: 'tpms_report',
  DEVICE_HEALTH_REPORT: 'device_health_report',
  DAILY_MILEAGE_REPORT: 'daily_mileage_report',
  DISTANCE_TRAVELED_REPORT: 'distance_traveled_report',
  GEOFENCE_HISTORY_REPORT: 'geofence_history_report',
  LATEST_TAG_LOCATION_REPORT: 'cargo_tags_report',
  LOST_TAGS_REPORT: 'lost_tags_report',
  UNASSIGNED_CARGO_TAGS_REPORT: 'unassigned_cargo_tags_report',
  TAG_HISTORY_DETAILS_REPORT: 'tag_history_details_report',
  ACTIVE_WARNINGS_REPORT: 'active_warnings_report',
  ASSET_PROVISIONING_SUMMARY_REPORT: 'asset_provisioning_summary_report',
  WARNING_HISTORY_REPORT: 'warning_history_report',
  NOTIFICATION_HISTORY_REPORT: 'notification_history_report',
  ASSETS_IN_GEOFENCE_REPORT: 'assets_in_geofence_report',
  DWELL_REPORT: 'dwell_report',
  CARGO_TAG_GATEWAY_REPORT: 'cargo_tags_gateway_report',
  ODOMETER_OFFSET_SUMMARY_REPORT: 'odometer_offset_summary_report',
  FAILED_NOTIFICATION_HISTORY_REPORT: 'failed_notification_history_report',
  DRIVER_APP_TRIP_REPORT: 'driver_app_trip_report',
  UTILIZATION_REPORT: 'utilization_report',
  NON_REPORTING_SENSORS_REPORT: 'non_reporting_sensors_report',
  DURATION_IN_WARNING_STATE_REPORT: 'duration_in_warning_state_report',
  MILES_IN_WARNING_STATE_REPORT: 'miles_in_warning_state_report',
  SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT:
    'scheduled_reports_subscriptions_report',
  NOTIFICATIONS_SUBSCRIPTIONS_REPORT: 'notifications_subscriptions_report',
  VEHICLE_LOCATION_REPORT: 'vehicle_location_report',
}

export const ColumnsIds = {
  ACKNOWLEDGE: 'ackowledge',
  STATUS: 'status',
  VIN: 'vin',
  CUSTOMER_NAME: 'customer_name',
  ASSET_NAME: 'asset_name',
  LAST_GPS_UPDATE: 'last_gps_update',
  SUBSCRIPTION: 'subscription',
  SENSOR_INFO: 'sensor_info',
  SENSOR_ID: 'mac',
  ASSET_TYPE: 'asset_type',
  GEOFENCE_ID: 'geofence_id',
  TYPE: 'type',
  MAKE: 'make',
  GEOFENCES: 'geofences',
  LAST_TPMS_UPDATE: 'last_tpms_update',
  ODOMETER: 'odometer',
  YEAR: 'year',
  MODEL: 'model',
  ATI_INSTALLED: 'ati_installed',
  ASSET_GROUPS: 'asset_groups',
  ASSET_SUBTYPE: 'asset_subtype',
  GEOFENCE_GROUPS: 'geofence_groups',
  FAVORITE: 'favorite',
}

export const UserColumnsIds = {
  USERNAME: 'username',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ROLE: 'role',
}

export const TpmsColumnsIds = {
  ALL: 'all',
  ASSET_NAME: 'asset_name',
  VIN: 'vin',
  CUSTOMER: 'customer',
  ASSET_TYPE: 'asset_type',
  TPMS_PROFILE: 'warning_setting_name',
  WARNINGS_SUBTYPE: 'warnings_subtype',
  ASSET_GROUPS: 'asset_groups',
  ASSET_SUBTYPE: 'asset_subtype',
}

export const SensorColumnsIds = {
  COMMAND: 'command',
  SENSOR_ID: 'sensor_id',
  RSSI: 'rssi',
  CONFIG: 'config_crc',
  FIRMWARE: 'fw_Version',
  HARDWARE: 'hw_Version',
  VOLTAGE: 'battery_voltage',
  PACKETS: 'num_rx_packets',
  FAILURES: 'num_tx_failuers',
  GATEWAY_UID: 'gateway_uid',
}

export const AssetGroupDetailsColumnIds = {
  ALL: 'all',
  ASSET_NAME: 'name',
  ASSET_TYPE: 'asset_type',
  MAKE: 'make',
  MODEL: 'model',
  ASSET_GROUPS: 'groups',
  SUBSCRIPTIONS: 'subscriptions',
}

export const SensorCommandsIds = {
  TOGGLE_VERBOSE_LOGGING: 'debug',
  REQUEST_GATEWAY_LOG: 'gateway_status',
  REQUEST_SENSOR_LOG: 'sensor_status',
  UPDATE_DEVICE: 'ota',
  RESET_DEVICE: 'reboot',
}

export const SortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const UserAttributes = {
  ADDRESS: 'address',
  BIRTHDATE: 'birthdate',
  EMAIL: 'email',
  FAMILY_NAME: 'family_name',
  GENDER: 'gender',
  GIVEN_NAME: 'given_name',
  LOCALE: 'locale',
  MIDDLE_NAME: 'middle_name',
  NAME: 'name',
  NICKNAME: 'nickname',
  PHONE_NUMBER: 'phone_number',
  PICTURE: 'picture',
  PREFERRED_USERNAME: 'preferred_username',
  PROFILE: 'profile',
  UPDATED_AT: 'updated_at',
  WEBSITE: 'website',
  ZONEINFO: 'zoneinfo',
  NOTIF_EMAIL: 'custom:notification_email',
  NOTIF_TEXT: 'custom:notification_text',
  NOTIF_THRESHOLD: 'custom:notification_level',
  UNITS_OF_MEASUREMENT: 'custom:units_of_measurement',
}

export const Timezones = {
  PACIFIC_HONOLULU: 'Pacific/Honolulu',
  PACIFIC_JUNEAU: 'America/Juneau',
  AMERICA_LOS_ANGELES: 'America/Los_Angeles',
  AMERICA_DENVER: 'America/Denver',
  AMERICA_PHOENIX: 'America/Phoenix',
  AMERICA_CHICAGO: 'America/Chicago',
  AMERICA_NEW_YORK: 'America/New_York',
  CANADA_ATLANTIC: 'Canada/Atlantic',
  CANADA_CENTRAL: 'Canada/Central',
  CANADA_EASTERN: 'Canada/Eastern',
  CANADA_MOUNTAIN: 'Canada/Mountain',
  CANADA_NEWFOUNDLAND: 'Canada/Newfoundland',
  CANADA_PACIFIC: 'Canada/Pacific',
  CANADA_SASKATCHEWAN: 'Canada/Saskatchewan',
  CANADA_YUKON: 'Canada/Yukon',
  UTC: 'UTC',
  AUSTRALIA_MELBOURNE: 'Australia/Melbourne',
}

export const RolePermission = {
  ADMIN: 'ADMIN',
  DRIVER: 'DRIVER',
  INSTALLER: 'INSTALLER',
  INSTALLER_ADMIN: 'INSTALLER_ADMIN',
  STANDARD: 'STANDARD',
}

export const Languages = {
  EN: 'en',
  FR: 'fr',
  GR: 'gr',
}

export const NotificationThresholds = {
  INFORMATIONAL_AND_CRITICAL: 'INFORMATIONAL_AND_CRITICAL',
  CRITICAL_ONLY: 'CRITICAL_ONLY',
}

export const AssetTypes = {
  TRACTOR: 'tractor',
  TRAILER: 'trailer',
}

export const WheelOrder: { [key: string]: number } = {
  NONE: 9900,
  UNKNOWN: 9800,
  UNASSIGN: 9700,

  STEER_LEFT: 0,
  STEER_RIGHT: 10,

  DRIVE_LEFT_1: 20,
  DRIVE_RIGHT_1: 30,
  DRIVE_LEFT_2: 40,
  DRIVE_RIGHT_2: 50,
  DRIVE_LEFT_3: 60,
  DRIVE_RIGHT_3: 70,
  DRIVE_LEFT_4: 80,
  DRIVE_RIGHT_4: 90,
  DRIVE_LEFT_5: 100,
  DRIVE_RIGHT_5: 110,
  DRIVE_LEFT_6: 120,
  DRIVE_RIGHT_6: 130,

  DRIVE_FRONT_LEFT: 140,
  DRIVE_FRONT_RIGHT: 150,

  DRIVE_REAR_LEFT: 160,
  DRIVE_REAR_RIGHT: 170,

  DRIVE_LEFT_1_OUTER: 180,
  DRIVE_LEFT_1_INNER: 190,
  DRIVE_RIGHT_1_OUTER: 200,
  DRIVE_RIGHT_1_INNER: 210,

  DRIVE_LEFT_2_OUTER: 220,
  DRIVE_LEFT_2_INNER: 230,
  DRIVE_RIGHT_2_OUTER: 240,
  DRIVE_RIGHT_2_INNER: 250,

  DRIVE_LEFT_3_OUTER: 260,
  DRIVE_LEFT_3_INNER: 270,
  DRIVE_RIGHT_3_OUTER: 280,
  DRIVE_RIGHT_3_INNER: 290,

  DRIVE_LEFT_4_OUTER: 300,
  DRIVE_LEFT_4_INNER: 310,
  DRIVE_RIGHT_4_OUTER: 320,
  DRIVE_RIGHT_4_INNER: 330,

  DRIVE_LEFT_5_OUTER: 340,
  DRIVE_LEFT_5_INNER: 350,
  DRIVE_RIGHT_5_OUTER: 360,
  DRIVE_RIGHT_5_INNER: 370,

  DRIVE_LEFT_6_OUTER: 380,
  DRIVE_LEFT_6_INNER: 390,
  DRIVE_RIGHT_6_OUTER: 400,
  DRIVE_RIGHT_6_INNER: 410,

  TRAILER_LEFT_1: 420,
  TRAILER_RIGHT_1: 430,
  TRAILER_LEFT_2: 440,
  TRAILER_RIGHT_2: 450,
  TRAILER_LEFT_3: 460,
  TRAILER_RIGHT_3: 470,
  TRAILER_LEFT_4: 480,
  TRAILER_RIGHT_4: 490,
  TRAILER_LEFT_5: 500,
  TRAILER_RIGHT_5: 510,
  TRAILER_LEFT_6: 520,
  TRAILER_RIGHT_6: 530,

  TRAILER_LEFT_1_OUTER: 540,
  TRAILER_LEFT_1_INNER: 550,
  TRAILER_RIGHT_1_OUTER: 560,
  TRAILER_RIGHT_1_INNER: 570,

  TRAILER_LEFT_2_OUTER: 580,
  TRAILER_LEFT_2_INNER: 590,
  TRAILER_RIGHT_2_OUTER: 600,
  TRAILER_RIGHT_2_INNER: 610,

  TRAILER_LEFT_3_OUTER: 620,
  TRAILER_LEFT_3_INNER: 630,
  TRAILER_RIGHT_3_OUTER: 640,
  TRAILER_RIGHT_3_INNER: 650,

  TRAILER_LEFT_4_OUTER: 660,
  TRAILER_LEFT_4_INNER: 670,
  TRAILER_RIGHT_4_OUTER: 680,
  TRAILER_RIGHT_4_INNER: 690,

  TRAILER_LEFT_5_OUTER: 700,
  TRAILER_LEFT_5_INNER: 710,
  TRAILER_RIGHT_5_OUTER: 720,
  TRAILER_RIGHT_5_INNER: 730,

  TRAILER_LEFT_6_OUTER: 740,
  TRAILER_LEFT_6_INNER: 750,
  TRAILER_RIGHT_6_OUTER: 760,
  TRAILER_RIGHT_6_INNER: 770,

  TRAILER_LEFT_7_OUTER: 780,
  TRAILER_LEFT_7_INNER: 790,
  TRAILER_RIGHT_7_OUTER: 800,
  TRAILER_RIGHT_7_INNER: 810,
}

export const WhiteLabelFilenames = {
  smarthubHealthSvg: 'smarthub_health.svg',
  tpmsHealthSvg: 'tpms_health.svg',
  linePressureHealthSvg: 'line_pressure_health.svg',
  axleLoadHealthSvg: 'axle_load_health.svg',
  gatewayHealthSvg: 'gateway_health.svg',
  logoIconPng: 'logo_icon.png',
  logoFullPng: 'logo_full.png',
  authPageImagePng: 'auth_page_image.png',
  fleetSummarySmarthubSvg: 'fleet_summary_smarthub.svg',
  fleetSummaryTpmsSvg: 'fleet_summary_tpms.svg',
  fleetSummaryLinePressureSvg: 'fleet_summary_line_pressure.svg',
  fleetSummaryAxleLoadSvg: 'fleet_summary_axle_load.svg',
  fleetSummaryGeofenceInsideSvg: 'fleet_summary_geofence_inside.svg',
  fleetSummaryGeofenceOutsideSvg: 'fleet_summary_geofence_outside.svg',
}

export const PressureWarningLevel = {
  lowPressure: 'low_pressure',
  criticalLowPressure: 'critical_low_pressure',
  overPressure: 'over_pressure',
  criticalOverPressure: 'critical_over_pressure',
}

export const TemperatureWarningLevel = {
  highTemperature: 'high_temperature',
  criticalHighTemperature: 'critical_high_temperature',
}

export const TireVibrationLevel = {
  abnormalTireVibration: 'abnormal_tire_vibration',
  abnormalHighTireVibration: 'abnormal_high_tire_vibration',
}

export const BearingVibrationLevel = {
  abnormalBearingVibration: 'abnormal_bearing_vibration',
  abnormalHighBearingVibration: 'abnormal_high_bearing_vibration',
}

export const AssetSpeedLevel = {
  overSpeed: 'over_speed',
  criticalOverSpeed: 'critical_over_speed',
}

export const WarningType = {
  hubTemperature: 'hub_temperature',
  hubVibration: 'hub_vibration',
  tirePressure: 'tire_pressure',
  noStatus: 'no_status',
  linePressure: 'line_pressure',
  tireVibration: 'tire_vibration',
  bearingVibration: 'bearing_vibration',
  assetState: 'asset_state',
  assetSpeed: 'asset_speed',
  pressureFastLeak: 'pressure_fast_leak',
}

export const UnitsOfMeasurement = {
  imperial: 'imperial',
  metric: 'metric',
}

export const HardwareType = {
  UNKNOWN: 1,
  TIRE_SENSOR: 2,
  GATEWAY: 5,
  HUB_SENSOR: 6,
  AKTV8: 9,
  AXLE_LOAD: 11,
  LINE_PRESSURE: 12,
}

export const AxlesNames = {
  Steer: 'tractor_axle_1',
  'Drive 1': 'tractor_axle_2',
  'Drive 2': 'tractor_axle_3',
  'Drive 3': 'tractor_axle_4',
  'Drive 4': 'tractor_axle_5',
  'Trailer 1': 'trailer_axle_1',
  'Trailer 2': 'trailer_axle_2',
  'Trailer 3': 'trailer_axle_3',
  'Trailer 4': 'trailer_axle_4',
  'Trailer 5': 'trailer_axle_5',
  'Trailer 6': 'trailer_axle_6',
}

export const PhoneCountryCodes = [
  { label: 'USA', code: '+1' },
  { label: 'Canada', code: '+1' },
  { label: 'Australia', code: '+61' },
  { label: 'Latvia', code: '+371' },
  { label: 'Mexico', code: '+52' },
]

export const SubscriptionTypes = {
  smarthub: 'HUB_SENSOR',
  tpms: 'TIRE_SENSOR',
  manifold: 'AKTV8',
  axleLoad: 'AXLE_LOAD',
  linePressure: 'LINE_PRESSURE',
  gateway: 'GATEWAY',
}

export const WarningSearchColumnIds = {
  SENSOR_TYPE: 'sensor_type',
  LOCATION: 'sensor_position',
  WARNING_TYPE: 'warning_type',
  WARNING_STATUSES: 'warning_statuses',
}

export const WarningSensorTypes = {
  hub_sensor: 'Smarthub',
  tire_sensor: 'TPMS',
  line_pressure: 'Line Pressure',
  axle_load: 'Axle Load',
}

export const TractorBubblePositions = ['S-1', 'D-1', 'D-2', 'D-3', 'D-4', 'D-5']

export const TrailerBubblePositions = ['T-1', 'T-2', 'T-3', 'T-4', 'T-5']

export const DateLabels = {
  ONE_DAY: '1 day',
  SEVEN_DAYS: '7 days',
  THIRTY_DAYS: '30 days',
  NINETY_DAYS: '90 days',
  SIX_MONTHS: '6 months',
}

export enum DetailsComponents {
  DetailsMap = 'DetailsMap',
  HubVibration = 'HubVibration',
  VehicleInfo = 'VehicleInfo',
  SensorNode = 'SensorInfo',
  RecentWarnings = 'RecentWarnings',
  TemperatureHistory = 'TemperatureHistory',
  TemperatureChart = 'TemperatureChart',
  TirePressure = 'TirePressure',
  AxleLoad = 'AxleLoad',
  ChartsControl = 'ChartsControl',
  LinePressure = 'LinePressure',
  TpmsStatusDiagram = 'TpmsStatusDiagram',
  LocationChart = 'LocationChart',
  PowerInputsChart = 'PowerInputsChart',
}

export enum AssociativeAxlesNames {
  TRACTOR_STEER = 'Steer',
  TRACTOR_FRONT = 'Drive 1',
  TRACTOR_REAR_1 = 'Drive 2',
  TRACTOR_REAR_2 = 'Drive 3',
  TRACTOR_REAR_3 = 'Drive 4',
  TRAILER_1 = 'Trailer 1',
  TRAILER_2 = 'Trailer 2',
  TRAILER_3 = 'Trailer 3',
  TRAILER_4 = 'Trailer 4',
  TRAILER_5 = 'Trailer 5',
  TRAILER_6 = 'Trailer 6',
}

export enum Pages {
  // standard
  Dashboard = 'Dashboard',
  AssetDetails = 'AssetDetails',
  WarningDetails = 'WarningDetails',
  Reports = 'Reports',
  Geofences = 'Geofences',
  GeofencesDetails = 'GeofencesDetails',
  Document = 'Document',
  Support = 'Support',
  Settings = 'Settings',
  CargoTags = 'CargoTags',
  TagLocations = 'TagLocations',
  TagLocationDetails = 'TagLocationDetails',
  // admin
  Provision = 'Provision',
  TpmsProfiles = 'TpmsProfiles',
  AssetGroups = 'AssetGroups',
  AssetGroupsDetails = 'AssetGroupsDetails',
  CalibrateAssets = 'CalibrateAssets',
  ManageGeofences = 'ManageGeofences',
  TagAssetDetails = 'TagAssetDetails',
  ManageNotifications = 'ManageNotifications',
  GeofenceGroups = 'GeofenceGroups',
  GeofenceGroupsDetails = 'GeofenceGroupsDetails',
  ActionableItems = 'ActionableItems',
  // super admin
  WhiteLabel = 'WhiteLabel',
  Thresholds = 'Thresholds',
  AssetAdminInfo = 'AssetAdminInfo',
  ManageSupport = 'ManageSupport',
  Admin = 'Admin',
  UIComponents = 'UIComponents',
  // public
  PublicDocument = 'PublicDocument',
}

export enum Path {
  // standard
  Root = '/',
  Dashboard = '/dashboard',
  AssetDetails = '/details/:vin/:warnings?/:timestamp?/:days?/:warningKey?/:src?',
  Reports = '/reports',
  Geofences = '/geofences',
  GeofencesDetails = '/geofence-details/:id',
  Document = '/document/:type?/:id?',
  Support = '/support',
  Settings = '/settings',
  CargoTags = '/cargo-tags',
  TagLocations = '/tag-locations',
  TagLocationDetails = '/tag-location-details/:id',
  // admin
  Provision = '/provision',
  TpmsProfiles = '/tpms-profiles',
  AssetGroups = '/asset-groups',
  AssetGroupsDetails = '/asset-groups/:id',
  CalibrateAssets = '/calibrate-assets',
  ManageGeofences = '/manage-geofences',
  TagAssetDetails = '/tag-asset-details/:id',
  ManageNotifications = '/manage-notifications',
  GeofenceGroups = '/geofence-groups',
  GeofenceGroupsDetails = '/geofence-groups/:id',
  ActionableItems = '/actionable-items',
  // super admin
  WhiteLabel = '/whitelabel',
  Thresholds = '/thresholds',
  AssetAdminInfo = '/asset-admin-info/:vin',
  ManageSupport = '/manage-support',
  Admin = '/admin',
  UIComponents = '/ui-components',
  // public
  PublicDocument = '/public/document/:type?/:id?',
}

export enum RoutesWithBlockedAllOption {
  TPMSProfilesPage = 'tpms-profiles',
  WhitelabelPage = 'whitelabel',
  ThresholdsPage = 'thresholds',
  CalibrationPage = 'calibrate-assets',
  ManageNotificationsPage = 'manage-notifications',
  SettingsPage = 'settings',
  AssetGroups = 'asset-groups',
  GeofenceGroups = 'geofence-groups',
}

export enum LocationTypes {
  TAG_LOCATION = 1,
  GEOFENCE = 2,
  TAG_GEOFENCE = 3,
}
export enum NotificationTypes {
  all = 'all',
  none = 'none',
  criticalWarning = 'critical_warning',
  warning = 'warning',
  geofenceEntry = 'entry',
  geofenceExit = 'exit',
}

export enum WarningCase {
  LOW_PRESSURE = 'low_pressure',
  CRITICAL_LOW_PRESSURE = 'critical_low_pressure',
  OVER_PRESSURE = 'over_pressure',
  CRITICAL_OVER_PRESSURE = 'critical_over_pressure',
  HIGH_TEMPERATURE = 'high_temperature',
  CRITICAL_HIGH_TEMPERATURE = 'critical_high_temperature',
  HIGH_VIBRATION = 'high_vibration',
  NO_STATUS_TPMS = 'no_status_tpms',
  NO_STATUS_SMARTHUB = 'no_status_smarthub',
  NO_STATUS_GATEWAY = 'no_status_gateway',
  NO_STATUS_LINE_PRESSURE = 'no_status_line_pressure',
  NO_STATUS_AXLE_LOAD = 'no_status_axle_load',
  LOW_LINE_PRESSURE = 'low_line_pressure',
  ABNORMAL_TIRE_VIBRATION = 'abnormal_tire_vibration',
  ABNORMAL_HIGH_TIRE_VIBRATION = 'abnormal_high_tire_vibration',
  ABNORMAL_BEARING_VIBRATION = 'abnormal_bearing_vibration',
  ABNORMAL_HIGH_BEARING_VIBRATION = 'abnormal_high_bearing_vibration',
  MOVEMENT_WITHOUT_ABS = 'movement_without_abs',
  OVER_SPEED = 'over_speed',
  CRITICAL_OVER_SPEED = 'critical_over_speed',
  FAST_LEAK = 'fast_leak',
}

export enum CommandType {
  WHITELISTING = 'whitelisting',
  WHITELISTING_SMS = 'whitelisting_sms',
}

export enum GeofenceType {
  GEOFENCE = 2,
  CARGOTAG_GEOFENCE = 3,
}

export enum HealthStatus {
  good = 'good',
  warning = 'warning',
  criticalWarning = 'criticalWarning',
  noStatus = 'noStatus',
}

export enum Permission {
  INSTALLER_APP_LOGIN = 'INSTALLER_APP_LOGIN',
  INSTALLER_APP_CREATE_ASSET = 'INSTALLER_APP_CREATE_ASSET',
  DRIVER_APP_LOGIN = 'DRIVER_APP_LOGIN',
  VIEW_USER_LOGINS_REPORT = 'VIEW_USER_LOGINS_REPORT',
}

export const ATIInstalledOptions = [
  { value: null, label: '' },
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
  { value: '2', label: 'Not Sure' },
]

export enum BackendSearchOptions {
  assetName = 'assetName',
  assetVin = 'assetVin',
  warningSetting = 'warningSetting',
  user = 'user',
  geofence = 'geofence',
  tagGeofence = 'tagGeofence',
  cargotag = 'cargotag',
}

export enum ScheduledReportTypes {
  EMPTY_TYPE = '',
  TPMS_REPORT = 'TPMS_REPORT',
  ASSETS_IN_GEOFENCE_REPORT = 'ASSETS_IN_GEOFENCE_REPORT',
  ACTIVE_WARNINGS_REPORT = 'ACTIVE_WARNINGS_REPORT',
  DWELL_REPORT = 'DWELL_REPORT',
  DEVICE_HEALTH_REPORT = 'DEVICE_HEALTH_REPORT',
  UTILIZATION_REPORT = 'UTILIZATION_REPORT',
}

export enum InternalScheduledReportTypes {
  EMPTY_TYPE = '',
  INTERNAL_FAILED_HARDWARE_COMMANDS_REPORT = 'INTERNAL_FAILED_HARDWARE_COMMANDS_REPORT',
  INTERNAL_INSTALLATIONS_REPORT = 'INTERNAL_INSTALLATIONS_REPORT',
  INTERNAL_CONMET_BATTERY_VOLTAGE_REPORT = 'INTERNAL_CONMET_BATTERY_VOLTAGE_REPORT',
  INTERNAL_CONMET_DAILY_REPORT = 'INTERNAL_CONMET_DAILY_REPORT',
  INTERNAL_TPMS_REPORT = 'INTERNAL_TPMS_REPORT',
  INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT = 'INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT',
}

export enum CronTabs {
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum CronModes {
  everyNMinutes,
  everyNHours,
  atHoursAndMinutes,
  everyNDays,
  everyWeekdays,
  weekly,
  nDaysOfEveryMonth,
}

export enum CronDisplacementModes {
  none,
  backward,
  forward,
}

export enum CronWeekDaysIds {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}

export enum NoDataLabels {
  DASH = '-',
  TRIPPLE_DASH = '---',
}

export enum CalendarTypes {
  Continuous = 'continuous',
  DateRange = 'date_range',
  ExactDate = 'exact_date',
}

export enum AssetSubtypeTrailer {
  DOLLY = 'dolly',
  DRY_VAN_TRAILER = 'dry_van_trailer',
  REFRIGERATED_TRAILER = 'refrigerated_trailer',
  FLATBED_TRAILER = 'flatbed_trailer',
  LOWBODY_TRAILER = 'lowboy_trailer',
  DROP_DECK_TRAILER = 'drop_deck_trailer',
  TANKER_TRAILER = 'tanker_trailer',
  CAR_CARRIER_TRAILER = 'car_carrier_trailer',
  BULK_TRAILER = 'bulk_trailer',
  CURTAIN_SIDE_TRAILER = 'curtain_side_trailer',
  LIVESTOCK_TRAILER = 'livestock_trailer',
  INTERMODAL_CONTAINTER_TRAILER = 'intermodal_container_trailer',
  EXTENDABLE_DOUBLE_DROP_TRAILER = 'extendable_double_drop_trailer',
  POLE_TRAILER = 'pole_trailer',
  LOGGING_TRAILER = 'logging_trailer',
  HOPPER_BOTTOM_TRAILER = 'hopper_bottom_trailer',
  SPECIALIZED_HEAVY_HAUL_TRAILER = 'specialized_heavy_haul_trailer',
  OTHER_TRAILER = 'other_trailer',
}

export enum AssetSubtypeTractor {
  SLEEPER_CAB = 'sleeper_cab',
  DAY_CAB = 'day_cab',
  OTHER_TRACTOR = 'other_tractor',
}

const HyundaiTransleadVinInitialsDefaults = {
  validTypes: ['C', 'V', 'J', 'F', 'P', 'A'],
  mapping: {
    C: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
    V: AssetSubtypeTrailer.DRY_VAN_TRAILER,
    J: AssetSubtypeTrailer.DOLLY,
    F: AssetSubtypeTrailer.FLATBED_TRAILER,
    P: AssetSubtypeTrailer.OTHER_TRAILER,
    A: AssetSubtypeTrailer.CAR_CARRIER_TRAILER,
  },
  indexOfTrailerType: 3,
}

export const ManufacturerTrailerTypeMapping = {
  knownManufacturers: [
    '1GD', //Great Dane Trailers,
    '1UY', //Utility Trailer Manufacturer ,
    '3H3', //Hyundai Translead
    '3H4', //Hyundai Translead
    '3H5', //Hyundai Translead
    '3H6', //Hyundai Translead
    '3H7', //Hyundai Translead
    '3H8', //Hyundai Translead
    '1JJ', //Wabash National,
    '1DW', //Stoughton
  ],
  manufacturersData: {
    '1GD': {
      validTypes: ['1', '3', '4', '5', '6', 'A', 'D', 'E', 'L', 'C'],
      mapping: {
        1: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        3: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
        4: AssetSubtypeTrailer.FLATBED_TRAILER,
        5: AssetSubtypeTrailer.DOLLY,
        6: AssetSubtypeTrailer.LOWBODY_TRAILER,
        A: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        D: AssetSubtypeTrailer.FLATBED_TRAILER,
        E: AssetSubtypeTrailer.DOLLY,
        L: AssetSubtypeTrailer.LOWBODY_TRAILER,
        C: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
      },
      indexOfTrailerType: 3,
    },
    '1UY': {
      validTypes: ['V', 'F', 'T'],
      mapping: {
        V: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        F: AssetSubtypeTrailer.FLATBED_TRAILER,
        T: AssetSubtypeTrailer.CURTAIN_SIDE_TRAILER,
      },
      indexOfTrailerType: 3,
    },
    '1JJ': {
      validTypes: ['V', 'F', 'C', 'U', 'D', 'T', 'E', 'G', 'D'],
      mapping: {
        V: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        F: AssetSubtypeTrailer.FLATBED_TRAILER,
        C: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
        U: AssetSubtypeTrailer.OTHER_TRAILER,
        T: AssetSubtypeTrailer.TANKER_TRAILER,
        E: AssetSubtypeTrailer.OTHER_TRAILER,
        G: AssetSubtypeTrailer.FLATBED_TRAILER,
        D: AssetSubtypeTrailer.OTHER_TRAILER,
      },
      indexOfTrailerType: 3,
    },
    '1DW': {
      validTypes: ['A', 'B', 'C', 'U'],
      mapping: {
        A: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        B: AssetSubtypeTrailer.FLATBED_TRAILER,
        C: AssetSubtypeTrailer.DOLLY,
        U: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
      },
      indexOfTrailerType: 4,
    },
    '3H3': HyundaiTransleadVinInitialsDefaults,
    '3H4': HyundaiTransleadVinInitialsDefaults,
    '3H5': HyundaiTransleadVinInitialsDefaults,
    '3H6': HyundaiTransleadVinInitialsDefaults,
    '3H7': HyundaiTransleadVinInitialsDefaults,
    '3H8': HyundaiTransleadVinInitialsDefaults,
  },
}

export enum NthsaTrailerSubtypeMap {
  'Van Trailer' = AssetSubtypeTrailer.DRY_VAN_TRAILER,
  'Refrigerated Trailer' = AssetSubtypeTrailer.REFRIGERATED_TRAILER,
  'Flatbed - Double Drop' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Single Drop' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Extendable' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - A Train' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - B Train' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Flip Axle' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Tilt' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Lowboy/Low bed Trailer' = AssetSubtypeTrailer.LOWBODY_TRAILER,
  'Drop Deck Trailer' = AssetSubtypeTrailer.DROP_DECK_TRAILER,
  'Tank Trailer' = AssetSubtypeTrailer.TANKER_TRAILER,
  'Car Hauler Trailer' = AssetSubtypeTrailer.CAR_CARRIER_TRAILER,
  'Bulk' = AssetSubtypeTrailer.BULK_TRAILER,
  'Dry Bulk Trailer' = AssetSubtypeTrailer.BULK_TRAILER,
  'Curtain Side Trailer' = AssetSubtypeTrailer.CURTAIN_SIDE_TRAILER,
  'Livestock Trailer' = AssetSubtypeTrailer.LIVESTOCK_TRAILER,
  'Livestock - Cattle Trailer' = AssetSubtypeTrailer.LIVESTOCK_TRAILER,
  'Livestock - Horse Trailer' = AssetSubtypeTrailer.LIVESTOCK_TRAILER,
  'Container Chassis/Container Trailer' = AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
  'Pole Trailer' = AssetSubtypeTrailer.POLE_TRAILER,
  'Logging Trailer' = AssetSubtypeTrailer.LOGGING_TRAILER,
  'Hopper' = AssetSubtypeTrailer.HOPPER_BOTTOM_TRAILER,
  'Other' = AssetSubtypeTrailer.OTHER_TRAILER,
}
export enum ReportStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum ReportDateRangeTypes {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  ThisWeek = 'THIS_WEEK',
  LastWeek = 'LAST_WEEK',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
  Past24H = 'PAST_24_H',
  Past7Days = 'PAST_7_D',
  Past30Days = 'PAST_30_D',
}

export enum ReportEndRuleType {
  EPOCH = 0,
  COUNT = 1,
}

export enum ResetWhitelabelType {
  STYLES = 1,
  IMAGES = 2,
  LAYOUT = 3,
  FOOTER = 4,
}
export const WhiteLabelAppFilenames = {
  smartHubPng: 'app_smarthub.png',
  axleLoadPng: 'app_axle_load.png',
  linePressurePng: 'app_line_pressure.png',
  tpmsPng: 'app_tpms.png',
  logoBrandLightPng: 'app_logo_brand_light.png',
  logoBrandDarkPng: 'app_logo_brand_dark.png',
}

export const WarningCaseToLabelMapping: Record<WarningCase, string> = {
  [WarningCase.LOW_PRESSURE]: 'Low Pressure',
  [WarningCase.CRITICAL_LOW_PRESSURE]: 'Critical Low Pressure',
  [WarningCase.OVER_PRESSURE]: 'Over Pressure',
  [WarningCase.CRITICAL_OVER_PRESSURE]: 'Critical Over Pressure',
  [WarningCase.HIGH_TEMPERATURE]: 'High Temperature',
  [WarningCase.CRITICAL_HIGH_TEMPERATURE]: 'Critical High Temperature',
  [WarningCase.HIGH_VIBRATION]: 'High Vibration',
  [WarningCase.ABNORMAL_TIRE_VIBRATION]: 'Tire Vibration',
  [WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION]: 'High Severity Tire Vibration',
  [WarningCase.ABNORMAL_BEARING_VIBRATION]: 'Bearing Vibration',
  [WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION]:
    'High Severity Bearing Vibration',
  [WarningCase.NO_STATUS_TPMS]: 'No Status TPMS',
  [WarningCase.NO_STATUS_SMARTHUB]: 'No Status Smarthub',
  [WarningCase.NO_STATUS_GATEWAY]: 'No Status Gateway',
  [WarningCase.NO_STATUS_LINE_PRESSURE]: 'No Status Line Pressure',
  [WarningCase.LOW_LINE_PRESSURE]: 'Low Line Pressure',
  [WarningCase.NO_STATUS_AXLE_LOAD]: 'No Status Axle Load',
  [WarningCase.MOVEMENT_WITHOUT_ABS]: 'Movement Without ABS',
  [WarningCase.OVER_SPEED]: 'Over Speed',
  [WarningCase.CRITICAL_OVER_SPEED]: 'Critical Over Speed',
  [WarningCase.FAST_LEAK]: 'Pressure Fast Leak',
} as const
