import { IRow } from "../../../components/RivataGrid";
import { convertDataEpochToDate } from "../../../utils";

export const vehicleLocationReportRows = (rows: Array<IRow>) => {
    return rows.map(row => {
        return {
            ...row,
            location_datetime: convertDataEpochToDate(row.location_datetime, null, null, true)
        }
    })
}
