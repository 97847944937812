import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { cloneDeep, groupBy, orderBy } from 'lodash'

import DropdownItemSelector from '../DropdownItemSelector'
import { MenuItemType } from '../Menu/MenuItem'


import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface Props {
    onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
    selectedValueIds?: any[]
    className?: string
    disabled?: boolean
    customerIds?: string
    hideNoGroupOption?: boolean
    clearSelectionOnReset?: boolean
}

const AssetGroupsFilter = forwardRef(({
    onDropdownItemSelect,
    selectedValueIds,
    className,
    disabled,
    customerIds,
    hideNoGroupOption,
    clearSelectionOnReset,
}: Props, ref) => {

    const [assetGroupItems, setAssetGroupItems] = useState([] as Array<MenuItemType>)
    const [selectedItems, setSelectedItems] = useState([] as Array<MenuItemType>)
    const [assetGroupLabel, setAssetGroupLabel] = useState('Asset Group')

    const {
        assetGroupsLimitedData
    } = useTypedSelector(state => ({
        assetGroupsLimitedData: state.assetGroups.assetGroups.limitedData
    }))
    const {
        fetchAssetGroupsLimitedDataForCustomers
    } = useActions()
    const isNewSelectedValueIds = useRef(true)

    useEffect(() => {
        if (selectedValueIds) isNewSelectedValueIds.current = true
    }, [selectedValueIds])

    useEffect(() => {
        setSelectedItems([])
        fetchAssetGroupsLimitedDataForCustomers(customerIds ? customerIds : "")
    }, [fetchAssetGroupsLimitedDataForCustomers, onDropdownItemSelect, customerIds])

    const onGroupItemSelect = useCallback((id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(el => el.checked && el.id.indexOf("||") === -1)
        if (onDropdownItemSelect)
            onDropdownItemSelect(id, checked)
        setSelectedItems(checked)
    }, [onDropdownItemSelect])

    const buildAssetGroupsListItem = useCallback(() => {
        let groups: Array<MenuItemType> = []
        const checkedItems = (!clearSelectionOnReset || isNewSelectedValueIds.current) ? cloneDeep(selectedValueIds) : undefined
        if (customerIds && customerIds.split(",").length === 1) {
            groups = (assetGroupsLimitedData as any[]).map((ag: any) => {
                return {
                    id: ag.id.toString(), label: ag.name, type: "checkbox",
                    markType: "check", checked: checkedItems && checkedItems.includes(ag.id.toString()) ? true : false, parentId: null
                } as MenuItemType
            })
        } else {
            const groupedGroups = groupBy(orderBy(assetGroupsLimitedData, "customer_name") as any[], item => `${item.customer_id}||${item.customer_name}`)
            Object.keys(groupedGroups).forEach(k => {
                const customer = k.split("||");
                const allChecked = checkedItems && groupedGroups[k].every((ag: any) => checkedItems?.includes(ag.id.toString())) ? true : false
                const anyChecked = checkedItems && groupedGroups[k].some((ag: any) => checkedItems?.includes(ag.id.toString())) ? true : false
                groups.push({
                    id: k, label: customer[1], type: "checkbox",
                    markType: (allChecked ? "check" : anyChecked ? "line" : "check") || "check", checked: anyChecked || allChecked ? true : false, parentId: null, subMenuExpanded: true
                } as MenuItemType)
                groupedGroups[k].forEach((ag: any) => {
                    groups.push({
                        id: ag.id.toString(), label: ag.name, type: "checkbox",
                        markType: "check", checked: checkedItems && checkedItems.includes(ag.id.toString()) ? true : false, parentId: k
                    } as MenuItemType)
                })
            })
        }

        if (!hideNoGroupOption) {
            groups.unshift({
                id: "-1", label: "Assets without group", type: "checkbox",
                markType: "check", checked: checkedItems && checkedItems?.includes("-1") ? true : false, parentId: null
            })
        }

        if (checkedItems) {
            onGroupItemSelect("asset_group", groups.filter((ag) => checkedItems?.includes(ag.id)))
        }
        setAssetGroupItems(groups)
    }, [assetGroupsLimitedData, customerIds, onGroupItemSelect, selectedValueIds, hideNoGroupOption, clearSelectionOnReset])

    useEffect(() => {
        buildAssetGroupsListItem();
    }, [buildAssetGroupsListItem, assetGroupsLimitedData])
    useImperativeHandle(ref, () => ({
        clearSelection() {
            isNewSelectedValueIds.current = false
            buildAssetGroupsListItem()
            setSelectedItems([])
        }
    }))
    useEffect(() => {
        setAssetGroupLabel('Asset Group' + (selectedItems && selectedItems?.length > 0 ? `: ${selectedItems?.length} selected` : ""))
    }, [selectedItems])
    return (
        <DropdownItemSelector
            id="asset_group"
            filterClassName={`mr-2 ${className ?? ""}`}
            filterBtnLabel={assetGroupLabel}
            items={assetGroupItems || []}
            onItemsChange={onGroupItemSelect}
            disabled={disabled ? disabled : false}
            clearSelectionOnReset={clearSelectionOnReset}
        />
    )
})

export default React.memo(AssetGroupsFilter)
