import baseApi from "./BaseApi"

class WhitelabelApi {
  getConfig = (customerId: number) => {
    const queryString =
      typeof customerId === "number" ? `?customer_id=${customerId}` : ""
    
    return baseApi
      .get(`rivata/whitelabel${queryString}`, false, {
        "Cache-Control": "max-age=0",
      })
  }


  uploadImage = async (file: any, filename: string, customerId: number) => {
    const queryString = `&customer_id=${customerId}`
  
    return baseApi.post(`rivata/whitelabel/image?filename=${filename}${queryString}`, file, {}, false)
  }


  postConfig = (data: any) => {
    return baseApi.post(`rivata/whitelabel`, data)
  }


  putWhitelabelReset = (data: any) => {
    return baseApi.put("rivata/whitelabel/reset", data)
  }
}

const whitelabelApi = new WhitelabelApi()

export default whitelabelApi