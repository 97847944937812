import React, { useState, useEffect } from "react";
import momentTz from "moment-timezone"
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Timezones } from "../../enums";

const defaultTimezones = {...Timezones};

const timezones = Object.keys(defaultTimezones).map((id) => ({
  id: defaultTimezones[id],
  label: `${momentTz.tz(defaultTimezones[id]).format("Z")} ${defaultTimezones[id]}`,
}));

const TimezonePicker = ({
  id = "default",
  onSetTimezone,
  initialValue,
  locale = {},
  isInvalid = false
}) => {
  const [selections, setSelections] = useState([]);
  useEffect(() => {
    const selection = timezones.find(({ id }) => id === initialValue);
    if (selection) {
      setSelections([selection]);
    }
  }, [initialValue]);

  return (
    <Typeahead
      id={`rivata-typeahead-timezone-${id}`}
      labelKey="label"
      onChange={(selections) => {
        setSelections(selections);
        onSetTimezone(
          selections && selections.length ? selections[0].id : null
        );
      }}
      options={timezones}
      placeholder={locale.timezoneLabel || "Type or Select"}
      selected={selections}
      clearButton
      isInvalid={isInvalid}
    />
  );
};

export const timezonesType = {
  id: PropTypes.string,
  onSetTimezone: PropTypes.func.isRequired,
  locale: PropTypes.objectOf(PropTypes.string),
};

TimezonePicker.propTypes = timezonesType;

export default TimezonePicker;
