import React, { useMemo } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { Draggable } from '@hello-pangea/dnd'
import WidthDropdown from './WidthDropdown'
import { ModuleItem } from '.'

const newWidthItem = (item: ModuleItem, width: number) => ({
  id: item.id,
  data: { ...item.data, width },
})
const copyEnabled = (item: ModuleItem, enabled: boolean) => ({
  id: item.id,
  data: { ...item.data, enabled },
})

type Props = {
  item: ModuleItem & { rowId: number }
  onItemUpdated: (item: ModuleItem) => void
  index: number
  isConstantWidth?: boolean
  showDisableCheckbox?: boolean
}

const ModuleOrderItem: React.FC<Props> = ({
  item,
  onItemUpdated,
  index,
  isConstantWidth,
  showDisableCheckbox,
}) => {
  const rowColoringClass = useMemo(() => {
    if (item.rowId === -1) return 'module-item-disabled'
    if (item.rowId % 2 === 0) return 'module-item-light'
    return 'module-item-dark'
  }, [item.rowId])

  return (
    <Draggable draggableId={item.id} index={index}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          className={`module-item ${rowColoringClass}`}
        >
          <i className='fa fa-ellipsis-v px-2 ' aria-hidden='true'></i>

          {item.data.title}

          <div className='ml-auto'>
            <WidthDropdown
              selected={item.data.width}
              onSelect={(width) => onItemUpdated(newWidthItem(item, width))}
              disabled={isConstantWidth}
            />
          </div>

          {showDisableCheckbox && (
            <div className='mr-2'>
              <FormGroup check>
                <Label
                  check
                  className='d-inline-flex align-items-center justify-content-between'
                >
                  <Input
                    type='checkbox'
                    defaultChecked={item.data.enabled}
                    style={{ marginTop: 0 }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onItemUpdated(copyEnabled(item, e.target.checked))
                    }
                  />
                  <span>Enabled</span>
                </Label>
              </FormGroup>
            </div>
          )}
        </div>
      )}
    </Draggable>
  )
}

export default ModuleOrderItem
