import React from 'react'

import RivataLoader from '../../../components/RivataLoader'
import RivataModule from '../../../components/RivataModule'
import StatusAlert from '../../../components/StatusAlert'
import ReactJSONEditor from '../../../components/ReactJSONEditor'

import { useTypedSelector } from '../../../hooks/useTypedSelector'

interface Props {
  locale: ILocale
}

const SensorInfo: React.FC<Props> = ({ locale }) => {
  const sensorInfo = useTypedSelector(
    (state) => state.assetAdminInfo.sensorInfo,
  )

  return (
    <RivataModule
      title={`Installed Sensors`}
      locale={locale}
      marginTop={0}
      filters={null}
    >
      {sensorInfo?.isLoading ? (
        <RivataLoader />
      ) : sensorInfo &&
        sensorInfo.data &&
        !Object.keys(sensorInfo.data).length ? (
        <StatusAlert
          customText='No Data'
          color='success'
          statusCode={undefined}
          statusText={undefined}
        />
      ) : (
        <ReactJSONEditor
          content={sensorInfo.data ? { json: sensorInfo.data } : { text: '' }}
          readOnly
        />
      )}
    </RivataModule>
  )
}

export default SensorInfo
