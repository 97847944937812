import React, { useCallback, useEffect, useState } from 'react'
import { match } from 'react-router-dom'

import GeofenceDetailsMap from './Map'
import SubNav from '../../components/SubNav'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import GeofenceDetailsGrid from './Grid'
import { useQuery } from '../../hooks/useQuery'


interface Props {
    match: match<{ id: string }>
}

const breadcrumbs = [
    { label: "Home", path: "/dashboard" }, 
    { label: "Geofences", path: "/geofences" }, 
    { label: "Geofence Details" }
]

const GeofenceDetails: React.FC<Props> = ({ match }) => {
    const locale = useTypedSelector(state => state.whitelabel.locale)
    const [sortOrder, setSortOrder] = useState()
    const { fetchGeofenceById, fetchGeofenceAssetsById } = useActions()

    const geofenceId = match.params.id

    const query = useQuery()

    const onFilter = useCallback((filters: string) => {
        fetchGeofenceById(geofenceId)
        fetchGeofenceAssetsById(geofenceId, filters, sortOrder)
    }, [geofenceId, fetchGeofenceById, fetchGeofenceAssetsById, sortOrder])

    useEffect(() => {
        const filtersString = new URLSearchParams(query.getAll("filters").map(filter => ["filters", filter])).toString()
        onFilter(filtersString)
    }, [query, onFilter])

    return (
        <>
            <SubNav
                title='Geofence Details'
                breadcrumbs={breadcrumbs}
            />

            <GeofenceDetailsMap
                locale={locale}
            />

            <GeofenceDetailsGrid
                locale={locale}
                onFilter={onFilter}
                onSort={setSortOrder}
            />
        </>
    )
}

export default React.memo(GeofenceDetails)