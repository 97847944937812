import React, { useEffect, useMemo, useState } from 'react'
import RivataTable from '../../../components/RivataTable'
import calibrationApi from '../../../services/api/ApiGroups/CalibrationApi'
import { getOdometerRows } from './utils'

interface Props {
  selectedAsset: AssetShortFormatType | null
  selectedUnitsOfMeasurement: string
  onDelete: (data: any) => void
}

const columns = [
  { id: 'odometer', label: 'Odometer', isDraggable: true },
  { id: 'timestamp', label: 'Date', isDraggable: true },
  { id: 'source', label: 'Source', isDraggable: true },
  { id: 'created_by', label: 'Username', isDraggable: true },
]
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'calibrate.odometerTable.columns',
}
const OdometerTable: React.FC<Props> = ({
  selectedAsset,
  selectedUnitsOfMeasurement,
  onDelete,
}) => {
  // const [rows, setRows] = useState<Array<any>>([])
  const [data, setData] = useState<Array<IOdometerCalibration>>([])

  useEffect(() => {
    if (!selectedAsset) return setData([])

    const getData = async () => {
      const res = await calibrationApi.getAssetOdometerCalibration(
        selectedAsset.id,
      )
      setData(res)
    }

    getData()
  }, [selectedAsset])

  const rows = useMemo(() => {
    return getOdometerRows(data, selectedUnitsOfMeasurement)
  }, [data, selectedUnitsOfMeasurement])

  return (
    <RivataTable
      columns={columns}
      rows={rows}
      onDelete={onDelete}
      isLoading={undefined}
      onEdit={undefined}
      editDisabled={undefined}
      deleteDisabled={false}
      setSortOptions={undefined}
      onCustomAction={undefined}
      // @ts-ignore component expect undefined cause of default value
      columnsSelectorProps={columnsSelectorProps}
    />
  )
}

export default OdometerTable
