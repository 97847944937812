import React, { ButtonHTMLAttributes } from 'react'
import Button from '../Button'

export type LabelItemType = { id: string | number, label: string }

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>{
    text: string
    onDelete: () => void
}

const Label: React.FC<Props> = ({
    text,
    onDelete,
    ...props
}) => {
    return (
        <Button
            btnType='btn__primary'
            {...props}
        >
            <div
                className='btn__text'
            >
                {text}
            </div>
            <div className='d-flex'>
                <i 
                    className="fa-solid fa-xmark label__delete"
                    onClick={() => onDelete()}
                ></i>
            </div>
        </Button>
    )
}

export default Label
