import React, { useMemo } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hexToRgb } from '../../utils/utils'

type Props = {
  children: any
}

const CssRgbThemeColorsWrapper: React.FC<Props> = ({ children }) => {
  const themeColors = useTypedSelector((state) => state.whitelabel.themeColors)

  const cssVarsStyle = useMemo(() => {
    type whitelabelColor = {
      id: string
      label: string
      color: string
    }
    const primary = hexToRgb(
      themeColors.find((item: whitelabelColor) => item.id === 'Primary1').color,
    )
    const secondary = hexToRgb(
      themeColors.find((item: whitelabelColor) => item.id === 'Secondary1')
        .color,
    )
    return {
      '--primary-rgb': primary
        ? `${primary.r}, ${primary.g}, ${primary.b}`
        : '51, 175, 201',
      '--secondary-rgb': secondary
        ? `${secondary.r}, ${secondary.g}, ${secondary.b}`
        : '55, 57, 54',
    } as React.CSSProperties
  }, [themeColors])
  return <div style={cssVarsStyle}>{children}</div>
}

export default CssRgbThemeColorsWrapper
