import React, { useMemo } from 'react'
import ChartLegend from '../../components/ChartLegend'
import pinOk from '../../assets/images/icons/svg/pin_ok.svg'
import pinCritWarn from '../../assets/images/icons/svg/pin_crit_warn.svg'
import circleWarn from '../../assets/images/icons/svg/circle_warn.svg'
import circleCritWarn from '../../assets/images/icons/svg/circle_crit_warn.svg'
import circleGps from '../../assets/images/icons/svg/circle_gps.svg'

const MapLegend = ({ locale, isWarningDetailsPage, isAssetDetailsPage }) => {
  const items = useMemo(() => {
    const legendDesc = [
      {
        icon: circleCritWarn,
        label: 'Critical Warning',
      },
      {
        icon: circleWarn,
        label: 'Warning',
      },
      {
        icon: circleGps,
        label: 'GPS Data',
      },
      {
        icon: pinCritWarn,
        label: 'Latest GPS Data',
      },
    ]

    if (!isWarningDetailsPage) {
      legendDesc.splice(0, 2)
    }

    if (isAssetDetailsPage) {
      legendDesc.splice(1, 1)
      legendDesc.splice(2, 0, {
        icon: pinOk,
        label: 'Starting Point',
      })
    }

    return legendDesc
  }, [isWarningDetailsPage, isAssetDetailsPage])

  return <ChartLegend items={items} zIndex={9} locale={locale} title='Legend' />
}

export default MapLegend
