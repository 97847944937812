import React, { useState, useEffect } from "react";

import RivataModule from "../../components/RivataModule";
import RivataLoader from "../../components/RivataLoader"
import PreferencesForm from "../../components/PreferencesForm";
import InfoModal from '../../components/InfoModal'

import { fixInValidPhoneNumber } from "../../utils";

import { useTypedSelector } from "../../hooks/useTypedSelector";

const Preferences = ({
  preferences,
  isSaving,
  onUpdatePreferences,
  locale,
  width,
}) => {
  const { customerDefaults } = useTypedSelector(state => ({
    customerDefaults: state.common.customerDefaults
  }))

  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [timezone, setTimezone] = useState(preferences.timezone);
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [unitsOfMeasurementConfig, setUnitsOfMeasurementConfig] = useState()

  useEffect(() => {
    setPhone(fixInValidPhoneNumber(preferences.phone))
    setTimezone(preferences.timezone)
    setFirstName(preferences.firstName)
    setLastName(preferences.lastName)
    setUnitsOfMeasurementConfig(preferences.unitsOfMeasurementConfig)
  }, [preferences])

  const handleUpdateUserAttributes = async () => {
    setLoading(true)

    const res = await onUpdatePreferences({
      phone: phone,
      oldPhone: preferences.phone,
      timezone,
      unitsOfMeasurementConfig: unitsOfMeasurementConfig,
      firstName,
      lastName
    });

    setLoading(false)

    if (res && res.status === "ok") {
      setMessage("Settings successfully saved")
    }
  }

  return (
    <RivataModule
      title={locale.settings || "User Profile"}
      width={width}
      locale={locale}
    >
      <InfoModal
        header={"Settings info"}
        open={!!message}
        message={message || ""}
        onConfirm={() => setMessage(null)}
      />
    
      {loading && <RivataLoader/>}

      <PreferencesForm
        onSubmit={handleUpdateUserAttributes}
        onTimezoneChange={setTimezone}
        phone={phone}
        onPhoneChange={setPhone}
        email={preferences.email}
        timezone={timezone}
        isSaving={isSaving}
        hasLanguage={false}
        locale={locale}
        showUnitsOfMeasureSelector={true}
        clearMessage={() => setMessage(null)}
        isUserProfile={true}
        customerDefaults={customerDefaults}
        phoneNumberVerified={preferences.phoneNumberVerified}
        isVerifyPhoneActive={preferences.phone === phone}
        firstName={firstName}
        lastName={lastName}
        roles={preferences.roles}
        onFirstNameChange={setFirstName}
        onLastNameChange={setLastName}
        unitsOfMeasurementConfig={unitsOfMeasurementConfig}
        setUnitsOfMeasurementConfig={setUnitsOfMeasurementConfig}
      />
    </RivataModule>
  );
};

export default Preferences;
