import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import UsersRow from '../ReportParametersRows/UsersRow'
import ReportTypeRow from '../ReportParametersRows/ReportTypeRow'

const ScheduledReportSubscriptionsReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <UsersRow value={props.parameters.usernames} />
      <ReportTypeRow value={props.parameters.report_type_filter} />
    </>
  )
}
export default ScheduledReportSubscriptionsReportParameters
