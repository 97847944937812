import React, { useState, useCallback } from "react"
import SubNav from "../../components/SubNav"
import CustomerSelector from "../../modules/CustomerSelector"
import Tags from "../../modules/CargoTags"
import { useActions } from "../../hooks/useActions"

const CargoTags = () => {
  const { setCargoTagsFilter } = useActions()
  const [unassigned, setUnassigned] = useState(false)

  const onCustomerSubmit = useCallback(() => {
    setCargoTagsFilter("")
  }, [setCargoTagsFilter])

  return (
    <>
      <SubNav
        title="Cargo Tags"
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: "Cargo Tags" },
        ]}
      >
        <CustomerSelector
          onSubmit={onCustomerSubmit}
          disabled={unassigned}
          setAllFromTags={unassigned}
        />
      </SubNav>

      <Tags unassigned={unassigned} setUnassigned={setUnassigned} />
    </>
  )
}

export default CargoTags
