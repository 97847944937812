import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, Button } from 'reactstrap'
import * as Yup from 'yup'

import NotificationUsers from '../NotificationUsers'
import InputsGroup from './InputsGroup'

import { NotificationFormValues } from '../../hooks'

import './style.scss'
import NotificationAssets from '../NotificationAssets'
import { SearchItemType } from '../../../../components/ItemsSearch'
import AssetGroupsFilter from '../../../../componentsV2/AssetGroupsFilter'
import { MenuItemType } from '../../../../componentsV2/Menu/MenuItem'
import NotificationGeofences from '../NotificationGeofences'
import GeofenceGroupsFilter from '../../../../componentsV2/GeofenceGroupsFilter'

const schema = Yup.object().shape({
  name: Yup.string().required('Profile name is required'),
  sms: Yup.boolean(),
  email: Yup.boolean(),
  critical_alert_interval_in_seconds: Yup.number(),
  info_alert_interval_in_seconds: Yup.number(),
  tire_pressure: Yup.string(),
  line_pressure: Yup.string(),
})

interface Props {
  locale: ILocale
  initialValues: NotificationFormValues
  users: Array<IUserShortFormat>
  isUpdate: boolean
  profileUsers: Array<IUserShortFormat> | null
  profileAssets: Array<SearchItemType>
  profileAssetsGroups: Array<string>
  profileGeofences: Array<SearchItemType>
  profileGeofenceGroups: Array<string>
  profileExcludeAssets: Array<SearchItemType>
  profileExcludeGeofences: Array<SearchItemType>
  setProfileGeofenceGroups: React.Dispatch<React.SetStateAction<number[]>>
  setProfileUsers: React.Dispatch<
    React.SetStateAction<IUserShortFormat[] | null>
  >
  setProfileAssets: React.Dispatch<React.SetStateAction<SearchItemType[]>>
  setProfileAssetsGroups: React.Dispatch<React.SetStateAction<number[]>>
  setProfileGeofences: React.Dispatch<React.SetStateAction<SearchItemType[]>>
  setProfileExcludeAssets: React.Dispatch<
    React.SetStateAction<SearchItemType[]>
  >
  setProfileExcludeGeofences: React.Dispatch<
    React.SetStateAction<SearchItemType[]>
  >
  handleFormSubmit: (
    formValues: NotificationFormValues,
    resetForm: () => void,
  ) => void
  onCancel: () => void
  selectedCustomersList: Array<ICustomer>
  subscriptions: any
  isSuperAdmin: boolean
}

const ProfileForm: React.FC<Props> = ({
  initialValues,
  users,
  isUpdate,
  profileUsers,
  profileAssets,
  profileAssetsGroups,
  profileGeofences,
  profileGeofenceGroups,
  profileExcludeAssets,
  profileExcludeGeofences,
  setProfileUsers,
  setProfileAssets,
  setProfileAssetsGroups,
  setProfileGeofences,
  setProfileGeofenceGroups,
  setProfileExcludeGeofences,
  setProfileExcludeAssets,
  handleFormSubmit,
  onCancel,
  selectedCustomersList,
  subscriptions,
  isSuperAdmin,
}) => {
  const [isFormTouched, setIsFormTouched] = useState(false)

  useEffect(() => {
    setIsFormTouched(false)
  }, [initialValues])

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const ids = items.map((el) => Number(el.id))
      setProfileAssetsGroups(ids)

      setIsFormTouched(true)
    },
    [setProfileAssetsGroups, setIsFormTouched],
  )

  const onGeofenceGroupItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const ids = items.map((el) => Number(el.id))
      setProfileGeofenceGroups(ids)

      setIsFormTouched(true)
    },
    [setProfileGeofenceGroups, setIsFormTouched],
  )

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
    >
      <Form className='profile-form'>
        <Row className='profile-form__wrapper'>
          <Col md='6' className='mt-3'>
            <InputsGroup
              disabled={initialValues.is_system_profile}
              subscriptions={subscriptions}
              isSuperAdmin={isSuperAdmin}
            />
          </Col>

          <Col md='6' className='mt-3'>
            <NotificationUsers
              users={users}
              profileUsers={profileUsers}
              setProfileUsers={setProfileUsers}
              setIsFormTouched={setIsFormTouched}
            />
            {!initialValues.is_system_profile && (
              <>
                <h4 className='ml-3'>Include</h4>
                <NotificationAssets
                  profileAssets={profileAssets}
                  setIsFormTouched={setIsFormTouched}
                  setProfileAssets={setProfileAssets}
                />
                <div className='profile-form__asset_group'>
                  <AssetGroupsFilter
                    selectedValueIds={profileAssetsGroups}
                    onDropdownItemSelect={onDropdownItemSelect}
                    customerIds={selectedCustomersList
                      .map((c) => c.id)
                      .join(',')}
                    ref={null}
                    clearSelectionOnReset={true}
                  />
                </div>
                <NotificationGeofences
                  profileGeofences={profileGeofences}
                  setIsFormTouched={setIsFormTouched}
                  setProfileAssets={setProfileGeofences}
                />
                <div className='profile-form__geofence_groups'>
                  <GeofenceGroupsFilter
                    selectedValueIds={profileGeofenceGroups}
                    onDropdownItemSelect={onGeofenceGroupItemSelect}
                    customerIds={selectedCustomersList
                      .map((c: any) => c.id)
                      .join(',')}
                    ref={null}
                    clearSelectionOnReset={true}
                  />
                </div>
                <h4 className='ml-3 mt-4'>Exclude</h4>
                <NotificationAssets
                  profileAssets={profileExcludeAssets}
                  setIsFormTouched={setIsFormTouched}
                  setProfileAssets={setProfileExcludeAssets}
                />
                <NotificationGeofences
                  profileGeofences={profileExcludeGeofences}
                  setIsFormTouched={setIsFormTouched}
                  setProfileAssets={setProfileExcludeGeofences}
                />
              </>
            )}
          </Col>
        </Row>

        <Row className='mt-3' style={{ alignSelf: 'flex-end' }}>
          <Col className='d-flex justify-content-end'>
            <Button type='submit' color='primary' disabled={!isFormTouched}>
              {isUpdate ? 'Save' : 'Create'}
            </Button>
            <Button color={'danger'} className='ml-3' onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Formik>
  )
}

export default ProfileForm
