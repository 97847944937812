import React, { ReactNode, useEffect, useState } from 'react'
import './style.scss'

type Props = {
  children?: ReactNode
}

type AssetCardFC = React.FC<Props> & {
  Header: React.FC<AssetCardHeaderProps>
}

type AssetCardHeaderProps = {
  label: string
}

const CardIcon: React.FC<{ icon?: string; fallBackIcon?: string }> = ({
  icon,
  fallBackIcon,
}) => {
  const [iconSrc, setIconSrc] = useState(icon)

  useEffect(() => setIconSrc(icon), [icon, fallBackIcon])

  if (!iconSrc) return <></>

  return (
    <div className='asset_card_content_icon_container'>
      <img
        className='asset_card_content_icon'
        src={iconSrc}
        alt={''}
        onError={(e) => {
          if (fallBackIcon) {
            e.stopPropagation()
            setIconSrc(fallBackIcon)
          }
        }}
      />
    </div>
  )
}

const AssetCard: AssetCardFC = (props) => {
  const { children } = props

  return <div className='asset_card_container'>{children}</div>
}

export const AssetCardHeader: React.FC<AssetCardHeaderProps> = (props) => {
  const { label } = props

  return <span className='asset_card_label'>{label}</span>
}

export const AssetCardItem: React.FC<{
  id?: string
  icon?: string
  fallBackIcon?: string
  label: string
  value: number
}> = (props) => {
  const { id, icon, fallBackIcon, label, value } = props

  return (
    <div id={id} className='asset_card_content'>
      <CardIcon icon={icon} fallBackIcon={fallBackIcon} />

      <div>
        <div className='asset_card_content_label'>{label}</div>

        <div className='asset_card_content_value'>
          <b>{value}</b>
        </div>
      </div>
    </div>
  )
}

AssetCard.Header = AssetCardHeader

export default AssetCard
