import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Fade, CustomInput } from 'reactstrap'
import GeofenceConstructor from './GeofenceConstructor'
import GeofenceRenderer from '../renderer/GeofenceRenderer'
import ShapeSelector from '../shapes/ShapeSelector'
import { Geometry } from '../types'
import '../styles.scss'
import RivataDropdown from '../../../components/RivataDropdown'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import ConfirmModal from '../../../components/ConfirmModal'
import { GeofenceBodyType } from '../../../redux/manageGeofences/types'
import ShareCustomersSelector from './ShareCustomersSelector'
import InfoModal from '../../../components/InfoModal'

interface IRemoveGW
  extends Pick<
    IGeofence,
    'id' | 'name' | 'geofence' | 'shape' | 'location_type_id' | 'gateways'
  > {
  unassign_gateways: boolean
  share_with_customer_ids: Array<number>
}

interface IGefencePostPutBody
  extends Pick<IGeofence, 'name' | 'geofence' | 'shape' | 'location_type_id'> {
  share_with_customer_ids: Array<number>
}

interface Props {
  map: H.Map | null
  ui: H.ui.UI | null
  behavior: H.mapevents.Behavior | null
  geofences: Array<IGeofence>
  zoomTo: number | null
  selectedGeofence: IGeofence | null
  resetEditorState?: number
  selectedCustomerId: number | null
  locale: ILocale
  setSelectedGeofence: (data: IGeofence | null) => void
  createGeofence: (geofence: GeofenceBodyType) => void
  updateGeofence: (id: number, geofence: GeofenceBodyType) => void
  mapZoomBounds: any
  onMapViewChange: (bounds: any, zoom: any) => void
  onCancel: () => void
  showDeleted: boolean
  selectedForReceation: IGeofence | null
  setSelectedForReceation: (data: IGeofence | null) => void
}

const dropdownTypes = [
  { id: 2, label: 'Geofence' },
  { id: 3, label: 'Tag Geofence' },
]

const GeofenceEditor: React.FC<Props> = ({
  map,
  ui,
  behavior,
  zoomTo,
  geofences,
  selectedGeofence,
  resetEditorState,
  selectedCustomerId,
  locale,
  setSelectedGeofence,
  createGeofence,
  updateGeofence,
  mapZoomBounds,
  onMapViewChange,
  onCancel,
  showDeleted,
  selectedForReceation,
  setSelectedForReceation,
}) => {
  const { cargotagsSubscription, isSuperAdmin, isAttributesLoaded, customers } =
    useTypedSelector((state) => ({
      cargotagsSubscription:
        state.common.customerDefaults.cargo_tags_subscription,
      isSuperAdmin: state.auth.isSuperAdmin,
      isAttributesLoaded: state.auth.isAttributesLoaded,
      customers: state.common.customers.data as Array<ICustomer>,
    }))

  const [showToolbar, setShowToolbar] = useState(false)
  const [geofencesVisible, setGeofencesVisible] = useState(true)
  const [error, setError] = useState('')
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const [geofenceWKT, setGeofenceWKT] = useState('')
  const [geofenceName, setGeofenceName] = useState('')
  const [geofenceType, setGeofenceType] = useState<number | null>(null)

  const [selectedShape, setSelectedShape] = useState('')
  const [geometry, setGeometry] = useState<Geometry>(null)
  const [drawingStarted, setDrawingStarted] = useState(false)
  const [edit, setEdit] = useState<boolean>(false)

  const [removeGwWarning, setRemoveGwWarning] = useState<{
    geo: IRemoveGW
    message: string
  } | null>(null)

  const [shareCustomerIds, setShareCustomerIds] = useState<Array<number>>([])

  const onDrawingStarted = () => {
    setDrawingStarted(true)
  }

  const onDrawingFinished = (geofenceWKT: string) => {
    setGeofenceWKT(geofenceWKT)
  }

  const disposeGeometry = () => {
    if (geometry) {
      geometry.dispose(true)
      setGeometry(null)
    }
  }

  const onClear = () => {
    if (selectedGeofence || selectedForReceation) {
      setGeofencesVisible(false)
    }
    setEdit(true)
    setSelectedShape('')
    setGeofenceWKT('')
    setGeofenceType(null)
    disposeGeometry()

    setDrawingStarted(false)
  }

  const onReset = () => {
    setSelectedGeofence(null)
    setSelectedForReceation(null)

    setShowToolbar(false)
    setError('')
    setEdit(false)
    setGeofenceName('')
    setSelectedShape('')
    setGeofenceWKT('')

    disposeGeometry()
    setGeofencesVisible(true)

    setDrawingStarted(false)

    setShareCustomerIds([])
  }

  const handleApiResult = (res: IStatus) => {
    if (res.ok) {
      onReset()
      setSuccessModalOpen(true)
    } else setError(res.message)
  }

  const onSave = async () => {
    setError('')

    const name = geofenceName.trim()

    if (!selectedShape) return setError('Shape must be selected.')
    if (!geofenceType) return setError('Type must be selected.')
    if (!name) return setError('Name can not be empty.')
    if (!geofenceWKT)
      return setError(
        "Oops we've encountered an issue. Please try again later.",
      )

    const geofence: IGefencePostPutBody = {
      name,
      geofence: geofenceWKT,
      shape: selectedShape,
      location_type_id: geofenceType,
      share_with_customer_ids: shareCustomerIds,
    }

    if (selectedGeofence?.id) {
      if (
        selectedGeofence.location_type_id === 3 &&
        geofence.location_type_id !== 3 &&
        selectedGeofence.gateways?.length
      ) {
        setRemoveGwWarning({
          geo: {
            ...geofence,
            id: selectedGeofence.id,
            gateways: selectedGeofence.gateways,
            unassign_gateways: true,
          },
          message:
            'Tag data will no longer be reported from this location, are you sure you want to proceed?',
        })
      } else if (selectedGeofence.id) {
        const res: any = await updateGeofence(selectedGeofence.id, geofence)
        handleApiResult(res)
      }
    } else {
      const res: any = await createGeofence(geofence)
      handleApiResult(res)
    }
  }

  useEffect(() => {
    if (
      !selectedGeofence ||
      !selectedGeofence.geofence ||
      !selectedGeofence.shape
    )
      return

    setGeofencesVisible(true)

    setGeofenceWKT(selectedGeofence.geofence)
    setGeofenceName(selectedGeofence.name)
    setSelectedShape(selectedGeofence.shape)

    setShareCustomerIds(
      selectedGeofence.shared_with_customer_ids?.filter((id) =>
        customers.some((customer: ICustomer) => customer.id === id),
      ) ?? [],
    )

    setShowToolbar(true)
  }, [selectedGeofence, customers])

  useEffect(() => {
    if (resetEditorState || selectedCustomerId) onReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetEditorState, selectedCustomerId, showDeleted])

  const memoizedGeofences = useMemo(() => {
    return selectedGeofence
      ? [selectedGeofence]
      : selectedForReceation
      ? [selectedForReceation]
      : geofences
  }, [selectedGeofence, geofences, selectedForReceation])

  useEffect(() => {
    if (!cargotagsSubscription && !isSuperAdmin && isAttributesLoaded) {
      const idx = dropdownTypes.findIndex((el) => el.id === 3)
      if (idx > -1) dropdownTypes.splice(idx, 1)
    }
  }, [cargotagsSubscription, isSuperAdmin, isAttributesLoaded])

  useEffect(() => {
    disposeGeometry()
    if (selectedGeofence) {
      const elem = dropdownTypes.find(
        (el) => el.id === selectedGeofence.location_type_id,
      )

      if (elem) setGeofenceType(elem.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeofence])

  useEffect(() => {
    if (selectedForReceation) {
      setGeofencesVisible(true)
      setShowToolbar(true)

      setGeofenceName(selectedForReceation.nameOriginal ?? '')
      setGeofenceWKT(selectedForReceation.geofence)
      setSelectedShape(selectedForReceation.shape)
      setGeofenceType(
        dropdownTypes.find(
          (el) => el.id === selectedForReceation.location_type_id,
        )?.id ?? null,
      )
    }
  }, [selectedForReceation])

  return (
    <>
      <div id='add_geofence_button' className='d-flex flex-column'>
        <div>
          {!selectedGeofence && !showToolbar && (
            <Button
              className='mb-2 mr-3'
              color={showToolbar ? 'danger' : 'primary'}
              disabled={!selectedCustomerId}
              onClick={() => {
                setGeofencesVisible(!geofencesVisible)
                setShowToolbar(!showToolbar)

                if (showToolbar) {
                  onReset()
                  onCancel()
                }
              }}
            >
              {showToolbar ? 'Cancel' : 'Add Geofence'}
            </Button>
          )}

          {showToolbar && (
            <Fade className='geofence-toolbar mb-3'>
              <div className='d-flex mb-3'>
                <ShapeSelector
                  selectedShape={selectedShape}
                  disabled={
                    !!(
                      (selectedGeofence || selectedForReceation) &&
                      geofencesVisible
                    )
                  }
                  setSelectedShape={setSelectedShape}
                />

                <RivataDropdown
                  caret={true}
                  buttonLabel={
                    dropdownTypes.find((el) => el.id === geofenceType)?.label ||
                    'Select Type'
                  }
                  filters={undefined}
                  items={dropdownTypes}
                  selected={geofenceType}
                  onSelect={setGeofenceType}
                  onRemove={undefined}
                  color={'secondary'}
                  disabled={false}
                />

                <Button
                  disabled={
                    !(
                      ((selectedGeofence || selectedForReceation) &&
                        geofencesVisible) ||
                      drawingStarted
                    )
                  }
                  onClick={onClear}
                >
                  Clear Geofence
                </Button>

                {!selectedGeofence && !selectedForReceation && (
                  <div className='d-flex align-items-center ml-5'>
                    <CustomInput
                      type='switch'
                      id='showGeofencesSwitch'
                      label='Show Geofences'
                      checked={geofencesVisible}
                      onChange={() => {
                        setGeofencesVisible((visible) => !visible)
                      }}
                    />
                  </div>
                )}
              </div>

              <Input
                className='mb-3 col-md-4 col-sm-6 col-10'
                placeholder='Enter Name'
                value={geofenceName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setGeofenceName(e.target.value)
                }
              />

              <ShareCustomersSelector
                selectedGeofence={selectedGeofence}
                shareCustomerIds={shareCustomerIds}
                setShareCustomerIds={setShareCustomerIds}
              />

              <div className='mt-3'>
                <Button
                  className='mr-3'
                  color='danger'
                  onClick={() => {
                    onReset()
                    onCancel()
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className='btn-primary'
                  disabled={!geofenceWKT || !geofenceName.trim().length}
                  onClick={onSave}
                >
                  Save
                </Button>
              </div>
            </Fade>
          )}

          <GeofenceConstructor
            map={map}
            behavior={behavior}
            geometry={geometry}
            selectedShape={selectedShape}
            disabled={false}
            setGeometry={setGeometry}
            onDrawingStarted={onDrawingStarted}
            onDrawingFinished={onDrawingFinished}
          />
        </div>

        {error && <span className='error-message mb-3'>{error}</span>}
      </div>

      {removeGwWarning && (
        <ConfirmModal
          header='Unassign Gateway'
          locale={locale}
          open={true}
          onClose={() => setRemoveGwWarning(null)}
          modalButtons={[
            {
              id: 1,
              label: 'Ok',
              color: 'danger',
              onClick: async () => {
                if (removeGwWarning.geo.id)
                  await updateGeofence(
                    removeGwWarning.geo.id,
                    removeGwWarning.geo,
                  )

                setRemoveGwWarning(null)
              },
            },
          ]}
        >
          {removeGwWarning.message}
        </ConfirmModal>
      )}

      <GeofenceRenderer
        onMapViewChange={onMapViewChange}
        mapZoomBounds={mapZoomBounds}
        map={map}
        ui={ui}
        geofences={memoizedGeofences}
        geofencesVisible={geofencesVisible}
        zoomTo={zoomTo}
        edit={edit}
        setBounds={true}
        renderLocationBtn={true}
        shouldAddCenterMarkers={true}
      />

      <InfoModal
        header='Manage Geofences Info'
        message='Changes successfully saved'
        open={!!successModalOpen}
        onConfirm={() => setSuccessModalOpen(false)}
      />
    </>
  )
}

export default GeofenceEditor
