import React, { useEffect, useMemo, useState } from "react";
import { TabContent, TabPane, Row, Nav, NavItem, NavLink } from "reactstrap"
import SubNav from "../../components/SubNav";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ManageDocumentTypesModule from "../../modules/ManageDocumentTypesModule";
import ManageSupportModule from "../../modules/ManageSupportModule";
import classnames from 'classnames';

import './style.scss'

const ManageSupport = () => {
  const {
    locale = {}, 
    documents: { isLoading: isLoadingDocs, documents, types: { isLoading: isLoadingTypes, docTypes } }
  } = useTypedSelector(state => ({
    locale: state.whitelabel.locale,
    documents: state.documents,
  }))

  const isLoading = useMemo(() => {
    return (isLoadingDocs || isLoadingTypes)
  }, [isLoadingDocs, isLoadingTypes])

  const {
    fetchDocuments,
    addNewDocument,
    deleteDocument,
    fetchDocumentTypes,
  } = useActions()

  const [activeTab, setActiveTab] = useState("documents")

  useEffect(() => {
    fetchDocuments()
    fetchDocumentTypes()
  }, [fetchDocuments, fetchDocumentTypes])

  return (
    <>
      <SubNav title="Manage Support" breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Manage Support" }]}/>

      <div className="tabs-wrapper">
        <Nav tabs className="ml-3 mr-3">
          <NavItem className="tab-item">
            <NavLink
              className={classnames({ "active-table_tab": activeTab === "documents" })}
              onClick={() => setActiveTab("documents")}
            >
              Documents
            </NavLink>
          </NavItem>
          
          <NavItem className="tab-item">
            <NavLink
              className={classnames({ "active-table_tab": activeTab === "types" })}
              onClick={() => setActiveTab("types")}
            >
              Document Types
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="ml-3 mr-3">
          <TabPane tabId="documents">
            <Row>
              <ManageSupportModule
                locale={locale}
                isLoading={isLoading}
                documents={documents}
                docTypes={docTypes}
                addDocument={addNewDocument}
                removeDocument={deleteDocument}
              />
            </Row>
          </TabPane>
        
          <TabPane tabId="types">
            <Row>
              <ManageDocumentTypesModule
                locale={locale}
                isLoading={isLoading}
                docTypes={docTypes}
              />
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default ManageSupport;
