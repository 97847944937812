import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'
import appConfig from '../../config/appConfig'
import { UserAttributes } from '../../enums'

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri
    .replace('https://', '')
    .replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri,
  })
  return auth
}
// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool()
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () =>
  new CognitoUserPool({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
  })

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
  const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`
  return signinUri
}

const refreshToken = () => {
  return new Promise((resolve, reject) => {
    authService
      .getUser()
      .then((session) => {
        if (session.credentials) {
          const token = new CognitoRefreshToken({
            RefreshToken: session.credentials.refreshToken,
          })
          session.cognitoUser.refreshSession(token, (err, session) => {
            if (err) reject(err)
            resolve(session)
          })
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth()

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result)
      },
      onFailure: function (err) {
        reject(
          new Error('Failure parsing Cognito web response: ' + err.message),
        )
      },
    }
    auth.parseCognitoWebResponse(href)
  })
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser()

    if (!cognitoUser) {
      throw new Error('cognitoUser is null')
    }

    cognitoUser.getSession((err, result) => {
      if (err || !result) {
        reject(new Error('Failure getting Cognito session: ' + err.message))
        return
      }

      // Resolve the promise with the session credentials
      const session = {
        credentials: {
          accessToken: result.accessToken.jwtToken,
          idToken: result.idToken.jwtToken,
          refreshToken: result.refreshToken.token,
        },
        expiresIn: result.idToken.payload.exp,
        user: {
          userName: result.idToken.payload['cognito:username'],
          email: result.idToken.payload.email,
          ...result.idToken.payload,
        },
        cognitoUser,
      }
      resolve(session)
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth()
  auth.signOut()
}

const putUserAttributes = (
  cognitoUser,
  { timezone, notifications = {}, unitsOfMeasurement },
) => {
  const { receiveEmail, receiveText, threshold } = notifications
  var attributeList = []
  attributeList.push(
    new CognitoUserAttribute({
      Name: UserAttributes.ZONEINFO,
      Value: timezone,
    }),
  )
  attributeList.push(
    new CognitoUserAttribute({
      Name: UserAttributes.NOTIF_EMAIL,
      Value: `${receiveEmail}`,
    }),
  )
  attributeList.push(
    new CognitoUserAttribute({
      Name: UserAttributes.NOTIF_TEXT,
      Value: `${receiveText}`,
    }),
  )
  attributeList.push(
    new CognitoUserAttribute({
      Name: UserAttributes.NOTIF_THRESHOLD,
      Value: threshold,
    }),
  )
  attributeList.push(
    new CognitoUserAttribute({
      Name: UserAttributes.UNITS_OF_MEASUREMENT,
      Value: unitsOfMeasurement,
    }),
  )

  return new Promise((resolve, reject) => {
    cognitoUser.updateAttributes(attributeList, function (err, result) {
      if (err) {
        console.error(err.message || JSON.stringify(err))
        reject(err.message || JSON.stringify(err))
      }
      resolve(result)
    })
  })
}

export const authService = {
  putUserAttributes,
  getUser: getCognitoSession,
  getSignInUri: getCognitoSignInUri,
  parseCallbackURI: parseCognitoWebResponse,
  signOut: signOutCognitoSession,
  refreshToken: refreshToken,
  getCognitoUser: createCognitoUser,
}

export default authService
