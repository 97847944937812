import { useMemo } from 'react'
import {
  convertDataEpochToDate,
  getClearLabel,
  getCurrentEpoch,
  getIconsStatusData,
  getTimeLabelFromSeconds,
} from '../../../utils'
import { NoDataLabels } from '../../../enums'

export const useColumns = () => {
  return useMemo(() => {
    return [
      {
        label: 'Status',
        id: 'status',
        control: 'sort'
      },
      {
        label: 'Asset Name',
        id: 'name',
        isDraggable: true,
        control: 'sort'
      },
      {
        label: 'Asset Type',
        id: 'asset_type',
        isDraggable: true,
        control: 'sort',
      },
      {
        label: 'Asset Subtype',
        id: 'asset_subtype',
        isDraggable: true,
        control: 'sort',
      },
      {
        label: 'VIN',
        id: 'vin',
        isDraggable: true,
        control: 'sort',
      },
      {
        label: 'Asset Groups',
        id: 'asset_groups',
        isDraggable: true,
        control: 'sort',
      },
      {
        label: 'Entry Time',
        id: 'entry_time',
        isDraggable: true,
        control: 'sort',
      },
      {
        label: 'Dwell Time',
        id: 'dwell_time',
        isDraggable: true,
        control: 'sort',
      },
      {
        label: 'Last GPS Communication',
        id: 'timestamp',
        isDraggable: true,
        control: 'sort',
      },
    ]
  }, [])
}

export const useRows = (
  columns: Array<{ id: string; label: string }>,
  data: Array<any>,
  limit: number,
  offset: number,
  whiteLabelUrl: string,
  isSuperAdmin: boolean,
) => {
  return useMemo(() => {
    const pagedData: Array<any> = []
    const length = offset + limit

    for (let i = offset; i < length && i < data.length; i++) {
      pagedData.push(data[i])
    }

    return pagedData.map((row) => {
      const { alt, src, styles, tooltips } = getIconsStatusData(
        whiteLabelUrl,
        isSuperAdmin,
        row,
      )

      return {
        id: row.id,
        columns: columns.map((col) => {
          const cell: any = {
            columnId: col.id,
            label: row[col.id],
            type: 'link',
            link: `/details/${row.vin}?reducePoints=true`,
          }

          if (col.id === 'entry_time') {
            cell.label = convertDataEpochToDate(
              row['entry_epoch'],
              null,
              null,
              true,
            )
          } else if (col.id === 'dwell_time') {
            const dwellSeconds = getCurrentEpoch() - row['entry_epoch']

            cell.label = getTimeLabelFromSeconds(dwellSeconds)
          } else if (col.id === 'timestamp') {
            cell.label = row['epoch']
              ? convertDataEpochToDate(row['epoch'], null, null, true)
              : NoDataLabels.DASH
          } else if (col.id === 'asset_groups') {
            cell.label = row['asset_groups']
              ? row['asset_groups']
                  .map((ag: { id: number; name: string }) => {
                    return ag.name
                  })
                  .join(', ')
              : NoDataLabels.DASH
          } else if (col.id === 'status') {
            cell.type = 'icon'
            cell.label = alt
            cell.src = src
            cell.classNames = styles
            cell.min_width = '145px'
            cell.showIconTooltip = true
            cell.tooltipContents = tooltips
          } else if (col.id === 'asset_subtype') {
            cell.label = row.asset_subtype
              ? getClearLabel(row.asset_subtype)
              : NoDataLabels.DASH
          } else if (col.id === 'asset_type') {
            cell.label = getClearLabel(row.asset_type)
          }

          return cell
        }),
      }
    })
  }, [columns, data, limit, offset, isSuperAdmin, whiteLabelUrl])
}
