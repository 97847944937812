import React, { useCallback, useState } from 'react'
import { Input, FormGroup, Label, FormText } from 'reactstrap'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import placeholder from '../../assets/images/placeholder.png'
import { baseUrl } from '../../services/api/base'

import './style.scss'

const formatMessage = (bytes) => {
  if (bytes >= 1000000) {
    return `${bytes / 1000000}MB`
  }
  return `${bytes / 1000}KB`
}

const ImageUploaderItem = ({
  filename,
  locale,
  text,
  id,
  accept,
  whiteLabel,
  onUpdate,
  uploadImage,
  maxSizeInBytes = 256000,
  fallBackImage = undefined,
}) => {
  const [imageHash, setImageHash] = useState(+new Date())
  const [loading, setLoading] = useState(false)
  const [showWrongExtMessage, setShowWrongExtMessage] = useState(false)
  const [showWrongImageSizeMessage, setShowWrongImageSizeMessage] =
    useState(false)
  const whiteLabelUrl = `${baseUrl}/config/${whiteLabel}/`
  const [imgSrc, setImgSrc] = useState(
    filename && whiteLabelUrl
      ? `${whiteLabelUrl}${filename}?h=${imageHash}`
      : fallBackImage ?? placeholder,
  )
  const handleImageError = useCallback(
    (e) => {
      e.stopPropagation()
      setImgSrc(fallBackImage ?? placeholder)
    },
    [setImgSrc, fallBackImage],
  )

  const uploadImageFile = () => {
    uploadImage().then((r) => {
      setTimeout(() => {
        setImageHash(+new Date())
        setLoading(false)
      }, 2500)
    })
  }

  return (
    <div className='d-flex my-3'>
      <div className='mr-2 col-3 d-flex align-items-center justify-content-center'>
        <img
          src={imgSrc}
          alt={filename}
          className='border shadow'
          style={{
            width: 'auto',
            height: '100px',
            objectFit: 'contain',
            maxWidth: '100%',
          }}
          onError={handleImageError}
        />
      </div>
      {loading && <RivataLoader />}
      <FormGroup className='col-9'>
        <Label for='exampleFile'>{locale[id] || id}</Label>
        <Input
          type='file'
          name={filename}
          id={id}
          onChange={(e) => {
            if (accept.includes(e.target.files[0].type)) {
              const file = e.target.files[0]
              if (file.size > maxSizeInBytes) {
                e.target.value = null
                setShowWrongImageSizeMessage(true)
                return
              }

              setLoading(true)
              setShowWrongExtMessage(false)
              setShowWrongImageSizeMessage(false)

              if (file.type === 'image/jpeg') {
                //converting jpg to png using canvas
                const img = new Image()
                img.onload = function () {
                  const canvas = document.createElement('canvas')
                  canvas.width = img.width
                  canvas.height = img.height
                  const ctx = canvas.getContext('2d')
                  ctx.drawImage(img, 0, 0)
                  canvas.toBlob((blob) => {
                    const convertedFile = new File([blob], filename, {
                      type: 'image/png',
                    })
                    onUpdate(convertedFile, filename)
                    uploadImageFile()
                  })
                }

                img.src = URL.createObjectURL(file)
              } else if (
                file.type === 'image/png' ||
                file.type === 'image/svg+xml'
              ) {
                onUpdate(file, filename)
                uploadImageFile()
              } else {
                throw new Error('unsupported  file type')
              }
            } else {
              setShowWrongExtMessage(true)
            }
          }}
          onClick={(e) => (e.target.value = null)}
          accept={accept}
        />
        {showWrongExtMessage ? (
          <p className='error-message'>
            Wrong file extension please select file with extension: {accept}
          </p>
        ) : null}
        {showWrongImageSizeMessage ? (
          <p className='error-message'>
            Wrong file size please select file with maximum size of{' '}
            {formatMessage(maxSizeInBytes)}
          </p>
        ) : null}
        <FormText color='muted'>{locale[text] || text}</FormText>
      </FormGroup>
    </div>
  )
}

const ImageUploader = ({ items, title, locale, whiteLabel }) => {
  return (
    <RivataModule title={title} width={6} locale={locale}>
      {items.map((item) => (
        <ImageUploaderItem
          key={item.id}
          {...item}
          whiteLabel={whiteLabel}
          locale={locale}
        />
      ))}
    </RivataModule>
  )
}

export default ImageUploader
