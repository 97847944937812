import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
import api from '../../../services/api'
const AssetGroupsRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    if (!value || (value && value.toLowerCase() === 'all')) {
      setDisplayValue('All')
    } else {
      const fetchGrops = async () => {
        try {
          const data = await api.getAssetGroupsLimitedData(null, null, value)

          if (data && data.length > 0) {
            const val: string[] = data.map((item: any) => {
              return `${item.name}`
            })
            if (value.includes('-1')) {
              val.unshift('Assets without group')
            }
            setDisplayValue(val.join(', '))
          } else if (value.includes('-1')) {
            setDisplayValue('Assets without group')
          }
        } catch (err) {
          console.log(err)
        }
      }
      fetchGrops()
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Assets Group:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default AssetGroupsRow
