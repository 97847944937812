import React, { useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import authService from '../../services/auth'
import { useTypedSelector } from '../../hooks/useTypedSelector'

const LogoutDeletedUserModal = () => {
  const isUserDeleted = useTypedSelector((state) => state.auth.isUserDeleted)

  useEffect(() => {
    if (!isUserDeleted) return

    Object.keys(localStorage)
      .filter((x) => x.startsWith('CognitoIdentityServiceProvider'))
      .forEach((x) => localStorage.removeItem(x))
  }, [isUserDeleted])

  return (
    <Modal isOpen={isUserDeleted} centered>
      <ModalHeader>User not found</ModalHeader>

      <ModalBody>Your account was deleted. You will be logged out.</ModalBody>

      <ModalFooter>
        <Button onClick={() => authService.signOut()}>Logout</Button>
      </ModalFooter>
    </Modal>
  )
}

export default React.memo(LogoutDeletedUserModal)
