import { MenuItemType } from '../../componentsV2/Menu/MenuItem'

export const actionableWarningsSensorTypeItems: Array<MenuItemType> = [
  {
    id: 'hub_sensor',
    label: 'SmartHub',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    disabled: false,
  },
  {
    id: 'tire_sensor',
    label: 'TPMS',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    disabled: false,
  },
  {
    id: 'line_pressure',
    label: 'Line Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    disabled: false,
  },
  {
    id: 'axle_load',
    label: 'Axle Load',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    disabled: false,
  },
]
