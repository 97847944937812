import { useMemo } from 'react'
import { IGpsData } from '../../components/RivataMap/types'
import { isValidCoord } from '../../utils'
import { getElemIdxByKeyInArr, hasCriticalWarning } from './utils'
import { CriticalWarningTypes } from '../../constants/constants'

interface IuseParsedData {
  (
    data: Array<IGpsData>,
    assetInfoData: IAssetInfo,
    selectedWarning: any,
    setForceMapRerender: (shouldUpdate: boolean) => void,
    nonAcknowledgedCriticalWarningsOnly: boolean,
  ): { data: Array<IGpsData> }
}

interface IParsedData {
  data: Array<IGpsData>
}

type ClosestPoint = { data: IGpsData | null; diff: number }

export const useParsedData: IuseParsedData = (
  data,
  assetInfoData,
  selectedWarning,
  setForceMapRerender,
  nonAcknowledgedCriticalWarningsOnly,
) => {
  const parsedData: IParsedData = useMemo(() => {
    if (data.length && assetInfoData) {
      const { id, name, vin } = assetInfoData
      let hasAtleast1WarningPoint = false

      const gpsData: Array<IGpsData> = data
        .filter((l) => isValidCoord(l.latitude) && isValidCoord(l.longitude))
        .map((location, i) => {
          const { has_warning, details } = location.warning_info
          const gpsPoint = {
            ...location,
            heading: location.heading === 0 ? 360 : location.heading,
            id: i,
            asset_id: id,
            name,
            vin,
            has_critical_warning: false,
            showAsWarning: false,
          }
          if (has_warning) {
            if (
              selectedWarning &&
              selectedWarning.key &&
              getElemIdxByKeyInArr(details, 'key', selectedWarning.key) > -1
            ) {
              gpsPoint.has_critical_warning = hasCriticalWarning(
                details,
                false,
                'key',
                selectedWarning.key,
              )

              gpsPoint.showAsWarning = true
              hasAtleast1WarningPoint = true
            } else if (nonAcknowledgedCriticalWarningsOnly) {
              const isCritical = hasCriticalWarning(
                details,
                nonAcknowledgedCriticalWarningsOnly,
              )

              gpsPoint.has_critical_warning = isCritical

              if (isCritical) {
                gpsPoint.showAsWarning = true
                hasAtleast1WarningPoint = true
              }
            }
          }

          return gpsPoint
        })

      setForceMapRerender(true)

      if (!hasAtleast1WarningPoint && selectedWarning) {
        const closestPointByEpoch: ClosestPoint = {
          data: null,
          diff: selectedWarning.epoch,
        }
        gpsData.forEach((el) => {
          const diff = Math.abs(el.epoch - selectedWarning.epoch)
          if (diff < closestPointByEpoch.diff) {
            closestPointByEpoch.data = el
            closestPointByEpoch.diff = diff
          }
        })
        const idx = gpsData.findIndex(
          (el) => el.id === closestPointByEpoch.data?.id,
        )

        if (idx > -1) {
          gpsData[idx].showAsWarning = true
          gpsData[idx].has_critical_warning = CriticalWarningTypes.includes(
            selectedWarning.warning_subtype,
          )
        }
      }

      return { data: gpsData }
    }
    return { data: [] }
  }, [
    data,
    assetInfoData,
    selectedWarning,
    setForceMapRerender,
    nonAcknowledgedCriticalWarningsOnly,
  ])

  return parsedData
}
