import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col } from 'reactstrap'

import DatesDropdown from '../../components/DatesDropdown'
import RivataToggle from '../../components/RivataToggle'

import { useActions } from '../../hooks/useActions'
import { useQuery } from '../../hooks/useQuery'
import './style.scss'
import { saveGoogleAnalyticsEvent } from '../../utils/utils'

const ChartsControl = ({ width }) => {
  const history = useHistory()
  const location = useLocation()
  const params = useQuery()

  const { fetchAssetDetailsChartsData } = useActions()

  const [days, setDays] = useState(7)
  const [toggle, setToggle] = useState(null)

  const toggleItems = useMemo(() => {
    return {
      item1: { id: 0, label: 'Original Chart Data View' },
      item2: { id: 1, label: 'Reduced Chart Data View' },
    }
  }, [])

  useEffect(() => {
    setToggle(() =>
      params.get('reducePoints') ? toggleItems.item2 : toggleItems.item1,
    )
  }, [toggleItems, params])

  const toggleReducePoints = useCallback(
    (selectedItem) => {
      setToggle(selectedItem)
      fetchAssetDetailsChartsData(days)

      history.push(
        {
          pathname: location.pathname,
          search: selectedItem.id === 1 ? '?reducePoints=true' : '',
        },
        { scroll: false },
      )
    },
    [history, location, days, fetchAssetDetailsChartsData],
  )

  const onSelectDays = useCallback(
    (d) => {
      setDays(d)
      fetchAssetDetailsChartsData(d)
    },
    [fetchAssetDetailsChartsData],
  )

  useEffect(() => {
    if (days === 30) {
      saveGoogleAnalyticsEvent('30days_view_selected')
    } else if (days === 90) {
      saveGoogleAnalyticsEvent('90days_view_selected')
    }
  }, [days])

  return (
    <Col lg={width}>
      <div data-component='fleet-map' className='charts-control mt-4'>
        <div>
          <h4 className='charts-control__title'>Charts Control</h4>
        </div>

        <div className='mr-2 d-flex'>
          {days === 7 && (
            <RivataToggle
              onToggle={toggleReducePoints}
              selectedId={toggle?.id}
              {...toggleItems}
            />
          )}

          <DatesDropdown onSelect={onSelectDays} />
        </div>
      </div>
    </Col>
  )
}

export default ChartsControl
