import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import RivataTable from '../../components/RivataTable'
import RivataModule from '../../components/RivataModule'
import { useTableColumns, useTableRows } from './hooks'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { cloneDeep, debounce } from 'lodash'
import Button from '../../componentsV2/Button'
import RivataToggle from '../../components/RivataToggle'
import { makeScrollToY } from '../../utils/utils'
import ConfirmModal from '../../components/ConfirmModal'
import api from '../../services/api'
import InfoModal from '../../components/InfoModal'
import { Row } from 'reactstrap'
import { Path } from '../../enums'
import { useQuery } from '../../hooks/useQuery'
import RouteLeavingGuard from '../../components/BlockLeavingModal'
import SearchInput from '../../componentsV2/SearchInput'
import { SearchIcon } from '../../componentsV2/Icon'

const initialSelection = {
  geofence_subtype: [],
  sensor_type: [],
  geofence_groups: [],
}

const debouncer = debounce((func: () => void) => func(), 400)

const tabs = {
  assigned: 'assigned',
  unassigned: 'unassigned',
}
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'geofenceGroups.detailsTable.columns',
}

const GeofenceGroupsDetailsModule = ({ group_id }: any) => {
  const {
    auth: { whiteLabelUrl },
    geofenceGroupsDetails: { isLoading, data, limit, offset },
    locale,
  } = useTypedSelector((state) => ({
    auth: state.auth,
    geofenceGroupsDetails: state.geofenceGroups.geofenceGroupsDetails,
    locale: state.whitelabel.locale,
  }))

  const params = useQuery()

  //filters
  const [geofenceNameSearchValue, setGeofenceNameSearchValue] = useState('')

  // Sorting
  const [sortOrder, setSortOrder] = useState({
    column: 'name',
    direction: 'asc',
  })

  // Table
  const [selectedOption, setSelectedOption] = useState(
    params.get('tab') === 'unassigned'
      ? { id: tabs.unassigned }
      : { id: tabs.assigned },
  )
  const [selectedRows, setSelectedRows] = useState<Array<number>>([])
  const [selectAll, setSelectAll] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [statusMessage, setStatusMessage] = useState('')

  const searchParams = useRef<Record<string, Array<string>>>(
    cloneDeep(initialSelection),
  )

  const [filters, setFilters] = useState('')

  const actualQuery = useRef('')

  const {
    setGeofenceGroupsDetailsLimit,
    setGeofencesGroupsDetailsOffset,
    getGeofenceGroupById,
  } = useActions()

  useEffect(() => {
    setGeofencesGroupsDetailsOffset(0)
  }, [setGeofencesGroupsDetailsOffset, selectedOption])

  useEffect(() => {
    getGeofenceGroupById(group_id, limit, offset, sortOrder, filters)
    makeScrollToY(0)
    setSelectedRows([])
  }, [
    setGeofencesGroupsDetailsOffset,
    getGeofenceGroupById,
    group_id,
    limit,
    offset,
    sortOrder,
    filters,
  ])

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value

      if (!tempValue || !tempValue.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(tempValue)) {
        searchParams.current[key] = tempValue
      } else {
        searchParams.current[key] = [tempValue]
      }

      const query = new URLSearchParams()

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0]
        filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
      })

      actualQuery.current = query.toString()
      setFilters(query.toString())
      setSelectAll(false)
    },
    [],
  )

  const onPageChange = useCallback(
    (offset: number) => {
      setSelectAll(false)
      setGeofencesGroupsDetailsOffset(offset)
      makeScrollToY(0)
    },
    [setGeofencesGroupsDetailsOffset],
  )

  const onCheckRowColumn = (id: string, checked: boolean) => {
    setSelectAll(checked)

    const rows =
      selectedOption.id === tabs.assigned
        ? data.assigned_geofences.paged_data
        : data.unassigned_geofences.paged_data
    const selected = rows.map((el) => el.id)

    setSelectedRows(selected)
    if (!checked) {
      setSelectedRows([])
    }
  }

  const onCheckRowItem = (item: { id: number }) => {
    const { id } = item
    const rows =
      selectedOption.id === tabs.assigned
        ? data.assigned_geofences.paged_data
        : data.unassigned_geofences.paged_data

    if (selectedRows.includes(id)) {
      const updatedSelected = selectedRows.filter((el) => el !== id)
      setSelectedRows(updatedSelected)
    } else if (selectAll) {
      const selected = rows.map((el) => el.id)
      setSelectedRows(selected)
    } else {
      let array = [...selectedRows, id]
      setSelectedRows(array)
    }
    setSelectAll(false)
  }

  const columns = useTableColumns(selectAll)
  const rows = useTableRows(
    selectedOption.id === tabs.assigned
      ? data?.assigned_geofences
      : data?.unassigned_geofences,
    selectedRows,
    selectAll,
    whiteLabelUrl,
  )

  const showModalHandler = () => {
    setShowModal(selectedRows.length > 0)
  }

  const saveChangesHandler = () => {
    const action = selectedOption.id === tabs.assigned ? 'unassign' : 'assign'

    api
      .manageGeofenceGroupGeofences(group_id, selectedRows, action)
      .then(() => {
        setSelectAll(false)
        setSelectedRows([])
        setGeofencesGroupsDetailsOffset(0)
        setShowModal(false)
        getGeofenceGroupById(
          group_id,
          limit,
          offset,
          sortOrder,
          actualQuery.current,
        )
      })
      .catch((err) => {
        setStatusMessage(err ? err.message : '')
      })
  }

  const handleSaveCancel = () => {
    setSelectedRows([])
    setSelectAll(false)
  }

  const handleLimitChange = useCallback(
    (limit: number) => {
      setSelectAll(false)
      setGeofencesGroupsDetailsOffset(0)
      setGeofenceGroupsDetailsLimit(limit)
      makeScrollToY(0)
    },
    [setGeofenceGroupsDetailsLimit, setGeofencesGroupsDetailsOffset],
  )

  const onSearchByGeofenceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setGeofenceNameSearchValue(value)
    debouncer(() => updateFilters('name_like', value))
  }
  const handleFiltersReset = () => {
    setSelectAll(false)
    setSelectedRows([])
    setGeofenceNameSearchValue('')
    if (actualQuery.current === '') return

    actualQuery.current = ''
    setFilters('')
    searchParams.current = cloneDeep(initialSelection)
  }
  return (
    <>
      <Row>
        <RivataModule
          title={data?.name}
          locale={locale}
          marginTop={0}
          error={undefined}
          filters={
            <RivataToggle
              item1={{
                id: 'assigned',
                label: `Show geofences in group`,
                isDisabled:
                  selectedRows.length > 0 &&
                  selectedOption.id === tabs.unassigned,
              }}
              item2={{
                id: 'unassigned',
                label: `Show available geofences`,
                isDisabled:
                  selectedRows.length > 0 &&
                  selectedOption.id === tabs.assigned,
              }}
              selectedId={selectedOption.id}
              onToggle={(e) => {
                if (selectedRows.length === 0) {
                  setSelectedOption({ id: e.id.toString() })
                }
              }}
            />
          }
        >
          <div className='d-flex justify-content-between flex-wrap mb-2'>
            <SearchInput
              placeholder='Search by Geofence Name'
              icon={<SearchIcon width={20} height={20} color='black' />}
              value={geofenceNameSearchValue}
              onChange={onSearchByGeofenceName}
              wrapperClassName='mb-2'
            />
            <div>
              <Button
                type='reset'
                className='btn btn-clear'
                onClick={handleFiltersReset}
              >
                Clear
              </Button>
            </div>
          </div>
          <hr />
          <>
            <RivataTable
              columns={columns}
              rows={rows}
              onDelete={undefined}
              isLoading={isLoading}
              onEdit={undefined}
              editDisabled={undefined}
              deleteDisabled={false}
              setSortOptions={setSortOrder}
              onCustomAction={undefined}
              onCheckRowColumn={onCheckRowColumn}
              onCheckRowItem={onCheckRowItem}
              totalCount={
                selectedOption.id === tabs.assigned
                  ? data?.assigned_geofences?.total_count
                  : data?.unassigned_geofences?.total_count
              }
              showPagination={true}
              // @ts-ignore component expect undefined cause of default value
              onSelectLimit={handleLimitChange}
              pageLimit={limit}
              isShowingLimit={true}
              page={offset / limit}
              // @ts-ignore component expect undefined cause of default value
              onPageChange={onPageChange}
              customNoDataText={
                selectedOption.id === tabs.assigned
                  ? 'There are currently no geofences in this group. Select available geofences tab to add geofences to the group.'
                  : ''
              }
              // @ts-ignore component expect undefined cause of default value
              columnsSelectorProps={columnsSelectorProps}
            >
              {/* @ts-ignore component expect undefined cause of default value */}
              <div className='d-flex flex-wrap justify-content-end align-items-center mt-3 mb-4'>
                <p className='mb-0 mr-2'>{selectedRows.length + ' selected'}</p>
                <Button
                  type='button'
                  className='btn btn-clear'
                  onClick={handleSaveCancel}
                >
                  Cancel
                </Button>

                <Button
                  type='button'
                  className='btn btn-clear'
                  onClick={showModalHandler}
                >
                  {selectedOption.id !== tabs.assigned
                    ? 'Add selected'
                    : 'Remove selected'}
                </Button>
              </div>
            </RivataTable>
          </>

          <hr />

          <Link to={Path.GeofenceGroups}>
            <Button type='button' className='btn btn-clear m-0'>
              Return To The Groups List
            </Button>
          </Link>
        </RivataModule>
      </Row>
      <ConfirmModal
        open={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        modalButtons={[
          {
            id: 1,
            label: 'Save Changes',
            color: 'success',
            onClick: saveChangesHandler,
          },
        ]}
      >
        <div>
          <h5>Confirm Changes</h5>
          <p>
            {selectedOption.id !== tabs.assigned ? 'Add' : 'Remove'}{' '}
            {selectedRows.length} geofence(s){' '}
            {selectedOption.id !== tabs.assigned ? 'to' : 'from'} group?
          </p>
        </div>
      </ConfirmModal>
      <InfoModal
        header={'Saving info'}
        message={statusMessage}
        open={!!statusMessage}
        onConfirm={() => {
          setStatusMessage('')
        }}
      />

      <RouteLeavingGuard when={!!selectedRows.length} />
    </>
  )
}

export default GeofenceGroupsDetailsModule
