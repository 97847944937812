import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'

import { assetTypeItems } from './items'

interface Props {
    onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
    className?: string
    disabled?: boolean
}

const AssetTypeFilter = forwardRef(({
    onDropdownItemSelect,
    className,
    disabled
}: Props, ref) => {
    const [selectedItems, setSelectedItems] = useState([] as Array<MenuItemType>)
    const [label, setLabel] = useState('Asset Type')
    const [allItems, setAllItems] = useState(assetTypeItems)
    const isNewSelectedValueIds = useRef(true)

    const onGroupItemSelect = useCallback((id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(el => el.checked && el.id.indexOf("||") === -1 && !["trailer", "tractor"].includes(el.id))

        const modifiedArr: Array<MenuItemType> = []

        for (let i = 0; i < checked.length; i++) {
            const el = checked[i];
            const parent_id = assetTypeItems.find(c => c.id === el.id)?.parentId;
            modifiedArr.push({ ...el, id: `${parent_id}|${el.id}` })
        }

        if (onDropdownItemSelect)
            onDropdownItemSelect(id, modifiedArr)
        setSelectedItems(checked)
    }, [onDropdownItemSelect])

    useImperativeHandle(ref, () => ({
        clearSelection() {
            isNewSelectedValueIds.current = false
            setSelectedItems([])
            setAllItems(allItems.map(c => {
                return {
                    ...c,
                    checked: false
                }
            }))
        }
    }))

    useEffect(() => {
        setLabel('Asset Type' + (selectedItems && selectedItems?.length > 0 ? `: ${selectedItems?.length} selected` : ""))
    }, [selectedItems])

    return (
        <DropdownItemSelector
            id="asset_subtype"
            filterClassName={`mr-2 ${className ?? ""}`}
            filterBtnLabel={label}
            items={allItems}
            onItemsChange={onGroupItemSelect}
            disabled={disabled ? disabled : false}
            clearSelectionOnReset={true}
        />
    )
})

export default React.memo(AssetTypeFilter)
