import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'

import { assetTypeItems } from './items'

interface Props {
    onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
    className?: string
    disabled?: boolean
}

const RoleFilter = forwardRef(({
    onDropdownItemSelect,
    className,
    disabled
}: Props, ref) => {
    const [selectedItems, setSelectedItems] = useState([] as Array<MenuItemType>)
    const [label, setLabel] = useState('User Role')
    const isNewSelectedValueIds = useRef(true)
    const [allItems, setAllItems] = useState(assetTypeItems);

    const onGroupItemSelect = useCallback((id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(el => el.checked && el.id.indexOf("||") === -1)
        if (onDropdownItemSelect)
            onDropdownItemSelect(id, checked)

        setSelectedItems(checked)
    }, [onDropdownItemSelect])

    useImperativeHandle(ref, () => ({
        clearSelection() {
            isNewSelectedValueIds.current = false
            setSelectedItems([])
            setAllItems(allItems.map(c => {
                return {
                    ...c,
                    checked: false
                }
            }))
        }
    }))

    useEffect(() => {
        setLabel('User Role' + (selectedItems && selectedItems?.length > 0 ? `: ${selectedItems?.length} selected` : ""))
    }, [selectedItems])

    return (
        <DropdownItemSelector
            id="user_role"
            filterClassName={`mr-2 ${className ?? ""}`}
            filterBtnLabel={label}
            items={allItems}
            onItemsChange={onGroupItemSelect}
            disabled={disabled ? disabled : false}
            clearSelectionOnReset={true}
        />
    )
})

export default React.memo(RoleFilter)
