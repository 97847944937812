import { MenuItemType } from './MenuItem'
import {
  getChildrenByParentId,
  handleCheckboxSelection,
  handleRadioSelection,
  updateChildren,
  uppdateParentItem,
} from './utils'

class MenuItemsHelper {
  private constructor() {}

  public static copyDeep(items: MenuItemType[]): MenuItemType[] {
    return [...items].map((item) => ({ ...item }))
  }

  public static handleItemChange(
    itemsRef: MenuItemType[],
    item: MenuItemType,
  ): void {
    if (item.readOnly) return

    item = { ...item }

    const newDropdownData = itemsRef
    const itemChildren = getChildrenByParentId(newDropdownData, item.id)
    const itemParent = item.parentId
      ? newDropdownData.find((el) => el.id === item.parentId)
      : null

    if (item.type === 'radio') {
      const currentLevelItems = getChildrenByParentId(
        newDropdownData,
        itemParent?.id ?? null,
      )
      handleRadioSelection(newDropdownData, currentLevelItems, item)
    } else {
      handleCheckboxSelection(newDropdownData, itemChildren, item)
    }

    if (itemParent) {
      uppdateParentItem(
        newDropdownData,
        getChildrenByParentId(newDropdownData, itemParent.id),
        itemParent,
      )
    }
    if (itemChildren.length > 0) {
      updateChildren(newDropdownData, itemChildren, item)
    }
  }

  public static handleSubMenuToggle(
    itemsRef: MenuItemType[],
    item: MenuItemType,
  ) {
    item = { ...item }

    const matchedId = itemsRef.findIndex((i) => i.id === item.id)

    if (matchedId > -1) {
      item.subMenuExpanded = !item.subMenuExpanded
      itemsRef[matchedId] = item
    }
  }

  public static resetSelection(itemsRef: MenuItemType[]) {
    itemsRef.forEach((item) => (item.checked = false))
  }
}

export default MenuItemsHelper
