import {
  convertDataEpochToDate,
  DATE_FORMAT,
  kgToLbs,
  kmhToMph,
  psiToBar,
} from '../../utils'
import { chartDatasetSort } from '../../utils/chartUtils'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

export const composeChartData = (
  data,
  calibrationsCount,
  unitsOfMeasurementConfig,
) => {
  const parsedData = {
    datasets: [
      {
        label: 'Air Suspension',
        data: [],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
      },
    ],
  }

  let pressureUnits = 'psi'
  let weightUnits = 'kg'
  let speedUnits = 'kmh'

  weightUnits =
    unitsOfMeasurementConfig.weight === UnitsOfMeasurementConfig.weight.lb
      ? 'lbs'
      : 'kg'
  speedUnits =
    unitsOfMeasurementConfig.speed === UnitsOfMeasurementConfig.speed.mph
      ? 'mph'
      : 'kmh'
  pressureUnits =
    unitsOfMeasurementConfig.pressure === UnitsOfMeasurementConfig.pressure.bar
      ? 'bar'
      : 'psi'

  data.map((item) => {
    let pressure = item.pressure
    let weight = item.weight
    let speed = item.speed

    weight =
      unitsOfMeasurementConfig.weight === UnitsOfMeasurementConfig.weight.lb
        ? kgToLbs(weight)
        : weight
    speed =
      unitsOfMeasurementConfig.speed === UnitsOfMeasurementConfig.speed.mph
        ? kmhToMph(speed)
        : speed
    pressure =
      unitsOfMeasurementConfig.pressure ===
      UnitsOfMeasurementConfig.pressure.psi
        ? pressure.toFixed(0)
        : psiToBar(pressure).toFixed(2)

    const accuracy =
      calibrationsCount < 5 ? 'Low' : calibrationsCount > 10 ? 'High' : 'Medium'
    const x = convertDataEpochToDate(item.bucket_epoch, null, null, false)

    return parsedData.datasets[0].data.push({
      x,
      y: weight,
      displayBeforeBody: `Pressure: ${pressure} ${pressureUnits}`,
      displayBeforeLabel: `Accuracy: ${accuracy}`,
      displayValue: `Weight: ${weight.toFixed(2)} ${weightUnits}`,
      displayAfterLabel: `Speed: ${speed.toFixed(2)} ${speedUnits}`,
      displayFooter: `Date: ${x.format(DATE_FORMAT)}`,
    })
  })

  // sorting for sequential connection between line dots
  chartDatasetSort(parsedData.datasets[0].data)

  return parsedData
}

export const composeAnnotations = (id) => {
  const annotations = {}

  annotations.bg = {
    type: 'box',
    id: `box-plot-top-${id}`,
    xScaleID: 'x',
    yScaleID: 'y',
    yMax: Infinity,
    yMin: -Infinity,
    xMin: -Infinity,
    xMax: Infinity,
    backgroundColor: '#fff',
    drawTime: 'beforeDatasetsDraw',
  }
  return annotations
}

export const composeDefaultChartData = (data, unitsOfMeasurementConfig) => {
  const parsedData = {
    datasets: [
      {
        label: 'Air Suspension',
        data: [],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
      },
    ],
  }

  let pressureUnits =
    unitsOfMeasurementConfig.pressure === UnitsOfMeasurementConfig.pressure.psi
      ? 'psi'
      : 'bar'

  data.map((item) => {
    let pressure = item.pressure

    if (
      unitsOfMeasurementConfig.pressure ===
      UnitsOfMeasurementConfig.pressure.psi
    ) {
      pressure = pressure.toFixed(0)
    } else {
      pressure = psiToBar(pressure).toFixed(2)
    }

    const x = convertDataEpochToDate(item.bucket_epoch, null, null, false)

    return parsedData.datasets[0].data.push({
      x,
      y: pressure,
      displayValue: `Pressure: ${pressure} ${pressureUnits}`,
      displayFooter: `Date: ${x.format(DATE_FORMAT)}`,
    })
  })

  // sorting for sequential connection between line dots
  chartDatasetSort(parsedData.datasets[0].data)

  return parsedData
}
