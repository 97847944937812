import React, { useState, useEffect, useCallback, useRef } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import DeleteModal from '../../components/DeleteModal'
import UpdateUserModal from './modals/UpdateUserModal'
import UserCreateButton from './components/UserCreateButton'
import InfoModal from '../../components/InfoModal'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { useTableRows, useColumns } from './hooks'
import { ensureUserHaveAccess } from './utils'
import { fixInValidPhoneNumber } from '../../utils'
import { getStatusObj, makeScrollToElementWithOffset } from '../../utils/utils'

import { IUserFormValues, IUserComponentProps } from './types'

import './style.scss'
import UsernameEmailFilter from '../../componentsV2/UsernameEmailFilter'
import { debounce } from 'lodash'
import FirstNameLastNameFilter from '../../componentsV2/FirstNameLastNameFilter'
import RoleFilter from '../../componentsV2/RoleFilter'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import Button from '../../componentsV2/Button'

// Fixes input values
const fixInValid = (value: string) => (!value ? '' : value)

const debouncer = debounce((func: () => void) => func(), 400)
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'provision.usersTable.columns',
}
const Users: React.FC<IUserComponentProps> = ({ width, locale = {} }) => {
  const {
    loading,
    data,
    totalCount,
    pageLimit,
    pageOffset,
    selectedCustomersList,
    customersList,
    error,
    auth: {
      isSuperAdmin,
      isAdmin,
      preferences: { customer_ids },
      user: { userName },
    },
  } = useTypedSelector((state) => ({
    ...state.provision.users,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    customersList: state.common.customers.data,
    auth: state.auth,
  }))
  const {
    setUsersPageSortOptions,
    addUser,
    deleteUser,
    updateUser,
    setUsersPageOffset,
    setUsersPageLimit,
    setUsersFlter,
    setUsersPageError,
  } = useActions()

  const scrollTarget = useRef(null)
  const usernameEmailInputRef = useRef<any>(null)
  const firstNameLastNameInputRef = useRef<any>(null)
  const userRoleInputRef = useRef<any>(null)

  const [modalUpdate, setModalUpdate] = useState<IUserFormValues | null>(null)
  const [modalDelete, setModalDelete] = useState<IUser | null>(null)
  const [infoModalMessage, setInfoModalMessage] = useState<string>('')
  const searchParams = useRef<Record<string, Array<string>>>({})

  const actualQuery = useRef('')

  // table hooks
  const columns = useColumns(locale)
  const rows = useTableRows(data, setInfoModalMessage)

  const editText =
    selectedCustomersList.length !== 1 ? 'Select Customer First' : 'Edit User'

  useEffect(() => {
    if (!selectedCustomersList.length) return
    // will trigger fetch users
    setUsersPageOffset(0)
  }, [setUsersPageOffset, selectedCustomersList])

  const handleClickEditUser = useCallback(
    (rowData: IUser) => {
      const user = data.find((el) =>
        el.username === rowData.username ? true : false,
      )

      if (!user) return

      const idsList = user.customers.map(
        (c: { id: number; name: string }) => c.id,
      )
      const role = rowData.roles ? rowData.roles[0].name : ''

      setModalUpdate({
        first_name: fixInValid(rowData.name),
        last_name: fixInValid(rowData.family_name),
        // if change phone number then ask about notifications
        phone_number: fixInValidPhoneNumber(rowData.phone_number),
        timezone: fixInValid(rowData.timezone),
        role: fixInValid(role),
        customerIds: idsList,
        // values below not update-able
        email_address: fixInValid(rowData.email),
        username: fixInValid(rowData.username),
        refCustomers: user.customers,
      })
    },
    [data],
  )

  const handlePageChange = useCallback(
    (offset: number) => {
      setUsersPageOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -300)
    },
    [setUsersPageOffset],
  )

  const handleLimitChange = useCallback(
    (limit: number) => {
      setUsersPageLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -300)
    },
    [setUsersPageLimit],
  )

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value

      if (!tempValue || !tempValue.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(tempValue)) {
        searchParams.current[key] = tempValue
      } else {
        searchParams.current[key] = [tempValue]
      }

      const query = new URLSearchParams()

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0]
        filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
      })

      actualQuery.current = query.toString()
      setUsersFlter(query.toString())
    },
    [setUsersFlter],
  )

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value))
  }

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  const handleFiltersReset = () => {
    if (actualQuery.current === '') return

    actualQuery.current = ''

    searchParams.current = {}

    usernameEmailInputRef?.current?.clearValue()
    userRoleInputRef?.current?.clearSelection()
    firstNameLastNameInputRef?.current?.clearValue()

    setUsersFlter('')
  }

  return (
    <RivataModule
      title='Users'
      width={width}
      locale={locale}
      marginTop={0}
      filters={
        <UserCreateButton
          locale={locale}
          isSuperAdmin={isSuperAdmin}
          isAdmin={isAdmin}
          selectedCustomersList={selectedCustomersList}
          customersList={customersList}
          accessedCustomerIds={customer_ids}
          addUser={addUser}
        />
      }
    >
      <div className='d-flex justify-content-between flex-wrap mb-2'>
        <UsernameEmailFilter
          onChange={onSearchByStringValue}
          ref={usernameEmailInputRef}
        />
        <FirstNameLastNameFilter
          onChange={onSearchByStringValue}
          ref={firstNameLastNameInputRef}
        />
      </div>
      <div className='d-flex justify-content-between flex-wrap mb-2'>
        <RoleFilter
          onDropdownItemSelect={onDropdownItemSelect}
          ref={userRoleInputRef}
        />
        <div>
          <Button
            type='reset'
            className='btn btn-clear'
            onClick={handleFiltersReset}
          >
            Clear
          </Button>
        </div>
      </div>
      <InfoModal
        open={!!infoModalMessage}
        header={`Temporary password info`}
        message={infoModalMessage}
        onConfirm={() => setInfoModalMessage('')}
      />
      {modalDelete && (
        <>
          {(ensureUserHaveAccess(customer_ids, modalDelete.customers) &&
            modalDelete.username !== userName) ||
          isSuperAdmin ? (
            <DeleteModal
              open={true}
              disabled={false}
              header={`Delete user ${modalDelete.username}?`}
              message={'This cannot be undone!'}
              onDelete={() => {
                deleteUser(modalDelete.username)
                setModalDelete(null)
              }}
              onCancel={() => setModalDelete(null)}
              locale={locale}
            />
          ) : modalDelete.username === userName ? (
            <InfoModal
              open={true}
              header={'This can not be done!'}
              message={'You cannot delete yourself!'}
              onConfirm={() => setModalDelete(null)}
            />
          ) : (
            <InfoModal
              open={true}
              header={'This can not be done!'}
              message={`User "${modalDelete.username}" has references to other customers which you don't have access to. To delete this user you must contact your Admin!`}
              onConfirm={() => setModalDelete(null)}
            />
          )}
        </>
      )}
      {modalUpdate && (
        <UpdateUserModal
          locale={locale}
          open={!!modalUpdate}
          formValues={modalUpdate}
          selectedCustomersList={selectedCustomersList}
          setFormValues={setModalUpdate}
          validate={null}
          isSuperAdmin={isSuperAdmin}
          isAdmin={isAdmin}
          userCustomerIds={customer_ids}
          onClose={() => setModalUpdate(null)}
          resetFormValues={() => setModalUpdate(null)}
          updateUser={updateUser}
        />
      )}
      {error.statusCode <= 201 && error.statusCode !== 0 && (
        <InfoModal
          open={true}
          header='Users Info'
          message={error.message}
          onConfirm={() => setUsersPageError(getStatusObj())}
        />
      )}

      <div ref={scrollTarget}>
        <RivataTable
          columns={columns}
          rows={rows}
          isLoading={loading}
          editDisabled={selectedCustomersList.length !== 1}
          setSortOptions={setUsersPageSortOptions}
          deleteDisabled={false}
          showPagination={true}
          isShowingLimit={true}
          totalCount={totalCount}
          // @ts-ignore component expect undefined cause of default value
          onPageChange={handlePageChange}
          pageLimit={pageLimit}
          page={pageOffset / pageLimit}
          // @ts-ignore component expect undefined cause of default value
          onSelectLimit={handleLimitChange}
          onEdit={handleClickEditUser}
          onDelete={(data: IUser) => setModalDelete(data)}
          onCustomAction={undefined}
          deleteTooltipText='Delete User'
          editTooltipText={editText}
          // @ts-ignore component expect undefined cause of default value
          columnsSelectorProps={columnsSelectorProps}
        />
      </div>
    </RivataModule>
  )
}

export default Users
