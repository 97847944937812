import { combineReducers, Action } from 'redux'
import settings from './settings/reducer'
import auth from './auth/reducer'
import dash from './dashboard/reducer'
import details from './details/reducer'
import whitelabel from './whitelabel/reducer'
import whitelabelAdmin from './whitelabelAdmin/reducer'
import reports from './reports/reducer'
import scheduledReports from './scheduledReports/reducer'
import tpmsProfiles from './tpmsProfiles/reducer'
import provision from './provision/reducer'
import common from './common/reducer'
import tresholds from './tresholds/reducer'
import assetAdminInfo from './assetAdminInfo/reducer'
import axleLoadCalibration from './axleLoadCalibration/reducer'
import documents from './documents/reducer'
import tagLocations from './tagLocations/reducer'
import geofences from './geofences/reducer'
import getAdminLogs from './adminLogs/reducer'
import manageGeofences from './manageGeofences/reducer'
import cargoTags from './cargoTags/reducer'
import manageNotifications from './manageNotifications/reducer'
import assetDetails from './assetDetails/reducer'
import assetGroups from './assetGroups/reducer'
import reportsHistory from './reportsHistory/reducer'
import assetBulkUpload from './assetBulkUpload/reducer'
import geofenceGroups from './geofenceGroups/reducer'
import actionableItems from './actionableItems/reducer'

const reducers = combineReducers({
  settings,
  auth,
  dash,
  assetDetails,
  details, // will be replaced by assetDetails reducer
  whitelabel,
  whitelabelAdmin,
  reports,
  scheduledReports,
  tpmsProfiles,
  provision,
  common,
  tresholds,
  assetAdminInfo,
  axleLoadCalibration,
  lastAction,
  documents,
  tagLocations,
  getAdminLogs,
  geofences,
  manageGeofences,
  cargoTags,
  manageNotifications,
  assetGroups,
  reportsHistory,
  assetBulkUpload,
  geofenceGroups,
  actionableItems,
})

function lastAction(state = null, action: Action) {
  return action
}

export default reducers

export type RootState = ReturnType<typeof reducers>
