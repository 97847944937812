import { GeofenceGroupsActions, GeofenceGroupsReducer, GeofenceGroupsTypes } from "./types";

const status = { ok: true, statusCode: 0, message: "" }

const initialState: GeofenceGroupsReducer = {
    geofenceGroups: {
        isLoading: false,
        data: [],
        status,
        limit: 30,
        offset: 0,
        totalCount: 0,
        limitedData: [],
        unassignedGeofencesCount: 0
    },
    geofenceGroupsDetails: {
        isLoading: false,
        data: { id: undefined, name: undefined, assigned_geofences: { paged_data: [], total_count: 0 }, unassigned_geofences: { paged_data: [], total_count: 0 } },
        limit: 30,
        offset: 0,
        status,
        sortOptions: { column: "name", direction: "asc" }
    }
}

const geofenceGroups = (state = initialState, action: GeofenceGroupsActions): GeofenceGroupsReducer => {
    switch (action.type) {
        case GeofenceGroupsTypes.LOADING_GEOFENCE_GROUPS:
            return { ...state, geofenceGroups: { ...state.geofenceGroups, isLoading: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS:
            return { ...state, geofenceGroups: { ...state.geofenceGroups, data: action.payload.paged_data, totalCount: action.payload.total_count, unassignedGeofencesCount: action.payload.geofences_count_without_groups } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_STATUS:
            return { ...state, geofenceGroups: { ...state.geofenceGroups, status: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_OFFSET:
            return { ...state, geofenceGroups: { ...state.geofenceGroups, offset: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMIT:
            return { ...state, geofenceGroups: { ...state.geofenceGroups, limit: action.payload } }
        case GeofenceGroupsTypes.LOADING_GEOFENCE_GROUPS_DETAILS:
            return { ...state, geofenceGroupsDetails: { ...state.geofenceGroupsDetails, isLoading: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_DETAILS:
            return { ...state, geofenceGroupsDetails: { ...state.geofenceGroupsDetails, data: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_DETAILS_STATUS:
            return { ...state, geofenceGroupsDetails: { ...state.geofenceGroupsDetails, status: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCE_GROUPS_LIMITED_DATA:
            return { ...state, geofenceGroups: { ...state.geofenceGroups, limitedData: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_OFFSET:
            return { ...state, geofenceGroupsDetails: { ...state.geofenceGroupsDetails, offset: action.payload } }
        case GeofenceGroupsTypes.SET_GEOFENCES_GROUPS_DETAILS_LIMIT:
            return { ...state, geofenceGroupsDetails: { ...state.geofenceGroupsDetails, limit: action.payload } }
        default:
            return state
    }
}

export default geofenceGroups
