import { useEffect, useState } from 'react'
import {
  NoDataLabels,
} from '../../enums'

export const useTableColumns = (selectAll) => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const columns = [
      {
        label: 'All Visible',
        id: 'all',
        control: 'checkbox',
        checked: selectAll
      },
      {
        label: 'Location Name',
        id: 'name',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Groups',
        id: 'groups',
        control: 'sort',
        isDraggable: true,
      },
    ]

    setColumns(columns)
  }, [selectAll])

  return columns
}

export const useTableRows = (data, selectedRows, selectAll, whiteLabelUrl) => {
  const [rows, setRows] = useState([])

  useEffect(() => {

    const d = data.paged_data.map((row) => {

      return {
        columns: [
          {
            columnId: 'all',
            control: 'checkbox',
            checked: selectAll
              ? true
              : selectedRows.indexOf(row.id) > -1
                ? true
                : false,
            id: row.id,
            width: '100px',
          },
          {
            id: row.id,
            type: 'text',
            label: row.name,
            columnId: 'name',
            isDraggable: true,
          },
          {
            id: row.id,
            type: 'text',
            label: row.groups || NoDataLabels.DASH,
            columnId: 'groups',
            isDraggable: true,
          },
        ],
        id: row.id,
        selectedRow: selectedRows.includes(row.id) ? true : false,
      }
    })
    setRows(d)
  }, [data, selectAll, selectedRows, whiteLabelUrl])

  return rows
}
