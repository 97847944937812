import {
  IAssetBulkUploadReducer,
  AssetBulkUploadActionTypes,
  AssetBulkUploadActionsType,
} from './types'

const initialState: IAssetBulkUploadReducer = {
  uploadingAssetsCsv: false,
  history: {
    data: [],
    isLoading: false,
    limit: 30,
    offset: 0,
    totalCount: 0,
    sortOptions: {
      column: 'end_dt',
      direction: 'desc',
    },
  },
}
const assetBulkUpload = (
  state = initialState,
  actions: AssetBulkUploadActionsType,
): any => {
  switch (actions.type) {
    case AssetBulkUploadActionTypes.UPLOADING_ASSETS_BULK_UPLOAD_FILE:
      return {
        ...state,
        uploadingAssetsCsv: actions.payload,
      }
    case AssetBulkUploadActionTypes.LOADING_BULK_UPLOAD_HISTORY:
      return {
        ...state,
        history: { ...state.history, isLoading: actions.payload },
      }
    case AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY:
      return {
        ...state,
        history: { ...state.history, data: actions.payload },
      }
    case AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_LIMIT:
      return {
        ...state,
        history: { ...state.history, limit: actions.payload },
      }
    case AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_OFFSET:
      return {
        ...state,
        history: { ...state.history, offset: actions.payload },
      }
    case AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_TOTAL_COUNT:
      return {
        ...state,
        history: { ...state.history, totalCount: actions.payload },
      }
    case AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_SORT_OPTIONS:
      return {
        ...state,
        history: { ...state.history, sortOptions: actions.payload },
      }
    default:
      return state
  }
}

export default assetBulkUpload
