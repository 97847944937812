import React from 'react'
import { Redirect } from 'react-router-dom'
const Logout = () => {
  const redirectToAuthUrl = localStorage.getItem('login_for')
    ? `/auth/${localStorage.getItem('login_for')}`
    : '/authentication/login'
  return <Redirect to={redirectToAuthUrl} />
}

export default Logout
