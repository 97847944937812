import { useEffect, useState } from 'react'
import { IColumn, IRow } from '../RivataGrid'
import {
  IActionColumnConfig,
  composeDynamicActionsFormatter,
} from '../RivataGrid/cellFormatter'
import { getClearLabel } from '../../utils'
import { NotificationSystemProfileNames } from '../../constants/constants'

interface IuseColumns {
  (
    onUnsubscribe: (id: number) => void,
    onSubscribe: (id: number) => void,
  ): Array<IColumn>
}

export const useColumns: IuseColumns = (onUnsubscribe, onSubscribe) => {
  const [columns, setColumns] = useState<Array<IColumn>>([])

  useEffect(() => {
    const formatterConfigUnsubcribe: IActionColumnConfig = {
      onDelete: (id) => {
        onUnsubscribe(id)
      },
      tooltipTexts: {
        deleteTooltipText: 'Unsubscribe',
      },
    }

    const formatterConfigSubscribe: IActionColumnConfig = {
      onCustomAction1: (id) => {
        onSubscribe(id)
      },
      customAction1Icon: undefined,
      tooltipTexts: {
        customAction1TooltipText: 'Subscribe',
      },
    }

    const colsList: Array<IColumn> = [
      {
        name: 'Actions',
        key: 'actions',
        minWidth: 100,
        width: 100,
        renderCell: ({ row }: any) => {
          if (row.subscribed === 'yes') {
            return composeDynamicActionsFormatter({
              ...formatterConfigUnsubcribe,
              cellData: row,
            })
          }
          return composeDynamicActionsFormatter({
            ...formatterConfigSubscribe,
            cellData: row,
          })
        },
      },
      { name: 'Name', key: 'name', minWidth: 100, width: '25%' },
      {
        name: 'Email/SMS',
        key: 'notificationType',
        minWidth: 100,
        width: '15%',
      },
      {
        name: 'Warning Events',
        key: 'warningEvents',
        minWidth: 100,
        width: '45%',
      },
      { name: 'Subscribed', key: 'subscribed', minWidth: 100, width: '15%' },
    ]

    setColumns(colsList)
  }, [onUnsubscribe, onSubscribe])

  return columns
}

interface IuseRows {
  (data: Array<any>): Array<IRow>
}

export const useRows: IuseRows = (data) => {
  const [rows, setRows] = useState<Array<IRow>>([])

  useEffect(() => {
    const rowsList = data?.map((row) => {
      const { sms, email } = row.alert_notification_types
      let notificationType = ''
      let warningEvents: Array<string> = []

      if (email && sms) notificationType = 'Email & SMS'
      else if (email) notificationType = 'Email'
      else if (sms) notificationType = 'SMS'

      const warningKeys = Object.keys(row.profile)

      warningKeys.forEach((key) => {
        const warningTypes = row.profile[key]

        if (warningTypes.length)
          warningEvents.push(
            `${getClearLabel(key)} - ${warningTypes
              .map((w: string) => getClearLabel(w))
              .join(', ')}`,
          )
      })

      return {
        id: row.id,
        type: 'MASTER', // DETAIL   MASTER
        selected: false,
        name: row.name.includes('system_profile_')
          ? NotificationSystemProfileNames[row.name]
          : row.name,
        notificationType,
        warningEvents: warningEvents.join('; '),
        expanded: false,
        subscribed: row.subscribed ? 'yes' : 'no',
        actions: '',
      }
    })

    setRows(rowsList)
  }, [data])

  return rows
}
