import {
  convertDataEpochToDate,
  getClearLabel,
  getCurrentEpoch,
  getMileage,
} from '../../../utils'
import { IColumn, IRow } from '../../../components/RivataGrid'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'

export const distanceReportColumns = (
  columns: Array<string>,
): Array<IColumn> => {
  return columns.map((col, id) => {
    if (col === 'vin') {
      return { key: col, name: col.toUpperCase(), minWidth: 90, width: 200 }
    }

    if (col === 'timezone') {
      return { key: col, name: getClearLabel(col), minWidth: 90, width: 90 }
    }

    if (id === columns.length - 1) {
      return { key: col, name: getClearLabel(col), minWidth: 200 }
    }

    return { key: col, name: getClearLabel(col), minWidth: 90, width: 200 }
  })
}

export const distanceReportRows = (
  rows: Array<IRow>,
  unitsOfMeasurementConfig: any,
) => {
  const emptyCellLabel = '---'

  return rows.map((row) => {
    if (!row.parent_customer_name) row.parent_customer_name = emptyCellLabel

    row.period_distance_traveled = getMileage(
      row.period_distance_traveled,
      unitsOfMeasurementConfig,
    ).toFixed(2)
    row.timezone = convertDataEpochToDate(
      getCurrentEpoch(),
      null,
      null,
      false,
    ).format('z')
    row.report_start_date = row.report_start_date
      ? convertDataEpochToDate(+row.report_start_date, null, null, true)
      : emptyCellLabel
    row.report_end_date = row.report_end_date
      ? convertDataEpochToDate(+row.report_end_date, null, null, true)
      : emptyCellLabel
    row.report_date_time = row.report_date_time
      ? convertDataEpochToDate(+row.report_date_time, null, null, true)
      : emptyCellLabel
    row.distance_units =
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.km
        ? 'Kilometers'
        : 'Miles'
    row.first_gw_communication = row.first_gw_communication
      ? convertDataEpochToDate(+row.first_gw_communication, null, null, true)
      : emptyCellLabel
    row.last_gw_communication = row.last_gw_communication
      ? convertDataEpochToDate(+row.last_gw_communication, null, null, true)
      : emptyCellLabel
    row.end_odometer = row.end_odometer
      ? getMileage(row.end_odometer, unitsOfMeasurementConfig).toFixed(2)
      : emptyCellLabel
    row.asset_groups = row.asset_groups || emptyCellLabel

    return row
  })
}
