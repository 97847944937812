import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { history } from './jwt/_helpers'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import ReactGA from 'react-ga4'
import config from './config/appConfig'

import './assets/scss/style.scss'
// styles for date-range-picker
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const App = require('./app').default

ReactGA.initialize(config.ga_measurement_id, {
  gaOptions: {
    user_id: null,
  },
})

ReactGA.send({ hitType: 'pageview', page: 'init', title: 'Page load' })
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
)
