import React from 'react'

import FleetMap from '../../modules/FleetMap'
import WarningsChart from '../../modules/WarningsChart'
import TablesWrapper from '../../components/TablesWrapper'
import FleetSummaryModule from '../../modules/FleetSummary'

import { useTypedSelector } from '../../hooks/useTypedSelector'

const FleetSummaryType = 'FleetSummary'
const FleetMapType = 'FleetMap'
const WarningsChartType = 'Warnings'
const AssetTableType = 'AssetList'

const DashboardBody = ({ components, locale }) => {
  const {
    geofences,
    geofencesStatus,
    whiteLabelUrl,
    healthColors,
    preferences,
    isLoadingMap,
    isLoadingStatus,
    locations,
    locationsStatus,
    assetsCardsData,
    assetsCardsDataLoading,
  } = useTypedSelector((state) => ({
    geofences: state.geofences.geofences.data,
    geofencesStatus: state.geofences.geofences.status,
    whiteLabelUrl: state.auth.whiteLabelUrl,
    healthColors: state.whitelabel.healthColors,
    preferences: state.auth.preferences,
    isLoadingMap: state.dash.isLoadingMap,
    isLoadingStatus: state.dash.isLoadingStatus,
    locations: state.dash.locations,
    locationsStatus: state.dash.locationsStatus,
    assetsCardsData: state.dash.assetsCards.data,
    assetsCardsDataLoading: state.dash.assetsCards.isLoading,
  }))

  return components.map(({ component, width }) => {
    switch (component) {
      case WarningsChartType:
        return (
          <WarningsChart
            key={component}
            isLoading={isLoadingStatus}
            width={width}
            healthColors={healthColors}
            whiteLabelUrl={whiteLabelUrl}
            locale={locale}
            preferences={preferences}
            locations={locations}
          />
        )
      case FleetMapType:
        return (
          <FleetMap
            key={component}
            isLoading={isLoadingMap}
            width={width}
            locale={locale}
            preferences={preferences}
            locations={locations}
            locationsStatus={locationsStatus}
            geofences={geofences}
            geofencesStatus={geofencesStatus}
          />
        )
      case AssetTableType:
        return (
          <TablesWrapper
            key={component}
            width={width}
            whiteLabelUrl={whiteLabelUrl}
            locale={locale}
            preferences={preferences}
          />
        )
      case FleetSummaryType:
        return (
          <FleetSummaryModule
            key={component}
            width={width}
            locale={locale}
            data={assetsCardsData}
            isLoading={assetsCardsDataLoading}
            availableCustomersSubscriptions={
              preferences.availableCustomersSubscriptions
            }
          />
        )
      default:
        return null
    }
  })
}

export default DashboardBody
