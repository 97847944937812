import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
const TimeZoneRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    setDisplayValue(value)
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Timezone:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default TimeZoneRow
