import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import RivataModule from '../../components/RivataModule'
import OrderItem from './OrderItem'
import './style.scss'
import { getItemsUpdated, reorderAndResize } from './utils'

export type ModuleItem = {
  id: string
  data: {
    title: string
    width: number
    enabled: boolean
  }
}

type Props = {
  title: string
  initialItems: Array<ModuleItem>
  onUpdate: (items: Array<ModuleItem>) => void
  locale: ILocale
  width?: number
  customClass?: string
  showDisableCheckbox?: boolean
}

const ModuleOrder: React.FC<Props> = ({
  title,
  initialItems,
  onUpdate,
  locale,
  width = 6,
  customClass = '',
  showDisableCheckbox,
}) => {
  const [items, setItems] = useState(initialItems)
  useEffect(() => {
    if (initialItems) setItems(initialItems)
  }, [initialItems])

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorderAndResize(
      items,
      result.source.index,
      result.destination.index,
    )
    onUpdate(newItems)
    setItems(newItems)
  }

  const onItemUpdated = (item: ModuleItem) => {
    const newItems = getItemsUpdated(item, rows, items)

    onUpdate(newItems)
    setItems(newItems)
  }

  const [rows, itemsWithRowId] = useMemo(() => {
    const rows = items
      .reduce(
        (rows, item) => {
          if (!item.data.enabled) return rows

          const rowId = rows.length - 1
          const size = rows[rowId].reduce((a, b) => a + b.data.width, 0)
          if (size + item.data.width <= 12) {
            rows[rowId].push(item)
          } else {
            rows.push([item])
          }
          return rows
        },
        [[]] as Array<Array<ModuleItem>>,
      )
      .map((row) => row.map((item) => item.id))

    const itemsWithRowId = items.map((item) => {
      const rowId = rows.findIndex((row) => row.includes(item.id))

      return {
        ...item,
        rowId,
      }
    })

    return [rows, itemsWithRowId]
  }, [items])

  return (
    <RivataModule
      title={title}
      width={width}
      locale={locale}
      customClass={customClass}
      filters={undefined}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {itemsWithRowId.map((item, index) => (
                <OrderItem
                  key={item.id}
                  item={item}
                  index={index}
                  onItemUpdated={onItemUpdated}
                  isConstantWidth={item.id === 'TpmsStatusDiagram'}
                  showDisableCheckbox={showDisableCheckbox}
                />
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </RivataModule>
  )
}

export default ModuleOrder
