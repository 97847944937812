import SimpleGeometry from './SimpleGeometry'

class Circle extends SimpleGeometry {
  constructor(
    map: H.Map,
    behavior: H.mapevents.Behavior | null,
    onDrawingStarted: () => void,
    onDrawingFinished: (geofenceWKT: string) => void,
  ) {
    super(map, behavior, 'circle', onDrawingStarted, onDrawingFinished)
  }
}

export default Circle
