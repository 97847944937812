import React from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import AssetsSelect from './AssetsSelect'
import AssetGroupsFilter from '../../../componentsV2/AssetGroupsFilter'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'

interface Props {
  disabled: boolean
  assetTypes: Array<any>
  selectedAssetType: 'tractor' | 'trailer' | 'all'
  setSelectedAssetType: React.Dispatch<React.SetStateAction<string>>
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>
  selectedAssetsItems: Array<SearchItemType>
  showAssetGroupsFilter?: boolean | false
  onAssetGroupFilterItemSelected?: (
    id: string,
    selectedValue: MenuItemType[],
  ) => void
  customerIds?: string | ''
}

const DurationInWarningStateReportForm: React.FC<Props> = ({
  disabled,
  assetTypes,
  selectedAssetType,
  setSelectedAssetType,
  setVinList,
  selectedAssetsItems,
  onAssetGroupFilterItemSelected,
  customerIds,
  showAssetGroupsFilter,
}) => {
  return (
    <>
      <AssetsSelect
        disabled={disabled}
        assetTypes={assetTypes}
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        setVinList={setVinList}
        selectedItems={selectedAssetsItems}
      />
      {showAssetGroupsFilter && (
        <AssetGroupsFilter
          disabled={disabled}
          className='mt-3'
          onDropdownItemSelect={onAssetGroupFilterItemSelected}
          customerIds={customerIds}
        />
      )}
    </>
  )
}

export default DurationInWarningStateReportForm
