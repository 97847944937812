import { useEffect } from 'react'
import React from 'react'
import { pinMap } from '../RivataMapCluster/utils'
import config from '../../config/appConfig'

export const H = window.H || {
  map: {
    Icon: () => { },
  },
}

interface IGPSData {
  lat: number
  lng: number
  datetime: string
  name: string
}

interface Props {
  data: Array<IGPSData>
}

export const SimpleMap = ({ data }: Props) => {
  useEffect(() => {
    const platform = new H.service.Platform({
      apikey: config.mapApiKey,
    })

    //@ts-expect-error //bug of @types/heremaps
    const engineType = H.Map.EngineType['HARP'];
    //@ts-expect-error //bug of @types/heremaps
    const defaultLayers = platform.createDefaultLayers({engineType, lg: 'en'})

    const mapElement = document.getElementById("map");

    if (mapElement) {
      const map = new H.Map(
        mapElement,
        defaultLayers.vector.normal.map,
        {
          engineType,
          pixelRatio: window.devicePixelRatio || 1,
        },
      )
      window.addEventListener('resize', () => map.getViewPort().resize())
      // eslint-disable-next-line
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map))
      const ui = H.ui.UI.createDefault(map, defaultLayers)

      const group = new H.map.Group()

      data.forEach((d: IGPSData) => {
        const marker = new H.map.Marker(
          { lat: d.lat, lng: d.lng },
          { icon: pinMap.ok },
        )
        marker.addEventListener('pointerenter', (e) => {
          const bubble = new H.ui.InfoBubble(
            { lat: d.lat, lng: d.lng },
            {
              content: addInfoBubble(d.datetime, d.name),
            },
          )
          ui.addBubble(bubble)
          marker.addEventListener('pointerleave', (e) => {
            bubble.close()
          })
        })
        group.addObjects([marker])
      })

      map.addObject(group)

      map.getViewModel().setLookAtData(
        {
          bounds: group.getBoundingBox(),
          zoom: 4,
        },
        false,
      )
    }
  }, [data])

  const addInfoBubble = (datetime: string, name: string,) => {
    return `<div style="width: 220px">
        <b>Asset Name:</b> ${name}
        </br>
        <b>Datetime:</b> ${datetime}
    </div>`
  }

  return (
    <div className='map' id='map' style={{ height: '100%', width: '100%' }} />
  )
}
