import { isEmpty } from 'lodash'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Button } from 'reactstrap'
import RivataLoader from '../../components/RivataLoader'
import ProfilesDropdown from '../../components/ProfilesDropdown'
import RivataModule from '../../components/RivataModule'
import TpmsProfileConfiguratiomForm from '../../components/TmpsProfileConfigurationForm'
import ProfileModal from './ProfileModal'
import StatusAlert from '../../components/StatusAlert'
import { psiToBar, getCurrentEpoch } from '../../utils'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

const TpmsConfigurationModule = ({
  width,
  isLoading,
  warningSettingsDefaults,
  preferences: { unitsOfMeasurementConfig = {} },
  subscriptions,
  locale,
}) => {
  const {
    setTpmsProfiles,
    setSelectedTpmsProfile,
    deleteTpmsSettings,
    addUpdateTpmsSettings,
  } = useActions()
  const { profiles, selectedProfile, isSuperAdmin, selectedCustomersList } =
    useTypedSelector((state) => ({
      isSuperAdmin: state.auth.isSuperAdmin,
      profiles: state.tpmsProfiles.tpmsProfiles,
      selectedProfile: state.tpmsProfiles.selectedTpmsProfile,
      selectedCustomersList: state.common.customers.selectedCustomersList,
    }))

  const [profileModalVisible, setProfileModalVisible] = useState(false)
  const [name, setName] = useState(null)

  const createProfileHandler = useCallback(
    (profileData) => {
      const profile = profiles.find((c) =>
        c.name
          ? c.name.toLowerCase().trim() ===
            profileData.name.toLowerCase().trim()
          : null,
      )
      if (profile) {
        return {
          statusCode: 409,
          message: `Profile with name '${profile.name}' already exists`,
        }
      }
      if (!warningSettingsDefaults) return null

      const defaultValue =
        warningSettingsDefaults.cold_inflation_pressure_in_psi

      const newProfile = {
        id: profileData.name,
        settings_id: null,
        name: profileData.name,
        label: profileData.name,
        low_pressure_in_percent:
          warningSettingsDefaults.low_pressure_in_percent,
        critical_low_pressure_in_percent:
          warningSettingsDefaults.critical_low_pressure_in_percent,
        over_pressure_in_percent:
          warningSettingsDefaults.over_pressure_in_percent,
        critical_over_pressure_in_percent:
          warningSettingsDefaults.critical_over_pressure_in_percent,
        axels: {
          tractor: {
            tractor_axle_1:
              warningSettingsDefaults.steer_cold_inflation_pressure_in_psi,
            tractor_axle_2: defaultValue,
            tractor_axle_3: defaultValue,
            tractor_axle_4: defaultValue,
            tractor_axle_5: defaultValue,
          },
          trailer: {
            trailer_axle_1: defaultValue,
            trailer_axle_2: defaultValue,
            trailer_axle_3: defaultValue,
            trailer_axle_4: defaultValue,
            trailer_axle_5: defaultValue,
            trailer_axle_6: defaultValue,
          },
          tractorViewAxlesCount: 5,
          trailerViewAxlesCount: 5,
          hasTractor: true,
          hasTrailer: true,
        },
      }

      if (!profiles.length) {
        setSelectedTpmsProfile(newProfile)
      }

      if (profiles.length && newProfile.name === 'Default') {
        setSelectedTpmsProfile(newProfile)
      }
      setTpmsProfiles([...profiles, newProfile])
      setProfileModalVisible(false)
      return { statusCode: 201, message: 'created' }
    },
    [
      profiles,
      setSelectedTpmsProfile,
      setTpmsProfiles,
      warningSettingsDefaults,
    ],
  )

  useEffect(() => {
    const isThereDefaultProfile = () => {
      const idx = profiles.findIndex((el) =>
        el.name === 'Default' ? true : false,
      )
      return idx > -1 ? true : false
    }

    if (isThereDefaultProfile()) return

    createProfileHandler({ name: 'Default' })
  }, [profiles, createProfileHandler])

  const initialValues = useMemo(() => {
    if (!selectedProfile?.axels) return {}
    const obj = {
      low_pressure_in_percent: selectedProfile.low_pressure_in_percent || '',
      critical_low_pressure_in_percent:
        selectedProfile.critical_low_pressure_in_percent || '',
      over_pressure_in_percent: selectedProfile.over_pressure_in_percent || '',
      critical_over_pressure_in_percent:
        selectedProfile.critical_over_pressure_in_percent || '',
      tractor: selectedProfile.axels.hasTractor || false,
      trailer: selectedProfile.axels.hasTrailer || false,
    }
    for (let i = 1; i <= 5; i++) {
      obj[`tractor_axle_${i}`] =
        unitsOfMeasurementConfig?.pressure ===
        UnitsOfMeasurementConfig.pressure.psi
          ? selectedProfile.axels.tractor[[`tractor_axle_${i}`]] || ''
          : psiToBar(selectedProfile.axels.tractor[`tractor_axle_${i}`])

      if (obj[`tractor_axle_${i}`]) {
        obj[`tractor_axle_${i}`] =
          typeof obj[`tractor_axle_${i}`] === 'number'
            ? obj[`tractor_axle_${i}`].toFixed(2)
            : ''
      }
    }
    for (let i = 1; i <= 5; i++) {
      obj[`trailer_axle_${i}`] =
        unitsOfMeasurementConfig?.pressure ===
        UnitsOfMeasurementConfig.pressure.psi
          ? selectedProfile.axels.trailer[`trailer_axle_${i}`] || ''
          : psiToBar(selectedProfile.axels.trailer[`trailer_axle_${i}`])

      if (obj[`trailer_axle_${i}`]) {
        obj[`trailer_axle_${i}`] =
          typeof obj[`trailer_axle_${i}`] === 'number'
            ? obj[`trailer_axle_${i}`].toFixed(2)
            : ''
      }
    }

    return obj
  }, [selectedProfile, unitsOfMeasurementConfig])

  const addProfile = (data) => {
    setName(data.name)
    return createProfileHandler(data)
  }

  useEffect(() => {
    if (name) {
      const profile = profiles.find((el) => el.name === name)
      profile && setSelectedTpmsProfile(profile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, profiles])

  const isActiveSubscription = useMemo(() => {
    return (
      isSuperAdmin || subscriptions?.TIRE_SENSOR?.valid_to >= getCurrentEpoch()
    )
  }, [subscriptions, isSuperAdmin])

  return (
    <RivataModule
      title='Manage TPMS Profiles'
      width={width}
      locale={locale}
      filters={
        isActiveSubscription && (
          <>
            <ProfilesDropdown
              locale={locale}
              onSelect={(id) => {
                const profile = profiles.find((c) => c.id === id)
                if (profile) {
                  setSelectedTpmsProfile(profile)
                }
              }}
              profiles={profiles}
              selected={selectedProfile?.id}
            />

            <Button
              className='btn btn-primary'
              onClick={() => setProfileModalVisible(true)}
            >
              Add New Profile
            </Button>
          </>
        )
      }
    >
      {!isActiveSubscription ? (
        <>
          {isLoading ? (
            <RivataLoader />
          ) : (
            <StatusAlert
              customText='No Active Subscription Found'
              customSubText={
                selectedCustomersList && selectedCustomersList[0]
                  ? selectedCustomersList[0]?.children?.length > 0
                    ? 'Try selecting a different account'
                    : ''
                  : ''
              }
              color='success'
            />
          )}
        </>
      ) : (
        <>
          {!isEmpty(selectedProfile) ? (
            <TpmsProfileConfiguratiomForm
              initialValues={initialValues}
              selectedProfile={selectedProfile}
              profiles={profiles}
              setTpmsProfiles={setTpmsProfiles}
              setSelectedTpmsProfile={setSelectedTpmsProfile}
              locale={locale}
              onProfileEdit={() => {}}
              onProfileCreate={() => {}}
              onCreateUpdate={addUpdateTpmsSettings}
              onDeleteTpmsSettings={deleteTpmsSettings}
              unitsOfMeasurement={unitsOfMeasurementConfig?.pressure}
            />
          ) : null}
          {isLoading && <RivataLoader />}
          <ProfileModal
            initialValues={{ name: '' }}
            disabled={false}
            locale={locale}
            open={profileModalVisible}
            onClose={() => setProfileModalVisible(false)}
            onCreate={(data) => {
              return addProfile(data)
            }}
            title='Create'
          />
        </>
      )}
    </RivataModule>
  )
}

export default TpmsConfigurationModule
