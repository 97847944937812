import polyCircle from './common/polyCircle'

const polyStyle = {
  style: { strokeColor: 'rgba(76, 134, 194, 0.8)', lineWidth: 2 },
}

class Polygon {
  map: H.Map | null = null
  behavior: H.mapevents.Behavior | null = null
  verticeGroup: H.map.Group | null = null
  mainGroup: H.map.Group | null = null
  polyline: H.map.Polyline | null = null
  drawing: H.map.Polygon | null = null
  onDrawingStarted: () => void
  onDrawingFinished: (geofenceWKT: string) => void
  selectedVertice: H.map.Marker | null = null

  constructor(
    map: H.Map,
    behavior: H.mapevents.Behavior | null,
    onDrawingStarted: () => void,
    onDrawingFinished: (geofenceWKT: string) => void,
  ) {
    this.map = map
    this.behavior = behavior
    this.onDrawingStarted = onDrawingStarted
    this.onDrawingFinished = onDrawingFinished
    this.init()
  }

  save(geofenceWKT: string): void {
    this.onDrawingFinished(geofenceWKT)
  }

  init() {
    this.verticeGroup = new H.map.Group({
      visibility: true,
    })

    this.mainGroup = new H.map.Group({
      objects: [this.verticeGroup],
    })

    if (this.map) this.map.addObject(this.mainGroup)

    this.mainGroup.setZIndex(10000)

    this.addMapListener()
  }

  removeAll() {
    if (this.verticeGroup) {
      this.verticeGroup.removeAll()
      this.verticeGroup.dispose()
    }

    if (this.mainGroup) {
      this.mainGroup.removeAll()
      this.mainGroup.dispose()
    }

    if (this.map) {
      this.removeMapListener()
    }
  }

  removeDrawing() {
    this.drawing?.dispose()
  }

  reset() {
    this.removeAll()
    this.init()
  }

  dispose(removeDrawing?: boolean) {
    this.removeAll()
    this.map = null
    this.verticeGroup = null
    this.mainGroup = null
    this.polyline = null
    if (removeDrawing) this.removeDrawing()
  }

  addMapListener() {
    if (this.map) this.map.addEventListener('tap', this.onMapClicked)
  }

  removeMapListener() {
    if (this.map) this.map.removeEventListener('tap', this.onMapClicked)
  }

  addVertice = (lat: number, lng: number, index: number) => {
    const options: H.map.Icon.Options = {
      anchor: { x: 10, y: 10 },
      crossOrigin: false,
    }

    const vertice = new H.map.Marker(
      { lat, lng },
      {
        icon: new H.map.Icon(polyCircle, options),
      },
    )

    vertice.draggable = true

    vertice.setData({ verticeIndex: index, lat, lng })

    if (this.verticeGroup) this.verticeGroup.addObject(vertice)

    vertice.addEventListener('dragstart', (e: any) => {
      this.behavior?.disable()
    })

    vertice.addEventListener('drag', (e: any) => {
      if (this.map && this.behavior && this.verticeGroup) {
        const pointer = e.currentPointer
        const targetPosition = this.map.screenToGeo(
          pointer.viewportX,
          pointer.viewportY,
        )

        let idx = -1

        this.verticeGroup.forEach((v, n) => {
          if (v === vertice) {
            idx = n
          }
        })

        const vertices = this.verticeGroup.getObjects()

        if (this.drawing) {
          if (idx === 0) {
            const lastPoint = vertices[vertices.length - 1] as H.map.Marker
            lastPoint.setGeometry({
              lat: targetPosition.lat,
              lng: targetPosition.lng,
            })
          } else if (idx === vertices.length - 1) {
            const firstPoint = vertices[0] as H.map.Marker
            firstPoint.setGeometry({
              lat: targetPosition.lat,
              lng: targetPosition.lng,
            })
          }
        }

        vertice.setGeometry(
          new H.geo.Point(targetPosition.lat, targetPosition.lng),
        )
      }
    })

    vertice.addEventListener('dragend', (e: any) => {
      if (this.behavior) {
        this.behavior.enable()
      }

      if (!this.verticeGroup) {
        return
      }

      const index = this.verticeGroup.getObjects().length

      if (index === 0) {
        return
      } else {
        this.redrawPolyline()
      }
    })

    this.redrawPolyline()

    // if it is the first vertice, then it is the one we close with
    if (index === 0) vertice.addEventListener('tap', this.closeLine)
  }

  redrawPolyline = () => {
    if (this.polyline) {
      // const lineString = this.polyline?.getGeometry()
      const lineString = new H.geo.LineString()

      const vertices = this.verticeGroup?.getObjects()

      if (vertices) {
        vertices.forEach((v) => {
          // @ts-ignore
          const { lat, lng } = v.getGeometry()

          lineString.pushPoint({
            lat,
            lng,
          })
        })
      }

      this.polyline?.setGeometry(lineString)

      // If polygon is closed
      if (this.drawing) {
        const polygon = new H.map.Polygon(lineString)
        this.map?.removeObject(this.drawing)
        this.draw(polygon)
        this.drawing = polygon
      }
    }
  }

  geometryToWKT(geometry: H.map.Polygon) {
    return geometry.getGeometry().toString()
  }

  draw(mapObject: H.map.Object) {
    if (!this.map) return

    this.map.addObject(mapObject)
  }

  closeLine = (evt: Event) => {
    evt.stopPropagation()

    if (!this.verticeGroup) return

    const vertices = this.verticeGroup.getObjects()

    if (vertices.length > 2) {
      // add the last point as first to connect
      this.buildPolyline(vertices[0].getData())

      type Point = { lat: number; lng: number }

      const points: Array<Point> = this.verticeGroup
        .getObjects()
        .map((obj: any) => {
          const { lat, lng } = obj.getGeometry() as H.geo.Point
          return { lat, lng }
        })

      const lineString = new H.geo.LineString()

      points.forEach((point) => {
        lineString.pushPoint(point)
      })

      const polygon = new H.map.Polygon(lineString)

      this.draw(polygon)
      this.drawing = polygon
      this.save(this.geometryToWKT(polygon))
    } else {
      this.onMapClicked(evt)
    }
  }

  initPolyline() {
    if (!this.verticeGroup) return

    const vertices = this.verticeGroup.getObjects()
    const lineString = new H.geo.LineString()

    lineString.pushPoint(vertices[0].getData())
    lineString.pushPoint(vertices[1].getData())

    this.polyline = new H.map.Polyline(lineString, polyStyle)
    if (this.mainGroup) this.mainGroup.addObject(this.polyline)
  }

  onMapClicked = (evt: any) => {
    evt.stopPropagation()

    if (!this.map) return

    const pointer = evt.currentPointer

    const geoPoint = this.map.screenToGeo(pointer.viewportX, pointer.viewportY)

    // check if it is not a point

    this.buildPolyline(geoPoint)
  }

  buildPolyline = (geoPoint: any) => {
    if (!this.verticeGroup || this.drawing) {
      return
    }

    const index = this.verticeGroup.getObjects().length
    this.addVertice(geoPoint.lat, geoPoint.lng, index)

    if (index === 0) {
      this.onDrawingStarted()
      // just add the vertice, can't do a line yet
      return
    } else if (index === 1) {
      // init the poly line and add first first line
      this.initPolyline()
    } else {
      // keep building the polyline
      if (!this.polyline) return

      const geoLineString = this.polyline.getGeometry()
      // @ts-ignore
      geoLineString.pushPoint(geoPoint)
      this.polyline.setGeometry(geoLineString)
    }
  }
}

export default Polygon
