import React, { useCallback, useEffect, useState } from 'react'
import { Link, Redirect, useParams, withRouter } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import authService from '../../services/auth'

import brandLogo from '../../assets/images/conmet_brand/conmet_digital.png'
import defaultBgImage from '../../assets/images/riv_brand/image--013.png'
import './style.scss'
import { baseUrl } from '../../services/api/base'
const Login: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const intSession = useCallback(() => {
    const currentUser = authService.getCognitoUser()
    setIsLoggedIn(currentUser !== null && currentUser !== undefined)
  }, [setIsLoggedIn])

  useEffect(() => {
    intSession()
  }, [intSession])

  const refreshTokenExpired = useTypedSelector(
    (state) => state.auth.failedTokenRefresh.expired,
  )
  const auth = useTypedSelector((state) => state.auth)
  useEffect(() => {
    if (auth.isLoggedIn) {
      setIsLoggedIn(true)
    }
  }, [auth])

  const params: { customer?: string } = useParams()

  const [imgSrc, setImgSrc] = useState(
    params?.customer
      ? `${baseUrl}/config/${params?.customer}/logo_full.png`
      : brandLogo,
  )
  const [showBgImage, setShowBgImage] = useState(
    params?.customer ? true : false,
  )
  const [bgImgSrc] = useState(
    params?.customer
      ? `${baseUrl}/config/${params?.customer}/auth_page_image.png`
      : defaultBgImage,
  )

  const handleLogoError = (e: any) => {
    e.stopPropagation()
    setImgSrc(brandLogo)
  }
  const handleBgImageError = (e: any) => {
    e.stopPropagation()
    setShowBgImage(false)
    document.documentElement.style.setProperty(
      '--login-bg',
      `url(${defaultBgImage})`,
    )
  }
  useEffect(() => {
    if (params?.customer) {
      localStorage.setItem('login_for', params?.customer)
      document.documentElement.style.setProperty(
        '--login-bg',
        `url(${baseUrl}/config/${params?.customer}/auth_page_image.png)`,
      )
    } else {
      localStorage.removeItem('login_for')
      setShowBgImage(false)
    }
  }, [params, setShowBgImage])
  return !isLoggedIn ? (
    <>
      {params?.customer && (
        <link
          href={`${baseUrl}/config/${params?.customer}/style.css`}
          rel='stylesheet'
        />
      )}
      {/* Image used to create fallback background image */}
      {showBgImage && (
        <img
          src={bgImgSrc}
          onError={handleBgImageError}
          width={'0px'}
          height={'0px'}
          style={{
            position: 'absolute',
            top: -1000,
            left: -1000,
            visibility: 'hidden',
            display: 'none',
          }}
        />
      )}
      <div className='auth-wrapper'>
        <div className='auth-box on-sidebar'>
          <div className='logo'>
            <h2 className='welcome mb-3'>Welcome To</h2>
            <img
              src={imgSrc}
              alt='logo'
              onError={handleLogoError}
              width={'243px'}
            />
          </div>

          <a
            href={authService.getSignInUri()}
            className='btn btn-block btn-login mt-3'
            style={{
              backgroundColor: params?.customer ? 'var(--primary)' : undefined,
            }}
          >
            Sign In
          </a>

          <Link
            to='/authentication/request-access'
            className='btn btn-block btn-req'
          >
            Request Account
          </Link>

          {refreshTokenExpired && (
            <div className='mt-3 text-secondary1'>
              You have been logged in for 30days. Please login again
            </div>
          )}

          <a
            href='https://conmet.com/innovation/digital/'
            target='_blank'
            rel='noreferrer noopener'
            className='position-absolute sidebar-footer'
            style={{ bottom: '1rem', right: '1rem', fontSize: '1rem' }}
          >
            Learn More About Our Products
          </a>
        </div>
      </div>
    </>
  ) : (
    <Redirect to={'/'} />
  )
}
export default withRouter(Login)
