import { IRow } from "../../../components/RivataGrid";

export const notificationsSubscriptionsReportRows = (rows: Array<IRow>) =>
    rows.map((row) => ({
      ...row,
      notification_type: row.notification_type !== '-' ? row.notification_type : "---",
      assets: row.assets ? row.assets : "---",
      assets_groups: row.assets_groups ? row.assets_groups : "---",
      geofences: row.geofences ? row.geofences : "---",
      is_combined: row.is_combined ? 'True' : 'False'
    }));