import React, { useState } from 'react'
import { Location } from 'history'
import { Nav } from 'reactstrap'
import NavList from './NavList'

import { INavRoute } from '../RivataPage/navRoutes'

import './styles.scss'
import RivataScrollBar from '../../componentsV2/RivataPerfectScrollbar'

interface Props {
  navRoutes: Array<INavRoute>
  location: Location
  versionInfo: {
    branch: string
    commit_uid: string
    build_branch: string
    build_fullhash: string
  }
}

const Sidebar: React.FC<Props> = ({ navRoutes, versionInfo, location }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleMenuExpand = (isExpanded: boolean) => {
    const logo = document.getElementById('logobg')
    if (logo && isExpanded) {
      logo.classList.add('expand-logo')
      setIsExpanded(true)
    } else if (logo) {
      logo.classList.remove('expand-logo')
      setIsExpanded(false)
    }
  }

  return (
    <aside
      className='left-sidebar'
      id='sidebarbg'
      data-sidebarbg={'skin6'}
      onMouseOver={() => handleMenuExpand(true)}
      onMouseLeave={() => handleMenuExpand(false)}
    >
      <div className='scroll-sidebar'>
        <RivataScrollBar className='sidebar-nav'>
          <Nav id='sidebarnav'>
            <NavList navRoutes={navRoutes} location={location} />
          </Nav>
          {isExpanded && (
            <div
              className='versions_wrapper'
              style={{ fontSize: 12, color: 'grey' }}
            >
              {(versionInfo.build_branch || versionInfo.build_fullhash) && (
                <div>
                  f: {versionInfo.build_branch} {versionInfo.build_fullhash}
                </div>
              )}
              {(versionInfo.branch || versionInfo.commit_uid) && (
                <div>
                  b: {versionInfo.branch} {versionInfo.commit_uid}
                </div>
              )}
            </div>
          )}
        </RivataScrollBar>
      </div>
    </aside>
  )
}

export default React.memo(Sidebar)
