import {
  LOADING_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_ERROR,
  POSTING_WHITELABEL_ADMIN,
  POSTED_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_DATA_DIRTY,
  SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LOGO_ICON_PNG,
  SET_WHITELABEL_ADMIN_LOGO_FULL_PNG,
  SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_GATEWAY_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_AUTH_IMAGE_PNG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_SMARTHUB_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_TPMS_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_LINE_PRESSURE_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_AXLE_LOAD_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_INSIDE_SVG,
  SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_OUTSIDE_SVG,
  SET_WHITELABEL_ADMIN_APP_SMARTHUB_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_AXLE_LOAD_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_TPMS_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_LINE_PRESSURE_ICON_PNG,
  SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_LIGHT_PNG,
  SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_DARK_PNG,
  SET_WHITELABEL_LOADED,
} from '../actionTypes'
import { localeText } from '../../locale'
import { Languages } from '../../enums'
import defaultConfig from '../whitelabel/defaultConfig'
import { getActualConfig } from '../../utils'

const languagechoice = (data) => {
  switch (data.preferenceDefaults.locale.language) {
    case Languages.FR:
      return localeText.fr
    case Languages.EN:
      return localeText.en
    case Languages.GR:
      return localeText.gr
    default:
      return null
  }
}

const initialState = {
  config: defaultConfig,
  locale: languagechoice(defaultConfig),
  error: {
    statusCode: 0,
    message: '',
  },
  isLoading: false,
  isPosting: false,
  hasCheckedForConfig: false,
  dirty: false,
  postedConfig: false,
  smarthubHealthSvg: null,
  tpmsHealthSvg: null,
  linePressureHealthSvg: null,
  axleLoadHealthSvg: null,
  gatewayHealthSvg: null,
  logoIconPng: null,
  logoFullPng: null,
  authImagePng: null,
  fleetSummarySmarthubSvg: null,
  fleetSummaryTpmsSvg: null,
  fleetSummaryLinePressureSvg: null,
  fleetSummaryAxleLoadSvg: null,
  fleetSummaryGeofenceInsideSvg: null,
  fleetSummaryGeofenceOutsideSvg: null,
  appSmartHubIconPng: null,
  appAxleLoadIconPng: null,
  appTpmsIconPng: null,
  appLinePressureIconPng: null,
  appLogoBrandLightPng: null,
  appLogoBrandDarkPng: null,
}

// const validate = (data) => (Array.isArray(data) ? data : null);

const setWhiteLabel = (state, data) => {
  const { config, isUpdate } = data
  if (!config) return state

  const newConfig = isUpdate ? config : getActualConfig(config)
  // we need to reset all svgs after update, to not cause double image upload. RIVS - 3892
  return {
    ...state,
    config: {
      ...newConfig,
    },
    hasCheckedForConfig: true,
    locale:
      config.preferenceDefaults &&
      config.preferenceDefaults.locale &&
      languagechoice(config),
    smarthubHealthSvg: null,
    tpmsHealthSvg: null,
    linePressureHealthSvg: null,
    axleLoadHealthSvg: null,
    gatewayHealthSvg: null,
    logoIconPng: null,
    logoFullPng: null,
    authImagePng: null,
    fleetSummarySmarthubSvg: null,
    fleetSummaryTpmsSvg: null,
    fleetSummaryLinePressureSvg: null,
    fleetSummaryAxleLoadSvg: null,
    fleetSummaryGeofenceInsideSvg: null,
    fleetSummaryGeofenceOutsideSvg: null,
    appSmartHubIconPng: null,
    appAxleLoadIconPng: null,
    appTpmsIconPng: null,
    appLinePressureIconPng: null,
    appLogoBrandLightPng: null,
    appLogoBrandDarkPng: null,
  }
}

const whitelabel = (state = initialState, action) => {
  switch (action.type) {
    case SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG:
      return { ...state, smarthubHealthSvg: action.payload }
    case SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG:
      return { ...state, tpmsHealthSvg: action.payload }
    case SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG:
      return { ...state, linePressureHealthSvg: action.payload }
    case SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG:
      return { ...state, axleLoadHealthSvg: action.payload }
    case SET_WHITELABEL_ADMIN_GATEWAY_HEALTH_SVG:
      return { ...state, gatewayHealthSvg: action.payload }
    case SET_WHITELABEL_ADMIN_LOGO_ICON_PNG:
      return { ...state, logoIconPng: action.payload }
    case SET_WHITELABEL_ADMIN_LOGO_FULL_PNG:
      return { ...state, logoFullPng: action.payload }
    case SET_WHITELABEL_ADMIN_FLEET_SUMMARY_SMARTHUB_SVG:
      return { ...state, fleetSummarySmarthubSvg: action.payload }
    case SET_WHITELABEL_ADMIN_FLEET_SUMMARY_TPMS_SVG:
      return { ...state, fleetSummaryTpmsSvg: action.payload }
    case SET_WHITELABEL_ADMIN_FLEET_SUMMARY_LINE_PRESSURE_SVG:
      return { ...state, fleetSummaryLinePressureSvg: action.payload }
    case SET_WHITELABEL_ADMIN_FLEET_SUMMARY_AXLE_LOAD_SVG:
      return { ...state, fleetSummaryAxleLoadSvg: action.payload }
    case SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_INSIDE_SVG:
      return { ...state, fleetSummaryGeofenceInsideSvg: action.payload }
    case SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_OUTSIDE_SVG:
      return { ...state, fleetSummaryGeofenceOutsideSvg: action.payload }
    case POSTING_WHITELABEL_ADMIN:
      return { ...state, isPosting: action.payload }
    case POSTED_WHITELABEL_ADMIN:
      return { ...state, postedConfig: action.payload }
    case SET_WHITELABEL_ADMIN_DATA_DIRTY:
      return { ...state, dirty: action.payload }
    case LOADING_WHITELABEL_ADMIN:
      return { ...state, isLoading: action.payload }
    case SET_WHITELABEL_ADMIN:
      return setWhiteLabel(state, action.payload)
    case SET_WHITELABEL_ADMIN_ERROR:
      return { ...state, error: action.payload, hasCheckedForConfig: true }
    case SET_WHITELABEL_ADMIN_AUTH_IMAGE_PNG:
      return { ...state, authImagePng: action.payload }
    case SET_WHITELABEL_ADMIN_APP_SMARTHUB_ICON_PNG:
      return { ...state, appSmartHubIconPng: action.payload }
    case SET_WHITELABEL_ADMIN_APP_AXLE_LOAD_ICON_PNG:
      return { ...state, appAxleLoadIconPng: action.payload }
    case SET_WHITELABEL_ADMIN_APP_TPMS_ICON_PNG:
      return { ...state, appTpmsIconPng: action.payload }
    case SET_WHITELABEL_ADMIN_APP_LINE_PRESSURE_ICON_PNG:
      return { ...state, appLinePressureIconPng: action.payload }
    case SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_LIGHT_PNG:
      return { ...state, appLogoBrandLightPng: action.payload }
    case SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_DARK_PNG:
      return { ...state, appLogoBrandDarkPng: action.payload }
    default:
      return state
  }
}

export default whitelabel
