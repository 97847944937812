import { object, array, string, number, TestContext, mixed } from "yup"

interface ISensor {
  mac: string;
  position?: string;
}

interface IHardware {
  gateways?: Array<{ esn: number }>;
  smarthub_sensors?: Array<ISensor>;
  tpms?: Array<ISensor>;
  pneumatics?: {
    axle_load?: ISensor;
    line_pressure?: ISensor;
  };
}

const getShortMac = (mac: string) => mac?.substring(12) || "";

const validShortMac = (mac: string) => mac?.length === 5;

const findShortMacDuplicates = (hardware: IHardware) => {
  const shortMacError = {
    value: false,
    text: "",
  };

  let sensors: Array<ISensor> = [];

  if (hardware.tpms?.length) sensors = [...hardware.tpms];

  if (hardware.pneumatics?.axle_load) {
    sensors.push({
      mac: hardware.pneumatics?.axle_load.mac,
      position: "AXLE_LOAD",
    });
  }

  if (hardware.pneumatics?.line_pressure) {
    sensors.push({
      mac: hardware.pneumatics?.line_pressure.mac,
      position: "LINE_PRESSURE",
    });
  }

  for (let i = 0; i < sensors.length; i++) {
    const firstSensor = sensors[i];
    const firstShortMac = getShortMac(firstSensor.mac);

    for (let k = 0; k < sensors.length; k++) {
      const secondSensor = sensors[k];
      const secondShortMac = getShortMac(secondSensor.mac);

      if (i !== k) {
        if (validShortMac(firstShortMac) && validShortMac(secondShortMac) && firstShortMac === secondShortMac) {
          shortMacError.value = true;
          shortMacError.text = `You installed two sensors with the same short MAC on positions 
          "${firstSensor.position}" and "${secondSensor.position}".\n Please use different sensor.`;
          break;
        }
      }
    }

    if (shortMacError.value) break;
  }

  return shortMacError;
};

const checkSensorsCount = (hardware: IHardware) => {
  const maxSensorCountPerGateway = 10;
  const gatewaysCount = hardware.gateways?.length || 0;
  let sensorsCount = 0;

  if (hardware.tpms) sensorsCount += hardware.tpms.length;

  if (hardware.pneumatics?.axle_load) sensorsCount += 1;

  if (hardware.pneumatics?.line_pressure) sensorsCount += 1;

  if (!gatewaysCount) return "Please go back and provision a gateway first";

  if (gatewaysCount === 1 && sensorsCount > maxSensorCountPerGateway) {
    return `Only 10 TPMS sensors can be provisioned per gateway, please either remove ${sensorsCount - maxSensorCountPerGateway} sensors, or provision an additional Gateway first and then add the additional TPMS sensors`;
  }

  if (gatewaysCount === 2 && sensorsCount > maxSensorCountPerGateway * 2) {
    return `The maximum number of TPMS sensors that are supported in this configuration is 20. Please limit provisioning to 20 or fewer TPMS sensor`;
  }
};


const checkPositionUniqueness = (sensors: any, context: TestContext) => {
  for (let i = 0; i < sensors.length; i++) {
    for (let j = 0; j < sensors.length; j++) {
      if (sensors[i].position === sensors[j].position && i !== j)
        return context.createError({
          message: `${context.path} same position at indexes ${i} and ${j}`,
        })
    }
  }

  return true
}

const macRegex = /^([\d\w]{2}-){5}[\d\w]{2}$/

const sensorSchema = object({
  mac: string().required().matches(macRegex, {
    message: "MAC must match pattern: XX-XX-XX-XX-XX-XX",
  }),
  position: string().required().max(100),
})

const assetHardwareSchema = object({
  gateways: array(
    object({
      device_type: string().max(100),
      esn: string().required().matches(macRegex, {
        message: "ESN must match pattern: XX-XX-XX-XX-XX-XX",
      }),
    })
  )
    .required()
    .min(1),
  smarthub_sensors: array(sensorSchema)
    .required()
    .test({ name: "uniquePosition", test: checkPositionUniqueness }),
  tpms: array(sensorSchema)
    .required()
    .test({ name: "uniquePosition", test: checkPositionUniqueness }),
  pneumatics: object({
    line_pressure: object({
      mac: string().required().matches(macRegex, {
        message: "MAC must match pattern: XX-XX-XX-XX-XX-XX",
      }),
    }).default(null),
    axle_load: object({
      mac: string().required().matches(macRegex, {
        message: "MAC must match pattern: XX-XX-XX-XX-XX-XX",
      }),
    }).default(null),
  }).required(),
})
  .test({
    name: "duplicateShortMac",
    test: (hardware, context) => {
      const shortMacError = findShortMacDuplicates(hardware as IHardware)
      if (shortMacError.value)
        return context.createError({ message: shortMacError.text })

      return true
    },
  })
  .test({
    name: "sensorsCount",
    test: (hardware, context) => {
      const sensorsCountError = checkSensorsCount(hardware as IHardware)
      if (sensorsCountError)
        return context.createError({ message: sensorsCountError })

      return true
    },
  })

export const provisionSchema = object({
  asset: object({
    customer_key: string().required("'customer_key' field is required in json").max(100),
    name: string().required("'name' field is required in json").max(100),
    vin: string().required("'vin' field is required in json").max(100),
    make: string().nullable().max(100).defined("'make' field is required in json"),
    model: string().nullable().max(100).defined("'model' field is required in json"),
    year: number().nullable().integer().defined("'year' field is required in json"),
    odometer_start: number().nullable().integer().min(0),
    type: mixed()
      .equals(
        ["tractor", "trailer"],
        'Unsupported asset type. Allowed types: "tractor", "trailer"'
      ).required("'type' is a required field"),
    ati_installed: mixed()
      .equals(
        [0, 1, 2],
        "Unsupported ati_installed option. Options: 0: no, 1: yes, 2: not sure"
      ),
    hardware: assetHardwareSchema.required(),
  }).required(),
  version: mixed()
    .equals(["0.5"], 'Only supported version is "0.5"'),
})
