import { useEffect, useState } from 'react'

import { pick, isNil, isNumber } from 'lodash'

import {
  getThresholdsByAssignedProfile,
  getTpmsTirePressure,
  psiToBar,
  convertDataEpochToDate,
  getTpmsTemperature,
} from '../../utils'

import { UnitsOfMeasurementConfig } from '../../constants/constants'

import { NoDataLabels } from '../../enums'

export const useDiagramData = ({
  assetTpmsStatus,
  preferences: { unitsOfMeasurementConfig, timezone },
  customerDefaults,
}) => {
  const [tpmsThresholds, setTpmsThresholds] = useState({})

  useEffect(() => {
    if (!assetTpmsStatus.isLoading && assetTpmsStatus.asset_id) {
      const defaultTpmsThresholds = pick(customerDefaults.tpms, [
        'critical_low_pressure_in_percent',
        'critical_over_pressure_in_percent',
        'low_pressure_in_percent',
        'over_pressure_in_percent',
      ])

      if (
        Object.values(assetTpmsStatus.tpms_profile_thresholds_percents).some(
          (i) => isNil(i),
        )
      ) {
        setTpmsThresholds(defaultTpmsThresholds)
      } else {
        setTpmsThresholds(assetTpmsStatus.tpms_profile_thresholds_percents)
      }
    }
  }, [assetTpmsStatus, customerDefaults])

  const getDescription = (
    pressure,
    thresholdsByAssignedProfile,
    isNoStatusSensor,
  ) => {
    if (isNoStatusSensor) return 'No Status'

    if (pressure < thresholdsByAssignedProfile.critical_low_pressure)
      return 'Critical Low Pressure'

    if (
      pressure > thresholdsByAssignedProfile.critical_low_pressure &&
      pressure < thresholdsByAssignedProfile.low_pressure
    )
      return 'Low Pressure'

    if (
      pressure > thresholdsByAssignedProfile.over_pressure &&
      pressure < thresholdsByAssignedProfile.critical_over_pressure
    )
      return 'Over Pressure'

    if (pressure > thresholdsByAssignedProfile.critical_over_pressure)
      return 'Critical Over Pressure'

    return 'Normal'
  }

  const getTireData = (sensor) => {
    const thresholdsByAssignedProfile = getThresholdsByAssignedProfile(
      unitsOfMeasurementConfig.pressure,
      sensor.cold_inflation_pressure_in_psi,
      tpmsThresholds,
      customerDefaults.tpms?.cold_inflation_pressure_in_psi,
    )

    const isNoStatusSensor = sensor.no_status
    const isPressure =
      sensor.cold_inflation_pressure_in_psi &&
      psiToBar(sensor.cold_inflation_pressure_in_psi).toFixed(2)
    const pressure = getTpmsTirePressure(
      sensor.pressure,
      unitsOfMeasurementConfig.pressure,
    )
    const temperature = getTpmsTemperature(
      sensor.temperature,
      unitsOfMeasurementConfig.temperature,
    )
    const data = {
      pressure,
      temperature,
      timestampPressure: sensor.timestamp_pressure
        ? convertDataEpochToDate(sensor.timestamp_pressure, null, null, true)
        : NoDataLabels.DASH,
      timestampTemperature: sensor.timestamp_temperature
        ? convertDataEpochToDate(sensor.timestamp_temperature, null, null, true)
        : null,
      coldInflationPressure:
        unitsOfMeasurementConfig.pressure ===
        UnitsOfMeasurementConfig.pressure.psi
          ? sensor.cold_inflation_pressure_in_psi
          : isPressure,
      description: getDescription(
        pressure,
        thresholdsByAssignedProfile,
        isNoStatusSensor,
      ),
      status: '',
      isNoStatusSensor,
      hidden: sensor.hidden,
      fastLeak: sensor.fast_leak,
    }

    if (isNumber(data.pressure)) {
      if (
        data.pressure < thresholdsByAssignedProfile.critical_low_pressure ||
        data.pressure > thresholdsByAssignedProfile.critical_over_pressure ||
        data.fastLeak
      ) {
        data.status = 'criticalWarning'
      } else if (
        data.pressure < thresholdsByAssignedProfile.low_pressure ||
        data.pressure > thresholdsByAssignedProfile.over_pressure
      ) {
        data.status = 'warning'
      } else {
        data.status = 'normal'
      }
    } else {
      data.status = 'unknown'
      data.pressure = NoDataLabels.DASH
    }

    if (isNoStatusSensor) {
      data.pressure = NoDataLabels.DASH
      data.temperature = NoDataLabels.DASH
      data.coldInflationPressure = NoDataLabels.DASH
      data.status = 'noStatus'
    }

    return data
  }

  return {
    pressureUnit:
      unitsOfMeasurementConfig.pressure ===
      UnitsOfMeasurementConfig.pressure.psi
        ? 'PSI'
        : 'Bar',
    timezone: timezone || 'America/Los_Angeles',
    getTireData,
  }
}
