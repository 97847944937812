import {LoadCalibrationReducer, LoadCalibrationTypes, UsersActionsType} from './types'

const initialState:LoadCalibrationReducer = {
    axleLoadAssets: {
        isLoading: false,
        data: [],
        error: {
            statusCode: 0,
            message: ""
        }
    },
    selectedAsset: null,
    calibrationData: {
        isLoading: false,
        data: [],
        totalCount: 0,
        offset: 0,
        limit: 10,
        error: {
            statusCode: 0,
            message: ""
        }
    }
}

const setField = (state:LoadCalibrationReducer, key:string, field:string, payload:any) => ({ ...state, [key]: { ...state[key as keyof typeof state], [field]: payload } })
const setSelectedAsset = (state:LoadCalibrationReducer, payload:any) => ({ ...state, selectedAsset: payload })
 
const axleLoadCalibration = (state:LoadCalibrationReducer = initialState, action:UsersActionsType) => {
    switch (action.type) {
        case LoadCalibrationTypes.LOADING:
            return setField(state, "axleLoadAssets", "isLoading", action.payload)
        case LoadCalibrationTypes.SET_ASSETS:
            return setField(state, "axleLoadAssets", "data", action.payload)
        case LoadCalibrationTypes.SET_AXLE_LOAD_ERROR:
            return setField(state, "axleLoadAssets", "error", action.payload)
        
        case LoadCalibrationTypes.SET_SELECTED:
            return setSelectedAsset(state, action.payload)
        
        case LoadCalibrationTypes.SET_LOAD_CALIBRATION_DATA:
            return setField(state, "calibrationData", "isLoading", action.payload)
        case LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA:
            return setField(state, "calibrationData", "data", action.payload)
        case LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT:
            return setField(state, "calibrationData", "totalCount", action.payload)
        case LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET:
            return setField(state, "calibrationData", "offset", action.payload)
        case LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT:
            return setField(state, "calibrationData", "limit", action.payload)
        case LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_ERROR:
            return setField(state, "calibrationData", "error", action.payload)
        default:
            return state
    }
}

export default axleLoadCalibration