import { useEffect, useState } from 'react'
import { getClearLabel } from '../../utils'
import { NoDataLabels } from '../../enums'

const ColumnsIds = {
  Name: 'name',
  Latitude: 'latitude',
  Longitude: 'longitude',
  Type: 'type',
  CustomerName: 'customer_name',
  TagsCount: 'tags_count',
}

export const useColumns = () => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns([
      {
        label: 'Name',
        id: ColumnsIds.Name,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Customer Name',
        id: ColumnsIds.CustomerName,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Type',
        id: ColumnsIds.Type,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Tags Count',
        id: ColumnsIds.TagsCount,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Latitude',
        id: ColumnsIds.Latitude,
        isDraggable: true,
      },
      {
        label: 'Longitude',
        id: ColumnsIds.Longitude,
        isDraggable: true,
      },
    ])
  }, [])

  return columns
}

export const useTableRows = (data) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const dataRows = []
    data.forEach((d) => {
      const link =
        d.type === 'ASSET'
          ? `/tag-asset-details/${d.id}`
          : `/tag-location-details/${d.id}`
      const row = {
        id: d.id,
        data: d,
        columns: [
          {
            columnId: ColumnsIds.Name,
            type: 'link',
            label: d.name,
            link,
          },
          {
            columnId: ColumnsIds.CustomerName,
            type: 'link',
            label: d.customer_name,
            link,
          },
          {
            columnId: ColumnsIds.Type,
            type: 'link',
            label: d.type ? getClearLabel(d.type) : NoDataLabels.DASH,
            link,
          },
          {
            columnId: ColumnsIds.TagsCount,
            type: 'link',
            label: d.tags_count || NoDataLabels.DASH,
            link,
          },
          {
            columnId: ColumnsIds.Latitude,
            type: 'link',
            label: d.latitude || NoDataLabels.DASH,
            link,
          },
          {
            columnId: ColumnsIds.Longitude,
            type: 'link',
            label: d.longitude || NoDataLabels.DASH,
            link,
          },
        ],
      }
      dataRows.push(row)
    })
    setRows(dataRows)
  }, [data])
  return rows
}

export const useMapData = (data) => {
  const [mapData, setMapData] = useState([])

  useEffect(() => {
    const mD = []

    data.forEach((d) => {
      mD.push({
        id: d.id,
        latitude: Number(d.latitude),
        longitude: Number(d.longitude),
        name: d.name,
        customer_name: d.customer_name,
        tagsCount: d.tags_count,
        type: 'asset',
      })
    })
    setMapData(mD)
  }, [data])

  return mapData
}
