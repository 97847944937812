import React, { useContext, useMemo, useState } from 'react'

import RivataModule from '../../components/RivataModule'
import RivataLoader from '../../components/RivataLoader'
import RivataLineChart from '../../components/RivataLineChart'

import { useTypedSelector } from '../../hooks/useTypedSelector'


import { AssetDetailsContext } from '../../pages/AssetDetails'
import { composeVibrationChartData, vibrationChartOptions } from './utils'
import { AxlesGroups } from '../../constants/constants'
import { chartThresholdAnnotations, getAxlesDropdownItems } from '../../utils/chartUtils'
import RivataDropdown from '../../components/RivataDropdown'
import StatusAlert from '../../components/StatusAlert'
import RivataToggle, { RivataToggleItemType } from '../../components/RivataToggle'
import { composeVibrationLineChartDataOld } from '../HubVibration/utils'
import { chartDefaultOptions } from '../../components/RivataLineChart/chartDefaultOptions'
import { saveGoogleAnalyticsEvent } from '../../utils/utils'


interface Props {
    width: number
}

const chartOptions: IDictStrKeys<RivataToggleItemType> = {
    oldChart: { id: "legacy", label: "Legacy Chart" },
    actualChart: { id: "health", label: "Health State" },
}

const VibrationChart: React.FC<Props> = ({
    width
}) => {
    const {
        vibrations: { isLoading, data, status },
        customerDefaults,
    } = useTypedSelector(state => ({
        vibrations: state.assetDetails.vibrations,
        customerDefaults: state.common.customerDefaults,
    }))

    const {
        locale,
        chartsXAxisLabel,
        healthColors,
        timestamp,
        wheelColors
    } = useContext(AssetDetailsContext)

    const actualChartId = "vibrationV2"
    const legacyChartId = "vibrationV1"

    const [selectedAxle, setSelectedAxle] = useState("all")
    const [selectedChart, setSelectedChart] = useState(chartOptions.actualChart)

    const chartData = useMemo(() => {
        const datasets = selectedChart.id === "health" ? composeVibrationChartData(data, healthColors, customerDefaults) : composeVibrationLineChartDataOld(data, wheelColors)

        if (selectedChart.id === "health") {
            const offset = 0.5
            vibrationChartOptions.yScale.min = offset
            vibrationChartOptions.yScale.max = datasets.datasets.length + offset
        }

        return datasets
    }, [data, healthColors, selectedChart, wheelColors, customerDefaults])

    const dropdownItems = useMemo(() => {
        return getAxlesDropdownItems(chartData?.datasets?.map(el => el.label))
    }, [chartData])

    const filtredChartData = useMemo(() => {
        if (selectedAxle === "all" || !chartData) return chartData

        return { datasets: chartData.datasets.filter(line => AxlesGroups[selectedAxle].includes(line.label)) }
    }, [chartData, selectedAxle])

    const annotations = useMemo(() => {
        if (!timestamp) return {}

        return chartThresholdAnnotations(
            legacyChartId,
            customerDefaults?.smarthub.vibration_treshold,
            timestamp,
            healthColors,
        )
    }, [customerDefaults, timestamp, healthColors])

    return (
        <RivataModule
            title="Wheel End Vibration"
            width={width}
            fullScreenModalModeEnabled
            collapsible
            locale={locale}
            error={status}
            filters={
                <>
                    <RivataToggle
                        item1={chartOptions.oldChart}
                        item2={chartOptions.actualChart}
                        selectedId={selectedChart.id}
                        onToggle={setSelectedChart}
                    />

                    {dropdownItems.length > 1 && (
                        <div>
                            <label className="mr-2">Select Axle:</label>

                            <RivataDropdown
                                items={dropdownItems}
                                selected={selectedAxle}
                                onSelect={(value: string) => {
                                    saveGoogleAnalyticsEvent('charts_axle_filter', { axle: value })
                                    setSelectedAxle(value)
                                }}
                            />
                        </div>
                    )}
                </>
            }
        >
            {isLoading ? (
                <RivataLoader />
            ) : filtredChartData.datasets.length > 0 ? (
                <>
                    {selectedChart.id === "health" && (
                        <RivataLineChart
                            id={actualChartId}
                            height={400}
                            isSync={false}
                            data={filtredChartData}
                            yAxisLabel=""
                            displayX={true}
                            xAxisLabel={chartsXAxisLabel}
                            {...vibrationChartOptions}
                        />
                    )}

                    {selectedChart.id === "legacy" && (
                        <RivataLineChart
                            height={400}
                            id={legacyChartId}
                            data={filtredChartData}
                            yAxisLabel="Level (x Nominal)"
                            xAxisLabel={chartsXAxisLabel}
                            annotations={annotations}
                            tooltip={vibrationChartOptions.tooltip}
                            point={chartDefaultOptions.elements?.point}
                        />
                    )}
                </>
            ) : (
                <StatusAlert customText="No Data" color="success" statusCode={null} statusText={null} />
            )}
        </RivataModule>
    )
}

export default VibrationChart
