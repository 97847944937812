import baseApi from "./BaseApi"

class TestingUtilsApi {
  testingWriteToRivataDb = (data: any) => {
    return baseApi.post(`rivata/testing_utils/write_to_db`, data)
  }
}

const testingUtilsApi = new TestingUtilsApi()

export default testingUtilsApi
