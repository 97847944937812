import React from "react";
import DeleteModal from "../../components/DeleteModal";
import { NoDataLabels, UnitsOfMeasurement } from "../../enums";
import { kgToLbs, convertDataEpochToDate } from "../../utils";

export interface IItem {
  id: number;
  is_loaded: boolean;
  vehicle_weight: number;
  timestamp: number;
}
interface IcalibrationDelete {
  deleteItem: IItem;
  onCancel: () => void;
  onDelete: () => void;
  selectedUnitsOfMeasurement: string;
}

const CalibrationDeleteModal: React.FC<IcalibrationDelete> = ({
  deleteItem: { id, is_loaded, vehicle_weight, timestamp },
  onCancel,
  onDelete,
  selectedUnitsOfMeasurement,
}) => {
  return (
    <DeleteModal
      header={"Delete This Calibration Data?"}
      open={!!id}
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <div>
        <label>State: {is_loaded ? "Loaded" : "Empty"}</label>
        <br />
        <label>
          Weight:{" "}
          {selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial
            ? kgToLbs(vehicle_weight).toFixed(2) + " lbs"
            : vehicle_weight.toFixed(2) + " kg"}
        </label>
        <br />
        <label>
          Weight Timestamp:{" "}
          {timestamp
            ? convertDataEpochToDate(timestamp, null, null, true)
            : NoDataLabels.DASH}
        </label>
      </div>
    </DeleteModal>
  );
};

export default CalibrationDeleteModal;
