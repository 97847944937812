import React from 'react'
import { Col, Label, Input } from 'reactstrap'
import { ICustomerOptions } from '../types'

interface ICompanySelectorProps {
    customerIds: Array<number>
    options: Array<ICustomerOptions>
    setPermissions: (id: Array<number> | null) => void
}

const CompanySelector: React.FC<ICompanySelectorProps> = ({
    customerIds,
    options,
    setPermissions
}) => {
    return (
        <Col md="12">
            <h6>Customers</h6>
            <Col>
                {options.map((customer) => {
                    return (
                        <div key={customer.id} className={`d-flex align-items-center ml-${customer.parent_id ? 4 : 2}`}>
                            <Label className="mb-0">{customer.name}</Label>
                            <Input 
                                type="checkbox" 
                                className="mb-1"
                                disabled={customer.disabled}
                                checked={customer.id ? customerIds.includes(customer.id) : false}
                                onChange={() => {
                                    setPermissions([customer.id])
                                }} 
                            />
                        </div>
                    )
                })}
            </Col>
        </Col>
    )
}

export default CompanySelector