import { Dispatch } from 'redux'

import {
  ICustomerReqBody,
  ICustomerUpdateReqBody,
} from '../../pages/Provision/types'
import { IFilterOptions, IgetState, ISortOptions, ThunkResult } from '../types'
import { ProvisionActionTypes, ProvisionAction, filterType } from './types'

import { sortByAlphabet, upperCaseFirstLetter } from '../../utils'
import { getStatusObj } from '../../utils/utils'

import { WARNING_TYPE } from '../../constants'

import {
  loadingCustomers,
  setCustomers,
  setCustomersError,
  fetchCustomers,
  setSelectedCustomersList,
  setSelectedCustomersCache,
} from '../common/action'
import { store } from '../store'
import api from '../../services/api'
import { NoDataLabels } from '../../enums'

export const setProvisionAssetsLimit = (payload: number) => ({
  type: ProvisionActionTypes.SET_ASSETS_LIMIT,
  payload: payload,
})
export const setProvisionAssetsOffset = (payload: number) => ({
  type: ProvisionActionTypes.SET_ASSETS_OFFSET,
  payload: payload,
})
export const setProvisionAssetsSortOptions = (payload: ISortOptions) => ({
  type: ProvisionActionTypes.SET_ASSETS_SORT_OPTIONS,
  payload: payload,
})
export const setProvisionAssetsError = (payload: IStatus) => ({
  type: ProvisionActionTypes.SET_ASSETS_ERROR,
  payload: payload,
})
export const setProvisionAssetsFilter = (payload: filterType) => ({
  type: ProvisionActionTypes.SET_ASSETS_SEARCH_FILTER,
  payload,
})

export const setTpmsAssets = (payload: Array<IAsset>) => ({
  type: ProvisionActionTypes.SET_TPMS_ASSETS,
  payload: payload,
})
export const setTpmsPageLimit = (payload: number) => ({
  type: ProvisionActionTypes.SET_TPMS_ASSETS_LIMIT,
  payload: payload,
})
export const setTpmsPageOffset = (payload: number) => ({
  type: ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET,
  payload: payload,
})
export const setTpmsPageSortOptions = (payload: ISortOptions) => ({
  type: ProvisionActionTypes.SET_TPMS_ASSETS_SORT_OPTIONS,
  payload: payload,
})
export const setTpmsAssetsFilter = (payload: IFilterOptions) => ({
  type: ProvisionActionTypes.SET_TPMS_ASSETS_FILTER,
  payload: payload,
})

export const setUsersPageSortOptions = (payload: ISortOptions) => ({
  type: ProvisionActionTypes.SET_USERS_SORT_OPTIONS,
  payload: payload,
})
export const setUsersPageOffset = (payload: number) => ({
  type: ProvisionActionTypes.SET_USERS_OFFSET,
  payload: payload,
})
export const setUsersPageLimit = (payload: number) => ({
  type: ProvisionActionTypes.SET_USERS_LIMIT,
  payload: payload,
})
export const setUsersFlter = (payload: string) => ({
  type: ProvisionActionTypes.SET_USERS_FILTER,
  payload,
})
export const setUsersPageError = (payload: IStatus) => ({
  type: ProvisionActionTypes.SET_USERS_ERROR,
  payload: payload,
})

function fetchProvisionPage() {
  return function (dispatch: Dispatch<any>) {
    dispatch(fetchProvisionAssets())
    dispatch(fetchPagedTpmsAssets())
    dispatch(fetchProvisionTpmsProfiles())
    dispatch(fetchUsers())
  }
}

export const addCustomer = (data: ICustomerReqBody) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(loadingCustomers(true))

      const customer = await api.postCustomer(data)
      const status = getStatusObj()

      dispatch(setSelectedCustomersList([customer]))

      setSelectedCustomersCache(JSON.stringify([customer]))
      dispatch(setCustomersError(status))

      return status
    } catch (err) {
      const errorObj = getStatusObj(err)
      let message = errorObj.message.split(':')
      let parsedMessage = message[1] + message[2]
      const status = getStatusObj(err, parsedMessage)

      dispatch(setCustomersError(status))

      return status
    } finally {
      dispatch(fetchCustomers())
      dispatch(loadingCustomers(false))
    }
  }
}

export const updateCustomer = (data: ICustomerUpdateReqBody) => {
  return async (dispatch: Dispatch<any>, getState: IgetState) => {
    if (!data.description && !data.preferences) return
    try {
      dispatch(loadingCustomers(true))

      const customer = await api.putCustomer(data)
      const customers = [...getState().common.customers.data]

      const index = customers.findIndex((c) => c.id === customer.id)
      if (index > -1) {
        customers.splice(index, 1, customer)
      }
      dispatch(setCustomers(sortByAlphabet(customers, 'name')))
      dispatch(
        setCustomersError(
          getStatusObj(
            { statusCode: 200 },
            `Customer "${customer.name}" successfully updated`,
          ),
        ),
      )

      const status = getStatusObj()
      dispatch(setSelectedCustomersList([customer]))
      dispatch(setCustomersError(status))
    } catch (err) {
      dispatch(setCustomersError(getStatusObj(err)))
    } finally {
      dispatch(loadingCustomers(false))
    }
  }
}

export const deleteCustomer = (id: number) => {
  return async (dispatch: Dispatch<any>, getState: IgetState) => {
    try {
      dispatch(loadingCustomers(true))

      await api.deleteCustomer(id)

      dispatch(fetchCustomers())
      dispatch(setSelectedCustomersList([...getState().common.customers.data]))

      dispatch(fetchProvisionPage())

      dispatch(setCustomersError(getStatusObj()))
    } catch (err) {
      dispatch(setCustomersError(getStatusObj(err)))
    } finally {
      dispatch(loadingCustomers(false))
    }
  }
}

export const fetchProvisionAssets = () => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
      const {
        pageOffset,
        pageLimit,
        sortOptions: { column, direction },
        filter,
      } = getState().provision.assets
      const { hideAutogeneratedAssets } = getState().common

      const queryParams = new URLSearchParams(filter)

      queryParams.append('offset', pageOffset.toString())
      queryParams.append('limit', pageLimit.toString())
      if (column && direction) {
        queryParams.append('sort_column', column)
        queryParams.append('sort_direction', direction)
      }
      if (hideAutogeneratedAssets) {
        queryParams.append('hide_autogenerated', 'true')
      }

      const assetsRes = await api.getAssetsV2(queryParams.toString())

      const { paged_data, total_count } = assetsRes

      dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: paged_data })
      dispatch({
        type: ProvisionActionTypes.SET_ASSETS_COUNT,
        payload: total_count,
      })
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_ASSETS_ERROR,
        payload: getStatusObj(err),
      })
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
    }
  }
}

export const addAsset = (data: any) => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
      const asset = await api.postAsset(data)

      const assets = getState().provision.assets.data
      const status = getStatusObj(
        { statusCode: 201 },
        `Asset "${asset.name}" successfully created`,
      )

      dispatch({
        type: ProvisionActionTypes.SET_ASSETS,
        payload: [...assets, asset],
      })
      dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

      return status
    } catch (err) {
      const status = getStatusObj(err)
      dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

      return status
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
    }
  }
}

export const updateAsset = (data: any) => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
      const userName = getState().auth.user.userName

      const asset = await api.putAsset(data, userName)

      const assets = [...getState().provision.assets.data]
      const index = assets.findIndex((a) => a.id === asset.id)
      if (index > -1) {
        assets.splice(index, 1, asset)
      }

      const status = getStatusObj(
        { statusCode: 201 },
        `Asset "${asset.name}" successfully updated`,
      )

      dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: assets })
      dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

      return status
    } catch (err) {
      const status = getStatusObj(err)
      dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })
      return status
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
    }
  }
}

export const deleteAsset = (id: number) => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
      const asset = await api.deleteAsset(id)

      const assets = [...getState().provision.assets.data]
      const index = assets.findIndex((a) => a.id === asset.id)
      if (index > -1) {
        assets.splice(index, 1)
      }

      dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: assets })
      dispatch({
        type: ProvisionActionTypes.SET_ASSETS_ERROR,
        payload: getStatusObj(
          { statusCode: 201 },
          `Asset "${asset.name}" successfully deleted`,
        ),
      })
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_ASSETS_ERROR,
        payload: getStatusObj(
          err,
          'Something went wrong, please try again later',
        ),
      })
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
    }
  }
}

export const fetchUsers = (): ThunkResult<void> => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })

      const {
        sortOptions: { column, direction },
        pageLimit,
        pageOffset,
        filter,
      } = getState().provision.users
      const customersArr = getState().common.customers.data
      console.log('here', filter)

      const queryParams = new URLSearchParams(filter)

      queryParams.append('offset', pageOffset.toString())
      queryParams.append('limit', pageLimit.toString())
      if (column && direction) {
        queryParams.append('sort_column', column)
        queryParams.append('sort_direction', direction)
      }

      const res = await api.getUsers(queryParams.toString())

      const { total_count, paged_data } = res
      const updatedPageData = paged_data.map((el: any) => {
        return {
          ...el,
          customers: el.customers.map((c: any) => {
            const parent_id = customersArr.find((e: any) => e.id === c.id)
              ?.parent_id
            return { ...c, parent_id }
          }),
        }
      })
      dispatch({
        type: ProvisionActionTypes.SET_USERS,
        payload: updatedPageData,
      })
      dispatch({
        type: ProvisionActionTypes.SET_USERS_COUNT,
        payload: total_count,
      })
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_USERS_ERROR,
        payload: getStatusObj(err),
      })
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
    }
  }
}
export interface ICreateUserRequest {
  email_address: string
  first_name: string
  last_name: string
  phone_number: string | null
  role: string
  time_zone: string
  username: string
  customer_ids: Array<number>
}

export const addUser = (reqBody: ICreateUserRequest): ThunkResult<IStatus> => {
  return async (dispatch) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })

      await api.postUser(reqBody)
      const status = getStatusObj(
        { statusCode: 201 },
        `User "${reqBody.username}" successfully created`,
      )

      dispatch(fetchUsers())
      dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: status })

      return status
    } catch (err) {
      const errorObj = getStatusObj(err)
      let str = errorObj.message.split(': ')
      let message = str[str.length - 1]
      const status = getStatusObj(err, message)

      dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: status })

      return status
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
    }
  }
}

export interface IUpdateUserRequest extends ICreateUserRequest {
  old_phone_number: string | undefined | null
}

export const updateUser = (reqBody: IUpdateUserRequest): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })

      await api.putUser(reqBody)
      dispatch(fetchUsers())
      dispatch({
        type: ProvisionActionTypes.SET_USERS_ERROR,
        payload: getStatusObj(
          { statusCode: 200 },
          `User "${reqBody.username}" successfully updated`,
        ),
      })
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_USERS_ERROR,
        payload: getStatusObj(err),
      })
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
    }
  }
}

export const deleteUser = (username: string) => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })

      const user = await api.deleteUser(username)
      const users = [...getState().provision.users.data]
      const index = users.findIndex((u) => u.username === user.username)
      if (index > -1) {
        users.splice(index, 1)
      }

      dispatch({ type: ProvisionActionTypes.SET_USERS, payload: users })
      dispatch({
        type: ProvisionActionTypes.SET_USERS_ERROR,
        payload: getStatusObj(
          { statusCode: 200 },
          `User "${username}" successfully deleted`,
        ),
      })
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_USERS_ERROR,
        payload: getStatusObj(err),
      })
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
    }
  }
}

export const fetchPagedTpmsAssets = () => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({
        type: ProvisionActionTypes.LOADING_TPMS_ASSETS,
        payload: true,
      })

      const {
        pageOffset,
        pageLimit,
        sortOptions: { column, direction },
        filter,
      } = getState().provision.tpmsAssets
      const { hideAutogeneratedAssets } = getState().common

      const queryParams = new URLSearchParams(filter)

      queryParams.append('offset', pageOffset.toString())
      queryParams.append('limit', pageLimit.toString())
      if (column && direction) {
        queryParams.append('sort_column', column)
        queryParams.append('sort_direction', direction)
      }
      if (hideAutogeneratedAssets) {
        queryParams.append('hide_autogenerated', 'true')
      }

      const assetsRes = await api.getAssetsV2(queryParams.toString())

      const { paged_data, total_count } = assetsRes

      const data = paged_data.map((a: IAsset) => ({
        id: a.id,
        customer: a.customer_name,
        type: a.asset_type
          ? upperCaseFirstLetter(a.asset_type)
          : NoDataLabels.DASH,
        tpmsProfile: a.warning_setting_name || 'Default',
        vin: a.vin,
        assetName: a.name,
        assetGroups: a.asset_groups,
        assetSubtype: a.asset_subtype,
      }))

      dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS, payload: data })
      dispatch({
        type: ProvisionActionTypes.SET_TPMS_ASSETS_COUNT,
        payload: total_count,
      })
      dispatch({
        type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR,
        payload: getStatusObj(),
      })
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR,
        payload: getStatusObj(err),
      })
    } finally {
      dispatch({
        type: ProvisionActionTypes.LOADING_TPMS_ASSETS,
        payload: false,
      })
    }
  }
}

export const fetchProvisionTpmsProfiles = () => {
  return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
    try {
      dispatch({
        type: ProvisionActionTypes.LOADING_TPMS_ASSETS,
        payload: true,
      })
      const res = await api.getWarningsSettings(
        WARNING_TYPE.TIRE_PRESSURE_SENSOR,
      )

      let isThereDefaultProfile = false

      const profiles = Object.keys(res).map((id) => {
        const profile = res[id]

        if (profile.name === 'Default') {
          isThereDefaultProfile = true
        }

        return {
          id,
          label: profile.name,
        }
      })

      if (!isThereDefaultProfile) {
        profiles.push({ id: 'systemDefaults', label: 'Default' })
      }

      dispatch({
        type: ProvisionActionTypes.SET_TPMS_PROFILES,
        payload: profiles,
      })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch({
        type: ProvisionActionTypes.LOADING_TPMS_ASSETS,
        payload: false,
      })
    }
  }
}

export const assignWarningSettingsToAssets = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: ProvisionActionTypes.LOADING_TPMS_ASSETS,
        payload: true,
      })

      const res = await api.assignWarningSettingsToAssets(data)
      dispatch(fetchPagedTpmsAssets())
      dispatch({
        type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR,
        payload: getStatusObj(),
      })

      return res
    } catch (err) {
      dispatch({
        type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR,
        payload: getStatusObj(err),
      })
    } finally {
      dispatch({
        type: ProvisionActionTypes.LOADING_TPMS_ASSETS,
        payload: false,
      })
    }
  }
}

export const migrateAssetBetweenCustomers = (payload: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })

      await api.migrateAssetBetweenCustomers(payload)

      dispatch({
        type: ProvisionActionTypes.SET_ASSETS_ERROR,
        payload: getStatusObj(
          { statusCode: 201 },
          `Asset ${payload.asset_vin} migrated`,
        ),
      })

      return getStatusObj({
        statusCode: 201,
        message: `Asset ${payload.asset_vin} migrated`,
      })
    } catch (err) {
      const status = getStatusObj(err)
      dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

      return status
    } finally {
      dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
    }
  }
}

export const resetProvisionPageReducer = () => ({
  type: ProvisionActionTypes.RESET_PROVISION_PAGE_REDUCER,
  payload: true,
})

export const removeProvisionAssetGroupFilter = () => ({
  type: ProvisionActionTypes.REMOVE_PROVISION_ASSET_GROUP_FILTER,
  payload: null,
})

export const removeProvisionTpmsAssetGroupFilter = () => ({
  type: ProvisionActionTypes.REMOVE_PROVISION_TPMS_ASSET_GROUP_FILTER,
  payload: null,
})

store.subscribe(() => {
  const lastAction = store.getState().lastAction

  if (
    lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET ||
    lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_SORT_OPTIONS
  ) {
    store.dispatch(fetchPagedTpmsAssets())
  }

  if (
    lastAction.type === ProvisionActionTypes.SET_USERS_SORT_OPTIONS ||
    lastAction.type === ProvisionActionTypes.SET_USERS_OFFSET
  ) {
    store.dispatch(fetchUsers())
  }

  if (
    lastAction.type === ProvisionActionTypes.SET_ASSETS_SORT_OPTIONS ||
    lastAction.type === ProvisionActionTypes.SET_ASSETS_OFFSET
  ) {
    store.dispatch(fetchProvisionAssets())
  }

  if (
    lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_LIMIT ||
    lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_FILTER
  ) {
    store.dispatch({
      type: ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET,
      payload: 0,
    })
  }

  if (
    lastAction.type === ProvisionActionTypes.SET_USERS_FILTER ||
    lastAction.type === ProvisionActionTypes.SET_USERS_LIMIT
  ) {
    store.dispatch({ type: ProvisionActionTypes.SET_USERS_OFFSET, payload: 0 })
  }

  if (
    lastAction.type === ProvisionActionTypes.SET_ASSETS_SEARCH_FILTER ||
    lastAction.type === ProvisionActionTypes.SET_ASSETS_LIMIT
  ) {
    store.dispatch({ type: ProvisionActionTypes.SET_ASSETS_OFFSET, payload: 0 })
  }
})
