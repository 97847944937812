import React, { useEffect, useMemo, useState } from 'react'
// import { FormatterProps } from 'react-data-grid'
import { IColumn, IRow } from '../../../components/RivataGrid'
import TableActions from '../../../components/TableActions'
import { LocationTypes, NoDataLabels } from '../../../enums'
import { getClearLabel } from '../../../utils'
import { RenderCellProps } from 'react-data-grid'

const ColumnsIds: { [key: string]: string } = {
  Name: 'name',
  Geofence_Groups: 'geofence_groups',
  // Customer: "customer_name",
  Customers: 'sharedWithString',
  Geofence_Type: 'location_type_id',
  Gateways: 'gateways',
  Created_At: 'formatted_created_at_datetime',
  Deleted_At: 'deleteDateTime',
}

const composeFormatter = (
  onDelete?: (id: number) => void,
  onEdit?: (id: number) => void,
  onCustomAction1?: (id: number) => void,
  onCustomAction2?: (id: number) => void,
  onRecreate?: (id: number) => void,
) => {
  return (cellData: any) => {
    const id = parseInt(cellData.row.id)
    const locationTypeId = parseInt(cellData.row.location_type_id)
    const haveAccessToCreator = cellData.row.haveAccessToCreator

    if (cellData.row.is_deleted)
      return (
        <TableActions
          disabled={false}
          onUpdate={() => onRecreate && onRecreate(id)}
          onCustomAction1={() => onCustomAction2 && onCustomAction2(id)}
          customAction1Icon={'fa fa-compass fa-lg'}
          uniqueKey={id}
          tooltipTexts={{
            editTooltipText: 'Re-Create Geofence',
            customAction1TooltipText: 'Zoom',
          }}
        />
      )

    return (
      <TableActions
        disabled={false}
        onDelete={() => onDelete && onDelete(id)}
        onUpdate={() => onEdit && onEdit(id)}
        onCustomAction1={() => onCustomAction2 && onCustomAction2(id)}
        onCustomAction2={
          locationTypeId === LocationTypes.TAG_GEOFENCE
            ? () => onCustomAction1 && onCustomAction1(id)
            : null
        }
        customAction1Icon={'fa fa-compass fa-lg'}
        customAction2Icon={'far fa-plus-square fa-lg'}
        uniqueKey={id}
        tooltipTexts={{
          deleteTooltipText: !haveAccessToCreator
            ? "You don't have access to delete this geofence"
            : 'Delete Geofence',
          editTooltipText: !haveAccessToCreator
            ? "You don't have access to edit this geofence"
            : 'Edit Geofence',
          customAction1TooltipText: 'Zoom',
          customAction2TooltipText: !haveAccessToCreator
            ? "You don't have access to edit this geofence"
            : 'Assign Gateways',
        }}
        disabledSpecific={{
          delete: !haveAccessToCreator,
          edit: !haveAccessToCreator,
          custom2: !haveAccessToCreator,
        }}
      />
    )
  }
}

interface IuseColumns {
  (
    customers: Array<ICustomer>,
    onDelete?: (id: number) => any,
    onEdit?: (id: number) => any,
    onCustomAction1?: (id: number) => any,
    onCustomAction2?: (id: number) => any,
    showDeleted?: boolean,
    onRecreate?: (id: number) => any,
  ): Array<IColumn>
}

export const useColumns: IuseColumns = (
  customers,
  onDelete,
  onEdit,
  onCustomAction1,
  onCustomAction2,
  showDeleted,
  onRecreate,
) => {
  // const [columns, setColumns] = useState<Array<IColumn>>([])

  return useMemo(() => {
    const keys = Object.keys(ColumnsIds)

    if (customers.length === 1) {
      const idx = keys.findIndex((el) => el === 'Customers')
      if (idx > -1) keys.splice(idx, 1)
    }

    if (!showDeleted) {
      const idx = keys.findIndex((el) => el === 'Deleted_At')
      if (idx > -1) keys.splice(idx, 1)
    }

    const width = `${Math.floor(90 / keys.length)}%`

    const colsList: Array<IColumn> = keys.map((key, i) => {
      return {
        name: getClearLabel(key),
        key,
        minWidth: 100,
        width: i === keys.length - 1 ? undefined : width,
      }
    })

    const actions = {
      name: 'Actions',
      key: 'actions',
      minWidth: 120,
      width: 154,
      renderCell: composeFormatter(
        onDelete,
        onEdit,
        onCustomAction1,
        onCustomAction2,
        onRecreate,
      ),
    }

    // make last column not resizable
    const lastColumn = { ...colsList[colsList.length - 1] }
    lastColumn.resizable = false
    colsList[colsList.length - 1] = lastColumn

    colsList.unshift(actions)

    return colsList
  }, [
    onDelete,
    onEdit,
    onCustomAction1,
    onCustomAction2,
    showDeleted,
    onRecreate,
    customers,
  ])
}

export const useTableRows = (geofences: Array<IGeofence>) => {
  const [rows, setRows] = useState<Array<IRow>>([])

  useEffect(() => {
    const keys = Object.keys(ColumnsIds)

    const rowsList: Array<IRow> = geofences.map((geo) => {
      const row: IRow = { ...geo }

      keys.forEach((key) => {
        if (key === 'Geofence_Type') {
          if (geo.location_type_id === 3) row[key] = 'Tag Geofence'
          else if (geo.location_type_id === 2) row[key] = 'Geofence'
        } else if (key === 'Name' && geo.is_deleted) {
          row[key] = geo.nameDeleted
        } else if (key === 'Geofence_Groups') {
          const groupData = row.geofence_groups?.map((group: any) => {
            return group.name
          })
          row[key] =
            groupData && groupData.length > 0
              ? groupData.join(', ')
              : NoDataLabels.DASH
        } else row[key] = geo[ColumnsIds[key]]?.toString() || NoDataLabels.DASH
      })

      return row
    })

    setRows(rowsList)
  }, [geofences])

  return rows
}
