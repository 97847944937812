import React from 'react'
import RivataGrid, { IColumn } from '../../components/RivataGrid'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'
import { convertDataEpochToDate } from '../../utils'
import { NoDataLabels } from '../../enums'

interface Props {
  locale: ILocale
  place: any
  isLoading: boolean
  status: IStatus
}

const CargotagGrid: React.FC<Props> = ({
  locale,
  place,
  isLoading,
  status,
}) => {
  const columns: Array<IColumn> = [
    { name: 'Tag Mac', key: 'cargotag_mac', width: '25%', minWidth: 200 },
    {
      name: 'Cargo Tag Name',
      key: 'cargo_tag_name',
      width: '25%',
      minWidth: 200,
    },
    {
      name: 'Reported From',
      key: 'reported_from',
      width: '25%',
      minWidth: 200,
    },
    { name: 'Last Reported Time', key: 'last_reported_time', resizable: false },
  ]

  const rows =
    place && place.tags_data
      ? place.tags_data.map((tagData: any) => {
          return {
            cargotag_mac: tagData.cargotag_mac,
            cargo_tag_name: tagData.cargo_tag_name ?? NoDataLabels.DASH,
            reported_from: tagData.reported_from,
            last_reported_time: convertDataEpochToDate(
              tagData.epoch,
              null,
              null,
              true,
            ),
          }
        })
      : []

  return (
    <RivataModule
      title={'Cargotags'}
      locale={locale}
      width={12}
      filters={null}
      error={status}
    >
      {isLoading ? (
        <RivataLoader />
      ) : rows.length ? (
        <RivataGrid columns={columns} rows={rows} />
      ) : (
        <StatusAlert
          color='success'
          customText='No Data'
          statusCode={undefined}
          statusText={undefined}
        />
      )}
    </RivataModule>
  )
}

export default React.memo(CargotagGrid)
