import React, { ReactNode } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { ActiveWarningSubtype, FleetSummaryModuleProps } from '.'
import circleCritWarn from '../../assets/images/icons/svg/circle_crit_warn.svg'
import circleOk from '../../assets/images/icons/svg/circle_ok.svg'
import circleWarn from '../../assets/images/icons/svg/circle_warn.svg'
import AssetCard, { AssetCardItem } from '../../components/AssetCard'
import { WarningCase, WarningCaseToLabelMapping } from '../../enums'
import './styles.scss'
import { useActions } from '../../hooks/useActions'

enum AssetHealthStatusGroup {
  Tire = 'tire',
  Hub = 'hub',
  LinePressure = 'line_pressure',
  NoStatus = 'no_status',
  AssetState = 'asset_state',
  AssetSpeed = 'asset_speed',
  Normal = 'ok',
}

const AssetHealthGroupToLabelMapping: Record<AssetHealthStatusGroup, string> = {
  [AssetHealthStatusGroup.Tire]: 'Tire',
  [AssetHealthStatusGroup.Hub]: 'Hub',
  [AssetHealthStatusGroup.LinePressure]: 'Line pressure',
  [AssetHealthStatusGroup.NoStatus]: 'No status',
  [AssetHealthStatusGroup.AssetState]: 'Asset State',
  [AssetHealthStatusGroup.AssetSpeed]: 'Asset Speed',
  [AssetHealthStatusGroup.Normal]: 'Normal',
} as const

const WarningCaseToAssetHealthStatusGroupMapping: Record<
  WarningCase,
  AssetHealthStatusGroup
> = {
  [WarningCase.CRITICAL_OVER_PRESSURE]: AssetHealthStatusGroup.Tire,
  [WarningCase.OVER_PRESSURE]: AssetHealthStatusGroup.Tire,
  [WarningCase.CRITICAL_LOW_PRESSURE]: AssetHealthStatusGroup.Tire,
  [WarningCase.LOW_PRESSURE]: AssetHealthStatusGroup.Tire,
  [WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION]: AssetHealthStatusGroup.Tire,
  [WarningCase.ABNORMAL_TIRE_VIBRATION]: AssetHealthStatusGroup.Tire,
  [WarningCase.FAST_LEAK]: AssetHealthStatusGroup.Tire,
  [WarningCase.CRITICAL_HIGH_TEMPERATURE]: AssetHealthStatusGroup.Hub,
  [WarningCase.HIGH_TEMPERATURE]: AssetHealthStatusGroup.Hub,
  [WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION]: AssetHealthStatusGroup.Hub,
  [WarningCase.ABNORMAL_BEARING_VIBRATION]: AssetHealthStatusGroup.Hub,
  [WarningCase.HIGH_VIBRATION]: AssetHealthStatusGroup.Hub,
  [WarningCase.LOW_LINE_PRESSURE]: AssetHealthStatusGroup.LinePressure,
  [WarningCase.NO_STATUS_TPMS]: AssetHealthStatusGroup.NoStatus,
  [WarningCase.NO_STATUS_SMARTHUB]: AssetHealthStatusGroup.NoStatus,
  [WarningCase.NO_STATUS_LINE_PRESSURE]: AssetHealthStatusGroup.NoStatus,
  [WarningCase.NO_STATUS_AXLE_LOAD]: AssetHealthStatusGroup.NoStatus,
  [WarningCase.NO_STATUS_GATEWAY]: AssetHealthStatusGroup.NoStatus,
  [WarningCase.MOVEMENT_WITHOUT_ABS]: AssetHealthStatusGroup.AssetState,
  [WarningCase.OVER_SPEED]: AssetHealthStatusGroup.AssetSpeed,
  [WarningCase.CRITICAL_OVER_SPEED]: AssetHealthStatusGroup.AssetSpeed,
} as const

const WarningCaseOrder: Record<WarningCase, number> = {
  [WarningCase.CRITICAL_OVER_PRESSURE]: 1,
  [WarningCase.OVER_PRESSURE]: 2,
  [WarningCase.CRITICAL_LOW_PRESSURE]: 3,
  [WarningCase.LOW_PRESSURE]: 4,
  [WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION]: 5,
  [WarningCase.ABNORMAL_TIRE_VIBRATION]: 6,
  [WarningCase.FAST_LEAK]: 7,
  [WarningCase.CRITICAL_HIGH_TEMPERATURE]: 8,
  [WarningCase.HIGH_TEMPERATURE]: 9,
  [WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION]: 10,
  [WarningCase.ABNORMAL_BEARING_VIBRATION]: 11,
  [WarningCase.HIGH_VIBRATION]: 12,
  [WarningCase.LOW_LINE_PRESSURE]: 13,
  [WarningCase.NO_STATUS_TPMS]: 14,
  [WarningCase.NO_STATUS_SMARTHUB]: 15,
  [WarningCase.NO_STATUS_LINE_PRESSURE]: 16,
  [WarningCase.NO_STATUS_AXLE_LOAD]: 17,
  [WarningCase.NO_STATUS_GATEWAY]: 18,
  [WarningCase.MOVEMENT_WITHOUT_ABS]: 19,
  [WarningCase.OVER_SPEED]: 20,
  [WarningCase.CRITICAL_OVER_SPEED]: 21,
} as const

const AssetHealthSummary: React.FC<FleetSummaryModuleProps> = (props) => {
  const { data } = props

  const { filterTabByAssetHealthTooltipItem } = useActions()

  const getSubtypeLabel = (subtype: ActiveWarningSubtype) => {
    const warningCaseLabel = WarningCaseToLabelMapping[subtype.warning_subtype]

    const assetHealthStatusGroup =
      WarningCaseToAssetHealthStatusGroupMapping[subtype.warning_subtype]

    const assetHealthStatusGroupLabel =
      AssetHealthGroupToLabelMapping[assetHealthStatusGroup]

    return `${warningCaseLabel} (${assetHealthStatusGroupLabel})`
  }

  return (
    <AssetCard>
      <AssetCard.Header label='Asset Health' />

      {[
        {
          icon: circleOk,
          label: 'Normal',
          value: data.normal_state_assets_count,
        },
      ].map((item) => (
        <AssetCardItem key={item.label} {...item} />
      ))}

      {[
        {
          icon: circleWarn,
          label: 'Warning',
          value: data.warning_state_assets_count,
          active_warning_subtypes: data.active_warning_subtypes,
          tooltip_class_name: 'warning',
        },
        {
          icon: circleCritWarn,
          label: 'High severity',
          value: data.critical_warning_state_assets_count,
          active_warning_subtypes: data.active_critical_warning_subtypes,
          tooltip_class_name: 'critical_warning',
        },
      ].map((item) => {
        const componentId =
          'asset_card_item_' + item.label.replaceAll(' ', '').toLowerCase()

        const tooltipClassName = [
          'asset_card_item_tooltip',
          item.tooltip_class_name,
        ].join(' ')

        if (!item.active_warning_subtypes?.length) {
          return <AssetCardItem key={item.label} {...item} />
        }

        const comparer = (a: ActiveWarningSubtype, b: ActiveWarningSubtype) =>
          WarningCaseOrder[a.warning_subtype] -
          WarningCaseOrder[b.warning_subtype]

        item.active_warning_subtypes.sort(comparer)

        return (
          <React.Fragment key={item.label}>
            <UncontrolledTooltip
              target={componentId}
              hideArrow={true}
              autohide={false}
              placement='bottom'
              className={tooltipClassName}
            >
              {item.active_warning_subtypes.map((subtype) => (
                <ListItemButton
                  key={subtype.warning_subtype}
                  onPress={() => {
                    filterTabByAssetHealthTooltipItem(subtype.warning_subtype)
                  }}
                >
                  <Typography variant='P2'>
                    {getSubtypeLabel(subtype)}
                  </Typography>
                </ListItemButton>
              ))}
            </UncontrolledTooltip>

            <AssetCardItem id={componentId} {...item} />
          </React.Fragment>
        )
      })}
    </AssetCard>
  )
}

type ListItemButtonProps = {
  onPress: () => void
  children?: ReactNode
}

const ListItemButton: React.FC<ListItemButtonProps> = (props) => {
  const { onPress, children } = props

  return (
    <div
      role='button'
      tabIndex={0}
      onClick={onPress}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          onPress()
        }
      }}
      className='list_item_button'
    >
      {children}
    </div>
  )
}

type TypographyProps = {
  variant: 'P2'
  children?: ReactNode
}

const Typography: React.FC<TypographyProps> = (props) => {
  const { variant, children } = props

  const variantClassNames: Record<TypographyProps['variant'], string> = {
    P2: 'typography_paragraph_2',
  }

  return <div className={variantClassNames[variant]}>{children}</div>
}

export default AssetHealthSummary
