import { getClearLabel, convertDataEpochToDate } from '../../../utils'
import { IRow } from '../../../components/RivataGrid'
import { NoDataLabels } from '../../../enums'

interface ActiveWarningRow {
  parent_account: string
  customer: string
  asset_name: string
  vin: string
  warning_timestamp: number
  position: string
  warning_type: string
  sensor_type: string
  description: string
  asset_in_geofence: string
  warning_latitude: number
  warning_longitude: number
  warning_geofence: string
  report_datetime: number
  asset_type: string
}

export const activeWarningsRows = (
  rows: Array<IRow>,
  locale: ILocale,
  unitsOfMeasurementConfig: any,
) =>
  rows.map((row) => {
    return {
      ...row,
      parent_account: row.parent_account || NoDataLabels.TRIPPLE_DASH,
      warning_type: locale[row.warning_type] || row.warning_type,
      sensor_type: row.sensor_type
        ? getClearLabel(row.sensor_type)
        : NoDataLabels.TRIPPLE_DASH,
      position:
        row.position === 'Chassis'
          ? getClearLabel('Red Supply Line')
          : getClearLabel(row.position),
      description: row.description || NoDataLabels.TRIPPLE_DASH,
      asset_in_geofence: row.asset_in_geofence || NoDataLabels.TRIPPLE_DASH,
      warning_latitude: row.warning_latitude || NoDataLabels.TRIPPLE_DASH,
      warning_longitude: row.warning_longitude || NoDataLabels.TRIPPLE_DASH,
      warning_geofence: row.warning_geofence || NoDataLabels.TRIPPLE_DASH,
      warning_timestamp: convertDataEpochToDate(
        row.warning_timestamp,
        null,
        null,
        true,
      ),
      report_datetime: convertDataEpochToDate(
        row.report_datetime,
        null,
        null,
        true,
      ),
      asset_type: row.asset_type
        ? getClearLabel(row.asset_type)
        : NoDataLabels.TRIPPLE_DASH,
      asset_groups: row.asset_groups || NoDataLabels.TRIPPLE_DASH,
      geofence_groups: row.geofence_groups || NoDataLabels.TRIPPLE_DASH,
    }
  })
