import React, { useCallback, useEffect, useState } from 'react'

import InfoModal from '../../components/InfoModal'
import ProfileForm from './components/ProfileForm/ProfileForm'
import RivataLoader from '../../components/RivataLoader'
import UnverifiedUsersModal from './components/UnverifiedUsersModal'

import { useActions } from '../../hooks/useActions'

import { NotificationFormValues, useInitialFormValues } from './hooks'

import {
  composeCreateRequestBody,
  composeUpdateRequestBody,
  getUnverifiedUserList,
} from './utils'
import { sortByAlphabet } from '../../utils'
import { SearchItemType } from '../../components/ItemsSearch'

interface IFormSubmitContext {
  formValues: NotificationFormValues
  resetForm: () => void
}
export interface IUnverifiedUsers {
  state: boolean
  aknowledged: boolean
  unverifiedUsersList: {
    email: Array<IUserShortFormat>
    sms: Array<IUserShortFormat>
  }
}

interface Props {
  showCreateForm: boolean
  locale: ILocale
  selectedCustomersList: Array<ICustomer>
  users: Array<IUserShortFormat>
  profileToEdit: INotificationProfile | null
  setProfileToEdit: (profile: INotificationProfile | null) => void
  setShowCreateForm: React.Dispatch<React.SetStateAction<boolean>>
  fetchNotificationProfiles: () => void
  subscriptions: any
  isSuperAdmin: boolean
}

const NotificationsModule: React.FC<Props> = ({
  showCreateForm,
  locale = {},
  selectedCustomersList,
  users,
  profileToEdit,
  setProfileToEdit,
  setShowCreateForm,
  fetchNotificationProfiles,
  subscriptions,
  isSuperAdmin,
}) => {
  const { createNotificationProfile, updateNotificationProfile } = useActions()

  const [profileUsers, setProfileUsers] =
    useState<Array<IUserShortFormat> | null>(null)
  const [profileAssets, setProfileAssets] = useState<Array<SearchItemType>>([])
  const [profileGeofences, setProfileGeofences] = useState<
    Array<SearchItemType>
  >([])
  const [profileGeofenceGroups, setProfileGeofenceGroups] = useState<
    Array<string>
  >([])
  const [profileGeofenceGroupsToUpdate, setProfileGeofenceGroupsToUpdate] =
    useState<Array<number>>([])
  const [profileAssetsGroups, setProfileAssetsGroups] = useState<Array<string>>(
    [],
  )
  const [profileAssetsGroupsToUpdate, setProfileAssetsGroupsToUpdate] =
    useState<Array<number>>([])
  const [profileExcludeAssets, setProfileExcludeAssets] = useState<
    Array<SearchItemType>
  >([])
  const [profileExcludeGeofences, setProfileExcludeGeofences] = useState<
    Array<SearchItemType>
  >([])

  const [unverifiedUsers, setUnverifiedUsers] =
    useState<IUnverifiedUsers | null>(null)
  const [formSubmitContext, setFormSubmitContext] =
    useState<IFormSubmitContext | null>(null)
  const [status, setStatus] = useState<IStatus | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const initialFormValues = useInitialFormValues(profileToEdit)

  useEffect(() => {
    if (!profileToEdit || !profileToEdit.users) {
      setProfileUsers(null)

      return
    }

    const currentpProfileUsers: Array<IUserShortFormat> = []

    profileToEdit.users.forEach((id) => {
      const user = users.find((el) => el.id === id)

      if (user) currentpProfileUsers.push(user)
    })

    sortByAlphabet(currentpProfileUsers, 'username')

    setProfileUsers(currentpProfileUsers)

    setProfileAssets(profileToEdit.assets)

    setProfileGeofences(profileToEdit.geofences)

    const profileGeofenceGroups = profileToEdit.geofence_groups.map((a) =>
      a.toString(),
    )

    setProfileGeofenceGroups(profileGeofenceGroups)

    const assets_groups = profileToEdit.assets_groups.map((a) => a.toString())

    setProfileAssetsGroups(assets_groups)

    setProfileExcludeAssets(profileToEdit.exclude.assets)

    setProfileExcludeGeofences(profileToEdit.exclude.geofences)
  }, [profileToEdit, users])

  const proceedRequest = async (formContext?: IFormSubmitContext) => {
    let formValues = formSubmitContext
      ? formSubmitContext.formValues
      : formContext?.formValues
    let resetForm = formSubmitContext
      ? formSubmitContext.resetForm
      : formContext?.resetForm
    let res: any

    if (!formValues || !resetForm) return

    setIsLoading(true)

    if (!profileToEdit && formValues) {
      const body = composeCreateRequestBody(
        formValues,
        profileUsers,
        profileAssets,
        profileGeofences,
        profileGeofenceGroupsToUpdate,
        profileAssetsGroupsToUpdate,
        profileExcludeAssets,
        profileExcludeGeofences,
        selectedCustomersList[0].id,
      )

      res = await createNotificationProfile(body)
    } else if (profileToEdit && formValues) {
      const body = composeUpdateRequestBody(
        profileToEdit,
        formValues,
        profileUsers,
        profileAssets,
        profileGeofences,
        profileGeofenceGroupsToUpdate,
        profileAssetsGroupsToUpdate,
        profileExcludeAssets,
        profileExcludeGeofences,
        selectedCustomersList[0].id,
      )

      res = await updateNotificationProfile(profileToEdit.id, body)
    }

    if (res && res.ok && resetForm) {
      resetForm()
      fetchNotificationProfiles()
    }

    setIsLoading(false)
    setStatus(res)
  }

  const handleFormSubmit = (
    formValues: NotificationFormValues,
    resetForm: () => void,
  ) => {
    if (selectedCustomersList.length !== 1) return

    const formContext = { formValues, resetForm }

    setFormSubmitContext(formContext)

    const unverifiedUsersList = getUnverifiedUserList(
      formValues.email,
      formValues.sms,
      profileUsers,
    )
    const hasUnverifiedUsers =
      unverifiedUsersList.email.length > 0 || unverifiedUsersList.sms.length > 0

    if (hasUnverifiedUsers && !unverifiedUsers?.aknowledged) {
      setUnverifiedUsers({
        state: true,
        aknowledged: false,
        unverifiedUsersList,
      })

      return
    }

    proceedRequest(formContext)
  }

  const onCancel = useCallback(() => {
    setProfileToEdit(null)
    setShowCreateForm(false)
  }, [setProfileToEdit, setShowCreateForm])

  if (!showCreateForm && !profileToEdit) return null

  return (
    <>
      {isLoading && <RivataLoader />}

      <ProfileForm
        locale={locale}
        initialValues={initialFormValues}
        users={users}
        isUpdate={!!profileToEdit}
        profileUsers={profileUsers}
        profileAssets={profileAssets}
        profileAssetsGroups={profileAssetsGroups}
        profileGeofences={profileGeofences}
        profileGeofenceGroups={profileGeofenceGroups}
        profileExcludeAssets={profileExcludeAssets}
        profileExcludeGeofences={profileExcludeGeofences}
        setProfileUsers={setProfileUsers}
        setProfileAssets={setProfileAssets}
        setProfileAssetsGroups={setProfileAssetsGroupsToUpdate}
        setProfileGeofences={setProfileGeofences}
        setProfileGeofenceGroups={setProfileGeofenceGroupsToUpdate}
        setProfileExcludeAssets={setProfileExcludeAssets}
        setProfileExcludeGeofences={setProfileExcludeGeofences}
        handleFormSubmit={handleFormSubmit}
        onCancel={onCancel}
        selectedCustomersList={selectedCustomersList}
        subscriptions={subscriptions}
        isSuperAdmin={isSuperAdmin}
      />

      {unverifiedUsers?.state && !unverifiedUsers.aknowledged && (
        <UnverifiedUsersModal
          unverifiedUsers={unverifiedUsers}
          setUnverifiedUsers={setUnverifiedUsers}
          proceedRequest={proceedRequest}
          header='Notification Profile'
          message='Following users have unverified contacts. Do you want to proceed'
        />
      )}

      {status && (
        <InfoModal
          header={'Notification Profile'}
          message={status.message}
          open={true}
          onConfirm={() => {
            if (status.ok) onCancel()

            setStatus(null)
          }}
        />
      )}
    </>
  )
}

export default NotificationsModule
