import { Dispatch } from "redux"
import notificationApi from "../../services/api/ApiGroups/NotificationsApi"
import { IgetState, ThunkResult } from "../types"
import { SettingsActionTypes, SettingsActions } from "./types"
import { getStatusObj } from "../../utils/utils"
import { ApiError } from "../../services/api/base"
import { filter } from "lodash"

export function fetchSettingsNotificationsProfiles(): ThunkResult<void> {
    return async function (dispatch: Dispatch<SettingsActions>, getState: IgetState) {
        try {
            dispatch({ type: SettingsActionTypes.LOADING, payload: false })

            const selectedCustomersList: Array<ICustomer> = getState().common.customers.selectedCustomersList
            const userId = getState().auth.preferences.id
            const isSuperAdmin = getState().auth.isSuperAdmin;
            if (selectedCustomersList.length !== 1) return
    
            let res = await notificationApi.getNotificationProfile(selectedCustomersList[0].id, undefined, undefined,undefined,undefined,userId)
            
            dispatch({ type: SettingsActionTypes.SET_NOTIFICATIONS_PROFILES, payload: res })
            return res
        } catch (err) {
            console.log(err)
        } finally {
            dispatch({ type: SettingsActionTypes.LOADING, payload: false })
        }
    }
}

export function manageUserNotificationProfile(profile_id: number, action: string): ThunkResult<IStatus> {
    return async function (dispatch: Dispatch<any>, getState: IgetState) {
        try {
            dispatch({ type: SettingsActionTypes.LOADING, payload: true })
            const user_id: number = getState().auth.preferences.id
            const data = {
                profile_id,
                action,
                user_id
            }
            await notificationApi.manageUserNotificationProfile(data)
            dispatch(fetchSettingsNotificationsProfiles())
            return getStatusObj({ statusCode: 200 }, "Success!")
        } catch (err) {
            if (err instanceof ApiError) {
                let message = err.message.split(":")
                message.splice(0, 1)

                return getStatusObj(err, message.join(":"))
            }
            
            return getStatusObj(err)
        } finally {
            dispatch({ type: SettingsActionTypes.LOADING, payload: false })
        }
    }
    
}


