import React, { useMemo, useState } from 'react'
import ConfirmModal from '../../../../components/ConfirmModal'
import { sortByAlphabet } from '../../../../utils'
import "./style.scss"

interface Props {
    isOpen: boolean
    users: Array<IUserShortFormat>
    selectedUserIds: Array<number>
    onCancel: () => void
    singleOption: boolean
    onConfirm: (users: Array<IUserShortFormat>) => void
}

const ManageScheduledReportUsersModal: React.FC<Props> = ({
    isOpen,
    users,
    selectedUserIds,
    onCancel,
    singleOption,
    onConfirm
}) => {
    const [userIds, setUserIds] = useState(selectedUserIds)

    const listItems = useMemo(() => {
        const alreadySelectedUsers: Array<IUserShortFormat> = []
        const unselectedUsers: Array<IUserShortFormat> = []
        
        users.forEach(user => {
            if (selectedUserIds.includes(user.id)) alreadySelectedUsers.push(user)
            else unselectedUsers.push(user)
        })

        sortByAlphabet(alreadySelectedUsers, "username")
        sortByAlphabet(unselectedUsers, "username")

        return [ ...alreadySelectedUsers, ...unselectedUsers ]
    }, [users, selectedUserIds])

    const handleCheckUser = (id: number) => {
        if (!userIds) return;

        let arr = [ ...userIds ]
        const idx = arr.findIndex(el => el === id)

        if (idx > -1) arr.splice(idx, 1)
        else {
            if (singleOption) {
                arr = [];
            }
            arr.push(id)
        }
        
        setUserIds(arr)
    }

    return (
        <ConfirmModal
            header={singleOption ? "Manage Scheduled Report User" : "Manage Scheduled Report Bcc Users"}
            open={isOpen}
            modalButtons={[{
                id: 1, label: "Confirm", color: "primary", onClick: () => {
                    const selectedusers = users.filter(el => userIds.includes(el.id))

                    sortByAlphabet(selectedusers, "username")
                    onConfirm(selectedusers)
                }
            }]}
            onClose={onCancel}
        >
            <div className="scheduled-reports__users">
                {!singleOption ? (
                    <div
                        className="reports__user"
                        onClick={() => setUserIds(prev => {
                            if (prev.length !== users.length) return listItems.map(el => el.id)
                            
                            return []
                        })}
                    >
                        <input 
                            type="checkbox" 
                            checked={userIds.length === users.length}
                            className="mr-2"
                            readOnly
                        />
                        <label>
                            <b>All</b>
                        </label>
                    </div>
                ) : ""}
                {listItems.map(el => (
                    <div
                        key={el.id}
                        className="reports__user"
                        onClick={() => handleCheckUser(el.id)}
                    >
                        <input 
                            type="checkbox" 
                            checked={userIds.includes(el.id)}
                            className="mr-2"
                            readOnly
                        />
                        <label>{el.username}</label>
                    </div>
                ))}
            </div>
        </ConfirmModal>
    )
}

export default ManageScheduledReportUsersModal