import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { debounce, cloneDeep } from 'lodash'
import Button from '../../../componentsV2/Button'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import AssetGroupsFilter from '../../../componentsV2/AssetGroupsFilter'
import AssetTypeFilter from '../../../componentsV2/AssetTypeFilter'
import HealthStatusFilter from '../../../componentsV2/HealthStatusFilter'
import AssetNameVinFilter from '../../../componentsV2/AssetNameVinFilter'
import GeofenceFilter from '../../../componentsV2/GeofenceFilter'
import GeofenceGroupsFilter from '../../../componentsV2/GeofenceGroupsFilter'
import './styles.scss'
interface Props {
  onFilter: (filters: string) => void
}

const debouncer = debounce((func: () => void) => func(), 400)

const wrapperStyle = { width: '470px' }
const initialSelection = {
  asset_subtype: [],
  status_2: [],
  asset_group: [],
  geofence_group: [],
}

const statusParentFilters = ['tire', 'hub', 'line_pressure', 'no_status']

const GeofencesFilterMenu = forwardRef(({ onFilter }: Props, ref) => {
  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))

  const searchParams = useRef<Record<string, Array<string>>>(
    cloneDeep(initialSelection),
  )
  const actualQuery = useRef('')
  const groupsFilterRef = useRef<any>(null)
  const geofenceGroupsFilterRef = useRef<any>(null)
  const assetTypeFilterRef = useRef<any>(null)
  const healthStatusFilterRef = useRef<any>(null)
  const assetNameVinFilterRef = useRef<any>(null)
  const [clearLabelSelector, setClearLabelSelector] = useState(0)
  const mode = useRef('all')
  const [filterMode, setFilterMode] = useState('all')

  const buildFilterQueryAndFilter = useCallback(() => {
    const query = new URLSearchParams()

    Object.entries(searchParams.current).forEach((filter) => {
      const key = filter[0]
      filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
    })

    actualQuery.current = query.toString()
    onFilter(actualQuery.current)
  }, [onFilter])
  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value

      // remove parent filters
      if (key === 'status_2') {
        if (
          Array.isArray(tempValue) &&
          statusParentFilters.some((pf) => tempValue.includes(pf))
        ) {
          let vArr: Array<string> = tempValue

          statusParentFilters.forEach((pf) => {
            if (vArr.includes(pf)) {
              vArr = vArr.filter((v) => v !== pf)
            }
          })

          tempValue = vArr
        }
      }

      if (!tempValue || !tempValue.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(tempValue)) {
        searchParams.current[key] = tempValue
      } else {
        searchParams.current[key] = [tempValue]
      }
      buildFilterQueryAndFilter()
    },
    [buildFilterQueryAndFilter],
  )

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value))
  }

  const handleFiltersReset = useCallback(() => {
    //some elements must be reseted even if filters weren't selected
    setClearLabelSelector((prev) => prev + 1)

    // make filtering only if filter query params are different from prevoius
    if (actualQuery.current === '') return

    actualQuery.current = ''
    // searchParams.current = cloneDeep(initialSelection)
    if (mode.current === 'all') {
      groupsFilterRef?.current?.clearSelection()
      healthStatusFilterRef?.current?.clearSelection()
      assetTypeFilterRef?.current?.clearSelection()
      assetNameVinFilterRef?.current?.clearValue()

      searchParams.current['asset_subtype'] = []
      searchParams.current['status_2'] = []
      searchParams.current['asset_group'] = []
    }
    geofenceGroupsFilterRef?.current?.clearSelection()
    searchParams.current['geofence_group'] = []
    buildFilterQueryAndFilter()
  }, [buildFilterQueryAndFilter])

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked)
      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  useEffect(() => {
    handleFiltersReset()
  }, [selectedCustomersList, handleFiltersReset])
  useImperativeHandle(ref, () => ({
    setMode(modeValue: string) {
      mode.current = modeValue
      setFilterMode(mode.current)
      if (mode.current !== 'all') {
        groupsFilterRef?.current?.clearSelection()
        healthStatusFilterRef?.current?.clearSelection()
        assetTypeFilterRef?.current?.clearSelection()
        assetNameVinFilterRef?.current?.clearValue()

        searchParams.current['asset_subtype'] = []
        searchParams.current['status_2'] = []
        searchParams.current['asset_group'] = []
        buildFilterQueryAndFilter()
      }
    },
  }))
  return (
    <>
      {/* {mode === 'all' && ( */}
      <div className={filterMode !== 'all' ? 'hidden-filter' : ''}>
        <div className='d-flex flex-wrap'>
          <AssetNameVinFilter
            wrapperStyle={wrapperStyle}
            onChange={onSearchByStringValue}
            ref={assetNameVinFilterRef}
            wrapperClassName='mr-3'
          />

          <GeofenceFilter
            wrapperStyle={wrapperStyle}
            clearSelection={clearLabelSelector}
            updateFilters={updateFilters}
            searchParams={searchParams}
          />
        </div>

        <br />
      </div>
      {/* )} */}

      <div className='d-flex flex-wrap justify-content-between'>
        <div className='d-flex flex-wrap'>
          <div
            className={
              filterMode !== 'all' ? 'hidden-filter' : 'd-flex flex-wrap'
            }
          >
            <AssetTypeFilter
              onDropdownItemSelect={onDropdownItemSelect}
              ref={assetTypeFilterRef}
            />
            <HealthStatusFilter
              onDropdownItemSelect={onDropdownItemSelect}
              ref={healthStatusFilterRef}
            />
            <AssetGroupsFilter
              onDropdownItemSelect={onDropdownItemSelect}
              ref={groupsFilterRef}
              customerIds={selectedCustomersList
                .map((c: any) => c.id)
                .join(',')}
            />{' '}
          </div>
          <GeofenceGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={geofenceGroupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(',')}
          />
        </div>

        <div>
          <Button onClick={handleFiltersReset}>Clear</Button>
        </div>
      </div>
    </>
  )
})
export default React.memo(GeofencesFilterMenu)
