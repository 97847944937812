import { RolePermission } from "../../enums";
import { MenuItemType } from "../Menu/MenuItem";

export const assetTypeItems: Array<MenuItemType> = [
    { id: RolePermission.ADMIN, label: "Admin", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: RolePermission.DRIVER, label: "Driver", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: RolePermission.INSTALLER, label: "Installer", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: RolePermission.INSTALLER_ADMIN, label: "Installer Admin", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: RolePermission.STANDARD, label: "Standard", type: "checkbox", markType: "check", checked: false, parentId: null }
]
