import React, { ReactNode, useEffect, useRef } from 'react'

type Props = {
  children?: ReactNode
  onClickAway: () => void
}

const ClickAwayListener: React.FC<Props> = (props) => {
  const { children, onClickAway } = props

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!ref.current) return

      if (ref.current.contains(event.target)) return

      onClickAway()
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [onClickAway])

  return <div ref={ref}>{children}</div>
}

export default ClickAwayListener
