import React from 'react'
import RivataLoader from '../../../components/RivataLoader'
import RivataModule from '../../../components/RivataModule'
import StatusAlert from '../../../components/StatusAlert'
import ReactJSONEditor from '../../../components/ReactJSONEditor'

import { useTypedSelector } from '../../../hooks/useTypedSelector'

interface Props {
  locale: ILocale
}

const AssetSubscriptions: React.FC<Props> = ({ locale }) => {
  const subscription = useTypedSelector(
    (state) => state.assetAdminInfo.subscription,
  )

  return (
    <RivataModule
      title={`Current Subscriptions`}
      locale={locale}
      marginTop={0}
      filters={null}
    >
      {subscription?.isLoading ? (
        <RivataLoader />
      ) : !subscription?.isLoading &&
        !Object.keys(subscription?.data).length ? (
        <StatusAlert
          customText='No Data'
          color='success'
          statusCode={undefined}
          statusText={undefined}
        />
      ) : (
        <ReactJSONEditor
          content={
            subscription.data ? { json: subscription.data } : { text: '' }
          }
          readOnly
        />
      )}
    </RivataModule>
  )
}

export default AssetSubscriptions
