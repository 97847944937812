import baseApi from "./BaseApi"

class AssetGroupsApi {
  getAssetGroups = (
    limit = 30,
    offset = 0,
    sortColumn?: string | null,
    sortDirection?: string | null
  ) => {
    const params = new URLSearchParams()

    if (typeof limit === "number") {
      params.append("limit", limit.toString())
    }

    if (typeof offset === "number") {
      params.append("offset", offset.toString())
    }

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }

    const queryString = params.toString()

    return baseApi.get(`rivata/asset_group?${queryString}`)
  }

  createAssetGroup = (
    name: string,
    customer_id: number
  ) => {
    return baseApi.post(`rivata/asset_group`, { name, customer_id })
  }

  updateAssetGroup = (
    name: string,
    id: number
  ) => {
    return baseApi.put(`rivata/asset_group?id=${id}`, { name })
  }

  deleteAssetGroup = (
    id: number
  ) => {
    return baseApi.delete(`rivata/asset_group?id=${id}`)
  }

  getAssetGroupById = (
    id: number,
    limit: number,
    offset: number,
    showUngroupedOnly: boolean,
    sortColumn?: string,
    sortDirection?: string,
    filters?: string,
  ) => {
    const params = new URLSearchParams()

    params.append("id", id.toString())

    if (typeof limit === "number") {
      params.append("limit", limit.toString())
    }

    if (typeof offset === "number") {
      params.append("offset", offset.toString())
    }

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }


    if (showUngroupedOnly) {
      params.append("filters", "asset_group=-1");
    }

    const queryString = params.toString()

    return baseApi.get(`rivata/asset_group?${queryString}${filters ? "&" + filters : ""}`)
  }

  getAssetGroupsLimitedData = (
    sortColumn?: string | null,
    sortDirection?: string | null,
    groupIds?: string | null
  ) => {
    const params = new URLSearchParams()

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }

    params.append("get_limited_data", "true")
    if (groupIds)
      params.append("group_ids", groupIds)
    if (baseApi.customerIdsList) {
      params.append("customer_ids", baseApi.customerIdsList)
    }
    const queryString = params.toString()

    return baseApi.get(`rivata/asset_group?${queryString}`)
  }

  getAssetGroupsLimitedDataForCustomers = (
    customerIds: string,
    sortColumn?: string | null,
    sortDirection?: string | null
  ) => {
    const params = new URLSearchParams()

    if (sortColumn) {
      params.append("sort_column", sortColumn)
    }

    if (sortDirection) {
      params.append("sort_direction", sortDirection)
    }
    params.append("get_limited_data", "true")
    if (customerIds && customerIds.split(",").length > 0) {
      params.append("customer_ids", customerIds)
    }
    const queryString = params.toString()

    return baseApi.get(`rivata/asset_group?${queryString}`, false)
  }

  manageAssetGroupAssets = (
    groupId: number,
    assetIds: Array<number>,
    action: string
  ) => {
    return baseApi.post("rivata/asset_group/manage", { id: groupId, assets_ids: assetIds, action })
  }
}

const assetGroupsApi = new AssetGroupsApi()

export default assetGroupsApi
