import { IColumn, IRow } from "../../../components/RivataGrid"
import { convertDataEpochToDate } from "../../../utils"

interface IhardwareCommandHistoryReportColumns {
    (
        columns: Array<string>
    ): Array<IColumn>
}

export const hardwareCommandHistoryReportColumns: IhardwareCommandHistoryReportColumns = (columns) => {
    const parsedColumns: Array<IColumn> = []

    const labels = ["Parent Customer Name", "Customer Name", "Asset Name", "VIN", "ESN", 
        "Command Generated Timestamp", "Command Status", "Command Confirmed At", 
        "Number of Retries", "Last retry timestamp", "Command Type", "First Delivery Attempt", "Report run timestamp"]

    columns.forEach((key, i) => {
        parsedColumns.push({ key, name: labels[i], width: 125, minWidth: 125 })
    })

    return parsedColumns
}


interface IhardwareCommandHistoryReportRows {
    (
        columns: Array<string>,
        rows: Array<IRow>
    ): Array<IRow>
}

export const hardwareCommandHistoryReportRows: IhardwareCommandHistoryReportRows = (columns, rows) => {
    const parsedRows = rows.map(item => {
        return { 
            parent_customer_name: item.parent_customer_name ?? "---", 
            customer_name: item.customer_name, 
            asset_name: item.asset_name,
            vin: item.vin,
            esn: item.esn,
            command_generated_epoch: convertDataEpochToDate(item.command_generated_epoch, null, null, true),
            command_status: item.command_status || "---",
            command_confirmed_at_epoch: item.command_confirmed_at_epoch ? convertDataEpochToDate(item.command_confirmed_at_epoch, null, null, true) : "---",
            number_of_retries: item.number_of_retries || "---",
            last_retry_epoch: item.last_retry_epoch ? convertDataEpochToDate(item.last_retry_epoch, null, null, true) : "---",
            command_type: item.command_type,
            delivery_first_attempt_at: item.delivery_first_attempt_at ? convertDataEpochToDate(item.delivery_first_attempt_at, null, null, true) : "---",
            report_run_epoch: item.report_run_epoch ? convertDataEpochToDate(item.report_run_epoch, null, null, true) : "---"
        }
    })

    return parsedRows
}