import { isInteger } from 'lodash'
import { celsiusToFahr, kmhToMph, parseSecondsToDateParts } from '../utils'
import {
  CronDisplacementModes,
  CronModes,
  CronWeekDaysIds,
  NoDataLabels,
  PressureWarningLevel,
  WarningCase,
  WarningType,
} from '../enums'
import moment from 'moment'
import ReactGA from 'react-ga4'
import { store } from '../redux/store'
import { UnitsOfMeasurementConfig } from '../constants/constants'
import { useEffect, useRef } from 'react'
interface IgetErrorObj {
  (err?: any, customMessage?: string): IStatus
}

export const getStatusObj: IgetErrorObj = (
  err = { statusCode: 0, message: '' },
  customMessage,
) => ({
  ok: err.statusCode < 400,
  statusCode: err.statusCode,
  message: customMessage ? customMessage : err.message.toUpperCase(),
})

export const getGpsDirectinFromDeg = (deg: number) => {
  const defaultOffset = 11.25
  const directionMiddle = 360 / 16 // 22.5
  const arr = [{ min: 348.75, max: 11.25 }]
  const directions = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ]

  for (let i = directionMiddle; i < 360; i += directionMiddle) {
    const range = {
      min: i - defaultOffset,
      max: i + defaultOffset,
    }

    arr.push(range)
  }

  if ((deg >= arr[0].min && deg <= 360) || (deg >= 0 && deg < arr[0].max)) {
    // N
    return directions[0]
  }

  for (let i = 1; i < arr.length; i++) {
    if (deg >= arr[i].min && deg < arr[i].max) {
      return directions[i]
    }
  }

  return ''
}

export const makeScrollToY = (y: number) => {
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  })
}

export const makeScrollToElementWithOffset = (
  scrollTarget: React.MutableRefObject<null | HTMLElement>,
  offset: number,
) => {
  if (scrollTarget.current) {
    const y =
      scrollTarget.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset

    makeScrollToY(y)
  }
}

export const makeScrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth',
  })
}

const getFormattedDurationText = (
  is_recovered_to_normal: boolean,
  duration: number,
  warning_type: string,
) => {
  let formattedDuration = ''

  if (is_recovered_to_normal) {
    if (isInteger(duration)) {
      const {
        parsed: { days },
        formatted: { fDays, fHours, fMinutes, fSeconds },
      } = parseSecondsToDateParts(duration)

      if (warning_type === WarningType.noStatus) {
        formattedDuration =
          days === 0
            ? ` for ${fHours}:${fMinutes}`
            : ` for ${fDays} ${fHours}:${fMinutes}`
      } else {
        formattedDuration =
          days === 0
            ? ` for ${fHours}:${fMinutes}:${fSeconds}`
            : ` for ${fDays} ${fHours}:${fMinutes}:${fSeconds}`
      }
    }
  }

  return formattedDuration
}

const getRecoveredCanceledText = (
  is_recovered_to_normal: boolean,
  formatted_recovered_to_normal_at_datetime: string,
  warning_type: string,
  canceled: boolean,
  formatted_canceled_at_datetime: string,
  cancel_reason: string,
) => {
  let recoveredCanceledText = ''

  if (is_recovered_to_normal) {
    recoveredCanceledText = ` recovered at ${formatted_recovered_to_normal_at_datetime}`

    if (warning_type !== 'tire_pressure') {
      recoveredCanceledText = ` recovered to normal at ${formatted_recovered_to_normal_at_datetime}`
    }
  }

  if (canceled)
    recoveredCanceledText = ` canceled at ${formatted_canceled_at_datetime} with reason: ${
      cancel_reason === 'no_status'
        ? 'No Status warning generated'
        : cancel_reason
    } `

  return recoveredCanceledText
}

const getHubTemperatureMessage = (
  threshold: number,
  isImperial: boolean,
  warning_subtype: string,
  locale: ILocale,
  formattedDuration: string,
  recoveredCanceledText: string,
) => {
  let thresholdText = ''
  let resultText = NoDataLabels.DASH.toString()

  if (threshold) {
    thresholdText = isImperial
      ? ` ${celsiusToFahr(threshold).toFixed(2)} °F`
      : ` ${threshold.toFixed(2)} °C`
  }

  if (warning_subtype === WarningCase.HIGH_TEMPERATURE) {
    resultText = `${locale.high_temperature_warning} ${thresholdText} ${formattedDuration} ${recoveredCanceledText}`
  } else if (warning_subtype === WarningCase.CRITICAL_HIGH_TEMPERATURE) {
    resultText = `${locale.critical_high_temperature_warning} ${thresholdText} ${formattedDuration} ${recoveredCanceledText}`
  }

  return resultText
}

const getAssetStateWarningMessage = (
  warningSubtype: string,
  formattedDuration: string,
  recoveredCanceledText: string,
) => {
  if (warningSubtype === WarningCase.MOVEMENT_WITHOUT_ABS) {
    return `Asset Movement Without ABS detected ${formattedDuration} ${recoveredCanceledText}`
  }
  return NoDataLabels.DASH
}

const getAssetSpeedWarningMessage = (
  warningSubtype: string,
  formattedDuration: string,
  recoveredCanceledText: string,
  warningValue: number,
  isImperial: boolean,
) => {
  const speedValue = isImperial
    ? `${kmhToMph(warningValue).toFixed(0)} mph`
    : `${warningValue} kmh`

  if (warningSubtype === WarningCase.CRITICAL_OVER_SPEED) {
    return `Critical Over Speed ${speedValue} detected ${formattedDuration} ${recoveredCanceledText}`
  } else if (warningSubtype === WarningCase.OVER_SPEED) {
    return `Over Speed ${speedValue} detected ${formattedDuration} ${recoveredCanceledText}`
  }
  return NoDataLabels.DASH
}

const getPressureFastLeakWarningMessage = (
  warningSubtype: string,
  formattedDuration: string,
  recoveredCanceledText: string,
) => {
  if (warningSubtype === WarningCase.FAST_LEAK) {
    return `Pressure Fast Leak detected ${formattedDuration} ${recoveredCanceledText}`
  }
  return NoDataLabels.DASH
}

const getTirePressureMessage = (
  warning_subtype: string,
  formattedDuration: string,
  recoveredCanceledText: string,
) => {
  let tpmsWarningText = ''
  let warningLevel = ''

  if (warning_subtype === PressureWarningLevel.lowPressure) {
    warningLevel = 'Low Pressure'
  } else if (warning_subtype === PressureWarningLevel.criticalLowPressure) {
    warningLevel = 'Critical Low Pressure'
  } else if (warning_subtype === PressureWarningLevel.overPressure) {
    warningLevel = 'Over Pressure'
  } else if (warning_subtype === PressureWarningLevel.criticalOverPressure) {
    warningLevel = 'Critical Over Pressure'
  }

  if (warningLevel) {
    tpmsWarningText = warningLevel

    if (formattedDuration) {
      tpmsWarningText += formattedDuration
    }
    if (recoveredCanceledText) {
      tpmsWarningText += recoveredCanceledText
    }
  }

  return tpmsWarningText
}

const getTireVibrationMessage = (
  warning_subtype: string,
  formattedDuration: string,
  recoveredCanceledText: string,
) => {
  const inspectText = !recoveredCanceledText ? '- Inspect tire' : ''

  if (warning_subtype === WarningCase.ABNORMAL_TIRE_VIBRATION) {
    return `Tire vibration detected ${inspectText} ${formattedDuration} ${recoveredCanceledText}`
  } else if (warning_subtype === WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION) {
    return `High severity tire vibration detected ${inspectText} ${formattedDuration} ${recoveredCanceledText}`
  }

  return NoDataLabels.DASH
}

const getBearingVibrationMessage = (
  warning_subtype: string,
  formattedDuration: string,
  recoveredCanceledText: string,
) => {
  if (warning_subtype === WarningCase.ABNORMAL_BEARING_VIBRATION) {
    const inspectText = !recoveredCanceledText
      ? '- inspect bearing based on manufacturer field inspection guidelines'
      : ''

    return `Hub vibration detected ${inspectText} ${formattedDuration} ${recoveredCanceledText}`
  } else if (warning_subtype === WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION) {
    const inspectText = !recoveredCanceledText
      ? '- inspect based on manufacturer service guidelines'
      : ''

    return `High severity hub vibration detected ${inspectText} ${formattedDuration} ${recoveredCanceledText}`
  }

  return NoDataLabels.DASH
}

export const getWarningDescription = (
  warning_type: string,
  warning_subtype: string,
  duration: number,
  level: number,
  is_recovered_to_normal: boolean,
  locale: ILocale,
  unitsOfMeasurementConfig: any,
  formatted_recovered_to_normal_at_datetime: string,
  max_warning_value: number,
  canceled: boolean,
  formatted_canceled_at_datetime: string,
  cancel_reason: string,
  threshold: number,
) => {
  try {
    const isTempImperial =
      unitsOfMeasurementConfig ===
      UnitsOfMeasurementConfig.temperature.fahrenheit
    const isDistanceImperial =
      unitsOfMeasurementConfig === UnitsOfMeasurementConfig.distance.miles

    let formattedDuration = getFormattedDurationText(
      is_recovered_to_normal,
      duration,
      warning_type,
    )
    let recoveredCanceledText = getRecoveredCanceledText(
      is_recovered_to_normal,
      formatted_recovered_to_normal_at_datetime,
      warning_type,
      canceled,
      formatted_canceled_at_datetime,
      cancel_reason,
    )

    switch (warning_type) {
      case WarningType.hubTemperature:
        return getHubTemperatureMessage(
          threshold,
          isTempImperial,
          warning_subtype,
          locale,
          formattedDuration,
          recoveredCanceledText,
        )
      case WarningType.hubVibration:
        return `${locale.vibMsgStart} ${level.toFixed(1)} ${
          locale.vibMsgEnd
        } ${recoveredCanceledText}`
      case WarningType.tireVibration:
        return getTireVibrationMessage(
          warning_subtype,
          formattedDuration,
          recoveredCanceledText,
        )
      case WarningType.bearingVibration:
        return getBearingVibrationMessage(
          warning_subtype,
          formattedDuration,
          recoveredCanceledText,
        )
      case WarningType.tirePressure:
        return getTirePressureMessage(
          warning_subtype,
          formattedDuration,
          recoveredCanceledText,
        )
      case WarningType.noStatus:
        return `In no-status state ${formattedDuration} ${recoveredCanceledText}`
      case WarningType.linePressure:
        return `Check Line Pressure ${formattedDuration} ${recoveredCanceledText}`
      case WarningType.assetState:
        return getAssetStateWarningMessage(
          warning_subtype,
          formattedDuration,
          recoveredCanceledText,
        )
      case WarningType.assetSpeed:
        return getAssetSpeedWarningMessage(
          warning_subtype,
          formattedDuration,
          recoveredCanceledText,
          level,
          isDistanceImperial,
        )
      case WarningType.pressureFastLeak:
        return getPressureFastLeakWarningMessage(
          warning_subtype,
          formattedDuration,
          recoveredCanceledText,
        )
      default:
        return NoDataLabels.DASH
    }
  } catch (err) {
    console.log(err)

    return locale['Error Parsing Data']
  }
}

export const mapCustomerIdsToNames = (
  array: Array<{ customer_id: number }>,
  customers: Array<ICustomer>,
) => {
  return array.map((object) => {
    return {
      ...object,
      customer: customers.find((customer) => customer.id === object.customer_id)
        ?.name,
    }
  })
}

export const getNormalizedSource = (src: string) => {
  let source = src.toLowerCase()

  if (source.includes('rivata')) source = 'Dashboard'
  else if (source.includes('installer')) {
    source = `Install Manager (${source.includes('native') ? 'native' : 'web'})`
  }

  return source
}

const WARNING_CASE_COUNT = 21

export const getStatusFromState = (warningState: string) => {
  const flags = warningState.split('')
  const fToBool = (idx: Array<number>) => idx.some((i) => flags[i] === '1') // atleast one must be true to be marked as a warning

  if (flags.length !== WARNING_CASE_COUNT) {
    throw new Error(
      `Invalid Warning State string, make sure that state contains all warning cases. Actual state size = ${WARNING_CASE_COUNT}, Received: ${flags.length}`,
    )
  }

  const smarthubNoStatus = fToBool([13])

  return {
    smartHub: {
      vibration: {
        critWarn: fToBool([8, 10]),
        warn: fToBool([6, 7, 9]),
        noStatus: smarthubNoStatus,
      },
      temperature: {
        critWarn: fToBool([5]),
        warn: fToBool([4]),
        noStatus: smarthubNoStatus,
      },
      critWarn: fToBool([5, 8, 10]),
      warn: fToBool([4, 6, 7, 9]),
      noStatus: smarthubNoStatus,
    },
    tpms: {
      fastLeak: fToBool([20]),
      critWarn: fToBool([1, 3, 20]),
      warn: fToBool([0, 2]),
      noStatus: fToBool([12]),
    },
    linePressure: {
      critWarn: fToBool([11]),
      warn: false,
      noStatus: fToBool([15]),
    },
    axleLoad: {
      critWarn: false,
      warn: false,
      noStatus: fToBool([16]),
    },
    gateway: {
      critWarn: false,
      warn: false,
      noStatus: fToBool([14]),
    },
  }
}

export const convertCronToTimezone = (cron: string, timezone: string) => {
  const cronParts = cron.split(' ')
  const minutes = cronParts[0]
  const hours = cronParts[1]
  const day = cronParts[2]
  const month = cronParts[3]
  const daysOfWeek = cronParts[4]

  let tzCron = cron
  let mode = CronModes.atHoursAndMinutes
  let displacementMode: number = CronDisplacementModes.none
  let daysOfWeekIds: any[] = []

  // mode detection
  if (day === '*' && month === '*' && daysOfWeek === '*') {
    // hourly tab
    if (hours.includes('*/') && minutes === '0') {
      // every n hour(s)
      mode = CronModes.everyNHours
    } else if (hours === '*' && minutes.includes('*/')) {
      // every n hour(s)
      mode = CronModes.everyNMinutes
    } else {
      mode = CronModes.atHoursAndMinutes
    }
  }

  if (day !== '*') {
    if (day.includes('1/')) {
      // daily tab
      // every n day(s)
      mode = CronModes.everyNDays
    } else {
      // monthly tab
      // n day(s) of every month
      mode = CronModes.nDaysOfEveryMonth
    }
  }

  if (daysOfWeek !== '*') {
    if (daysOfWeek === '1-5') {
      // daily tab
      // every week day
      mode = CronModes.everyWeekdays
    } else {
      // weekly tab
      mode = CronModes.weekly

      // is sequential
      if (daysOfWeek.includes('-')) {
        let days = daysOfWeek.split('-')
        daysOfWeekIds = days.map(Number)
        let firstId = Number(daysOfWeekIds[0])
        let lastId = Number(daysOfWeekIds[1])

        for (let i = firstId; i < lastId; i++) {
          if (!daysOfWeekIds.includes(i)) {
            daysOfWeekIds.push(i)
          }
        }
      } else if (daysOfWeek.includes(',')) {
        let days = daysOfWeek.split(',')

        for (let i = 0; i < days.length; i++) {
          daysOfWeekIds.push(Number(days[i]))
        }
      } else {
        daysOfWeekIds.push(Number(daysOfWeek))
      }
    }
  }

  let m = minutes.includes('*/') ? Number(minutes.slice(2)) : Number(minutes)
  let h = hours.includes('*/')
    ? Number(hours.slice(2))
    : Number(hours)
    ? Number(hours)
    : 0
  let d = day.includes('1/') ? Number(day.slice(2)) : Number(day)

  let time = moment.tz(`${h}:${m}`, 'H:m', timezone)

  let utcH = moment.utc(`${h}:${m}`, 'H:m').hours()
  let utcM = moment.utc(`${h}:${m}`, 'H:m').minutes()

  // day of month
  let offset = 0
  offset = moment.tz(timezone).utcOffset() / 60

  let minutesOffset = time.utcOffset() % 60

  if (timezone) {
    m = time.minutes() + minutesOffset
    h = Math.abs(h) === 0.5 ? 0 : Math.ceil(time.hours() + offset)
    displacementMode =
      h < 0
        ? CronDisplacementModes.backward
        : h > 23
        ? CronDisplacementModes.forward
        : CronDisplacementModes.none

    h = h < 0 ? 24 + h : h > 23 ? 0 + h - 24 : h
    m = m < 0 ? 60 + m : m > 50 ? 0 + m - 60 : m

    if (displacementMode === CronDisplacementModes.forward) {
      d = d + 1
    } else if (displacementMode === CronDisplacementModes.backward) {
      d = d - 1
    }

    if (d < 1) {
      d = 31
    } else if (d > 31) {
      d = 1
    }
  }

  let utcHours = h
  let utcMinutes = m

  if (mode !== CronModes.everyNHours && mode !== CronModes.everyNMinutes) {
    utcHours = time.hours()
    utcMinutes = time.minutes()
  }

  let tzMinutes = utcMinutes
  let tzHours = utcHours

  if (
    mode !== CronModes.everyNHours &&
    mode !== CronModes.everyNMinutes &&
    timezone
  ) {
    let convertedTime = moment
      .utc(`${tzMinutes} ${tzHours}`, 'm H')
      .tz(timezone)
    tzMinutes = convertedTime.minutes()
    tzHours = convertedTime.hours()
  }

  switch (mode) {
    case CronModes.everyNMinutes:
      tzCron = `*/${utcM} * * * *`
      break
    case CronModes.everyNHours:
      tzCron = `0 */${utcH} * * *`
      break
    case CronModes.atHoursAndMinutes:
      tzCron = `${tzMinutes} ${tzHours} * * *`
      break
    case CronModes.everyNDays:
      tzCron = `${tzMinutes} ${tzHours} 1/${d} * *`
      break
    case CronModes.everyWeekdays:
      tzCron = `${tzMinutes} ${tzHours} * * 1-5`
      break
    case CronModes.nDaysOfEveryMonth:
      tzCron = `${tzMinutes} ${tzHours} ${d} * *`
      break
    case CronModes.weekly:
      tzCron = `${tzMinutes} ${tzHours} * * ${selectedWeekDays(
        displacementMode,
        daysOfWeekIds,
      )}`
      break
  }

  return tzCron
}

export const selectedWeekDays = (
  displacementMode: number = CronDisplacementModes.none,
  weekDays: Array<number>,
) => {
  switch (displacementMode) {
    case CronDisplacementModes.forward:
      for (let i = 0; i < weekDays.length; i++) {
        const d = weekDays[i]
        if (d === CronWeekDaysIds.saturday) {
          weekDays[i] = CronWeekDaysIds.sunday
        } else {
          weekDays[i] = d + 1
        }
      }
      break
    case CronDisplacementModes.backward:
      for (let i = 0; i < weekDays.length; i++) {
        const d = weekDays[i]
        if (d === CronWeekDaysIds.sunday) {
          weekDays[i] = CronWeekDaysIds.saturday
        } else {
          weekDays[i] = d - 1
        }
      }
      break
  }

  weekDays.sort((a, b) => a - b)

  if (weekDays.length === 1) {
    return `${weekDays[0]}`
  } else if (weekDays.length === 7) {
    return '0-6'
  } else if (weekDays.length > 0) {
    if (isSequential(weekDays)) {
      return `${weekDays[0]}-${weekDays[weekDays.length - 1]}`
    } else {
      let weekDayString = ''

      weekDays.forEach((wd, i) => {
        weekDayString += wd

        if (i + 1 !== weekDays.length) {
          weekDayString += ','
        }
      })

      return weekDayString
    }
  } else {
    return '*'
  }
}

export const isSequential = (weekDays: number[]): boolean => {
  // check if id list is sequential
  let inSequence = true

  for (let i = 0; i < weekDays.length; i++) {
    if (weekDays.length > i + 1) {
      if (weekDays[i + 1] - weekDays[i] !== 1) {
        inSequence = false
      }
    }
  }

  return inSequence
}

export const saveGoogleAnalyticsEvent = (
  name: string,
  params: any = {},
): void => {
  const userName = store.getState().auth.user.userName

  const eventParams = {
    ...params,
    user_name: userName,
  }

  ReactGA.event(name, eventParams)
}

export const reorderTableColumns = (
  list: any[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const useInterval = (callback: any, delay: any) => {
  const intervalRef: any = useRef(null)
  const savedCallback = useRef(callback)
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay)
      return () => window.clearInterval(intervalRef.current)
    }
  }, [delay])
  return intervalRef
}

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}
