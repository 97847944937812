import baseApi from './BaseApi'

class CalibrationApi {
  // odometer
  getAssetOdometerCalibration = (assetId: number | string) => {
    return baseApi.get(`rivata/calibration/odometer?asset_id=${assetId}`)
  }

  postAssetOdometerCalibration = (data: any) => {
    return baseApi.post('rivata/calibration/odometer', data)
  }

  deleteAssetOdometerCalibration = (id: number | string) => {
    return baseApi.delete(`rivata/calibration/odometer?id=${id}`)
  }

  uploadOdometerCsv = (
    base64csv: string,
    unitsOfMeasurement: string,
    timezone: string,
    customerId: string,
  ) => {
    let queryString = `?customer_id=${customerId}&units_of_measurement=${unitsOfMeasurement}&timezone=${timezone}`

    return baseApi.post(
      `rivata/calibration/odometer/upload${queryString}`,
      base64csv,
    )
  }

  // axle load
  postAxleLoadCalibrationData = (data: any) => {
    return baseApi.post(`rivata/hardware/axleload_calibration_data`, data)
  }

  getAxleLoadCalibrationData = (id: number, offset: number, limit: number) => {
    return baseApi.get(
      `rivata/hardware/axleload_calibration_data?asset_id=${id}&offset=${offset}&limit=${limit}`,
    )
  }

  putAxleLoadCalibrationData = (data: any) => {
    return baseApi.put('rivata/hardware/axleload_calibration_data', data)
  }

  deleteAxleLoadCalibrationData = (assetId: number, cDataId: number) => {
    return baseApi.delete(
      `rivata/hardware/axleload_calibration_data?asset_id=${assetId}&calibration_data_id=${cDataId}`,
    )
  }
}

const calibrationApi = new CalibrationApi()

export default calibrationApi
