import { AssetTypes, SubscriptionTypes } from './../../enums/index';
import { useMemo } from 'react'

interface IAssetHardware {
    id: number
    asset_id: number
    vin: string
    position: string
    position_type: string
    hardware_type: string,
    hardware_type_id: number
    mac: string
    last_updated_at_epoch: number | null
    prev_update_at_epoch: number | null
    last_update: number | null
    formatted_last_update: string | null
    gateway_mac: string
}

interface ISensorTab {
    hardwareTypes: Array<string>
    label: string
    order: number
    hardwareTypeId: number
}


interface IuseTablesConfig {
    (
        sortedData: Array<IAssetHardware>,
        setOnlyIgnoredSensors: (b: boolean) => void,
        assetType: string
    ): Array<ISensorTab>
}


export const useTablesConfig: IuseTablesConfig = (sortedData, setOnlyIgnoredSensors, assetType) => {
    return useMemo(() => {
        let data: Array<ISensorTab> = []
        
        sortedData.forEach((hardware) => {
            const hardwareType = hardware.hardware_type
            const alreadyExist = data.some(el => el.hardwareTypes.includes(hardwareType))

            if (assetType === AssetTypes.TRACTOR && hardwareType === SubscriptionTypes.linePressure.toLowerCase()) return
            if (alreadyExist) return 
            setOnlyIgnoredSensors(false)

            switch (hardwareType) {
                case "gateway":
                    data.push({ hardwareTypes: [hardwareType], label: "Gateways", order: 0, hardwareTypeId: hardware.hardware_type_id})
                    break;
                case "hub_sensor":
                    data.push({ hardwareTypes: [hardwareType], label: "SmartHub", order:1, hardwareTypeId: hardware.hardware_type_id})
                    break;
                case "tire_sensor":
                    data.push({ hardwareTypes: [hardwareType], label: "TPMS", order:2, hardwareTypeId: hardware.hardware_type_id})
                    break;
                case "axle_load":
                    data.push({ hardwareTypes: [hardwareType], label: "Axle Load", order:3, hardwareTypeId: hardware.hardware_type_id})
                    break;
                case "line_pressure":
                    data.push({ hardwareTypes: [hardwareType], label: "Line Pressure", order:4, hardwareTypeId: hardware.hardware_type_id})
                    break;
                default:
                    data.push({ hardwareTypes: [hardwareType], label: "Others", order:5, hardwareTypeId: hardware.hardware_type_id})
                    break;
            }
        })

        return data.sort((a, b)=> a.order-b.order)
    }, [sortedData, setOnlyIgnoredSensors, assetType])
}