import { useEffect, useState } from 'react'
import { getClearLabel } from '../../utils'
import { NoDataLabels } from '../../enums'

export const useTableColumns = () => {
    const [columns, setColumns] = useState([])

    useEffect(() => {
        const columns = [
            {
                label: "Id",
                id: "id"
            },
            {
                label: "Asset Id",
                id: "asset_id",
            },
            {
                label: "Json",
                id: "json",
            },
            {
                label: "Created At",
                id: "createdat"
            },
            {
                label: "Source",
                id: "source"
            }
        ]

        setColumns(columns)
    }, [])

    return columns;
}

export const useTableRows = (data) => {
    const [rows, setRows] = useState([])

    useEffect(() => {
        const d = data.map((row) => {
            return {
                columns: [
                    {   
                        id: row.id,
                        type: "text",
                        label: row.id,
                        columnId: "id"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.asset_id,
                        columnId: "asset_id"
                    },
                    {
                        id: row.id,
                        type: "json",
                        label: row.jvalue,
                        columnId: "json",
                        width: '500px'
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.formatted_created_datetime || NoDataLabels.DASH,
                        columnId: "createdat"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.source ? getClearLabel(row.source) : NoDataLabels.DASH,
                        columnId: "source"
                    }

                ],
                id: row.id
            }
        })
        setRows(d);
    }, [data]);

    return rows;
}