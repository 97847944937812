import React from 'react'
import HealthStatusRow from '../ReportParametersRows/HealthStatusRow'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import GeofencesRow from '../ReportParametersRows/GeofencesRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'
import CustomerRow from '../ReportParametersRows/CustomerRow'
import GeofenceGroupsRow from '../ReportParametersRows/GeofenceGroupsRow'

const ActiveWarningsReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      {props.isSchedule && (
        <CustomerRow value={props.parameters.customer_keys} />
      )}
      {props.isSchedule && <TimeZoneRow value={props.parameters.timezone} />}

      <HealthStatusRow value={props.parameters.warning_filter} />
      <AssetTypeRow value={props.parameters.asset_type} />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
      <GeofencesRow value={props.parameters.selected_geofences} />
      <GeofenceGroupsRow value={props.parameters.geofence_group} />
    </>
  )
}
export default ActiveWarningsReportParameters
