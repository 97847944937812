import React, { useCallback, useMemo, useState } from 'react'
//@ts-ignore
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Button } from 'reactstrap'

type Props = {
  file: any
}

const PdfPreview: React.FC<Props> = ({ file }) => {
  const [numPages, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages)
      setPageNumber(1)
    },
    [],
  )

  const fileObj = useMemo(() => ({ url: file }), [file])

  return (
    <div
      className='d-flex flex-column align-items-center justify-content-between'
      style={{ width: '100%' }}
    >
      <Document
        file={fileObj}
        className='border border-dark'
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={1104} />
      </Document>

      <div className='d-flex' style={{ marginTop: '20px' }}>
        <Button
          disabled={pageNumber === 1}
          onClick={() => {
            if (pageNumber > 1) setPageNumber(pageNumber - 1)
          }}
        >
          &#8592;
        </Button>

        <div
          className='d-flex align-items-center justify-content-center'
          style={{ width: '100px' }}
        >
          <span>
            Page {pageNumber} of {numPages}
          </span>
        </div>

        <Button
          disabled={pageNumber === numPages}
          onClick={() => {
            if (pageNumber < numPages) setPageNumber(pageNumber + 1)
          }}
        >
          &#8594;
        </Button>
      </div>
    </div>
  )
}

export default React.memo(PdfPreview)
