import React, { useState } from "react"
import { Label } from "reactstrap"
import ConfirmModal from "../ConfirmModal"
import "./style.scss"


const FormCheckbox: React.FC<{ id: string, label: string, checked: boolean, onChange: () => void }> = ({ 
    id, 
    label, 
    checked,
    onChange
}) => {
    return (
        <div className="d-flex align-items-center ml-2 p-1">
            <input 
                id={id}
                type="checkbox" 
                onChange={onChange}
                checked={checked}
                className="mr-2"
            />
            <Label for={id} className="m-0">{label}</Label>
        </div>
    )
}


type ListItem = { id: number | string, label: string }
export type EnvStatus = {
    [key: string]: boolean
    dev: boolean
    staging: boolean
}

interface Props {
    header: string
    title: string
    data: Array<ListItem>
    onClose: () => void
    onSubmit: (env: EnvStatus, arr: Array<number | string>) => void
}

const CopyToEnvModal: React.FC<Props> = ({
    header,
    title,
    data,
    onClose,
    onSubmit
}) => {
    const [checkedIds, setCheckedIds] = useState<Array<number | string>>([])
    const [env, setEnv] = useState<EnvStatus>({ dev: false, staging: false })
    
    return (
        <ConfirmModal
            header={header}
            open={true}
            onClose={onClose}
            modalButtons={[{
                id: 1, 
                label: "Submit", 
                color: "success", 
                disabled: (!env.dev && !env.staging) || !checkedIds.length,
                onClick: () => onSubmit(env, checkedIds)
            }]}
        >
            <div>
                <h6 className="text-center">Select Environment</h6>
                <div>
                    {Object.keys(env).map((el) => {
                        if (process.env.REACT_APP_STAGE === "staging" && el === "staging") return null

                        return (
                            <FormCheckbox
                                key={el}
                                id={el}
                                checked={env[el]}
                                label={el.toUpperCase()}
                                onChange={() => setEnv(prev => ({ ...prev, [el]: !prev[el] }))}
                            />
                        )
                    })}
                </div>
            </div>
            <hr/>
            <div className="copy-modal">
                <h6 className="text-center">{title}</h6>
                <div className="copy-form">
                    <FormCheckbox
                        key={"all"}
                        id={"all"}
                        checked={checkedIds.length === data.length}
                        label={"All"}
                        onChange={() => {
                            if (checkedIds.length === data.length) setCheckedIds([])
                            else setCheckedIds(data.map(el => el.id))
                        }}
                    />
                    {data.map(el => {
                        if (!el.id) return null

                        return (
                            <FormCheckbox
                                key={el.id}
                                id={el.label + el.id}
                                checked={checkedIds.includes(el.id)}
                                label={el.label}
                                onChange={() => { 
                                    setCheckedIds(prev => {
                                        const curr = [ ...prev ]
                                        const idx = curr.findIndex(id => id === el.id)

                                        if (idx !== -1) curr.splice(idx, 1)
                                        else if (el.id) curr.push(el.id)

                                        return curr
                                    })
                                }}
                            />
                        )
                    })}
                </div>
            </div>
        </ConfirmModal>
    )
}

export default CopyToEnvModal