import React, { useState } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Button,
  Input,
  FormFeedback,
} from 'reactstrap'
import PhoneInput from 'react-phone-number-input/input'
import TimezonePicker from '../../components/TimezonePicker'
import LanguageDropdown from '../../components/LanguageDropdown'
import Toggle from '../Toggle'
import NotificationsSettings from './NotificationsSettings'
import PhoneNumberVerificationModal from './PhoneNumberVerificationModal'
import { validatePhone } from '../../utils'
import { UnitsOfMeasurementConfigItems } from '../../constants/constants'

import { useActions } from '../../hooks/useActions'

import './style.scss'

const PreferencesForm = ({
  onSubmit,
  onTimezoneChange,
  onLanguageChange,
  phone,
  email,
  timezone,
  language,
  hasLanguage = false,
  locale,
  showUnitsOfMeasureSelector,
  onPhoneChange,
  isUserProfile,
  phoneNumberVerified,
  isVerifyPhoneActive = true,
  firstName,
  lastName,
  roles,
  onFirstNameChange,
  onLastNameChange,
  unitsOfMeasurementConfig,
  setUnitsOfMeasurementConfig,
}) => {
  const [phoneValidationData, setPhoneValidationData] = useState({
    isValid: true,
    errorMessage: '',
  })
  const [verificationFormVisible, setVerificationFormVisible] = useState(false)

  const toggleVerificationForm = () => {
    setVerificationFormVisible(!verificationFormVisible)
  }

  const { requestVerificationCode, verifyCode } = useActions()

  return (
    <Form
      className='w-100'
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        setPhoneValidationData(validatePhone(phone))

        if (phoneValidationData.isValid) onSubmit(e)
      }}
    >
      {isUserProfile && (
        <>
          <Row>
            <Col md='6'>
              <FormGroup className='w-100'>
                <Label>Email</Label>
                <Input
                  disabled
                  type='text'
                  name='email'
                  id='textemail'
                  placeholder=''
                  value={email || ''}
                  onChange={() => {}}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <FormGroup className='w-100'>
                <Row>
                  <Col>
                    <Label>Phone</Label>
                  </Col>
                </Row>

                <Row>
                  <Col md='6'>
                    <PhoneInput
                      type='text'
                      name='phone'
                      placeholder='Enter Phone Here'
                      defaultCountry='US'
                      value={phone || ''}
                      onChange={(value) => {
                        onPhoneChange(value ?? '')
                        setPhoneValidationData({
                          isValid: true,
                          errorMessage: '',
                        })
                      }}
                      onBlur={() =>
                        setPhoneValidationData(validatePhone(phone))
                      }
                      className={`form-control ${
                        !phoneValidationData.isValid ? 'is-invalid' : ''
                      }`}
                    />
                    <FormFeedback tooltip>
                      {phoneValidationData.errorMessage}
                    </FormFeedback>
                  </Col>

                  <Col md='2'>
                    {!phoneNumberVerified && (
                      <>
                        <Button
                          color='warning'
                          onClick={toggleVerificationForm}
                          disabled={!isVerifyPhoneActive}
                        >
                          Verify Phone Number
                        </Button>
                        {verificationFormVisible && (
                          <PhoneNumberVerificationModal
                            open={verificationFormVisible}
                            onCancel={toggleVerificationForm}
                            onVerificationRequestCodeClick={
                              requestVerificationCode
                            }
                            onVerifyCodeClick={verifyCode}
                            phoneNumber={phone}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <FormGroup className='w-100'>
                <Label>First Name</Label>
                <Input
                  type='text'
                  name='firstname'
                  id='firstname'
                  placeholder=''
                  value={firstName || ''}
                  onChange={(e) => onFirstNameChange(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <FormGroup className='w-100'>
                <Label>Last Name</Label>
                <Input
                  type='text'
                  name='lastname'
                  id='lastname'
                  placeholder=''
                  value={lastName || ''}
                  onChange={(e) => onLastNameChange(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <FormGroup className='w-100'>
                <Label>Role</Label>
                <Input
                  disabled
                  type='text'
                  name='role'
                  id='role'
                  placeholder=''
                  value={roles?.join(',').replace('_', '')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <h3 className='card-title'>{locale.locale || 'Locale'}</h3>
      <hr />
      <Row>
        <Col md='6'>
          <FormGroup className='w-100'>
            <Label>{locale.timezone || 'Timezone'}</Label>
            <TimezonePicker
              onSetTimezone={onTimezoneChange}
              initialValue={timezone}
              locale={locale}
              id='user_profile'
            />
          </FormGroup>
          {hasLanguage && (
            <FormGroup className='w-100'>
              <Label className='d-block'>{locale.language || 'Language'}</Label>
              <LanguageDropdown
                onSelect={onLanguageChange}
                selected={language}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      {showUnitsOfMeasureSelector ? (
        <>
          <h3>Units of Measure</h3>
          <hr />
          <Row className='mb-2'>
            <Col
              md={1}
              className={"d-flex align-items-center"}
            >
              <Label>Pressure</Label>
            </Col>

            <Col md='2'>
              <Toggle
                items={UnitsOfMeasurementConfigItems.pressure}
                onChange={(value) => {
                  setUnitsOfMeasurementConfig({
                    ...unitsOfMeasurementConfig,
                    pressure: value,
                  })
                }}
                selected={unitsOfMeasurementConfig?.pressure}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col
              md={1}
              className={"d-flex align-items-center"}
            >
              <Label>Temperature</Label>
            </Col>

            <Col md='2'>
              <Toggle
                items={UnitsOfMeasurementConfigItems.temperature}
                onChange={(value) => {
                  setUnitsOfMeasurementConfig({
                    ...unitsOfMeasurementConfig,
                    temperature: value,
                  })
                }}
                selected={unitsOfMeasurementConfig?.temperature}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col
              md={1}
              className={"d-flex align-items-center"}
            >
              <Label>Distance</Label>
            </Col>

            <Col md='2'>
              <Toggle
                items={UnitsOfMeasurementConfigItems.distance}
                onChange={(value) => {
                  setUnitsOfMeasurementConfig({
                    ...unitsOfMeasurementConfig,
                    distance: value,
                  })
                }}
                selected={unitsOfMeasurementConfig?.distance}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col
              md={1}
              className={"d-flex align-items-center"}
            >
              <Label>Speed</Label>
            </Col>

            <Col md='2'>
              <Toggle
                items={UnitsOfMeasurementConfigItems.speed}
                onChange={(value) => {
                  setUnitsOfMeasurementConfig({
                    ...unitsOfMeasurementConfig,
                    speed: value,
                  })
                }}
                selected={unitsOfMeasurementConfig?.speed}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col
              md={1}
              className={"d-flex align-items-center"}
            >
              <Label>Weight</Label>
            </Col>

            <Col md='2'>
              <Toggle
                items={UnitsOfMeasurementConfigItems.weight}
                onChange={(value) => {
                  setUnitsOfMeasurementConfig({
                    ...unitsOfMeasurementConfig,
                    weight: value,
                  })
                }}
                selected={unitsOfMeasurementConfig?.weight}
              />
            </Col>
          </Row>
        </>
      ) : null}

      <Row>
        <Col>
          <NotificationsSettings />
        </Col>
      </Row>

      {onSubmit && (
        <>
          <hr />
          <div className='form-actions'>
            <Row>
              <Col md='6'>
                <div className='d-flex align-items-center'>
                  <Button type='submit' color='primary' disabled={false}>
                    {locale.submit || 'Submit'}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Form>
  )
}

export default PreferencesForm
