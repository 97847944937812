import React, { useState } from 'react'
import InfoModal from '../../components/InfoModal'
import RivataModule from '../../components/RivataModule'
import RivataLoader from '../../components/RivataLoader'
import TresholdsForm from '../../components/TresholdsForm'
import { celsiusToFahr, kmhToMph } from '../../utils'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

const TresholdsModule = ({
  width,
  locale,
  vibrationTreshold,
  temperatureTreshold,
  criticalTemperatureTreshold,
  linePressureThreshold,
  overSpeedThreshold,
  criticalOverSpeedThreshold,
  onSave,
  isLoading,
  unitsOfMeasurementConfig = {},
  customerId,
}) => {
  const [status, setStatus] = useState({})
  return (
    <RivataModule title='Thresholds' width={width} locale={locale}>
      <InfoModal
        header='Thresholds info'
        open={status.statusCode >= 200}
        message={status.message || ''}
        onConfirm={() => setStatus({})}
      />
      {!temperatureTreshold ||
      !linePressureThreshold ||
      !criticalOverSpeedThreshold ||
      isLoading ? (
        <RivataLoader />
      ) : (
        <TresholdsForm
          initialValues={{
            // vibration_treshold: vibrationTreshold || "",
            critical_temperature_treshold:
              unitsOfMeasurementConfig.temperature ===
              UnitsOfMeasurementConfig.temperature.fahrenheit
                ? celsiusToFahr(criticalTemperatureTreshold).toFixed(1) || ''
                : typeof criticalTemperatureTreshold === 'number'
                ? criticalTemperatureTreshold.toFixed(2) || ''
                : '',
            temperature_treshold:
              unitsOfMeasurementConfig.temperature ===
              UnitsOfMeasurementConfig.temperature.fahrenheit
                ? celsiusToFahr(temperatureTreshold).toFixed(1) || ''
                : typeof temperatureTreshold === 'number'
                ? temperatureTreshold.toFixed(2) || ''
                : '',
            line_pressure_treshold: linePressureThreshold || '',
            critical_over_speed_threshold:
              unitsOfMeasurementConfig.speed ===
              UnitsOfMeasurementConfig.speed.mph
                ? kmhToMph(criticalOverSpeedThreshold).toFixed(0) || ''
                : typeof criticalOverSpeedThreshold === 'number'
                ? criticalOverSpeedThreshold.toFixed(0) || ''
                : '',
            over_speed_threshold:
              unitsOfMeasurementConfig.speed ===
              UnitsOfMeasurementConfig.speed.mph
                ? kmhToMph(overSpeedThreshold).toFixed(0) || ''
                : typeof overSpeedThreshold === 'number'
                ? overSpeedThreshold.toFixed(0) || ''
                : '',
          }}
          onSave={async (values) => {
            const res = await onSave(values)
            setStatus(res)
          }}
          unitsOfMeasurementConfig={unitsOfMeasurementConfig}
          customerId={customerId}
        />
      )}
    </RivataModule>
  )
}

export default TresholdsModule
