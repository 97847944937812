import React from "react"
import RivataLoader from "../../components/RivataLoader"
import RivataModule from "../../components/RivataModule"
import StatusAlert from "../../components/StatusAlert"
import './style.scss'
import { NoDataLabels } from "../../enums"

interface Props {
  locale: ILocale
  place: any
  isLoading: boolean
  title: string
  status: IStatus
}

const CargotagPlaceDetails: React.FC<Props> = ({
  locale,
  place, // asset or geofence
  isLoading,
  title,
  status,
}) => {
  return (
    <RivataModule
      title={title}
      locale={locale}
      width={4}
      filters={null}
      error={status}
    >
      <>
        {isLoading ? (
          <RivataLoader/>
        ) : place ? (
          <ul className="cargotag-asset__info">
            <li>Name: <span>{place.name}</span></li>
            {place.type === "ASSET" && <li>VIN: <span>{place.vin}</span></li>}
            <li>Customer Name: <span>{place.customer_name}</span></li>
              {place.gateways.map((el: any, i: number) => {
                return (
                  <li key={i} className="gateways__list">
                    <label>Gateway ESN: <span>{el.esn}</span></label>
                    <label>Last GPS Update: <span>{el.date ? el.date : NoDataLabels.DASH}</span></label>
                  </li>
                )
              })}
            {/* <li>Gateways: <span>{place.gateways?.map((el: any) => <label>{el.esn + (el.date ? `- ${el.date}` : "")}</label>)}</span></li> */}
            <li>Tags Count: <span>{place.tags_data?.length || 0}</span></li>
          </ul>
        ) : (
          <StatusAlert color="success" statusText="No Data" customText={undefined} statusCode={undefined} />
        )}
      </>
    </RivataModule>
  )
}

export default React.memo(CargotagPlaceDetails)