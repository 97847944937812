import { Dispatch } from "react"
import { GeofenceType } from "../../enums"
import api from "../../services/api"
import { convertDataEpochToDate, getErrorObj } from "../../utils"

import { getStatusFromState, getStatusObj } from "../../utils/utils"
import { composeLocationsWarnings } from "../dashboard/action"
import { ISortOptions, IgetState, ThunkResult } from "../types"
import { GeofencesActionTypes } from "./types"


export const setGeofencesLimit = (payload: number) => ({
	type: GeofencesActionTypes.SET_GEOFENCES_LIMIT,
	payload
})

export const setGeofencesOffset = (payload: number) => ({
	type: GeofencesActionTypes.SET_GEOFENCES_OFFSET,
	payload
})

export const setGeofencesSortOptions = (payload: ISortOptions) => ({
	type: GeofencesActionTypes.SET_GEOFENCES_SORT,
	payload
})

export const setGeofenceAssetsData = (payload: Array<any>) => ({
	type: GeofencesActionTypes.SET_ASSETS,
	payload
})

export const setGeofenceAssetsLimit = (payload: number) => ({
	type: GeofencesActionTypes.SET_ASSETS_LIMIT,
	payload
})

export const setGeofenceAssetsOffset = (payload: number) => ({
	type: GeofencesActionTypes.SET_ASSETS_OFFSET,
	payload
})

export const setGeofenceAssetsSortOptions = (payload: ISortOptions) => ({
	type: GeofencesActionTypes.SET_ASSETS_SORT,
	payload
})


export const fetchGeofences = (filter: any = ""): ThunkResult<void> => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: GeofencesActionTypes.LOADING_GEOFENCES, payload: true })
			const res = await api.getGeoLocations(9999, 0, GeofenceType.GEOFENCE, filter)

			const geofences = convertDataEpochToDate(res.paged_data, "created_at_epoch", "created_at")

			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES, payload: geofences })
			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES_COUNT, payload: res.total_count })
			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES_STATUS, payload: getStatusObj() })
		} catch (err) {
			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES_STATUS, payload: getStatusObj(err) })
		} finally {
			dispatch({ type: GeofencesActionTypes.LOADING_GEOFENCES, payload: false })
		}
	}
}

export const fetchGeofenceById = (id: string): ThunkResult<void> => {
	return async (dispatch) => {
		try {
			const res = await api.getGeolocationById(id)

			dispatch({ type: GeofencesActionTypes.SET_SELECTED_GEOFENCE, payload: res.paged_data[0] || null })
			dispatch({ type: GeofencesActionTypes.SET_SELECTED_GEOFENCE_STATUS, payload: getStatusObj() })
		} catch (err) {
			dispatch({ type: GeofencesActionTypes.SET_SELECTED_GEOFENCE_STATUS, payload: getStatusObj(err) })
		}
	}
}

export const fetchGeofenceAssetsById = (id: string, filters = "", sortOptions?: any): ThunkResult<void> => {
	return async (dispatch) => {
		try {
			dispatch({ type: GeofencesActionTypes.LOADING_ASSETS, payload: true })

			const res = await api.getGeolocationAssetsById(id, filters, sortOptions)

			const assets = convertDataEpochToDate(res, "epoch", "datetime")
			const assetsWithWarnings = composeLocationsWarnings(assets)

			dispatch({ type: GeofencesActionTypes.SET_ASSETS, payload: assetsWithWarnings })
			dispatch({ type: GeofencesActionTypes.SET_ASSETS_TOTAL_COUNT, payload: assetsWithWarnings.length })
		} catch (err) {
			console.log(err)
		} finally {
			dispatch({ type: GeofencesActionTypes.LOADING_ASSETS, payload: false })
		}
	}
}

export const fetchGeofencePageAssetsLocations = (filter: any = ""): ThunkResult<void> => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: GeofencesActionTypes.LOADING_GEOFENCES_ASSETS_LOCATIONS, payload: true })

			const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets
			const queryParams = new URLSearchParams(filter)

			if (hideAutogeneratedAssets) {
				queryParams.append("hide_autogenerated", "true")
			}

			const res = await api.getLatestGpsV2(queryParams.toString())
				.then((locations) => {
					locations.forEach((loc: any) => {
						const warningInfo = getStatusFromState(loc.warning_state)
						loc.hasCriticalWarning = warningInfo.tpms.critWarn || warningInfo.smartHub.critWarn || warningInfo.linePressure.critWarn
						loc.hasWarning = warningInfo.tpms.warn || warningInfo.smartHub.warn
						delete loc.warning_state
					})

					return locations
				})
			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS, payload: res })
			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS_STATUS, payload: getStatusObj() })
		} catch (err) {
			dispatch({ type: GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS_STATUS, payload: getStatusObj(err) })
		} finally {
			dispatch({ type: GeofencesActionTypes.LOADING_GEOFENCES_ASSETS_LOCATIONS, payload: false })
		}
	}
}

export function postGeofencesCsv(base64str: string) {
	return async function (dispatch: Dispatch<any>, getState: IgetState) {
		try {
			dispatch({ type: GeofencesActionTypes.LOADING_GEOFENCES_ASSETS_LOCATIONS, payload: true })
			const selectedCustomersList = getState().common.customers.selectedCustomersList
			if (selectedCustomersList.length !== 1) return
  
			const response = await api.postGeofencesCsv(base64str, selectedCustomersList[0].id)

			if (response.invalid_data?.length || response.incorrect_name_formats?.length || response.already_exists_names?.length || response.invalid_data_types?.length) {
			  return response
			} else {
			  dispatch(fetchGeofences())
			  const errorObj = getErrorObj({ statusCode: 200, message: "Geofences uploaded successfully" })
			  return errorObj
			}
		} catch (err) {
			const errorObj = getErrorObj(err as any);
			return errorObj
		} finally {
			dispatch({ type: GeofencesActionTypes.LOADING_GEOFENCES_ASSETS_LOCATIONS, payload: false })
		}      
	}
  }
  