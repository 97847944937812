import baseApi from './BaseApi'

class CargotagsApi {
  getCargotags = (
    limit: number,
    offset: number,
    unassigned: boolean,
    sortOptions?: { column: string; direction: 'asc' | 'desc' },
    filter?: string,
  ) => {
    const queryParams = new URLSearchParams(filter)

    queryParams.append('limit', limit.toString())
    queryParams.append('offset', offset.toString())

    if (unassigned)
      queryParams.append('get_unassigned_tags', unassigned.toString())

    if (sortOptions && sortOptions.column) {
      const { column, direction } = sortOptions
      queryParams.append('sort_column', column)
      queryParams.append('sort_direction', direction)
    }

    return baseApi.get(`rivata/cargotags?${queryParams.toString()}`)
  }

  putCargotag = (id: string, data: Pick<ICargoTag, 'cargo_tag_name'>) => {
    return baseApi.put(`rivata/cargotags?id=${id}`, data)
  }

  getAssetsWithCargotags = (assetId?: number) => {
    const queryString = assetId ? `?asset_id=${assetId}` : ''

    return baseApi.get('rivata/cargotags/assets' + queryString, !assetId)
  }

  getLocationsWithCargotags = (locationId?: number) => {
    const queryString = locationId ? `?location_id=${locationId}` : ''

    return baseApi.get('rivata/cargotags/locations' + queryString, !locationId)
  }

  postCustomerCargoTagsCsv = (
    base64csv: string,
    customerId: number,
    reassignAll?: boolean,
  ) => {
    let queryString = `?customer_id=${customerId}`

    if (reassignAll) queryString += '&reassign_all=true'

    return baseApi.post(`rivata/cargotags${queryString}`, base64csv)
  }

  assignCargoTagsToCustomer = (
    body: Array<{ hi_id: number; customer_id: number }>,
  ) => {
    return baseApi.put(`rivata/cargotags/assign`, body)
  }

  migrateCargoTagsBetweenCustomers = (body: {
    hi_id: number
    customer_id: number
    current_customer_id: number
  }) => {
    return baseApi.post('rivata/cargotags/migrate', body)
  }

  getCargoTagsLimitedData = (getAll?: boolean) => {
    return baseApi.get(`rivata/cargotags?get_limited_data=true`, !getAll)
  }

  searchCargoTags = (searchValue: string, limit = 20) => {
    const params = new URLSearchParams()

    params.append('search_value', searchValue)

    params.append('limit', limit.toString())

    return baseApi.get(`rivata/cargotags/search?${params.toString()}`)
  }
  getCargoTagsLimitedDataByMacs = (macs: string) => {
    return baseApi.get(`rivata/cargotags?get_limited_data=true&macs=${macs}`)
  }
}

const cargotagsApi = new CargotagsApi()

export default cargotagsApi
