import { AnnotationOptions } from "chartjs-plugin-annotation";
import { composeVertLineAnnotation } from "../../utils/chartUtils";

interface IcomposePressureChartAnnotations {
  (
    id: string,
    criticalOverPressure: number | null,
    overPressure: number | null,
    lowPressure: number | null,
    criticalLowPressure: number | null,
    timestamp: number | null,
    healthColors: Array<IHealthColor>,
    isOnlyThresholdLine: boolean
  ): IDictStrKeys<AnnotationOptions>;
}

const composePressureChartAnnotations: IcomposePressureChartAnnotations = (
  id,
  criticalOverPressure,
  overPressure,
  lowPressure,
  criticalLowPressure,
  timestamp,
  healthColors,
  isOnlyThresholdLine = false
) => {
  const annotations: IDictStrKeys<AnnotationOptions> = {};
  const xMin = -Infinity;
  const xMax = Infinity;

  if (isOnlyThresholdLine && timestamp) {
    annotations.verticalLine1 = composeVertLineAnnotation(
      id,
      timestamp,
      healthColors[1].color
    );
    return annotations;
  }

  if (
    overPressure &&
    lowPressure &&
    criticalOverPressure &&
    criticalLowPressure
  ) {
    annotations.box1 = {
      type: "box",
      id: `box-plot-top-${id}`,
      xScaleID: "x",
      yScaleID: "y",
      yMax: Infinity,
      yMin: overPressure,
      xMin,
      xMax,
      backgroundColor: "#fffcdf",
      borderWidth: 0,
      drawTime: "beforeDatasetsDraw",
    };

    annotations.box2 = {
      type: "box",
      id: `box-plot-mid-${id}`,
      xScaleID: "x",
      yScaleID: "y",
      yMax: overPressure,
      yMin: lowPressure,
      xMin,
      xMax,
      backgroundColor: "#fff",
      borderWidth: 0,
      drawTime: "beforeDatasetsDraw",
    };

    annotations.box3 = {
      type: "box",
      id: `box-plot-bottom-${id}`,
      xScaleID: "x",
      yScaleID: "y",
      yMax: lowPressure,
      yMin: -Infinity,
      xMin,
      xMax,
      backgroundColor: "#fffcdf",
      borderWidth: 0,
      drawTime: "beforeDatasetsDraw",
    };

    annotations.criticalOverPressureLine = {
      id: `critical-over-${id}`,
      type: "line",
      scaleID: "y",
      value: criticalOverPressure,
      endValue: criticalOverPressure,
      borderColor: "#FED925",
      borderWidth: 4,
      borderDash: [2, 2],
      borderDashOffset: 10,
      drawTime: "beforeDatasetsDraw",
    };

    annotations.overPressureLine = {
      id: `over-${id}`,
      type: "line",
      scaleID: "y",
      value: overPressure,
      endValue: overPressure,
      borderColor: "#FED925",
      borderWidth: 2,
      borderDash: [2, 2],
      borderDashOffset: 10,
      drawTime: "beforeDatasetsDraw",
    };

    annotations.lowPressureLine = {
      id: `low-${id}`,
      type: "line",
      scaleID: "y",
      value: lowPressure,
      endValue: lowPressure,
      borderColor: "#FED925",
      borderWidth: 2,
      borderDash: [2, 2],
      borderDashOffset: 10,
      drawTime: "beforeDatasetsDraw",
    };

    annotations.criticalLowPressureLine = {
      id: `critical-low-${id}`,
      type: "line",
      scaleID: "y",
      value: criticalLowPressure,
      endValue: criticalLowPressure,
      borderColor: "#FED925",
      borderWidth: 4,
      borderDash: [2, 2],
      borderDashOffset: 10,
      drawTime: "beforeDatasetsDraw",
    };
  }

  if (timestamp) {
    annotations.verticalLine2 = composeVertLineAnnotation(
      id,
      timestamp,
      healthColors[1].color
    );
  }

  return annotations;
};

export default composePressureChartAnnotations;
