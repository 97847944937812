import {
    setAxleLoadAssets, 
    setAxleLoadAssetsError, 
    setAxleLoadCalibrationData, 
    setAxleLoadCalibrationDataError, 
    setCalibrationDataLimit, 
    setCalibrationDataOffset, 
    setLoadingAxleLoadAssets, 
    setLoadingAxleLoadCalibrationData, 
    setSelectedCalibrationAsset, 
    setCalibrationDataTotalCount
} from "./action"

export enum LoadCalibrationTypes {
    LOADING = "LOADING_AXLE_LOAD_ASSETS",
    SET_ASSETS = "SET_AXLE_LOAD_ASSETS",
    SET_AXLE_LOAD_ERROR = "SET_AXLE_LOAD_ASSETS_ERROR",
    SET_SELECTED = "SET_SELECTED_CALIBRATION_ASSET",
    SET_LOAD_CALIBRATION_DATA = "LOADING_AXLE_LOAD_CALIBRATION_DATA",
    SET_AXLE_LOAD_CALIBRATION_DATA ="SET_AXLE_LOAD_CALIBRATION_DATA",
    SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT ="SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT",
    SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET = "SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET",
    SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT = "SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT",
    SET_AXLE_LOAD_CALIBRATION_DATA_ERROR = "SET_AXLE_LOAD_CALIBRATION_DATA_ERROR"
}

export interface Ierror {
    statusCode: number
    message: string
}

export interface IaxleLoadData {
    id:number
    is_loaded: boolean
    vehicle_weight: number
    timestamp: number
}

export interface LoadCalibrationReducer {
    axleLoadAssets: {
        isLoading: boolean
        data: Array<IaxleLoadData>
        error: Ierror
    }
    selectedAsset: IAsset | null
    calibrationData: {
        isLoading: boolean
        data: Array<IaxleLoadData>
        totalCount: number
        offset: number
        limit: number
        error: Ierror
    }
}
export interface Icalibration {
    epoch_from: number
    isLoaded: boolean
    vehicle_weight: number
}
export interface IpostData{
    asset_id: number
    calibrationData: Icalibration
}

///actions
export type SetLoadingAxleLoadAssets = ReturnType<typeof setLoadingAxleLoadAssets>
export type SetAxleLoadAssets = ReturnType<typeof setAxleLoadAssets>
export type SetAxleLoadAssetsError = ReturnType<typeof setAxleLoadAssetsError>
export type SetCalibrationDataOffset = ReturnType<typeof setCalibrationDataOffset>
export type SetCalibrationDataLimit = ReturnType<typeof setCalibrationDataLimit>
export type SetSelectedCalibrationAsset = ReturnType<typeof setSelectedCalibrationAsset>
export type SetLoadingAxleLoadCalibrationData = ReturnType<typeof setLoadingAxleLoadCalibrationData>
export type SetAxleLoadCalibrationData = ReturnType<typeof setAxleLoadCalibrationData>
export type SetTotalCount = ReturnType<typeof setCalibrationDataTotalCount>
export type SetAxleLoadCalibrationDataError = ReturnType<typeof setAxleLoadCalibrationDataError>

export type UsersActionsType = SetLoadingAxleLoadAssets |
SetAxleLoadAssets |
SetAxleLoadAssetsError |
SetCalibrationDataOffset |
SetCalibrationDataOffset |
SetCalibrationDataLimit |
SetSelectedCalibrationAsset |
SetLoadingAxleLoadCalibrationData |
SetAxleLoadCalibrationData |
SetTotalCount |
SetAxleLoadCalibrationDataError


///thunks
// export type FetchAxlesLoadType = ReturnType<typeof fetchAxleLoadAssets>
// export type FetchAxleLoadCalibrationData = ReturnType<typeof fetchAxleLoadCalibrationData>

// export type BaseThunkType<A extends Action = Action, R = Promise<void>> = ThunkAction<R, RootState, unknown, A>
// export type ThunkType = BaseThunkType<UsersActionsType>






