import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CancelWarningsButton = (props) => {
  const { disabled, btnClassName, onCancel, locale = {} } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button
        disabled={disabled}
        size="md"
        className={btnClassName}
        onClick={toggle}
      >
        Cancel Warnings
      </Button>
      <Modal isOpen={modal && !disabled}>
        <ModalHeader toggle={toggle}>
          Cancel these warnings?
        </ModalHeader>
        <ModalBody>
          This cannot be undone!
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onCancel();
              toggle();
            }}
          >
            {locale["ok"] || "Ok"}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            {locale["cancel"] || "Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CancelWarningsButton;
