import baseApi from './BaseApi'

class CustomerApi {
  postCustomer = (data: any) => {
    return baseApi.post(`rivata/company`, data)
  }

  getCustomers = () => baseApi.get(`rivata/company`, false)

  putCustomer = (data: any) => {
    return baseApi.put(`rivata/company?customer_id=${data.id}`, data)
  }

  deleteCustomer = (data: any) => {
    return baseApi.delete(`rivata/company?customer_id=${data}`)
  }
  getCustomersDataByKeys = (data: any) => {
    return baseApi.get(`rivata/company?customer_keys=${data}`, false)
  }
}

const customerApi = new CustomerApi()

export default customerApi
