import { AssetTypes, DetailsComponents } from "../../enums"
import { IComponentConfigItem } from "./types"


export const subscriptionFilter = (isSuperAdmin = false, components: Array<IComponentConfigItem>, assetInfoData: IAssetInfo) => {
    if (isSuperAdmin) return components
    if (!assetInfoData.subscription) return []
    
    const { HUB_SENSOR, TIRE_SENSOR, AXLE_LOAD, LINE_PRESSURE } = assetInfoData.subscription

    return components.filter(el => {
        switch (el.id) {
            case DetailsComponents.HubVibration:
            case DetailsComponents.TemperatureChart:
            case DetailsComponents.TemperatureHistory:
                return !!HUB_SENSOR
            case DetailsComponents.TirePressure:
                return !!TIRE_SENSOR
            case DetailsComponents.AxleLoad:
                return !!AXLE_LOAD
            case DetailsComponents.LinePressure:
                return assetInfoData.asset_type === AssetTypes.TRAILER ? !!LINE_PRESSURE : false
            case DetailsComponents.DetailsMap:
            case DetailsComponents.LocationChart:
            case DetailsComponents.VehicleInfo:
                return true
            case DetailsComponents.RecentWarnings:
                return !!HUB_SENSOR || !!TIRE_SENSOR || !!LINE_PRESSURE
            case DetailsComponents.SensorNode:
            case DetailsComponents.TpmsStatusDiagram:
            case DetailsComponents.ChartsControl:
                return !!HUB_SENSOR || !!TIRE_SENSOR || !!LINE_PRESSURE || !!AXLE_LOAD
            default:
                return false
        }
    })
}


export const addPowerInputsChart = (components: Array<IComponentConfigItem>) => {
    const chart = {
        id: DetailsComponents.PowerInputsChart, 
        data: {
            title: "Power Inputs",
            enabled: true,
            width: 12
        }
    }

    return [ ...components, chart ]
}