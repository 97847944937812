type ModuleItem = {
  id: string
  data: {
    title: string
    width: number
    enabled: boolean
  }
}

export const reorderAndResize = (
  items: Array<ModuleItem>,
  startIndex: number,
  endIndex: number,
) => {
  const rearranged = Array.from(items)
  const [removed] = rearranged.splice(startIndex, 1)
  rearranged.splice(endIndex, 0, removed)

  const rows = rearranged.reduce(
    (acc, item) => {
      const rowId = acc.length - 1
      if (acc[rowId].size + item.data.width <= 12) {
        acc[rowId].ids.push(item.id)
        acc[rowId].size += item.data.width
      } else {
        acc.push({ ids: [item.id], size: item.data.width })
      }
      return acc
    },
    [{ ids: [] as string[], size: 0 }],
  )

  const result = rearranged.map((item) => {
    const row = rows.find((row) => row.ids.includes(item.id))

    if (!!row && row.size < 12)
      return { ...item, data: { ...item.data, width: 12 / row.ids.length } }

    return item
  })

  return result
}

//changing size of module affects sizes of other modules in same row
//when 2 modules in row, other module changes size to occupy remaining space in row if there is any, else its takes new whole row
//when 3 modules in row,
//  if changed module is not full row then module next to it takes reamaining space and other goes on new row
//  if changed module takes full row then other modules take half row if they are next to each other, else they each takes new row
export const getItemsUpdated = (
  item: ModuleItem,
  rows: Array<Array<string>>,
  oldItems: Array<ModuleItem>,
) => {
  if (
    oldItems.find((ol) => ol.id === item.id)?.data.width === item.data.width
  ) {
    return oldItems.map((curr) => (curr.id === item.id ? item : curr))
  }

  const row = rows.find((row) => row.includes(item.id))

  if (row?.length === 2) {
    return oldItems.map((curr) => {
      if (curr.id === item.id) return item

      if (row.includes(curr.id)) {
        return {
          ...curr,
          data: { ...curr.data, width: 12 - (item.data.width % 12) },
        }
      }

      return curr
    })
  }

  if (row?.length === 3) {
    const groupItemId = row.findIndex((id) => id === item.id)

    return oldItems.map((curr) => {
      if (curr.id === item.id) return item

      const groupCurrId = row.findIndex((id) => id === curr.id)

      if (groupCurrId < 0) return curr

      if (item.data.width < 12) {
        if (
          (groupItemId === 0 && groupCurrId === 1) ||
          (groupItemId === 1 && groupCurrId === 0) ||
          (groupItemId === 2 && groupCurrId === 1)
        ) {
          return {
            ...curr,
            data: { ...curr.data, width: 12 - (item.data.width % 12) },
          }
        }

        return {
          ...curr,
          data: { ...curr.data, width: 12 },
        }
      }

      if (groupItemId === 1) {
        return {
          ...curr,
          data: { ...curr.data, width: 12 },
        }
      }
      return {
        ...curr,
        data: { ...curr.data, width: 6 },
      }
    })
  }

  return oldItems.map((curr) => (curr.id === item.id ? item : curr))
}
