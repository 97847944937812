import React, { useMemo, useEffect, useState, useRef, useContext } from 'react'

import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import RivataLineChart from '../../components/RivataLineChart'
import RivataDropdown from '../../components/RivataDropdown'
import StatusAlert from '../../components/StatusAlert'

import { AxlesNames } from '../../enums'

import { composeTireChartData, drawPressureLinesLegend } from './utils'
import composePressureChartAnnotations from './annotations'
import { getThresholdsByAssignedProfile, psiToBar } from '../../utils'
import {
  composeCustomTooltip,
  getAxlesDropdownItems,
} from '../../utils/chartUtils'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { AssetDetailsContext } from '../../pages/AssetDetails'
import {
  AxlesGroups,
  UnitsOfMeasurementConfig,
} from '../../constants/constants'
import { saveGoogleAnalyticsEvent } from '../../utils/utils'

const id = 'pressure'
const tooltip = composeCustomTooltip({
  displayLabelColors: true,
  tableFormat: true,
  footer: true,
  footerAlign: 'right',
})

const TirePressureChart = ({ width }) => {
  const {
    tirePressure: { isLoading, data, status },
    assignedTpmsProfile,
    thresholds,
  } = useTypedSelector((state) => ({
    tirePressure: state.assetDetails.tirePressure,
    assignedTpmsProfile: state.assetDetails.assignedTpmsProfile,
    thresholds: state.common.customerDefaults.tpms,
  }))

  const {
    locale,
    unitsOfMeasurementConfig,
    chartsXAxisLabel,
    healthColors,
    wheelColors,
    assetInfoData,
    timestamp,
  } = useContext(AssetDetailsContext)

  const [pressureThresholds, setPressureThresholds] = useState(null)
  const [selectedAxle, setSelectedAxle] = useState('all')

  const chartLinesLegend = useRef({ current: {} })

  useEffect(() => {
    drawPressureLinesLegend(chartLinesLegend)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartLinesLegend.current, isLoading, selectedAxle])

  const chartData = useMemo(() => {
    if (data && data.length && wheelColors) {
      return composeTireChartData(data, wheelColors, unitsOfMeasurementConfig)
    }
    return null
  }, [data, wheelColors, unitsOfMeasurementConfig])

  useEffect(() => {
    if (!thresholds || selectedAxle === 'all')
      return setPressureThresholds(null)

    const assetType = assetInfoData.asset_type

    let obj = {}

    if (
      assignedTpmsProfile &&
      assignedTpmsProfile.thresholds &&
      assetType &&
      assignedTpmsProfile.thresholds[assetType] &&
      assignedTpmsProfile.thresholds[assetType][AxlesNames[selectedAxle]]
    ) {
      obj = {
        ...assignedTpmsProfile.thresholds[assetType][AxlesNames[selectedAxle]],
      }
    } else {
      const percentThresholds = {
        critical_low_pressure_in_percent:
          thresholds.critical_low_pressure_in_percent,
        critical_over_pressure_in_percent:
          thresholds.critical_over_pressure_in_percent,
        low_pressure_in_percent: thresholds.low_pressure_in_percent,
        over_pressure_in_percent: thresholds.over_pressure_in_percent,
      }

      const pressureValue = thresholds.thresholdsPerAxle
        ? thresholds.thresholdsPerAxle.find((el) => el.axle === selectedAxle)
            ?.pressure_value
        : thresholds.cold_inflation_pressure_in_psi

      obj = getThresholdsByAssignedProfile(
        UnitsOfMeasurementConfig.pressure.psi,
        pressureValue,
        percentThresholds,
        thresholds.cold_inflation_pressure_in_psi,
      )
    }

    if (unitsOfMeasurementConfig === UnitsOfMeasurementConfig.pressure.bar) {
      Object.keys(obj).map((key) => {
        return (obj[key] = psiToBar(obj[key]))
      })
    }
    setPressureThresholds(obj)
  }, [
    thresholds,
    unitsOfMeasurementConfig,
    assignedTpmsProfile,
    selectedAxle,
    assetInfoData,
  ])

  const annotations = useMemo(() => {
    if (pressureThresholds) {
      return composePressureChartAnnotations(
        id,
        pressureThresholds?.critical_over_pressure,
        pressureThresholds?.over_pressure,
        pressureThresholds?.low_pressure,
        pressureThresholds?.critical_low_pressure,
        timestamp,
        healthColors,
      )
    }

    return composePressureChartAnnotations(
      id,
      null,
      null,
      null,
      null,
      timestamp,
      healthColors,
      true,
    )
  }, [pressureThresholds, healthColors, timestamp])

  const dropdownItems = useMemo(() => {
    return getAxlesDropdownItems(chartData?.datasets?.map((el) => el.label))
  }, [chartData])

  const yLabel = useMemo(() => {
    return `Tire Pressure (${
      unitsOfMeasurementConfig === UnitsOfMeasurementConfig.pressure.psi
        ? 'psi'
        : 'bar'
    })`
  }, [unitsOfMeasurementConfig])

  const filtredChartData = useMemo(() => {
    if (selectedAxle === 'all' || !chartData) return chartData

    return {
      datasets: chartData.datasets.filter((line) =>
        AxlesGroups[selectedAxle].includes(line.label),
      ),
    }
  }, [chartData, selectedAxle])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title='Tire Pressure'
      width={width}
      locale={locale}
      error={status}
      filters={
        <div>
          <label className='mr-2'>Select Axle:</label>
          <RivataDropdown
            caret={true}
            items={dropdownItems}
            selected={selectedAxle}
            onSelect={(value) => {
              saveGoogleAnalyticsEvent('charts_axle_filter', { axle: value })
              setSelectedAxle(value)
            }}
          />
        </div>
      }
      collapsible
    >
      {isLoading ? (
        <RivataLoader />
      ) : filtredChartData?.datasets?.length ? (
        <div className='position-relative' style={{ height: '420px' }}>
          <RivataLineChart
            id={id}
            legendPostion={'top'}
            data={filtredChartData}
            height={400}
            threshold={pressureThresholds?.critical_over_pressure}
            tooltip={tooltip}
            annotations={annotations}
            xAxisLabel={chartsXAxisLabel}
            yAxisLabel={yLabel}
          />
          <canvas
            ref={chartLinesLegend}
            className={`position-absolute ${
              selectedAxle === 'all' ? 'd-none' : ''
            }`}
            style={{ left: '5%' }}
          />
        </div>
      ) : (
        <StatusAlert customText='No Data' color='success' />
      )}
    </RivataModule>
  )
}

export default TirePressureChart
