import { useEffect, useState } from 'react'
import { NoDataLabels } from '../../enums'
import {
  convertDataEpochToDate,
  getClearLabel,
  getSensorFromSensorType,
} from '../../utils'
import { getWarningDescription } from '../../utils/utils'

export const useTableColumns = (selectAll: boolean, showCustomerColumn: boolean) => {
  const [columns, setColumns] = useState<Array<any>>([])

  useEffect(() => {
    const columns = [
      {
        label: 'All Visible',
        id: 'all',
        control: 'checkbox',
        checked: selectAll,
      },
      {
        label: 'Sensor Type',
        id: 'sensor_type',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Time',
        id: 'time',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Position',
        id: 'position',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Warning Type',
        id: 'warning_type',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Description',
        id: 'description',
        control: undefined,
        isDraggable: true,
      },
      {
        label: 'Asset Name',
        id: 'asset_name',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'VIN',
        id: 'vin',
        control: 'sort',
        isDraggable: true,
      },
    ]
    if (showCustomerColumn) {
      columns.splice(1, 0, {
        label: 'Customer Name',
        id: 'customer_name',
        control: 'sort',
        isDraggable: true,
      },)
    }
    setColumns(columns)
  }, [selectAll, showCustomerColumn])

  return columns
}

export const useTableRows = (
  data: any[],
  locale: any,
  unitsOfMeasurementConfig: any,
  selectAll: boolean,
  selectedRows: any[], showCustomerColumn: boolean
) => {
  const [rows, setRows] = useState<Array<any>>([])

  useEffect(() => {
    const d = data.map((row: any) => {
      const link = `/details/${row.vin}/warnings/${row.epoch * 1000}/90/${row.key}/email`
      const result = {
        columns: [{
          columnId: 'all',
          control: 'checkbox',
          checked: selectAll
            ? true
            : selectedRows.indexOf(row.key) > -1
              ? true
              : false,
          id: row.id,
        },
        {
          id: row.id,
          type: 'link',
          link,
          label: getSensorFromSensorType(row.sensor_type),
          columnId: 'sensor_type',
        },
        {
          id: row.id,
          type: 'link',
          link,
          label: convertDataEpochToDate(row.epoch, null, null, true),
          columnId: 'time',
        },
        {
          id: row.id,
          type: 'link',
          link,
          label: getClearLabel(row.position),
          columnId: 'position',
        },
        {
          id: row.id,
          type: 'link',
          link,
          label: getClearLabel(row.warning_type),
          columnId: 'warning_type',
        },

        {
          id: row.id,
          type: 'link',
          link,
          label: getWarningDescription(
            row.warning_type,
            row.warning_subtype,
            row.duration_in_seconds,
            row.warning_value,
            row.is_recovered_to_normal,
            locale,
            unitsOfMeasurementConfig,
            row.recovered_to_normal_at_datetime,
            row.max_warning_value,
            row.canceled,
            row.canceled_at_datetime,
            row.cancel_reason,
            row.threshold,
          ),
          columnId: 'description',
        },
        {
          id: row.id,
          type: 'link',
          link,
          label: row.asset_name,
          columnId: 'asset_name',
        },
        {
          id: row.id,
          type: 'link',
          link,
          label: row.vin || NoDataLabels.DASH,
          columnId: 'vin',
        },
        ],
        id: row.id,
      }
      if (showCustomerColumn) {
        result.columns.splice(1, 0, {
          id: row.id,
          type: 'link',
          link,
          label: row.customer_name,
          columnId: 'customer_name',
        })
      }
      return result
    })
    setRows(d)
  }, [data, locale, unitsOfMeasurementConfig, selectAll, selectedRows, showCustomerColumn])

  return rows
}
