import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
import { DateLabels, DatesEnum } from '../../../enums'
const DaysRangeRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All time')
  useEffect(() => {
    if (!value) {
      setDisplayValue('All time')
    } else {
      const index = Object.values(DatesEnum).indexOf(parseInt(value))
      const key: string = Object.keys(DatesEnum)[index]
      setDisplayValue((DateLabels as any)[key])
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Date Range:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default DaysRangeRow
