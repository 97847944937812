import React, { useState } from "react";
import * as Yup from "yup";
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Formik } from "formik";
import RivataDropdown from "../../components/RivataDropdown";
import RivataFormField from "../../components/RivataFormField";
import { DateInput } from "../../components/DateRangeCalendar";
import {
  kgToLbs,
  lbsToKg,
  dateToEpoch,
  convertDataEpochToDate,
} from "../../utils";
import { UnitsOfMeasurement } from "../../enums";
import PickTime from "../../components/TimePicker";
import { IItem } from "./calibrationDeleteModal";


const schema = Yup.object({
  vehicle_weight: Yup.number()
    .required("This field is required")
    .positive("Value must be positive"),
});


interface IBody {
  asset_id: number;
  calibration_data: {
    id: number;
    is_loaded: boolean;
    vehicle_weight: string | number;
    epoch_from: number;
  };
}

interface IEditModal {
  disabled: boolean;
  onEdit: (body: IBody) => IStatus;
  onCancel: () => void;
  locale: ILocale | undefined;
  editItem: IItem;
  setEditItem: (data: IItem | null) => void;
  selectedUnitsOfMeasurement: string;
  setStatusMessage: (msg: string | null) => void;
  selectedAsset: IAsset;
}

const CalibrationEditModal: React.FC<IEditModal> = ({
  disabled,
  onEdit,
  onCancel,
  locale = {},
  editItem,
  setEditItem,
  selectedUnitsOfMeasurement,
  setStatusMessage,
  selectedAsset,
}) => {
  const dateTimeParts = convertDataEpochToDate(
    editItem.timestamp,
    null,
    null,
    false
  ).format("MM/DD/YYYY HH:mm").split(" ")

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [day, setDay] = useState<Date>(new Date(dateTimeParts[0]));
  const [time, setTime] = useState<string>(dateTimeParts[1]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        vehicle_weight:
          selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial
            ? kgToLbs(editItem.vehicle_weight).toFixed(2)
            : editItem.vehicle_weight.toFixed(2),
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        const body = {
          asset_id: selectedAsset.id,
          calibration_data: {
            id: editItem.id,
            is_loaded: editItem.is_loaded,
            vehicle_weight:
              selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial
                ? lbsToKg(values.vehicle_weight)
                : values.vehicle_weight,
            epoch_from: editItem.timestamp,
          },
        };

        const res = await onEdit(body);
        if (res.statusCode >= 400) {
          setErrorMessage(res.message);
        } else {
          setEditItem(null);
          setStatusMessage(res.message);
        }
      }}
    >
      {({ submitForm }) => {
        return (
          <Modal
            isOpen={!!editItem && !disabled}
            size="md"
            style={{ maxWidth: "500px", width: "100%", position: "relative" }}
          >
            <ModalHeader toggle={onCancel}>
              Update This Calibration Data?
            </ModalHeader>
            <ModalBody>
              <div>
                <Col md="6" className="pl-0">
                  <RivataDropdown
                    caret={true}
                    items={[
                      { id: "empty", label: "Empty" },
                      { id: "loaded", label: "Loaded" },
                    ]}
                    selected={editItem.is_loaded ? "loaded" : "empty"}
                    onSelect={(id: string) => {
                      let item = { ...editItem };
                      item.is_loaded = id === "loaded";
                      setEditItem(item);
                    }}
                    buttonLabel={undefined}
                    filters={undefined}
                    onRemove={undefined}
                    color={undefined}
                    disabled={undefined}
                  />
                </Col>
                <RivataFormField
                  className="mt-3 mb-3 pl-0 mr-1"
                  md={6}
                  name={"vehicle_weight"}
                  label={`Vehicle Weight (${
                    selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial
                      ? "lbs"
                      : "kg"
                  })`}
                />
                <div style={{ position: "absolute", bottom: "65px" }}>
                  Select Weight Timestamp
                </div>
                <div
                  className="d-flex"
                  style={{ position: "relative", width: "47%" }}
                >
                  <DateInput
                    onSelect={(dateStart: Date) => {
                      setDay(dateStart);
                    }}
                    to={day}
                    title={"Select Vehicle Weight Date Period"}
                    customClassNames={"calibration-edit-modal__date-range"}
                    disabled={disabled}
                    md={9}
                    isSingleDate={true}
                    singleDateTitle={""}
                    singleDateMd={9}
                    from={undefined}
                    children={undefined}
                  />
                  <div className="pick-time-calibration">
                    <PickTime
                      t={time}
                      onChange={setTime}
                      isdisabled={!selectedAsset?.id}
                      type="forModal"
                      setIsPicker={undefined}
                    />
                  </div>
                </div>

                <Col md={12} className="pl-0 mt-2">
                  <div className="error">
                    {errorMessage ? errorMessage : ""}
                  </div>
                </Col>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  let editedItem = { ...editItem };
                  //get epoch based on user selected timezone
                  const deltaArr = time.split(" ");
                  const deltaTime = deltaArr[0];
                  const dates = dateToEpoch(day, new Date(), deltaTime);

                  editedItem.timestamp = dates.startDate;
                  setEditItem(editedItem);

                  submitForm();
                }}
              >
                {locale["save"] || "Save"}
              </Button>
              <Button color="secondary" onClick={onCancel}>
                {locale["cancel"] || "Cancel"}
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CalibrationEditModal;
