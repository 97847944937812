import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { warningStatusItems } from '../../../componentsV2/WarningStatusFilter/items'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
const WarningStatusRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    if (!value || (value && value.toLowerCase() === 'all')) {
      setDisplayValue('All')
    } else {
      const valueArray = value.split(',')
      const selectedItems = warningStatusItems
        .filter((status: any) => {
          return valueArray.includes(status.id)
        })
        .map((s) => {
          return s.label
        })
      setDisplayValue(selectedItems.join(', '))
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Warning Status:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default WarningStatusRow
