import React, { useState, useEffect } from 'react'
import { Col, Label, CustomInput, Input, FormFeedback } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import TimezonePicker from '../../components/TimezonePicker'
import UnitsOfMeasureToggle from '../../components/UnitsOfMeasureToggle'
import PhoneInput from 'react-phone-number-input/input'
import { validatePhone, validateEmail } from '../../utils'

const CustomerDefaults = ({
  title,
  locale,
  width,
  preferences,
  onUpdate,
  setValidity,
  hideSuperAdminOnlyElements,
}) => {
  const {
    locale: { timezone },
    unitsOfMeasurement,
  } = preferences

  const [zone, setZone] = useState(timezone)
  const [unitsOfMeasure, setUnitsOfMeasure] = useState(unitsOfMeasurement)

  const [phoneValidationData, setPhoneValidationData] = useState({
    isValid: true,
    errorMessage: '',
  })
  const [emailValidationData, setEmailValidationData] = useState({
    isValid: true,
    errorMessage: '',
  })

  useEffect(() => {
    setZone(timezone)
  }, [timezone])

  useEffect(() => {
    setUnitsOfMeasure(unitsOfMeasurement)
  }, [unitsOfMeasurement])
  useEffect(() => {
    setValidity(emailValidationData.isValid, phoneValidationData.isValid)
  }, [emailValidationData, phoneValidationData, setValidity])
  return (
    <RivataModule title={title} locale={locale} width={width}>
      <>
        <Col md='12' className='p-0'>
          <h3>{locale['Locale']}</h3>
          <hr />
          <Col md='3' className='p-0'>
            <Label>Timezone</Label>
            <TimezonePicker
              onSetTimezone={(z) => {
                if (z !== zone) {
                  setZone(z)

                  if (z) {
                    onUpdate({
                      ...preferences,
                      locale: { ...preferences.locale, timezone: z },
                    })
                  }
                }
              }}
              initialValue={zone}
              locale={locale}
              id='customer_default'
            />
          </Col>
        </Col>
        <Col className='mt-4 mb-3 p-0'>
          <h3>Units of Measure</h3>
          <hr />
          <Label>Select Units of Measure</Label>
          <UnitsOfMeasureToggle
            onChangeUnitsOfMeasure={(u) => {
              if (u !== unitsOfMeasure) {
                setUnitsOfMeasure(u)
                onUpdate({ ...preferences, unitsOfMeasurement: u })
              }
            }}
            unitsOfMeasure={unitsOfMeasure}
          />
        </Col>
        {!hideSuperAdminOnlyElements && (
          <>
            {' '}
            <Col className='mt-4 mb-3 p-0'>
              <h3>Subscriptions</h3>
              <hr />
              <Label>Customer Subscriptions</Label>
              <CustomInput
                type='switch'
                id='cargoTagSubscription'
                label='Cargo Tags'
                checked={preferences.subscriptions?.cargoTags}
                onChange={(e) =>
                  onUpdate({
                    ...preferences,
                    subscriptions: {
                      ...preferences.subscriptions,
                      cargoTags: e.target.checked,
                    },
                  })
                }
              />
            </Col>
            <Col className='mt-4 mb-3 p-0'>
              <h3>Automation</h3>
              <hr />
              <Label>Whitelisting</Label>
              <CustomInput
                type='switch'
                id='whitelistingAutomation'
                label='Whitelisting automation'
                checked={preferences.automation?.whitelisting}
                onChange={(e) =>
                  onUpdate({
                    ...preferences,
                    automation: {
                      ...preferences.automation,
                      whitelisting: e.target.checked,
                    },
                  })
                }
              />
            </Col>
          </>
        )}
        <Col className='mt-4 mb-3 p-0'>
          <h3>App</h3>
          <hr />{' '}
          <Col md='3' className='p-0'>
            <Label>Email Support</Label>
            <Input
              type='text'
              id='appEmailSupport'
              label='Email Support'
              value={preferences.app?.supportEmail}
              onChange={(e) =>
                onUpdate({
                  ...preferences,
                  app: {
                    ...preferences.app,
                    supportEmail: e.target.value,
                  },
                })
              }
              onBlur={() => {
                setEmailValidationData(
                  validateEmail(preferences.app?.supportEmail),
                )
              }}
              className={`form-control ${
                !emailValidationData.isValid ? 'is-invalid' : ''
              }`}
            />{' '}
            <FormFeedback tooltip>
              {emailValidationData.errorMessage}
            </FormFeedback>
          </Col>
          <Col md='3' className='p-0 pt-1'>
            <Label>Customer Support</Label>
            <PhoneInput
              type='text'
              name='phone'
              placeholder='Enter Phone Here'
              defaultCountry='US'
              value={preferences.app?.supportPhone}
              onChange={(value) => {
                onUpdate({
                  ...preferences,
                  app: {
                    ...preferences.app,
                    supportPhone: value,
                  },
                })
                setPhoneValidationData({
                  isValid: true,
                  errorMessage: '',
                })
              }}
              onBlur={() => {
                setPhoneValidationData(
                  validatePhone(preferences.app?.supportPhone),
                )
              }}
              className={`form-control ${
                !phoneValidationData.isValid ? 'is-invalid' : ''
              }`}
            />
            <FormFeedback tooltip>
              {phoneValidationData.errorMessage}
            </FormFeedback>
          </Col>
          {!hideSuperAdminOnlyElements && (
            <Col className='mt-4 mb-3 p-0'>
              <h3>Permissions</h3>
              <hr />
              <Label>Whitelabel permissions</Label>
              <CustomInput
                type='switch'
                id='whiteLabelEditing'
                label='Allow updates for Fleet Admins'
                checked={preferences.permissions?.canFleetAdminEditWlSettings}
                onChange={(e) =>
                  onUpdate({
                    ...preferences,
                    permissions: {
                      ...preferences.permissions,
                      canFleetAdminEditWlSettings: e.target.checked,
                    },
                  })
                }
              />
            </Col>
          )}
        </Col>
      </>
    </RivataModule>
  )
}

export default CustomerDefaults
