import React, { useState, useEffect } from "react"
import { Row, Input, CustomInput } from "reactstrap"

import { CronModes } from "../../../../../enums"

import "../style.scss"
import TimeInput, { IAllowedRange } from "../../../../../components/TimeInput"
import moment from "moment"

interface Props {
    settings: ICronSettings,
    updateDateTime: (hours: number, minutes: number, day: number) => void,
    changeMode: (mode: number) => void,
    allowedTimeRange: IAllowedRange
}

const Daily: React.FC<Props> = ({
    settings,
    updateDateTime,
    changeMode,
    allowedTimeRange
}) => {
    const [time, setTime] = useState("");

    useEffect(() => {
        if (time) {
            updateDateTime(Number(time.slice(0, 2)), Number(time.slice(3, 5)), settings.datetime.day);
        }
    }, [time, settings.datetime.day, updateDateTime])

    return (
        <>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 mt-3 option-section">
                <CustomInput
                    type="radio"
                    id="everyNDays"
                    label="Every"
                    checked={settings.tabs.daily.everyNDays}
                    onChange={() => changeMode(CronModes.everyNDays)}
                />
                <Input 
                    type="number"
                    disabled={!settings.tabs.daily.everyNDays}
                    value={settings.tabs.daily.everyNDays ? settings.datetime.day : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateDateTime(settings.datetime.hours, settings.datetime.minutes, Number(e.target.value));
                    }}
                    className="ml-2 mr-2"
                    style={{ maxWidth: "75px" }}
                />
                <span>day(s)</span>
            </Row>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 m-0 option-section">
                <CustomInput
                    type="radio"
                    id="everyWeekdays"
                    label="Every week day"
                    checked={settings.tabs.daily.everyWeekdays}
                    onChange={() => changeMode(CronModes.everyWeekdays)}
                />
            </Row>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 option-section">
                <div className="mt-1">
                    <label className="pr-2 mb-0 pt-2">
                        Start Time:
                    </label>
                    <TimeInput
                        value={moment(`${settings.datetime.hours}:${settings.datetime.minutes}`, "HH:mm").format("HH:mm")}
                        setValue={setTime}
                        dropdownItemsStep={10}
                        restrictToStep
                        allowedRange={allowedTimeRange}
                    />
                </div>
            </Row>
        </>
    )
}

export default React.memo(Daily)
