import React, { useCallback, useRef, useState } from 'react'
import RivataModule from '../../../components/RivataModule'
import RivataTable from '../../../components/RivataTable'
import GeofencesFilterMenu from '../../../modules/Geofences/filter/GeofencesFilterMenu'

import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useColumns, useRows } from './hooks'
import { makeScrollToElementWithOffset } from '../../../utils/utils'

interface Props {
  locale: ILocale
  selectedCustomersList: Array<ICustomer>
  geofences: Array<IGeofence>
  geofencesStatus: IStatus
  isLoading: boolean
}
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'geofences.table.columns',
}
const GeofencesGrid: React.FC<Props> = ({
  locale,
  selectedCustomersList,
  geofences,
  geofencesStatus,
  isLoading,
}) => {
  const { limit, offset, totalCount, sortOptions } = useTypedSelector(
    (state) => ({
      limit: state.geofences.geofences.limit,
      offset: state.geofences.geofences.offset,
      totalCount: state.geofences.geofences.totalCount,
      sortOptions: state.geofences.geofences.sortOptions,
    }),
  )
  const {
    setGeofencesLimit,
    setGeofencesOffset,
    setGeofencesSortOptions,
    fetchGeofencePageAssetsLocations,
    fetchGeofences,
  } = useActions()

  const [filters, setFilters] = useState('')

  const scrollTarget = useRef(null)

  const columns = useColumns(selectedCustomersList)
  const rows = useRows(columns, geofences, limit, offset, sortOptions, filters)

  const handlePageChange = useCallback(
    (offset: number) => {
      setGeofencesOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -250)
    },
    [setGeofencesOffset],
  )

  const handleLimitChange = useCallback(
    (limit: number) => {
      setGeofencesLimit(limit)
      setGeofencesOffset(0)
      makeScrollToElementWithOffset(scrollTarget, -250)
    },
    [setGeofencesLimit, setGeofencesOffset],
  )

  const onFilter = useCallback(
    (filter: any) => {
      fetchGeofencePageAssetsLocations(filter)
      fetchGeofences(filter)
      setFilters(filter)
    },
    [fetchGeofencePageAssetsLocations, fetchGeofences],
  )
  return (
    <RivataModule
      title='Assets in Geofence'
      customClass={'activity-module'}
      locale={locale}
      error={geofencesStatus}
      filters={undefined}
    >
      <GeofencesFilterMenu onFilter={onFilter} />

      <hr />

      <div
        ref={scrollTarget}
        className={`position-relative ${
          rows.length <= 3 && 'extra-height-content'
        }`}
      >
        <RivataTable
          columns={columns}
          rows={rows}
          isLoading={isLoading}
          showPagination={true}
          isShowingLimit={true}
          pageLimit={limit}
          page={offset / limit}
          // @ts-ignore component expect undefined cause of default value
          onSelectLimit={handleLimitChange}
          totalCount={totalCount}
          // @ts-ignore component expect undefined cause of default value
          onPageChange={handlePageChange}
          onEdit={undefined}
          onDelete={undefined}
          editDisabled={undefined}
          deleteDisabled={undefined}
          setSortOptions={setGeofencesSortOptions}
          onCustomAction={undefined}
          // @ts-ignore component expect undefined cause of default value
          columnsSelectorProps={columnsSelectorProps}
        />
      </div>
    </RivataModule>
  )
}

export default GeofencesGrid
