import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomInput } from 'reactstrap'
import { debounce, cloneDeep } from 'lodash'
import Button from '../../componentsV2/Button'
import SearchInput from '../../componentsV2/SearchInput'
import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import { SearchIcon } from '../../componentsV2/Icon'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import { getGpsDropdownItems } from '../AssetTable/utils'
import { sensorStatusItems } from './TpmsStatusFilterConfigs'
import RivataModule from '../../components/RivataModule'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import AssetGroupsFilter from '../../componentsV2/AssetGroupsFilter'
import ColumnsSelectorDropdown from '../../components/RivataTable/ColumnsSelectorDropdown'
import { columnsSelectorCheckboxProps } from '../../components/RivataTable/useColumnsSelector'
import SensorTypeFilter from '../../componentsV2/SensorTypeFilter'
import { sensorTypeItems } from '../../componentsV2/SensorTypeFilter/items'
import HealthStatusFilter from '../../componentsV2/HealthStatusFilter'
import AssetNameVinFilter from '../../componentsV2/AssetNameVinFilter'
import GeofenceFilter from '../../componentsV2/GeofenceFilter'
import GeofenceGroupsFilter from '../../componentsV2/GeofenceGroupsFilter'
import ControlledHealthStatusFilter from '../../componentsV2/HealthStatusFilter/ControlledHealthStatusFilter'
import { selectIsFilterQueryEmpty } from '../../redux/dashboard/selectors'

interface Props {
  columnsCheckboxesProps: columnsSelectorCheckboxProps[]
  onSetDefaultColumns: () => void
}

const debouncer = debounce((func: () => void) => func(), 400)

const wrapperStyle = { width: '470px' }
const initialSelection = {
  warnings_subtype: [],
  last_tpms_update: [],
  asset_group: [],
  sensor_type: [],
}

const defaultTpmsTabFilter = 'filters=sensor_type=tpms'

const TpmsStatusFilterMenu: React.FC<Props> = ({
  columnsCheckboxesProps,
  onSetDefaultColumns,
}) => {
  const {
    isSuperAdmin,
    timezone,
    locale,
    hideAutogeneratedAssets,
    selectedCustomersList,
    healthStatusItems,
    isFilterQueryEmpty,
    filterParams,
  } = useTypedSelector((state) => ({
    isSuperAdmin: state.auth.isSuperAdmin,
    timezone: state.auth.preferences.timezone,
    locale: state.whitelabel.locale,
    hideAutogeneratedAssets: state.common.hideAutogeneratedAssets,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    healthStatusItems: state.dash.filters.healthStatus as MenuItemType[],
    isFilterQueryEmpty: selectIsFilterQueryEmpty(state),
    filterParams: state.dash.filterParams,
  }))

  const {
    setTpmsStatusTractorAssetsOffset,
    setTpmsStatusTrailerAssetsOffset,
    setHideAutogeneratedAssets,
    fetchLocations,
    fetchAssetsCardsData,
    setFiltersField,
    setFilterParams,
    setFilterParamsField,
    deleteFilterParamsField,
    setFilters,
  } = useActions()

  const modifiedSensorTypeItems = useMemo(() => {
    const items = cloneDeep(sensorTypeItems)
    for (let i = 0; i < items.length; i++) {
      items[i].disabled = true
      if (items[i].id === 'tpms') {
        items[i].checked = true
      }
    }
    return items
  }, [])

  const groupsFilterRef = useRef<any>(null)
  const geofenceGroupsFilterRef = useRef<any>(null)
  const assetNameVinFilterRef = useRef<any>(null)
  const [profileSearchValue, setProfileSearchValue] = useState('')
  const [clearLabelSelector, setClearLabelSelector] = useState(0)
  const [dropdownItems, setDropdownItems] = useState({
    sensorStatusItems: cloneDeep(sensorStatusItems),
    gpsDropdownItems: getGpsDropdownItems(timezone),
  })

  useEffect(() => {
    setDropdownItems((prev) => ({
      ...prev,
      gpsDropdownItems: getGpsDropdownItems(timezone),
    }))
  }, [timezone])

  const onFilter = useCallback(() => {
    setTpmsStatusTractorAssetsOffset(0)
    setTpmsStatusTrailerAssetsOffset(0)
    fetchLocations()
    fetchAssetsCardsData()
  }, [
    setTpmsStatusTractorAssetsOffset,
    setTpmsStatusTrailerAssetsOffset,
    fetchLocations,
    fetchAssetsCardsData,
  ])

  const resetFiltersState = useCallback(() => {
    setFilters({})
    setFiltersField('healthStatus', sensorStatusItems)
    setFilterParams({})
    setFilterParamsField('sensor_type', ['tpms'])
  }, [setFilters, setFiltersField, setFilterParams, setFilterParamsField])

  const resetFilterComponentsInternalState = useCallback(() => {
    //some elements must be reseted even if filters weren't selected
    setClearLabelSelector((prev) => prev + 1)
    setDropdownItems({
      sensorStatusItems: cloneDeep(sensorStatusItems),
      gpsDropdownItems: getGpsDropdownItems(timezone),
    })

    setProfileSearchValue('')
    groupsFilterRef?.current?.clearSelection()
    geofenceGroupsFilterRef?.current.clearSelection()
    assetNameVinFilterRef?.current?.clearValue()
  }, [timezone])

  useEffect(() => {
    resetFiltersState()
    onFilter()

    return () => {
      setFilters({})
      setFiltersField('healthStatus', [])
      setFilterParams({})
    }
  }, [setFilterParams, setFilters, resetFiltersState, onFilter])

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      if (!value || !value.length) {
        deleteFilterParamsField(key)
      } else if (Array.isArray(value)) {
        setFilterParamsField(key, value)
      } else {
        setFilterParamsField(key, [value])
      }

      onFilter()
    },
    [onFilter, deleteFilterParamsField, setFilterParamsField],
  )

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value))
  }

  const onSearchByProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setProfileSearchValue(value)
    debouncer(() => updateFilters('warning_setting_name', value))
  }

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  const resetFilters = useCallback(() => {
    resetFilterComponentsInternalState()

    const isFilterParamsDefault =
      Object.keys(filterParams).length === 1 &&
      filterParams.hasOwnProperty('sensor_type') &&
      Array.isArray(filterParams.sensor_type) &&
      filterParams.sensor_type.length === 1 &&
      filterParams.sensor_type[0] === 'tpms'

    if (isFilterParamsDefault) return

    resetFiltersState()
    onFilter()
  }, [
    onFilter,
    resetFiltersState,
    filterParams,
    resetFilterComponentsInternalState,
  ])

  useEffect(() => {
    resetFilters()
  }, [selectedCustomersList])

  function getHealthStatusFilterLabel(items: MenuItemType[]) {
    const selectedItems = items.filter((item) => item.checked)

    return (
      'Health Status' +
      (selectedItems && selectedItems?.length > 0
        ? `: ${selectedItems?.length} selected`
        : '')
    )
  }

  return (
    <RivataModule
      title='TPMS Status'
      locale={locale}
      marginTop={0}
      filters={
        <>
          {isSuperAdmin && (
            <CustomInput
              id='hideAutogenerated'
              className='d-flex align-items-center mr-3'
              type='checkbox'
              label='Hide Autogenerated Assets'
              checked={hideAutogeneratedAssets}
              onChange={() => {
                setHideAutogeneratedAssets(!hideAutogeneratedAssets)
                setTpmsStatusTractorAssetsOffset(0)
                setTpmsStatusTrailerAssetsOffset(0)
                fetchLocations()
                fetchAssetsCardsData()
              }}
            />
          )}
        </>
      }
    >
      <div className='d-flex justify-content-between flex-wrap'>
        <AssetNameVinFilter
          wrapperStyle={wrapperStyle}
          onChange={onSearchByStringValue}
          ref={assetNameVinFilterRef}
        />

        <SearchInput
          placeholder='Search by TPMS Profile'
          icon={<SearchIcon width={20} height={20} color='black' />}
          wrapperStyle={wrapperStyle}
          value={profileSearchValue}
          onChange={onSearchByProfile}
        />

        <GeofenceFilter
          wrapperStyle={wrapperStyle}
          clearSelection={clearLabelSelector}
          updateFilters={updateFilters}
          searchParams={{ current: filterParams }}
        />
      </div>

      <br />

      <div className='d-flex flex-wrap justify-content-between'>
        <div className='d-flex flex-wrap'>
          <ControlledHealthStatusFilter
            items={healthStatusItems}
            buttonLabel={getHealthStatusFilterLabel(healthStatusItems)}
            containerClassName='mr-2'
            onItemsChange={(items) => {
              setFiltersField('healthStatus', items)
              onDropdownItemSelect(
                'warnings_subtype',
                items.filter((item) => item.checked),
              )
            }}
          />

          <SensorTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            overrideDefaultItems={modifiedSensorTypeItems}
            readonly={true}
          />
          <DropdownItemSelector
            id='last_tpms_update'
            filterClassName='mr-2'
            filterBtnLabel='Last TPMS Update'
            items={dropdownItems.gpsDropdownItems}
            onItemsChange={onDropdownItemSelect}
          />
          <AssetGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={groupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(',')}
          />
          <GeofenceGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={geofenceGroupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(',')}
          />
        </div>

        <div>
          <Button onClick={resetFilters}>Clear</Button>
        </div>
      </div>

      <hr />

      <div className='d-flex justify-content-end'>
        <ColumnsSelectorDropdown
          checkboxesProps={columnsCheckboxesProps}
          onSetDefault={onSetDefaultColumns}
          className=''
        />
      </div>
    </RivataModule>
  )
}

export default React.memo(TpmsStatusFilterMenu)
