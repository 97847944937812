import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import InitialLoader from '../components/InitialLoader'

import { initSession } from '../redux/auth/action'
import withSubscriptionCheck from '../hoc/withSubscriptionCheck'
import withWhiteLabelEditPermissionsCheck from '../hoc/withWhiteLabelEditPermissionsCheck'
class AdminRoute extends Component {
  componentDidMount() {
    if (!this.props.auth.isLoggedIn) {
      this.props.initSession()
    }
  }

  makeRedirect = () => (
    <Redirect
      to={{
        pathname: '/',
        state: { from: this.props.location },
      }}
    />
  )

  render() {
    const {
      auth: {
        loginChecked,
        isLoggedIn,
        isAttributesLoaded,
        isAdmin,
        isSuperAdmin,
      },
      componentName,
      checkForSuperAdmin,
      Component,
      whitelabel: { hasCheckedForConfig },
      common: { customerDefaults, isDefaultsLoading },
      disabled,
    } = this.props

    const keys = Object.keys(customerDefaults)

    return !loginChecked ||
      (isLoggedIn && !hasCheckedForConfig) ||
      (!keys.length && isDefaultsLoading) ? (
      <InitialLoader />
    ) : (
      <Route
        {...this.props}
        render={(props) => {
          if ((isAttributesLoaded && (!isAdmin || disabled)) || !isLoggedIn) {
            // not logged in so redirect to login page with the return url
            return this.makeRedirect()
          }

          if (checkForSuperAdmin) {
            if (isAttributesLoaded && !isSuperAdmin) {
              return this.makeRedirect()
            }
          }

          return <Component {...props} componentName={componentName} />
        }}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  whitelabel: state.whitelabel,
  common: state.common,
})

const mapDispatchToProps = (dispatch) => {
  return {
    initSession: () => dispatch(initSession()),
  }
}

export default withWhiteLabelEditPermissionsCheck(
  withSubscriptionCheck(
    connect(mapStateToProps, mapDispatchToProps)(AdminRoute),
  ),
)
