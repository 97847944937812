import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import GeofencesRow from '../ReportParametersRows/GeofencesRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import GeofenceGroupsRow from '../ReportParametersRows/GeofenceGroupsRow'

const GeofenceHistoryReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <AssetTypeRow value={props.parameters.asset_type} />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
      <GeofencesRow value={props.parameters.selected_geofences} />
      <GeofenceGroupsRow value={props.parameters.geofence_group} />
    </>
  )
}
export default GeofenceHistoryReportParameters
