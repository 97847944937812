import React from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import AssetsInGeofenceReportForm from './AssetsInGeofenceReportForm'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'
import moment from 'moment'
import { CalendarTypes } from '../../../enums'
import DateTimePicker from '../../../componentsV2/DateTimePicker'

type IGeofenceHistoryReportForm = {
  disabled: boolean
  dateFrom: Date
  dateTo: Date
  assetTypes: Array<{ id: string; label: string }>
  selectedAssetType: 'tractor' | 'trailer' | 'all'
  onDateRangeSelect: (from: Date, to: Date) => void
  setSelectedAssetType: React.Dispatch<React.SetStateAction<string>>
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>
  setSelectedGeofences: React.Dispatch<React.SetStateAction<Array<Object>>>
  selectedAssetsItems: Array<SearchItemType>
  selectedGeofenceItems: Array<SearchItemType>
  showAssetGroupsFilter?: boolean | false
  onAssetGroupFilterItemSelected?: (
    id: string,
    selectedValue: MenuItemType[],
  ) => void
  customerIds?: string | ''
  onGeofenceGroupFilterItemSelected?: (
    id: string,
    selectedValue: MenuItemType[],
  ) => void
}

const GeofenceHistoryReportForm: React.FC<IGeofenceHistoryReportForm> = ({
  disabled,
  dateFrom,
  dateTo,
  assetTypes,
  selectedAssetType,
  onDateRangeSelect,
  setSelectedAssetType,
  setVinList,
  setSelectedGeofences,
  selectedAssetsItems,
  selectedGeofenceItems,
  onAssetGroupFilterItemSelected,
  customerIds,
  showAssetGroupsFilter,
  onGeofenceGroupFilterItemSelected,
}) => {
  return (
    <>
      <div className='mb-2'>Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom.toISOString()}
        dateTo={dateTo.toISOString()}
        onDateRangeSelect={onDateRangeSelect}
        maxDate={moment().toDate()}
        disabled={disabled}
      />

      <AssetsInGeofenceReportForm
        disabled={disabled}
        assetTypes={assetTypes}
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        setVinList={setVinList}
        setSelectedGeofences={setSelectedGeofences}
        selectedAssetsItems={selectedAssetsItems}
        selectedGeofenceItems={selectedGeofenceItems}
        onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
        customerIds={customerIds}
        showAssetGroupsFilter={showAssetGroupsFilter}
        onGeofenceGroupFilterItemSelected={onGeofenceGroupFilterItemSelected}
      />
    </>
  )
}

export default GeofenceHistoryReportForm
