import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'reactstrap';
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions';
import { useTableColumns, useTableRows } from './hooks'
import { DateRangeSelector } from '../../components/DateRangeCalendar';
import moment from 'moment';
import { dateToEpoch } from '../../utils';

const AdminGeofencesEvents = () => {
    const {
        geofencesEvents: {
            isLoading,
            data,
            error
        },
        locale,
        assetId
    } = useTypedSelector((state) => ({
        geofencesEvents: state.assetAdminInfo.geofencesEvents,
        assetId: state.assetAdminInfo.assetInfo.id,
        locale: state.whitelabel.locale
    }));
    const [calendarVisible, setCalendarVisible] = useState(false);
    const [dateRangeObj, setDateRangeObj] = useState({
        inputValue: `${moment().format("MM/DD/YYYY")} - ${moment().format("MM/DD/YYYY")}`,
        dayStart: moment().toDate(),
        dayEnd: moment().toDate()
    });

    const columns = useTableColumns();
    const rows = useTableRows(data);
    const { fetchAssetGeofencesEvents } = useActions();

    useEffect(() => {
        if (assetId) {
            const { startDate, endDate } = dateToEpoch();
            fetchAssetGeofencesEvents(startDate, endDate);
        }
    }, [assetId, fetchAssetGeofencesEvents]);

    const toggleCalendar = (value = !calendarVisible) => {
        setCalendarVisible(value)
    }

    const dateRangeHandler = (date) => {
        const { startDate, endDate } = date.selection
        setDateRangeObj({
            ...dateRangeObj,
            inputValue: `${moment(startDate).format("MM/DD/YYYY")} ${endDate ? "- " + moment(endDate).format("MM/DD/YYYY") : ""}`,
            dayStart: startDate,
            dayEnd: endDate
        })
    }

    const onDateRangeFormSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(dateRangeObj.dayStart && dateRangeObj.dayEnd) {
            const { startDate, endDate } = dateToEpoch(dateRangeObj.dayStart, dateRangeObj.dayEnd);
            fetchAssetGeofencesEvents(startDate, endDate);
        }
    }

    return (
        <RivataModule
            title="Geofences Events"
            locale={locale}
            marginTop={0}
            error={error}
            filters={<Form
                className="d-inline-flex align-items-start time_input_style"
                onSubmit={onDateRangeFormSubmit}
            >
                <div className="position-relative d-flex range-selector__wrapper">
                    <Input
                        className="date-range-field"
                        id="range_input"
                        placeholder="Please choose your dates"
                        onClick={(e) => {
                            e.persist();
                            toggleCalendar();
                        }}
                        value={dateRangeObj.inputValue}
                        readOnly

                    />
                    <DateRangeSelector
                        customClassNames="date-range-popup_right"
                        selectionRange={{ startDate: dateRangeObj.dayStart, endDate: dateRangeObj.dayEnd, key: 'selection' }}
                        handleSelect={dateRangeHandler}
                        toggleCalendar={toggleCalendar}
                        visible={calendarVisible}
                    />
                </div>
                <Button color="primary" disabled={isLoading}>{"Submit"}</Button>
            </Form>
            }
        >
            <RivataTable
                isLoading={isLoading}
                columns={columns}
                rows={rows}
            />
        </RivataModule>
    )
}

export default AdminGeofencesEvents