import React, { useCallback, useEffect, useState } from 'react'

import InfoModal from '../../components/InfoModal'
import RivataLoader from '../../components/RivataLoader'

import { useActions } from '../../hooks/useActions'

import { ScheduledReportFormValues, useInitialFormValues } from './hooks'

import EditForm from './components/EditForm/EditForm'
import {
  createScheduledReport,
  updateScheduledReport,
} from '../../redux/scheduledReports/action'
import { cloneDeep } from 'lodash'
import { InternalScheduledReportTypes, ReportEndRuleType, ScheduledReportTypes } from '../../enums'
import UnverifiedUsersModal from '../NotificationsModule/components/UnverifiedUsersModal'
import { IUnverifiedUsers } from '../NotificationsModule'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getReportEndRuleDate } from '../../utils'

interface EndRule {
  type?: number | null,
  value?: number | null,
  send_for?: number | null,
  send_for_metric?: string | null
}
interface IFormSubmitContext {
  formValues: ScheduledReportFormValues
  resetForm: () => void
}
interface Props {
  creationMode: boolean
  setCreationMode: (creationMode: boolean) => void
  reportToEdit: IScheduledReport | null
  showEditForm: boolean
  setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>
  setReportToEdit: (report: IScheduledReport | null) => void
  parameters: any
  setParameters: React.Dispatch<React.SetStateAction<any>>
  isDisabled: boolean
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
  locale: ILocale
  customerTimezone: string
  internalReports: boolean
  reportType: string
}

const ScheduledReportsModule: React.FC<Props> = ({
  creationMode,
  setCreationMode,
  reportToEdit,
  showEditForm,
  setShowEditForm,
  setReportToEdit,
  parameters,
  setParameters,
  isDisabled,
  setIsDisabled,
  locale,
  customerTimezone,
  internalReports,
  reportType,
}) => {
  const {
    scheduledReports: { users },
  } = useTypedSelector((state) => ({
    scheduledReports: state.scheduledReports,
  }))

  const { fetchScheduledReports } = useActions()

  const [formSubmitContext, setFormSubmitContext] =
    useState<IFormSubmitContext | null>(null)
  const [status, setStatus] = useState<IStatus | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const initialFormValues = useInitialFormValues(reportToEdit)
  const [frequency, setFrequency] = useState(initialFormValues.frequency)
  const [unverifiedUsers, setUnverifiedUsers] =
    useState<IUnverifiedUsers | null>(null)
  const [customerId, setCustomerId] = useState<number | null>(null) // used for non-internal scheduled customer reports
  const [endRuleEnabled, setEndRuleEnabled] = useState(!!reportToEdit?.end_rule?.send_for)

  const proceedRequest = async (formContext?: IFormSubmitContext) => {
    let formValues = formSubmitContext
      ? formSubmitContext.formValues
      : formContext?.formValues
    let resetForm = formSubmitContext
      ? formSubmitContext.resetForm
      : formContext?.resetForm
    let res: any

    if (!formValues || !resetForm) return

    setIsLoading(true)

    let rqParameters = cloneDeep(parameters)

    if (
      formValues.type !==
      InternalScheduledReportTypes.INTERNAL_CONMET_DAILY_REPORT &&
      formValues.type !== InternalScheduledReportTypes.INTERNAL_TPMS_REPORT &&
      formValues.type !== ScheduledReportTypes.TPMS_REPORT &&
      formValues.type !== ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT &&
      formValues.type !== ScheduledReportTypes.ACTIVE_WARNINGS_REPORT &&
      formValues.type !== ScheduledReportTypes.DWELL_REPORT &&
      formValues.type !== ScheduledReportTypes.DEVICE_HEALTH_REPORT &&
      formValues.type !== ScheduledReportTypes.UTILIZATION_REPORT
    ) {
      delete rqParameters.customer_keys
    }

    if (
      formValues.type !== InternalScheduledReportTypes.INTERNAL_TPMS_REPORT &&
      !(formValues.type in ScheduledReportTypes)
    ) {
      delete rqParameters.asset_type
    }

    if (
      [
        ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
        ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
        ScheduledReportTypes.DWELL_REPORT,
        InternalScheduledReportTypes.INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT,
        ScheduledReportTypes.DEVICE_HEALTH_REPORT,
      ].includes(formValues.type as ScheduledReportTypes)
    ) {
      delete rqParameters.warning_type
    }

    if (formValues.type !== ScheduledReportTypes.DWELL_REPORT) {
      delete rqParameters.dwellDays
    }

    if (
      formValues.type !==
      InternalScheduledReportTypes.INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT
    ) {
      delete rqParameters.usernames
    }

    for (let i = 0; i < rqParameters.bcc_users.length; i++) {
      delete rqParameters.bcc_users[i].email_verified
    }

    let endRule: EndRule | null = null
    if (endRuleEnabled && formValues.sendFor && formValues.sendForMetric) {
      const sendFor = Number(formValues.sendFor)
      if (sendFor === initialFormValues.sendFor && formValues.sendForMetric === initialFormValues.sendForMetric) {
        if (reportToEdit?.end_rule) {
          endRule = reportToEdit.end_rule
        }
      } else {
        endRule = { send_for: sendFor, send_for_metric: formValues.sendForMetric }
        if (formValues.sendForMetric === 'times') {
          endRule.type = ReportEndRuleType.COUNT
          endRule.value = sendFor
        }
        else {
          endRule.type = ReportEndRuleType.EPOCH
          endRule.value = getReportEndRuleDate(formValues.sendForMetric, sendFor)
        }
      }
    }

    if (creationMode) {
      res = await createScheduledReport(
        frequency,
        rqParameters,
        initialFormValues.isDisabled !== isDisabled,
        formValues.name,
        formValues.type,
        customerId,
        endRule
      )
    } else if (reportToEdit && formValues) {
      res = await updateScheduledReport(
        reportToEdit.id,
        frequency,
        rqParameters,
        initialFormValues.isDisabled !== isDisabled,
        formValues.name,
        formValues.customerId,
        endRule
      )
    }

    if (res && res.ok && resetForm) {
      resetForm()
      fetchScheduledReports(internalReports, reportType)
    }

    setIsLoading(false)
    setStatus(res)
  }

  useEffect(() => {
    setIsDisabled(initialFormValues.isDisabled)
    setFrequency(initialFormValues.frequency)
  }, [reportToEdit, initialFormValues, setIsDisabled])

  useEffect(() => {
    setParameters(parameters)
  }, [parameters, setParameters, reportToEdit])

  const handleFormSubmit = (
    formValues: ScheduledReportFormValues,
    parameters: any,
    resetForm: () => void,
  ) => {
    console.log('here', formValues, parameters)
    const formContext = { formValues, resetForm }
    setFormSubmitContext(formContext)

    const bcc_users = parameters['bcc_users']

    if (bcc_users && bcc_users.length > 0) {
      bcc_users.forEach((bu: { user_id: number; email_verified: boolean }) => {
        let u = users.find((user: { id: number }) => bu.user_id === user.id)

        if (u) {
          bu.email_verified = u.email_verified
        }
      })
    }

    const unverifiedEmailList: IUserShortFormat[] = []
    for (let i = 0; i < bcc_users.length; i++) {
      const bcc_user: IUserShortFormat = bcc_users[i]
      if (!bcc_user.email_verified) {
        unverifiedEmailList.push(bcc_user)
      }
    }
    if (unverifiedEmailList.length) {
      setUnverifiedUsers({
        state: true,
        aknowledged: false,
        unverifiedUsersList: { email: unverifiedEmailList, sms: [] },
      })
      return
    }
    proceedRequest(formContext)
  }

  const onCancel = useCallback(() => {
    setReportToEdit(null)
    setShowEditForm(false)
    setCreationMode(false)
  }, [setReportToEdit, setShowEditForm, setCreationMode])

  if (!showEditForm && !reportToEdit) return null

  return (
    <>
      {isLoading && <RivataLoader />}

      <EditForm
        initialValues={initialFormValues}
        isUpdate={true}
        handleFormSubmit={handleFormSubmit}
        onCancel={onCancel}
        parameters={parameters}
        setParameters={setParameters}
        creationMode={creationMode}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        locale={locale}
        frequency={frequency}
        setFrequency={setFrequency}
        customerTimezone={customerTimezone}
        internalReports={internalReports}
        setCustomerId={setCustomerId}
        selectedCustomerId={customerId}
        endRuleEnabled={endRuleEnabled}
        setEndRuleEnabled={setEndRuleEnabled}
      />

      {status && (
        <InfoModal
          header={'Scheduled Report'}
          message={status.message}
          open={true}
          onConfirm={() => {
            if (status.ok) onCancel()

            setStatus(null)
          }}
        />
      )}

      {unverifiedUsers?.state && !unverifiedUsers.aknowledged && (
        <UnverifiedUsersModal
          unverifiedUsers={unverifiedUsers}
          setUnverifiedUsers={setUnverifiedUsers}
          proceedRequest={proceedRequest}
          header='Report Schedule'
          message='The following users have unverified emails. These users can be added to the scheduled report, but will not receive scheduled reports until they log in for the first time.'
        />
      )}
    </>
  )
}

export default ScheduledReportsModule
