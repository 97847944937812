

export const getCustomTooltipContent = (data: any) => {
    const labelColor = "#4c86c2"

    return `
        <div 
            class="alert-primary cluster show" 
            role="alert" 
            aria-live="assertive" 
            aria-atomic="true"" 
            style="background-color: ${labelColor}; border-color: ${labelColor};"
        >
            <div class="label-line"></div>
            <table class="table-responsive table-light table-sm m-0 p-0">
                <tbody>
                    <tr>
                        <th scope="row">Geofence: </th>
                        <td colspan=3>${data.name}</td>
                    </tr>
                    <tr>
                        <th scope="row">Customer:</th>
                        <td colspan=3>${data.customer_name}</td>
                    </tr>
                    <tr>
                        <th scope="row">Assets Count: </th>
                        <td colspan=3>${data.assets_in_geofence}</td>
                        <td class="aligned-cell details"><a id="locationMapBubble">Details</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    `
}