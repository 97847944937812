import React, { useState, useEffect } from 'react'
import { Col, Label, CustomInput } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import { DEFAULT_LEGAL_TEXT } from '../../constants/constants'
import { Input } from 'reactstrap'
interface Props {
  title: string
  footer: any
  onUpdate: (data: any) => void
  width: number
  locale: any
}
const FooterSettings: React.FC<Props> = ({
  title,
  width,
  footer,
  onUpdate,
  locale,
}: Props) => {
  const { legalText = DEFAULT_LEGAL_TEXT } = { ...footer }
  const loc = locale
  const [legalTxt, setLegalTxt] = useState(legalText)
  return (
    <RivataModule title={title} locale={loc} width={width} filters={<></>}>
      <>
        <Col md='12' className='p-0'>
          <Col md='12' className='p-0'>
            <Label>Legal Text</Label>
            <Input
              name='legalText'
              type='textarea'
              rows={7}
              defaultValue={legalTxt}
              onChange={(e: any) => {
                setLegalTxt(e.target.value)
                onUpdate({
                  ...footer,
                  legalText: e.target.value,
                })
              }}
            />
          </Col>
        </Col>
      </>
    </RivataModule>
  )
}

export default FooterSettings
