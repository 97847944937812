import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"

import hereMapsApi from "../../../services/api/ApiGroups/HereMapsApi"

import RivataMenu from "../../../components/RivataMenu"

import { pinMap } from "../../../components/RivataMap/utils"

type HereSuggestion = {
  label: string
  id: string
}

type AddressDetails = {
  id: string
  position: {
    lat: number
    lng: number
  }
}

interface Props {
  map: H.Map | null
  resetAddressSearch: number
}

const AddressSearch: React.FC<Props> = ({ map, resetAddressSearch }) => {
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState<Array<HereSuggestion> | null>(null)
  const [selectedAddress, setSelectedAddress] = useState<HereSuggestion | null>(null)
  const [addressDetails, setAddressDetails] = useState<AddressDetails | null>(null)
  const [markerGroup, setMarkerBbox] = useState<any>(null)

  useEffect(() => {
    // initialize group for marker
    const markersGroup = new H.map.Group();
    map?.addObject(markersGroup)

    setMarkerBbox(markersGroup)
  }, [map])

  useEffect(() => {
    if (resetAddressSearch === 0) return

    setSuggestions(null)
    setSelectedAddress(null)
    setValue("")
  }, [resetAddressSearch])

  useEffect(() => {
    if ((!value && suggestions?.length) || selectedAddress) return setSuggestions(null)
  }, [value, suggestions, selectedAddress])

  useEffect(() => {
    if (!value) return

    const fetchSuggestions = async () => {
      try {
        const data = await hereMapsApi.getSuggestions(value)

        let suggestionItems: Array<HereSuggestion> = []
        data.items.forEach((d: any) => {
          const s: HereSuggestion = {
            id: d.id,
            label: d.address.label
          }
          suggestionItems.push(s)
        });

        setSuggestions(suggestionItems)
      } catch (err) {
        console.log(err)
      }
    }

    fetchSuggestions()
  }, [value, selectedAddress])

  useEffect(() => {
    if (!selectedAddress) return markerGroup?.removeAll()

    const fetchSuggestionDetails = async () => {
      const data = await hereMapsApi.getAddressDetails(selectedAddress.id)
      setAddressDetails(data)
    }
    fetchSuggestionDetails()

  }, [selectedAddress, markerGroup])


  useEffect(() => {
    if (!addressDetails) return

    const { lat, lng } = addressDetails.position
    const marker = new H.map.Marker(
      { lat: lat, lng: lng },
      { icon: pinMap.pinNavyBlue }
    );

    markerGroup.removeAll()
    markerGroup.addObject(marker)
    map?.getViewModel().setLookAtData({
      bounds: markerGroup.getBoundingBox(),
      zoom: 14
    })

  }, [map, markerGroup, addressDetails])

  return (
    <div className="col-4" style={{ position: "relative" }}>
      <Input
        placeholder="Type Address"
        className="w-100"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value)
          setSelectedAddress(null)
        }}
      />
      {suggestions && (
        <RivataMenu
          data={suggestions}
          displayNotFound={!suggestions.length && value.length > 0}
          labelKey={"label"}
          onClick={(el) => {
            setValue(el.label)
            setSelectedAddress(el)
            setSuggestions(null)
          }}
        />
      )}
    </div>
  )
}

export default AddressSearch