import { IWhiteLabelColors } from ".";
import { IMapAsset } from "../../redux/manageGeofences/types";
import { isValidCoord } from "../../utils";
import { pinMap } from "../RivataMapCluster/utils";

type IClusterWarnings = Array<{
    color: string;
    count: number;
  }>

export default function createAssetsLayer(
  assets: Array<IMapAsset>,
  addInfoBubble: (e: Event) => void,
  whiteLabelColors: IWhiteLabelColors
) {
  const dataPoints: Array<H.clustering.DataPoint> = []

  assets.forEach((item) => {
    if (isValidCoord(item.latitude) && isValidCoord(item.longitude))
      dataPoints.push(new H.clustering.DataPoint(item.latitude, item.longitude, 1, item))
  })

  const theme = {
    getClusterPresentation: (cluster: H.clustering.ICluster) => {
      return getClusterMarker(cluster, whiteLabelColors);
    },
    getNoisePresentation: (noisePoint: H.clustering.INoisePoint) => {
      return getNoisePointMarker(noisePoint, addInfoBubble);
    },
  };

  const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    theme,
    clusteringOptions: {
      eps: 32,
      minWeight: 2,
      strategy: H.clustering.Provider.Strategy.GRID,
    },
  });

  return new H.map.layer.ObjectLayer(clusteredDataProvider);
}

const getClusterMarker = (
  cluster: H.clustering.ICluster,
  whiteLabelColors: IWhiteLabelColors
) => {
  const dataPoints: Array<H.clustering.INoisePoint> = [];
  cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

  const clusterWarnings = getClusterWarnings(dataPoints, whiteLabelColors);

  return new H.map.Marker(cluster.getPosition(), {
    icon: getClusterIcon(clusterWarnings, dataPoints.length),
    min: cluster.getMinZoom(),
    max: cluster.getMaxZoom(),
  });
};

const getClusterWarnings = (
  dataPoints: Array<H.clustering.INoisePoint>,
  whiteLabelColors: IWhiteLabelColors
) => {
  return dataPoints.reduce(
    (points: IClusterWarnings, marker) => {
      const { hasCriticalWarning, hasWarning } = marker.getData();
      if (hasCriticalWarning === true) {
        points[0].count++;
      } else if (hasWarning === true) {
        points[1].count++;
      } else {
        points[2].count++;
      }
      return points;
    },
    [
      {
        color: whiteLabelColors.healthCriticalWarning,
        count: 0,
      },
      {
        color: whiteLabelColors.healthWarning,
        count: 0,
      },
      {
        color: whiteLabelColors.healthGood,
        count: 0,
      },
    ]
  );
};

const getClusterIcon = (clusterWarnings: any, numOfPoints: number) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const radius = 38;

  canvas.width = radius * 2;
  canvas.height = radius * 2;

  const cursor = { end: 0, start: 0 };

  if (context) {
    clusterWarnings.forEach((item: any) => {
      const ratio = item.count / numOfPoints;
      cursor.end = 2 * ratio * Math.PI;
      context.beginPath();
      context.moveTo(radius, radius);
      context.arc(
        radius,
        radius,
        radius,
        cursor.start,
        cursor.end + cursor.start,
        false
      );
      context.fillStyle = item.color;
      context.globalAlpha = 0.8;
      cursor.start += cursor.end;
      context.fill();
    });

    context.fillStyle = "#6d6e71";
    context.globalAlpha = 1;
    context.beginPath();
    context.arc(radius, radius, 25, 0, 2 * Math.PI);
    context.fill();
    context.fillStyle = "white";
    context.font = "bold 22px Arial";
    context.textAlign = "center";
    context.fillText(numOfPoints.toString(), radius, 45);
  }

  return new H.map.Icon(canvas, {
    size: { w: 38, h: 38 },
    anchor: { x: 19, y: 19 },
    crossOrigin: false,
  });
};

const getNoisePointMarker = (
  noisePoint: H.clustering.INoisePoint,
  addInfoBubble: (e: Event) => void
) => {
  const data = noisePoint.getData();

  const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
    ...getPinIcon(data),
    data,
    min: noisePoint.getMinZoom(),
  });

  noiseMarker.addEventListener("pointerenter", addInfoBubble);

  return noiseMarker;
};

const getPinIcon = (data: any) => {
  const { hasCriticalWarning, hasWarning } = data;

  const icon = hasCriticalWarning
    ? pinMap.critWarn
    : hasWarning
      ? pinMap.warn
      : pinMap.ok;

  const zIndex = hasCriticalWarning ? 2 : hasWarning ? 3 : 4;

  return {
    icon,
    zIndex,
  };
};
