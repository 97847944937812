import React, { useEffect } from 'react'
import SubNav from '../../components/SubNav'
import CustomerSelector from '../../modules/CustomerSelector'
import GeofencesGrid from './Grid'
import GeofencesActivityMap from './Map'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface Props {}

const breadcrumbs = [{ label: "Home", path: "/dashboard" }, { label: "Geofences" }]

const Geofences: React.FC<Props> = () => {
    const {
        locale, 
        selectedCustomersList,
        isLoading,
        data,
        geofencesStatus,
    } = useTypedSelector(state => ({
        locale: state.whitelabel.locale,
        selectedCustomersList: state.common.customers.selectedCustomersList,
        isLoading: state.geofences.geofences.isLoading,
        data: state.geofences.geofences.data,
        geofencesStatus: state.geofences.geofences.status,
    }))
    const { 
        fetchGeofences, 
        fetchGeofencePageAssetsLocations, 
        setGeofenceAssetsData,
        setGeofencesOffset
    } = useActions()


    useEffect(() => {
        setGeofencesOffset(0)
        fetchGeofences()
        fetchGeofencePageAssetsLocations()
    }, [selectedCustomersList, fetchGeofences, fetchGeofencePageAssetsLocations, setGeofencesOffset])

    useEffect(() => {
        return () => {
            setGeofenceAssetsData([])
        }
    }, [setGeofenceAssetsData])


    return (
        <>
            <SubNav title="Geofences" breadcrumbs={breadcrumbs}>
                <CustomerSelector />
            </SubNav>

            <GeofencesActivityMap
                locale={locale}
                isLoading={isLoading}
                geofences={data}
                geofencesStatus={geofencesStatus}
            />

            <GeofencesGrid
                locale={locale}
                selectedCustomersList={selectedCustomersList}
                isLoading={isLoading}
                geofences={data}
                geofencesStatus={geofencesStatus}
            />
        </>
    )
}

export default React.memo(Geofences)