import React, { useState, useEffect } from "react"
import { Row, Col, Input, Label, FormGroup, } from "reactstrap"

import { nameToCapitalizedLabel } from "../../../../../utils"
import TimeInput, { IAllowedRange } from "../../../../../components/TimeInput"
import moment from "moment"

interface Props {
    settings: ICronSettings,
    updateDateTime: (hours: number, minutes: number, day: number) => void,
    toggleWeekday: (id: number) => void,
    allowedTimeRange: IAllowedRange
}

const Weekly: React.FC<Props> = ({
    settings,
    updateDateTime,
    toggleWeekday,
    allowedTimeRange
}) => {
    const [time, setTime] = useState("");

    useEffect(() => {
        if (time) {
            updateDateTime(Number(time.slice(0, 2)), Number(time.slice(3, 5)), settings.datetime.day);
        }
    }, [time, settings.datetime.day, updateDateTime])

    return (
        <>
            <Row className="flex align-items-center p-3 mt-3">
                    {settings.tabs.weekly.options.map(({ id, name, enabled }, i) => (
                        <Col key={i}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={enabled}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => toggleWeekday(id)}
                                    />
                                    {nameToCapitalizedLabel(name)}
                                </Label>
                            </FormGroup>
                        </Col>
                    ))}
            </Row>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 option-section">
                <div className="mt-1">
                    <label className="pr-2 mb-0 pt-2">
                        Start Time:
                    </label>
                    <TimeInput
                        value={moment(`${settings.datetime.hours}:${settings.datetime.minutes}`, "HH:mm").format("HH:mm")}
                        setValue={setTime}
                        dropdownItemsStep={10}
                        restrictToStep
                        allowedRange={allowedTimeRange}
                    />
                </div>
            </Row>
        </>
    )
}

export default React.memo(Weekly)
