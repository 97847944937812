import React, { useEffect, useRef, useState } from 'react'
import { Row } from 'reactstrap'
import CreateModal, {
  SelectFormField,
} from '../../../components/CreateModal/children'
import { FormField } from '../../../components/CreateModal'
import {
  AssetTypes,
  ATIInstalledOptions,
  AssetSubtypeTractor,
  AssetSubtypeTrailer,
} from '../../../enums'
import * as Yup from 'yup'
import { getClearLabel } from '../../../utils'

const nullableOption = { value: null, label: '' }

const AssetSchema = Yup.object().shape({
  name: Yup.string().required('Required').max(100),
  vin: Yup.string().required('Required').max(100),
  year: Yup.number()
    .min(1900)
    .max(new Date().getFullYear() + 2),
  make: Yup.string().max(100),
  model: Yup.string().max(100),
  asset_type: Yup.string().required('Required'),
  ati_installed: Yup.string().required('Required').nullable(),
  asset_subtype: Yup.string().required('Required'),
  number_of_axles: Yup.number().required('Required').min(1).max(5),
})

const assetTypeOptions = [
  nullableOption,
  { value: AssetTypes.TRACTOR, label: 'Tractor' },
  { value: AssetTypes.TRAILER, label: 'Trailer' },
]

const AssetModal = ({
  locale,
  onCreate,
  disabled,
  isVinDisabled = false,
  isNHTSAFilled = false,
  initialValues,
  title,
  open,
  onClose,
}) => {
  const formRef = useRef()
  const [assetSubTypeOptions, setAssetSubTypeOptions] = useState([
    nullableOption,
  ])
  const [selectedAssetType, setSelectedAssetType] = useState(
    initialValues.asset_type,
  )

  useEffect(() => {
    if (!selectedAssetType) {
      setAssetSubTypeOptions([nullableOption])
    } else if (selectedAssetType === AssetTypes.TRAILER) {
      setAssetSubTypeOptions([
        nullableOption,
        ...[...Object.values(AssetSubtypeTrailer)].map((c) => {
          return { value: c, label: getClearLabel(c) }
        }),
      ])
    } else if (selectedAssetType === AssetTypes.TRACTOR) {
      setAssetSubTypeOptions([
        nullableOption,
        ...[...Object.values(AssetSubtypeTractor)].map((c) => {
          return { value: c, label: getClearLabel(c) }
        }),
      ])
    }

    if (formRef.current) {
      formRef.current.setFieldValue('asset_subtype', undefined)
    }
  }, [selectedAssetType])

  return (
    <CreateModal
      variant='primary'
      disabled={disabled}
      className='ml-3'
      btnClassName='ml-3'
      onCreate={onCreate}
      createBtnLabel={title}
      openBtnLabel={`${title} Asset`}
      header={`${title} Asset`}
      locale={locale}
      schema={AssetSchema}
      initialValues={initialValues}
      onClose={onClose}
      open={open}
      setRef={formRef}
    >
      <Row className='p-t-0'>
        <FormField name='name' label='Name' />
        <FormField name='vin' label='VIN' disabled={isVinDisabled} />
        <SelectFormField
          customOnChange={(ev) => {
            setSelectedAssetType(ev.target.value)
          }}
          name='asset_type'
          label='Asset Type'
          options={assetTypeOptions}
          disabled={isNHTSAFilled && initialValues.asset_type}
        />
        <SelectFormField
          name='asset_subtype'
          label='Asset Sub Type'
          options={assetSubTypeOptions}
          disabled={isNHTSAFilled && initialValues.asset_subtype}
        />
        <FormField
          name='year'
          type='number'
          label='Year'
          disabled={isNHTSAFilled}
        />
        <FormField name='make' label='Make' disabled={isNHTSAFilled} />
        <FormField name='model' label='Model' disabled={isNHTSAFilled} />
        <FormField
          name='number_of_axles'
          type='number'
          label='Number Of Axles'
        />
        <SelectFormField
          name='ati_installed'
          label='ATI Installed'
          options={ATIInstalledOptions}
        />
      </Row>
    </CreateModal>
  )
}

export default AssetModal
