import { DocumentsReducer, DocumentsTypes, DocumentsAction } from "./types"
import { getStatusObj } from "../../utils/utils"


const initialState: DocumentsReducer = {
  isLoading: false,
  documents: {},
  error: false,
  types: {
    isLoading: false,
    docTypes: [],
    status: getStatusObj()
  }
}

const documents = (state = initialState, action: DocumentsAction) => {
  switch (action.type) {
    case DocumentsTypes.LOADING_DOCUMENTS:
      return { ...state, isLoading: action.payload };
      
    case DocumentsTypes.LOADING_DOCUMENT_TYPES:
      return { ...state, types: { ...state.types, isLoading: action.payload } };

    case DocumentsTypes.SET_DOCUMENTS:
      return { ...state, documents: action.payload };

    case DocumentsTypes.SET_DOCUMENTS_ERROR:
      return { ...state, error: action.payload };

    case DocumentsTypes.SET_DOCUMENTS_TYPES:
      return { ...state, types: { ...state.types, docTypes: action.payload } }

    case DocumentsTypes.SET_DOCUMENTS_TYPES_STATUS:
      return { ...state, types: { ...state.types, status: action.payload } }

    default:
      return state
  }
}

export default documents