import * as Yup from "yup"
import { validatePhone } from "../../../utils"

export const phoneNumberMessage = 'Invalid phone number'

Yup.addMethod(Yup.string, "isValidPhoneNumberValue", function() {
    return this.test({
        name: 'isValidPhoneNumberValue',
        message: (v) => validatePhone(v.value).errorMessage,
        test: (v) => validatePhone(v).isValid
    });
})

const UserSchema = Yup.object().shape({
    username: Yup.string().required("Required").trim().lowercase(),
    email_address: Yup.string()
      .email("Must supply valid email")
      .required("Required"),
    phone_number: Yup.string()
        .isValidPhoneNumberValue(),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    license_state: Yup.string(),
    timezone: Yup.string(),
    role: Yup.string(),
})

export default UserSchema