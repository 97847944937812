import React, { useCallback, useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Button from '../../componentsV2/Button'
import StatusAlert from '../../components/StatusAlert'
import './styles.scss'
import GroupItem from './GroupItem'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { Spinner } from 'reactstrap'
import AssetGroupModal from './AssetGroupModal'
import { IAssetGroupFormValues } from './types'
import InfoModal from '../../components/InfoModal'
import { getStatusObj } from '../../utils/utils'
import DeleteModal from '../../components/DeleteModal'
import RivataPagination from '../../components/RivataPagination'
import LimitDropdown from '../../components/LimitDropdown'
import { makeScrollToY } from '../../utils/utils'

const initialAssetGroupFormValues: IAssetGroupFormValues = {
  id: null,
  name: '',
}

const AssetGroupsModule = () => {
  const {
    selectedCustomersList,
    assetGroups: { isLoading, data, totalCount, status, limit, offset },
    locale,
  } = useTypedSelector((state) => ({
    assetGroups: state.assetGroups.assetGroups,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    locale: state.whitelabel.locale,
  }))
  const {
    setAssetGroupsOffset,
    fetchAssetGroupsInfo,
    createAssetGroup,
    updateAssetGroup,
    setAssetGroupsStatus,
    deleteAssetGroup,
    setAssetGroupsLimit,
  } = useActions()

  const history = useHistory()

  const [groupsList, setGroupsList] = useState<Array<any> | null>([])
  const [assetGroupModalVisible, setAssetGroupModalVisible] = useState(false)
  const [deleteAssetGroupData, setDeleteAssetGroupData] = useState(
    initialAssetGroupFormValues,
  )
  const [assetGroupInitialValues, setAssetGroupInitialValues] = useState(
    initialAssetGroupFormValues,
  )

  useEffect(() => {
    if (selectedCustomersList.length === 1) {
      fetchAssetGroupsInfo()
    }
  }, [setAssetGroupsOffset, fetchAssetGroupsInfo, selectedCustomersList])

  useEffect(() => {
    setGroupsList(data)
  }, [data])

  const toggleAssetGroupModal = () => {
    setAssetGroupModalVisible(!assetGroupModalVisible)
  }

  const onCreateAssetGroupClick = () => {
    setAssetGroupInitialValues(initialAssetGroupFormValues)
    toggleAssetGroupModal()
  }

  const onEditAssetGroupClick = (values: IAssetGroupFormValues) => {
    setAssetGroupInitialValues(values)
    toggleAssetGroupModal()
  }

  const onDeleteAssetGroupClick = (values: IAssetGroupFormValues) => {
    setDeleteAssetGroupData(values)
  }

  const onDeleteAssetGroupSubmit = async () => {
    if (deleteAssetGroupData.id) {
      const res: any = await deleteAssetGroup(deleteAssetGroupData.id)

      if (res.ok) {
        setDeleteAssetGroupData(initialAssetGroupFormValues)
      }
    }
  }

  const onAssetGroupSubmit = async (formValues: IAssetGroupFormValues) => {
    let res: any
    const selectedCustomerId = selectedCustomersList[0].id
    if (formValues.id) {
      res = await updateAssetGroup(formValues.name, formValues.id)
    } else {
      res = await createAssetGroup(formValues.name, selectedCustomerId)
      if (res) history.push(`/asset-groups/${res}?tab=unassigned`)
    }

    if (res.ok) {
      toggleAssetGroupModal()
    }
  }

  const onPaginationClick = useCallback(
    (newPage: number) => {
      setAssetGroupsOffset(newPage * limit)
      makeScrollToY(0)
    },
    [limit, setAssetGroupsOffset],
  )

  return (
    <>
      <div className='row container-fluid justify-content-center align-items-center'>
        <Col md='4 d-flex justify-content-start p-0'>
          <h5>All groups ({totalCount})</h5>
        </Col>
        <Col md='4 d-flex justify-content-end p-0'>
          <Button
            btnType='btn__primary-filled'
            onClick={onCreateAssetGroupClick}
          >
            Create new group
          </Button>
        </Col>
      </div>
      <div className='row container-fluid justify-content-center align-items-center'>
        <Col md='8 border' className='mt-3 pt-3 bg-white rounded'>
          {isLoading ? (
            <Col md='12 d-flex justify-content-center pb-3'>
              <Spinner size='md' className='mr-2' />
            </Col>
          ) : (
            <>
              {!!data?.length && (
                <div className='d-flex'>
                  <RivataPagination
                    totalCount={totalCount}
                    pageLimit={limit}
                    setCurrentPage={onPaginationClick}
                    currentPage={offset / limit}
                  />

                  <LimitDropdown
                    pageLimit={limit}
                    setPageLimit={setAssetGroupsLimit}
                  />
                </div>
              )}

              {!groupsList || groupsList.length === 0 ? (
                <StatusAlert
                  customText='No Asset Groups'
                  color='success'
                  statusCode={undefined}
                  statusText={undefined}
                />
              ) : (
                data &&
                data.map((group: any, idx: number) => (
                  <GroupItem
                    key={group.id}
                    group={group}
                    onEditClick={onEditAssetGroupClick}
                    onDeleteClick={onDeleteAssetGroupClick}
                  />
                ))
              )}

              {!!data?.length && (
                <div className='d-flex justify-content-end'>
                  <RivataPagination
                    totalCount={totalCount}
                    pageLimit={limit}
                    setCurrentPage={onPaginationClick}
                    currentPage={offset / limit}
                  />

                  <LimitDropdown
                    pageLimit={limit}
                    setPageLimit={setAssetGroupsLimit}
                  />
                </div>
              )}
            </>
          )}
        </Col>
      </div>
      {assetGroupModalVisible && (
        <AssetGroupModal
          disabled={isLoading}
          initialValues={assetGroupInitialValues}
          locale={locale}
          onClose={toggleAssetGroupModal}
          onCreate={onAssetGroupSubmit}
          open={true}
          title={assetGroupInitialValues.id ? 'Update' : 'Create'}
        />
      )}

      {!status.ok && (
        <InfoModal
          open={true}
          header='Asset Group Info'
          message={status.message}
          onConfirm={() => setAssetGroupsStatus(getStatusObj())}
        />
      )}

      {deleteAssetGroupData.id && (
        <DeleteModal
          open={true}
          disabled={false}
          header={`Delete Asset Group "${deleteAssetGroupData.name}"?`}
          message={'This cannot be undone!'}
          locale={locale}
          onDelete={() => onDeleteAssetGroupSubmit()}
          onCancel={() => setDeleteAssetGroupData(initialAssetGroupFormValues)}
        />
      )}
    </>
  )
}

export default AssetGroupsModule
