import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import DropdownItemSelector from '../DropdownItemSelector'
import { MenuItemType } from '../Menu/MenuItem'

import { sensorTypeItems } from './items'
import { cloneDeep } from 'lodash'

interface Props {
    onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
    className?: string
    disabled?: boolean
    overrideDefaultItems?: MenuItemType[]
    readonly?: boolean
}

const SensorTypeFilter = forwardRef(({
    onDropdownItemSelect,
    className,
    disabled,
    overrideDefaultItems,
    readonly
}: Props, ref) => {

    const [selectedItems, setSelectedItems] = useState([] as Array<MenuItemType>)
    const [label, setLabel] = useState('Sensor Type')
    const [allItems, setAllItems] = useState(cloneDeep(sensorTypeItems))

    useEffect(() => {
        if (overrideDefaultItems) {
            setAllItems(overrideDefaultItems);
            const checked = overrideDefaultItems.filter(el => el.checked && el.id.indexOf("||") === -1)
            setSelectedItems(checked)
        }
    }, [overrideDefaultItems])
    
    const isNewSelectedValueIds = useRef(true)

    const onGroupItemSelect = useCallback((id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(el => el.checked && el.id.indexOf("||") === -1)
        if (onDropdownItemSelect)
            onDropdownItemSelect(id, checked)
        setSelectedItems(checked)
    }, [onDropdownItemSelect])

    useImperativeHandle(ref, () => ({
        clearSelection() {
            isNewSelectedValueIds.current = false
            setSelectedItems([])
            setAllItems(allItems.map(c => {
                return {
                    ...c,
                    checked: false
                }
            }))
        }
    }))

    useEffect(() => {
        setLabel('Sensor Type' + (selectedItems && selectedItems?.length > 0 ? `: ${selectedItems?.length} selected` : ""))
    }, [selectedItems])

    return (
        <DropdownItemSelector
            id="sensor_type"
            filterClassName={`mr-2 ${className ?? ""}`}
            filterBtnLabel={label}
            items={allItems}
            onItemsChange={onGroupItemSelect}
            disabled={disabled ? disabled : false}
            clearSelectionOnReset={true}
            readOnly={readonly}
        />
    )
})

export default React.memo(SensorTypeFilter)
