import React from 'react'
import { RenderHeaderCellProps, SortDirection } from 'react-data-grid'

export const renderSortableHeader = (h: RenderHeaderCellProps<any>) => {
  const getIconClassName = (direction: SortDirection | undefined) => {
    if (direction === 'ASC') {
      return 'fas fa-sort-down sort__desc'
    } else if (direction === 'DESC') {
      return 'fas fa-sort-up sort__desc'
    }
    return 'fas fa-sort sort__default'
  }

  return (
    <div style={{ cursor: 'pointer' }}>
      <span>{h.column.name}</span>
      <i className={`ml-2 ${getIconClassName(h.sortDirection)}`} />
    </div>
  )
}
