import React, { useCallback, useMemo, useRef } from 'react'
import AssetNameVinFilter from '../../componentsV2/AssetNameVinFilter'
import Button from '../../componentsV2/Button'
import { cloneDeep, debounce } from 'lodash'
import SensorTypeFilter from '../../componentsV2/SensorTypeFilter'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import HealthStatusFilter from '../../componentsV2/HealthStatusFilter'
import SensorPositionFilter from '../../componentsV2/SensorPositionFilter'
import { warningTypeItems } from '../RecentWarnings/filterConfigs'
import { ActionableItemsFilter } from '../../redux/actionableItems/types'
import { actionableWarningsSensorTypeItems } from './filtersConfig'

const wrapperStyle = { width: '470px' }

type Props = {
  onFilter: (filter: ActionableItemsFilter) => void
  className?: string
  disabled?: boolean
}

const Filters: React.FC<Props> = ({ onFilter, className, disabled }) => {
  const searchParams = useRef<Record<string, Array<string>>>({})

  //induvidual filter component refs for reset
  const assetNameVinFilterRef = useRef<any>(null)
  const sensorTypeFilterRef = useRef<any>(null)
  const healthStatusFilterRef = useRef<any>(null)
  const sensorPositionFilterRef = useRef<any>(null)

  const sensorTypeItems = useMemo(
    () => cloneDeep(actionableWarningsSensorTypeItems),
    [],
  )
  const heathStatusItems = useMemo(() => cloneDeep(warningTypeItems), [])

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      if (!value || !value.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(value)) {
        searchParams.current[key] = value
      } else {
        searchParams.current[key] = [value]
      }

      onFilter(searchParams.current)
    },
    [onFilter],
  )

  const debouncedUpdateFilters = useMemo(() => {
    return debounce((id, value) => updateFilters(id, value), 600)
  }, [updateFilters])

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const value = items.filter((el) => el.checked).map((el) => el.id)

      debouncedUpdateFilters(id, value)
    },
    [debouncedUpdateFilters],
  )

  const handleFiltersReset = useCallback(
    (filterData: boolean = true) => {
      if (!Object.keys(searchParams).length) return

      searchParams.current = cloneDeep({})

      //reset input fields
      assetNameVinFilterRef?.current?.clearValue()

      //reset dropdowns
      sensorTypeFilterRef?.current?.clearSelection()
      healthStatusFilterRef?.current?.clearSelection()
      sensorPositionFilterRef?.current?.clearSelection()

      if (filterData) onFilter({})
    },
    [onFilter],
  )

  return (
    <div className={className}>
      <div className='d-flex justify-content-between flex-wrap'>
        <AssetNameVinFilter
          wrapperStyle={wrapperStyle}
          onChange={debouncedUpdateFilters}
          ref={assetNameVinFilterRef}
        />
      </div>

      <br />

      <div className='d-flex flex-wrap justify-content-between'>
        <div className='d-flex flex-wrap'>
          <SensorTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            overrideDefaultItems={sensorTypeItems}
            ref={sensorTypeFilterRef}
            disabled={disabled}
          />

          <HealthStatusFilter
            id='warning_type'
            overrideDefaultItems={heathStatusItems}
            onDropdownItemSelect={onDropdownItemSelect}
            ref={healthStatusFilterRef}
            ignoreParrentFilterCheck
            disabled={disabled}
          />

          <SensorPositionFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={sensorPositionFilterRef}
            disabled={disabled}
          />
        </div>

        <div>
          <Button
            onClick={() => {
              handleFiltersReset()
            }}
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Filters
