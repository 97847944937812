import React from 'react'
import { Col } from "reactstrap";
import SubNav from '../../components/SubNav'
import CustomerSelector from '../../modules/CustomerSelector'
import GeofenceGroupsModule from '../../modules/GeofenceGroupsModule'

interface Props { }

const breadcrumbs = [
    { label: "Home", path: "/dashboard" },
    { label: "Geofence Groups" }
]

const GeofenceGroups: React.FC<Props> = () => {
    return (
        <>
            <SubNav
                title="Geofence Groups"
                breadcrumbs={breadcrumbs}
            >
                <CustomerSelector />
            </SubNav>

            <Col md="12">
                <GeofenceGroupsModule />
            </Col>
        </>
    )
}

export default GeofenceGroups
