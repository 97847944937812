import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import api from '../../../services/api'
import RivataLoader from '../../../components/RivataLoader'
import { useActions } from '../../../hooks/useActions'
import InfoModal from '../../../components/InfoModal'
import ConfirmModal from '../../../components/ConfirmModal'

const CustomerCheckbox = ({
  customer,
  selectedCustomerId,
  customerIds,
  onCustomerCheck,
}: {
  customer: ICustomer
  selectedCustomerId: number
  customerIds: Array<number>
  onCustomerCheck: (id: number) => void
}) => {
  const disabled = customer.id === selectedCustomerId
  const checked = disabled || customerIds.includes(customer.id)

  return (
    <div
      className={`mt-2 ${customer.parent_id ? 'ml-4' : 'ml-2'}`}
      key={`customer${customer.id}`}
    >
      <input
        type='checkbox'
        id={`customer${customer.id}`}
        onChange={() => onCustomerCheck(customer.id)}
        disabled={disabled}
        checked={checked}
      />
      <label className='mb-0 ml-1' htmlFor={`customer${customer.id}`}>
        {customer.name}
      </label>
    </div>
  )
}

const GeofenceCheckbox = ({
  geofence,
  locationIds,
  onGeofenceCheck,
  lenOfGeofences,
}: {
  geofence?: IShortGeofence
  locationIds: Array<number>
  onGeofenceCheck: (id: number | 'All') => void
  lenOfGeofences?: number
}) => {
  const checked = geofence
    ? locationIds.includes(geofence.id)
    : locationIds.length === lenOfGeofences

  const key = `geofence${geofence ? geofence.id : 'All'}`

  return (
    <div className={`mt-2 ${geofence ? 'ml-4' : 'ml-2'}`} key={key}>
      <input
        type='checkbox'
        id={key}
        onChange={() => onGeofenceCheck(geofence ? geofence.id : 'All')}
        checked={checked}
      />
      <label className='mb-0 ml-1' htmlFor={key}>
        {geofence ? geofence.name : 'All'}
      </label>
    </div>
  )
}

type Props = {
  className?: string
}

const ShareGeofencesButton: React.FC<Props> = ({ className }) => {
  const {
    customers: { data: customers, selectedCustomersList },
    isSuperAdmin,
    geofences,
  }: {
    customers: {
      data: Array<ICustomer>
      selectedCustomersList: Array<ICustomer>
    }
    isSuperAdmin: boolean
    geofences: Array<IShortGeofence>
  } = useTypedSelector((state) => ({
    customers: state.common.customers,
    isSuperAdmin: state.auth.isSuperAdmin,
    geofences: state.manageGeofences.allShortData.data,
  }))

  const { setManageGeofencesOffset } = useActions()

  const [isMainModalOpen, setIsMainModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const [customerIds, setCustomerIds] = useState<Array<number>>([])
  const [locationIds, setLocationIds] = useState<Array<number>>([])

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  const customersFiltered = useMemo(() => {
    if (!isSuperAdmin) {
      const parent = customers.find((customer) => !customer.parent_id)

      const children = customers.filter((customer) => !!customer.parent_id)

      return { parent, children }
    }

    if (selectedCustomersList.length !== 1) return

    const parentId = !selectedCustomersList[0].parent_id
      ? selectedCustomersList[0].id
      : selectedCustomersList[0].parent_id

    const parent = customers.find(
      (customer: ICustomer) => customer.id === parentId,
    )

    const children = customers.filter(
      (customer: ICustomer) => customer.parent_id === parentId,
    )

    return { parent, children }
  }, [isSuperAdmin, customers, selectedCustomersList])

  const geofencesFiltered = useMemo(() => {
    if (selectedCustomersList.length !== 1) return

    return geofences.filter(
      (geofence) => geofence.customer_id === selectedCustomersList[0].id,
    )
  }, [geofences, selectedCustomersList])

  useEffect(() => {
    if (isMainModalOpen) {
      setCustomerIds([])
      setLocationIds([])
    }
  }, [customersFiltered, geofencesFiltered, isMainModalOpen])

  const onCustomerCheck = useCallback((customerId: number) => {
    setCustomerIds((customerIds) => {
      const res = [...customerIds]
      const idx = res.findIndex((id) => id === customerId)

      if (idx >= 0) {
        res.splice(idx, 1)
        return res
      }

      res.push(customerId)
      return res
    })
  }, [])

  const onGeofenceCheck = useCallback(
    (locationId: number | 'All') => {
      setLocationIds((locationIds) => {
        if (locationId === 'All') {
          if (
            !geofencesFiltered ||
            locationIds.length === geofencesFiltered.length
          )
            return []

          return geofencesFiltered.map((el) => el.id)
        }

        const res = [...locationIds]
        const idx = res.findIndex((id) => id === locationId)

        if (idx >= 0) {
          res.splice(idx, 1)
          return res
        }

        res.push(locationId)
        return res
      })
    },
    [geofencesFiltered],
  )

  const handleSubmit = useCallback(() => {
    setIsConfirmModalOpen(false)
    setIsLoading(true)
    setError('')

    api
      .shareGeolocations(customerIds, locationIds)
      .then(() => {
        setIsMainModalOpen(false)
        setIsInfoModalOpen(true)
        setManageGeofencesOffset(0)
      })
      .catch((err) => setError(err.message))
      .finally(() => {
        setIsLoading(false)
      })
  }, [customerIds, locationIds, setManageGeofencesOffset])

  return (
    <>
      {customers.length > 1 && (
        <Button
          className={className}
          onClick={() => setIsMainModalOpen(true)}
          disabled={selectedCustomersList.length !== 1}
        >
          Share Geofences
        </Button>
      )}

      {selectedCustomersList.length === 1 && (
        <Modal
          isOpen={isMainModalOpen}
          toggle={() => setIsMainModalOpen((prev) => isLoading || !prev)}
        >
          {isLoading && <RivataLoader />}

          <ModalHeader toggle={() => setIsMainModalOpen((prev) => !prev)}>
            Share Geofences
          </ModalHeader>

          <ModalBody>
            <h5>Customers:</h5>

            {customersFiltered ? (
              <div className='share-form'>
                {customersFiltered.parent && (
                  <CustomerCheckbox
                    customer={customersFiltered.parent}
                    selectedCustomerId={selectedCustomersList[0].id}
                    customerIds={customerIds}
                    onCustomerCheck={onCustomerCheck}
                  />
                )}

                {customersFiltered.children.map((childCustomer) => (
                  <CustomerCheckbox
                    customer={childCustomer}
                    selectedCustomerId={selectedCustomersList[0].id}
                    customerIds={customerIds}
                    onCustomerCheck={onCustomerCheck}
                    key={childCustomer.id}
                  />
                ))}
              </div>
            ) : (
              <h6 className='ml-2'>Customer not selected</h6>
            )}

            <h5 className='mt-3'>Geofences:</h5>

            {geofencesFiltered?.length ? (
              <div className='share-form'>
                <GeofenceCheckbox
                  locationIds={locationIds}
                  onGeofenceCheck={onGeofenceCheck}
                  lenOfGeofences={geofencesFiltered.length}
                />

                {geofencesFiltered.map((geofence) => (
                  <GeofenceCheckbox
                    geofence={geofence}
                    locationIds={locationIds}
                    onGeofenceCheck={onGeofenceCheck}
                  />
                ))}
              </div>
            ) : (
              <h6 className='ml-2'>No geofences</h6>
            )}
          </ModalBody>

          <ModalFooter>
            {error && <div className='error mr-3'>{error}</div>}

            <Button
              onClick={() => setIsConfirmModalOpen(true)}
              disabled={isLoading || !locationIds.length}
              color='success'
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          header='Confirm Geofences Sharing'
          onClose={() => setIsConfirmModalOpen(false)}
          open={true}
          modalButtons={[
            { id: 1, label: 'Submit', color: 'success', onClick: handleSubmit },
          ]}
        >
          <p>
            {locationIds.length} geofences will be shared with{' '}
            {customerIds.length}{' '}
            {customerIds.length === 1 ? 'account' : 'accounts'}.
          </p>
          <p>This will overwrite current sharing.</p>
          <p>Are you sure you want to proceed?</p>
        </ConfirmModal>
      )}

      <InfoModal
        header='Share Geofences Info'
        message='Geofences successfully shared'
        open={!!isInfoModalOpen}
        onConfirm={() => setIsInfoModalOpen(false)}
      />
    </>
  )
}

export default ShareGeofencesButton
