import { useMemo } from 'react'

import { IColumn } from '../../components/RivataGrid/index'
import { ReportTypes, TagReportTypes } from '../../enums'
import { dvHealthReportColumns } from './parsers/deviceHealthReport'
import { tpmsReportColumns } from './parsers/tpmsReport'
import { dailyReportColumns } from './parsers/dailyReport'
import { getClearLabel } from '../../utils'
import { geofencesReportColumns } from './parsers/geofencesReports'
import { distanceReportColumns } from './parsers/distanceTravelReport'
import {
  tagHistoryDetailsReportColumns,
  tagsReportColumns,
} from './parsers/tagsReport'
import { hardwareCommandHistoryReportColumns } from './parsers/hardwareCommandHistoryReport'
import { odometerSummaryColumns } from './parsers/odometerOffsetSummaryReport'
import { utilizationReportColumns } from './parsers/utilizationReport'
import { dwellReportColumns } from './parsers/dwellReport'
import { cloneDeep } from 'lodash'

interface IuseReportColumns {
  (
    reportType: string,
    columns: Array<string>,
    unitsOfMeasurementConfig: any,
  ): Array<IColumn>
}

const useReportColumns: IuseReportColumns = (
  reportType,
  columns,
  unitsOfMeasurementConfig,
) => {
  return useMemo(() => {
    let parsedColumns: Array<IColumn> = []

    if (!columns.length) return parsedColumns

    switch (reportType) {
      case ReportTypes.USER_LOGINS_REPORT:
        parsedColumns.push({ key: columns[0], name: 'Username' })
        parsedColumns.push({ key: columns[1], name: 'Last Login' })
        parsedColumns.push({ key: columns[2], name: 'Application' })
        break
      case ReportTypes.HARDWARE_COMMANDS_HISTORY_REPORT:
        parsedColumns = hardwareCommandHistoryReportColumns(columns)
        break
      case ReportTypes.TPMS_REPORT:
        parsedColumns = tpmsReportColumns(columns)
        break
      case ReportTypes.DEVICE_HEALTH_REPORT:
        parsedColumns = dvHealthReportColumns(columns)
        break
      case ReportTypes.DAILY_MILEAGE_REPORT:
        parsedColumns = dailyReportColumns(columns, unitsOfMeasurementConfig)
        break
      case ReportTypes.GEOFENCE_HISTORY_REPORT:
        parsedColumns = geofencesReportColumns(columns)
        break
      case ReportTypes.DISTANCE_TRAVELED_REPORT:
        parsedColumns = distanceReportColumns(columns)
        break
      case TagReportTypes.LATEST_TAG_LOCATION_REPORT:
      case TagReportTypes.LOST_TAGS_REPORT:
      case TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT:
        parsedColumns = tagsReportColumns(columns)
        break
      case TagReportTypes.TAG_HISTORY_DETAILS_REPORT:
        parsedColumns = tagHistoryDetailsReportColumns(columns)
        break
      case TagReportTypes.CARGO_TAG_GATEWAY_REPORT:
        parsedColumns = columns.map((key) => {
          if (key === 'esn') return { key, name: 'ESN', width: 200 }
          return { key, name: getClearLabel(key), width: 200 }
        })
        break
      case ReportTypes.ODOMETER_OFFSET_SUMMARY_REPORT:
        parsedColumns = odometerSummaryColumns(columns)
        break
      case ReportTypes.UTILIZATION_REPORT:
        parsedColumns = utilizationReportColumns(columns)
        break
      case ReportTypes.DWELL_REPORT:
        parsedColumns = dwellReportColumns(columns)
        break
      default:
        parsedColumns = columns.map((key) => {
          if (key === 'vin') return { key, name: 'VIN', width: 200 }
          return { key, name: getClearLabel(key), width: 200 }
        })
        break
    }

    const lastColumn = parsedColumns[parsedColumns.length - 1]
    const clonedLastColumn = { ...lastColumn }
    clonedLastColumn.width = undefined // make last column stick to the table end
    clonedLastColumn.minWidth = 150
    parsedColumns[parsedColumns.length - 1] = clonedLastColumn
    return parsedColumns
  }, [reportType, columns, unitsOfMeasurementConfig])
}

export default useReportColumns
