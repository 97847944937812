import {
  convertDataEpochToDate,
  getClearLabel,
  getCurrentEpoch,
} from '../../../utils'
import { getWarningDescription } from '../../../utils/utils'
import { IRow } from '../../../components/RivataGrid'
import { NoDataLabels } from '../../../enums'

const getLabelBySensorType = (type: string) => {
  switch (type) {
    case 'hub_sensor':
      return 'SmartHub'
    case 'tire_sensor':
      return 'TPMS'
    case 'axle_load':
      return 'Axle Load'
    case 'line_pressure':
      return 'Line Pressure'
    default:
      return getClearLabel(type)
  }
}

export const warningHistoryReportRows = (
  rows: Array<IRow>,
  locale: ILocale,
  unitsOfMeasurementConfig: any,
) => {
  const currentDateTime = convertDataEpochToDate(
    getCurrentEpoch(),
    null,
    null,
    true,
  )

  return rows.map((row) => {
    row.warning_recovery_timestamp = row.warning_recovery_timestamp
      ? convertDataEpochToDate(row.warning_recovery_timestamp, null, null, true)
      : NoDataLabels.TRIPPLE_DASH
    row.description = getWarningDescription(
      row.warning_type,
      row.description.warning_subtype,
      row.description.duration_in_seconds,
      row.description.warning_value,
      row.description.is_recovered,
      locale,
      unitsOfMeasurementConfig,
      row.warning_recovery_timestamp,
      row.description.max_warning_value,
      row.description.canceled,
      row.description.canceled && row.description.canceled_at
        ? convertDataEpochToDate(row.description.canceled_at, null, null, true)
        : NoDataLabels.TRIPPLE_DASH,
      row.description.canceled
        ? row.description.cancel_reason
        : NoDataLabels.TRIPPLE_DASH,
      row.description.threshold,
    )
    row.parent_account = row.parent_account
      ? row.parent_account
      : NoDataLabels.TRIPPLE_DASH
    row.position = row.position
      ? getClearLabel(row.position)
      : NoDataLabels.TRIPPLE_DASH
    row.sensor_type = getLabelBySensorType(row.sensor_type)
    row.warning_type = row.warning_type
      ? getClearLabel(row.warning_type)
      : NoDataLabels.TRIPPLE_DASH
    row.warning_timestamp = convertDataEpochToDate(
      row.warning_timestamp,
      null,
      null,
      true,
    )
    row.report_datetime = currentDateTime
    row.asset_geofences = row.asset_geofences || NoDataLabels.TRIPPLE_DASH
    row.warning_latitude = row.warning_latitude || NoDataLabels.TRIPPLE_DASH
    row.warning_longitude = row.warning_longitude || NoDataLabels.TRIPPLE_DASH
    row.warning_geofence = row.warning_geofence || NoDataLabels.TRIPPLE_DASH
    row.asset_groups = row.asset_groups || NoDataLabels.TRIPPLE_DASH
    row.geofence_groups = row.geofence_groups || NoDataLabels.TRIPPLE_DASH

    return row
  })
}
