import React from "react";

import doubleTrailerAxle from "../../../assets/images/tpms_status_diagram/DoubleTrailerAxle.svg";

import doubleDriveAxle from "../../../assets/images/tpms_status_diagram/DoubleDriveAxle.svg";

import singleSteerAxle from "../../../assets/images/tpms_status_diagram/SingleSteerAxle.svg";

import TireLayer from "./TireLayer";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const Axle = ({ axle, axleType, style, height, pressureUnit, getTireData, timezone, locale }) => {
  const axles = {
    doubleTrailerAxle,
    doubleDriveAxle,
    singleSteerAxle,
  };

  const { isSuperAdmin } = useTypedSelector((state) => ({
    isSuperAdmin: state.auth.isSuperAdmin,
  }))
 
  return (
    <div style={style}>
    <img src={axles[axleType]} height={axleType="singleSteerAxle" ? "245" : height} alt="axle"></img> 
    
      {axle.sensors.map((sensor, i) => {
        const data = getTireData(sensor);
       
        return (
          <TireLayer
            key={sensor.key + i}
            axleType={axleType}
            tirePressure={data.pressure}
            tireTemperature={data.temperature}
            tirePosition={sensor.key}
            tirePositionLabel={locale[sensor.key]}
            tireStatus={data.status}
            hidden={data.hidden}
            pressureUnit={pressureUnit}
            coldInflationPressure={data.coldInflationPressure}
            description={data.description}
            latestTirePressureMeasurement={data.timestampPressure}
            latestTireTemperatureMeasurement={data.timestampTemperature}
            timezone={timezone}
            isNoStatusSensor={data.isNoStatusSensor}
            fastLeak={data.fastLeak}
            isSuperAdmin={isSuperAdmin}
            />
        );
      })}
    </div>
  );
};

export default Axle;
