import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import * as Yup from 'yup'

import { ScheduledReportFormValues } from '../../hooks'

import './style.scss'
import EditFormInputs from './EditFormInputs'
import CronEditor from '../CronEditor/CronEditor'
import { DefaultCron } from '../../../../constants/constants'
interface Props {
  initialValues: ScheduledReportFormValues
  isUpdate: boolean
  handleFormSubmit: (
    formValues: ScheduledReportFormValues,
    parameters: Object,
    resetForm: () => void,
  ) => void
  onCancel: () => void
  parameters: any
  setParameters: React.Dispatch<React.SetStateAction<string>>
  creationMode: boolean
  isDisabled: boolean
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
  locale: ILocale
  frequency: string
  setFrequency: React.Dispatch<React.SetStateAction<string>>
  customerTimezone: string
  internalReports: boolean
  setCustomerId: React.Dispatch<React.SetStateAction<number | null>>
  selectedCustomerId: number | null
  endRuleEnabled: boolean
  setEndRuleEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const EditForm: React.FC<Props> = ({
  initialValues,
  isUpdate,
  handleFormSubmit,
  onCancel,
  parameters,
  setParameters,
  creationMode,
  isDisabled,
  setIsDisabled,
  locale,
  frequency,
  setFrequency,
  customerTimezone,
  internalReports,
  setCustomerId,
  selectedCustomerId,
  endRuleEnabled,
  setEndRuleEnabled,
}) => {
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [showCronModal, setShowCronModal] = useState(false)
  const [newCron, setNewCron] = useState(frequency)
  const [timezone, setTimezone] = useState(parameters.timezone)

  useEffect(() => {
    setIsFormTouched(false)
  }, [initialValues])

  const handleSubmit = (values: any, resetForm: any) => {
    handleFormSubmit(values, parameters, resetForm)
  }

  useEffect(() => {
    if (creationMode) {
      initialValues.name = ''
      initialValues.frequency = ''
      initialValues.parameters = {
        bcc_users: [],
        timezone: customerTimezone,
        asset_type: 'all',
      }
    }
  }, [creationMode, initialValues, customerTimezone])

  const updateFrequency = () => {
    setFrequency(newCron)
    setIsFormTouched(true)
  }

  let formRef = useRef<any>(null)

  const yupScheme = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      type: Yup.string().required('Type is required'),
      parameters: Yup.object({
        timezone: Yup.string().required('Timezone is required'),
      }),
      sendFor: Yup.number()
        .nullable()
        .when({
          is: () => {
            return endRuleEnabled
          },
          then: (schema) =>
            schema
              .required('Field is required')
              .min(1, 'Value should be between 1 and 50')
              .max(50, 'Value should be between 1 and 50'),
        }),
      sendForMetric: Yup.string()
        .nullable()
        .when({
          is: () => {
            return endRuleEnabled
          },
          then: (schema) => schema.required('Field is required'),
        }),
    })
  }, [endRuleEnabled])

  return (
    <Formik
      validationSchema={yupScheme}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm)
      }}
      innerRef={(p) => {
        if (p !== null) {
          formRef.current = p
        }
      }}
    >
      <Form>
        <Row>
          <Col md='12' className='mt-3'>
            <EditFormInputs
              initialValues={initialValues}
              setIsFormTouched={setIsFormTouched}
              parameters={parameters}
              setParameters={setParameters}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              creationMode={creationMode}
              frequency={frequency}
              setShowCronModal={setShowCronModal}
              internalReports={internalReports}
              timezone={timezone}
              setTimezone={setTimezone}
              setCustomerId={setCustomerId}
              selectedCustomerId={selectedCustomerId}
              setFrequency={setFrequency}
              endRuleEnabled={endRuleEnabled}
              setEndRuleEnabled={setEndRuleEnabled}
              formRef={formRef}
            />
          </Col>
        </Row>

        <Modal isOpen={showCronModal} size='lg'>
          <ModalHeader>
            {locale['EditFrequency'] || 'Edit Frequency'}
          </ModalHeader>
          <ModalBody>
            <CronEditor
              cron={frequency ? frequency : DefaultCron}
              setNewCron={setNewCron}
              timezone={timezone}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              onClick={() => {
                updateFrequency()
                setShowCronModal(false)
              }}
            >
              {locale['ok'] || 'Ok'}
            </Button>{' '}
            <Button
              color='secondary'
              onClick={() => {
                setShowCronModal(false)
              }}
            >
              {locale['cancel'] || 'Cancel'}
            </Button>
          </ModalFooter>
        </Modal>

        <Row className='mt-3' style={{ alignSelf: 'flex-end' }}>
          <Col className='d-flex justify-content-end'>
            <Button type='submit' color='primary' disabled={!isFormTouched}>
              {isUpdate ? 'Save' : 'Create'}
            </Button>
            <Button color={'danger'} className='ml-3' onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Formik>
  )
}

export default EditForm
