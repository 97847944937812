import { IRow } from '../../../components/RivataGrid'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'
import {
  convertDataEpochToDate,
  getClearLabel,
  metersToKmh,
  metersToMph,
} from '../../../utils'
import { getNormalizedSource } from '../../../utils/utils'

export const odometerSummaryColumns = (columns: Array<string>) => {
  return columns.map((column) => {
    if (column === 'epoch') column = 'date'
    else if (column === 'epoch_entered') column = 'date_entered'
    else if (column === 'vin') return { key: column, name: 'VIN' }

    return { key: column, name: getClearLabel(column) }
  })
}

export const odometerSummaryRows = (
  rows: Array<IRow>,
  unitsOfMeasurementConfig: any,
) => {
  return rows.map((row) => {
    row.date = convertDataEpochToDate(row.epoch, null, null, true)
    row.date_entered = convertDataEpochToDate(
      row.epoch_entered,
      null,
      null,
      true,
    )

    if (
      unitsOfMeasurementConfig?.distance ===
      UnitsOfMeasurementConfig.distance.miles
    ) {
      row.units = 'Miles'
      row.odometer_offset = Math.ceil(metersToMph(row.odometer_offset))
    } else {
      row.units = 'Kilometers'
      row.odometer_offset = Math.ceil(metersToKmh(row.odometer_offset))
    }

    row.source = getNormalizedSource(row.source)
    row.asset_groups = row.asset_groups || '---'
    return row
  })
}
