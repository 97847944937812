import React, { useCallback, useState } from 'react'
import momentTz from 'moment-timezone'
import { Row } from 'reactstrap'

import CompanySelector from '../components/CompanySelector'

import { Timezones, RolePermission } from '../../../enums'
import { IUserFormValues, IValidate } from '../types'
import UserSchema from './validationSchema'

import { useCustomerOptionsUpdate } from '../hooks'
import { FormField, PhoneNumberField } from '../../../components/CreateModal'
import CreateModal, { SelectFormField } from '../../../components/CreateModal/children'
import { getUpdateUserBody } from '../utils'
import { IUpdateUserRequest } from '../../../redux/provision/action'
import ConfirmModal from '../../../components/ConfirmModal'
import { formatRole } from '../../../utils'

interface IDefaultList {
    [key: string]: string
}

const tz: IDefaultList = Timezones
const tzOptions = Object.keys(tz).map(k => ({
    value: tz[k],
    label: `${momentTz.tz(tz[k]).format("Z")} ${tz[k]}`
}))

const roles: IDefaultList = RolePermission
const roleOptions = Object.keys(roles).map(k => ({
    value: roles[k],
    label: formatRole(roles[k])
}))

interface IUpdateUserModal {
    locale: ILocale
    formValues: IUserFormValues
    open?: boolean
    isSuperAdmin: boolean
    isAdmin: boolean
    userCustomerIds: Array<number>
    selectedCustomersList: Array<ICustomer>
    onClose?: () => void
    validate: IValidate | null
    resetFormValues: () => void
    setFormValues: (data: IUserFormValues | null) => void
    updateUser: (reqBody: IUpdateUserRequest) => void
}

const title = "Update"

const UpdateUserModal: React.FC<IUpdateUserModal> = ({
    locale,
    formValues,
    open,
    isSuperAdmin,
    isAdmin,
    userCustomerIds,
    selectedCustomersList,
    onClose,
    validate,
    resetFormValues,
    setFormValues,
    updateUser
}) => {
    const [updateUserValues, setUpdateUserValues] = useState<IUserFormValues | null>(null)
    const [updatePhoneModal, setUpdatePhoneModal] = useState<string | null>(null)

    const { customerIds, refCustomers } = formValues
    const customerOption = useCustomerOptionsUpdate(isSuperAdmin, isAdmin, selectedCustomersList, userCustomerIds, customerIds, refCustomers)


    const handleUserUpdate = useCallback((values?: IUserFormValues) => {
        const updatedValues = updateUserValues ? updateUserValues : values

        if (updatedValues) {
            const body = getUpdateUserBody(updatedValues)
    
            updateUser(body)
            setFormValues(null)
        }
    }, [updateUserValues, updateUser, setFormValues])

    const handleUpdateUserSubmit = useCallback((val: IUserFormValues): void | IStatus => {
        if (!formValues) return
        if (val.role === "") return { statusCode: 400, message: "This user doesn't have any role!" } // show error in modal if there is no valid role

        const updatedUserData = {
            ...val,
            old_phone_number: formValues.phone_number ? formValues.phone_number : ""
        }
        
        if (
            formValues.phone_number.length > 0 && 
            val.phone_number.length > 0 &&
            formValues.phone_number !== val.phone_number
        ) {
            setUpdateUserValues(updatedUserData)
            setUpdatePhoneModal(`User ${val.username} won't get sms notifications until new phone number is verified! Do you want to proceed?`)
        } else handleUserUpdate(updatedUserData)
    }, [formValues, handleUserUpdate])

    const handleSetPermissions = (ids: Array<number> | null) => {
        const formData = { ...formValues }
        
        if (ids) {
            ids.forEach((id) => {
                const idx = formData.customerIds.findIndex(el => el === id ? true : false)

                if (idx > -1) {
                    formData.customerIds.splice(idx, 1)
                } else {
                    formData.customerIds.push(id)
                }
            })
        }
        
        setFormValues(formData)
    }

    return (
        <>
            <CreateModal
                disabled={false}
                btnClassName="ml-3"
                onCreate={handleUpdateUserSubmit}
                createBtnLabel={title}
                openBtnLabel={`${title} User`}
                header={`${title} User`}
                locale={locale}
                schema={UserSchema}
                initialValues={formValues}
                onClose={onClose}
                open={open}
                validate={validate}
                resetFormValues={resetFormValues}
                footerBtnDisabled={!formValues.customerIds.length}
            >
                <Row>
                    <FormField name="username" disabled={true} label="User Name" />
                    <FormField name="email_address" disabled={true} type="email" label="Email" />
                    <FormField name="first_name" label="First Name" />
                    <FormField name="last_name" label="Last Name" />
                    <PhoneNumberField className="" name="phone_number" label="Mobile Number" />
                    <SelectFormField name="timezone" label="Time Zone" options={tzOptions} />
                    <SelectFormField name="role" label="Role" options={formValues.role === "" ? [ ...roleOptions, { value: "", label: "---" } ] : roleOptions}/>
                    <CompanySelector
                        options={customerOption}
                        setPermissions={handleSetPermissions}
                        customerIds={formValues.customerIds}
                    />
                </Row>
            </CreateModal>

            {updatePhoneModal && (
                <ConfirmModal
                    open={!!updatePhoneModal}
                    header={"Change Phone Number"}
                    onClose={() => setUpdatePhoneModal(null)}
                    locale={locale}
                    modalButtons={[
                        {
                            id: 1,
                            label: "Yes",
                            color: "primary",
                            onClick: () => {
                                setUpdatePhoneModal(null)
                                handleUserUpdate()
                            }
                        }
                    ]}
                >
                    <h6>{updatePhoneModal}</h6>
                </ConfirmModal>
            )}
        </>
    )
}

export default UpdateUserModal