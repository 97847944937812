import React, { useCallback, useState } from "react"
import DeleteModal from "../../components/DeleteModal"
import InfoModal from "../../components/InfoModal"
import reportApi from "../../services/api/ApiGroups/ReportApi"
import { getStatusObj } from "../../utils/utils"
import { useActions } from "../../hooks/useActions"

type Props = {
  reportToDelete: IScheduledReport | null
  setReportToDelete: React.Dispatch<
    React.SetStateAction<IScheduledReport | null>
  >
  internalReports: boolean,
  reportType: string
}

const SheduledReportDeleteModal: React.FC<Props> = ({
  reportToDelete,
  setReportToDelete,
  internalReports,
  reportType
}) => {
  const { fetchScheduledReports } = useActions()

  const [status, setStatus] = useState<IStatus | null>(null)

  const deleteScheduledReport = useCallback(async (id: number) => {
    reportApi
      .deleteScheduledReport(id)
      .then(() =>
        setStatus(
          getStatusObj({ statusCode: 200 }, "Scheduled report deleted!")
        )
      )
      .catch((err) => setStatus(getStatusObj(err)))
      .finally(() => {
        setReportToDelete(null)
        fetchScheduledReports(internalReports, reportType)
      })
  }, [setReportToDelete, fetchScheduledReports, internalReports, reportType])

  return (
    <>
      {!!reportToDelete && (
        <DeleteModal
          open={true}
          header={`Delete Report Schedule "${reportToDelete.report_name}"?`}
          message={"This cannot be undone!"}
          onDelete={() => deleteScheduledReport(reportToDelete.id)}
          onCancel={() => setReportToDelete(null)}
        />
      )}

      {!!status && (
        <InfoModal
          open={true}
          header="Report Schedule Info"
          message={status?.message}
          onConfirm={() => setStatus(null)}
        />
      )}
    </>
  )
}

export default SheduledReportDeleteModal
