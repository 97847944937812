import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, CustomInput } from 'reactstrap'

import geolocationApi from '../../../services/api/ApiGroups/GeolocationApi'

import RivataModule from '../../../components/RivataModule'
import GeofenceMap from '../map/GeofenceMap'
import GeofenceEditor from '../editor/GeofenceEditor'
import DeleteModal from '../../../components/DeleteModal'
import LayersDropdown from '../../../components/LayersDropdown'
import ConfirmModal from '../../../components/ConfirmModal'
import StatusAlert from '../../../components/StatusAlert'
import RivataPagination from '../../../components/RivataPagination'
import LimitDropdown from '../../../components/LimitDropdown'
import RivataLoader from '../../../components/RivataLoader'
import LocationGatewayModal from '../../GeoLocations/LocationGatewayModal'
import RivataGrid from '../../../components/RivataGrid'
import AddressSearch from './AddressSearch'
import CopyToEnvModal, { EnvStatus } from '../../../components/CopyToEnvModal'
import ScrollBlockWrapper from '../../../components/ScrollBlockWrapper'
import MapAssetsRenderer from '../../../components/MapAssetsRenderer'
import ShareGeofencesButton from './ShareGeofencesButton'
import GeofencesFilterMenu from '../filter/GeofencesFilterMenu'

import { useColumns, useTableRows } from './hooks'
import { useActions } from '../../../hooks/useActions'

import { removeSpecialCharacters } from '../../../utils'
import { getStatusObj } from '../../../utils/utils'
import { LayerTypes } from '../../../components/RivataMapCluster/utils'
import { makeScrollToY } from '../../../utils/utils'

import { IMapAsset } from '../../../redux/manageGeofences/types'

import '../styles.scss'
import UploadGeofencesButton from './UploadGeofencesButton'

const formatGatewayAssignData = (
  formData: any,
  selectedGeolocationData: any,
) => {
  const gatewayEsn = removeSpecialCharacters(formData.gateway_esn)

  return {
    location_id: selectedGeolocationData.id,
    gateway_esn:
      gatewayEsn.length === 12 ? gatewayEsn : `00${formData.gateway_esn}`,
    notified_gateway_unnasigning: false,
  }
}

interface Props {
  width: number
  locale: Record<string, string>
  isLoading: boolean
  geofences: Array<IGeofence>
  limit: number
  offset: number
  totalCount: number
  status: IStatus
  selectedCustomersList: Array<ICustomer>
  isSuperAdmin: boolean
  allGeofencesInShortFormat: Array<IShortGeofence>
  assets: Array<IMapAsset>
  isLoadingAssets: boolean
  assetsStatus: IStatus
  setAssetsFilter: (queryParams: string) => void
  customers: Array<ICustomer>
  showDeleted: boolean
  setShowDeleted: (payload: boolean) => void
  unitsOfMeasurementConfig: any
}

const GeofenceManager: React.FC<Props> = ({
  width,
  locale,
  isLoading,
  geofences,
  limit,
  offset,
  totalCount,
  status,
  selectedCustomersList,
  isSuperAdmin,
  allGeofencesInShortFormat,
  assets,
  isLoadingAssets,
  assetsStatus,
  setAssetsFilter,
  customers,
  showDeleted,
  setShowDeleted,
  unitsOfMeasurementConfig,
}) => {
  const selectedCustomerId =
    selectedCustomersList?.length === 1 ? selectedCustomersList[0].id : null

  const [map, setMap] = useState<H.Map | null>(null)
  const [ui, setUi] = useState<H.ui.UI | null>(null)
  const [behavior, setBehavior] = useState<H.mapevents.Behavior | null>(null)
  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL,
  )

  const [deleteData, setDeleteData] = useState<IGeofence | null>(null)
  const [selectedGeofence, setSelectedGeofence] = useState<IGeofence | null>(
    null,
  )
  const [selectedForReceation, setSelectedForReceation] =
    useState<IGeofence | null>(null)
  const [zoomTo, setZoomTo] = useState<number | null>(null)

  const [resetEditorState, setResetEditorState] = useState(0)
  const [error, setError] = useState('')
  const [mapZoomBounds, setMapZoomBounds] = useState({
    zoom: null,
    bounds: null,
  })

  const [gatewayModalData, setGatewayModalData] = useState<IGeofence | null>(
    null,
  )
  const [gatewayAssigningData, setGatewayAssigningData] = useState<any>(null)

  const [removeGwWarning, setRemoveGwWarning] = useState<{
    esn: string | null
    message: string
  } | null>(null)
  const [resetAddressSearch, setResetAddressSearch] = useState<number>(0)

  const [isCopyToEnvOpen, setIsCopyToEnvOpen] = useState<boolean>(false)

  const [isAssetsVisible, setIsAssetsVisible] = useState(false)

  const geofenceFilterMenuRef = useRef<any>(null)

  const [hideNonFullScreenItems, setHideNonFullScreenItems] =
    useState<boolean>(false)
  const {
    unnasignGatewayFromlocation,
    assignGatewayToLocation,
    createGeofence,
    updateGeofence,
    deleteGeofence,
    setManageGeofencesOffset,
    setManageGeofencesLimit,
    setGeofencesListFilters,
  } = useActions()

  const onPressZoomTo = useCallback((id: number) => setZoomTo(id), [])

  const onDelete = async (id: number) => {
    const res: any = await deleteGeofence(id)
    if (res.ok) {
      setDeleteData(null)
    } else {
      setError(res.message)
    }
  }

  const handleDelete = useCallback(
    (id: number) => {
      const geo = geofences.find((el) => el.id === id)
      if (geo) setDeleteData(geo)
    },
    [geofences],
  )

  const onEdit = useCallback(
    (id: number) => {
      setSelectedForReceation(null)
      setMapZoomBounds({ zoom: null, bounds: null })

      const geo = geofences.find((el) => el.id === id)

      if (geo) setZoomTo(id)
      setSelectedGeofence(geo || null)

      makeScrollToY(0)
    },
    [geofences],
  )

  const onRecreate = useCallback(
    (id: number) => {
      setSelectedGeofence(null)
      setSelectedForReceation(null)

      const geo = geofences.find((el) => el.id === id)

      if (geo) setZoomTo(id)
      setSelectedForReceation(geo || null)

      makeScrollToY(0)
    },
    [geofences],
  )

  const handleassignGateway = useCallback(
    (id: number) => {
      const geo = geofences.find((el) => el.id === id)
      if (geo) setGatewayModalData(geo)
    },
    [geofences],
  )

  // compose data for grid
  const columns = useColumns(
    customers,
    handleDelete,
    onEdit,
    handleassignGateway,
    onPressZoomTo,
    showDeleted,
    onRecreate,
  )
  const rows = useTableRows(geofences)

  useEffect(() => {
    // reset after getting new data
    setSelectedGeofence(null)
  }, [geofences])

  const handleCopyToEnvSubmit = async (
    env: EnvStatus,
    arr: Array<number | string>,
  ) => {
    if (selectedCustomersList.length !== 1) return

    try {
      await geolocationApi.postCopygeolocationToEnv({
        customer_key: selectedCustomersList[0].key,
        staging: env.staging,
        dev: env.dev,
        geofence_ids: arr,
      })

      setIsCopyToEnvOpen(false)
    } catch (err) {
      console.log(err)
      return getStatusObj(err)
    }
  }

  const onPageClick = useCallback(
    (newPage: number) => {
      setManageGeofencesOffset(newPage * limit)
    },
    [limit, setManageGeofencesOffset],
  )

  const onGeofencesFilter = useCallback(
    (value: string) => {
      setAssetsFilter(value)
      setGeofencesListFilters(value)
      setManageGeofencesOffset(0)
    },
    [setGeofencesListFilters, setAssetsFilter, setManageGeofencesOffset],
  )

  const onShowAssetsToggle = useCallback((show: boolean) => {
    setIsAssetsVisible(show)
  }, [])
  useEffect(() => {
    geofenceFilterMenuRef?.current.setMode(
      isAssetsVisible ? 'all' : 'geofence_groups_only',
    )
  }, [isAssetsVisible])
  const customFullScreenAction = (show: boolean) => {
    setHideNonFullScreenItems(show)
  }
  return (
    <>
      <RivataModule
        fullScreenModalModeEnabled
        title='Manage Geofences'
        width={width}
        marginTop={0}
        locale={locale}
        error={isAssetsVisible && !assetsStatus.ok ? assetsStatus : status}
        filters={
          <>
            {isSuperAdmin &&
              (process.env.REACT_APP_STAGE === 'prod' ||
                process.env.REACT_APP_STAGE === 'staging') && (
                <Button
                  className={
                    hideNonFullScreenItems ? 'hidden-in-full-screen' : 'mr-3'
                  }
                  onClick={() => setIsCopyToEnvOpen(true)}
                  disabled={!selectedCustomerId}
                >
                  Copy To Environment
                </Button>
              )}

            <ShareGeofencesButton
              className={
                hideNonFullScreenItems ? 'hidden-in-full-screen' : 'mr-3'
              }
            />
            <div
              className={hideNonFullScreenItems ? 'hidden-in-full-screen' : ''}
            >
              <UploadGeofencesButton />
            </div>
            <div
              className={
                hideNonFullScreenItems
                  ? 'hidden-in-full-screen'
                  : 'd-flex align-items-center mr-3'
              }
            >
              <CustomInput
                type='switch'
                id='markersSwitch'
                label='Show Assets'
                checked={isAssetsVisible}
                onChange={(e: any) => {
                  onShowAssetsToggle(e.target.checked)
                }}
              />
            </div>

            <div
              className={
                hideNonFullScreenItems
                  ? 'hidden-in-full-screen'
                  : 'd-flex align-items-center mr-3'
              }
            >
              <CustomInput
                type='switch'
                id='showDeletedSwitch'
                label='Show Deleted'
                checked={showDeleted}
                onChange={(e: any) => setShowDeleted(e.target.checked)}
              />
            </div>

            <LayersDropdown
              selected={selectedLayerOption}
              onSelect={(type: string) => setSelectedLayerOption(type)}
              locale={locale}
            />
          </>
        }
        customFullScreenAction={customFullScreenAction}
      >
        <div
          className={
            hideNonFullScreenItems
              ? 'hidden-in-full-screen'
              : 'd-flex justify-content-between w-100 mb-2'
          }
        >
          <div className='w-75'>
            <GeofenceEditor
              onMapViewChange={(bounds, zoom) => {
                setMapZoomBounds({ bounds, zoom })
                setZoomTo(null)
              }}
              mapZoomBounds={mapZoomBounds}
              map={map}
              ui={ui}
              behavior={behavior}
              geofences={geofences}
              selectedGeofence={selectedGeofence}
              zoomTo={zoomTo}
              selectedCustomerId={selectedCustomerId}
              resetEditorState={resetEditorState}
              locale={locale}
              setSelectedGeofence={setSelectedGeofence}
              createGeofence={createGeofence}
              updateGeofence={updateGeofence}
              onCancel={() => setResetAddressSearch((prev) => prev + 1)}
              showDeleted={showDeleted}
              selectedForReceation={selectedForReceation}
              setSelectedForReceation={setSelectedForReceation}
            />
          </div>

          <AddressSearch map={map} resetAddressSearch={resetAddressSearch} />
        </div>

        <ScrollBlockWrapper>
          <GeofenceMap
            map={map}
            selectedLayerOption={selectedLayerOption}
            setMap={setMap}
            setUi={setUi}
            setBehavior={setBehavior}
          />

          <MapAssetsRenderer
            map={map}
            ui={ui}
            assets={assets}
            isVisible={isAssetsVisible}
            unitsOfMeasurementConfig={unitsOfMeasurementConfig}
          />
        </ScrollBlockWrapper>
      </RivataModule>
      <RivataModule
        title='Geofences'
        width={width}
        locale={locale}
        error={isAssetsVisible && !assetsStatus.ok ? assetsStatus : status}
        filters={null}
      >
        <GeofencesFilterMenu
          onFilter={onGeofencesFilter}
          ref={geofenceFilterMenuRef}
        />
        <hr />

        {!rows.length ? (
          <StatusAlert
            statusCode={null}
            statusText={null}
            customText='No Data'
            color='success'
          />
        ) : (
          <>
            <div className='d-flex mt-4'>
              <RivataPagination
                totalCount={totalCount}
                pageLimit={limit}
                setCurrentPage={onPageClick}
                currentPage={offset / limit}
              />

              <LimitDropdown
                pageLimit={limit}
                setPageLimit={setManageGeofencesLimit}
              />
            </div>

            {(isLoading || (isAssetsVisible && isLoadingAssets)) && (
              <RivataLoader />
            )}

            {rows.length > 0 && (
              <RivataGrid columns={columns} rows={rows} rowHeight={45} />
            )}

            <div className='d-flex justify-content-end mt-4'>
              <RivataPagination
                totalCount={totalCount}
                pageLimit={limit}
                setCurrentPage={onPageClick}
                currentPage={offset / limit}
              />

              <LimitDropdown
                pageLimit={limit}
                setPageLimit={setManageGeofencesLimit}
                dateWindow={undefined}
                updateTable={undefined}
              />
            </div>
          </>
        )}
        {removeGwWarning && (
          <ConfirmModal
            header='Unassign Gateway'
            locale={locale}
            open={true}
            onClose={() => setRemoveGwWarning(null)}
            modalButtons={[
              {
                id: 1,
                label: 'Ok',
                color: 'danger',
                onClick: async () => {
                  if (removeGwWarning.esn) {
                    const res: any = await unnasignGatewayFromlocation(
                      removeGwWarning.esn,
                    )
                    if (res.ok) setGatewayModalData(null)
                  }
                  setRemoveGwWarning(null)
                },
              },
            ]}
          >
            {removeGwWarning.message}
          </ConfirmModal>
        )}

        {gatewayModalData && (
          <LocationGatewayModal
            open={true}
            locale={locale}
            disabled={false}
            initialValues={{
              gateway_esn: '',
            }}
            onDelete={(esn: string) => {
              if (gatewayModalData.gateways?.length === 1) {
                setRemoveGwWarning({
                  esn,
                  message:
                    'Tag data will not be reported from this location until a GW is assigned!',
                })
              } else {
                return unnasignGatewayFromlocation(esn)
              }
            }}
            onCreate={async (formData: any) => {
              if (!formData.gateway_esn) {
                setGatewayModalData(null)
                return
              }

              const formattedData = formatGatewayAssignData(
                formData,
                gatewayModalData,
              )
              const res: any = await assignGatewayToLocation(formattedData)

              if (res.ok) setGatewayModalData(null)
              else if (!res.ok && res.message.includes('already assigned')) {
                setGatewayAssigningData({
                  onConfirmPress: async () => {
                    formattedData.notified_gateway_unnasigning = true
                    await assignGatewayToLocation(formattedData)
                    setGatewayAssigningData(null)
                    setGatewayModalData(null)
                  },
                  message: res.message,
                })
              }

              return res
            }}
            onClose={() => {
              setGatewayModalData(null)
            }}
            data={gatewayModalData}
            isLoading={isLoading}
          />
        )}

        {deleteData && (
          <DeleteModal
            open={true}
            disabled={isLoading}
            header={`Delete geofence ${deleteData?.name}?`}
            message={'This cannot be undone, are you sure you want to proceed?'}
            onDelete={() => {
              setError('')
              if (deleteData.id) onDelete(deleteData.id)
              setResetEditorState((prev) => (prev += 1))
            }}
            onCancel={() => {
              setError('')
              setDeleteData(null)
            }}
            locale={locale}
            error={error}
          />
        )}

        {gatewayAssigningData && (
          <ConfirmModal
            open={true}
            header={'Are you sure you want to unnasign gateway?'}
            onClose={() => setGatewayAssigningData(null)}
            locale={locale}
            modalButtons={[
              {
                id: 1,
                label: 'Yes',
                color: 'success',
                onClick: () => gatewayAssigningData.onConfirmPress(),
              },
            ]}
          >
            {gatewayAssigningData.message}
          </ConfirmModal>
        )}

        {isCopyToEnvOpen && (
          <CopyToEnvModal
            header={'Copy Geofences To Environment'}
            title={'Select Geofences'}
            data={allGeofencesInShortFormat.map((el) => ({
              id: el.id,
              label: el.name,
            }))}
            onClose={() => setIsCopyToEnvOpen(false)}
            onSubmit={handleCopyToEnvSubmit}
          />
        )}
      </RivataModule>
    </>
  )
}

export default GeofenceManager
