import { IRow } from '../../../components/RivataGrid'
import { NoDataLabels } from '../../../enums'
import { convertDataEpochToDate, getTimeLabelFromSeconds } from '../../../utils'

export const assetInGeofenceReportRows = (data: Array<IRow>) => {
  return data.map((el) => {
    el['entry_time'] = convertDataEpochToDate(
      el['entry_time'],
      null,
      null,
      true,
    )
    el['report_time'] = convertDataEpochToDate(
      el['report_time'],
      null,
      null,
      true,
    )
    el['dwell_time'] = getTimeLabelFromSeconds(el['dwell_time'])
    el['asset_groups'] = el['asset_groups'] || NoDataLabels.TRIPPLE_DASH
    el['geofence_groups'] = el['geofence_groups'] || NoDataLabels.TRIPPLE_DASH

    return el
  })
}
